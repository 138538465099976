.cross-our-work-page {
  color: $black;
  margin: 0 auto 80px;

  @media all and (min-width: $screen-smd) {
    margin: 0 auto 120px;
  }

  h1 {
    margin: 80px 0 90px;
  }

  h2 {
    margin: 0;
  }

  h3 {
    margin: 0;
    font-weight: 600;
    line-height: 49px;
    margin-bottom: 14px;
  }

  p {
    margin: 0;
  }

  a {
    color: $black;
  }

  // Project Example Section
  .project-examples {
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 auto 100px;
    
    @media all and (min-width: $screen-lg) {
      padding: 0;
    }

    h2 {
      background-color: $bg-dark;
      color: $white;
      padding: 40px 0 30px 55px;
    }

    img {
      margin: 0 auto;
      display: block;
      margin-bottom: 30px;
    }

    .project-place {
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      margin-bottom: 46px;

      p {
        display: inline-block;
      }
    }

    .project-description {
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      margin-bottom: 35px;
    }

    .project-category {
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
    }

    .top-project-container {
      padding: 0 16px;
      margin: 60px auto;
      max-width: 636px;

      @media all and (min-width: $screen-lg) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 0;
        margin-bottom: 80px;
        max-width: unset;
      }

      .top-project-image-container {
        max-width: 619px;
        background-color: $white;
        position: relative;
        margin-bottom: 30px;

        @media all and (min-width: $screen-lg) {
          grid-column: 2/3;
          grid-row: 1/2;
          top: -140px;
          padding: 28px 30px 0;
        }

        img {
          @media all and (min-width: $screen-lg) {
            margin: 0 auto;
            display: block;
            min-height: 518px;
            min-width: 602px;
            object-fit: cover;
          }
        }
      }

      .top-project-article-container {
        max-width: 619px;

        @media all and (min-width: $screen-lg) {
          margin-left: 55px;
        }
      }
    }

    .bottom-project-container {
      padding: 0 16px;
      margin-bottom: 80px;
      gap: 61px;

      @media all and (min-width: $screen-lg) {
        align-items: unset;
        //padding: 0 55px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        &.no-featured-project {
          margin-top: 0;
        }
      }

      @media all and (min-width: $screen-lg) {
        margin-top: -150px;
      }

      .bottom-project-image-container {
        width: 100%;
        @media all and (min-width: $screen-sm) {
          display: flex;
          height: 312px;
          width: 636px;
          img {
            margin: 0;
            width: 100%;
            object-fit: cover;
          }
        }
      }

      .bottom-project-details {
        max-width: 596px;

        @media all and (min-width: $screen-lg) {
          padding: 30px 20px 20px 20px;
          border-left: 1px solid $black;
          border-bottom: 1px solid $black;
        }
      }
    }

    .all-project-examples {
      text-align: center;

      a {
        border-bottom: 2px solid $bg-dark;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        text-align: center;

        &:hover {
          color: $grey-2;
          border-bottom: 2px solid $grey-2;
          text-decoration: none;
        }
      }
    }
  }

  // Case Study Section
  .case-study {
    background-color: $bg-dark;
    color: #ffff;
    padding: 100px 16px;

    @media all and (min-width: $screen-xs) {
      padding: 100px 40px;
    }

    h2 {
      color: #ffff;
      margin-bottom: 80px;
    }

    .case-studies-slider {
      padding-left: 0;

      .case-study-container {
        gap: 60px;
        margin-bottom: 71px;

        @media all and (min-width: $screen-mdl) {
          flex-direction: row;
        }

        .case-study-article {
          max-width: 636px;
          padding: 0 0 46px 30px;
          border-left: 2px solid $lightblue;
          border-bottom: 2px solid $lightblue;

          .image-container {
            position: relative;
            margin-bottom: 24px;
            .image-overlay {
              position: absolute;
              top: 5px;
              left: 5px;
              @media all and (min-width: $screen-sm) {
                top: 20px;
                left: 20px;
              }
              .logo {
                margin-bottom: 10px;
                @media all and (min-width: $screen-sm) {
                  margin-bottom: 64px;
                }
              }
              .case-title {
                margin-bottom: 10px;
                font-size: 26px;
                @media all and (min-width: $screen-sm) {
                  font-size: 36px;
                  font-weight: 600;
                }
              }
              .location {
                font-size: 20px;
                font-weight: 500px;
              }
            }
          }

          .case-study-details {
            margin-top: 24px;

            .case-study-description {
              font-weight: 400;
              font-size: 14px;
              line-height: 19px;
              margin-bottom: 60px;
            }

            .view-case-study-button {
              color: $lightblue;
              padding: 16px 20px;
              margin-bottom: 30px;
              border: 2px solid $lightblue;
              border-radius: 4px;
            }
          }
        }
      }
    }

    .all-case-studies {
      text-align: center;

      a {
        color: $white;
        border-bottom: 2px solid $white;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        text-align: center;

        &:hover {
          color: $link-hover-light;
          border-bottom: 2px solid $link-hover-light;
          text-decoration: none;
        }
      }
    }
  }

  // Testimonial Section
  .testimonials {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 16px;

    @media all and (min-width: $screen-xs) {
      padding: 120px 52px 0;
    }

    h2 {
      margin-bottom: 80px;
    }

    .tesimonials-container {
      gap: 40px;
      margin-bottom: 78px;

      @media all and (min-width: $screen-lg) {
        flex-direction: row;
        align-items: unset;
      }

      .testimonial-article {
        max-width: 416px;

        .comment-icon {
          margin-bottom: 50px;
        }

        .tesimonial-quote-container {
          height: 150px;
          margin-bottom: 54px;

          p {
            font-weight: 400;
            font-size: 18px;
            line-height: 25px;
            margin-bottom: 16px;
          }
        }

        hr {
          margin: 0;
          background-color: $bg-dark;
          height: 6px;
          margin: 0 0 18px;
          max-width: 416px;
        }

        // ul {
        //   margin: 0;
        //   padding: 0;
        //   list-style: none;
        //   gap: 10px;
        //   flex-wrap: wrap;

        .testimonial-info {
          font-weight: 700;
          font-size: 20px;
          line-height: 27px;
        }
      }
    }

    .all-testimonials {
      border-bottom: 2px solid $bg-dark;
      display: inline-block;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;

      &:hover {
        color: $grey-2;
        text-decoration: none;
      }
    }
  }
}
