footer {
	border-top: 1px solid $grey-1;
	.top-footer-section {
		.partner-footer-form-section {
			@media all and ( min-width: $screen-xl ) {
				display: flex;
				justify-content: flex-end;
			}
			.partner-form-container {
				margin: 80px 16px 80px 16px;
				@media all and ( min-width: $screen-sm ) {
					margin: 80px 55px 101px 55px;
				}
				@media all and ( min-width: $screen-lg ) {
					max-width: 720px;
				}
				.gform_wrapper {
					.gform_required_legend, 
					.gfield_required, 
					.gfield_validation_message {
						color: $error-light-bg;
					}
					.gform_heading h2 {
						text-transform: uppercase;
						margin-bottom: 40px;
						text-align: center;
						@media all and ( min-width: $screen-md ) {
							text-align: left;
						}
					}
					form {
						.gform_body.gform-body {
							.gform_fields {
								display: block;
								.gfield {
									@include gform-field-container();
									margin-bottom: 17px;
									.gfield_label {
										@include gform-field-label();
									}
									.ginput_container {
										@include gform-input-fields();
										&.ginput_container_consent {
											@include gform-checkbox-grey;
											color: $grey-1;
											a {
												color: inherit;
												font-weight: 700;
											}
										}
									}
									textarea {
										height: unset;
										max-height: 120px;
										@media all and ( min-width: $screen-sm ) {
											max-height: 180px;
										}
									}
									&.gfield_error .ginput_container label {
										color: $error-light-bg;
									}
								}
							}
						}
						.gform_footer {
							@include gform-footer( $padding-top: 40px );
							input.gform_button.button {
								@include button-tilt-dark( $border-size:1px );
								padding: 16px;
								width: 100%;
								font-size: 16px;
								@media all and ( min-width: $screen-sm ) {
									padding: 16px 89px;
									width: fit-content;
								}
							}
						}
					}
				}
				.gform_confirmation_wrapper {
					text-align: center;
					margin: 0 0 70px 0;
					@media all and (min-width: $screen-sm) {
						margin: 170px 0 139px 0;
					}
					a {
						  color: $black;
					}
					.title {
						font-weight: 800;
						font-size: 32px;
						margin-bottom: 15px;
						@media all and (min-width: $screen-sm) {
							margin-bottom: 30px;
						}
					}
					.body {
						font-size: 14px;
						margin-bottom: 26px;
						@media all and (min-width: $screen-sm) {
							font-size: 18px;
							margin-bottom: 52px;
						}
						strong {
							font-weight: 800;
						}
					}
					.social-media {
						font-size: 43px;
						a {
							&:hover {
								color: $lightblue;
							}
							&.twitter-icon {
								margin-right: 100px;
							}
						}
						}
				  }
			}
		}
		.footer-nav-section {
			background-color: $black;
			@media all and ( min-width: $screen-xl ) {
				display: flex;
				justify-content: flex-start;
			}
			.footer-nav-container {
				font-size: 14px;
				padding: 50px 16px 0px 16px;
				@media all and ( min-width: $screen-md ) {
					font-size: 16px;
					padding: 90px 120px 90px 54px;
				}
				@media all and ( min-width: $screen-lg ) {
					box-sizing: border-box;
					max-width: 720px;
				}
				a {
					color: $white;
				}
				ul {
					padding-left: unset;
					margin-bottom: 46px;
					li {
						padding-bottom: 0;
						list-style: none;
						&.menu-item {
							margin-bottom: 24px;
							@media all and ( min-width: $screen-sm ) {
								margin-bottom: 40px;
							}
							a {
								font-weight: 700;
								text-transform: uppercase;
							}
							&.menu-item-has-children ul.sub-menu {
								margin-top: 26px;
								li {
									padding-top: 0;
									padding-bottom: 0;
									margin-bottom: 22px;
									a {
										font-weight: 400;
										text-transform: unset;
									}
									&:last-child {
										margin-bottom: 0;
									}
								}
							}
						}
						&:last-child {
							margin-bottom: 0;
						}
						&::before {
							content: "";
							display: none;
						}
					}
				}
				.footer-top-items {
					li {
						float: left;
						width: 50%;
						@media all and ( min-width: $screen-sm ) {
							width: 33.32223%
						}
					}
				}
				.footer-top-items-container {
					margin-bottom: 16px;
					@media all and ( min-width: $screen-sm ) {
						margin-bottom: 0;
					}
				}
				.footer-column-2-container, 
				.footer-column-3-container {
					margin-bottom: 18px;
					@media all and ( min-width: $screen-sm ) {
						margin-bottom: 0;
					}
				}
			}
		}
	}
	.bottom-footer-section {
		background-color: $black;
		.bottom-footer-section-container {
			max-width: 918px;
			color: $white;
			.copyright, 
			.site-creator {
				text-align: left;
				font-size: 12px;
				@media all and ( min-width: $screen-sm ) {
					text-align: center;
					font-size: 16px;
				}
				@media all and ( min-width: $screen-lg ) {
					display: inline-block;
					margin-right: 60px;
				}
				a {
					color: $lightblue;
				}
			}
			.copyright {
				padding: 20px 0px;
			}
			.site-creator {
				float: right;
				padding-top: 3px;
				padding-bottom: 3px;
				@media all and ( min-width: $screen-sm ) {
					float: unset;
				}
			}
			.footer-social-media-container {
				float: left;
				@media all and ( min-width: $screen-sm ) {
					float: unset;
				}
				@media all and ( min-width: $screen-lg ) {
					display: inline-block;
				}
				.footer-social-media {
					margin-bottom: 20px;
					@media all and ( min-width: $screen-sm ) {
						margin-top: 20px;
					}
					@media all and ( min-width: $screen-lg ) {
						margin-top: 0px;
					}
					a {
						font-size: 22px;
						color: $white;
						&:hover {
							color: $lightblue;
						}
						&:not(:first-child) {
							margin-left: 40px;
						}
					}
				}
				
			}
		}
	}
}