.cross-products-services-page-container {
  .page-header-container {
    padding-top: 51px;
    padding-bottom: 105px;
    @media all and (min-width: $screen-sm) {
      padding-top: 102px;
      padding-bottom: 110px;
    }
    h1 {
      color: $white;
    }
  }
  .page-content {
    background-color: $bg-dark;
    background-image: url("../../dist/images/crossover-pyramid-vertical.png");
    background-position: 100% -40px;
    background-repeat: no-repeat;
    .uk-container {
      max-width: 1250px;
    }
    .all-categoties {
      margin-bottom: 41px;
      .object-category {
        margin-bottom: 20px;
        a {
          font-size: 20px;
          border-bottom: 4px solid transparent;
          .category-icon {
            min-height: 50px;
            margin-bottom: 24px;
          }
          .category-number {
            font-size: 80px;
            line-height: 97px;
            font-weight: 600;
            color: $lightblue;
            margin-bottom: 24px;
          }
          .category-name {
            min-height: 54px;
            max-width: 186px;
            color: $white;
            margin-bottom: 10px;
            font-weight: 700;
          }
          &:hover {
            text-decoration: none;
            .category-name {
              color: $lightblue;
            }
          }
        }
        &.uk-active,
        &:hover {
          a {
            border-bottom: 4px solid $lightblue;
          }
        }
      }
    }
    .all-items {
      max-width: 1227px;
      padding-bottom: 120px;
      .object-container {
        background: $bg-dark;
        border: 2px solid $lightblue;
        border-radius: 4px;
        margin-bottom: 40px;
        a {
          font-size: 26px;
          @media all and (min-width: $screen-sm) {
            font-size: 36px;
          }
          .object-list-item {
            padding: 10px;
            column-gap: 10px;
            row-gap: 10px;
            @media all and (min-width: $screen-sm) {
              flex-direction: row;
            }
            @media all and (min-width: $screen-lg) {
              padding: 20px 60px 20px 20px;
              column-gap: 30px;
            }
            .object-thumbnail {
              display: flex;
              height: 135px;
              width: 200px;
              img {
                height: 100%;
                object-fit: cover;
              }
            }
            .object-name {
              h3 {
                color: $white;
                font-size: inherit;
              }
            }
            i {
              color: $lightblue;
              @media all and (min-width: $screen-sm) {
                padding-right: 50px;
              }
            }
            &:hover {
              background: $gradient-1;
              backdrop-filter: blur(10px);
            }
          }
          &:hover {
            text-decoration: none;
          }
        }
        .object-info-container {
          background-color: $white;
          padding: 10px;
          @media all and (min-width: $screen-sm) {
            padding: 30px 60px 30px 30px;
          }
          gap: 30px;
          @media all and (min-width: $screen-lg) {
            gap: 60px;
            flex-direction: row;
          }
          .object-image {
            height: 200px;
            @media all and (min-width: $screen-sm) {
              min-width: 425px;
            }
            &.service-image {
              height: 230px;
              @media all and (min-width: $screen-sm) {
                min-width: 425px;
              }
            }
            img {
              height: 100%;
              @media all and (min-width: $screen-md) {
                width: 100%;
              }
              object-fit: contain;
            }
          }
          .object-info {
            .object-name {
              margin-bottom: 24px;
              h3 {
                font-size: 36px;
              }
              i {
                color: $bg-dark;
              }
            }
            .object-description {
              margin-bottom: 40px;
            }
            .object-logos {
              margin-bottom: 60px;
              &.uk-grid {
                margin-left: -20px;
                & > * {
                  padding-left: 20px;
                }
              }
              .logo-container {
                width: 100%;
                img {
                  width: 100%;
                  max-height: 100px;
                  object-fit: contain;
                }
              }
            }
            .object-info-footer a {
              @include button-tilt-dark();
              padding: 16px 0;
              width: 100%;
              @media all and (min-width: $screen-sm) {
                padding: 16px 73px;
                width: fit-content;
              }
            }
          }
        }
      }
    }
  }
}
