.cross-shop-index-page {
  color: $black;
  max-width: 983px;
  margin: 0 auto 120px;
  padding: 0 16px;

  h1 {
    margin: 80px auto 60px;
    font-weight: 800;
    font-size: 64px;
    line-height: 87px;
  }

  h3 {
    font-weight: 600;
    line-height: 49px;
  }

  a {
    color: $black;
    background-color: $white;
    &.crossover-button {
      @include button-tilt-dark();
      padding: 16px 20px;
    }
  }

  .shop-all-categolies {
    gap: 40px;

    .shop-category {
      gap: 40px;
      border: 1px solid $grey-4;
      box-shadow: 0px 2px 6px rgba(42, 42, 42, 0.18);

      .shop-category-outline {
        padding: 30px 30px 35px;
        gap: 30px;

        @media all and (min-width: $screen-smd) {
          align-items: unset;
        }

        // ---------------
        .load-query-toggle-for-card {
          background-color: unset;
          text-align: unset;
          padding: 0;

          img {
            display: block;
          }
        }
        // ---------------

        .title-more-products {
          @media all and (min-width: $screen-smd) {
            width: 100%;
            flex-direction: row;
          }
          img.logo {
            margin-bottom: 30px;
          }
        }

        h3 {
          margin: 0;
        }

        .view-more-products {
          a {
            background-color: unset;
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            padding-left: 0;
            padding-right: 0;
            @media all and (min-width: $screen-sm) {
              padding-right: 30px;
            }
            &:hover {
              color: $link-hover-dark;
              text-decoration: none;
            }

            i {
              margin-left: 20px;
            }
          }
        }
      }
    }
  }

  .shop-products-table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;

    thead {
      display: none;
      @media all and (min-width: $screen-xs) {
        display: table-header-group;
      }
    }
    th {
      color: $white;
      background-color: $bg-dark;
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      &.th-product-image {
        width: 18.6%;
        padding-left: 30px;
        //width: 183px;
      }
      &.th-product-name {
        width: 32.6%;
        padding-left: 16px;
      }
      &.th-part-number {
        width: 22.8%;
        padding-left: 50px;
      }
    }

    tr {
      border: 1px solid $grey-4;
    }

    .product-row {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      min-height: 141px;

      @media all and (min-width: $screen-xs) {
        display: table-row;
      }

      .td-product-image {
        width: 180px;
        height: 130px;
      }

      .td-product-name {
        padding-left: 16px;
        text-decoration-line: underline;

        a {
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
        }
      }

      .td-model {
        padding-left: 50px;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        padding-left: 16px;
        @media all and (min-width: $screen-xs) {
          padding-left: 50px;
        }
      }

      .td-details {
        text-align: center;

        .crosover-button {
          @include button-black-white;
          padding: 8px 35px;
        }
      }
    }
  }

  .pagination-container {
    gap: 60px;
    margin: 30px 0 39px;

    .pagination {
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
      .page-numbers {
        margin-right: 36px;
        .current {
          color: $black;
        }
      }
      .next.page-numbers {
        margin-right: 0;
        margin-left: 24px;
      }
      .prev.page-numbers {
        margin-left: 0;
        margin-right: 60px;
      }
      a {
        color: $grey-2;
        &:hover {
          color: $link-hover-dark;
          text-decoration: none;
        }
      }
    }
  }

  /* .pagenation-numbers {
    gap: 36px;
  } */
}
