.gform_wrapper {
  .gform_heading .gform_required_legend,
  .gform_validation_errors {
    display: none;
  }
}

@mixin gform-field-container() {
  position: relative;
  font-family: $nunitoSans;
  margin-bottom: 20px;
  .gfield_required.gfield_required_asterisk,
  .gfield_required .gfield_required.gfield_required_asterisk {
    display: none;
  }

  &.gfield_error {
    .ginput_container {
      input,
      select,
      textarea {
        padding-left: 18px;
        padding-top: 17px;
        padding-bottom: 17px;
      }
    }
    .ginput_container {
      input,
      select,
      .chosen-container,
      textarea {
        border: 3px solid $error-dark-bg;
      }
    }
    .validation_message {
      background-color: transparent;
      border: unset;
      margin-top: unset;
      padding-top: 12px;
      padding-left: 20px;
    }
  }
}

@mixin gform-input-fields() {
  input,
  select,
  textarea {
    color: $black;
    border: 1px solid $grey-2;
    padding-left: 20px;
    padding-top: 19px;
    padding-bottom: 19px;
    line-height: 24px;
    font-size: 18px;
    width: 100%;
    border-radius: 4px;
    font-family: inherit;
    &:focus {
      outline: unset;
      border: 3px solid $lightblue;
      padding-left: 18px;
      padding-top: 17px;
      padding-bottom: 17px;
    }
  }
  &.ginput_container_checkbox {
    .gfield_checkbox .gchoice {
      vertical-align: top;
      input.gfield-choice-input {
        vertical-align: top;
        width: unset;
      }
    }
  }
  &.ginput_container_select {
    position: relative;
    z-index: 0;
    background-color: $white;
    border-radius: 4px;
    &::after {
      content: "\f078";
      font-family: $fa;
      bottom: 20px;
      right: 15px;
      @media all and (min-width: $screen-sm) {
        right: 28px;
      }
      position: absolute;
      font-size: 18px;
      line-height: 25px;
      color: $grey-2;
      z-index: -1;
    }
    select {
      background-color: transparent;
      appearance: none;
      z-index: 2;
      position: relative;
      width: 100%;
      padding-right: 30px;
    }
  }
}

@mixin gform-checkbox() {
  input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: 0;
    appearance: none;
    max-width: 16px;
    width: 100%;
    height: 16px;
    border-radius: 3.3px;
    margin: 0;
    background: transparent;
    float: left;
    padding: 0;
  }
  input[type="checkbox"]:checked {
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
  label {
    display: block;
    font-weight: normal;
    padding-left: 25px;
    font-size: 14px;
    margin-bottom: 0;
    position: relative;
    top: -2px;
  }
}

@mixin gform-checkbox-tilt {
  @include gform-checkbox();
  input[type="checkbox"] {
    border: 1px solid $lightblue;
  }
  input[type="checkbox"]:checked {
    background-image: url("../../dist/images/checkbox-tilt.svg");
  }
}

@mixin gform-checkbox-white {
  @include gform-checkbox();
  input[type="checkbox"] {
    border: 1px solid $white;
  }
  input[type="checkbox"]:checked {
    background-image: url("../../dist/images/checkbox-white.svg");
  }
}

@mixin gform-checkbox-grey {
  @include gform-checkbox();
  input[type="checkbox"] {
    border: 1px solid $grey-1;
  }
  input[type="checkbox"]:checked {
    background-image: url("../../assets/images/checkbox-grey.svg");
  }
}

@mixin gform-field-label() {
  color: $grey-1;
  background-color: $white;
  padding: unset;
  font-size: 18px;
  font-weight: 400;
  margin-left: 21px;
  margin-bottom: 0px;
  position: absolute;
  top: 19px;
  z-index: 3;
  a {
    font-size: inherit;
  }
  .gfield_required {
    display: inline-block;
    font-size: 13.008px;
    padding-left: 0.125em;
  }
  &.hidden {
    color: transparent;
    background-color: transparent;
    .gfield_required {
      color: transparent;
    }
  }
}

@mixin gform-footer($padding-top: 16px) {
  padding-bottom: unset;
  padding-top: $padding-top;
  margin: unset;
  display: block;
  input.gform_button.button {
    margin-bottom: unset;
  }
}
