.cross-home-page-container {
  .home-page-header-content {
    .header-video-container {
      position: relative;
      margin-left: auto;
      margin-right: auto;
      .header-image,
      .header-video {
        min-height: 665px;
        @media all and (min-width: $screen-sm) {
          min-height: 785px;
        }
        @media all and (min-width: $screen-md) {
          height: 961px;
        }
        position: relative;
        vertical-align: bottom;
        display: flex;
        img {
          width: 100%;
          object-fit: cover;
          min-height: 665px;
          @media all and (min-width: $screen-md) {
            height: 100%;
          }
        }
      }
      .header-image {
        display: flex;
      }
      .header-video {
        overflow: hidden;
        display: block;
        .video-embed-container {
          position: relative;
          padding-bottom: 56.25%;
          height: 0;
          iframe {
            position: absolute;
            top: -42px;
            left: -140px;
            width: 100%;
            height: 100%;
            @media all and (min-width: $screen-lg) {
              width: 140%;
              height: 140%;
            }
            @media all and (min-width: $screen-xl) {
              width: 120%;
              height: 120%;
            }
          }
        }
      }
      .video-image-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        .crossover-header-container {
          background-color: unset;
          margin-bottom: 70px;
          @media all and (min-width: $screen-sm) {
            margin-bottom: 75px;
          }
          @media all and (min-width: $screen-md) {
            margin-bottom: 154px;
          }
          &.default-background-color {
            background-color: $bg-dark;
          }
        }
        .top-logo-slider-container {
          color: $white;
          font-weight: 600;
          font-size: 22px;
          max-width: 1440px;
          @media all and (min-width: $screen-sm) {
            font-size: 36px;
            margin-left: 10px;
          }
          @media all and (min-width: $screen-md) {
            margin-left: 56px;
          }
          @media all and (min-width: $screen-xl) {
            margin-left: auto;
            margin-right: auto;
          }
          .logo {
            margin-bottom: 48px;
            @media all and (min-width: $screen-lg) {
              height: auto;
              margin-bottom: 58px;
            }
          }
          .header-text {
            margin: 0 20px 100px 20px;
            line-height: 30px;
            @media all and (min-width: $screen-sm) {
              margin-bottom: 75px;
              line-height: 43px;
            }
            @media all and (min-width: $screen-md) {
              margin-bottom: 158px;
            }
          }
          .header-slider {
            margin-left: 16px;
            @media all and (min-width: $screen-sm) {
              margin-left: 0px;
            }

            .slider-header-text {
              margin-bottom: 20px;
              line-height: 30px;
              @media all and (min-width: $screen-sm) {
                margin-bottom: 40px;
                line-height: 49px;
              }
            }

            .uk-slider {
              position: relative;
            }

            .uk-slider-items.uk-grid {
              cursor: ew-resize;
              // cursor: url("../../dist/images/cross-drag-cursor.png") 40 40, pointer;
              @media all and (min-width: $screen-sm) {
                margin-left: -20px;
                & > * {
                  padding-left: 20px;
                }
              }
            }
            li {
              padding-bottom: 0;
              a {
                cursor: inherit;
              }
              .article-container {
                position: relative;
                width: 278px;
                @media all and (min-width: $screen-sm) {
                  width: 404px;
                }
                .thumbnail {
                  max-width: 278px;
                  height: 137px;
                  @media all and (min-width: $screen-sm) {
                    max-width: unset;
                    height: 200px;
                    width: 404px;
                  }
                  img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                  }
                }
                .thumbnail-overlay {
                  background: linear-gradient(
                      0deg,
                      rgba(0, 0, 0, 0.2) 0%,
                      rgba(0, 0, 0, 0.2) 100%
                    ),
                    linear-gradient(
                      180deg,
                      rgba(0, 0, 0, 0) 1.56%,
                      rgba(0, 0, 0, 0.8) 100%
                    );
                  height: 100%;
                  align-items: flex-start;
                  display: flex;
                  flex-direction: column;
                  justify-content: end;
                  width: 100%;
                  box-sizing: border-box;
                  position: absolute;
                  left: 0px;
                  bottom: 0px;
                  font-size: 12px;
                  padding: 0 10px 16px 10px;
                  @media all and (min-width: $screen-sm) {
                    font-size: 14px;
                    padding: 0 20px 18px 20px;
                  }
                  .headline {
                    h5 {
                      color: $white;
                      font-weight: 700;
                      margin-bottom: 9px;
                      @media all and (min-width: $screen-sm) {
                        margin-bottom: 11px;
                      }
                    }
                  }
                  .text {
                    font-size: inherit;
                    color: $white;
                    font-weight: 400;
                    margin-right: 33px;
                    @media all and (min-width: $screen-sm) {
                      margin-right: 43px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .vendor-partners-section {
    max-width: 1470px;
    padding-top: 70px;
    padding-bottom: 60px;
    @media all and (min-width: $screen-md) {
      padding-top: 130px;
      padding-bottom: 97px;
    }
    h2 {
      margin-bottom: 25px;
      @media all and (min-width: $screen-sm) {
        margin-bottom: 45px;
      }
    }
    .uk-grid {
      margin-left: -15px;
      & > * {
        padding-left: 15px;
      }
    }
    .vendor-partner {
      margin: 25px 0;
      .vendor-partner-image-container {
        @media all and (min-width: $screen-sm) {
          width: 150px;
        }
        img {
          max-height: 100px;
          object-fit: contain;
        }
      }
    }
    .vendor-partners-mobile .uk-slider-nav {
      margin-top: 6px;
      li {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }

  .our-products-section {
    background-color: $bg-dark;
    background-image: url("../../dist/images/crossover-pyramid-horizontal.png");
    background-position: top right;
    background-size: cover;
    background-repeat: no-repeat;
    color: $white;
    padding-top: 60px;
    padding-bottom: 67px;
    @media all and (min-width: $screen-md) {
      padding-top: 120px;
      padding-bottom: 227px;
    }
    .our-products-section-container {
      max-width: 1247px;
      .products-heading h2 {
        color: inherit;
        margin-bottom: 50px;
        @media all and (min-width: $screen-md) {
          margin-bottom: 100px;
        }
      }
      .products-container {
        ul {
          column-gap: 42px;
          &.uk-accordion {
            @media all and (min-width: $screen-smd) {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
            }
            @media all and (min-width: $screen-xl) {
              column-gap: 165px;
            }
            & > :nth-child(n + 2) {
              margin-top: unset;
            }
          }
        }

        li.product-category-container {
          max-width: 541px;
          width: 100%;
          padding-bottom: 42px;
          @media all and (min-width: $screen-lg) {
            padding-bottom: 84px;
          }
          a {
            color: $white;
            font-size: 20px;
            line-height: 27px;
            @media all and (min-width: $screen-md) {
              font-size: 36px;
              line-height: 49px;
            }
            font-weight: 600;
            .category-item {
              padding: 4px 1.5px 4px 0;
              .category-name {
                margin-left: 20px;
                @media all and (min-width: $screen-lg) {
                  margin-left: 40px;
                  min-width: 341px;
                }
                color: inherit;
              }
              .arrow-container img {
                margin-right: 10px;
                margin-left: 10px;
                @media all and (min-width: $screen-md) {
                  margin-left: 40px;
                }
              }
              &:hover {
                text-decoration: none;
                padding-bottom: 3px;
                border-bottom: 1px solid $lightblue;
              }
            }
          }
          .accordion-content {
            margin-top: 10px;
            ul {
              padding: 16px 0 6px 0;
              margin-bottom: 0;
              background-color: $card-bg;
              backdrop-filter: blur(10px);
              li {
                list-style: none;
                color: $white;
                position: relative;
                margin-bottom: 10px;
                padding-bottom: 0;
                &:hover {
                  background-color: $link-hover-light;
                }
                a {
                  font-size: 18px;
                  line-height: 24px;
                  font-weight: 400;
                  text-decoration: none;
                  display: flex;
                  padding: 8px 10px 8px 44px;
                  @media all and (min-width: $screen-md) {
                    padding: 8.5px 10px 8.5px 56px;
                  }
                  &::before {
                    content: "";
                    height: 2px;
                    width: 16px;
                    background-color: $white;
                    position: absolute;
                    left: 20px;
                    top: 18px;
                  }
                }
                &::before {
                  content: "";
                  display: none;
                }
              }
            }
            &.show {
              display: block;
            }
          }
          &:not(.uk-open) {
            .arrow-container {
              .arrow-up {
                display: none;
              }
            }
          }
          &.uk-open {
            .arrow-container {
              .arrow-down {
                display: none;
              }
              .arrow-up {
                display: block;
              }
            }
          }
        }
      }
    }
  }
  .why-crossover-section {
    .why-crossover-section-container {
      max-width: 1328px;
      padding: 60px 0 80px 0;
      @media all and (min-width: $screen-md) {
        padding: 120px 0 120px 0;
      }
      .why-choose-heading-container {
        margin-bottom: 40px;
        @media all and (min-width: $screen-sm) {
          margin-bottom: 61px;
        }
      }
      .sub-heading-container {
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0.2)
        );
        background-image: url("../../dist/images/why-choose-subheading-background.jpeg");
        background-position: center;
        background-repeat: no-repeat;
        min-height: 406px;
        margin-bottom: 30px;
        @media all and (min-width: $screen-sm) {
          background-image: unset;
          min-height: unset;
          margin-bottom: unset;
        }
        .sub-heading {
          margin-bottom: 0;
          color: $white;
          font-size: 22px;
          padding: 0 29px 44px 29px;
          @media all and (min-width: $screen-sm) {
            color: $black;
            font-size: 32px;
            padding: 0 0 61px 0;
          }
          p {
            margin: 0;
          }
          strong {
            font-weight: 700;
          }
        }
      }
      .why-choose-content-container {
        font-size: 16px;
        padding: 0 16px;
        @media all and (min-width: $screen-md) {
          font-size: 24px;
          padding: 0;
        }
        .uk-grid {
          @media all and (min-width: $screen-md) {
            gap: 80px;
          }
        }
        .why-choose-content {
          margin-bottom: 41px;
          @media all and (min-width: $screen-sm) {
            margin-bottom: 53px;
          }
        }
        .why-choose-list-container {
          @media all and (min-width: $screen-sm) {
            background-image: url("../../dist/images/why-choose-background.jpeg");
            background-position: center;
            background-repeat: no-repeat;
            min-height: 800px;
          }
          .why-choose-list {
            width: 100%;
            column-count: 1;
            font-size: 14px;
            @media all and (min-width: $screen-sm) {
              column-count: 2;
              font-size: 24px;
              font-weight: 700;
              background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.85) 13.67%,
                rgba(0, 0, 0, 0) 105.77%
              );
              padding: 0 18px 71px 68px;
              color: $white;
            }
            ul {
              padding: 0;
              li {
                position: relative;
                list-style: none;
                padding-left: 27px;
                margin-bottom: 22px;
                padding-bottom: 0;
                @media all and (min-width: $screen-md) {
                  padding-left: 38px;
                  margin-bottom: 20px;
                }
              }
            }
          }
        }
        .become-partner-button-container {
          margin-top: 49px;
          @media all and (min-width: $screen-md) {
            margin-top: 79px;
          }
          .crossover-button {
            @include button-tilt-dark();
            font-size: 16px;
            padding: 16px 0;
            width: 100%;
            @media all and (min-width: $screen-sm) {
              padding: 16px 100px;
              width: fit-content;
            }
          }
        }
      }
    }
  }
  .services-section {
    background-color: $bg-dark;
    background-image: url("../../dist/images/services-homepage-background.png");
    background-position: top right;
    background-size: auto;
    background-repeat: no-repeat;
    color: $white;
    padding-top: 60px;
    padding-bottom: 20px;
    @media all and (min-width: $screen-md) {
      padding-top: 120px;
      padding-bottom: 49px;
    }
    .services-section-container {
      max-width: 1339px;
      .services-heading h2 {
        color: inherit;
        margin-bottom: 40px;
        @media all and (min-width: $screen-md) {
          margin-bottom: 138px;
        }
      }
      .products-container {
        ul {
          column-gap: 8px;
          &.uk-accordion {
            @media all and (min-width: $screen-smd) {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
            }
            @media all and (min-width: $screen-xl) {
              column-gap: 66px;
            }
            & > :nth-child(n + 2) {
              margin-top: unset;
            }
          }
        }

        li.product-category-container {
          max-width: 638px;
          width: 100%;
          padding-bottom: 40px;
          @media all and (min-width: $screen-lg) {
            padding-bottom: 180px;
          }
          a {
            color: $white;
            font-weight: 600;
            .category-item {
              padding: 4px 1.5px 4px 0;
              .category-icon {
                font-family: $inter;
                color: $lightblue;
                font-weight: 600;
                line-height: 1;
                letter-spacing: 0em;
                font-size: 60px;
                @media all and (min-width: $screen-md) {
                  font-size: 150px;
                }
              }
              .category-name {
                margin-left: 20px;
                font-size: 20px;
                line-height: 37px;
                @media all and (min-width: $screen-sm) {
                  font-size: 36px;
                }
                @media all and (min-width: $screen-md) {
                  margin-left: 40px;
                  line-height: 49px;
                }
                color: inherit;
              }
              .arrow-container img {
                margin-right: 10px;
                margin-left: 10px;
                @media all and (min-width: $screen-md) {
                  margin-left: 40px;
                }
              }
              &:hover {
                border-bottom: 1px solid $lightblue;
                padding-bottom: 3px;
                text-decoration: none;
              }
            }
          }
          .accordion-content {
            padding: 12px;
            margin-bottom: 0;
            background-color: $card-bg;
            backdrop-filter: blur(10px);
            margin-top: 10px;
            a {
              font-size: 18px;
              line-height: 24px;
              font-weight: 400;
              text-decoration: none;
              p {
                color: inherit;
              }
            }
            &.show {
              display: block;
            }
          }
          &:not(.uk-open) {
            .arrow-container {
              .arrow-up {
                display: none;
              }
            }
          }
          &.uk-open {
            .arrow-container {
              .arrow-down {
                display: none;
              }
              .arrow-up {
                display: block;
              }
            }
          }
          &:nth-child(2) {
            @media all and (min-width: $screen-smd) {
              grid-row: 2/3;
            }
          }
        }
      }
    }
  }
  .testimonials-section {
    background-color: $bg-dark;
    padding: 60px 16px 30px 16px;
    @media all and (min-width: $screen-md) {
      padding: 120px 0 60px 55px;
    }
    .testimonials-section-container {
      color: $white;
      .testimonials-heading h2 {
        color: inherit;
        margin-bottom: 50px;
        @media all and (min-width: $screen-sm) {
          margin-bottom: 50px;
        }
      }
      .uk-grid {
        @media all and (min-width: $screen-sm) {
          margin-left: -60px;
          & > * {
            padding-left: 60px;
          }
        }
      }
      .testimonial-container {
        font-size: 14px;
        border-left: 1px solid $lightblue;
        border-bottom: 1px solid $lightblue;
        padding: 4px 2px 20px 20px;
        margin-bottom: 50px;
        min-height: 348px;
        @media all and (min-width: $screen-sm) {
          padding: 4px 2px 0px 20px;
          width: 300px;
        }
        .text-container {
          margin-bottom: 48px;
          min-height: 225px;
        }
        .info-container {
          font-weight: 700;
          font-size: 16px;

          .testimonial-name {
            color: $white;
            font-weight: 700;
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 10px;
          }

          .testimonial-company {
            color: $white;
            text-transform: initial;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            margin: 0;
          }
        }
      }
      .navigation-container {
        color: $lightblue;
        .navigation {
          @include slider-navigation();
          .next {
            margin-left: 50px !important;
            @media all and (min-width: $screen-xxs) {
              margin-left: 80px !important;
            }
          }
        }
      }
    }
  }

  .case-studies-slider {
    padding-top: 60px;
    padding-bottom: 60px;
    @media all and (min-width: $screen-md) {
      padding-top: 80px;
      padding-bottom: 67px;
    }
  }

  .social-media-section {
    background-color: $bg-dark;
    background-image: url("../../dist/images/home-social-media-background.png");
    background-size: auto;
    background-repeat: no-repeat;
    color: $white;
    padding-bottom: 61px;
    padding: 0 16px 61px 16px;
    background-position: 0 -52%;
    @media all and (min-width: $screen-md) {
      padding: 100px 0 122px 0;
      background-position: 0 70%;
    }
    .social-media-section-container {
      color: $white;
      .social-media-heading h2 {
        color: inherit;
        margin-bottom: 30px;
        @media all and (min-width: $screen-sm) {
          margin-bottom: 60px;
        }
      }
      .social-media-icons {
        margin-bottom: 60px;
        font-size: 32px;
        @media all and (min-width: $screen-sm) {
          margin-bottom: 106px;
          font-size: 48px;
        }
        a {
          color: $white;
          &:not(:last-child) {
            margin-right: 40px;
            @media all and (min-width: $screen-sm) {
              margin-right: 150px;
            }
          }
          &:hover {
            color: $lightblue;
          }
        }
      }

      .twitter-slider-list {
        max-width: 1660px; //1440px;
        @media all and (min-width: $screen-sm) {
          margin-left: 20px;
        }

        @media all and (min-width: $screen-md) {
          margin-left: 55px;
        }

        @media all and (min-width: $screen-xl) {
          margin-left: auto;
          margin-right: auto;
        }

        .juicer-feed {
          .referral {
            display: none;
          }

          /* width */
          ::-webkit-scrollbar {
            height: 10px;
          }

          /* Track */
          ::-webkit-scrollbar-track {
            background: $bg-dark;
          }

          /* Handle */
          ::-webkit-scrollbar-thumb {
            background: $black;
          }

          /* Handle on hover */
          ::-webkit-scrollbar-thumb:hover {
            background: $lightblue;
          }

          .j-stacker-wrapper .j-stacker {
            display: flex;
            flex-direction: column;
            padding-right: 0;
            @media all and (min-width: $screen-sm) {
              padding-right: 30px;
              flex-direction: row;
              overflow-x: scroll;
            }

            & > * {
              display: block !important;
              @media all and (min-width: $screen-sm) {
                display: flex !important;
              }
            }

            .galcolumn {
              background-color: $bg-dark;
              float: left !important;
              width: 100% !important;
              margin-top: 0;
              margin-bottom: 0;
              @media all and (min-width: $screen-sm) {
                //max-height: 194px;
                margin-bottom: unset;
                margin-block-start: 0px;
                width: unset !important;
              }

              .feed-item {
                background: unset;
                border: 2px solid $lightblue;
                display: flex;
                cursor: pointer;
                min-height: 124px;
                padding: 10px;
                @media all and (min-width: $screen-sm) {
                  margin-bottom: unset !important;
                  width: 537px;
                  padding: 20px;

                  &:not(:first-child) {
                    margin-left: 20px;
                  }
                }

                .j-poster {
                  display: none;
                  // height: auto;
                  padding: 12px 16px 18px;
                  @media all and (min-width: $screen-sm) {
                    padding: 12px 23px 8px;
                  }

                  a {
                    color: $white;

                    .j-twitter-poster {
                      color: inherit;
                      margin-top: 4px;

                      .j-twitter-display-name {
                        font-size: 16px;
                        font-family: $nunitoSans;
                      }

                      .j-twitter-screen-name {
                        display: none;
                      }
                    }

                    &.j-twitter-text::before {
                      color: inherit;
                      font-size: 19px;
                      @media all and (min-width: $screen-sm) {
                        font-size: 24px;
                      }

                      &:hover {
                        color: $lightblue;
                      }
                    }

                    &:first-child img {
                      display: none;
                    }
                  }
                }

                .j-image {
                  width: 100px;
                  min-width: 100px;
                  height: 100px;
                  min-height: 100px;
                  max-height: 100px;
                  @media all and (min-width: $screen-sm) {
                    width: 200px;
                    min-width: 200px;
                    height: 200px;
                    min-height: 200px;
                    max-height: 200px;
                  }
                  img {
                    width: 100% !important;
                    height: 100% !important;
                    object-fit: cover;
                  }
                }

                .j-text {
                  padding-top: unset;

                  .j-message {
                    font-family: $nunitoSans;
                    display: inline-block;
                    color: $white;
                    font-size: 12px;
                    // font-size: 14px;
                    line-height: 19px;
                    font-weight: 400;
                    @media all and (min-width: $screen-sm) {
                      font-size: 14px;
                    }

                    br {
                      content: " ";

                      &:after {
                        content: " ";
                      }
                    }

                    .j-retweet {
                      display: none;
                    }

                    p {
                      color: inherit;
                      font-size: 16px;
                      line-height: 20px;
                      float: left;

                      &:last-child {
                        margin: 0;
                      }
                    }

                    a {
                      color: $lightblue;

                      &:hover {
                        color: $lightblue;
                        text-decoration: underline !important;
                      }
                    }

                    .j-read-more {
                      display: none;
                    }
                  }

                  .j-image {
                    display: inline;

                    &::before {
                      content: "";
                      display: none;
                    }

                    .j-content-image {
                      float: left;
                      margin-top: -37px;
                      max-height: 154px;
                      object-fit: cover;
                      max-width: 64px;
                      margin-bottom: 20px;
                      @media all and (min-width: $screen-sm) {
                        max-width: 153px;
                      }
                    }
                  }

                  .j-meta {
                    display: none;
                  }
                }

                // Feed item with an image
                &.image-post,
                &.j-video {
                  .j-poster {
                    padding-left: 103px;
                    @media all and (min-width: $screen-sm) {
                      padding-left: 193px;
                    }
                  }

                  .j-text .j-message {
                    margin-left: 10px;
                    // max-width: 71%;
                    @media all and (min-width: $screen-sm) {
                      max-width: 324px;
                      margin-left: 20px;
                    }
                  }
                }

                // Feed item with just text
                &.words {
                  .j-poster {
                    padding-left: 20px;
                    @media all and (min-width: $screen-sm) {
                      padding-left: 20px;
                    }
                  }

                  .j-text .j-message {
                    @media all and (min-width: $screen-sm) {
                      max-width: 493px;
                    }
                  }
                }

                &.juicer {
                  display: none;
                }

                &:hover {
                  background: $gradient-1;
                }
              }
            }

            li::before {
              content: "";
              display: none;
            }
          }

          .j-paginate {
            display: none;
          }
        }
      }
    }
  }
}
