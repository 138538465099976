@charset "UTF-8";
/*!
 * Font Awesome Pro 6.7.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2024 Fonticons, Inc.
 */
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&display=block");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap");
.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: var(--fa-style, 900); }

.fas,
.fass,
.far,
.fasr,
.fal,
.fasl,
.fat,
.fast,
.fad,
.fadr,
.fadl,
.fadt,
.fasds,
.fasdr,
.fasdl,
.fasdt,
.fab,
.fa-solid,
.fa-regular,
.fa-light,
.fa-thin,
.fa-brands,
.fa-classic,
.fa-duotone,
.fa-sharp,
.fa-sharp-duotone,
.fa {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto; }

.fas::before,
.fass::before,
.far::before,
.fasr::before,
.fal::before,
.fasl::before,
.fat::before,
.fast::before,
.fad::before,
.fadr::before,
.fadl::before,
.fadt::before,
.fasds::before,
.fasdr::before,
.fasdl::before,
.fasdt::before,
.fab::before,
.fa-solid::before,
.fa-regular::before,
.fa-light::before,
.fa-thin::before,
.fa-brands::before,
.fa-classic::before,
.fa-duotone::before,
.fa-sharp::before,
.fa-sharp-duotone::before,
.fa::before {
  content: var(--fa); }

.fad::after,
.fa-duotone.fa-solid::after,
.fa-duotone::after,
.fadr::after,
.fa-duotone.fa-regular::after,
.fadl::after,
.fa-duotone.fa-light::after,
.fadt::after,
.fa-duotone.fa-thin::after,
.fasds::after,
.fa-sharp-duotone.fa-solid::after,
.fa-sharp-duotone::after,
.fasdr::after,
.fa-sharp-duotone.fa-regular::after,
.fasdl::after,
.fa-sharp-duotone.fa-light::after,
.fasdt::after,
.fa-sharp-duotone.fa-thin::after {
  content: var(--fa--fa); }

.fa-classic.fa-duotone {
  font-family: 'Font Awesome 6 Duotone'; }

.fass,
.fa-sharp {
  font-weight: 900; }

.fad,
.fa-duotone {
  font-weight: 900; }

.fasds,
.fa-sharp-duotone {
  font-weight: 900; }

.fa-classic,
.fas,
.fa-solid,
.far,
.fa-regular,
.fal,
.fa-light,
.fat,
.fa-thin {
  font-family: 'Font Awesome 6 Pro'; }

.fa-duotone,
.fad,
.fadr,
.fadl,
.fadt {
  font-family: 'Font Awesome 6 Duotone'; }

.fa-brands,
.fab {
  font-family: 'Font Awesome 6 Brands'; }

.fa-sharp,
.fass,
.fasr,
.fasl,
.fast {
  font-family: 'Font Awesome 6 Sharp'; }

.fa-sharp-duotone,
.fasds,
.fasdr,
.fasdl,
.fasdt {
  font-family: 'Font Awesome 6 Sharp Duotone'; }

.fa-1x {
  font-size: 1em; }

.fa-2x {
  font-size: 2em; }

.fa-3x {
  font-size: 3em; }

.fa-4x {
  font-size: 4em; }

.fa-5x {
  font-size: 5em; }

.fa-6x {
  font-size: 6em; }

.fa-7x {
  font-size: 7em; }

.fa-8x {
  font-size: 8em; }

.fa-9x {
  font-size: 9em; }

.fa-10x {
  font-size: 10em; }

.fa-2xs {
  font-size: 0.625em;
  line-height: 0.1em;
  vertical-align: 0.225em; }

.fa-xs {
  font-size: 0.75em;
  line-height: 0.0833333337em;
  vertical-align: 0.125em; }

.fa-sm {
  font-size: 0.875em;
  line-height: 0.0714285718em;
  vertical-align: 0.0535714295em; }

.fa-lg {
  font-size: 1.25em;
  line-height: 0.05em;
  vertical-align: -0.075em; }

.fa-xl {
  font-size: 1.5em;
  line-height: 0.0416666682em;
  vertical-align: -0.125em; }

.fa-2xl {
  font-size: 2em;
  line-height: 0.03125em;
  vertical-align: -0.1875em; }

.fa-fw {
  text-align: center;
  width: 1.25em; }

.fa-ul {
  list-style-type: none;
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0; }
  .fa-ul > li {
    position: relative; }

.fa-li {
  left: calc(-1 * var(--fa-li-width, 2em));
  position: absolute;
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit; }

.fa-border {
  border-color: var(--fa-border-color, #eee);
  border-radius: var(--fa-border-radius, 0.1em);
  border-style: var(--fa-border-style, solid);
  border-width: var(--fa-border-width, 0.08em);
  padding: var(--fa-border-padding, 0.2em 0.25em 0.15em); }

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, 0.3em); }

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, 0.3em); }

.fa-beat {
  animation-name: fa-beat;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out); }

.fa-bounce {
  animation-name: fa-bounce;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1)); }

.fa-fade {
  animation-name: fa-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1)); }

.fa-beat-fade {
  animation-name: fa-beat-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1)); }

.fa-flip {
  animation-name: fa-flip;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out); }

.fa-shake {
  animation-name: fa-shake;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear); }

.fa-spin {
  animation-name: fa-spin;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 2s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear); }

.fa-spin-reverse {
  --fa-animation-direction: reverse; }

.fa-pulse,
.fa-spin-pulse {
  animation-name: fa-spin;
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, steps(8)); }

@media (prefers-reduced-motion: reduce) {
  .fa-beat,
  .fa-bounce,
  .fa-fade,
  .fa-beat-fade,
  .fa-flip,
  .fa-pulse,
  .fa-shake,
  .fa-spin,
  .fa-spin-pulse {
    animation-delay: -1ms;
    animation-duration: 1ms;
    animation-iteration-count: 1;
    transition-delay: 0s;
    transition-duration: 0s; } }

@keyframes fa-beat {
  0%, 90% {
    transform: scale(1); }
  45% {
    transform: scale(var(--fa-beat-scale, 1.25)); } }

@keyframes fa-bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0); }
  30% {
    transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em)); }
  50% {
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em)); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, 0.4); } }

@keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, 0.4);
    transform: scale(1); }
  50% {
    opacity: 1;
    transform: scale(var(--fa-beat-fade-scale, 1.125)); } }

@keyframes fa-flip {
  50% {
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg)); } }

@keyframes fa-shake {
  0% {
    transform: rotate(-15deg); }
  4% {
    transform: rotate(15deg); }
  8%, 24% {
    transform: rotate(-18deg); }
  12%, 28% {
    transform: rotate(18deg); }
  16% {
    transform: rotate(-22deg); }
  20% {
    transform: rotate(22deg); }
  32% {
    transform: rotate(-12deg); }
  36% {
    transform: rotate(12deg); }
  40%, 100% {
    transform: rotate(0deg); } }

@keyframes fa-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.fa-rotate-90 {
  transform: rotate(90deg); }

.fa-rotate-180 {
  transform: rotate(180deg); }

.fa-rotate-270 {
  transform: rotate(270deg); }

.fa-flip-horizontal {
  transform: scale(-1, 1); }

.fa-flip-vertical {
  transform: scale(1, -1); }

.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1, -1); }

.fa-rotate-by {
  transform: rotate(var(--fa-rotate-angle, 0)); }

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em; }

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: var(--fa-stack-z-index, auto); }

.fa-stack-1x {
  line-height: inherit; }

.fa-stack-2x {
  font-size: 2em; }

.fa-inverse {
  color: var(--fa-inverse, #fff); }

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-0 {
  --fa: "\30";
  --fa--fa: "\30\30"; }

.fa-1 {
  --fa: "\31";
  --fa--fa: "\31\31"; }

.fa-2 {
  --fa: "\32";
  --fa--fa: "\32\32"; }

.fa-3 {
  --fa: "\33";
  --fa--fa: "\33\33"; }

.fa-4 {
  --fa: "\34";
  --fa--fa: "\34\34"; }

.fa-5 {
  --fa: "\35";
  --fa--fa: "\35\35"; }

.fa-6 {
  --fa: "\36";
  --fa--fa: "\36\36"; }

.fa-7 {
  --fa: "\37";
  --fa--fa: "\37\37"; }

.fa-8 {
  --fa: "\38";
  --fa--fa: "\38\38"; }

.fa-9 {
  --fa: "\39";
  --fa--fa: "\39\39"; }

.fa-fill-drip {
  --fa: "\f576";
  --fa--fa: "\f576\f576"; }

.fa-arrows-to-circle {
  --fa: "\e4bd";
  --fa--fa: "\e4bd\e4bd"; }

.fa-circle-chevron-right {
  --fa: "\f138";
  --fa--fa: "\f138\f138"; }

.fa-chevron-circle-right {
  --fa: "\f138";
  --fa--fa: "\f138\f138"; }

.fa-wagon-covered {
  --fa: "\f8ee";
  --fa--fa: "\f8ee\f8ee"; }

.fa-line-height {
  --fa: "\f871";
  --fa--fa: "\f871\f871"; }

.fa-bagel {
  --fa: "\e3d7";
  --fa--fa: "\e3d7\e3d7"; }

.fa-transporter-7 {
  --fa: "\e2a8";
  --fa--fa: "\e2a8\e2a8"; }

.fa-at {
  --fa: "\40";
  --fa--fa: "\40\40"; }

.fa-rectangles-mixed {
  --fa: "\e323";
  --fa--fa: "\e323\e323"; }

.fa-phone-arrow-up-right {
  --fa: "\e224";
  --fa--fa: "\e224\e224"; }

.fa-phone-arrow-up {
  --fa: "\e224";
  --fa--fa: "\e224\e224"; }

.fa-phone-outgoing {
  --fa: "\e224";
  --fa--fa: "\e224\e224"; }

.fa-trash-can {
  --fa: "\f2ed";
  --fa--fa: "\f2ed\f2ed"; }

.fa-trash-alt {
  --fa: "\f2ed";
  --fa--fa: "\f2ed\f2ed"; }

.fa-circle-l {
  --fa: "\e114";
  --fa--fa: "\e114\e114"; }

.fa-head-side-goggles {
  --fa: "\f6ea";
  --fa--fa: "\f6ea\f6ea"; }

.fa-head-vr {
  --fa: "\f6ea";
  --fa--fa: "\f6ea\f6ea"; }

.fa-text-height {
  --fa: "\f034";
  --fa--fa: "\f034\f034"; }

.fa-user-xmark {
  --fa: "\f235";
  --fa--fa: "\f235\f235"; }

.fa-user-times {
  --fa: "\f235";
  --fa--fa: "\f235\f235"; }

.fa-face-hand-yawn {
  --fa: "\e379";
  --fa--fa: "\e379\e379"; }

.fa-gauge-simple-min {
  --fa: "\f62d";
  --fa--fa: "\f62d\f62d"; }

.fa-tachometer-slowest {
  --fa: "\f62d";
  --fa--fa: "\f62d\f62d"; }

.fa-stethoscope {
  --fa: "\f0f1";
  --fa--fa: "\f0f1\f0f1"; }

.fa-coffin {
  --fa: "\f6c6";
  --fa--fa: "\f6c6\f6c6"; }

.fa-message {
  --fa: "\f27a";
  --fa--fa: "\f27a\f27a"; }

.fa-comment-alt {
  --fa: "\f27a";
  --fa--fa: "\f27a\f27a"; }

.fa-salad {
  --fa: "\f81e";
  --fa--fa: "\f81e\f81e"; }

.fa-bowl-salad {
  --fa: "\f81e";
  --fa--fa: "\f81e\f81e"; }

.fa-info {
  --fa: "\f129";
  --fa--fa: "\f129\f129"; }

.fa-robot-astromech {
  --fa: "\e2d2";
  --fa--fa: "\e2d2\e2d2"; }

.fa-ring-diamond {
  --fa: "\e5ab";
  --fa--fa: "\e5ab\e5ab"; }

.fa-fondue-pot {
  --fa: "\e40d";
  --fa--fa: "\e40d\e40d"; }

.fa-theta {
  --fa: "\f69e";
  --fa--fa: "\f69e\f69e"; }

.fa-face-hand-peeking {
  --fa: "\e481";
  --fa--fa: "\e481\e481"; }

.fa-square-user {
  --fa: "\e283";
  --fa--fa: "\e283\e283"; }

.fa-down-left-and-up-right-to-center {
  --fa: "\f422";
  --fa--fa: "\f422\f422"; }

.fa-compress-alt {
  --fa: "\f422";
  --fa--fa: "\f422\f422"; }

.fa-explosion {
  --fa: "\e4e9";
  --fa--fa: "\e4e9\e4e9"; }

.fa-file-lines {
  --fa: "\f15c";
  --fa--fa: "\f15c\f15c"; }

.fa-file-alt {
  --fa: "\f15c";
  --fa--fa: "\f15c\f15c"; }

.fa-file-text {
  --fa: "\f15c";
  --fa--fa: "\f15c\f15c"; }

.fa-wave-square {
  --fa: "\f83e";
  --fa--fa: "\f83e\f83e"; }

.fa-ring {
  --fa: "\f70b";
  --fa--fa: "\f70b\f70b"; }

.fa-building-un {
  --fa: "\e4d9";
  --fa--fa: "\e4d9\e4d9"; }

.fa-dice-three {
  --fa: "\f527";
  --fa--fa: "\f527\f527"; }

.fa-tire-pressure-warning {
  --fa: "\f633";
  --fa--fa: "\f633\f633"; }

.fa-wifi-fair {
  --fa: "\f6ab";
  --fa--fa: "\f6ab\f6ab"; }

.fa-wifi-2 {
  --fa: "\f6ab";
  --fa--fa: "\f6ab\f6ab"; }

.fa-calendar-days {
  --fa: "\f073";
  --fa--fa: "\f073\f073"; }

.fa-calendar-alt {
  --fa: "\f073";
  --fa--fa: "\f073\f073"; }

.fa-mp3-player {
  --fa: "\f8ce";
  --fa--fa: "\f8ce\f8ce"; }

.fa-anchor-circle-check {
  --fa: "\e4aa";
  --fa--fa: "\e4aa\e4aa"; }

.fa-tally-4 {
  --fa: "\e297";
  --fa--fa: "\e297\e297"; }

.fa-rectangle-history {
  --fa: "\e4a2";
  --fa--fa: "\e4a2\e4a2"; }

.fa-building-circle-arrow-right {
  --fa: "\e4d1";
  --fa--fa: "\e4d1\e4d1"; }

.fa-volleyball {
  --fa: "\f45f";
  --fa--fa: "\f45f\f45f"; }

.fa-volleyball-ball {
  --fa: "\f45f";
  --fa--fa: "\f45f\f45f"; }

.fa-sun-haze {
  --fa: "\f765";
  --fa--fa: "\f765\f765"; }

.fa-text-size {
  --fa: "\f894";
  --fa--fa: "\f894\f894"; }

.fa-ufo {
  --fa: "\e047";
  --fa--fa: "\e047\e047"; }

.fa-fork {
  --fa: "\f2e3";
  --fa--fa: "\f2e3\f2e3"; }

.fa-utensil-fork {
  --fa: "\f2e3";
  --fa--fa: "\f2e3\f2e3"; }

.fa-arrows-up-to-line {
  --fa: "\e4c2";
  --fa--fa: "\e4c2\e4c2"; }

.fa-mobile-signal {
  --fa: "\e1ef";
  --fa--fa: "\e1ef\e1ef"; }

.fa-barcode-scan {
  --fa: "\f465";
  --fa--fa: "\f465\f465"; }

.fa-sort-down {
  --fa: "\f0dd";
  --fa--fa: "\f0dd\f0dd"; }

.fa-sort-desc {
  --fa: "\f0dd";
  --fa--fa: "\f0dd\f0dd"; }

.fa-folder-arrow-down {
  --fa: "\e053";
  --fa--fa: "\e053\e053"; }

.fa-folder-download {
  --fa: "\e053";
  --fa--fa: "\e053\e053"; }

.fa-circle-minus {
  --fa: "\f056";
  --fa--fa: "\f056\f056"; }

.fa-minus-circle {
  --fa: "\f056";
  --fa--fa: "\f056\f056"; }

.fa-face-icicles {
  --fa: "\e37c";
  --fa--fa: "\e37c\e37c"; }

.fa-shovel {
  --fa: "\f713";
  --fa--fa: "\f713\f713"; }

.fa-door-open {
  --fa: "\f52b";
  --fa--fa: "\f52b\f52b"; }

.fa-films {
  --fa: "\e17a";
  --fa--fa: "\e17a\e17a"; }

.fa-right-from-bracket {
  --fa: "\f2f5";
  --fa--fa: "\f2f5\f2f5"; }

.fa-sign-out-alt {
  --fa: "\f2f5";
  --fa--fa: "\f2f5\f2f5"; }

.fa-face-glasses {
  --fa: "\e377";
  --fa--fa: "\e377\e377"; }

.fa-nfc {
  --fa: "\e1f7";
  --fa--fa: "\e1f7\e1f7"; }

.fa-atom {
  --fa: "\f5d2";
  --fa--fa: "\f5d2\f5d2"; }

.fa-soap {
  --fa: "\e06e";
  --fa--fa: "\e06e\e06e"; }

.fa-icons {
  --fa: "\f86d";
  --fa--fa: "\f86d\f86d"; }

.fa-heart-music-camera-bolt {
  --fa: "\f86d";
  --fa--fa: "\f86d\f86d"; }

.fa-microphone-lines-slash {
  --fa: "\f539";
  --fa--fa: "\f539\f539"; }

.fa-microphone-alt-slash {
  --fa: "\f539";
  --fa--fa: "\f539\f539"; }

.fa-closed-captioning-slash {
  --fa: "\e135";
  --fa--fa: "\e135\e135"; }

.fa-calculator-simple {
  --fa: "\f64c";
  --fa--fa: "\f64c\f64c"; }

.fa-calculator-alt {
  --fa: "\f64c";
  --fa--fa: "\f64c\f64c"; }

.fa-bridge-circle-check {
  --fa: "\e4c9";
  --fa--fa: "\e4c9\e4c9"; }

.fa-sliders-up {
  --fa: "\f3f1";
  --fa--fa: "\f3f1\f3f1"; }

.fa-sliders-v {
  --fa: "\f3f1";
  --fa--fa: "\f3f1\f3f1"; }

.fa-location-minus {
  --fa: "\f609";
  --fa--fa: "\f609\f609"; }

.fa-map-marker-minus {
  --fa: "\f609";
  --fa--fa: "\f609\f609"; }

.fa-pump-medical {
  --fa: "\e06a";
  --fa--fa: "\e06a\e06a"; }

.fa-fingerprint {
  --fa: "\f577";
  --fa--fa: "\f577\f577"; }

.fa-ski-boot {
  --fa: "\e3cc";
  --fa--fa: "\e3cc\e3cc"; }

.fa-standard-definition {
  --fa: "\e28a";
  --fa--fa: "\e28a\e28a"; }

.fa-rectangle-sd {
  --fa: "\e28a";
  --fa--fa: "\e28a\e28a"; }

.fa-h1 {
  --fa: "\f313";
  --fa--fa: "\f313\f313"; }

.fa-hand-point-right {
  --fa: "\f0a4";
  --fa--fa: "\f0a4\f0a4"; }

.fa-magnifying-glass-location {
  --fa: "\f689";
  --fa--fa: "\f689\f689"; }

.fa-search-location {
  --fa: "\f689";
  --fa--fa: "\f689\f689"; }

.fa-message-bot {
  --fa: "\e3b8";
  --fa--fa: "\e3b8\e3b8"; }

.fa-forward-step {
  --fa: "\f051";
  --fa--fa: "\f051\f051"; }

.fa-step-forward {
  --fa: "\f051";
  --fa--fa: "\f051\f051"; }

.fa-face-smile-beam {
  --fa: "\f5b8";
  --fa--fa: "\f5b8\f5b8"; }

.fa-smile-beam {
  --fa: "\f5b8";
  --fa--fa: "\f5b8\f5b8"; }

.fa-light-ceiling {
  --fa: "\e016";
  --fa--fa: "\e016\e016"; }

.fa-message-exclamation {
  --fa: "\f4a5";
  --fa--fa: "\f4a5\f4a5"; }

.fa-comment-alt-exclamation {
  --fa: "\f4a5";
  --fa--fa: "\f4a5\f4a5"; }

.fa-bowl-scoop {
  --fa: "\e3de";
  --fa--fa: "\e3de\e3de"; }

.fa-bowl-shaved-ice {
  --fa: "\e3de";
  --fa--fa: "\e3de\e3de"; }

.fa-square-x {
  --fa: "\e286";
  --fa--fa: "\e286\e286"; }

.fa-building-memo {
  --fa: "\e61e";
  --fa--fa: "\e61e\e61e"; }

.fa-utility-pole-double {
  --fa: "\e2c4";
  --fa--fa: "\e2c4\e2c4"; }

.fa-flag-checkered {
  --fa: "\f11e";
  --fa--fa: "\f11e\f11e"; }

.fa-chevrons-up {
  --fa: "\f325";
  --fa--fa: "\f325\f325"; }

.fa-chevron-double-up {
  --fa: "\f325";
  --fa--fa: "\f325\f325"; }

.fa-football {
  --fa: "\f44e";
  --fa--fa: "\f44e\f44e"; }

.fa-football-ball {
  --fa: "\f44e";
  --fa--fa: "\f44e\f44e"; }

.fa-user-vneck {
  --fa: "\e461";
  --fa--fa: "\e461\e461"; }

.fa-school-circle-exclamation {
  --fa: "\e56c";
  --fa--fa: "\e56c\e56c"; }

.fa-crop {
  --fa: "\f125";
  --fa--fa: "\f125\f125"; }

.fa-angles-down {
  --fa: "\f103";
  --fa--fa: "\f103\f103"; }

.fa-angle-double-down {
  --fa: "\f103";
  --fa--fa: "\f103\f103"; }

.fa-users-rectangle {
  --fa: "\e594";
  --fa--fa: "\e594\e594"; }

.fa-people-roof {
  --fa: "\e537";
  --fa--fa: "\e537\e537"; }

.fa-square-arrow-right {
  --fa: "\f33b";
  --fa--fa: "\f33b\f33b"; }

.fa-arrow-square-right {
  --fa: "\f33b";
  --fa--fa: "\f33b\f33b"; }

.fa-location-plus {
  --fa: "\f60a";
  --fa--fa: "\f60a\f60a"; }

.fa-map-marker-plus {
  --fa: "\f60a";
  --fa--fa: "\f60a\f60a"; }

.fa-lightbulb-exclamation-on {
  --fa: "\e1ca";
  --fa--fa: "\e1ca\e1ca"; }

.fa-people-line {
  --fa: "\e534";
  --fa--fa: "\e534\e534"; }

.fa-beer-mug-empty {
  --fa: "\f0fc";
  --fa--fa: "\f0fc\f0fc"; }

.fa-beer {
  --fa: "\f0fc";
  --fa--fa: "\f0fc\f0fc"; }

.fa-carpool {
  --fa: "\e69c";
  --fa--fa: "\e69c\e69c"; }

.fa-car-people {
  --fa: "\e69c";
  --fa--fa: "\e69c\e69c"; }

.fa-crate-empty {
  --fa: "\e151";
  --fa--fa: "\e151\e151"; }

.fa-diagram-predecessor {
  --fa: "\e477";
  --fa--fa: "\e477\e477"; }

.fa-transporter {
  --fa: "\e042";
  --fa--fa: "\e042\e042"; }

.fa-calendar-circle-user {
  --fa: "\e471";
  --fa--fa: "\e471\e471"; }

.fa-arrow-up-long {
  --fa: "\f176";
  --fa--fa: "\f176\f176"; }

.fa-long-arrow-up {
  --fa: "\f176";
  --fa--fa: "\f176\f176"; }

.fa-person-carry-box {
  --fa: "\f4cf";
  --fa--fa: "\f4cf\f4cf"; }

.fa-person-carry {
  --fa: "\f4cf";
  --fa--fa: "\f4cf\f4cf"; }

.fa-fire-flame-simple {
  --fa: "\f46a";
  --fa--fa: "\f46a\f46a"; }

.fa-burn {
  --fa: "\f46a";
  --fa--fa: "\f46a\f46a"; }

.fa-person {
  --fa: "\f183";
  --fa--fa: "\f183\f183"; }

.fa-male {
  --fa: "\f183";
  --fa--fa: "\f183\f183"; }

.fa-laptop {
  --fa: "\f109";
  --fa--fa: "\f109\f109"; }

.fa-file-csv {
  --fa: "\f6dd";
  --fa--fa: "\f6dd\f6dd"; }

.fa-menorah {
  --fa: "\f676";
  --fa--fa: "\f676\f676"; }

.fa-union {
  --fa: "\f6a2";
  --fa--fa: "\f6a2\f6a2"; }

.fa-chevrons-left {
  --fa: "\f323";
  --fa--fa: "\f323\f323"; }

.fa-chevron-double-left {
  --fa: "\f323";
  --fa--fa: "\f323\f323"; }

.fa-circle-heart {
  --fa: "\f4c7";
  --fa--fa: "\f4c7\f4c7"; }

.fa-heart-circle {
  --fa: "\f4c7";
  --fa--fa: "\f4c7\f4c7"; }

.fa-truck-plane {
  --fa: "\e58f";
  --fa--fa: "\e58f\e58f"; }

.fa-record-vinyl {
  --fa: "\f8d9";
  --fa--fa: "\f8d9\f8d9"; }

.fa-bring-forward {
  --fa: "\f856";
  --fa--fa: "\f856\f856"; }

.fa-square-p {
  --fa: "\e279";
  --fa--fa: "\e279\e279"; }

.fa-face-grin-stars {
  --fa: "\f587";
  --fa--fa: "\f587\f587"; }

.fa-grin-stars {
  --fa: "\f587";
  --fa--fa: "\f587\f587"; }

.fa-sigma {
  --fa: "\f68b";
  --fa--fa: "\f68b\f68b"; }

.fa-camera-movie {
  --fa: "\f8a9";
  --fa--fa: "\f8a9\f8a9"; }

.fa-bong {
  --fa: "\f55c";
  --fa--fa: "\f55c\f55c"; }

.fa-clarinet {
  --fa: "\f8ad";
  --fa--fa: "\f8ad\f8ad"; }

.fa-truck-flatbed {
  --fa: "\e2b6";
  --fa--fa: "\e2b6\e2b6"; }

.fa-spaghetti-monster-flying {
  --fa: "\f67b";
  --fa--fa: "\f67b\f67b"; }

.fa-pastafarianism {
  --fa: "\f67b";
  --fa--fa: "\f67b\f67b"; }

.fa-arrow-down-up-across-line {
  --fa: "\e4af";
  --fa--fa: "\e4af\e4af"; }

.fa-arrows-rotate-reverse {
  --fa: "\e630";
  --fa--fa: "\e630\e630"; }

.fa-leaf-heart {
  --fa: "\f4cb";
  --fa--fa: "\f4cb\f4cb"; }

.fa-house-building {
  --fa: "\e1b1";
  --fa--fa: "\e1b1\e1b1"; }

.fa-cheese-swiss {
  --fa: "\f7f0";
  --fa--fa: "\f7f0\f7f0"; }

.fa-spoon {
  --fa: "\f2e5";
  --fa--fa: "\f2e5\f2e5"; }

.fa-utensil-spoon {
  --fa: "\f2e5";
  --fa--fa: "\f2e5\f2e5"; }

.fa-jar-wheat {
  --fa: "\e517";
  --fa--fa: "\e517\e517"; }

.fa-envelopes-bulk {
  --fa: "\f674";
  --fa--fa: "\f674\f674"; }

.fa-mail-bulk {
  --fa: "\f674";
  --fa--fa: "\f674\f674"; }

.fa-file-circle-exclamation {
  --fa: "\e4eb";
  --fa--fa: "\e4eb\e4eb"; }

.fa-bow-arrow {
  --fa: "\f6b9";
  --fa--fa: "\f6b9\f6b9"; }

.fa-cart-xmark {
  --fa: "\e0dd";
  --fa--fa: "\e0dd\e0dd"; }

.fa-hexagon-xmark {
  --fa: "\f2ee";
  --fa--fa: "\f2ee\f2ee"; }

.fa-times-hexagon {
  --fa: "\f2ee";
  --fa--fa: "\f2ee\f2ee"; }

.fa-xmark-hexagon {
  --fa: "\f2ee";
  --fa--fa: "\f2ee\f2ee"; }

.fa-circle-h {
  --fa: "\f47e";
  --fa--fa: "\f47e\f47e"; }

.fa-hospital-symbol {
  --fa: "\f47e";
  --fa--fa: "\f47e\f47e"; }

.fa-merge {
  --fa: "\e526";
  --fa--fa: "\e526\e526"; }

.fa-pager {
  --fa: "\f815";
  --fa--fa: "\f815\f815"; }

.fa-cart-minus {
  --fa: "\e0db";
  --fa--fa: "\e0db\e0db"; }

.fa-address-book {
  --fa: "\f2b9";
  --fa--fa: "\f2b9\f2b9"; }

.fa-contact-book {
  --fa: "\f2b9";
  --fa--fa: "\f2b9\f2b9"; }

.fa-pan-frying {
  --fa: "\e42c";
  --fa--fa: "\e42c\e42c"; }

.fa-grid {
  --fa: "\e195";
  --fa--fa: "\e195\e195"; }

.fa-grid-3 {
  --fa: "\e195";
  --fa--fa: "\e195\e195"; }

.fa-football-helmet {
  --fa: "\f44f";
  --fa--fa: "\f44f\f44f"; }

.fa-hand-love {
  --fa: "\e1a5";
  --fa--fa: "\e1a5\e1a5"; }

.fa-trees {
  --fa: "\f724";
  --fa--fa: "\f724\f724"; }

.fa-strikethrough {
  --fa: "\f0cc";
  --fa--fa: "\f0cc\f0cc"; }

.fa-page {
  --fa: "\e428";
  --fa--fa: "\e428\e428"; }

.fa-k {
  --fa: "\4b";
  --fa--fa: "\4b\4b"; }

.fa-diagram-previous {
  --fa: "\e478";
  --fa--fa: "\e478\e478"; }

.fa-gauge-min {
  --fa: "\f628";
  --fa--fa: "\f628\f628"; }

.fa-tachometer-alt-slowest {
  --fa: "\f628";
  --fa--fa: "\f628\f628"; }

.fa-folder-grid {
  --fa: "\e188";
  --fa--fa: "\e188\e188"; }

.fa-eggplant {
  --fa: "\e16c";
  --fa--fa: "\e16c\e16c"; }

.fa-excavator {
  --fa: "\e656";
  --fa--fa: "\e656\e656"; }

.fa-ram {
  --fa: "\f70a";
  --fa--fa: "\f70a\f70a"; }

.fa-landmark-flag {
  --fa: "\e51c";
  --fa--fa: "\e51c\e51c"; }

.fa-lips {
  --fa: "\f600";
  --fa--fa: "\f600\f600"; }

.fa-pencil {
  --fa: "\f303";
  --fa--fa: "\f303\f303"; }

.fa-pencil-alt {
  --fa: "\f303";
  --fa--fa: "\f303\f303"; }

.fa-backward {
  --fa: "\f04a";
  --fa--fa: "\f04a\f04a"; }

.fa-caret-right {
  --fa: "\f0da";
  --fa--fa: "\f0da\f0da"; }

.fa-comments {
  --fa: "\f086";
  --fa--fa: "\f086\f086"; }

.fa-paste {
  --fa: "\f0ea";
  --fa--fa: "\f0ea\f0ea"; }

.fa-file-clipboard {
  --fa: "\f0ea";
  --fa--fa: "\f0ea\f0ea"; }

.fa-desktop-arrow-down {
  --fa: "\e155";
  --fa--fa: "\e155\e155"; }

.fa-code-pull-request {
  --fa: "\e13c";
  --fa--fa: "\e13c\e13c"; }

.fa-pumpkin {
  --fa: "\f707";
  --fa--fa: "\f707\f707"; }

.fa-clipboard-list {
  --fa: "\f46d";
  --fa--fa: "\f46d\f46d"; }

.fa-pen-field {
  --fa: "\e211";
  --fa--fa: "\e211\e211"; }

.fa-chart-sine {
  --fa: "\e69d";
  --fa--fa: "\e69d\e69d"; }

.fa-blueberries {
  --fa: "\e2e8";
  --fa--fa: "\e2e8\e2e8"; }

.fa-truck-ramp-box {
  --fa: "\f4de";
  --fa--fa: "\f4de\f4de"; }

.fa-truck-loading {
  --fa: "\f4de";
  --fa--fa: "\f4de\f4de"; }

.fa-note {
  --fa: "\e1ff";
  --fa--fa: "\e1ff\e1ff"; }

.fa-arrow-down-to-square {
  --fa: "\e096";
  --fa--fa: "\e096\e096"; }

.fa-user-check {
  --fa: "\f4fc";
  --fa--fa: "\f4fc\f4fc"; }

.fa-cloud-xmark {
  --fa: "\e35f";
  --fa--fa: "\e35f\e35f"; }

.fa-vial-virus {
  --fa: "\e597";
  --fa--fa: "\e597\e597"; }

.fa-book-blank {
  --fa: "\f5d9";
  --fa--fa: "\f5d9\f5d9"; }

.fa-book-alt {
  --fa: "\f5d9";
  --fa--fa: "\f5d9\f5d9"; }

.fa-golf-flag-hole {
  --fa: "\e3ac";
  --fa--fa: "\e3ac\e3ac"; }

.fa-message-arrow-down {
  --fa: "\e1db";
  --fa--fa: "\e1db\e1db"; }

.fa-comment-alt-arrow-down {
  --fa: "\e1db";
  --fa--fa: "\e1db\e1db"; }

.fa-face-unamused {
  --fa: "\e39f";
  --fa--fa: "\e39f\e39f"; }

.fa-sheet-plastic {
  --fa: "\e571";
  --fa--fa: "\e571\e571"; }

.fa-circle-9 {
  --fa: "\e0f6";
  --fa--fa: "\e0f6\e0f6"; }

.fa-blog {
  --fa: "\f781";
  --fa--fa: "\f781\f781"; }

.fa-user-ninja {
  --fa: "\f504";
  --fa--fa: "\f504\f504"; }

.fa-pencil-slash {
  --fa: "\e215";
  --fa--fa: "\e215\e215"; }

.fa-bowling-pins {
  --fa: "\f437";
  --fa--fa: "\f437\f437"; }

.fa-person-arrow-up-from-line {
  --fa: "\e539";
  --fa--fa: "\e539\e539"; }

.fa-down-right {
  --fa: "\e16b";
  --fa--fa: "\e16b\e16b"; }

.fa-scroll-torah {
  --fa: "\f6a0";
  --fa--fa: "\f6a0\f6a0"; }

.fa-torah {
  --fa: "\f6a0";
  --fa--fa: "\f6a0\f6a0"; }

.fa-webhook {
  --fa: "\e5d5";
  --fa--fa: "\e5d5\e5d5"; }

.fa-blinds-open {
  --fa: "\f8fc";
  --fa--fa: "\f8fc\f8fc"; }

.fa-fence {
  --fa: "\e303";
  --fa--fa: "\e303\e303"; }

.fa-up {
  --fa: "\f357";
  --fa--fa: "\f357\f357"; }

.fa-arrow-alt-up {
  --fa: "\f357";
  --fa--fa: "\f357\f357"; }

.fa-broom-ball {
  --fa: "\f458";
  --fa--fa: "\f458\f458"; }

.fa-quidditch {
  --fa: "\f458";
  --fa--fa: "\f458\f458"; }

.fa-quidditch-broom-ball {
  --fa: "\f458";
  --fa--fa: "\f458\f458"; }

.fa-drumstick {
  --fa: "\f6d6";
  --fa--fa: "\f6d6\f6d6"; }

.fa-square-v {
  --fa: "\e284";
  --fa--fa: "\e284\e284"; }

.fa-face-awesome {
  --fa: "\e409";
  --fa--fa: "\e409\e409"; }

.fa-gave-dandy {
  --fa: "\e409";
  --fa--fa: "\e409\e409"; }

.fa-dial-off {
  --fa: "\e162";
  --fa--fa: "\e162\e162"; }

.fa-toggle-off {
  --fa: "\f204";
  --fa--fa: "\f204\f204"; }

.fa-face-smile-horns {
  --fa: "\e391";
  --fa--fa: "\e391\e391"; }

.fa-box-archive {
  --fa: "\f187";
  --fa--fa: "\f187\f187"; }

.fa-archive {
  --fa: "\f187";
  --fa--fa: "\f187\f187"; }

.fa-grapes {
  --fa: "\e306";
  --fa--fa: "\e306\e306"; }

.fa-person-drowning {
  --fa: "\e545";
  --fa--fa: "\e545\e545"; }

.fa-dial-max {
  --fa: "\e15e";
  --fa--fa: "\e15e\e15e"; }

.fa-circle-m {
  --fa: "\e115";
  --fa--fa: "\e115\e115"; }

.fa-calendar-image {
  --fa: "\e0d4";
  --fa--fa: "\e0d4\e0d4"; }

.fa-circle-caret-down {
  --fa: "\f32d";
  --fa--fa: "\f32d\f32d"; }

.fa-caret-circle-down {
  --fa: "\f32d";
  --fa--fa: "\f32d\f32d"; }

.fa-arrow-down-9-1 {
  --fa: "\f886";
  --fa--fa: "\f886\f886"; }

.fa-sort-numeric-desc {
  --fa: "\f886";
  --fa--fa: "\f886\f886"; }

.fa-sort-numeric-down-alt {
  --fa: "\f886";
  --fa--fa: "\f886\f886"; }

.fa-face-grin-tongue-squint {
  --fa: "\f58a";
  --fa--fa: "\f58a\f58a"; }

.fa-grin-tongue-squint {
  --fa: "\f58a";
  --fa--fa: "\f58a\f58a"; }

.fa-shish-kebab {
  --fa: "\f821";
  --fa--fa: "\f821\f821"; }

.fa-spray-can {
  --fa: "\f5bd";
  --fa--fa: "\f5bd\f5bd"; }

.fa-alarm-snooze {
  --fa: "\f845";
  --fa--fa: "\f845\f845"; }

.fa-scarecrow {
  --fa: "\f70d";
  --fa--fa: "\f70d\f70d"; }

.fa-truck-monster {
  --fa: "\f63b";
  --fa--fa: "\f63b\f63b"; }

.fa-gift-card {
  --fa: "\f663";
  --fa--fa: "\f663\f663"; }

.fa-w {
  --fa: "\57";
  --fa--fa: "\57\57"; }

.fa-code-pull-request-draft {
  --fa: "\e3fa";
  --fa--fa: "\e3fa\e3fa"; }

.fa-square-b {
  --fa: "\e264";
  --fa--fa: "\e264\e264"; }

.fa-elephant {
  --fa: "\f6da";
  --fa--fa: "\f6da\f6da"; }

.fa-earth-africa {
  --fa: "\f57c";
  --fa--fa: "\f57c\f57c"; }

.fa-globe-africa {
  --fa: "\f57c";
  --fa--fa: "\f57c\f57c"; }

.fa-rainbow {
  --fa: "\f75b";
  --fa--fa: "\f75b\f75b"; }

.fa-circle-notch {
  --fa: "\f1ce";
  --fa--fa: "\f1ce\f1ce"; }

.fa-tablet-screen-button {
  --fa: "\f3fa";
  --fa--fa: "\f3fa\f3fa"; }

.fa-tablet-alt {
  --fa: "\f3fa";
  --fa--fa: "\f3fa\f3fa"; }

.fa-paw {
  --fa: "\f1b0";
  --fa--fa: "\f1b0\f1b0"; }

.fa-message-question {
  --fa: "\e1e3";
  --fa--fa: "\e1e3\e1e3"; }

.fa-cloud {
  --fa: "\f0c2";
  --fa--fa: "\f0c2\f0c2"; }

.fa-trowel-bricks {
  --fa: "\e58a";
  --fa--fa: "\e58a\e58a"; }

.fa-square-3 {
  --fa: "\e258";
  --fa--fa: "\e258\e258"; }

.fa-face-flushed {
  --fa: "\f579";
  --fa--fa: "\f579\f579"; }

.fa-flushed {
  --fa: "\f579";
  --fa--fa: "\f579\f579"; }

.fa-hospital-user {
  --fa: "\f80d";
  --fa--fa: "\f80d\f80d"; }

.fa-microwave {
  --fa: "\e01b";
  --fa--fa: "\e01b\e01b"; }

.fa-chf-sign {
  --fa: "\e602";
  --fa--fa: "\e602\e602"; }

.fa-tent-arrow-left-right {
  --fa: "\e57f";
  --fa--fa: "\e57f\e57f"; }

.fa-cart-circle-arrow-up {
  --fa: "\e3f0";
  --fa--fa: "\e3f0\e3f0"; }

.fa-trash-clock {
  --fa: "\e2b0";
  --fa--fa: "\e2b0\e2b0"; }

.fa-reflect-both {
  --fa: "\e66f";
  --fa--fa: "\e66f\e66f"; }

.fa-gavel {
  --fa: "\f0e3";
  --fa--fa: "\f0e3\f0e3"; }

.fa-legal {
  --fa: "\f0e3";
  --fa--fa: "\f0e3\f0e3"; }

.fa-sprinkler-ceiling {
  --fa: "\e44c";
  --fa--fa: "\e44c\e44c"; }

.fa-browsers {
  --fa: "\e0cb";
  --fa--fa: "\e0cb\e0cb"; }

.fa-trillium {
  --fa: "\e588";
  --fa--fa: "\e588\e588"; }

.fa-table-cells-unlock {
  --fa: "\e692";
  --fa--fa: "\e692\e692"; }

.fa-music-slash {
  --fa: "\f8d1";
  --fa--fa: "\f8d1\f8d1"; }

.fa-truck-ramp {
  --fa: "\f4e0";
  --fa--fa: "\f4e0\f4e0"; }

.fa-binoculars {
  --fa: "\f1e5";
  --fa--fa: "\f1e5\f1e5"; }

.fa-microphone-slash {
  --fa: "\f131";
  --fa--fa: "\f131\f131"; }

.fa-box-tissue {
  --fa: "\e05b";
  --fa--fa: "\e05b\e05b"; }

.fa-circle-c {
  --fa: "\e101";
  --fa--fa: "\e101\e101"; }

.fa-star-christmas {
  --fa: "\f7d4";
  --fa--fa: "\f7d4\f7d4"; }

.fa-chart-bullet {
  --fa: "\e0e1";
  --fa--fa: "\e0e1\e0e1"; }

.fa-motorcycle {
  --fa: "\f21c";
  --fa--fa: "\f21c\f21c"; }

.fa-tree-christmas {
  --fa: "\f7db";
  --fa--fa: "\f7db\f7db"; }

.fa-tire-flat {
  --fa: "\f632";
  --fa--fa: "\f632\f632"; }

.fa-sunglasses {
  --fa: "\f892";
  --fa--fa: "\f892\f892"; }

.fa-badge {
  --fa: "\f335";
  --fa--fa: "\f335\f335"; }

.fa-message-pen {
  --fa: "\f4a4";
  --fa--fa: "\f4a4\f4a4"; }

.fa-comment-alt-edit {
  --fa: "\f4a4";
  --fa--fa: "\f4a4\f4a4"; }

.fa-message-edit {
  --fa: "\f4a4";
  --fa--fa: "\f4a4\f4a4"; }

.fa-bell-concierge {
  --fa: "\f562";
  --fa--fa: "\f562\f562"; }

.fa-concierge-bell {
  --fa: "\f562";
  --fa--fa: "\f562\f562"; }

.fa-pen-ruler {
  --fa: "\f5ae";
  --fa--fa: "\f5ae\f5ae"; }

.fa-pencil-ruler {
  --fa: "\f5ae";
  --fa--fa: "\f5ae\f5ae"; }

.fa-file-mp3 {
  --fa: "\e648";
  --fa--fa: "\e648\e648"; }

.fa-arrow-progress {
  --fa: "\e5df";
  --fa--fa: "\e5df\e5df"; }

.fa-chess-rook-piece {
  --fa: "\f448";
  --fa--fa: "\f448\f448"; }

.fa-chess-rook-alt {
  --fa: "\f448";
  --fa--fa: "\f448\f448"; }

.fa-square-root {
  --fa: "\f697";
  --fa--fa: "\f697\f697"; }

.fa-album-collection-circle-plus {
  --fa: "\e48e";
  --fa--fa: "\e48e\e48e"; }

.fa-people-arrows {
  --fa: "\e068";
  --fa--fa: "\e068\e068"; }

.fa-people-arrows-left-right {
  --fa: "\e068";
  --fa--fa: "\e068\e068"; }

.fa-sign-post {
  --fa: "\e624";
  --fa--fa: "\e624\e624"; }

.fa-face-angry-horns {
  --fa: "\e368";
  --fa--fa: "\e368\e368"; }

.fa-mars-and-venus-burst {
  --fa: "\e523";
  --fa--fa: "\e523\e523"; }

.fa-tombstone {
  --fa: "\f720";
  --fa--fa: "\f720\f720"; }

.fa-square-caret-right {
  --fa: "\f152";
  --fa--fa: "\f152\f152"; }

.fa-caret-square-right {
  --fa: "\f152";
  --fa--fa: "\f152\f152"; }

.fa-scissors {
  --fa: "\f0c4";
  --fa--fa: "\f0c4\f0c4"; }

.fa-cut {
  --fa: "\f0c4";
  --fa--fa: "\f0c4\f0c4"; }

.fa-list-music {
  --fa: "\f8c9";
  --fa--fa: "\f8c9\f8c9"; }

.fa-sun-plant-wilt {
  --fa: "\e57a";
  --fa--fa: "\e57a\e57a"; }

.fa-toilets-portable {
  --fa: "\e584";
  --fa--fa: "\e584\e584"; }

.fa-hockey-puck {
  --fa: "\f453";
  --fa--fa: "\f453\f453"; }

.fa-mustache {
  --fa: "\e5bc";
  --fa--fa: "\e5bc\e5bc"; }

.fa-hyphen {
  --fa: "\2d";
  --fa--fa: "\2d\2d"; }

.fa-table {
  --fa: "\f0ce";
  --fa--fa: "\f0ce\f0ce"; }

.fa-user-chef {
  --fa: "\e3d2";
  --fa--fa: "\e3d2\e3d2"; }

.fa-message-image {
  --fa: "\e1e0";
  --fa--fa: "\e1e0\e1e0"; }

.fa-comment-alt-image {
  --fa: "\e1e0";
  --fa--fa: "\e1e0\e1e0"; }

.fa-users-medical {
  --fa: "\f830";
  --fa--fa: "\f830\f830"; }

.fa-sensor-triangle-exclamation {
  --fa: "\e029";
  --fa--fa: "\e029\e029"; }

.fa-sensor-alert {
  --fa: "\e029";
  --fa--fa: "\e029\e029"; }

.fa-magnifying-glass-arrow-right {
  --fa: "\e521";
  --fa--fa: "\e521\e521"; }

.fa-tachograph-digital {
  --fa: "\f566";
  --fa--fa: "\f566\f566"; }

.fa-digital-tachograph {
  --fa: "\f566";
  --fa--fa: "\f566\f566"; }

.fa-face-mask {
  --fa: "\e37f";
  --fa--fa: "\e37f\e37f"; }

.fa-pickleball {
  --fa: "\e435";
  --fa--fa: "\e435\e435"; }

.fa-star-sharp-half {
  --fa: "\e28c";
  --fa--fa: "\e28c\e28c"; }

.fa-users-slash {
  --fa: "\e073";
  --fa--fa: "\e073\e073"; }

.fa-clover {
  --fa: "\e139";
  --fa--fa: "\e139\e139"; }

.fa-meat {
  --fa: "\f814";
  --fa--fa: "\f814\f814"; }

.fa-reply {
  --fa: "\f3e5";
  --fa--fa: "\f3e5\f3e5"; }

.fa-mail-reply {
  --fa: "\f3e5";
  --fa--fa: "\f3e5\f3e5"; }

.fa-star-and-crescent {
  --fa: "\f699";
  --fa--fa: "\f699\f699"; }

.fa-empty-set {
  --fa: "\f656";
  --fa--fa: "\f656\f656"; }

.fa-house-fire {
  --fa: "\e50c";
  --fa--fa: "\e50c\e50c"; }

.fa-square-minus {
  --fa: "\f146";
  --fa--fa: "\f146\f146"; }

.fa-minus-square {
  --fa: "\f146";
  --fa--fa: "\f146\f146"; }

.fa-helicopter {
  --fa: "\f533";
  --fa--fa: "\f533\f533"; }

.fa-bird {
  --fa: "\e469";
  --fa--fa: "\e469\e469"; }

.fa-compass {
  --fa: "\f14e";
  --fa--fa: "\f14e\f14e"; }

.fa-square-caret-down {
  --fa: "\f150";
  --fa--fa: "\f150\f150"; }

.fa-caret-square-down {
  --fa: "\f150";
  --fa--fa: "\f150\f150"; }

.fa-heart-half-stroke {
  --fa: "\e1ac";
  --fa--fa: "\e1ac\e1ac"; }

.fa-heart-half-alt {
  --fa: "\e1ac";
  --fa--fa: "\e1ac\e1ac"; }

.fa-file-circle-question {
  --fa: "\e4ef";
  --fa--fa: "\e4ef\e4ef"; }

.fa-truck-utensils {
  --fa: "\e628";
  --fa--fa: "\e628\e628"; }

.fa-laptop-code {
  --fa: "\f5fc";
  --fa--fa: "\f5fc\f5fc"; }

.fa-joystick {
  --fa: "\f8c5";
  --fa--fa: "\f8c5\f8c5"; }

.fa-grill-fire {
  --fa: "\e5a4";
  --fa--fa: "\e5a4\e5a4"; }

.fa-rectangle-vertical-history {
  --fa: "\e237";
  --fa--fa: "\e237\e237"; }

.fa-swatchbook {
  --fa: "\f5c3";
  --fa--fa: "\f5c3\f5c3"; }

.fa-prescription-bottle {
  --fa: "\f485";
  --fa--fa: "\f485\f485"; }

.fa-bars {
  --fa: "\f0c9";
  --fa--fa: "\f0c9\f0c9"; }

.fa-navicon {
  --fa: "\f0c9";
  --fa--fa: "\f0c9\f0c9"; }

.fa-keyboard-left {
  --fa: "\e1c3";
  --fa--fa: "\e1c3\e1c3"; }

.fa-people-group {
  --fa: "\e533";
  --fa--fa: "\e533\e533"; }

.fa-hourglass-end {
  --fa: "\f253";
  --fa--fa: "\f253\f253"; }

.fa-hourglass-3 {
  --fa: "\f253";
  --fa--fa: "\f253\f253"; }

.fa-heart-crack {
  --fa: "\f7a9";
  --fa--fa: "\f7a9\f7a9"; }

.fa-heart-broken {
  --fa: "\f7a9";
  --fa--fa: "\f7a9\f7a9"; }

.fa-face-beam-hand-over-mouth {
  --fa: "\e47c";
  --fa--fa: "\e47c\e47c"; }

.fa-droplet-percent {
  --fa: "\f750";
  --fa--fa: "\f750\f750"; }

.fa-humidity {
  --fa: "\f750";
  --fa--fa: "\f750\f750"; }

.fa-square-up-right {
  --fa: "\f360";
  --fa--fa: "\f360\f360"; }

.fa-external-link-square-alt {
  --fa: "\f360";
  --fa--fa: "\f360\f360"; }

.fa-face-kiss-beam {
  --fa: "\f597";
  --fa--fa: "\f597\f597"; }

.fa-kiss-beam {
  --fa: "\f597";
  --fa--fa: "\f597\f597"; }

.fa-corn {
  --fa: "\f6c7";
  --fa--fa: "\f6c7\f6c7"; }

.fa-roller-coaster {
  --fa: "\e324";
  --fa--fa: "\e324\e324"; }

.fa-photo-film-music {
  --fa: "\e228";
  --fa--fa: "\e228\e228"; }

.fa-radar {
  --fa: "\e024";
  --fa--fa: "\e024\e024"; }

.fa-sickle {
  --fa: "\f822";
  --fa--fa: "\f822\f822"; }

.fa-film {
  --fa: "\f008";
  --fa--fa: "\f008\f008"; }

.fa-coconut {
  --fa: "\e2f6";
  --fa--fa: "\e2f6\e2f6"; }

.fa-ruler-horizontal {
  --fa: "\f547";
  --fa--fa: "\f547\f547"; }

.fa-shield-cross {
  --fa: "\f712";
  --fa--fa: "\f712\f712"; }

.fa-cassette-tape {
  --fa: "\f8ab";
  --fa--fa: "\f8ab\f8ab"; }

.fa-square-terminal {
  --fa: "\e32a";
  --fa--fa: "\e32a\e32a"; }

.fa-people-robbery {
  --fa: "\e536";
  --fa--fa: "\e536\e536"; }

.fa-lightbulb {
  --fa: "\f0eb";
  --fa--fa: "\f0eb\f0eb"; }

.fa-caret-left {
  --fa: "\f0d9";
  --fa--fa: "\f0d9\f0d9"; }

.fa-comment-middle {
  --fa: "\e149";
  --fa--fa: "\e149\e149"; }

.fa-trash-can-list {
  --fa: "\e2ab";
  --fa--fa: "\e2ab\e2ab"; }

.fa-block {
  --fa: "\e46a";
  --fa--fa: "\e46a\e46a"; }

.fa-circle-exclamation {
  --fa: "\f06a";
  --fa--fa: "\f06a\f06a"; }

.fa-exclamation-circle {
  --fa: "\f06a";
  --fa--fa: "\f06a\f06a"; }

.fa-school-circle-xmark {
  --fa: "\e56d";
  --fa--fa: "\e56d\e56d"; }

.fa-arrow-right-from-bracket {
  --fa: "\f08b";
  --fa--fa: "\f08b\f08b"; }

.fa-sign-out {
  --fa: "\f08b";
  --fa--fa: "\f08b\f08b"; }

.fa-face-frown-slight {
  --fa: "\e376";
  --fa--fa: "\e376\e376"; }

.fa-circle-chevron-down {
  --fa: "\f13a";
  --fa--fa: "\f13a\f13a"; }

.fa-chevron-circle-down {
  --fa: "\f13a";
  --fa--fa: "\f13a\f13a"; }

.fa-sidebar-flip {
  --fa: "\e24f";
  --fa--fa: "\e24f\e24f"; }

.fa-unlock-keyhole {
  --fa: "\f13e";
  --fa--fa: "\f13e\f13e"; }

.fa-unlock-alt {
  --fa: "\f13e";
  --fa--fa: "\f13e\f13e"; }

.fa-temperature-list {
  --fa: "\e299";
  --fa--fa: "\e299\e299"; }

.fa-cloud-showers-heavy {
  --fa: "\f740";
  --fa--fa: "\f740\f740"; }

.fa-headphones-simple {
  --fa: "\f58f";
  --fa--fa: "\f58f\f58f"; }

.fa-headphones-alt {
  --fa: "\f58f";
  --fa--fa: "\f58f\f58f"; }

.fa-sitemap {
  --fa: "\f0e8";
  --fa--fa: "\f0e8\f0e8"; }

.fa-pipe-section {
  --fa: "\e438";
  --fa--fa: "\e438\e438"; }

.fa-space-station-moon-construction {
  --fa: "\e034";
  --fa--fa: "\e034\e034"; }

.fa-space-station-moon-alt {
  --fa: "\e034";
  --fa--fa: "\e034\e034"; }

.fa-circle-dollar-to-slot {
  --fa: "\f4b9";
  --fa--fa: "\f4b9\f4b9"; }

.fa-donate {
  --fa: "\f4b9";
  --fa--fa: "\f4b9\f4b9"; }

.fa-memory {
  --fa: "\f538";
  --fa--fa: "\f538\f538"; }

.fa-face-sleeping {
  --fa: "\e38d";
  --fa--fa: "\e38d\e38d"; }

.fa-road-spikes {
  --fa: "\e568";
  --fa--fa: "\e568\e568"; }

.fa-fire-burner {
  --fa: "\e4f1";
  --fa--fa: "\e4f1\e4f1"; }

.fa-squirrel {
  --fa: "\f71a";
  --fa--fa: "\f71a\f71a"; }

.fa-arrow-up-to-line {
  --fa: "\f341";
  --fa--fa: "\f341\f341"; }

.fa-arrow-to-top {
  --fa: "\f341";
  --fa--fa: "\f341\f341"; }

.fa-flag {
  --fa: "\f024";
  --fa--fa: "\f024\f024"; }

.fa-face-cowboy-hat {
  --fa: "\e36e";
  --fa--fa: "\e36e\e36e"; }

.fa-hanukiah {
  --fa: "\f6e6";
  --fa--fa: "\f6e6\f6e6"; }

.fa-chart-scatter-3d {
  --fa: "\e0e8";
  --fa--fa: "\e0e8\e0e8"; }

.fa-display-chart-up {
  --fa: "\e5e3";
  --fa--fa: "\e5e3\e5e3"; }

.fa-square-code {
  --fa: "\e267";
  --fa--fa: "\e267\e267"; }

.fa-feather {
  --fa: "\f52d";
  --fa--fa: "\f52d\f52d"; }

.fa-volume-low {
  --fa: "\f027";
  --fa--fa: "\f027\f027"; }

.fa-volume-down {
  --fa: "\f027";
  --fa--fa: "\f027\f027"; }

.fa-xmark-to-slot {
  --fa: "\f771";
  --fa--fa: "\f771\f771"; }

.fa-times-to-slot {
  --fa: "\f771";
  --fa--fa: "\f771\f771"; }

.fa-vote-nay {
  --fa: "\f771";
  --fa--fa: "\f771\f771"; }

.fa-box-taped {
  --fa: "\f49a";
  --fa--fa: "\f49a\f49a"; }

.fa-box-alt {
  --fa: "\f49a";
  --fa--fa: "\f49a\f49a"; }

.fa-comment-slash {
  --fa: "\f4b3";
  --fa--fa: "\f4b3\f4b3"; }

.fa-swords {
  --fa: "\f71d";
  --fa--fa: "\f71d\f71d"; }

.fa-cloud-sun-rain {
  --fa: "\f743";
  --fa--fa: "\f743\f743"; }

.fa-album {
  --fa: "\f89f";
  --fa--fa: "\f89f\f89f"; }

.fa-circle-n {
  --fa: "\e118";
  --fa--fa: "\e118\e118"; }

.fa-compress {
  --fa: "\f066";
  --fa--fa: "\f066\f066"; }

.fa-wheat-awn {
  --fa: "\e2cd";
  --fa--fa: "\e2cd\e2cd"; }

.fa-wheat-alt {
  --fa: "\e2cd";
  --fa--fa: "\e2cd\e2cd"; }

.fa-ankh {
  --fa: "\f644";
  --fa--fa: "\f644\f644"; }

.fa-hands-holding-child {
  --fa: "\e4fa";
  --fa--fa: "\e4fa\e4fa"; }

.fa-asterisk {
  --fa: "\2a";
  --fa--fa: "\2a\2a"; }

.fa-key-skeleton-left-right {
  --fa: "\e3b4";
  --fa--fa: "\e3b4\e3b4"; }

.fa-comment-lines {
  --fa: "\f4b0";
  --fa--fa: "\f4b0\f4b0"; }

.fa-luchador-mask {
  --fa: "\f455";
  --fa--fa: "\f455\f455"; }

.fa-luchador {
  --fa: "\f455";
  --fa--fa: "\f455\f455"; }

.fa-mask-luchador {
  --fa: "\f455";
  --fa--fa: "\f455\f455"; }

.fa-square-check {
  --fa: "\f14a";
  --fa--fa: "\f14a\f14a"; }

.fa-check-square {
  --fa: "\f14a";
  --fa--fa: "\f14a\f14a"; }

.fa-shredder {
  --fa: "\f68a";
  --fa--fa: "\f68a\f68a"; }

.fa-book-open-cover {
  --fa: "\e0c0";
  --fa--fa: "\e0c0\e0c0"; }

.fa-book-open-alt {
  --fa: "\e0c0";
  --fa--fa: "\e0c0\e0c0"; }

.fa-sandwich {
  --fa: "\f81f";
  --fa--fa: "\f81f\f81f"; }

.fa-peseta-sign {
  --fa: "\e221";
  --fa--fa: "\e221\e221"; }

.fa-square-parking-slash {
  --fa: "\f617";
  --fa--fa: "\f617\f617"; }

.fa-parking-slash {
  --fa: "\f617";
  --fa--fa: "\f617\f617"; }

.fa-train-tunnel {
  --fa: "\e454";
  --fa--fa: "\e454\e454"; }

.fa-heading {
  --fa: "\f1dc";
  --fa--fa: "\f1dc\f1dc"; }

.fa-header {
  --fa: "\f1dc";
  --fa--fa: "\f1dc\f1dc"; }

.fa-ghost {
  --fa: "\f6e2";
  --fa--fa: "\f6e2\f6e2"; }

.fa-face-anguished {
  --fa: "\e369";
  --fa--fa: "\e369\e369"; }

.fa-hockey-sticks {
  --fa: "\f454";
  --fa--fa: "\f454\f454"; }

.fa-abacus {
  --fa: "\f640";
  --fa--fa: "\f640\f640"; }

.fa-film-simple {
  --fa: "\f3a0";
  --fa--fa: "\f3a0\f3a0"; }

.fa-film-alt {
  --fa: "\f3a0";
  --fa--fa: "\f3a0\f3a0"; }

.fa-list {
  --fa: "\f03a";
  --fa--fa: "\f03a\f03a"; }

.fa-list-squares {
  --fa: "\f03a";
  --fa--fa: "\f03a\f03a"; }

.fa-tree-palm {
  --fa: "\f82b";
  --fa--fa: "\f82b\f82b"; }

.fa-square-phone-flip {
  --fa: "\f87b";
  --fa--fa: "\f87b\f87b"; }

.fa-phone-square-alt {
  --fa: "\f87b";
  --fa--fa: "\f87b\f87b"; }

.fa-user-beard-bolt {
  --fa: "\e689";
  --fa--fa: "\e689\e689"; }

.fa-cart-plus {
  --fa: "\f217";
  --fa--fa: "\f217\f217"; }

.fa-gamepad {
  --fa: "\f11b";
  --fa--fa: "\f11b\f11b"; }

.fa-border-center-v {
  --fa: "\f89d";
  --fa--fa: "\f89d\f89d"; }

.fa-circle-dot {
  --fa: "\f192";
  --fa--fa: "\f192\f192"; }

.fa-dot-circle {
  --fa: "\f192";
  --fa--fa: "\f192\f192"; }

.fa-clipboard-medical {
  --fa: "\e133";
  --fa--fa: "\e133\e133"; }

.fa-face-dizzy {
  --fa: "\f567";
  --fa--fa: "\f567\f567"; }

.fa-dizzy {
  --fa: "\f567";
  --fa--fa: "\f567\f567"; }

.fa-egg {
  --fa: "\f7fb";
  --fa--fa: "\f7fb\f7fb"; }

.fa-up-to-line {
  --fa: "\f34d";
  --fa--fa: "\f34d\f34d"; }

.fa-arrow-alt-to-top {
  --fa: "\f34d";
  --fa--fa: "\f34d\f34d"; }

.fa-house-medical-circle-xmark {
  --fa: "\e513";
  --fa--fa: "\e513\e513"; }

.fa-watch-fitness {
  --fa: "\f63e";
  --fa--fa: "\f63e\f63e"; }

.fa-clock-nine-thirty {
  --fa: "\e34d";
  --fa--fa: "\e34d\e34d"; }

.fa-campground {
  --fa: "\f6bb";
  --fa--fa: "\f6bb\f6bb"; }

.fa-folder-plus {
  --fa: "\f65e";
  --fa--fa: "\f65e\f65e"; }

.fa-jug {
  --fa: "\f8c6";
  --fa--fa: "\f8c6\f8c6"; }

.fa-futbol {
  --fa: "\f1e3";
  --fa--fa: "\f1e3\f1e3"; }

.fa-futbol-ball {
  --fa: "\f1e3";
  --fa--fa: "\f1e3\f1e3"; }

.fa-soccer-ball {
  --fa: "\f1e3";
  --fa--fa: "\f1e3\f1e3"; }

.fa-snow-blowing {
  --fa: "\f761";
  --fa--fa: "\f761\f761"; }

.fa-paintbrush {
  --fa: "\f1fc";
  --fa--fa: "\f1fc\f1fc"; }

.fa-paint-brush {
  --fa: "\f1fc";
  --fa--fa: "\f1fc\f1fc"; }

.fa-lock {
  --fa: "\f023";
  --fa--fa: "\f023\f023"; }

.fa-arrow-down-from-line {
  --fa: "\f345";
  --fa--fa: "\f345\f345"; }

.fa-arrow-from-top {
  --fa: "\f345";
  --fa--fa: "\f345\f345"; }

.fa-gas-pump {
  --fa: "\f52f";
  --fa--fa: "\f52f\f52f"; }

.fa-signal-bars-slash {
  --fa: "\f694";
  --fa--fa: "\f694\f694"; }

.fa-signal-alt-slash {
  --fa: "\f694";
  --fa--fa: "\f694\f694"; }

.fa-monkey {
  --fa: "\f6fb";
  --fa--fa: "\f6fb\f6fb"; }

.fa-rectangle-pro {
  --fa: "\e235";
  --fa--fa: "\e235\e235"; }

.fa-pro {
  --fa: "\e235";
  --fa--fa: "\e235\e235"; }

.fa-house-night {
  --fa: "\e010";
  --fa--fa: "\e010\e010"; }

.fa-hot-tub-person {
  --fa: "\f593";
  --fa--fa: "\f593\f593"; }

.fa-hot-tub {
  --fa: "\f593";
  --fa--fa: "\f593\f593"; }

.fa-globe-pointer {
  --fa: "\e60e";
  --fa--fa: "\e60e\e60e"; }

.fa-blanket {
  --fa: "\f498";
  --fa--fa: "\f498\f498"; }

.fa-map-location {
  --fa: "\f59f";
  --fa--fa: "\f59f\f59f"; }

.fa-map-marked {
  --fa: "\f59f";
  --fa--fa: "\f59f\f59f"; }

.fa-house-flood-water {
  --fa: "\e50e";
  --fa--fa: "\e50e\e50e"; }

.fa-comments-question-check {
  --fa: "\e14f";
  --fa--fa: "\e14f\e14f"; }

.fa-tree {
  --fa: "\f1bb";
  --fa--fa: "\f1bb\f1bb"; }

.fa-arrows-cross {
  --fa: "\e0a2";
  --fa--fa: "\e0a2\e0a2"; }

.fa-backpack {
  --fa: "\f5d4";
  --fa--fa: "\f5d4\f5d4"; }

.fa-square-small {
  --fa: "\e27e";
  --fa--fa: "\e27e\e27e"; }

.fa-folder-arrow-up {
  --fa: "\e054";
  --fa--fa: "\e054\e054"; }

.fa-folder-upload {
  --fa: "\e054";
  --fa--fa: "\e054\e054"; }

.fa-bridge-lock {
  --fa: "\e4cc";
  --fa--fa: "\e4cc\e4cc"; }

.fa-crosshairs-simple {
  --fa: "\e59f";
  --fa--fa: "\e59f\e59f"; }

.fa-sack-dollar {
  --fa: "\f81d";
  --fa--fa: "\f81d\f81d"; }

.fa-pen-to-square {
  --fa: "\f044";
  --fa--fa: "\f044\f044"; }

.fa-edit {
  --fa: "\f044";
  --fa--fa: "\f044\f044"; }

.fa-square-sliders {
  --fa: "\f3f0";
  --fa--fa: "\f3f0\f3f0"; }

.fa-sliders-h-square {
  --fa: "\f3f0";
  --fa--fa: "\f3f0\f3f0"; }

.fa-car-side {
  --fa: "\f5e4";
  --fa--fa: "\f5e4\f5e4"; }

.fa-message-middle-top {
  --fa: "\e1e2";
  --fa--fa: "\e1e2\e1e2"; }

.fa-comment-middle-top-alt {
  --fa: "\e1e2";
  --fa--fa: "\e1e2\e1e2"; }

.fa-lightbulb-on {
  --fa: "\f672";
  --fa--fa: "\f672\f672"; }

.fa-knife {
  --fa: "\f2e4";
  --fa--fa: "\f2e4\f2e4"; }

.fa-utensil-knife {
  --fa: "\f2e4";
  --fa--fa: "\f2e4\f2e4"; }

.fa-share-nodes {
  --fa: "\f1e0";
  --fa--fa: "\f1e0\f1e0"; }

.fa-share-alt {
  --fa: "\f1e0";
  --fa--fa: "\f1e0\f1e0"; }

.fa-display-chart-up-circle-dollar {
  --fa: "\e5e6";
  --fa--fa: "\e5e6\e5e6"; }

.fa-wave-sine {
  --fa: "\f899";
  --fa--fa: "\f899\f899"; }

.fa-heart-circle-minus {
  --fa: "\e4ff";
  --fa--fa: "\e4ff\e4ff"; }

.fa-circle-w {
  --fa: "\e12c";
  --fa--fa: "\e12c\e12c"; }

.fa-circle-calendar {
  --fa: "\e102";
  --fa--fa: "\e102\e102"; }

.fa-calendar-circle {
  --fa: "\e102";
  --fa--fa: "\e102\e102"; }

.fa-hourglass-half {
  --fa: "\f252";
  --fa--fa: "\f252\f252"; }

.fa-hourglass-2 {
  --fa: "\f252";
  --fa--fa: "\f252\f252"; }

.fa-microscope {
  --fa: "\f610";
  --fa--fa: "\f610\f610"; }

.fa-sunset {
  --fa: "\f767";
  --fa--fa: "\f767\f767"; }

.fa-sink {
  --fa: "\e06d";
  --fa--fa: "\e06d\e06d"; }

.fa-calendar-exclamation {
  --fa: "\f334";
  --fa--fa: "\f334\f334"; }

.fa-truck-container-empty {
  --fa: "\e2b5";
  --fa--fa: "\e2b5\e2b5"; }

.fa-hand-heart {
  --fa: "\f4bc";
  --fa--fa: "\f4bc\f4bc"; }

.fa-bag-shopping {
  --fa: "\f290";
  --fa--fa: "\f290\f290"; }

.fa-shopping-bag {
  --fa: "\f290";
  --fa--fa: "\f290\f290"; }

.fa-arrow-down-z-a {
  --fa: "\f881";
  --fa--fa: "\f881\f881"; }

.fa-sort-alpha-desc {
  --fa: "\f881";
  --fa--fa: "\f881\f881"; }

.fa-sort-alpha-down-alt {
  --fa: "\f881";
  --fa--fa: "\f881\f881"; }

.fa-mitten {
  --fa: "\f7b5";
  --fa--fa: "\f7b5\f7b5"; }

.fa-reply-clock {
  --fa: "\e239";
  --fa--fa: "\e239\e239"; }

.fa-reply-time {
  --fa: "\e239";
  --fa--fa: "\e239\e239"; }

.fa-person-rays {
  --fa: "\e54d";
  --fa--fa: "\e54d\e54d"; }

.fa-right {
  --fa: "\f356";
  --fa--fa: "\f356\f356"; }

.fa-arrow-alt-right {
  --fa: "\f356";
  --fa--fa: "\f356\f356"; }

.fa-circle-f {
  --fa: "\e10e";
  --fa--fa: "\e10e\e10e"; }

.fa-users {
  --fa: "\f0c0";
  --fa--fa: "\f0c0\f0c0"; }

.fa-face-pleading {
  --fa: "\e386";
  --fa--fa: "\e386\e386"; }

.fa-eye-slash {
  --fa: "\f070";
  --fa--fa: "\f070\f070"; }

.fa-flask-vial {
  --fa: "\e4f3";
  --fa--fa: "\e4f3\e4f3"; }

.fa-police-box {
  --fa: "\e021";
  --fa--fa: "\e021\e021"; }

.fa-cucumber {
  --fa: "\e401";
  --fa--fa: "\e401\e401"; }

.fa-head-side-brain {
  --fa: "\f808";
  --fa--fa: "\f808\f808"; }

.fa-hand {
  --fa: "\f256";
  --fa--fa: "\f256\f256"; }

.fa-hand-paper {
  --fa: "\f256";
  --fa--fa: "\f256\f256"; }

.fa-person-biking-mountain {
  --fa: "\f84b";
  --fa--fa: "\f84b\f84b"; }

.fa-biking-mountain {
  --fa: "\f84b";
  --fa--fa: "\f84b\f84b"; }

.fa-utensils-slash {
  --fa: "\e464";
  --fa--fa: "\e464\e464"; }

.fa-print-magnifying-glass {
  --fa: "\f81a";
  --fa--fa: "\f81a\f81a"; }

.fa-print-search {
  --fa: "\f81a";
  --fa--fa: "\f81a\f81a"; }

.fa-turn-right {
  --fa: "\e639";
  --fa--fa: "\e639\e639"; }

.fa-folder-bookmark {
  --fa: "\e186";
  --fa--fa: "\e186\e186"; }

.fa-arrow-turn-left-down {
  --fa: "\e633";
  --fa--fa: "\e633\e633"; }

.fa-om {
  --fa: "\f679";
  --fa--fa: "\f679\f679"; }

.fa-pi {
  --fa: "\f67e";
  --fa--fa: "\f67e\f67e"; }

.fa-flask-round-potion {
  --fa: "\f6e1";
  --fa--fa: "\f6e1\f6e1"; }

.fa-flask-potion {
  --fa: "\f6e1";
  --fa--fa: "\f6e1\f6e1"; }

.fa-face-shush {
  --fa: "\e38c";
  --fa--fa: "\e38c\e38c"; }

.fa-worm {
  --fa: "\e599";
  --fa--fa: "\e599\e599"; }

.fa-house-circle-xmark {
  --fa: "\e50b";
  --fa--fa: "\e50b\e50b"; }

.fa-plug {
  --fa: "\f1e6";
  --fa--fa: "\f1e6\f1e6"; }

.fa-calendar-circle-exclamation {
  --fa: "\e46e";
  --fa--fa: "\e46e\e46e"; }

.fa-square-i {
  --fa: "\e272";
  --fa--fa: "\e272\e272"; }

.fa-chevron-up {
  --fa: "\f077";
  --fa--fa: "\f077\f077"; }

.fa-face-saluting {
  --fa: "\e484";
  --fa--fa: "\e484\e484"; }

.fa-gauge-simple-low {
  --fa: "\f62c";
  --fa--fa: "\f62c\f62c"; }

.fa-tachometer-slow {
  --fa: "\f62c";
  --fa--fa: "\f62c\f62c"; }

.fa-face-persevering {
  --fa: "\e385";
  --fa--fa: "\e385\e385"; }

.fa-circle-camera {
  --fa: "\e103";
  --fa--fa: "\e103\e103"; }

.fa-camera-circle {
  --fa: "\e103";
  --fa--fa: "\e103\e103"; }

.fa-hand-spock {
  --fa: "\f259";
  --fa--fa: "\f259\f259"; }

.fa-spider-web {
  --fa: "\f719";
  --fa--fa: "\f719\f719"; }

.fa-circle-microphone {
  --fa: "\e116";
  --fa--fa: "\e116\e116"; }

.fa-microphone-circle {
  --fa: "\e116";
  --fa--fa: "\e116\e116"; }

.fa-book-arrow-up {
  --fa: "\e0ba";
  --fa--fa: "\e0ba\e0ba"; }

.fa-popsicle {
  --fa: "\e43e";
  --fa--fa: "\e43e\e43e"; }

.fa-command {
  --fa: "\e142";
  --fa--fa: "\e142\e142"; }

.fa-blinds {
  --fa: "\f8fb";
  --fa--fa: "\f8fb\f8fb"; }

.fa-stopwatch {
  --fa: "\f2f2";
  --fa--fa: "\f2f2\f2f2"; }

.fa-saxophone {
  --fa: "\f8dc";
  --fa--fa: "\f8dc\f8dc"; }

.fa-square-2 {
  --fa: "\e257";
  --fa--fa: "\e257\e257"; }

.fa-field-hockey-stick-ball {
  --fa: "\f44c";
  --fa--fa: "\f44c\f44c"; }

.fa-field-hockey {
  --fa: "\f44c";
  --fa--fa: "\f44c\f44c"; }

.fa-arrow-up-square-triangle {
  --fa: "\f88b";
  --fa--fa: "\f88b\f88b"; }

.fa-sort-shapes-up-alt {
  --fa: "\f88b";
  --fa--fa: "\f88b\f88b"; }

.fa-face-scream {
  --fa: "\e38b";
  --fa--fa: "\e38b\e38b"; }

.fa-square-m {
  --fa: "\e276";
  --fa--fa: "\e276\e276"; }

.fa-camera-web {
  --fa: "\f832";
  --fa--fa: "\f832\f832"; }

.fa-webcam {
  --fa: "\f832";
  --fa--fa: "\f832\f832"; }

.fa-comment-arrow-down {
  --fa: "\e143";
  --fa--fa: "\e143\e143"; }

.fa-lightbulb-cfl {
  --fa: "\e5a6";
  --fa--fa: "\e5a6\e5a6"; }

.fa-window-frame-open {
  --fa: "\e050";
  --fa--fa: "\e050\e050"; }

.fa-face-kiss {
  --fa: "\f596";
  --fa--fa: "\f596\f596"; }

.fa-kiss {
  --fa: "\f596";
  --fa--fa: "\f596\f596"; }

.fa-bridge-circle-xmark {
  --fa: "\e4cb";
  --fa--fa: "\e4cb\e4cb"; }

.fa-period {
  --fa: "\2e";
  --fa--fa: "\2e\2e"; }

.fa-face-grin-tongue {
  --fa: "\f589";
  --fa--fa: "\f589\f589"; }

.fa-grin-tongue {
  --fa: "\f589";
  --fa--fa: "\f589\f589"; }

.fa-up-to-dotted-line {
  --fa: "\e457";
  --fa--fa: "\e457\e457"; }

.fa-thought-bubble {
  --fa: "\e32e";
  --fa--fa: "\e32e\e32e"; }

.fa-skeleton-ribs {
  --fa: "\e5cb";
  --fa--fa: "\e5cb\e5cb"; }

.fa-raygun {
  --fa: "\e025";
  --fa--fa: "\e025\e025"; }

.fa-flute {
  --fa: "\f8b9";
  --fa--fa: "\f8b9\f8b9"; }

.fa-acorn {
  --fa: "\f6ae";
  --fa--fa: "\f6ae\f6ae"; }

.fa-video-arrow-up-right {
  --fa: "\e2c9";
  --fa--fa: "\e2c9\e2c9"; }

.fa-grate-droplet {
  --fa: "\e194";
  --fa--fa: "\e194\e194"; }

.fa-seal-exclamation {
  --fa: "\e242";
  --fa--fa: "\e242\e242"; }

.fa-chess-bishop {
  --fa: "\f43a";
  --fa--fa: "\f43a\f43a"; }

.fa-message-sms {
  --fa: "\e1e5";
  --fa--fa: "\e1e5\e1e5"; }

.fa-coffee-beans {
  --fa: "\e13f";
  --fa--fa: "\e13f\e13f"; }

.fa-hat-witch {
  --fa: "\f6e7";
  --fa--fa: "\f6e7\f6e7"; }

.fa-face-grin-wink {
  --fa: "\f58c";
  --fa--fa: "\f58c\f58c"; }

.fa-grin-wink {
  --fa: "\f58c";
  --fa--fa: "\f58c\f58c"; }

.fa-clock-three-thirty {
  --fa: "\e357";
  --fa--fa: "\e357\e357"; }

.fa-ear-deaf {
  --fa: "\f2a4";
  --fa--fa: "\f2a4\f2a4"; }

.fa-deaf {
  --fa: "\f2a4";
  --fa--fa: "\f2a4\f2a4"; }

.fa-deafness {
  --fa: "\f2a4";
  --fa--fa: "\f2a4\f2a4"; }

.fa-hard-of-hearing {
  --fa: "\f2a4";
  --fa--fa: "\f2a4\f2a4"; }

.fa-alarm-clock {
  --fa: "\f34e";
  --fa--fa: "\f34e\f34e"; }

.fa-eclipse {
  --fa: "\f749";
  --fa--fa: "\f749\f749"; }

.fa-face-relieved {
  --fa: "\e389";
  --fa--fa: "\e389\e389"; }

.fa-road-circle-check {
  --fa: "\e564";
  --fa--fa: "\e564\e564"; }

.fa-dice-five {
  --fa: "\f523";
  --fa--fa: "\f523\f523"; }

.fa-octagon-minus {
  --fa: "\f308";
  --fa--fa: "\f308\f308"; }

.fa-minus-octagon {
  --fa: "\f308";
  --fa--fa: "\f308\f308"; }

.fa-square-rss {
  --fa: "\f143";
  --fa--fa: "\f143\f143"; }

.fa-rss-square {
  --fa: "\f143";
  --fa--fa: "\f143\f143"; }

.fa-face-zany {
  --fa: "\e3a4";
  --fa--fa: "\e3a4\e3a4"; }

.fa-tricycle {
  --fa: "\e5c3";
  --fa--fa: "\e5c3\e5c3"; }

.fa-land-mine-on {
  --fa: "\e51b";
  --fa--fa: "\e51b\e51b"; }

.fa-square-arrow-up-left {
  --fa: "\e263";
  --fa--fa: "\e263\e263"; }

.fa-i-cursor {
  --fa: "\f246";
  --fa--fa: "\f246\f246"; }

.fa-chart-mixed-up-circle-dollar {
  --fa: "\e5d9";
  --fa--fa: "\e5d9\e5d9"; }

.fa-salt-shaker {
  --fa: "\e446";
  --fa--fa: "\e446\e446"; }

.fa-stamp {
  --fa: "\f5bf";
  --fa--fa: "\f5bf\f5bf"; }

.fa-file-plus {
  --fa: "\f319";
  --fa--fa: "\f319\f319"; }

.fa-draw-square {
  --fa: "\f5ef";
  --fa--fa: "\f5ef\f5ef"; }

.fa-toilet-paper-under-slash {
  --fa: "\e2a1";
  --fa--fa: "\e2a1\e2a1"; }

.fa-toilet-paper-reverse-slash {
  --fa: "\e2a1";
  --fa--fa: "\e2a1\e2a1"; }

.fa-stairs {
  --fa: "\e289";
  --fa--fa: "\e289\e289"; }

.fa-drone-front {
  --fa: "\f860";
  --fa--fa: "\f860\f860"; }

.fa-drone-alt {
  --fa: "\f860";
  --fa--fa: "\f860\f860"; }

.fa-glass-empty {
  --fa: "\e191";
  --fa--fa: "\e191\e191"; }

.fa-dial-high {
  --fa: "\e15c";
  --fa--fa: "\e15c\e15c"; }

.fa-user-helmet-safety {
  --fa: "\f82c";
  --fa--fa: "\f82c\f82c"; }

.fa-user-construction {
  --fa: "\f82c";
  --fa--fa: "\f82c\f82c"; }

.fa-user-hard-hat {
  --fa: "\f82c";
  --fa--fa: "\f82c\f82c"; }

.fa-i {
  --fa: "\49";
  --fa--fa: "\49\49"; }

.fa-hryvnia-sign {
  --fa: "\f6f2";
  --fa--fa: "\f6f2\f6f2"; }

.fa-hryvnia {
  --fa: "\f6f2";
  --fa--fa: "\f6f2\f6f2"; }

.fa-arrow-down-left-and-arrow-up-right-to-center {
  --fa: "\e092";
  --fa--fa: "\e092\e092"; }

.fa-pills {
  --fa: "\f484";
  --fa--fa: "\f484\f484"; }

.fa-face-grin-wide {
  --fa: "\f581";
  --fa--fa: "\f581\f581"; }

.fa-grin-alt {
  --fa: "\f581";
  --fa--fa: "\f581\f581"; }

.fa-tooth {
  --fa: "\f5c9";
  --fa--fa: "\f5c9\f5c9"; }

.fa-basketball-hoop {
  --fa: "\f435";
  --fa--fa: "\f435\f435"; }

.fa-objects-align-bottom {
  --fa: "\e3bb";
  --fa--fa: "\e3bb\e3bb"; }

.fa-v {
  --fa: "\56";
  --fa--fa: "\56\56"; }

.fa-sparkles {
  --fa: "\f890";
  --fa--fa: "\f890\f890"; }

.fa-squid {
  --fa: "\e450";
  --fa--fa: "\e450\e450"; }

.fa-leafy-green {
  --fa: "\e41d";
  --fa--fa: "\e41d\e41d"; }

.fa-circle-arrow-up-right {
  --fa: "\e0fc";
  --fa--fa: "\e0fc\e0fc"; }

.fa-calendars {
  --fa: "\e0d7";
  --fa--fa: "\e0d7\e0d7"; }

.fa-bangladeshi-taka-sign {
  --fa: "\e2e6";
  --fa--fa: "\e2e6\e2e6"; }

.fa-bicycle {
  --fa: "\f206";
  --fa--fa: "\f206\f206"; }

.fa-hammer-war {
  --fa: "\f6e4";
  --fa--fa: "\f6e4\f6e4"; }

.fa-circle-d {
  --fa: "\e104";
  --fa--fa: "\e104\e104"; }

.fa-spider-black-widow {
  --fa: "\f718";
  --fa--fa: "\f718\f718"; }

.fa-staff-snake {
  --fa: "\e579";
  --fa--fa: "\e579\e579"; }

.fa-rod-asclepius {
  --fa: "\e579";
  --fa--fa: "\e579\e579"; }

.fa-rod-snake {
  --fa: "\e579";
  --fa--fa: "\e579\e579"; }

.fa-staff-aesculapius {
  --fa: "\e579";
  --fa--fa: "\e579\e579"; }

.fa-pear {
  --fa: "\e20c";
  --fa--fa: "\e20c\e20c"; }

.fa-head-side-cough-slash {
  --fa: "\e062";
  --fa--fa: "\e062\e062"; }

.fa-file-mov {
  --fa: "\e647";
  --fa--fa: "\e647\e647"; }

.fa-triangle {
  --fa: "\f2ec";
  --fa--fa: "\f2ec\f2ec"; }

.fa-apartment {
  --fa: "\e468";
  --fa--fa: "\e468\e468"; }

.fa-truck-medical {
  --fa: "\f0f9";
  --fa--fa: "\f0f9\f0f9"; }

.fa-ambulance {
  --fa: "\f0f9";
  --fa--fa: "\f0f9\f0f9"; }

.fa-pepper {
  --fa: "\e432";
  --fa--fa: "\e432\e432"; }

.fa-piano {
  --fa: "\f8d4";
  --fa--fa: "\f8d4\f8d4"; }

.fa-gun-squirt {
  --fa: "\e19d";
  --fa--fa: "\e19d\e19d"; }

.fa-wheat-awn-circle-exclamation {
  --fa: "\e598";
  --fa--fa: "\e598\e598"; }

.fa-snowman {
  --fa: "\f7d0";
  --fa--fa: "\f7d0\f7d0"; }

.fa-user-alien {
  --fa: "\e04a";
  --fa--fa: "\e04a\e04a"; }

.fa-shield-check {
  --fa: "\f2f7";
  --fa--fa: "\f2f7\f2f7"; }

.fa-mortar-pestle {
  --fa: "\f5a7";
  --fa--fa: "\f5a7\f5a7"; }

.fa-road-barrier {
  --fa: "\e562";
  --fa--fa: "\e562\e562"; }

.fa-chart-candlestick {
  --fa: "\e0e2";
  --fa--fa: "\e0e2\e0e2"; }

.fa-briefcase-blank {
  --fa: "\e0c8";
  --fa--fa: "\e0c8\e0c8"; }

.fa-school {
  --fa: "\f549";
  --fa--fa: "\f549\f549"; }

.fa-igloo {
  --fa: "\f7ae";
  --fa--fa: "\f7ae\f7ae"; }

.fa-bracket-round {
  --fa: "\28";
  --fa--fa: "\28\28"; }

.fa-parenthesis {
  --fa: "\28";
  --fa--fa: "\28\28"; }

.fa-joint {
  --fa: "\f595";
  --fa--fa: "\f595\f595"; }

.fa-horse-saddle {
  --fa: "\f8c3";
  --fa--fa: "\f8c3\f8c3"; }

.fa-mug-marshmallows {
  --fa: "\f7b7";
  --fa--fa: "\f7b7\f7b7"; }

.fa-filters {
  --fa: "\e17e";
  --fa--fa: "\e17e\e17e"; }

.fa-bell-on {
  --fa: "\f8fa";
  --fa--fa: "\f8fa\f8fa"; }

.fa-angle-right {
  --fa: "\f105";
  --fa--fa: "\f105\f105"; }

.fa-dial-med {
  --fa: "\e15f";
  --fa--fa: "\e15f\e15f"; }

.fa-horse {
  --fa: "\f6f0";
  --fa--fa: "\f6f0\f6f0"; }

.fa-q {
  --fa: "\51";
  --fa--fa: "\51\51"; }

.fa-monitor-waveform {
  --fa: "\f611";
  --fa--fa: "\f611\f611"; }

.fa-monitor-heart-rate {
  --fa: "\f611";
  --fa--fa: "\f611\f611"; }

.fa-link-simple {
  --fa: "\e1cd";
  --fa--fa: "\e1cd\e1cd"; }

.fa-whistle {
  --fa: "\f460";
  --fa--fa: "\f460\f460"; }

.fa-g {
  --fa: "\47";
  --fa--fa: "\47\47"; }

.fa-wine-glass-crack {
  --fa: "\f4bb";
  --fa--fa: "\f4bb\f4bb"; }

.fa-fragile {
  --fa: "\f4bb";
  --fa--fa: "\f4bb\f4bb"; }

.fa-slot-machine {
  --fa: "\e3ce";
  --fa--fa: "\e3ce\e3ce"; }

.fa-notes-medical {
  --fa: "\f481";
  --fa--fa: "\f481\f481"; }

.fa-car-wash {
  --fa: "\f5e6";
  --fa--fa: "\f5e6\f5e6"; }

.fa-escalator {
  --fa: "\e171";
  --fa--fa: "\e171\e171"; }

.fa-comment-image {
  --fa: "\e148";
  --fa--fa: "\e148\e148"; }

.fa-temperature-half {
  --fa: "\f2c9";
  --fa--fa: "\f2c9\f2c9"; }

.fa-temperature-2 {
  --fa: "\f2c9";
  --fa--fa: "\f2c9\f2c9"; }

.fa-thermometer-2 {
  --fa: "\f2c9";
  --fa--fa: "\f2c9\f2c9"; }

.fa-thermometer-half {
  --fa: "\f2c9";
  --fa--fa: "\f2c9\f2c9"; }

.fa-dong-sign {
  --fa: "\e169";
  --fa--fa: "\e169\e169"; }

.fa-donut {
  --fa: "\e406";
  --fa--fa: "\e406\e406"; }

.fa-doughnut {
  --fa: "\e406";
  --fa--fa: "\e406\e406"; }

.fa-capsules {
  --fa: "\f46b";
  --fa--fa: "\f46b\f46b"; }

.fa-poo-storm {
  --fa: "\f75a";
  --fa--fa: "\f75a\f75a"; }

.fa-poo-bolt {
  --fa: "\f75a";
  --fa--fa: "\f75a\f75a"; }

.fa-tally-1 {
  --fa: "\e294";
  --fa--fa: "\e294\e294"; }

.fa-file-vector {
  --fa: "\e64c";
  --fa--fa: "\e64c\e64c"; }

.fa-face-frown-open {
  --fa: "\f57a";
  --fa--fa: "\f57a\f57a"; }

.fa-frown-open {
  --fa: "\f57a";
  --fa--fa: "\f57a\f57a"; }

.fa-square-dashed {
  --fa: "\e269";
  --fa--fa: "\e269\e269"; }

.fa-bag-shopping-plus {
  --fa: "\e651";
  --fa--fa: "\e651\e651"; }

.fa-square-j {
  --fa: "\e273";
  --fa--fa: "\e273\e273"; }

.fa-hand-point-up {
  --fa: "\f0a6";
  --fa--fa: "\f0a6\f0a6"; }

.fa-money-bill {
  --fa: "\f0d6";
  --fa--fa: "\f0d6\f0d6"; }

.fa-arrow-up-big-small {
  --fa: "\f88e";
  --fa--fa: "\f88e\f88e"; }

.fa-sort-size-up {
  --fa: "\f88e";
  --fa--fa: "\f88e\f88e"; }

.fa-barcode-read {
  --fa: "\f464";
  --fa--fa: "\f464\f464"; }

.fa-baguette {
  --fa: "\e3d8";
  --fa--fa: "\e3d8\e3d8"; }

.fa-bowl-soft-serve {
  --fa: "\e46b";
  --fa--fa: "\e46b\e46b"; }

.fa-face-holding-back-tears {
  --fa: "\e482";
  --fa--fa: "\e482\e482"; }

.fa-square-up {
  --fa: "\f353";
  --fa--fa: "\f353\f353"; }

.fa-arrow-alt-square-up {
  --fa: "\f353";
  --fa--fa: "\f353\f353"; }

.fa-train-subway-tunnel {
  --fa: "\e2a3";
  --fa--fa: "\e2a3\e2a3"; }

.fa-subway-tunnel {
  --fa: "\e2a3";
  --fa--fa: "\e2a3\e2a3"; }

.fa-square-exclamation {
  --fa: "\f321";
  --fa--fa: "\f321\f321"; }

.fa-exclamation-square {
  --fa: "\f321";
  --fa--fa: "\f321\f321"; }

.fa-semicolon {
  --fa: "\3b";
  --fa--fa: "\3b\3b"; }

.fa-bookmark {
  --fa: "\f02e";
  --fa--fa: "\f02e\f02e"; }

.fa-fan-table {
  --fa: "\e004";
  --fa--fa: "\e004\e004"; }

.fa-align-justify {
  --fa: "\f039";
  --fa--fa: "\f039\f039"; }

.fa-battery-low {
  --fa: "\e0b1";
  --fa--fa: "\e0b1\e0b1"; }

.fa-battery-1 {
  --fa: "\e0b1";
  --fa--fa: "\e0b1\e0b1"; }

.fa-credit-card-front {
  --fa: "\f38a";
  --fa--fa: "\f38a\f38a"; }

.fa-brain-arrow-curved-right {
  --fa: "\f677";
  --fa--fa: "\f677\f677"; }

.fa-mind-share {
  --fa: "\f677";
  --fa--fa: "\f677\f677"; }

.fa-umbrella-beach {
  --fa: "\f5ca";
  --fa--fa: "\f5ca\f5ca"; }

.fa-helmet-un {
  --fa: "\e503";
  --fa--fa: "\e503\e503"; }

.fa-location-smile {
  --fa: "\f60d";
  --fa--fa: "\f60d\f60d"; }

.fa-map-marker-smile {
  --fa: "\f60d";
  --fa--fa: "\f60d\f60d"; }

.fa-arrow-left-to-line {
  --fa: "\f33e";
  --fa--fa: "\f33e\f33e"; }

.fa-arrow-to-left {
  --fa: "\f33e";
  --fa--fa: "\f33e\f33e"; }

.fa-bullseye {
  --fa: "\f140";
  --fa--fa: "\f140\f140"; }

.fa-sushi {
  --fa: "\e48a";
  --fa--fa: "\e48a\e48a"; }

.fa-nigiri {
  --fa: "\e48a";
  --fa--fa: "\e48a\e48a"; }

.fa-message-captions {
  --fa: "\e1de";
  --fa--fa: "\e1de\e1de"; }

.fa-comment-alt-captions {
  --fa: "\e1de";
  --fa--fa: "\e1de\e1de"; }

.fa-trash-list {
  --fa: "\e2b1";
  --fa--fa: "\e2b1\e2b1"; }

.fa-bacon {
  --fa: "\f7e5";
  --fa--fa: "\f7e5\f7e5"; }

.fa-option {
  --fa: "\e318";
  --fa--fa: "\e318\e318"; }

.fa-raccoon {
  --fa: "\e613";
  --fa--fa: "\e613\e613"; }

.fa-hand-point-down {
  --fa: "\f0a7";
  --fa--fa: "\f0a7\f0a7"; }

.fa-arrow-up-from-bracket {
  --fa: "\e09a";
  --fa--fa: "\e09a\e09a"; }

.fa-head-side-gear {
  --fa: "\e611";
  --fa--fa: "\e611\e611"; }

.fa-trash-plus {
  --fa: "\e2b2";
  --fa--fa: "\e2b2\e2b2"; }

.fa-file-cad {
  --fa: "\e672";
  --fa--fa: "\e672\e672"; }

.fa-objects-align-top {
  --fa: "\e3c0";
  --fa--fa: "\e3c0\e3c0"; }

.fa-folder {
  --fa: "\f07b";
  --fa--fa: "\f07b\f07b"; }

.fa-folder-blank {
  --fa: "\f07b";
  --fa--fa: "\f07b\f07b"; }

.fa-face-anxious-sweat {
  --fa: "\e36a";
  --fa--fa: "\e36a\e36a"; }

.fa-credit-card-blank {
  --fa: "\f389";
  --fa--fa: "\f389\f389"; }

.fa-file-waveform {
  --fa: "\f478";
  --fa--fa: "\f478\f478"; }

.fa-file-medical-alt {
  --fa: "\f478";
  --fa--fa: "\f478\f478"; }

.fa-microchip-ai {
  --fa: "\e1ec";
  --fa--fa: "\e1ec\e1ec"; }

.fa-mug {
  --fa: "\f874";
  --fa--fa: "\f874\f874"; }

.fa-plane-up-slash {
  --fa: "\e22e";
  --fa--fa: "\e22e\e22e"; }

.fa-radiation {
  --fa: "\f7b9";
  --fa--fa: "\f7b9\f7b9"; }

.fa-pen-circle {
  --fa: "\e20e";
  --fa--fa: "\e20e\e20e"; }

.fa-bag-seedling {
  --fa: "\e5f2";
  --fa--fa: "\e5f2\e5f2"; }

.fa-chart-simple {
  --fa: "\e473";
  --fa--fa: "\e473\e473"; }

.fa-crutches {
  --fa: "\f7f8";
  --fa--fa: "\f7f8\f7f8"; }

.fa-circle-parking {
  --fa: "\f615";
  --fa--fa: "\f615\f615"; }

.fa-parking-circle {
  --fa: "\f615";
  --fa--fa: "\f615\f615"; }

.fa-mars-stroke {
  --fa: "\f229";
  --fa--fa: "\f229\f229"; }

.fa-leaf-oak {
  --fa: "\f6f7";
  --fa--fa: "\f6f7\f6f7"; }

.fa-square-bolt {
  --fa: "\e265";
  --fa--fa: "\e265\e265"; }

.fa-vial {
  --fa: "\f492";
  --fa--fa: "\f492\f492"; }

.fa-gauge {
  --fa: "\f624";
  --fa--fa: "\f624\f624"; }

.fa-dashboard {
  --fa: "\f624";
  --fa--fa: "\f624\f624"; }

.fa-gauge-med {
  --fa: "\f624";
  --fa--fa: "\f624\f624"; }

.fa-tachometer-alt-average {
  --fa: "\f624";
  --fa--fa: "\f624\f624"; }

.fa-wand-magic-sparkles {
  --fa: "\e2ca";
  --fa--fa: "\e2ca\e2ca"; }

.fa-magic-wand-sparkles {
  --fa: "\e2ca";
  --fa--fa: "\e2ca\e2ca"; }

.fa-lambda {
  --fa: "\f66e";
  --fa--fa: "\f66e\f66e"; }

.fa-e {
  --fa: "\45";
  --fa--fa: "\45\45"; }

.fa-pizza {
  --fa: "\f817";
  --fa--fa: "\f817\f817"; }

.fa-bowl-chopsticks-noodles {
  --fa: "\e2ea";
  --fa--fa: "\e2ea\e2ea"; }

.fa-h3 {
  --fa: "\f315";
  --fa--fa: "\f315\f315"; }

.fa-pen-clip {
  --fa: "\f305";
  --fa--fa: "\f305\f305"; }

.fa-pen-alt {
  --fa: "\f305";
  --fa--fa: "\f305\f305"; }

.fa-bridge-circle-exclamation {
  --fa: "\e4ca";
  --fa--fa: "\e4ca\e4ca"; }

.fa-badge-percent {
  --fa: "\f646";
  --fa--fa: "\f646\f646"; }

.fa-rotate-reverse {
  --fa: "\e631";
  --fa--fa: "\e631\e631"; }

.fa-user {
  --fa: "\f007";
  --fa--fa: "\f007\f007"; }

.fa-sensor {
  --fa: "\e028";
  --fa--fa: "\e028\e028"; }

.fa-comma {
  --fa: "\2c";
  --fa--fa: "\2c\2c"; }

.fa-school-circle-check {
  --fa: "\e56b";
  --fa--fa: "\e56b\e56b"; }

.fa-toilet-paper-under {
  --fa: "\e2a0";
  --fa--fa: "\e2a0\e2a0"; }

.fa-toilet-paper-reverse {
  --fa: "\e2a0";
  --fa--fa: "\e2a0\e2a0"; }

.fa-light-emergency {
  --fa: "\e41f";
  --fa--fa: "\e41f\e41f"; }

.fa-arrow-down-to-arc {
  --fa: "\e4ae";
  --fa--fa: "\e4ae\e4ae"; }

.fa-dumpster {
  --fa: "\f793";
  --fa--fa: "\f793\f793"; }

.fa-van-shuttle {
  --fa: "\f5b6";
  --fa--fa: "\f5b6\f5b6"; }

.fa-shuttle-van {
  --fa: "\f5b6";
  --fa--fa: "\f5b6\f5b6"; }

.fa-building-user {
  --fa: "\e4da";
  --fa--fa: "\e4da\e4da"; }

.fa-light-switch {
  --fa: "\e017";
  --fa--fa: "\e017\e017"; }

.fa-square-caret-left {
  --fa: "\f191";
  --fa--fa: "\f191\f191"; }

.fa-caret-square-left {
  --fa: "\f191";
  --fa--fa: "\f191\f191"; }

.fa-highlighter {
  --fa: "\f591";
  --fa--fa: "\f591\f591"; }

.fa-wave-pulse {
  --fa: "\f5f8";
  --fa--fa: "\f5f8\f5f8"; }

.fa-heart-rate {
  --fa: "\f5f8";
  --fa--fa: "\f5f8\f5f8"; }

.fa-key {
  --fa: "\f084";
  --fa--fa: "\f084\f084"; }

.fa-arrow-left-to-bracket {
  --fa: "\e669";
  --fa--fa: "\e669\e669"; }

.fa-hat-santa {
  --fa: "\f7a7";
  --fa--fa: "\f7a7\f7a7"; }

.fa-tamale {
  --fa: "\e451";
  --fa--fa: "\e451\e451"; }

.fa-box-check {
  --fa: "\f467";
  --fa--fa: "\f467\f467"; }

.fa-bullhorn {
  --fa: "\f0a1";
  --fa--fa: "\f0a1\f0a1"; }

.fa-steak {
  --fa: "\f824";
  --fa--fa: "\f824\f824"; }

.fa-location-crosshairs-slash {
  --fa: "\f603";
  --fa--fa: "\f603\f603"; }

.fa-location-slash {
  --fa: "\f603";
  --fa--fa: "\f603\f603"; }

.fa-person-dolly {
  --fa: "\f4d0";
  --fa--fa: "\f4d0\f4d0"; }

.fa-globe {
  --fa: "\f0ac";
  --fa--fa: "\f0ac\f0ac"; }

.fa-synagogue {
  --fa: "\f69b";
  --fa--fa: "\f69b\f69b"; }

.fa-file-chart-column {
  --fa: "\f659";
  --fa--fa: "\f659\f659"; }

.fa-file-chart-line {
  --fa: "\f659";
  --fa--fa: "\f659\f659"; }

.fa-person-half-dress {
  --fa: "\e548";
  --fa--fa: "\e548\e548"; }

.fa-folder-image {
  --fa: "\e18a";
  --fa--fa: "\e18a\e18a"; }

.fa-calendar-pen {
  --fa: "\f333";
  --fa--fa: "\f333\f333"; }

.fa-calendar-edit {
  --fa: "\f333";
  --fa--fa: "\f333\f333"; }

.fa-road-bridge {
  --fa: "\e563";
  --fa--fa: "\e563\e563"; }

.fa-face-smile-tear {
  --fa: "\e393";
  --fa--fa: "\e393\e393"; }

.fa-message-plus {
  --fa: "\f4a8";
  --fa--fa: "\f4a8\f4a8"; }

.fa-comment-alt-plus {
  --fa: "\f4a8";
  --fa--fa: "\f4a8\f4a8"; }

.fa-location-arrow {
  --fa: "\f124";
  --fa--fa: "\f124\f124"; }

.fa-c {
  --fa: "\43";
  --fa--fa: "\43\43"; }

.fa-tablet-button {
  --fa: "\f10a";
  --fa--fa: "\f10a\f10a"; }

.fa-person-dress-fairy {
  --fa: "\e607";
  --fa--fa: "\e607\e607"; }

.fa-rectangle-history-circle-user {
  --fa: "\e4a4";
  --fa--fa: "\e4a4\e4a4"; }

.fa-building-lock {
  --fa: "\e4d6";
  --fa--fa: "\e4d6\e4d6"; }

.fa-chart-line-up {
  --fa: "\e0e5";
  --fa--fa: "\e0e5\e0e5"; }

.fa-mailbox {
  --fa: "\f813";
  --fa--fa: "\f813\f813"; }

.fa-sign-posts {
  --fa: "\e625";
  --fa--fa: "\e625\e625"; }

.fa-truck-bolt {
  --fa: "\e3d0";
  --fa--fa: "\e3d0\e3d0"; }

.fa-pizza-slice {
  --fa: "\f818";
  --fa--fa: "\f818\f818"; }

.fa-money-bill-wave {
  --fa: "\f53a";
  --fa--fa: "\f53a\f53a"; }

.fa-chart-area {
  --fa: "\f1fe";
  --fa--fa: "\f1fe\f1fe"; }

.fa-area-chart {
  --fa: "\f1fe";
  --fa--fa: "\f1fe\f1fe"; }

.fa-house-flag {
  --fa: "\e50d";
  --fa--fa: "\e50d\e50d"; }

.fa-circle-three-quarters-stroke {
  --fa: "\e5d4";
  --fa--fa: "\e5d4\e5d4"; }

.fa-person-circle-minus {
  --fa: "\e540";
  --fa--fa: "\e540\e540"; }

.fa-scalpel {
  --fa: "\f61d";
  --fa--fa: "\f61d\f61d"; }

.fa-ban {
  --fa: "\f05e";
  --fa--fa: "\f05e\f05e"; }

.fa-cancel {
  --fa: "\f05e";
  --fa--fa: "\f05e\f05e"; }

.fa-bell-exclamation {
  --fa: "\f848";
  --fa--fa: "\f848\f848"; }

.fa-circle-bookmark {
  --fa: "\e100";
  --fa--fa: "\e100\e100"; }

.fa-bookmark-circle {
  --fa: "\e100";
  --fa--fa: "\e100\e100"; }

.fa-egg-fried {
  --fa: "\f7fc";
  --fa--fa: "\f7fc\f7fc"; }

.fa-face-weary {
  --fa: "\e3a1";
  --fa--fa: "\e3a1\e3a1"; }

.fa-uniform-martial-arts {
  --fa: "\e3d1";
  --fa--fa: "\e3d1\e3d1"; }

.fa-camera-rotate {
  --fa: "\e0d8";
  --fa--fa: "\e0d8\e0d8"; }

.fa-sun-dust {
  --fa: "\f764";
  --fa--fa: "\f764\f764"; }

.fa-comment-text {
  --fa: "\e14d";
  --fa--fa: "\e14d\e14d"; }

.fa-spray-can-sparkles {
  --fa: "\f5d0";
  --fa--fa: "\f5d0\f5d0"; }

.fa-air-freshener {
  --fa: "\f5d0";
  --fa--fa: "\f5d0\f5d0"; }

.fa-signal-bars {
  --fa: "\f690";
  --fa--fa: "\f690\f690"; }

.fa-signal-alt {
  --fa: "\f690";
  --fa--fa: "\f690\f690"; }

.fa-signal-alt-4 {
  --fa: "\f690";
  --fa--fa: "\f690\f690"; }

.fa-signal-bars-strong {
  --fa: "\f690";
  --fa--fa: "\f690\f690"; }

.fa-diamond-exclamation {
  --fa: "\e405";
  --fa--fa: "\e405\e405"; }

.fa-star {
  --fa: "\f005";
  --fa--fa: "\f005\f005"; }

.fa-dial-min {
  --fa: "\e161";
  --fa--fa: "\e161\e161"; }

.fa-repeat {
  --fa: "\f363";
  --fa--fa: "\f363\f363"; }

.fa-cross {
  --fa: "\f654";
  --fa--fa: "\f654\f654"; }

.fa-page-caret-down {
  --fa: "\e429";
  --fa--fa: "\e429\e429"; }

.fa-file-caret-down {
  --fa: "\e429";
  --fa--fa: "\e429\e429"; }

.fa-box {
  --fa: "\f466";
  --fa--fa: "\f466\f466"; }

.fa-venus-mars {
  --fa: "\f228";
  --fa--fa: "\f228\f228"; }

.fa-clock-seven-thirty {
  --fa: "\e351";
  --fa--fa: "\e351\e351"; }

.fa-arrow-pointer {
  --fa: "\f245";
  --fa--fa: "\f245\f245"; }

.fa-mouse-pointer {
  --fa: "\f245";
  --fa--fa: "\f245\f245"; }

.fa-clock-four-thirty {
  --fa: "\e34b";
  --fa--fa: "\e34b\e34b"; }

.fa-signal-bars-good {
  --fa: "\f693";
  --fa--fa: "\f693\f693"; }

.fa-signal-alt-3 {
  --fa: "\f693";
  --fa--fa: "\f693\f693"; }

.fa-cactus {
  --fa: "\f8a7";
  --fa--fa: "\f8a7\f8a7"; }

.fa-lightbulb-gear {
  --fa: "\e5fd";
  --fa--fa: "\e5fd\e5fd"; }

.fa-maximize {
  --fa: "\f31e";
  --fa--fa: "\f31e\f31e"; }

.fa-expand-arrows-alt {
  --fa: "\f31e";
  --fa--fa: "\f31e\f31e"; }

.fa-charging-station {
  --fa: "\f5e7";
  --fa--fa: "\f5e7\f5e7"; }

.fa-shapes {
  --fa: "\f61f";
  --fa--fa: "\f61f\f61f"; }

.fa-triangle-circle-square {
  --fa: "\f61f";
  --fa--fa: "\f61f\f61f"; }

.fa-plane-tail {
  --fa: "\e22c";
  --fa--fa: "\e22c\e22c"; }

.fa-gauge-simple-max {
  --fa: "\f62b";
  --fa--fa: "\f62b\f62b"; }

.fa-tachometer-fastest {
  --fa: "\f62b";
  --fa--fa: "\f62b\f62b"; }

.fa-circle-u {
  --fa: "\e127";
  --fa--fa: "\e127\e127"; }

.fa-shield-slash {
  --fa: "\e24b";
  --fa--fa: "\e24b\e24b"; }

.fa-square-phone-hangup {
  --fa: "\e27a";
  --fa--fa: "\e27a\e27a"; }

.fa-phone-square-down {
  --fa: "\e27a";
  --fa--fa: "\e27a\e27a"; }

.fa-arrow-up-left {
  --fa: "\e09d";
  --fa--fa: "\e09d\e09d"; }

.fa-transporter-1 {
  --fa: "\e043";
  --fa--fa: "\e043\e043"; }

.fa-peanuts {
  --fa: "\e431";
  --fa--fa: "\e431\e431"; }

.fa-shuffle {
  --fa: "\f074";
  --fa--fa: "\f074\f074"; }

.fa-random {
  --fa: "\f074";
  --fa--fa: "\f074\f074"; }

.fa-person-running {
  --fa: "\f70c";
  --fa--fa: "\f70c\f70c"; }

.fa-running {
  --fa: "\f70c";
  --fa--fa: "\f70c\f70c"; }

.fa-mobile-retro {
  --fa: "\e527";
  --fa--fa: "\e527\e527"; }

.fa-grip-lines-vertical {
  --fa: "\f7a5";
  --fa--fa: "\f7a5\f7a5"; }

.fa-bin-bottles-recycle {
  --fa: "\e5f6";
  --fa--fa: "\e5f6\e5f6"; }

.fa-arrow-up-from-square {
  --fa: "\e09c";
  --fa--fa: "\e09c\e09c"; }

.fa-file-dashed-line {
  --fa: "\f877";
  --fa--fa: "\f877\f877"; }

.fa-page-break {
  --fa: "\f877";
  --fa--fa: "\f877\f877"; }

.fa-bracket-curly-right {
  --fa: "\7d";
  --fa--fa: "\7d\7d"; }

.fa-spider {
  --fa: "\f717";
  --fa--fa: "\f717\f717"; }

.fa-clock-three {
  --fa: "\e356";
  --fa--fa: "\e356\e356"; }

.fa-hands-bound {
  --fa: "\e4f9";
  --fa--fa: "\e4f9\e4f9"; }

.fa-scalpel-line-dashed {
  --fa: "\f61e";
  --fa--fa: "\f61e\f61e"; }

.fa-scalpel-path {
  --fa: "\f61e";
  --fa--fa: "\f61e\f61e"; }

.fa-file-invoice-dollar {
  --fa: "\f571";
  --fa--fa: "\f571\f571"; }

.fa-pipe-smoking {
  --fa: "\e3c4";
  --fa--fa: "\e3c4\e3c4"; }

.fa-face-astonished {
  --fa: "\e36b";
  --fa--fa: "\e36b\e36b"; }

.fa-window {
  --fa: "\f40e";
  --fa--fa: "\f40e\f40e"; }

.fa-plane-circle-exclamation {
  --fa: "\e556";
  --fa--fa: "\e556\e556"; }

.fa-ear {
  --fa: "\f5f0";
  --fa--fa: "\f5f0\f5f0"; }

.fa-file-lock {
  --fa: "\e3a6";
  --fa--fa: "\e3a6\e3a6"; }

.fa-diagram-venn {
  --fa: "\e15a";
  --fa--fa: "\e15a\e15a"; }

.fa-arrow-down-from-bracket {
  --fa: "\e667";
  --fa--fa: "\e667\e667"; }

.fa-x-ray {
  --fa: "\f497";
  --fa--fa: "\f497\f497"; }

.fa-goal-net {
  --fa: "\e3ab";
  --fa--fa: "\e3ab\e3ab"; }

.fa-coffin-cross {
  --fa: "\e051";
  --fa--fa: "\e051\e051"; }

.fa-octopus {
  --fa: "\e688";
  --fa--fa: "\e688\e688"; }

.fa-spell-check {
  --fa: "\f891";
  --fa--fa: "\f891\f891"; }

.fa-location-xmark {
  --fa: "\f60e";
  --fa--fa: "\f60e\f60e"; }

.fa-map-marker-times {
  --fa: "\f60e";
  --fa--fa: "\f60e\f60e"; }

.fa-map-marker-xmark {
  --fa: "\f60e";
  --fa--fa: "\f60e\f60e"; }

.fa-circle-quarter-stroke {
  --fa: "\e5d3";
  --fa--fa: "\e5d3\e5d3"; }

.fa-lasso {
  --fa: "\f8c8";
  --fa--fa: "\f8c8\f8c8"; }

.fa-slash {
  --fa: "\f715";
  --fa--fa: "\f715\f715"; }

.fa-person-to-portal {
  --fa: "\e022";
  --fa--fa: "\e022\e022"; }

.fa-portal-enter {
  --fa: "\e022";
  --fa--fa: "\e022\e022"; }

.fa-calendar-star {
  --fa: "\f736";
  --fa--fa: "\f736\f736"; }

.fa-computer-mouse {
  --fa: "\f8cc";
  --fa--fa: "\f8cc\f8cc"; }

.fa-mouse {
  --fa: "\f8cc";
  --fa--fa: "\f8cc\f8cc"; }

.fa-arrow-right-to-bracket {
  --fa: "\f090";
  --fa--fa: "\f090\f090"; }

.fa-sign-in {
  --fa: "\f090";
  --fa--fa: "\f090\f090"; }

.fa-pegasus {
  --fa: "\f703";
  --fa--fa: "\f703\f703"; }

.fa-files-medical {
  --fa: "\f7fd";
  --fa--fa: "\f7fd\f7fd"; }

.fa-cannon {
  --fa: "\e642";
  --fa--fa: "\e642\e642"; }

.fa-nfc-lock {
  --fa: "\e1f8";
  --fa--fa: "\e1f8\e1f8"; }

.fa-person-ski-lift {
  --fa: "\f7c8";
  --fa--fa: "\f7c8\f7c8"; }

.fa-ski-lift {
  --fa: "\f7c8";
  --fa--fa: "\f7c8\f7c8"; }

.fa-square-6 {
  --fa: "\e25b";
  --fa--fa: "\e25b\e25b"; }

.fa-shop-slash {
  --fa: "\e070";
  --fa--fa: "\e070\e070"; }

.fa-store-alt-slash {
  --fa: "\e070";
  --fa--fa: "\e070\e070"; }

.fa-wind-turbine {
  --fa: "\f89b";
  --fa--fa: "\f89b\f89b"; }

.fa-sliders-simple {
  --fa: "\e253";
  --fa--fa: "\e253\e253"; }

.fa-grid-round {
  --fa: "\e5da";
  --fa--fa: "\e5da\e5da"; }

.fa-badge-sheriff {
  --fa: "\f8a2";
  --fa--fa: "\f8a2\f8a2"; }

.fa-server {
  --fa: "\f233";
  --fa--fa: "\f233\f233"; }

.fa-virus-covid-slash {
  --fa: "\e4a9";
  --fa--fa: "\e4a9\e4a9"; }

.fa-intersection {
  --fa: "\f668";
  --fa--fa: "\f668\f668"; }

.fa-shop-lock {
  --fa: "\e4a5";
  --fa--fa: "\e4a5\e4a5"; }

.fa-family {
  --fa: "\e300";
  --fa--fa: "\e300\e300"; }

.fa-hourglass-start {
  --fa: "\f251";
  --fa--fa: "\f251\f251"; }

.fa-hourglass-1 {
  --fa: "\f251";
  --fa--fa: "\f251\f251"; }

.fa-user-hair-buns {
  --fa: "\e3d3";
  --fa--fa: "\e3d3\e3d3"; }

.fa-blender-phone {
  --fa: "\f6b6";
  --fa--fa: "\f6b6\f6b6"; }

.fa-hourglass-clock {
  --fa: "\e41b";
  --fa--fa: "\e41b\e41b"; }

.fa-person-seat-reclined {
  --fa: "\e21f";
  --fa--fa: "\e21f\e21f"; }

.fa-paper-plane-top {
  --fa: "\e20a";
  --fa--fa: "\e20a\e20a"; }

.fa-paper-plane-alt {
  --fa: "\e20a";
  --fa--fa: "\e20a\e20a"; }

.fa-send {
  --fa: "\e20a";
  --fa--fa: "\e20a\e20a"; }

.fa-message-arrow-up {
  --fa: "\e1dc";
  --fa--fa: "\e1dc\e1dc"; }

.fa-comment-alt-arrow-up {
  --fa: "\e1dc";
  --fa--fa: "\e1dc\e1dc"; }

.fa-lightbulb-exclamation {
  --fa: "\f671";
  --fa--fa: "\f671\f671"; }

.fa-layer-minus {
  --fa: "\f5fe";
  --fa--fa: "\f5fe\f5fe"; }

.fa-layer-group-minus {
  --fa: "\f5fe";
  --fa--fa: "\f5fe\f5fe"; }

.fa-chart-pie-simple-circle-currency {
  --fa: "\e604";
  --fa--fa: "\e604\e604"; }

.fa-circle-e {
  --fa: "\e109";
  --fa--fa: "\e109\e109"; }

.fa-building-wheat {
  --fa: "\e4db";
  --fa--fa: "\e4db\e4db"; }

.fa-gauge-max {
  --fa: "\f626";
  --fa--fa: "\f626\f626"; }

.fa-tachometer-alt-fastest {
  --fa: "\f626";
  --fa--fa: "\f626\f626"; }

.fa-person-breastfeeding {
  --fa: "\e53a";
  --fa--fa: "\e53a\e53a"; }

.fa-apostrophe {
  --fa: "\27";
  --fa--fa: "\27\27"; }

.fa-file-png {
  --fa: "\e666";
  --fa--fa: "\e666\e666"; }

.fa-fire-hydrant {
  --fa: "\e17f";
  --fa--fa: "\e17f\e17f"; }

.fa-right-to-bracket {
  --fa: "\f2f6";
  --fa--fa: "\f2f6\f2f6"; }

.fa-sign-in-alt {
  --fa: "\f2f6";
  --fa--fa: "\f2f6\f2f6"; }

.fa-video-plus {
  --fa: "\f4e1";
  --fa--fa: "\f4e1\f4e1"; }

.fa-square-right {
  --fa: "\f352";
  --fa--fa: "\f352\f352"; }

.fa-arrow-alt-square-right {
  --fa: "\f352";
  --fa--fa: "\f352\f352"; }

.fa-comment-smile {
  --fa: "\f4b4";
  --fa--fa: "\f4b4\f4b4"; }

.fa-venus {
  --fa: "\f221";
  --fa--fa: "\f221\f221"; }

.fa-passport {
  --fa: "\f5ab";
  --fa--fa: "\f5ab\f5ab"; }

.fa-thumbtack-slash {
  --fa: "\e68f";
  --fa--fa: "\e68f\e68f"; }

.fa-thumb-tack-slash {
  --fa: "\e68f";
  --fa--fa: "\e68f\e68f"; }

.fa-inbox-in {
  --fa: "\f310";
  --fa--fa: "\f310\f310"; }

.fa-inbox-arrow-down {
  --fa: "\f310";
  --fa--fa: "\f310\f310"; }

.fa-heart-pulse {
  --fa: "\f21e";
  --fa--fa: "\f21e\f21e"; }

.fa-heartbeat {
  --fa: "\f21e";
  --fa--fa: "\f21e\f21e"; }

.fa-circle-8 {
  --fa: "\e0f5";
  --fa--fa: "\e0f5\e0f5"; }

.fa-clouds-moon {
  --fa: "\f745";
  --fa--fa: "\f745\f745"; }

.fa-clock-ten-thirty {
  --fa: "\e355";
  --fa--fa: "\e355\e355"; }

.fa-people-carry-box {
  --fa: "\f4ce";
  --fa--fa: "\f4ce\f4ce"; }

.fa-people-carry {
  --fa: "\f4ce";
  --fa--fa: "\f4ce\f4ce"; }

.fa-folder-user {
  --fa: "\e18e";
  --fa--fa: "\e18e\e18e"; }

.fa-trash-can-xmark {
  --fa: "\e2ae";
  --fa--fa: "\e2ae\e2ae"; }

.fa-temperature-high {
  --fa: "\f769";
  --fa--fa: "\f769\f769"; }

.fa-microchip {
  --fa: "\f2db";
  --fa--fa: "\f2db\f2db"; }

.fa-left-long-to-line {
  --fa: "\e41e";
  --fa--fa: "\e41e\e41e"; }

.fa-crown {
  --fa: "\f521";
  --fa--fa: "\f521\f521"; }

.fa-weight-hanging {
  --fa: "\f5cd";
  --fa--fa: "\f5cd\f5cd"; }

.fa-xmarks-lines {
  --fa: "\e59a";
  --fa--fa: "\e59a\e59a"; }

.fa-file-prescription {
  --fa: "\f572";
  --fa--fa: "\f572\f572"; }

.fa-table-cells-lock {
  --fa: "\e679";
  --fa--fa: "\e679\e679"; }

.fa-calendar-range {
  --fa: "\e0d6";
  --fa--fa: "\e0d6\e0d6"; }

.fa-flower-daffodil {
  --fa: "\f800";
  --fa--fa: "\f800\f800"; }

.fa-hand-back-point-up {
  --fa: "\e1a2";
  --fa--fa: "\e1a2\e1a2"; }

.fa-weight-scale {
  --fa: "\f496";
  --fa--fa: "\f496\f496"; }

.fa-weight {
  --fa: "\f496";
  --fa--fa: "\f496\f496"; }

.fa-arrow-up-to-arc {
  --fa: "\e617";
  --fa--fa: "\e617\e617"; }

.fa-star-exclamation {
  --fa: "\f2f3";
  --fa--fa: "\f2f3\f2f3"; }

.fa-books {
  --fa: "\f5db";
  --fa--fa: "\f5db\f5db"; }

.fa-user-group {
  --fa: "\f500";
  --fa--fa: "\f500\f500"; }

.fa-user-friends {
  --fa: "\f500";
  --fa--fa: "\f500\f500"; }

.fa-arrow-up-a-z {
  --fa: "\f15e";
  --fa--fa: "\f15e\f15e"; }

.fa-sort-alpha-up {
  --fa: "\f15e";
  --fa--fa: "\f15e\f15e"; }

.fa-layer-plus {
  --fa: "\f5ff";
  --fa--fa: "\f5ff\f5ff"; }

.fa-layer-group-plus {
  --fa: "\f5ff";
  --fa--fa: "\f5ff\f5ff"; }

.fa-play-pause {
  --fa: "\e22f";
  --fa--fa: "\e22f\e22f"; }

.fa-block-question {
  --fa: "\e3dd";
  --fa--fa: "\e3dd\e3dd"; }

.fa-snooze {
  --fa: "\f880";
  --fa--fa: "\f880\f880"; }

.fa-zzz {
  --fa: "\f880";
  --fa--fa: "\f880\f880"; }

.fa-scanner-image {
  --fa: "\f8f3";
  --fa--fa: "\f8f3\f8f3"; }

.fa-tv-retro {
  --fa: "\f401";
  --fa--fa: "\f401\f401"; }

.fa-square-t {
  --fa: "\e280";
  --fa--fa: "\e280\e280"; }

.fa-farm {
  --fa: "\f864";
  --fa--fa: "\f864\f864"; }

.fa-barn-silo {
  --fa: "\f864";
  --fa--fa: "\f864\f864"; }

.fa-chess-knight {
  --fa: "\f441";
  --fa--fa: "\f441\f441"; }

.fa-bars-sort {
  --fa: "\e0ae";
  --fa--fa: "\e0ae\e0ae"; }

.fa-pallet-boxes {
  --fa: "\f483";
  --fa--fa: "\f483\f483"; }

.fa-palette-boxes {
  --fa: "\f483";
  --fa--fa: "\f483\f483"; }

.fa-pallet-alt {
  --fa: "\f483";
  --fa--fa: "\f483\f483"; }

.fa-face-laugh-squint {
  --fa: "\f59b";
  --fa--fa: "\f59b\f59b"; }

.fa-laugh-squint {
  --fa: "\f59b";
  --fa--fa: "\f59b\f59b"; }

.fa-code-simple {
  --fa: "\e13d";
  --fa--fa: "\e13d\e13d"; }

.fa-bolt-slash {
  --fa: "\e0b8";
  --fa--fa: "\e0b8\e0b8"; }

.fa-panel-fire {
  --fa: "\e42f";
  --fa--fa: "\e42f\e42f"; }

.fa-binary-circle-check {
  --fa: "\e33c";
  --fa--fa: "\e33c\e33c"; }

.fa-comment-minus {
  --fa: "\f4b1";
  --fa--fa: "\f4b1\f4b1"; }

.fa-burrito {
  --fa: "\f7ed";
  --fa--fa: "\f7ed\f7ed"; }

.fa-violin {
  --fa: "\f8ed";
  --fa--fa: "\f8ed\f8ed"; }

.fa-objects-column {
  --fa: "\e3c1";
  --fa--fa: "\e3c1\e3c1"; }

.fa-square-chevron-down {
  --fa: "\f329";
  --fa--fa: "\f329\f329"; }

.fa-chevron-square-down {
  --fa: "\f329";
  --fa--fa: "\f329\f329"; }

.fa-comment-plus {
  --fa: "\f4b2";
  --fa--fa: "\f4b2\f4b2"; }

.fa-triangle-instrument {
  --fa: "\f8e2";
  --fa--fa: "\f8e2\f8e2"; }

.fa-triangle-music {
  --fa: "\f8e2";
  --fa--fa: "\f8e2\f8e2"; }

.fa-wheelchair {
  --fa: "\f193";
  --fa--fa: "\f193\f193"; }

.fa-user-pilot-tie {
  --fa: "\e2c1";
  --fa--fa: "\e2c1\e2c1"; }

.fa-piano-keyboard {
  --fa: "\f8d5";
  --fa--fa: "\f8d5\f8d5"; }

.fa-bed-empty {
  --fa: "\f8f9";
  --fa--fa: "\f8f9\f8f9"; }

.fa-circle-arrow-up {
  --fa: "\f0aa";
  --fa--fa: "\f0aa\f0aa"; }

.fa-arrow-circle-up {
  --fa: "\f0aa";
  --fa--fa: "\f0aa\f0aa"; }

.fa-toggle-on {
  --fa: "\f205";
  --fa--fa: "\f205\f205"; }

.fa-rectangle-vertical {
  --fa: "\f2fb";
  --fa--fa: "\f2fb\f2fb"; }

.fa-rectangle-portrait {
  --fa: "\f2fb";
  --fa--fa: "\f2fb\f2fb"; }

.fa-person-walking {
  --fa: "\f554";
  --fa--fa: "\f554\f554"; }

.fa-walking {
  --fa: "\f554";
  --fa--fa: "\f554\f554"; }

.fa-l {
  --fa: "\4c";
  --fa--fa: "\4c\4c"; }

.fa-signal-stream {
  --fa: "\f8dd";
  --fa--fa: "\f8dd\f8dd"; }

.fa-down-to-bracket {
  --fa: "\e4e7";
  --fa--fa: "\e4e7\e4e7"; }

.fa-circle-z {
  --fa: "\e130";
  --fa--fa: "\e130\e130"; }

.fa-stars {
  --fa: "\f762";
  --fa--fa: "\f762\f762"; }

.fa-fire {
  --fa: "\f06d";
  --fa--fa: "\f06d\f06d"; }

.fa-bed-pulse {
  --fa: "\f487";
  --fa--fa: "\f487\f487"; }

.fa-procedures {
  --fa: "\f487";
  --fa--fa: "\f487\f487"; }

.fa-house-day {
  --fa: "\e00e";
  --fa--fa: "\e00e\e00e"; }

.fa-shuttle-space {
  --fa: "\f197";
  --fa--fa: "\f197\f197"; }

.fa-space-shuttle {
  --fa: "\f197";
  --fa--fa: "\f197\f197"; }

.fa-shirt-long-sleeve {
  --fa: "\e3c7";
  --fa--fa: "\e3c7\e3c7"; }

.fa-chart-pie-simple {
  --fa: "\f64e";
  --fa--fa: "\f64e\f64e"; }

.fa-chart-pie-alt {
  --fa: "\f64e";
  --fa--fa: "\f64e\f64e"; }

.fa-face-laugh {
  --fa: "\f599";
  --fa--fa: "\f599\f599"; }

.fa-laugh {
  --fa: "\f599";
  --fa--fa: "\f599\f599"; }

.fa-folder-open {
  --fa: "\f07c";
  --fa--fa: "\f07c\f07c"; }

.fa-album-collection-circle-user {
  --fa: "\e48f";
  --fa--fa: "\e48f\e48f"; }

.fa-candy {
  --fa: "\e3e7";
  --fa--fa: "\e3e7\e3e7"; }

.fa-bowl-hot {
  --fa: "\f823";
  --fa--fa: "\f823\f823"; }

.fa-soup {
  --fa: "\f823";
  --fa--fa: "\f823\f823"; }

.fa-flatbread {
  --fa: "\e40b";
  --fa--fa: "\e40b\e40b"; }

.fa-heart-circle-plus {
  --fa: "\e500";
  --fa--fa: "\e500\e500"; }

.fa-code-fork {
  --fa: "\e13b";
  --fa--fa: "\e13b\e13b"; }

.fa-city {
  --fa: "\f64f";
  --fa--fa: "\f64f\f64f"; }

.fa-signal-bars-weak {
  --fa: "\f691";
  --fa--fa: "\f691\f691"; }

.fa-signal-alt-1 {
  --fa: "\f691";
  --fa--fa: "\f691\f691"; }

.fa-microphone-lines {
  --fa: "\f3c9";
  --fa--fa: "\f3c9\f3c9"; }

.fa-microphone-alt {
  --fa: "\f3c9";
  --fa--fa: "\f3c9\f3c9"; }

.fa-clock-twelve {
  --fa: "\e358";
  --fa--fa: "\e358\e358"; }

.fa-pepper-hot {
  --fa: "\f816";
  --fa--fa: "\f816\f816"; }

.fa-citrus-slice {
  --fa: "\e2f5";
  --fa--fa: "\e2f5\e2f5"; }

.fa-sheep {
  --fa: "\f711";
  --fa--fa: "\f711\f711"; }

.fa-unlock {
  --fa: "\f09c";
  --fa--fa: "\f09c\f09c"; }

.fa-colon-sign {
  --fa: "\e140";
  --fa--fa: "\e140\e140"; }

.fa-headset {
  --fa: "\f590";
  --fa--fa: "\f590\f590"; }

.fa-badger-honey {
  --fa: "\f6b4";
  --fa--fa: "\f6b4\f6b4"; }

.fa-h4 {
  --fa: "\f86a";
  --fa--fa: "\f86a\f86a"; }

.fa-store-slash {
  --fa: "\e071";
  --fa--fa: "\e071\e071"; }

.fa-road-circle-xmark {
  --fa: "\e566";
  --fa--fa: "\e566\e566"; }

.fa-signal-slash {
  --fa: "\f695";
  --fa--fa: "\f695\f695"; }

.fa-user-minus {
  --fa: "\f503";
  --fa--fa: "\f503\f503"; }

.fa-mars-stroke-up {
  --fa: "\f22a";
  --fa--fa: "\f22a\f22a"; }

.fa-mars-stroke-v {
  --fa: "\f22a";
  --fa--fa: "\f22a\f22a"; }

.fa-champagne-glasses {
  --fa: "\f79f";
  --fa--fa: "\f79f\f79f"; }

.fa-glass-cheers {
  --fa: "\f79f";
  --fa--fa: "\f79f\f79f"; }

.fa-taco {
  --fa: "\f826";
  --fa--fa: "\f826\f826"; }

.fa-hexagon-plus {
  --fa: "\f300";
  --fa--fa: "\f300\f300"; }

.fa-plus-hexagon {
  --fa: "\f300";
  --fa--fa: "\f300\f300"; }

.fa-clipboard {
  --fa: "\f328";
  --fa--fa: "\f328\f328"; }

.fa-house-circle-exclamation {
  --fa: "\e50a";
  --fa--fa: "\e50a\e50a"; }

.fa-file-arrow-up {
  --fa: "\f574";
  --fa--fa: "\f574\f574"; }

.fa-file-upload {
  --fa: "\f574";
  --fa--fa: "\f574\f574"; }

.fa-wifi {
  --fa: "\f1eb";
  --fa--fa: "\f1eb\f1eb"; }

.fa-wifi-3 {
  --fa: "\f1eb";
  --fa--fa: "\f1eb\f1eb"; }

.fa-wifi-strong {
  --fa: "\f1eb";
  --fa--fa: "\f1eb\f1eb"; }

.fa-messages {
  --fa: "\f4b6";
  --fa--fa: "\f4b6\f4b6"; }

.fa-comments-alt {
  --fa: "\f4b6";
  --fa--fa: "\f4b6\f4b6"; }

.fa-bath {
  --fa: "\f2cd";
  --fa--fa: "\f2cd\f2cd"; }

.fa-bathtub {
  --fa: "\f2cd";
  --fa--fa: "\f2cd\f2cd"; }

.fa-umbrella-simple {
  --fa: "\e2bc";
  --fa--fa: "\e2bc\e2bc"; }

.fa-umbrella-alt {
  --fa: "\e2bc";
  --fa--fa: "\e2bc\e2bc"; }

.fa-rectangle-history-circle-plus {
  --fa: "\e4a3";
  --fa--fa: "\e4a3\e4a3"; }

.fa-underline {
  --fa: "\f0cd";
  --fa--fa: "\f0cd\f0cd"; }

.fa-prescription-bottle-pill {
  --fa: "\e5c0";
  --fa--fa: "\e5c0\e5c0"; }

.fa-user-pen {
  --fa: "\f4ff";
  --fa--fa: "\f4ff\f4ff"; }

.fa-user-edit {
  --fa: "\f4ff";
  --fa--fa: "\f4ff\f4ff"; }

.fa-binary-slash {
  --fa: "\e33e";
  --fa--fa: "\e33e\e33e"; }

.fa-square-o {
  --fa: "\e278";
  --fa--fa: "\e278\e278"; }

.fa-caduceus {
  --fa: "\e681";
  --fa--fa: "\e681\e681"; }

.fa-signature {
  --fa: "\f5b7";
  --fa--fa: "\f5b7\f5b7"; }

.fa-stroopwafel {
  --fa: "\f551";
  --fa--fa: "\f551\f551"; }

.fa-bold {
  --fa: "\f032";
  --fa--fa: "\f032\f032"; }

.fa-anchor-lock {
  --fa: "\e4ad";
  --fa--fa: "\e4ad\e4ad"; }

.fa-building-ngo {
  --fa: "\e4d7";
  --fa--fa: "\e4d7\e4d7"; }

.fa-transporter-3 {
  --fa: "\e045";
  --fa--fa: "\e045\e045"; }

.fa-engine-warning {
  --fa: "\f5f2";
  --fa--fa: "\f5f2\f5f2"; }

.fa-engine-exclamation {
  --fa: "\f5f2";
  --fa--fa: "\f5f2\f5f2"; }

.fa-circle-down-right {
  --fa: "\e108";
  --fa--fa: "\e108\e108"; }

.fa-square-k {
  --fa: "\e274";
  --fa--fa: "\e274\e274"; }

.fa-manat-sign {
  --fa: "\e1d5";
  --fa--fa: "\e1d5\e1d5"; }

.fa-money-check-pen {
  --fa: "\f872";
  --fa--fa: "\f872\f872"; }

.fa-money-check-edit {
  --fa: "\f872";
  --fa--fa: "\f872\f872"; }

.fa-not-equal {
  --fa: "\f53e";
  --fa--fa: "\f53e\f53e"; }

.fa-border-top-left {
  --fa: "\f853";
  --fa--fa: "\f853\f853"; }

.fa-border-style {
  --fa: "\f853";
  --fa--fa: "\f853\f853"; }

.fa-map-location-dot {
  --fa: "\f5a0";
  --fa--fa: "\f5a0\f5a0"; }

.fa-map-marked-alt {
  --fa: "\f5a0";
  --fa--fa: "\f5a0\f5a0"; }

.fa-tilde {
  --fa: "\7e";
  --fa--fa: "\7e\7e"; }

.fa-jedi {
  --fa: "\f669";
  --fa--fa: "\f669\f669"; }

.fa-square-poll-vertical {
  --fa: "\f681";
  --fa--fa: "\f681\f681"; }

.fa-poll {
  --fa: "\f681";
  --fa--fa: "\f681\f681"; }

.fa-arrow-down-square-triangle {
  --fa: "\f889";
  --fa--fa: "\f889\f889"; }

.fa-sort-shapes-down-alt {
  --fa: "\f889";
  --fa--fa: "\f889\f889"; }

.fa-mug-hot {
  --fa: "\f7b6";
  --fa--fa: "\f7b6\f7b6"; }

.fa-dog-leashed {
  --fa: "\f6d4";
  --fa--fa: "\f6d4\f6d4"; }

.fa-car-battery {
  --fa: "\f5df";
  --fa--fa: "\f5df\f5df"; }

.fa-battery-car {
  --fa: "\f5df";
  --fa--fa: "\f5df\f5df"; }

.fa-face-downcast-sweat {
  --fa: "\e371";
  --fa--fa: "\e371\e371"; }

.fa-mailbox-flag-up {
  --fa: "\e5bb";
  --fa--fa: "\e5bb\e5bb"; }

.fa-memo-circle-info {
  --fa: "\e49a";
  --fa--fa: "\e49a\e49a"; }

.fa-gift {
  --fa: "\f06b";
  --fa--fa: "\f06b\f06b"; }

.fa-dice-two {
  --fa: "\f528";
  --fa--fa: "\f528\f528"; }

.fa-volume {
  --fa: "\f6a8";
  --fa--fa: "\f6a8\f6a8"; }

.fa-volume-medium {
  --fa: "\f6a8";
  --fa--fa: "\f6a8\f6a8"; }

.fa-transporter-5 {
  --fa: "\e2a6";
  --fa--fa: "\e2a6\e2a6"; }

.fa-gauge-circle-bolt {
  --fa: "\e496";
  --fa--fa: "\e496\e496"; }

.fa-coin-front {
  --fa: "\e3fc";
  --fa--fa: "\e3fc\e3fc"; }

.fa-file-slash {
  --fa: "\e3a7";
  --fa--fa: "\e3a7\e3a7"; }

.fa-message-arrow-up-right {
  --fa: "\e1dd";
  --fa--fa: "\e1dd\e1dd"; }

.fa-treasure-chest {
  --fa: "\f723";
  --fa--fa: "\f723\f723"; }

.fa-chess-queen {
  --fa: "\f445";
  --fa--fa: "\f445\f445"; }

.fa-paintbrush-fine {
  --fa: "\f5a9";
  --fa--fa: "\f5a9\f5a9"; }

.fa-paint-brush-alt {
  --fa: "\f5a9";
  --fa--fa: "\f5a9\f5a9"; }

.fa-paint-brush-fine {
  --fa: "\f5a9";
  --fa--fa: "\f5a9\f5a9"; }

.fa-paintbrush-alt {
  --fa: "\f5a9";
  --fa--fa: "\f5a9\f5a9"; }

.fa-glasses {
  --fa: "\f530";
  --fa--fa: "\f530\f530"; }

.fa-hood-cloak {
  --fa: "\f6ef";
  --fa--fa: "\f6ef\f6ef"; }

.fa-square-quote {
  --fa: "\e329";
  --fa--fa: "\e329\e329"; }

.fa-up-left {
  --fa: "\e2bd";
  --fa--fa: "\e2bd\e2bd"; }

.fa-bring-front {
  --fa: "\f857";
  --fa--fa: "\f857\f857"; }

.fa-chess-board {
  --fa: "\f43c";
  --fa--fa: "\f43c\f43c"; }

.fa-burger-cheese {
  --fa: "\f7f1";
  --fa--fa: "\f7f1\f7f1"; }

.fa-cheeseburger {
  --fa: "\f7f1";
  --fa--fa: "\f7f1\f7f1"; }

.fa-building-circle-check {
  --fa: "\e4d2";
  --fa--fa: "\e4d2\e4d2"; }

.fa-repeat-1 {
  --fa: "\f365";
  --fa--fa: "\f365\f365"; }

.fa-arrow-down-to-line {
  --fa: "\f33d";
  --fa--fa: "\f33d\f33d"; }

.fa-arrow-to-bottom {
  --fa: "\f33d";
  --fa--fa: "\f33d\f33d"; }

.fa-grid-5 {
  --fa: "\e199";
  --fa--fa: "\e199\e199"; }

.fa-swap-arrows {
  --fa: "\e60a";
  --fa--fa: "\e60a\e60a"; }

.fa-right-long-to-line {
  --fa: "\e444";
  --fa--fa: "\e444\e444"; }

.fa-person-chalkboard {
  --fa: "\e53d";
  --fa--fa: "\e53d\e53d"; }

.fa-mars-stroke-right {
  --fa: "\f22b";
  --fa--fa: "\f22b\f22b"; }

.fa-mars-stroke-h {
  --fa: "\f22b";
  --fa--fa: "\f22b\f22b"; }

.fa-hand-back-fist {
  --fa: "\f255";
  --fa--fa: "\f255\f255"; }

.fa-hand-rock {
  --fa: "\f255";
  --fa--fa: "\f255\f255"; }

.fa-grid-round-5 {
  --fa: "\e5de";
  --fa--fa: "\e5de\e5de"; }

.fa-tally {
  --fa: "\f69c";
  --fa--fa: "\f69c\f69c"; }

.fa-tally-5 {
  --fa: "\f69c";
  --fa--fa: "\f69c\f69c"; }

.fa-square-caret-up {
  --fa: "\f151";
  --fa--fa: "\f151\f151"; }

.fa-caret-square-up {
  --fa: "\f151";
  --fa--fa: "\f151\f151"; }

.fa-cloud-showers-water {
  --fa: "\e4e4";
  --fa--fa: "\e4e4\e4e4"; }

.fa-chart-bar {
  --fa: "\f080";
  --fa--fa: "\f080\f080"; }

.fa-bar-chart {
  --fa: "\f080";
  --fa--fa: "\f080\f080"; }

.fa-hands-bubbles {
  --fa: "\e05e";
  --fa--fa: "\e05e\e05e"; }

.fa-hands-wash {
  --fa: "\e05e";
  --fa--fa: "\e05e\e05e"; }

.fa-less-than-equal {
  --fa: "\f537";
  --fa--fa: "\f537\f537"; }

.fa-train {
  --fa: "\f238";
  --fa--fa: "\f238\f238"; }

.fa-up-from-dotted-line {
  --fa: "\e456";
  --fa--fa: "\e456\e456"; }

.fa-eye-low-vision {
  --fa: "\f2a8";
  --fa--fa: "\f2a8\f2a8"; }

.fa-low-vision {
  --fa: "\f2a8";
  --fa--fa: "\f2a8\f2a8"; }

.fa-traffic-light-go {
  --fa: "\f638";
  --fa--fa: "\f638\f638"; }

.fa-face-exhaling {
  --fa: "\e480";
  --fa--fa: "\e480\e480"; }

.fa-sensor-fire {
  --fa: "\e02a";
  --fa--fa: "\e02a\e02a"; }

.fa-user-unlock {
  --fa: "\e058";
  --fa--fa: "\e058\e058"; }

.fa-hexagon-divide {
  --fa: "\e1ad";
  --fa--fa: "\e1ad\e1ad"; }

.fa-00 {
  --fa: "\e467";
  --fa--fa: "\e467\e467"; }

.fa-crow {
  --fa: "\f520";
  --fa--fa: "\f520\f520"; }

.fa-cassette-betamax {
  --fa: "\f8a4";
  --fa--fa: "\f8a4\f8a4"; }

.fa-betamax {
  --fa: "\f8a4";
  --fa--fa: "\f8a4\f8a4"; }

.fa-sailboat {
  --fa: "\e445";
  --fa--fa: "\e445\e445"; }

.fa-window-restore {
  --fa: "\f2d2";
  --fa--fa: "\f2d2\f2d2"; }

.fa-nfc-magnifying-glass {
  --fa: "\e1f9";
  --fa--fa: "\e1f9\e1f9"; }

.fa-file-binary {
  --fa: "\e175";
  --fa--fa: "\e175\e175"; }

.fa-circle-v {
  --fa: "\e12a";
  --fa--fa: "\e12a\e12a"; }

.fa-square-plus {
  --fa: "\f0fe";
  --fa--fa: "\f0fe\f0fe"; }

.fa-plus-square {
  --fa: "\f0fe";
  --fa--fa: "\f0fe\f0fe"; }

.fa-bowl-scoops {
  --fa: "\e3df";
  --fa--fa: "\e3df\e3df"; }

.fa-mistletoe {
  --fa: "\f7b4";
  --fa--fa: "\f7b4\f7b4"; }

.fa-custard {
  --fa: "\e403";
  --fa--fa: "\e403\e403"; }

.fa-lacrosse-stick {
  --fa: "\e3b5";
  --fa--fa: "\e3b5\e3b5"; }

.fa-hockey-mask {
  --fa: "\f6ee";
  --fa--fa: "\f6ee\f6ee"; }

.fa-sunrise {
  --fa: "\f766";
  --fa--fa: "\f766\f766"; }

.fa-subtitles {
  --fa: "\e60f";
  --fa--fa: "\e60f\e60f"; }

.fa-panel-ews {
  --fa: "\e42e";
  --fa--fa: "\e42e\e42e"; }

.fa-torii-gate {
  --fa: "\f6a1";
  --fa--fa: "\f6a1\f6a1"; }

.fa-cloud-exclamation {
  --fa: "\e491";
  --fa--fa: "\e491\e491"; }

.fa-message-lines {
  --fa: "\f4a6";
  --fa--fa: "\f4a6\f4a6"; }

.fa-comment-alt-lines {
  --fa: "\f4a6";
  --fa--fa: "\f4a6\f4a6"; }

.fa-frog {
  --fa: "\f52e";
  --fa--fa: "\f52e\f52e"; }

.fa-bucket {
  --fa: "\e4cf";
  --fa--fa: "\e4cf\e4cf"; }

.fa-floppy-disk-pen {
  --fa: "\e182";
  --fa--fa: "\e182\e182"; }

.fa-image {
  --fa: "\f03e";
  --fa--fa: "\f03e\f03e"; }

.fa-window-frame {
  --fa: "\e04f";
  --fa--fa: "\e04f\e04f"; }

.fa-microphone {
  --fa: "\f130";
  --fa--fa: "\f130\f130"; }

.fa-cow {
  --fa: "\f6c8";
  --fa--fa: "\f6c8\f6c8"; }

.fa-file-zip {
  --fa: "\e5ee";
  --fa--fa: "\e5ee\e5ee"; }

.fa-square-ring {
  --fa: "\e44f";
  --fa--fa: "\e44f\e44f"; }

.fa-down-from-line {
  --fa: "\f349";
  --fa--fa: "\f349\f349"; }

.fa-arrow-alt-from-top {
  --fa: "\f349";
  --fa--fa: "\f349\f349"; }

.fa-caret-up {
  --fa: "\f0d8";
  --fa--fa: "\f0d8\f0d8"; }

.fa-shield-xmark {
  --fa: "\e24c";
  --fa--fa: "\e24c\e24c"; }

.fa-shield-times {
  --fa: "\e24c";
  --fa--fa: "\e24c\e24c"; }

.fa-screwdriver {
  --fa: "\f54a";
  --fa--fa: "\f54a\f54a"; }

.fa-circle-sort-down {
  --fa: "\e031";
  --fa--fa: "\e031\e031"; }

.fa-sort-circle-down {
  --fa: "\e031";
  --fa--fa: "\e031\e031"; }

.fa-folder-closed {
  --fa: "\e185";
  --fa--fa: "\e185\e185"; }

.fa-house-tsunami {
  --fa: "\e515";
  --fa--fa: "\e515\e515"; }

.fa-square-nfi {
  --fa: "\e576";
  --fa--fa: "\e576\e576"; }

.fa-forklift {
  --fa: "\f47a";
  --fa--fa: "\f47a\f47a"; }

.fa-arrow-up-from-ground-water {
  --fa: "\e4b5";
  --fa--fa: "\e4b5\e4b5"; }

.fa-bracket-square-right {
  --fa: "\5d";
  --fa--fa: "\5d\5d"; }

.fa-martini-glass {
  --fa: "\f57b";
  --fa--fa: "\f57b\f57b"; }

.fa-glass-martini-alt {
  --fa: "\f57b";
  --fa--fa: "\f57b\f57b"; }

.fa-square-binary {
  --fa: "\e69b";
  --fa--fa: "\e69b\e69b"; }

.fa-rotate-left {
  --fa: "\f2ea";
  --fa--fa: "\f2ea\f2ea"; }

.fa-rotate-back {
  --fa: "\f2ea";
  --fa--fa: "\f2ea\f2ea"; }

.fa-rotate-backward {
  --fa: "\f2ea";
  --fa--fa: "\f2ea\f2ea"; }

.fa-undo-alt {
  --fa: "\f2ea";
  --fa--fa: "\f2ea\f2ea"; }

.fa-table-columns {
  --fa: "\f0db";
  --fa--fa: "\f0db\f0db"; }

.fa-columns {
  --fa: "\f0db";
  --fa--fa: "\f0db\f0db"; }

.fa-square-a {
  --fa: "\e25f";
  --fa--fa: "\e25f\e25f"; }

.fa-tick {
  --fa: "\e32f";
  --fa--fa: "\e32f\e32f"; }

.fa-lemon {
  --fa: "\f094";
  --fa--fa: "\f094\f094"; }

.fa-head-side-mask {
  --fa: "\e063";
  --fa--fa: "\e063\e063"; }

.fa-handshake {
  --fa: "\f2b5";
  --fa--fa: "\f2b5\f2b5"; }

.fa-gem {
  --fa: "\f3a5";
  --fa--fa: "\f3a5\f3a5"; }

.fa-dolly {
  --fa: "\f472";
  --fa--fa: "\f472\f472"; }

.fa-dolly-box {
  --fa: "\f472";
  --fa--fa: "\f472\f472"; }

.fa-smoking {
  --fa: "\f48d";
  --fa--fa: "\f48d\f48d"; }

.fa-minimize {
  --fa: "\f78c";
  --fa--fa: "\f78c\f78c"; }

.fa-compress-arrows-alt {
  --fa: "\f78c";
  --fa--fa: "\f78c\f78c"; }

.fa-refrigerator {
  --fa: "\e026";
  --fa--fa: "\e026\e026"; }

.fa-monument {
  --fa: "\f5a6";
  --fa--fa: "\f5a6\f5a6"; }

.fa-octagon-xmark {
  --fa: "\f2f0";
  --fa--fa: "\f2f0\f2f0"; }

.fa-times-octagon {
  --fa: "\f2f0";
  --fa--fa: "\f2f0\f2f0"; }

.fa-xmark-octagon {
  --fa: "\f2f0";
  --fa--fa: "\f2f0\f2f0"; }

.fa-align-slash {
  --fa: "\f846";
  --fa--fa: "\f846\f846"; }

.fa-snowplow {
  --fa: "\f7d2";
  --fa--fa: "\f7d2\f7d2"; }

.fa-angles-right {
  --fa: "\f101";
  --fa--fa: "\f101\f101"; }

.fa-angle-double-right {
  --fa: "\f101";
  --fa--fa: "\f101\f101"; }

.fa-truck-ramp-couch {
  --fa: "\f4dd";
  --fa--fa: "\f4dd\f4dd"; }

.fa-truck-couch {
  --fa: "\f4dd";
  --fa--fa: "\f4dd\f4dd"; }

.fa-cannabis {
  --fa: "\f55f";
  --fa--fa: "\f55f\f55f"; }

.fa-circle-play {
  --fa: "\f144";
  --fa--fa: "\f144\f144"; }

.fa-play-circle {
  --fa: "\f144";
  --fa--fa: "\f144\f144"; }

.fa-arrow-up-right-and-arrow-down-left-from-center {
  --fa: "\e0a0";
  --fa--fa: "\e0a0\e0a0"; }

.fa-location-arrow-up {
  --fa: "\e63a";
  --fa--fa: "\e63a\e63a"; }

.fa-tablets {
  --fa: "\f490";
  --fa--fa: "\f490\f490"; }

.fa-360-degrees {
  --fa: "\e2dc";
  --fa--fa: "\e2dc\e2dc"; }

.fa-ethernet {
  --fa: "\f796";
  --fa--fa: "\f796\f796"; }

.fa-euro-sign {
  --fa: "\f153";
  --fa--fa: "\f153\f153"; }

.fa-eur {
  --fa: "\f153";
  --fa--fa: "\f153\f153"; }

.fa-euro {
  --fa: "\f153";
  --fa--fa: "\f153\f153"; }

.fa-chair {
  --fa: "\f6c0";
  --fa--fa: "\f6c0\f6c0"; }

.fa-circle-check {
  --fa: "\f058";
  --fa--fa: "\f058\f058"; }

.fa-check-circle {
  --fa: "\f058";
  --fa--fa: "\f058\f058"; }

.fa-square-dashed-circle-plus {
  --fa: "\e5c2";
  --fa--fa: "\e5c2\e5c2"; }

.fa-hand-holding-circle-dollar {
  --fa: "\e621";
  --fa--fa: "\e621\e621"; }

.fa-money-simple-from-bracket {
  --fa: "\e313";
  --fa--fa: "\e313\e313"; }

.fa-bat {
  --fa: "\f6b5";
  --fa--fa: "\f6b5\f6b5"; }

.fa-circle-stop {
  --fa: "\f28d";
  --fa--fa: "\f28d\f28d"; }

.fa-stop-circle {
  --fa: "\f28d";
  --fa--fa: "\f28d\f28d"; }

.fa-head-side-headphones {
  --fa: "\f8c2";
  --fa--fa: "\f8c2\f8c2"; }

.fa-phone-rotary {
  --fa: "\f8d3";
  --fa--fa: "\f8d3\f8d3"; }

.fa-arrow-up-to-bracket {
  --fa: "\e66a";
  --fa--fa: "\e66a\e66a"; }

.fa-compass-drafting {
  --fa: "\f568";
  --fa--fa: "\f568\f568"; }

.fa-drafting-compass {
  --fa: "\f568";
  --fa--fa: "\f568\f568"; }

.fa-plate-wheat {
  --fa: "\e55a";
  --fa--fa: "\e55a\e55a"; }

.fa-calendar-circle-minus {
  --fa: "\e46f";
  --fa--fa: "\e46f\e46f"; }

.fa-chopsticks {
  --fa: "\e3f7";
  --fa--fa: "\e3f7\e3f7"; }

.fa-car-wrench {
  --fa: "\f5e3";
  --fa--fa: "\f5e3\f5e3"; }

.fa-car-mechanic {
  --fa: "\f5e3";
  --fa--fa: "\f5e3\f5e3"; }

.fa-icicles {
  --fa: "\f7ad";
  --fa--fa: "\f7ad\f7ad"; }

.fa-person-shelter {
  --fa: "\e54f";
  --fa--fa: "\e54f\e54f"; }

.fa-neuter {
  --fa: "\f22c";
  --fa--fa: "\f22c\f22c"; }

.fa-id-badge {
  --fa: "\f2c1";
  --fa--fa: "\f2c1\f2c1"; }

.fa-kazoo {
  --fa: "\f8c7";
  --fa--fa: "\f8c7\f8c7"; }

.fa-marker {
  --fa: "\f5a1";
  --fa--fa: "\f5a1\f5a1"; }

.fa-bin-bottles {
  --fa: "\e5f5";
  --fa--fa: "\e5f5\e5f5"; }

.fa-face-laugh-beam {
  --fa: "\f59a";
  --fa--fa: "\f59a\f59a"; }

.fa-laugh-beam {
  --fa: "\f59a";
  --fa--fa: "\f59a\f59a"; }

.fa-square-arrow-down-left {
  --fa: "\e261";
  --fa--fa: "\e261\e261"; }

.fa-battery-bolt {
  --fa: "\f376";
  --fa--fa: "\f376\f376"; }

.fa-tree-large {
  --fa: "\f7dd";
  --fa--fa: "\f7dd\f7dd"; }

.fa-helicopter-symbol {
  --fa: "\e502";
  --fa--fa: "\e502\e502"; }

.fa-aperture {
  --fa: "\e2df";
  --fa--fa: "\e2df\e2df"; }

.fa-universal-access {
  --fa: "\f29a";
  --fa--fa: "\f29a\f29a"; }

.fa-gear-complex {
  --fa: "\e5e9";
  --fa--fa: "\e5e9\e5e9"; }

.fa-file-magnifying-glass {
  --fa: "\f865";
  --fa--fa: "\f865\f865"; }

.fa-file-search {
  --fa: "\f865";
  --fa--fa: "\f865\f865"; }

.fa-up-right {
  --fa: "\e2be";
  --fa--fa: "\e2be\e2be"; }

.fa-circle-chevron-up {
  --fa: "\f139";
  --fa--fa: "\f139\f139"; }

.fa-chevron-circle-up {
  --fa: "\f139";
  --fa--fa: "\f139\f139"; }

.fa-user-police {
  --fa: "\e333";
  --fa--fa: "\e333\e333"; }

.fa-lari-sign {
  --fa: "\e1c8";
  --fa--fa: "\e1c8\e1c8"; }

.fa-volcano {
  --fa: "\f770";
  --fa--fa: "\f770\f770"; }

.fa-teddy-bear {
  --fa: "\e3cf";
  --fa--fa: "\e3cf\e3cf"; }

.fa-stocking {
  --fa: "\f7d5";
  --fa--fa: "\f7d5\f7d5"; }

.fa-person-walking-dashed-line-arrow-right {
  --fa: "\e553";
  --fa--fa: "\e553\e553"; }

.fa-image-slash {
  --fa: "\e1b7";
  --fa--fa: "\e1b7\e1b7"; }

.fa-mask-snorkel {
  --fa: "\e3b7";
  --fa--fa: "\e3b7\e3b7"; }

.fa-smoke {
  --fa: "\f760";
  --fa--fa: "\f760\f760"; }

.fa-sterling-sign {
  --fa: "\f154";
  --fa--fa: "\f154\f154"; }

.fa-gbp {
  --fa: "\f154";
  --fa--fa: "\f154\f154"; }

.fa-pound-sign {
  --fa: "\f154";
  --fa--fa: "\f154\f154"; }

.fa-battery-exclamation {
  --fa: "\e0b0";
  --fa--fa: "\e0b0\e0b0"; }

.fa-viruses {
  --fa: "\e076";
  --fa--fa: "\e076\e076"; }

.fa-square-person-confined {
  --fa: "\e577";
  --fa--fa: "\e577\e577"; }

.fa-user-tie {
  --fa: "\f508";
  --fa--fa: "\f508\f508"; }

.fa-up-to-bracket {
  --fa: "\e66e";
  --fa--fa: "\e66e\e66e"; }

.fa-arrow-down-long {
  --fa: "\f175";
  --fa--fa: "\f175\f175"; }

.fa-long-arrow-down {
  --fa: "\f175";
  --fa--fa: "\f175\f175"; }

.fa-tent-arrow-down-to-line {
  --fa: "\e57e";
  --fa--fa: "\e57e\e57e"; }

.fa-certificate {
  --fa: "\f0a3";
  --fa--fa: "\f0a3\f0a3"; }

.fa-crystal-ball {
  --fa: "\e362";
  --fa--fa: "\e362\e362"; }

.fa-reply-all {
  --fa: "\f122";
  --fa--fa: "\f122\f122"; }

.fa-mail-reply-all {
  --fa: "\f122";
  --fa--fa: "\f122\f122"; }

.fa-suitcase {
  --fa: "\f0f2";
  --fa--fa: "\f0f2\f0f2"; }

.fa-person-skating {
  --fa: "\f7c5";
  --fa--fa: "\f7c5\f7c5"; }

.fa-skating {
  --fa: "\f7c5";
  --fa--fa: "\f7c5\f7c5"; }

.fa-star-shooting {
  --fa: "\e036";
  --fa--fa: "\e036\e036"; }

.fa-binary-lock {
  --fa: "\e33d";
  --fa--fa: "\e33d\e33d"; }

.fa-filter-circle-dollar {
  --fa: "\f662";
  --fa--fa: "\f662\f662"; }

.fa-funnel-dollar {
  --fa: "\f662";
  --fa--fa: "\f662\f662"; }

.fa-camera-retro {
  --fa: "\f083";
  --fa--fa: "\f083\f083"; }

.fa-circle-arrow-down {
  --fa: "\f0ab";
  --fa--fa: "\f0ab\f0ab"; }

.fa-arrow-circle-down {
  --fa: "\f0ab";
  --fa--fa: "\f0ab\f0ab"; }

.fa-comment-pen {
  --fa: "\f4ae";
  --fa--fa: "\f4ae\f4ae"; }

.fa-comment-edit {
  --fa: "\f4ae";
  --fa--fa: "\f4ae\f4ae"; }

.fa-file-import {
  --fa: "\f56f";
  --fa--fa: "\f56f\f56f"; }

.fa-arrow-right-to-file {
  --fa: "\f56f";
  --fa--fa: "\f56f\f56f"; }

.fa-banjo {
  --fa: "\f8a3";
  --fa--fa: "\f8a3\f8a3"; }

.fa-square-arrow-up-right {
  --fa: "\f14c";
  --fa--fa: "\f14c\f14c"; }

.fa-external-link-square {
  --fa: "\f14c";
  --fa--fa: "\f14c\f14c"; }

.fa-light-emergency-on {
  --fa: "\e420";
  --fa--fa: "\e420\e420"; }

.fa-kerning {
  --fa: "\f86f";
  --fa--fa: "\f86f\f86f"; }

.fa-box-open {
  --fa: "\f49e";
  --fa--fa: "\f49e\f49e"; }

.fa-square-f {
  --fa: "\e270";
  --fa--fa: "\e270\e270"; }

.fa-scroll {
  --fa: "\f70e";
  --fa--fa: "\f70e\f70e"; }

.fa-spa {
  --fa: "\f5bb";
  --fa--fa: "\f5bb\f5bb"; }

.fa-arrow-left-from-line {
  --fa: "\f344";
  --fa--fa: "\f344\f344"; }

.fa-arrow-from-right {
  --fa: "\f344";
  --fa--fa: "\f344\f344"; }

.fa-strawberry {
  --fa: "\e32b";
  --fa--fa: "\e32b\e32b"; }

.fa-location-pin-lock {
  --fa: "\e51f";
  --fa--fa: "\e51f\e51f"; }

.fa-pause {
  --fa: "\f04c";
  --fa--fa: "\f04c\f04c"; }

.fa-clock-eight-thirty {
  --fa: "\e346";
  --fa--fa: "\e346\e346"; }

.fa-plane-engines {
  --fa: "\f3de";
  --fa--fa: "\f3de\f3de"; }

.fa-plane-alt {
  --fa: "\f3de";
  --fa--fa: "\f3de\f3de"; }

.fa-hill-avalanche {
  --fa: "\e507";
  --fa--fa: "\e507\e507"; }

.fa-temperature-empty {
  --fa: "\f2cb";
  --fa--fa: "\f2cb\f2cb"; }

.fa-temperature-0 {
  --fa: "\f2cb";
  --fa--fa: "\f2cb\f2cb"; }

.fa-thermometer-0 {
  --fa: "\f2cb";
  --fa--fa: "\f2cb\f2cb"; }

.fa-thermometer-empty {
  --fa: "\f2cb";
  --fa--fa: "\f2cb\f2cb"; }

.fa-bomb {
  --fa: "\f1e2";
  --fa--fa: "\f1e2\f1e2"; }

.fa-gauge-low {
  --fa: "\f627";
  --fa--fa: "\f627\f627"; }

.fa-tachometer-alt-slow {
  --fa: "\f627";
  --fa--fa: "\f627\f627"; }

.fa-registered {
  --fa: "\f25d";
  --fa--fa: "\f25d\f25d"; }

.fa-trash-can-plus {
  --fa: "\e2ac";
  --fa--fa: "\e2ac\e2ac"; }

.fa-address-card {
  --fa: "\f2bb";
  --fa--fa: "\f2bb\f2bb"; }

.fa-contact-card {
  --fa: "\f2bb";
  --fa--fa: "\f2bb\f2bb"; }

.fa-vcard {
  --fa: "\f2bb";
  --fa--fa: "\f2bb\f2bb"; }

.fa-chart-fft {
  --fa: "\e69e";
  --fa--fa: "\e69e\e69e"; }

.fa-scale-unbalanced-flip {
  --fa: "\f516";
  --fa--fa: "\f516\f516"; }

.fa-balance-scale-right {
  --fa: "\f516";
  --fa--fa: "\f516\f516"; }

.fa-globe-snow {
  --fa: "\f7a3";
  --fa--fa: "\f7a3\f7a3"; }

.fa-subscript {
  --fa: "\f12c";
  --fa--fa: "\f12c\f12c"; }

.fa-diamond-turn-right {
  --fa: "\f5eb";
  --fa--fa: "\f5eb\f5eb"; }

.fa-directions {
  --fa: "\f5eb";
  --fa--fa: "\f5eb\f5eb"; }

.fa-integral {
  --fa: "\f667";
  --fa--fa: "\f667\f667"; }

.fa-burst {
  --fa: "\e4dc";
  --fa--fa: "\e4dc\e4dc"; }

.fa-house-laptop {
  --fa: "\e066";
  --fa--fa: "\e066\e066"; }

.fa-laptop-house {
  --fa: "\e066";
  --fa--fa: "\e066\e066"; }

.fa-face-tired {
  --fa: "\f5c8";
  --fa--fa: "\f5c8\f5c8"; }

.fa-tired {
  --fa: "\f5c8";
  --fa--fa: "\f5c8\f5c8"; }

.fa-money-bills {
  --fa: "\e1f3";
  --fa--fa: "\e1f3\e1f3"; }

.fa-blinds-raised {
  --fa: "\f8fd";
  --fa--fa: "\f8fd\f8fd"; }

.fa-smog {
  --fa: "\f75f";
  --fa--fa: "\f75f\f75f"; }

.fa-ufo-beam {
  --fa: "\e048";
  --fa--fa: "\e048\e048"; }

.fa-hydra {
  --fa: "\e686";
  --fa--fa: "\e686\e686"; }

.fa-circle-caret-up {
  --fa: "\f331";
  --fa--fa: "\f331\f331"; }

.fa-caret-circle-up {
  --fa: "\f331";
  --fa--fa: "\f331\f331"; }

.fa-user-vneck-hair-long {
  --fa: "\e463";
  --fa--fa: "\e463\e463"; }

.fa-square-a-lock {
  --fa: "\e44d";
  --fa--fa: "\e44d\e44d"; }

.fa-crutch {
  --fa: "\f7f7";
  --fa--fa: "\f7f7\f7f7"; }

.fa-gas-pump-slash {
  --fa: "\f5f4";
  --fa--fa: "\f5f4\f5f4"; }

.fa-cloud-arrow-up {
  --fa: "\f0ee";
  --fa--fa: "\f0ee\f0ee"; }

.fa-cloud-upload {
  --fa: "\f0ee";
  --fa--fa: "\f0ee\f0ee"; }

.fa-cloud-upload-alt {
  --fa: "\f0ee";
  --fa--fa: "\f0ee\f0ee"; }

.fa-palette {
  --fa: "\f53f";
  --fa--fa: "\f53f\f53f"; }

.fa-transporter-4 {
  --fa: "\e2a5";
  --fa--fa: "\e2a5\e2a5"; }

.fa-chart-mixed-up-circle-currency {
  --fa: "\e5d8";
  --fa--fa: "\e5d8\e5d8"; }

.fa-objects-align-right {
  --fa: "\e3bf";
  --fa--fa: "\e3bf\e3bf"; }

.fa-arrows-turn-right {
  --fa: "\e4c0";
  --fa--fa: "\e4c0\e4c0"; }

.fa-vest {
  --fa: "\e085";
  --fa--fa: "\e085\e085"; }

.fa-pig {
  --fa: "\f706";
  --fa--fa: "\f706\f706"; }

.fa-inbox-full {
  --fa: "\e1ba";
  --fa--fa: "\e1ba\e1ba"; }

.fa-circle-envelope {
  --fa: "\e10c";
  --fa--fa: "\e10c\e10c"; }

.fa-envelope-circle {
  --fa: "\e10c";
  --fa--fa: "\e10c\e10c"; }

.fa-triangle-person-digging {
  --fa: "\f85d";
  --fa--fa: "\f85d\f85d"; }

.fa-construction {
  --fa: "\f85d";
  --fa--fa: "\f85d\f85d"; }

.fa-ferry {
  --fa: "\e4ea";
  --fa--fa: "\e4ea\e4ea"; }

.fa-bullseye-arrow {
  --fa: "\f648";
  --fa--fa: "\f648\f648"; }

.fa-arrows-down-to-people {
  --fa: "\e4b9";
  --fa--fa: "\e4b9\e4b9"; }

.fa-seedling {
  --fa: "\f4d8";
  --fa--fa: "\f4d8\f4d8"; }

.fa-sprout {
  --fa: "\f4d8";
  --fa--fa: "\f4d8\f4d8"; }

.fa-clock-seven {
  --fa: "\e350";
  --fa--fa: "\e350\e350"; }

.fa-left-right {
  --fa: "\f337";
  --fa--fa: "\f337\f337"; }

.fa-arrows-alt-h {
  --fa: "\f337";
  --fa--fa: "\f337\f337"; }

.fa-boxes-packing {
  --fa: "\e4c7";
  --fa--fa: "\e4c7\e4c7"; }

.fa-circle-arrow-left {
  --fa: "\f0a8";
  --fa--fa: "\f0a8\f0a8"; }

.fa-arrow-circle-left {
  --fa: "\f0a8";
  --fa--fa: "\f0a8\f0a8"; }

.fa-flashlight {
  --fa: "\f8b8";
  --fa--fa: "\f8b8\f8b8"; }

.fa-file-jpg {
  --fa: "\e646";
  --fa--fa: "\e646\e646"; }

.fa-group-arrows-rotate {
  --fa: "\e4f6";
  --fa--fa: "\e4f6\e4f6"; }

.fa-bowl-food {
  --fa: "\e4c6";
  --fa--fa: "\e4c6\e4c6"; }

.fa-square-9 {
  --fa: "\e25e";
  --fa--fa: "\e25e\e25e"; }

.fa-candy-cane {
  --fa: "\f786";
  --fa--fa: "\f786\f786"; }

.fa-arrow-down-wide-short {
  --fa: "\f160";
  --fa--fa: "\f160\f160"; }

.fa-sort-amount-asc {
  --fa: "\f160";
  --fa--fa: "\f160\f160"; }

.fa-sort-amount-down {
  --fa: "\f160";
  --fa--fa: "\f160\f160"; }

.fa-square-dollar {
  --fa: "\f2e9";
  --fa--fa: "\f2e9\f2e9"; }

.fa-dollar-square {
  --fa: "\f2e9";
  --fa--fa: "\f2e9\f2e9"; }

.fa-usd-square {
  --fa: "\f2e9";
  --fa--fa: "\f2e9\f2e9"; }

.fa-phone-arrow-right {
  --fa: "\e5be";
  --fa--fa: "\e5be\e5be"; }

.fa-hand-holding-seedling {
  --fa: "\f4bf";
  --fa--fa: "\f4bf\f4bf"; }

.fa-message-check {
  --fa: "\f4a2";
  --fa--fa: "\f4a2\f4a2"; }

.fa-comment-alt-check {
  --fa: "\f4a2";
  --fa--fa: "\f4a2\f4a2"; }

.fa-cloud-bolt {
  --fa: "\f76c";
  --fa--fa: "\f76c\f76c"; }

.fa-thunderstorm {
  --fa: "\f76c";
  --fa--fa: "\f76c\f76c"; }

.fa-chart-line-up-down {
  --fa: "\e5d7";
  --fa--fa: "\e5d7\e5d7"; }

.fa-text-slash {
  --fa: "\f87d";
  --fa--fa: "\f87d\f87d"; }

.fa-remove-format {
  --fa: "\f87d";
  --fa--fa: "\f87d\f87d"; }

.fa-watch {
  --fa: "\f2e1";
  --fa--fa: "\f2e1\f2e1"; }

.fa-circle-down-left {
  --fa: "\e107";
  --fa--fa: "\e107\e107"; }

.fa-text {
  --fa: "\f893";
  --fa--fa: "\f893\f893"; }

.fa-projector {
  --fa: "\f8d6";
  --fa--fa: "\f8d6\f8d6"; }

.fa-face-smile-wink {
  --fa: "\f4da";
  --fa--fa: "\f4da\f4da"; }

.fa-smile-wink {
  --fa: "\f4da";
  --fa--fa: "\f4da\f4da"; }

.fa-tombstone-blank {
  --fa: "\f721";
  --fa--fa: "\f721\f721"; }

.fa-tombstone-alt {
  --fa: "\f721";
  --fa--fa: "\f721\f721"; }

.fa-chess-king-piece {
  --fa: "\f440";
  --fa--fa: "\f440\f440"; }

.fa-chess-king-alt {
  --fa: "\f440";
  --fa--fa: "\f440\f440"; }

.fa-circle-6 {
  --fa: "\e0f3";
  --fa--fa: "\e0f3\e0f3"; }

.fa-waves-sine {
  --fa: "\e65d";
  --fa--fa: "\e65d\e65d"; }

.fa-left {
  --fa: "\f355";
  --fa--fa: "\f355\f355"; }

.fa-arrow-alt-left {
  --fa: "\f355";
  --fa--fa: "\f355\f355"; }

.fa-file-word {
  --fa: "\f1c2";
  --fa--fa: "\f1c2\f1c2"; }

.fa-file-powerpoint {
  --fa: "\f1c4";
  --fa--fa: "\f1c4\f1c4"; }

.fa-square-down {
  --fa: "\f350";
  --fa--fa: "\f350\f350"; }

.fa-arrow-alt-square-down {
  --fa: "\f350";
  --fa--fa: "\f350\f350"; }

.fa-objects-align-center-vertical {
  --fa: "\e3bd";
  --fa--fa: "\e3bd\e3bd"; }

.fa-arrows-left-right {
  --fa: "\f07e";
  --fa--fa: "\f07e\f07e"; }

.fa-arrows-h {
  --fa: "\f07e";
  --fa--fa: "\f07e\f07e"; }

.fa-house-lock {
  --fa: "\e510";
  --fa--fa: "\e510\e510"; }

.fa-cloud-arrow-down {
  --fa: "\f0ed";
  --fa--fa: "\f0ed\f0ed"; }

.fa-cloud-download {
  --fa: "\f0ed";
  --fa--fa: "\f0ed\f0ed"; }

.fa-cloud-download-alt {
  --fa: "\f0ed";
  --fa--fa: "\f0ed\f0ed"; }

.fa-wreath {
  --fa: "\f7e2";
  --fa--fa: "\f7e2\f7e2"; }

.fa-children {
  --fa: "\e4e1";
  --fa--fa: "\e4e1\e4e1"; }

.fa-meter-droplet {
  --fa: "\e1ea";
  --fa--fa: "\e1ea\e1ea"; }

.fa-chalkboard {
  --fa: "\f51b";
  --fa--fa: "\f51b\f51b"; }

.fa-blackboard {
  --fa: "\f51b";
  --fa--fa: "\f51b\f51b"; }

.fa-user-large-slash {
  --fa: "\f4fa";
  --fa--fa: "\f4fa\f4fa"; }

.fa-user-alt-slash {
  --fa: "\f4fa";
  --fa--fa: "\f4fa\f4fa"; }

.fa-signal-strong {
  --fa: "\f68f";
  --fa--fa: "\f68f\f68f"; }

.fa-signal-4 {
  --fa: "\f68f";
  --fa--fa: "\f68f\f68f"; }

.fa-lollipop {
  --fa: "\e424";
  --fa--fa: "\e424\e424"; }

.fa-lollypop {
  --fa: "\e424";
  --fa--fa: "\e424\e424"; }

.fa-list-tree {
  --fa: "\e1d2";
  --fa--fa: "\e1d2\e1d2"; }

.fa-envelope-open {
  --fa: "\f2b6";
  --fa--fa: "\f2b6\f2b6"; }

.fa-draw-circle {
  --fa: "\f5ed";
  --fa--fa: "\f5ed\f5ed"; }

.fa-cat-space {
  --fa: "\e001";
  --fa--fa: "\e001\e001"; }

.fa-handshake-simple-slash {
  --fa: "\e05f";
  --fa--fa: "\e05f\e05f"; }

.fa-handshake-alt-slash {
  --fa: "\e05f";
  --fa--fa: "\e05f\e05f"; }

.fa-rabbit-running {
  --fa: "\f709";
  --fa--fa: "\f709\f709"; }

.fa-rabbit-fast {
  --fa: "\f709";
  --fa--fa: "\f709\f709"; }

.fa-memo-pad {
  --fa: "\e1da";
  --fa--fa: "\e1da\e1da"; }

.fa-mattress-pillow {
  --fa: "\e525";
  --fa--fa: "\e525\e525"; }

.fa-alarm-plus {
  --fa: "\f844";
  --fa--fa: "\f844\f844"; }

.fa-alicorn {
  --fa: "\f6b0";
  --fa--fa: "\f6b0\f6b0"; }

.fa-comment-question {
  --fa: "\e14b";
  --fa--fa: "\e14b\e14b"; }

.fa-gingerbread-man {
  --fa: "\f79d";
  --fa--fa: "\f79d\f79d"; }

.fa-guarani-sign {
  --fa: "\e19a";
  --fa--fa: "\e19a\e19a"; }

.fa-burger-fries {
  --fa: "\e0cd";
  --fa--fa: "\e0cd\e0cd"; }

.fa-mug-tea {
  --fa: "\f875";
  --fa--fa: "\f875\f875"; }

.fa-border-top {
  --fa: "\f855";
  --fa--fa: "\f855\f855"; }

.fa-arrows-rotate {
  --fa: "\f021";
  --fa--fa: "\f021\f021"; }

.fa-refresh {
  --fa: "\f021";
  --fa--fa: "\f021\f021"; }

.fa-sync {
  --fa: "\f021";
  --fa--fa: "\f021\f021"; }

.fa-circle-book-open {
  --fa: "\e0ff";
  --fa--fa: "\e0ff\e0ff"; }

.fa-book-circle {
  --fa: "\e0ff";
  --fa--fa: "\e0ff\e0ff"; }

.fa-arrows-to-dotted-line {
  --fa: "\e0a6";
  --fa--fa: "\e0a6\e0a6"; }

.fa-fire-extinguisher {
  --fa: "\f134";
  --fa--fa: "\f134\f134"; }

.fa-magnifying-glass-arrows-rotate {
  --fa: "\e65e";
  --fa--fa: "\e65e\e65e"; }

.fa-garage-open {
  --fa: "\e00b";
  --fa--fa: "\e00b\e00b"; }

.fa-shelves-empty {
  --fa: "\e246";
  --fa--fa: "\e246\e246"; }

.fa-cruzeiro-sign {
  --fa: "\e152";
  --fa--fa: "\e152\e152"; }

.fa-watch-apple {
  --fa: "\e2cb";
  --fa--fa: "\e2cb\e2cb"; }

.fa-watch-calculator {
  --fa: "\f8f0";
  --fa--fa: "\f8f0\f8f0"; }

.fa-list-dropdown {
  --fa: "\e1cf";
  --fa--fa: "\e1cf\e1cf"; }

.fa-cabinet-filing {
  --fa: "\f64b";
  --fa--fa: "\f64b\f64b"; }

.fa-burger-soda {
  --fa: "\f858";
  --fa--fa: "\f858\f858"; }

.fa-square-arrow-up {
  --fa: "\f33c";
  --fa--fa: "\f33c\f33c"; }

.fa-arrow-square-up {
  --fa: "\f33c";
  --fa--fa: "\f33c\f33c"; }

.fa-greater-than-equal {
  --fa: "\f532";
  --fa--fa: "\f532\f532"; }

.fa-pallet-box {
  --fa: "\e208";
  --fa--fa: "\e208\e208"; }

.fa-face-confounded {
  --fa: "\e36c";
  --fa--fa: "\e36c\e36c"; }

.fa-shield-halved {
  --fa: "\f3ed";
  --fa--fa: "\f3ed\f3ed"; }

.fa-shield-alt {
  --fa: "\f3ed";
  --fa--fa: "\f3ed\f3ed"; }

.fa-truck-plow {
  --fa: "\f7de";
  --fa--fa: "\f7de\f7de"; }

.fa-book-atlas {
  --fa: "\f558";
  --fa--fa: "\f558\f558"; }

.fa-atlas {
  --fa: "\f558";
  --fa--fa: "\f558\f558"; }

.fa-virus {
  --fa: "\e074";
  --fa--fa: "\e074\e074"; }

.fa-grid-round-2 {
  --fa: "\e5db";
  --fa--fa: "\e5db\e5db"; }

.fa-comment-middle-top {
  --fa: "\e14a";
  --fa--fa: "\e14a\e14a"; }

.fa-wave {
  --fa: "\e65b";
  --fa--fa: "\e65b\e65b"; }

.fa-envelope-circle-check {
  --fa: "\e4e8";
  --fa--fa: "\e4e8\e4e8"; }

.fa-layer-group {
  --fa: "\f5fd";
  --fa--fa: "\f5fd\f5fd"; }

.fa-restroom-simple {
  --fa: "\e23a";
  --fa--fa: "\e23a\e23a"; }

.fa-arrows-to-dot {
  --fa: "\e4be";
  --fa--fa: "\e4be\e4be"; }

.fa-border-outer {
  --fa: "\f851";
  --fa--fa: "\f851\f851"; }

.fa-hashtag-lock {
  --fa: "\e415";
  --fa--fa: "\e415\e415"; }

.fa-clock-two-thirty {
  --fa: "\e35b";
  --fa--fa: "\e35b\e35b"; }

.fa-archway {
  --fa: "\f557";
  --fa--fa: "\f557\f557"; }

.fa-heart-circle-check {
  --fa: "\e4fd";
  --fa--fa: "\e4fd\e4fd"; }

.fa-house-chimney-crack {
  --fa: "\f6f1";
  --fa--fa: "\f6f1\f6f1"; }

.fa-house-damage {
  --fa: "\f6f1";
  --fa--fa: "\f6f1\f6f1"; }

.fa-file-zipper {
  --fa: "\f1c6";
  --fa--fa: "\f1c6\f1c6"; }

.fa-file-archive {
  --fa: "\f1c6";
  --fa--fa: "\f1c6\f1c6"; }

.fa-ticket-perforated {
  --fa: "\e63e";
  --fa--fa: "\e63e\e63e"; }

.fa-heart-half {
  --fa: "\e1ab";
  --fa--fa: "\e1ab\e1ab"; }

.fa-comment-check {
  --fa: "\f4ac";
  --fa--fa: "\f4ac\f4ac"; }

.fa-square {
  --fa: "\f0c8";
  --fa--fa: "\f0c8\f0c8"; }

.fa-memo {
  --fa: "\e1d8";
  --fa--fa: "\e1d8\e1d8"; }

.fa-martini-glass-empty {
  --fa: "\f000";
  --fa--fa: "\f000\f000"; }

.fa-glass-martini {
  --fa: "\f000";
  --fa--fa: "\f000\f000"; }

.fa-couch {
  --fa: "\f4b8";
  --fa--fa: "\f4b8\f4b8"; }

.fa-cedi-sign {
  --fa: "\e0df";
  --fa--fa: "\e0df\e0df"; }

.fa-italic {
  --fa: "\f033";
  --fa--fa: "\f033\f033"; }

.fa-glass-citrus {
  --fa: "\f869";
  --fa--fa: "\f869\f869"; }

.fa-calendar-lines-pen {
  --fa: "\e472";
  --fa--fa: "\e472\e472"; }

.fa-table-cells-column-lock {
  --fa: "\e678";
  --fa--fa: "\e678\e678"; }

.fa-church {
  --fa: "\f51d";
  --fa--fa: "\f51d\f51d"; }

.fa-person-snowmobiling {
  --fa: "\f7d1";
  --fa--fa: "\f7d1\f7d1"; }

.fa-snowmobile {
  --fa: "\f7d1";
  --fa--fa: "\f7d1\f7d1"; }

.fa-face-hushed {
  --fa: "\e37b";
  --fa--fa: "\e37b\e37b"; }

.fa-comments-dollar {
  --fa: "\f653";
  --fa--fa: "\f653\f653"; }

.fa-tickets-simple {
  --fa: "\e659";
  --fa--fa: "\e659\e659"; }

.fa-pickaxe {
  --fa: "\e5bf";
  --fa--fa: "\e5bf\e5bf"; }

.fa-link-simple-slash {
  --fa: "\e1ce";
  --fa--fa: "\e1ce\e1ce"; }

.fa-democrat {
  --fa: "\f747";
  --fa--fa: "\f747\f747"; }

.fa-face-confused {
  --fa: "\e36d";
  --fa--fa: "\e36d\e36d"; }

.fa-pinball {
  --fa: "\e229";
  --fa--fa: "\e229\e229"; }

.fa-z {
  --fa: "\5a";
  --fa--fa: "\5a\5a"; }

.fa-person-skiing {
  --fa: "\f7c9";
  --fa--fa: "\f7c9\f7c9"; }

.fa-skiing {
  --fa: "\f7c9";
  --fa--fa: "\f7c9\f7c9"; }

.fa-deer {
  --fa: "\f78e";
  --fa--fa: "\f78e\f78e"; }

.fa-input-pipe {
  --fa: "\e1be";
  --fa--fa: "\e1be\e1be"; }

.fa-road-lock {
  --fa: "\e567";
  --fa--fa: "\e567\e567"; }

.fa-a {
  --fa: "\41";
  --fa--fa: "\41\41"; }

.fa-bookmark-slash {
  --fa: "\e0c2";
  --fa--fa: "\e0c2\e0c2"; }

.fa-temperature-arrow-down {
  --fa: "\e03f";
  --fa--fa: "\e03f\e03f"; }

.fa-temperature-down {
  --fa: "\e03f";
  --fa--fa: "\e03f\e03f"; }

.fa-mace {
  --fa: "\f6f8";
  --fa--fa: "\f6f8\f6f8"; }

.fa-feather-pointed {
  --fa: "\f56b";
  --fa--fa: "\f56b\f56b"; }

.fa-feather-alt {
  --fa: "\f56b";
  --fa--fa: "\f56b\f56b"; }

.fa-sausage {
  --fa: "\f820";
  --fa--fa: "\f820\f820"; }

.fa-trash-can-clock {
  --fa: "\e2aa";
  --fa--fa: "\e2aa\e2aa"; }

.fa-p {
  --fa: "\50";
  --fa--fa: "\50\50"; }

.fa-broom-wide {
  --fa: "\e5d1";
  --fa--fa: "\e5d1\e5d1"; }

.fa-snowflake {
  --fa: "\f2dc";
  --fa--fa: "\f2dc\f2dc"; }

.fa-stomach {
  --fa: "\f623";
  --fa--fa: "\f623\f623"; }

.fa-newspaper {
  --fa: "\f1ea";
  --fa--fa: "\f1ea\f1ea"; }

.fa-rectangle-ad {
  --fa: "\f641";
  --fa--fa: "\f641\f641"; }

.fa-ad {
  --fa: "\f641";
  --fa--fa: "\f641\f641"; }

.fa-guitar-electric {
  --fa: "\f8be";
  --fa--fa: "\f8be\f8be"; }

.fa-arrow-turn-down-right {
  --fa: "\e3d6";
  --fa--fa: "\e3d6\e3d6"; }

.fa-moon-cloud {
  --fa: "\f754";
  --fa--fa: "\f754\f754"; }

.fa-bread-slice-butter {
  --fa: "\e3e1";
  --fa--fa: "\e3e1\e3e1"; }

.fa-circle-arrow-right {
  --fa: "\f0a9";
  --fa--fa: "\f0a9\f0a9"; }

.fa-arrow-circle-right {
  --fa: "\f0a9";
  --fa--fa: "\f0a9\f0a9"; }

.fa-user-group-crown {
  --fa: "\f6a5";
  --fa--fa: "\f6a5\f6a5"; }

.fa-users-crown {
  --fa: "\f6a5";
  --fa--fa: "\f6a5\f6a5"; }

.fa-circle-i {
  --fa: "\e111";
  --fa--fa: "\e111\e111"; }

.fa-toilet-paper-check {
  --fa: "\e5b2";
  --fa--fa: "\e5b2\e5b2"; }

.fa-filter-circle-xmark {
  --fa: "\e17b";
  --fa--fa: "\e17b\e17b"; }

.fa-locust {
  --fa: "\e520";
  --fa--fa: "\e520\e520"; }

.fa-sort {
  --fa: "\f0dc";
  --fa--fa: "\f0dc\f0dc"; }

.fa-unsorted {
  --fa: "\f0dc";
  --fa--fa: "\f0dc\f0dc"; }

.fa-list-ol {
  --fa: "\f0cb";
  --fa--fa: "\f0cb\f0cb"; }

.fa-list-1-2 {
  --fa: "\f0cb";
  --fa--fa: "\f0cb\f0cb"; }

.fa-list-numeric {
  --fa: "\f0cb";
  --fa--fa: "\f0cb\f0cb"; }

.fa-chart-waterfall {
  --fa: "\e0eb";
  --fa--fa: "\e0eb\e0eb"; }

.fa-sparkle {
  --fa: "\e5d6";
  --fa--fa: "\e5d6\e5d6"; }

.fa-face-party {
  --fa: "\e383";
  --fa--fa: "\e383\e383"; }

.fa-kidneys {
  --fa: "\f5fb";
  --fa--fa: "\f5fb\f5fb"; }

.fa-wifi-exclamation {
  --fa: "\e2cf";
  --fa--fa: "\e2cf\e2cf"; }

.fa-chart-network {
  --fa: "\f78a";
  --fa--fa: "\f78a\f78a"; }

.fa-person-dress-burst {
  --fa: "\e544";
  --fa--fa: "\e544\e544"; }

.fa-dice-d4 {
  --fa: "\f6d0";
  --fa--fa: "\f6d0\f6d0"; }

.fa-money-check-dollar {
  --fa: "\f53d";
  --fa--fa: "\f53d\f53d"; }

.fa-money-check-alt {
  --fa: "\f53d";
  --fa--fa: "\f53d\f53d"; }

.fa-vector-square {
  --fa: "\f5cb";
  --fa--fa: "\f5cb\f5cb"; }

.fa-bread-slice {
  --fa: "\f7ec";
  --fa--fa: "\f7ec\f7ec"; }

.fa-language {
  --fa: "\f1ab";
  --fa--fa: "\f1ab\f1ab"; }

.fa-wheat-awn-slash {
  --fa: "\e338";
  --fa--fa: "\e338\e338"; }

.fa-face-kiss-wink-heart {
  --fa: "\f598";
  --fa--fa: "\f598\f598"; }

.fa-kiss-wink-heart {
  --fa: "\f598";
  --fa--fa: "\f598\f598"; }

.fa-dagger {
  --fa: "\f6cb";
  --fa--fa: "\f6cb\f6cb"; }

.fa-podium {
  --fa: "\f680";
  --fa--fa: "\f680\f680"; }

.fa-diamonds-4 {
  --fa: "\e68b";
  --fa--fa: "\e68b\e68b"; }

.fa-memo-circle-check {
  --fa: "\e1d9";
  --fa--fa: "\e1d9\e1d9"; }

.fa-route-highway {
  --fa: "\f61a";
  --fa--fa: "\f61a\f61a"; }

.fa-down-to-line {
  --fa: "\f34a";
  --fa--fa: "\f34a\f34a"; }

.fa-arrow-alt-to-bottom {
  --fa: "\f34a";
  --fa--fa: "\f34a\f34a"; }

.fa-filter {
  --fa: "\f0b0";
  --fa--fa: "\f0b0\f0b0"; }

.fa-square-g {
  --fa: "\e271";
  --fa--fa: "\e271\e271"; }

.fa-circle-phone {
  --fa: "\e11b";
  --fa--fa: "\e11b\e11b"; }

.fa-phone-circle {
  --fa: "\e11b";
  --fa--fa: "\e11b\e11b"; }

.fa-clipboard-prescription {
  --fa: "\f5e8";
  --fa--fa: "\f5e8\f5e8"; }

.fa-user-nurse-hair {
  --fa: "\e45d";
  --fa--fa: "\e45d\e45d"; }

.fa-question {
  --fa: "\3f";
  --fa--fa: "\3f\3f"; }

.fa-file-signature {
  --fa: "\f573";
  --fa--fa: "\f573\f573"; }

.fa-toggle-large-on {
  --fa: "\e5b1";
  --fa--fa: "\e5b1\e5b1"; }

.fa-up-down-left-right {
  --fa: "\f0b2";
  --fa--fa: "\f0b2\f0b2"; }

.fa-arrows-alt {
  --fa: "\f0b2";
  --fa--fa: "\f0b2\f0b2"; }

.fa-dryer-heat {
  --fa: "\f862";
  --fa--fa: "\f862\f862"; }

.fa-dryer-alt {
  --fa: "\f862";
  --fa--fa: "\f862\f862"; }

.fa-house-chimney-user {
  --fa: "\e065";
  --fa--fa: "\e065\e065"; }

.fa-hand-holding-heart {
  --fa: "\f4be";
  --fa--fa: "\f4be\f4be"; }

.fa-arrow-up-small-big {
  --fa: "\f88f";
  --fa--fa: "\f88f\f88f"; }

.fa-sort-size-up-alt {
  --fa: "\f88f";
  --fa--fa: "\f88f\f88f"; }

.fa-train-track {
  --fa: "\e453";
  --fa--fa: "\e453\e453"; }

.fa-puzzle-piece {
  --fa: "\f12e";
  --fa--fa: "\f12e\f12e"; }

.fa-money-check {
  --fa: "\f53c";
  --fa--fa: "\f53c\f53c"; }

.fa-star-half-stroke {
  --fa: "\f5c0";
  --fa--fa: "\f5c0\f5c0"; }

.fa-star-half-alt {
  --fa: "\f5c0";
  --fa--fa: "\f5c0\f5c0"; }

.fa-file-exclamation {
  --fa: "\f31a";
  --fa--fa: "\f31a\f31a"; }

.fa-code {
  --fa: "\f121";
  --fa--fa: "\f121\f121"; }

.fa-whiskey-glass {
  --fa: "\f7a0";
  --fa--fa: "\f7a0\f7a0"; }

.fa-glass-whiskey {
  --fa: "\f7a0";
  --fa--fa: "\f7a0\f7a0"; }

.fa-moon-stars {
  --fa: "\f755";
  --fa--fa: "\f755\f755"; }

.fa-building-circle-exclamation {
  --fa: "\e4d3";
  --fa--fa: "\e4d3\e4d3"; }

.fa-clothes-hanger {
  --fa: "\e136";
  --fa--fa: "\e136\e136"; }

.fa-mobile-notch {
  --fa: "\e1ee";
  --fa--fa: "\e1ee\e1ee"; }

.fa-mobile-iphone {
  --fa: "\e1ee";
  --fa--fa: "\e1ee\e1ee"; }

.fa-magnifying-glass-chart {
  --fa: "\e522";
  --fa--fa: "\e522\e522"; }

.fa-arrow-up-right-from-square {
  --fa: "\f08e";
  --fa--fa: "\f08e\f08e"; }

.fa-external-link {
  --fa: "\f08e";
  --fa--fa: "\f08e\f08e"; }

.fa-cubes-stacked {
  --fa: "\e4e6";
  --fa--fa: "\e4e6\e4e6"; }

.fa-images-user {
  --fa: "\e1b9";
  --fa--fa: "\e1b9\e1b9"; }

.fa-won-sign {
  --fa: "\f159";
  --fa--fa: "\f159\f159"; }

.fa-krw {
  --fa: "\f159";
  --fa--fa: "\f159\f159"; }

.fa-won {
  --fa: "\f159";
  --fa--fa: "\f159\f159"; }

.fa-image-polaroid-user {
  --fa: "\e1b6";
  --fa--fa: "\e1b6\e1b6"; }

.fa-virus-covid {
  --fa: "\e4a8";
  --fa--fa: "\e4a8\e4a8"; }

.fa-square-ellipsis {
  --fa: "\e26e";
  --fa--fa: "\e26e\e26e"; }

.fa-pie {
  --fa: "\f705";
  --fa--fa: "\f705\f705"; }

.fa-chess-knight-piece {
  --fa: "\f442";
  --fa--fa: "\f442\f442"; }

.fa-chess-knight-alt {
  --fa: "\f442";
  --fa--fa: "\f442\f442"; }

.fa-austral-sign {
  --fa: "\e0a9";
  --fa--fa: "\e0a9\e0a9"; }

.fa-cloud-plus {
  --fa: "\e35e";
  --fa--fa: "\e35e\e35e"; }

.fa-f {
  --fa: "\46";
  --fa--fa: "\46\46"; }

.fa-leaf {
  --fa: "\f06c";
  --fa--fa: "\f06c\f06c"; }

.fa-bed-bunk {
  --fa: "\f8f8";
  --fa--fa: "\f8f8\f8f8"; }

.fa-road {
  --fa: "\f018";
  --fa--fa: "\f018\f018"; }

.fa-taxi {
  --fa: "\f1ba";
  --fa--fa: "\f1ba\f1ba"; }

.fa-cab {
  --fa: "\f1ba";
  --fa--fa: "\f1ba\f1ba"; }

.fa-person-circle-plus {
  --fa: "\e541";
  --fa--fa: "\e541\e541"; }

.fa-chart-pie {
  --fa: "\f200";
  --fa--fa: "\f200\f200"; }

.fa-pie-chart {
  --fa: "\f200";
  --fa--fa: "\f200\f200"; }

.fa-bolt-lightning {
  --fa: "\e0b7";
  --fa--fa: "\e0b7\e0b7"; }

.fa-clock-eight {
  --fa: "\e345";
  --fa--fa: "\e345\e345"; }

.fa-sack-xmark {
  --fa: "\e56a";
  --fa--fa: "\e56a\e56a"; }

.fa-file-xls {
  --fa: "\e64d";
  --fa--fa: "\e64d\e64d"; }

.fa-file-excel {
  --fa: "\f1c3";
  --fa--fa: "\f1c3\f1c3"; }

.fa-file-contract {
  --fa: "\f56c";
  --fa--fa: "\f56c\f56c"; }

.fa-fish-fins {
  --fa: "\e4f2";
  --fa--fa: "\e4f2\e4f2"; }

.fa-circle-q {
  --fa: "\e11e";
  --fa--fa: "\e11e\e11e"; }

.fa-building-flag {
  --fa: "\e4d5";
  --fa--fa: "\e4d5\e4d5"; }

.fa-face-grin-beam {
  --fa: "\f582";
  --fa--fa: "\f582\f582"; }

.fa-grin-beam {
  --fa: "\f582";
  --fa--fa: "\f582\f582"; }

.fa-object-ungroup {
  --fa: "\f248";
  --fa--fa: "\f248\f248"; }

.fa-face-disguise {
  --fa: "\e370";
  --fa--fa: "\e370\e370"; }

.fa-circle-arrow-down-right {
  --fa: "\e0fa";
  --fa--fa: "\e0fa\e0fa"; }

.fa-alien-8bit {
  --fa: "\f8f6";
  --fa--fa: "\f8f6\f8f6"; }

.fa-alien-monster {
  --fa: "\f8f6";
  --fa--fa: "\f8f6\f8f6"; }

.fa-hand-point-ribbon {
  --fa: "\e1a6";
  --fa--fa: "\e1a6\e1a6"; }

.fa-poop {
  --fa: "\f619";
  --fa--fa: "\f619\f619"; }

.fa-object-exclude {
  --fa: "\e49c";
  --fa--fa: "\e49c\e49c"; }

.fa-telescope {
  --fa: "\e03e";
  --fa--fa: "\e03e\e03e"; }

.fa-location-pin {
  --fa: "\f041";
  --fa--fa: "\f041\f041"; }

.fa-map-marker {
  --fa: "\f041";
  --fa--fa: "\f041\f041"; }

.fa-square-list {
  --fa: "\e489";
  --fa--fa: "\e489\e489"; }

.fa-kaaba {
  --fa: "\f66b";
  --fa--fa: "\f66b\f66b"; }

.fa-toilet-paper {
  --fa: "\f71e";
  --fa--fa: "\f71e\f71e"; }

.fa-helmet-safety {
  --fa: "\f807";
  --fa--fa: "\f807\f807"; }

.fa-hard-hat {
  --fa: "\f807";
  --fa--fa: "\f807\f807"; }

.fa-hat-hard {
  --fa: "\f807";
  --fa--fa: "\f807\f807"; }

.fa-comment-code {
  --fa: "\e147";
  --fa--fa: "\e147\e147"; }

.fa-sim-cards {
  --fa: "\e251";
  --fa--fa: "\e251\e251"; }

.fa-starship {
  --fa: "\e039";
  --fa--fa: "\e039\e039"; }

.fa-eject {
  --fa: "\f052";
  --fa--fa: "\f052\f052"; }

.fa-circle-right {
  --fa: "\f35a";
  --fa--fa: "\f35a\f35a"; }

.fa-arrow-alt-circle-right {
  --fa: "\f35a";
  --fa--fa: "\f35a\f35a"; }

.fa-plane-circle-check {
  --fa: "\e555";
  --fa--fa: "\e555\e555"; }

.fa-seal {
  --fa: "\e241";
  --fa--fa: "\e241\e241"; }

.fa-user-cowboy {
  --fa: "\f8ea";
  --fa--fa: "\f8ea\f8ea"; }

.fa-hexagon-vertical-nft {
  --fa: "\e505";
  --fa--fa: "\e505\e505"; }

.fa-face-rolling-eyes {
  --fa: "\f5a5";
  --fa--fa: "\f5a5\f5a5"; }

.fa-meh-rolling-eyes {
  --fa: "\f5a5";
  --fa--fa: "\f5a5\f5a5"; }

.fa-bread-loaf {
  --fa: "\f7eb";
  --fa--fa: "\f7eb\f7eb"; }

.fa-rings-wedding {
  --fa: "\f81b";
  --fa--fa: "\f81b\f81b"; }

.fa-object-group {
  --fa: "\f247";
  --fa--fa: "\f247\f247"; }

.fa-french-fries {
  --fa: "\f803";
  --fa--fa: "\f803\f803"; }

.fa-chart-line {
  --fa: "\f201";
  --fa--fa: "\f201\f201"; }

.fa-line-chart {
  --fa: "\f201";
  --fa--fa: "\f201\f201"; }

.fa-calendar-arrow-down {
  --fa: "\e0d0";
  --fa--fa: "\e0d0\e0d0"; }

.fa-calendar-download {
  --fa: "\e0d0";
  --fa--fa: "\e0d0\e0d0"; }

.fa-send-back {
  --fa: "\f87e";
  --fa--fa: "\f87e\f87e"; }

.fa-mask-ventilator {
  --fa: "\e524";
  --fa--fa: "\e524\e524"; }

.fa-tickets {
  --fa: "\e658";
  --fa--fa: "\e658\e658"; }

.fa-signature-lock {
  --fa: "\e3ca";
  --fa--fa: "\e3ca\e3ca"; }

.fa-arrow-right {
  --fa: "\f061";
  --fa--fa: "\f061\f061"; }

.fa-signs-post {
  --fa: "\f277";
  --fa--fa: "\f277\f277"; }

.fa-map-signs {
  --fa: "\f277";
  --fa--fa: "\f277\f277"; }

.fa-octagon-plus {
  --fa: "\f301";
  --fa--fa: "\f301\f301"; }

.fa-plus-octagon {
  --fa: "\f301";
  --fa--fa: "\f301\f301"; }

.fa-cash-register {
  --fa: "\f788";
  --fa--fa: "\f788\f788"; }

.fa-person-circle-question {
  --fa: "\e542";
  --fa--fa: "\e542\e542"; }

.fa-melon-slice {
  --fa: "\e311";
  --fa--fa: "\e311\e311"; }

.fa-space-station-moon {
  --fa: "\e033";
  --fa--fa: "\e033\e033"; }

.fa-message-smile {
  --fa: "\f4aa";
  --fa--fa: "\f4aa\f4aa"; }

.fa-comment-alt-smile {
  --fa: "\f4aa";
  --fa--fa: "\f4aa\f4aa"; }

.fa-cup-straw {
  --fa: "\e363";
  --fa--fa: "\e363\e363"; }

.fa-left-from-line {
  --fa: "\f348";
  --fa--fa: "\f348\f348"; }

.fa-arrow-alt-from-right {
  --fa: "\f348";
  --fa--fa: "\f348\f348"; }

.fa-h {
  --fa: "\48";
  --fa--fa: "\48\48"; }

.fa-basket-shopping-simple {
  --fa: "\e0af";
  --fa--fa: "\e0af\e0af"; }

.fa-shopping-basket-alt {
  --fa: "\e0af";
  --fa--fa: "\e0af\e0af"; }

.fa-hands-holding-heart {
  --fa: "\f4c3";
  --fa--fa: "\f4c3\f4c3"; }

.fa-hands-heart {
  --fa: "\f4c3";
  --fa--fa: "\f4c3\f4c3"; }

.fa-clock-nine {
  --fa: "\e34c";
  --fa--fa: "\e34c\e34c"; }

.fa-hammer-brush {
  --fa: "\e620";
  --fa--fa: "\e620\e620"; }

.fa-tarp {
  --fa: "\e57b";
  --fa--fa: "\e57b\e57b"; }

.fa-face-sleepy {
  --fa: "\e38e";
  --fa--fa: "\e38e\e38e"; }

.fa-hand-horns {
  --fa: "\e1a9";
  --fa--fa: "\e1a9\e1a9"; }

.fa-screwdriver-wrench {
  --fa: "\f7d9";
  --fa--fa: "\f7d9\f7d9"; }

.fa-tools {
  --fa: "\f7d9";
  --fa--fa: "\f7d9\f7d9"; }

.fa-arrows-to-eye {
  --fa: "\e4bf";
  --fa--fa: "\e4bf\e4bf"; }

.fa-circle-three-quarters {
  --fa: "\e125";
  --fa--fa: "\e125\e125"; }

.fa-trophy-star {
  --fa: "\f2eb";
  --fa--fa: "\f2eb\f2eb"; }

.fa-trophy-alt {
  --fa: "\f2eb";
  --fa--fa: "\f2eb\f2eb"; }

.fa-plug-circle-bolt {
  --fa: "\e55b";
  --fa--fa: "\e55b\e55b"; }

.fa-face-thermometer {
  --fa: "\e39a";
  --fa--fa: "\e39a\e39a"; }

.fa-grid-round-4 {
  --fa: "\e5dd";
  --fa--fa: "\e5dd\e5dd"; }

.fa-sign-posts-wrench {
  --fa: "\e626";
  --fa--fa: "\e626\e626"; }

.fa-shirt-running {
  --fa: "\e3c8";
  --fa--fa: "\e3c8\e3c8"; }

.fa-book-circle-arrow-up {
  --fa: "\e0bd";
  --fa--fa: "\e0bd\e0bd"; }

.fa-face-nauseated {
  --fa: "\e381";
  --fa--fa: "\e381\e381"; }

.fa-heart {
  --fa: "\f004";
  --fa--fa: "\f004\f004"; }

.fa-file-chart-pie {
  --fa: "\f65a";
  --fa--fa: "\f65a\f65a"; }

.fa-mars-and-venus {
  --fa: "\f224";
  --fa--fa: "\f224\f224"; }

.fa-house-user {
  --fa: "\e1b0";
  --fa--fa: "\e1b0\e1b0"; }

.fa-home-user {
  --fa: "\e1b0";
  --fa--fa: "\e1b0\e1b0"; }

.fa-circle-arrow-down-left {
  --fa: "\e0f9";
  --fa--fa: "\e0f9\e0f9"; }

.fa-dumpster-fire {
  --fa: "\f794";
  --fa--fa: "\f794\f794"; }

.fa-hexagon-minus {
  --fa: "\f307";
  --fa--fa: "\f307\f307"; }

.fa-minus-hexagon {
  --fa: "\f307";
  --fa--fa: "\f307\f307"; }

.fa-left-to-line {
  --fa: "\f34b";
  --fa--fa: "\f34b\f34b"; }

.fa-arrow-alt-to-left {
  --fa: "\f34b";
  --fa--fa: "\f34b\f34b"; }

.fa-house-crack {
  --fa: "\e3b1";
  --fa--fa: "\e3b1\e3b1"; }

.fa-paw-simple {
  --fa: "\f701";
  --fa--fa: "\f701\f701"; }

.fa-paw-alt {
  --fa: "\f701";
  --fa--fa: "\f701\f701"; }

.fa-arrow-left-long-to-line {
  --fa: "\e3d4";
  --fa--fa: "\e3d4\e3d4"; }

.fa-brackets-round {
  --fa: "\e0c5";
  --fa--fa: "\e0c5\e0c5"; }

.fa-parentheses {
  --fa: "\e0c5";
  --fa--fa: "\e0c5\e0c5"; }

.fa-martini-glass-citrus {
  --fa: "\f561";
  --fa--fa: "\f561\f561"; }

.fa-cocktail {
  --fa: "\f561";
  --fa--fa: "\f561\f561"; }

.fa-user-shakespeare {
  --fa: "\e2c2";
  --fa--fa: "\e2c2\e2c2"; }

.fa-arrow-right-to-arc {
  --fa: "\e4b2";
  --fa--fa: "\e4b2\e4b2"; }

.fa-face-surprise {
  --fa: "\f5c2";
  --fa--fa: "\f5c2\f5c2"; }

.fa-surprise {
  --fa: "\f5c2";
  --fa--fa: "\f5c2\f5c2"; }

.fa-bottle-water {
  --fa: "\e4c5";
  --fa--fa: "\e4c5\e4c5"; }

.fa-circle-pause {
  --fa: "\f28b";
  --fa--fa: "\f28b\f28b"; }

.fa-pause-circle {
  --fa: "\f28b";
  --fa--fa: "\f28b\f28b"; }

.fa-gauge-circle-plus {
  --fa: "\e498";
  --fa--fa: "\e498\e498"; }

.fa-folders {
  --fa: "\f660";
  --fa--fa: "\f660\f660"; }

.fa-angel {
  --fa: "\f779";
  --fa--fa: "\f779\f779"; }

.fa-value-absolute {
  --fa: "\f6a6";
  --fa--fa: "\f6a6\f6a6"; }

.fa-rabbit {
  --fa: "\f708";
  --fa--fa: "\f708\f708"; }

.fa-toilet-paper-slash {
  --fa: "\e072";
  --fa--fa: "\e072\e072"; }

.fa-circle-euro {
  --fa: "\e5ce";
  --fa--fa: "\e5ce\e5ce"; }

.fa-apple-whole {
  --fa: "\f5d1";
  --fa--fa: "\f5d1\f5d1"; }

.fa-apple-alt {
  --fa: "\f5d1";
  --fa--fa: "\f5d1\f5d1"; }

.fa-kitchen-set {
  --fa: "\e51a";
  --fa--fa: "\e51a\e51a"; }

.fa-diamond-half {
  --fa: "\e5b7";
  --fa--fa: "\e5b7\e5b7"; }

.fa-lock-keyhole {
  --fa: "\f30d";
  --fa--fa: "\f30d\f30d"; }

.fa-lock-alt {
  --fa: "\f30d";
  --fa--fa: "\f30d\f30d"; }

.fa-r {
  --fa: "\52";
  --fa--fa: "\52\52"; }

.fa-temperature-quarter {
  --fa: "\f2ca";
  --fa--fa: "\f2ca\f2ca"; }

.fa-temperature-1 {
  --fa: "\f2ca";
  --fa--fa: "\f2ca\f2ca"; }

.fa-thermometer-1 {
  --fa: "\f2ca";
  --fa--fa: "\f2ca\f2ca"; }

.fa-thermometer-quarter {
  --fa: "\f2ca";
  --fa--fa: "\f2ca\f2ca"; }

.fa-square-info {
  --fa: "\f30f";
  --fa--fa: "\f30f\f30f"; }

.fa-info-square {
  --fa: "\f30f";
  --fa--fa: "\f30f\f30f"; }

.fa-wifi-slash {
  --fa: "\f6ac";
  --fa--fa: "\f6ac\f6ac"; }

.fa-toilet-paper-xmark {
  --fa: "\e5b3";
  --fa--fa: "\e5b3\e5b3"; }

.fa-hands-holding-dollar {
  --fa: "\f4c5";
  --fa--fa: "\f4c5\f4c5"; }

.fa-hands-usd {
  --fa: "\f4c5";
  --fa--fa: "\f4c5\f4c5"; }

.fa-cube {
  --fa: "\f1b2";
  --fa--fa: "\f1b2\f1b2"; }

.fa-arrow-down-triangle-square {
  --fa: "\f888";
  --fa--fa: "\f888\f888"; }

.fa-sort-shapes-down {
  --fa: "\f888";
  --fa--fa: "\f888\f888"; }

.fa-bitcoin-sign {
  --fa: "\e0b4";
  --fa--fa: "\e0b4\e0b4"; }

.fa-shutters {
  --fa: "\e449";
  --fa--fa: "\e449\e449"; }

.fa-shield-dog {
  --fa: "\e573";
  --fa--fa: "\e573\e573"; }

.fa-solar-panel {
  --fa: "\f5ba";
  --fa--fa: "\f5ba\f5ba"; }

.fa-lock-open {
  --fa: "\f3c1";
  --fa--fa: "\f3c1\f3c1"; }

.fa-table-tree {
  --fa: "\e293";
  --fa--fa: "\e293\e293"; }

.fa-house-chimney-heart {
  --fa: "\e1b2";
  --fa--fa: "\e1b2\e1b2"; }

.fa-tally-3 {
  --fa: "\e296";
  --fa--fa: "\e296\e296"; }

.fa-elevator {
  --fa: "\e16d";
  --fa--fa: "\e16d\e16d"; }

.fa-money-bill-transfer {
  --fa: "\e528";
  --fa--fa: "\e528\e528"; }

.fa-money-bill-trend-up {
  --fa: "\e529";
  --fa--fa: "\e529\e529"; }

.fa-house-flood-water-circle-arrow-right {
  --fa: "\e50f";
  --fa--fa: "\e50f\e50f"; }

.fa-square-poll-horizontal {
  --fa: "\f682";
  --fa--fa: "\f682\f682"; }

.fa-poll-h {
  --fa: "\f682";
  --fa--fa: "\f682\f682"; }

.fa-circle {
  --fa: "\f111";
  --fa--fa: "\f111\f111"; }

.fa-left-to-bracket {
  --fa: "\e66d";
  --fa--fa: "\e66d\e66d"; }

.fa-cart-circle-exclamation {
  --fa: "\e3f2";
  --fa--fa: "\e3f2\e3f2"; }

.fa-sword {
  --fa: "\f71c";
  --fa--fa: "\f71c\f71c"; }

.fa-backward-fast {
  --fa: "\f049";
  --fa--fa: "\f049\f049"; }

.fa-fast-backward {
  --fa: "\f049";
  --fa--fa: "\f049\f049"; }

.fa-recycle {
  --fa: "\f1b8";
  --fa--fa: "\f1b8\f1b8"; }

.fa-user-astronaut {
  --fa: "\f4fb";
  --fa--fa: "\f4fb\f4fb"; }

.fa-interrobang {
  --fa: "\e5ba";
  --fa--fa: "\e5ba\e5ba"; }

.fa-plane-slash {
  --fa: "\e069";
  --fa--fa: "\e069\e069"; }

.fa-circle-dashed {
  --fa: "\e105";
  --fa--fa: "\e105\e105"; }

.fa-trademark {
  --fa: "\f25c";
  --fa--fa: "\f25c\f25c"; }

.fa-basketball {
  --fa: "\f434";
  --fa--fa: "\f434\f434"; }

.fa-basketball-ball {
  --fa: "\f434";
  --fa--fa: "\f434\f434"; }

.fa-fork-knife {
  --fa: "\f2e6";
  --fa--fa: "\f2e6\f2e6"; }

.fa-utensils-alt {
  --fa: "\f2e6";
  --fa--fa: "\f2e6\f2e6"; }

.fa-satellite-dish {
  --fa: "\f7c0";
  --fa--fa: "\f7c0\f7c0"; }

.fa-badge-check {
  --fa: "\f336";
  --fa--fa: "\f336\f336"; }

.fa-circle-up {
  --fa: "\f35b";
  --fa--fa: "\f35b\f35b"; }

.fa-arrow-alt-circle-up {
  --fa: "\f35b";
  --fa--fa: "\f35b\f35b"; }

.fa-slider {
  --fa: "\e252";
  --fa--fa: "\e252\e252"; }

.fa-mobile-screen-button {
  --fa: "\f3cd";
  --fa--fa: "\f3cd\f3cd"; }

.fa-mobile-alt {
  --fa: "\f3cd";
  --fa--fa: "\f3cd\f3cd"; }

.fa-clock-one-thirty {
  --fa: "\e34f";
  --fa--fa: "\e34f\e34f"; }

.fa-inbox-out {
  --fa: "\f311";
  --fa--fa: "\f311\f311"; }

.fa-inbox-arrow-up {
  --fa: "\f311";
  --fa--fa: "\f311\f311"; }

.fa-cloud-slash {
  --fa: "\e137";
  --fa--fa: "\e137\e137"; }

.fa-volume-high {
  --fa: "\f028";
  --fa--fa: "\f028\f028"; }

.fa-volume-up {
  --fa: "\f028";
  --fa--fa: "\f028\f028"; }

.fa-users-rays {
  --fa: "\e593";
  --fa--fa: "\e593\e593"; }

.fa-wallet {
  --fa: "\f555";
  --fa--fa: "\f555\f555"; }

.fa-octagon-check {
  --fa: "\e426";
  --fa--fa: "\e426\e426"; }

.fa-flatbread-stuffed {
  --fa: "\e40c";
  --fa--fa: "\e40c\e40c"; }

.fa-clipboard-check {
  --fa: "\f46c";
  --fa--fa: "\f46c\f46c"; }

.fa-cart-circle-plus {
  --fa: "\e3f3";
  --fa--fa: "\e3f3\e3f3"; }

.fa-truck-clock {
  --fa: "\f48c";
  --fa--fa: "\f48c\f48c"; }

.fa-shipping-timed {
  --fa: "\f48c";
  --fa--fa: "\f48c\f48c"; }

.fa-pool-8-ball {
  --fa: "\e3c5";
  --fa--fa: "\e3c5\e3c5"; }

.fa-file-audio {
  --fa: "\f1c7";
  --fa--fa: "\f1c7\f1c7"; }

.fa-turn-down-left {
  --fa: "\e331";
  --fa--fa: "\e331\e331"; }

.fa-lock-hashtag {
  --fa: "\e423";
  --fa--fa: "\e423\e423"; }

.fa-chart-radar {
  --fa: "\e0e7";
  --fa--fa: "\e0e7\e0e7"; }

.fa-staff {
  --fa: "\f71b";
  --fa--fa: "\f71b\f71b"; }

.fa-burger {
  --fa: "\f805";
  --fa--fa: "\f805\f805"; }

.fa-hamburger {
  --fa: "\f805";
  --fa--fa: "\f805\f805"; }

.fa-utility-pole {
  --fa: "\e2c3";
  --fa--fa: "\e2c3\e2c3"; }

.fa-transporter-6 {
  --fa: "\e2a7";
  --fa--fa: "\e2a7\e2a7"; }

.fa-arrow-turn-left {
  --fa: "\e632";
  --fa--fa: "\e632\e632"; }

.fa-wrench {
  --fa: "\f0ad";
  --fa--fa: "\f0ad\f0ad"; }

.fa-bugs {
  --fa: "\e4d0";
  --fa--fa: "\e4d0\e4d0"; }

.fa-vector-polygon {
  --fa: "\e2c7";
  --fa--fa: "\e2c7\e2c7"; }

.fa-diagram-nested {
  --fa: "\e157";
  --fa--fa: "\e157\e157"; }

.fa-rupee-sign {
  --fa: "\f156";
  --fa--fa: "\f156\f156"; }

.fa-rupee {
  --fa: "\f156";
  --fa--fa: "\f156\f156"; }

.fa-file-image {
  --fa: "\f1c5";
  --fa--fa: "\f1c5\f1c5"; }

.fa-circle-question {
  --fa: "\f059";
  --fa--fa: "\f059\f059"; }

.fa-question-circle {
  --fa: "\f059";
  --fa--fa: "\f059\f059"; }

.fa-tickets-perforated {
  --fa: "\e63f";
  --fa--fa: "\e63f\e63f"; }

.fa-image-user {
  --fa: "\e1b8";
  --fa--fa: "\e1b8\e1b8"; }

.fa-buoy {
  --fa: "\e5b5";
  --fa--fa: "\e5b5\e5b5"; }

.fa-plane-departure {
  --fa: "\f5b0";
  --fa--fa: "\f5b0\f5b0"; }

.fa-handshake-slash {
  --fa: "\e060";
  --fa--fa: "\e060\e060"; }

.fa-book-bookmark {
  --fa: "\e0bb";
  --fa--fa: "\e0bb\e0bb"; }

.fa-border-center-h {
  --fa: "\f89c";
  --fa--fa: "\f89c\f89c"; }

.fa-can-food {
  --fa: "\e3e6";
  --fa--fa: "\e3e6\e3e6"; }

.fa-typewriter {
  --fa: "\f8e7";
  --fa--fa: "\f8e7\f8e7"; }

.fa-arrow-right-from-arc {
  --fa: "\e4b1";
  --fa--fa: "\e4b1\e4b1"; }

.fa-circle-k {
  --fa: "\e113";
  --fa--fa: "\e113\e113"; }

.fa-face-hand-over-mouth {
  --fa: "\e378";
  --fa--fa: "\e378\e378"; }

.fa-popcorn {
  --fa: "\f819";
  --fa--fa: "\f819\f819"; }

.fa-house-water {
  --fa: "\f74f";
  --fa--fa: "\f74f\f74f"; }

.fa-house-flood {
  --fa: "\f74f";
  --fa--fa: "\f74f\f74f"; }

.fa-object-subtract {
  --fa: "\e49e";
  --fa--fa: "\e49e\e49e"; }

.fa-code-branch {
  --fa: "\f126";
  --fa--fa: "\f126\f126"; }

.fa-warehouse-full {
  --fa: "\f495";
  --fa--fa: "\f495\f495"; }

.fa-warehouse-alt {
  --fa: "\f495";
  --fa--fa: "\f495\f495"; }

.fa-hat-cowboy {
  --fa: "\f8c0";
  --fa--fa: "\f8c0\f8c0"; }

.fa-bridge {
  --fa: "\e4c8";
  --fa--fa: "\e4c8\e4c8"; }

.fa-phone-flip {
  --fa: "\f879";
  --fa--fa: "\f879\f879"; }

.fa-phone-alt {
  --fa: "\f879";
  --fa--fa: "\f879\f879"; }

.fa-arrow-down-from-dotted-line {
  --fa: "\e090";
  --fa--fa: "\e090\e090"; }

.fa-file-doc {
  --fa: "\e5ed";
  --fa--fa: "\e5ed\e5ed"; }

.fa-square-quarters {
  --fa: "\e44e";
  --fa--fa: "\e44e\e44e"; }

.fa-truck-front {
  --fa: "\e2b7";
  --fa--fa: "\e2b7\e2b7"; }

.fa-cat {
  --fa: "\f6be";
  --fa--fa: "\f6be\f6be"; }

.fa-trash-xmark {
  --fa: "\e2b4";
  --fa--fa: "\e2b4\e2b4"; }

.fa-circle-caret-left {
  --fa: "\f32e";
  --fa--fa: "\f32e\f32e"; }

.fa-caret-circle-left {
  --fa: "\f32e";
  --fa--fa: "\f32e\f32e"; }

.fa-files {
  --fa: "\e178";
  --fa--fa: "\e178\e178"; }

.fa-anchor-circle-exclamation {
  --fa: "\e4ab";
  --fa--fa: "\e4ab\e4ab"; }

.fa-face-clouds {
  --fa: "\e47d";
  --fa--fa: "\e47d\e47d"; }

.fa-user-crown {
  --fa: "\f6a4";
  --fa--fa: "\f6a4\f6a4"; }

.fa-basket-shopping-plus {
  --fa: "\e653";
  --fa--fa: "\e653\e653"; }

.fa-truck-field {
  --fa: "\e58d";
  --fa--fa: "\e58d\e58d"; }

.fa-route {
  --fa: "\f4d7";
  --fa--fa: "\f4d7\f4d7"; }

.fa-cart-circle-check {
  --fa: "\e3f1";
  --fa--fa: "\e3f1\e3f1"; }

.fa-clipboard-question {
  --fa: "\e4e3";
  --fa--fa: "\e4e3\e4e3"; }

.fa-panorama {
  --fa: "\e209";
  --fa--fa: "\e209\e209"; }

.fa-comment-medical {
  --fa: "\f7f5";
  --fa--fa: "\f7f5\f7f5"; }

.fa-teeth-open {
  --fa: "\f62f";
  --fa--fa: "\f62f\f62f"; }

.fa-user-tie-hair-long {
  --fa: "\e460";
  --fa--fa: "\e460\e460"; }

.fa-file-circle-minus {
  --fa: "\e4ed";
  --fa--fa: "\e4ed\e4ed"; }

.fa-head-side-medical {
  --fa: "\f809";
  --fa--fa: "\f809\f809"; }

.fa-arrow-turn-right {
  --fa: "\e635";
  --fa--fa: "\e635\e635"; }

.fa-tags {
  --fa: "\f02c";
  --fa--fa: "\f02c\f02c"; }

.fa-wine-glass {
  --fa: "\f4e3";
  --fa--fa: "\f4e3\f4e3"; }

.fa-forward-fast {
  --fa: "\f050";
  --fa--fa: "\f050\f050"; }

.fa-fast-forward {
  --fa: "\f050";
  --fa--fa: "\f050\f050"; }

.fa-face-meh-blank {
  --fa: "\f5a4";
  --fa--fa: "\f5a4\f5a4"; }

.fa-meh-blank {
  --fa: "\f5a4";
  --fa--fa: "\f5a4\f5a4"; }

.fa-user-robot {
  --fa: "\e04b";
  --fa--fa: "\e04b\e04b"; }

.fa-square-parking {
  --fa: "\f540";
  --fa--fa: "\f540\f540"; }

.fa-parking {
  --fa: "\f540";
  --fa--fa: "\f540\f540"; }

.fa-card-diamond {
  --fa: "\e3ea";
  --fa--fa: "\e3ea\e3ea"; }

.fa-face-zipper {
  --fa: "\e3a5";
  --fa--fa: "\e3a5\e3a5"; }

.fa-face-raised-eyebrow {
  --fa: "\e388";
  --fa--fa: "\e388\e388"; }

.fa-house-signal {
  --fa: "\e012";
  --fa--fa: "\e012\e012"; }

.fa-square-chevron-up {
  --fa: "\f32c";
  --fa--fa: "\f32c\f32c"; }

.fa-chevron-square-up {
  --fa: "\f32c";
  --fa--fa: "\f32c\f32c"; }

.fa-bars-progress {
  --fa: "\f828";
  --fa--fa: "\f828\f828"; }

.fa-tasks-alt {
  --fa: "\f828";
  --fa--fa: "\f828\f828"; }

.fa-faucet-drip {
  --fa: "\e006";
  --fa--fa: "\e006\e006"; }

.fa-arrows-to-line {
  --fa: "\e0a7";
  --fa--fa: "\e0a7\e0a7"; }

.fa-dolphin {
  --fa: "\e168";
  --fa--fa: "\e168\e168"; }

.fa-arrow-up-right {
  --fa: "\e09f";
  --fa--fa: "\e09f\e09f"; }

.fa-circle-r {
  --fa: "\e120";
  --fa--fa: "\e120\e120"; }

.fa-cart-flatbed {
  --fa: "\f474";
  --fa--fa: "\f474\f474"; }

.fa-dolly-flatbed {
  --fa: "\f474";
  --fa--fa: "\f474\f474"; }

.fa-ban-smoking {
  --fa: "\f54d";
  --fa--fa: "\f54d\f54d"; }

.fa-smoking-ban {
  --fa: "\f54d";
  --fa--fa: "\f54d\f54d"; }

.fa-circle-sort-up {
  --fa: "\e032";
  --fa--fa: "\e032\e032"; }

.fa-sort-circle-up {
  --fa: "\e032";
  --fa--fa: "\e032\e032"; }

.fa-terminal {
  --fa: "\f120";
  --fa--fa: "\f120\f120"; }

.fa-mobile-button {
  --fa: "\f10b";
  --fa--fa: "\f10b\f10b"; }

.fa-house-medical-flag {
  --fa: "\e514";
  --fa--fa: "\e514\e514"; }

.fa-basket-shopping {
  --fa: "\f291";
  --fa--fa: "\f291\f291"; }

.fa-shopping-basket {
  --fa: "\f291";
  --fa--fa: "\f291\f291"; }

.fa-tape {
  --fa: "\f4db";
  --fa--fa: "\f4db\f4db"; }

.fa-chestnut {
  --fa: "\e3f6";
  --fa--fa: "\e3f6\e3f6"; }

.fa-bus-simple {
  --fa: "\f55e";
  --fa--fa: "\f55e\f55e"; }

.fa-bus-alt {
  --fa: "\f55e";
  --fa--fa: "\f55e\f55e"; }

.fa-eye {
  --fa: "\f06e";
  --fa--fa: "\f06e\f06e"; }

.fa-face-sad-cry {
  --fa: "\f5b3";
  --fa--fa: "\f5b3\f5b3"; }

.fa-sad-cry {
  --fa: "\f5b3";
  --fa--fa: "\f5b3\f5b3"; }

.fa-heat {
  --fa: "\e00c";
  --fa--fa: "\e00c\e00c"; }

.fa-ticket-airline {
  --fa: "\e29a";
  --fa--fa: "\e29a\e29a"; }

.fa-ticket-perforated-plane {
  --fa: "\e29a";
  --fa--fa: "\e29a\e29a"; }

.fa-ticket-plane {
  --fa: "\e29a";
  --fa--fa: "\e29a\e29a"; }

.fa-boot-heeled {
  --fa: "\e33f";
  --fa--fa: "\e33f\e33f"; }

.fa-arrows-minimize {
  --fa: "\e0a5";
  --fa--fa: "\e0a5\e0a5"; }

.fa-compress-arrows {
  --fa: "\e0a5";
  --fa--fa: "\e0a5\e0a5"; }

.fa-audio-description {
  --fa: "\f29e";
  --fa--fa: "\f29e\f29e"; }

.fa-person-military-to-person {
  --fa: "\e54c";
  --fa--fa: "\e54c\e54c"; }

.fa-file-shield {
  --fa: "\e4f0";
  --fa--fa: "\e4f0\e4f0"; }

.fa-hexagon {
  --fa: "\f312";
  --fa--fa: "\f312\f312"; }

.fa-manhole {
  --fa: "\e1d6";
  --fa--fa: "\e1d6\e1d6"; }

.fa-user-slash {
  --fa: "\f506";
  --fa--fa: "\f506\f506"; }

.fa-pen {
  --fa: "\f304";
  --fa--fa: "\f304\f304"; }

.fa-tower-observation {
  --fa: "\e586";
  --fa--fa: "\e586\e586"; }

.fa-floppy-disks {
  --fa: "\e183";
  --fa--fa: "\e183\e183"; }

.fa-toilet-paper-blank-under {
  --fa: "\e29f";
  --fa--fa: "\e29f\e29f"; }

.fa-toilet-paper-reverse-alt {
  --fa: "\e29f";
  --fa--fa: "\e29f\e29f"; }

.fa-file-code {
  --fa: "\f1c9";
  --fa--fa: "\f1c9\f1c9"; }

.fa-signal {
  --fa: "\f012";
  --fa--fa: "\f012\f012"; }

.fa-signal-5 {
  --fa: "\f012";
  --fa--fa: "\f012\f012"; }

.fa-signal-perfect {
  --fa: "\f012";
  --fa--fa: "\f012\f012"; }

.fa-pump {
  --fa: "\e442";
  --fa--fa: "\e442\e442"; }

.fa-bus {
  --fa: "\f207";
  --fa--fa: "\f207\f207"; }

.fa-heart-circle-xmark {
  --fa: "\e501";
  --fa--fa: "\e501\e501"; }

.fa-arrow-up-left-from-circle {
  --fa: "\e09e";
  --fa--fa: "\e09e\e09e"; }

.fa-house-chimney {
  --fa: "\e3af";
  --fa--fa: "\e3af\e3af"; }

.fa-home-lg {
  --fa: "\e3af";
  --fa--fa: "\e3af\e3af"; }

.fa-window-maximize {
  --fa: "\f2d0";
  --fa--fa: "\f2d0\f2d0"; }

.fa-dryer {
  --fa: "\f861";
  --fa--fa: "\f861\f861"; }

.fa-face-frown {
  --fa: "\f119";
  --fa--fa: "\f119\f119"; }

.fa-frown {
  --fa: "\f119";
  --fa--fa: "\f119\f119"; }

.fa-chess-bishop-piece {
  --fa: "\f43b";
  --fa--fa: "\f43b\f43b"; }

.fa-chess-bishop-alt {
  --fa: "\f43b";
  --fa--fa: "\f43b\f43b"; }

.fa-shirt-tank-top {
  --fa: "\e3c9";
  --fa--fa: "\e3c9\e3c9"; }

.fa-diploma {
  --fa: "\f5ea";
  --fa--fa: "\f5ea\f5ea"; }

.fa-scroll-ribbon {
  --fa: "\f5ea";
  --fa--fa: "\f5ea\f5ea"; }

.fa-screencast {
  --fa: "\e23e";
  --fa--fa: "\e23e\e23e"; }

.fa-walker {
  --fa: "\f831";
  --fa--fa: "\f831\f831"; }

.fa-prescription {
  --fa: "\f5b1";
  --fa--fa: "\f5b1\f5b1"; }

.fa-shop {
  --fa: "\f54f";
  --fa--fa: "\f54f\f54f"; }

.fa-store-alt {
  --fa: "\f54f";
  --fa--fa: "\f54f\f54f"; }

.fa-floppy-disk {
  --fa: "\f0c7";
  --fa--fa: "\f0c7\f0c7"; }

.fa-save {
  --fa: "\f0c7";
  --fa--fa: "\f0c7\f0c7"; }

.fa-vihara {
  --fa: "\f6a7";
  --fa--fa: "\f6a7\f6a7"; }

.fa-face-kiss-closed-eyes {
  --fa: "\e37d";
  --fa--fa: "\e37d\e37d"; }

.fa-scale-unbalanced {
  --fa: "\f515";
  --fa--fa: "\f515\f515"; }

.fa-balance-scale-left {
  --fa: "\f515";
  --fa--fa: "\f515\f515"; }

.fa-file-user {
  --fa: "\f65c";
  --fa--fa: "\f65c\f65c"; }

.fa-user-police-tie {
  --fa: "\e334";
  --fa--fa: "\e334\e334"; }

.fa-face-tongue-money {
  --fa: "\e39d";
  --fa--fa: "\e39d\e39d"; }

.fa-tennis-ball {
  --fa: "\f45e";
  --fa--fa: "\f45e\f45e"; }

.fa-square-l {
  --fa: "\e275";
  --fa--fa: "\e275\e275"; }

.fa-sort-up {
  --fa: "\f0de";
  --fa--fa: "\f0de\f0de"; }

.fa-sort-asc {
  --fa: "\f0de";
  --fa--fa: "\f0de\f0de"; }

.fa-calendar-arrow-up {
  --fa: "\e0d1";
  --fa--fa: "\e0d1\e0d1"; }

.fa-calendar-upload {
  --fa: "\e0d1";
  --fa--fa: "\e0d1\e0d1"; }

.fa-comment-dots {
  --fa: "\f4ad";
  --fa--fa: "\f4ad\f4ad"; }

.fa-commenting {
  --fa: "\f4ad";
  --fa--fa: "\f4ad\f4ad"; }

.fa-plant-wilt {
  --fa: "\e5aa";
  --fa--fa: "\e5aa\e5aa"; }

.fa-scarf {
  --fa: "\f7c1";
  --fa--fa: "\f7c1\f7c1"; }

.fa-album-circle-plus {
  --fa: "\e48c";
  --fa--fa: "\e48c\e48c"; }

.fa-user-nurse-hair-long {
  --fa: "\e45e";
  --fa--fa: "\e45e\e45e"; }

.fa-diamond {
  --fa: "\f219";
  --fa--fa: "\f219\f219"; }

.fa-square-left {
  --fa: "\f351";
  --fa--fa: "\f351\f351"; }

.fa-arrow-alt-square-left {
  --fa: "\f351";
  --fa--fa: "\f351\f351"; }

.fa-face-grin-squint {
  --fa: "\f585";
  --fa--fa: "\f585\f585"; }

.fa-grin-squint {
  --fa: "\f585";
  --fa--fa: "\f585\f585"; }

.fa-circle-ellipsis-vertical {
  --fa: "\e10b";
  --fa--fa: "\e10b\e10b"; }

.fa-hand-holding-dollar {
  --fa: "\f4c0";
  --fa--fa: "\f4c0\f4c0"; }

.fa-hand-holding-usd {
  --fa: "\f4c0";
  --fa--fa: "\f4c0\f4c0"; }

.fa-grid-dividers {
  --fa: "\e3ad";
  --fa--fa: "\e3ad\e3ad"; }

.fa-chart-diagram {
  --fa: "\e695";
  --fa--fa: "\e695\e695"; }

.fa-bacterium {
  --fa: "\e05a";
  --fa--fa: "\e05a\e05a"; }

.fa-hand-pointer {
  --fa: "\f25a";
  --fa--fa: "\f25a\f25a"; }

.fa-drum-steelpan {
  --fa: "\f56a";
  --fa--fa: "\f56a\f56a"; }

.fa-hand-scissors {
  --fa: "\f257";
  --fa--fa: "\f257\f257"; }

.fa-hands-praying {
  --fa: "\f684";
  --fa--fa: "\f684\f684"; }

.fa-praying-hands {
  --fa: "\f684";
  --fa--fa: "\f684\f684"; }

.fa-face-pensive {
  --fa: "\e384";
  --fa--fa: "\e384\e384"; }

.fa-user-music {
  --fa: "\f8eb";
  --fa--fa: "\f8eb\f8eb"; }

.fa-arrow-rotate-right {
  --fa: "\f01e";
  --fa--fa: "\f01e\f01e"; }

.fa-arrow-right-rotate {
  --fa: "\f01e";
  --fa--fa: "\f01e\f01e"; }

.fa-arrow-rotate-forward {
  --fa: "\f01e";
  --fa--fa: "\f01e\f01e"; }

.fa-redo {
  --fa: "\f01e";
  --fa--fa: "\f01e\f01e"; }

.fa-messages-dollar {
  --fa: "\f652";
  --fa--fa: "\f652\f652"; }

.fa-comments-alt-dollar {
  --fa: "\f652";
  --fa--fa: "\f652\f652"; }

.fa-sensor-on {
  --fa: "\e02b";
  --fa--fa: "\e02b\e02b"; }

.fa-balloon {
  --fa: "\e2e3";
  --fa--fa: "\e2e3\e2e3"; }

.fa-biohazard {
  --fa: "\f780";
  --fa--fa: "\f780\f780"; }

.fa-chess-queen-piece {
  --fa: "\f446";
  --fa--fa: "\f446\f446"; }

.fa-chess-queen-alt {
  --fa: "\f446";
  --fa--fa: "\f446\f446"; }

.fa-location-crosshairs {
  --fa: "\f601";
  --fa--fa: "\f601\f601"; }

.fa-location {
  --fa: "\f601";
  --fa--fa: "\f601\f601"; }

.fa-mars-double {
  --fa: "\f227";
  --fa--fa: "\f227\f227"; }

.fa-left-from-bracket {
  --fa: "\e66c";
  --fa--fa: "\e66c\e66c"; }

.fa-house-person-leave {
  --fa: "\e00f";
  --fa--fa: "\e00f\e00f"; }

.fa-house-leave {
  --fa: "\e00f";
  --fa--fa: "\e00f\e00f"; }

.fa-house-person-depart {
  --fa: "\e00f";
  --fa--fa: "\e00f\e00f"; }

.fa-ruler-triangle {
  --fa: "\f61c";
  --fa--fa: "\f61c\f61c"; }

.fa-card-club {
  --fa: "\e3e9";
  --fa--fa: "\e3e9\e3e9"; }

.fa-child-dress {
  --fa: "\e59c";
  --fa--fa: "\e59c\e59c"; }

.fa-users-between-lines {
  --fa: "\e591";
  --fa--fa: "\e591\e591"; }

.fa-lungs-virus {
  --fa: "\e067";
  --fa--fa: "\e067\e067"; }

.fa-spinner-third {
  --fa: "\f3f4";
  --fa--fa: "\f3f4\f3f4"; }

.fa-face-grin-tears {
  --fa: "\f588";
  --fa--fa: "\f588\f588"; }

.fa-grin-tears {
  --fa: "\f588";
  --fa--fa: "\f588\f588"; }

.fa-phone {
  --fa: "\f095";
  --fa--fa: "\f095\f095"; }

.fa-computer-mouse-scrollwheel {
  --fa: "\f8cd";
  --fa--fa: "\f8cd\f8cd"; }

.fa-mouse-alt {
  --fa: "\f8cd";
  --fa--fa: "\f8cd\f8cd"; }

.fa-calendar-xmark {
  --fa: "\f273";
  --fa--fa: "\f273\f273"; }

.fa-calendar-times {
  --fa: "\f273";
  --fa--fa: "\f273\f273"; }

.fa-child-reaching {
  --fa: "\e59d";
  --fa--fa: "\e59d\e59d"; }

.fa-table-layout {
  --fa: "\e290";
  --fa--fa: "\e290\e290"; }

.fa-narwhal {
  --fa: "\f6fe";
  --fa--fa: "\f6fe\f6fe"; }

.fa-ramp-loading {
  --fa: "\f4d4";
  --fa--fa: "\f4d4\f4d4"; }

.fa-calendar-circle-plus {
  --fa: "\e470";
  --fa--fa: "\e470\e470"; }

.fa-toothbrush {
  --fa: "\f635";
  --fa--fa: "\f635\f635"; }

.fa-border-inner {
  --fa: "\f84e";
  --fa--fa: "\f84e\f84e"; }

.fa-paw-claws {
  --fa: "\f702";
  --fa--fa: "\f702\f702"; }

.fa-kiwi-fruit {
  --fa: "\e30c";
  --fa--fa: "\e30c\e30c"; }

.fa-traffic-light-slow {
  --fa: "\f639";
  --fa--fa: "\f639\f639"; }

.fa-rectangle-code {
  --fa: "\e322";
  --fa--fa: "\e322\e322"; }

.fa-head-side-virus {
  --fa: "\e064";
  --fa--fa: "\e064\e064"; }

.fa-keyboard-brightness {
  --fa: "\e1c0";
  --fa--fa: "\e1c0\e1c0"; }

.fa-books-medical {
  --fa: "\f7e8";
  --fa--fa: "\f7e8\f7e8"; }

.fa-lightbulb-slash {
  --fa: "\f673";
  --fa--fa: "\f673\f673"; }

.fa-house-blank {
  --fa: "\e487";
  --fa--fa: "\e487\e487"; }

.fa-home-blank {
  --fa: "\e487";
  --fa--fa: "\e487\e487"; }

.fa-square-5 {
  --fa: "\e25a";
  --fa--fa: "\e25a\e25a"; }

.fa-square-heart {
  --fa: "\f4c8";
  --fa--fa: "\f4c8\f4c8"; }

.fa-heart-square {
  --fa: "\f4c8";
  --fa--fa: "\f4c8\f4c8"; }

.fa-puzzle {
  --fa: "\e443";
  --fa--fa: "\e443\e443"; }

.fa-user-gear {
  --fa: "\f4fe";
  --fa--fa: "\f4fe\f4fe"; }

.fa-user-cog {
  --fa: "\f4fe";
  --fa--fa: "\f4fe\f4fe"; }

.fa-pipe-circle-check {
  --fa: "\e436";
  --fa--fa: "\e436\e436"; }

.fa-arrow-up-1-9 {
  --fa: "\f163";
  --fa--fa: "\f163\f163"; }

.fa-sort-numeric-up {
  --fa: "\f163";
  --fa--fa: "\f163\f163"; }

.fa-octagon-exclamation {
  --fa: "\e204";
  --fa--fa: "\e204\e204"; }

.fa-dial-low {
  --fa: "\e15d";
  --fa--fa: "\e15d\e15d"; }

.fa-door-closed {
  --fa: "\f52a";
  --fa--fa: "\f52a\f52a"; }

.fa-laptop-mobile {
  --fa: "\f87a";
  --fa--fa: "\f87a\f87a"; }

.fa-phone-laptop {
  --fa: "\f87a";
  --fa--fa: "\f87a\f87a"; }

.fa-conveyor-belt-boxes {
  --fa: "\f46f";
  --fa--fa: "\f46f\f46f"; }

.fa-conveyor-belt-alt {
  --fa: "\f46f";
  --fa--fa: "\f46f\f46f"; }

.fa-shield-virus {
  --fa: "\e06c";
  --fa--fa: "\e06c\e06c"; }

.fa-starfighter-twin-ion-engine-advanced {
  --fa: "\e28e";
  --fa--fa: "\e28e\e28e"; }

.fa-starfighter-alt-advanced {
  --fa: "\e28e";
  --fa--fa: "\e28e\e28e"; }

.fa-dice-six {
  --fa: "\f526";
  --fa--fa: "\f526\f526"; }

.fa-starfighter-twin-ion-engine {
  --fa: "\e038";
  --fa--fa: "\e038\e038"; }

.fa-starfighter-alt {
  --fa: "\e038";
  --fa--fa: "\e038\e038"; }

.fa-rocket-launch {
  --fa: "\e027";
  --fa--fa: "\e027\e027"; }

.fa-mosquito-net {
  --fa: "\e52c";
  --fa--fa: "\e52c\e52c"; }

.fa-file-fragment {
  --fa: "\e697";
  --fa--fa: "\e697\e697"; }

.fa-vent-damper {
  --fa: "\e465";
  --fa--fa: "\e465\e465"; }

.fa-bridge-water {
  --fa: "\e4ce";
  --fa--fa: "\e4ce\e4ce"; }

.fa-ban-bug {
  --fa: "\f7f9";
  --fa--fa: "\f7f9\f7f9"; }

.fa-debug {
  --fa: "\f7f9";
  --fa--fa: "\f7f9\f7f9"; }

.fa-person-booth {
  --fa: "\f756";
  --fa--fa: "\f756\f756"; }

.fa-text-width {
  --fa: "\f035";
  --fa--fa: "\f035\f035"; }

.fa-garage-car {
  --fa: "\e00a";
  --fa--fa: "\e00a\e00a"; }

.fa-square-kanban {
  --fa: "\e488";
  --fa--fa: "\e488\e488"; }

.fa-hat-wizard {
  --fa: "\f6e8";
  --fa--fa: "\f6e8\f6e8"; }

.fa-chart-kanban {
  --fa: "\e64f";
  --fa--fa: "\e64f\e64f"; }

.fa-pen-fancy {
  --fa: "\f5ac";
  --fa--fa: "\f5ac\f5ac"; }

.fa-coffee-pot {
  --fa: "\e002";
  --fa--fa: "\e002\e002"; }

.fa-mouse-field {
  --fa: "\e5a8";
  --fa--fa: "\e5a8\e5a8"; }

.fa-person-digging {
  --fa: "\f85e";
  --fa--fa: "\f85e\f85e"; }

.fa-digging {
  --fa: "\f85e";
  --fa--fa: "\f85e\f85e"; }

.fa-shower-down {
  --fa: "\e24d";
  --fa--fa: "\e24d\e24d"; }

.fa-shower-alt {
  --fa: "\e24d";
  --fa--fa: "\e24d\e24d"; }

.fa-box-circle-check {
  --fa: "\e0c4";
  --fa--fa: "\e0c4\e0c4"; }

.fa-brightness {
  --fa: "\e0c9";
  --fa--fa: "\e0c9\e0c9"; }

.fa-car-side-bolt {
  --fa: "\e344";
  --fa--fa: "\e344\e344"; }

.fa-file-xml {
  --fa: "\e654";
  --fa--fa: "\e654\e654"; }

.fa-ornament {
  --fa: "\f7b8";
  --fa--fa: "\f7b8\f7b8"; }

.fa-phone-arrow-down-left {
  --fa: "\e223";
  --fa--fa: "\e223\e223"; }

.fa-phone-arrow-down {
  --fa: "\e223";
  --fa--fa: "\e223\e223"; }

.fa-phone-incoming {
  --fa: "\e223";
  --fa--fa: "\e223\e223"; }

.fa-cloud-word {
  --fa: "\e138";
  --fa--fa: "\e138\e138"; }

.fa-hand-fingers-crossed {
  --fa: "\e1a3";
  --fa--fa: "\e1a3\e1a3"; }

.fa-trash {
  --fa: "\f1f8";
  --fa--fa: "\f1f8\f1f8"; }

.fa-gauge-simple {
  --fa: "\f629";
  --fa--fa: "\f629\f629"; }

.fa-gauge-simple-med {
  --fa: "\f629";
  --fa--fa: "\f629\f629"; }

.fa-tachometer-average {
  --fa: "\f629";
  --fa--fa: "\f629\f629"; }

.fa-arrow-down-small-big {
  --fa: "\f88d";
  --fa--fa: "\f88d\f88d"; }

.fa-sort-size-down-alt {
  --fa: "\f88d";
  --fa--fa: "\f88d\f88d"; }

.fa-book-medical {
  --fa: "\f7e6";
  --fa--fa: "\f7e6\f7e6"; }

.fa-face-melting {
  --fa: "\e483";
  --fa--fa: "\e483\e483"; }

.fa-poo {
  --fa: "\f2fe";
  --fa--fa: "\f2fe\f2fe"; }

.fa-pen-clip-slash {
  --fa: "\e20f";
  --fa--fa: "\e20f\e20f"; }

.fa-pen-alt-slash {
  --fa: "\e20f";
  --fa--fa: "\e20f\e20f"; }

.fa-quote-right {
  --fa: "\f10e";
  --fa--fa: "\f10e\f10e"; }

.fa-quote-right-alt {
  --fa: "\f10e";
  --fa--fa: "\f10e\f10e"; }

.fa-scroll-old {
  --fa: "\f70f";
  --fa--fa: "\f70f\f70f"; }

.fa-guitars {
  --fa: "\f8bf";
  --fa--fa: "\f8bf\f8bf"; }

.fa-phone-xmark {
  --fa: "\e227";
  --fa--fa: "\e227\e227"; }

.fa-hose {
  --fa: "\e419";
  --fa--fa: "\e419\e419"; }

.fa-clock-six {
  --fa: "\e352";
  --fa--fa: "\e352\e352"; }

.fa-shirt {
  --fa: "\f553";
  --fa--fa: "\f553\f553"; }

.fa-t-shirt {
  --fa: "\f553";
  --fa--fa: "\f553\f553"; }

.fa-tshirt {
  --fa: "\f553";
  --fa--fa: "\f553\f553"; }

.fa-billboard {
  --fa: "\e5cd";
  --fa--fa: "\e5cd\e5cd"; }

.fa-square-r {
  --fa: "\e27c";
  --fa--fa: "\e27c\e27c"; }

.fa-cubes {
  --fa: "\f1b3";
  --fa--fa: "\f1b3\f1b3"; }

.fa-envelope-open-dollar {
  --fa: "\f657";
  --fa--fa: "\f657\f657"; }

.fa-divide {
  --fa: "\f529";
  --fa--fa: "\f529\f529"; }

.fa-sun-cloud {
  --fa: "\f763";
  --fa--fa: "\f763\f763"; }

.fa-lamp-floor {
  --fa: "\e015";
  --fa--fa: "\e015\e015"; }

.fa-square-7 {
  --fa: "\e25c";
  --fa--fa: "\e25c\e25c"; }

.fa-tenge-sign {
  --fa: "\f7d7";
  --fa--fa: "\f7d7\f7d7"; }

.fa-tenge {
  --fa: "\f7d7";
  --fa--fa: "\f7d7\f7d7"; }

.fa-headphones {
  --fa: "\f025";
  --fa--fa: "\f025\f025"; }

.fa-hands-holding {
  --fa: "\f4c2";
  --fa--fa: "\f4c2\f4c2"; }

.fa-campfire {
  --fa: "\f6ba";
  --fa--fa: "\f6ba\f6ba"; }

.fa-circle-ampersand {
  --fa: "\e0f8";
  --fa--fa: "\e0f8\e0f8"; }

.fa-snowflakes {
  --fa: "\f7cf";
  --fa--fa: "\f7cf\f7cf"; }

.fa-hands-clapping {
  --fa: "\e1a8";
  --fa--fa: "\e1a8\e1a8"; }

.fa-republican {
  --fa: "\f75e";
  --fa--fa: "\f75e\f75e"; }

.fa-leaf-maple {
  --fa: "\f6f6";
  --fa--fa: "\f6f6\f6f6"; }

.fa-arrow-left {
  --fa: "\f060";
  --fa--fa: "\f060\f060"; }

.fa-person-circle-xmark {
  --fa: "\e543";
  --fa--fa: "\e543\e543"; }

.fa-ruler {
  --fa: "\f545";
  --fa--fa: "\f545\f545"; }

.fa-arrow-left-from-bracket {
  --fa: "\e668";
  --fa--fa: "\e668\e668"; }

.fa-cup-straw-swoosh {
  --fa: "\e364";
  --fa--fa: "\e364\e364"; }

.fa-temperature-sun {
  --fa: "\f76a";
  --fa--fa: "\f76a\f76a"; }

.fa-temperature-hot {
  --fa: "\f76a";
  --fa--fa: "\f76a\f76a"; }

.fa-align-left {
  --fa: "\f036";
  --fa--fa: "\f036\f036"; }

.fa-dice-d6 {
  --fa: "\f6d1";
  --fa--fa: "\f6d1\f6d1"; }

.fa-restroom {
  --fa: "\f7bd";
  --fa--fa: "\f7bd\f7bd"; }

.fa-high-definition {
  --fa: "\e1ae";
  --fa--fa: "\e1ae\e1ae"; }

.fa-rectangle-hd {
  --fa: "\e1ae";
  --fa--fa: "\e1ae\e1ae"; }

.fa-j {
  --fa: "\4a";
  --fa--fa: "\4a\4a"; }

.fa-galaxy {
  --fa: "\e008";
  --fa--fa: "\e008\e008"; }

.fa-users-viewfinder {
  --fa: "\e595";
  --fa--fa: "\e595\e595"; }

.fa-file-video {
  --fa: "\f1c8";
  --fa--fa: "\f1c8\f1c8"; }

.fa-cherries {
  --fa: "\e0ec";
  --fa--fa: "\e0ec\e0ec"; }

.fa-up-right-from-square {
  --fa: "\f35d";
  --fa--fa: "\f35d\f35d"; }

.fa-external-link-alt {
  --fa: "\f35d";
  --fa--fa: "\f35d\f35d"; }

.fa-circle-sort {
  --fa: "\e030";
  --fa--fa: "\e030\e030"; }

.fa-sort-circle {
  --fa: "\e030";
  --fa--fa: "\e030\e030"; }

.fa-table-cells {
  --fa: "\f00a";
  --fa--fa: "\f00a\f00a"; }

.fa-th {
  --fa: "\f00a";
  --fa--fa: "\f00a\f00a"; }

.fa-bag-shopping-minus {
  --fa: "\e650";
  --fa--fa: "\e650\e650"; }

.fa-file-pdf {
  --fa: "\f1c1";
  --fa--fa: "\f1c1\f1c1"; }

.fa-siren {
  --fa: "\e02d";
  --fa--fa: "\e02d\e02d"; }

.fa-arrow-up-to-dotted-line {
  --fa: "\e0a1";
  --fa--fa: "\e0a1\e0a1"; }

.fa-image-landscape {
  --fa: "\e1b5";
  --fa--fa: "\e1b5\e1b5"; }

.fa-landscape {
  --fa: "\e1b5";
  --fa--fa: "\e1b5\e1b5"; }

.fa-tank-water {
  --fa: "\e452";
  --fa--fa: "\e452\e452"; }

.fa-curling-stone {
  --fa: "\f44a";
  --fa--fa: "\f44a\f44a"; }

.fa-curling {
  --fa: "\f44a";
  --fa--fa: "\f44a\f44a"; }

.fa-gamepad-modern {
  --fa: "\e5a2";
  --fa--fa: "\e5a2\e5a2"; }

.fa-gamepad-alt {
  --fa: "\e5a2";
  --fa--fa: "\e5a2\e5a2"; }

.fa-messages-question {
  --fa: "\e1e7";
  --fa--fa: "\e1e7\e1e7"; }

.fa-book-bible {
  --fa: "\f647";
  --fa--fa: "\f647\f647"; }

.fa-bible {
  --fa: "\f647";
  --fa--fa: "\f647\f647"; }

.fa-o {
  --fa: "\4f";
  --fa--fa: "\4f\4f"; }

.fa-suitcase-medical {
  --fa: "\f0fa";
  --fa--fa: "\f0fa\f0fa"; }

.fa-medkit {
  --fa: "\f0fa";
  --fa--fa: "\f0fa\f0fa"; }

.fa-briefcase-arrow-right {
  --fa: "\e2f2";
  --fa--fa: "\e2f2\e2f2"; }

.fa-expand-wide {
  --fa: "\f320";
  --fa--fa: "\f320\f320"; }

.fa-clock-eleven-thirty {
  --fa: "\e348";
  --fa--fa: "\e348\e348"; }

.fa-rv {
  --fa: "\f7be";
  --fa--fa: "\f7be\f7be"; }

.fa-user-secret {
  --fa: "\f21b";
  --fa--fa: "\f21b\f21b"; }

.fa-otter {
  --fa: "\f700";
  --fa--fa: "\f700\f700"; }

.fa-dreidel {
  --fa: "\f792";
  --fa--fa: "\f792\f792"; }

.fa-person-dress {
  --fa: "\f182";
  --fa--fa: "\f182\f182"; }

.fa-female {
  --fa: "\f182";
  --fa--fa: "\f182\f182"; }

.fa-comment-dollar {
  --fa: "\f651";
  --fa--fa: "\f651\f651"; }

.fa-business-time {
  --fa: "\f64a";
  --fa--fa: "\f64a\f64a"; }

.fa-briefcase-clock {
  --fa: "\f64a";
  --fa--fa: "\f64a\f64a"; }

.fa-flower-tulip {
  --fa: "\f801";
  --fa--fa: "\f801\f801"; }

.fa-people-pants-simple {
  --fa: "\e21a";
  --fa--fa: "\e21a\e21a"; }

.fa-cloud-drizzle {
  --fa: "\f738";
  --fa--fa: "\f738\f738"; }

.fa-table-cells-large {
  --fa: "\f009";
  --fa--fa: "\f009\f009"; }

.fa-th-large {
  --fa: "\f009";
  --fa--fa: "\f009\f009"; }

.fa-book-tanakh {
  --fa: "\f827";
  --fa--fa: "\f827\f827"; }

.fa-tanakh {
  --fa: "\f827";
  --fa--fa: "\f827\f827"; }

.fa-solar-system {
  --fa: "\e02f";
  --fa--fa: "\e02f\e02f"; }

.fa-seal-question {
  --fa: "\e243";
  --fa--fa: "\e243\e243"; }

.fa-phone-volume {
  --fa: "\f2a0";
  --fa--fa: "\f2a0\f2a0"; }

.fa-volume-control-phone {
  --fa: "\f2a0";
  --fa--fa: "\f2a0\f2a0"; }

.fa-disc-drive {
  --fa: "\f8b5";
  --fa--fa: "\f8b5\f8b5"; }

.fa-hat-cowboy-side {
  --fa: "\f8c1";
  --fa--fa: "\f8c1\f8c1"; }

.fa-table-rows {
  --fa: "\e292";
  --fa--fa: "\e292\e292"; }

.fa-rows {
  --fa: "\e292";
  --fa--fa: "\e292\e292"; }

.fa-location-exclamation {
  --fa: "\f608";
  --fa--fa: "\f608\f608"; }

.fa-map-marker-exclamation {
  --fa: "\f608";
  --fa--fa: "\f608\f608"; }

.fa-face-fearful {
  --fa: "\e375";
  --fa--fa: "\e375\e375"; }

.fa-clipboard-user {
  --fa: "\f7f3";
  --fa--fa: "\f7f3\f7f3"; }

.fa-bus-school {
  --fa: "\f5dd";
  --fa--fa: "\f5dd\f5dd"; }

.fa-film-slash {
  --fa: "\e179";
  --fa--fa: "\e179\e179"; }

.fa-square-arrow-down-right {
  --fa: "\e262";
  --fa--fa: "\e262\e262"; }

.fa-book-sparkles {
  --fa: "\f6b8";
  --fa--fa: "\f6b8\f6b8"; }

.fa-book-spells {
  --fa: "\f6b8";
  --fa--fa: "\f6b8\f6b8"; }

.fa-washing-machine {
  --fa: "\f898";
  --fa--fa: "\f898\f898"; }

.fa-washer {
  --fa: "\f898";
  --fa--fa: "\f898\f898"; }

.fa-child {
  --fa: "\f1ae";
  --fa--fa: "\f1ae\f1ae"; }

.fa-lira-sign {
  --fa: "\f195";
  --fa--fa: "\f195\f195"; }

.fa-user-visor {
  --fa: "\e04c";
  --fa--fa: "\e04c\e04c"; }

.fa-file-plus-minus {
  --fa: "\e177";
  --fa--fa: "\e177\e177"; }

.fa-chess-clock-flip {
  --fa: "\f43e";
  --fa--fa: "\f43e\f43e"; }

.fa-chess-clock-alt {
  --fa: "\f43e";
  --fa--fa: "\f43e\f43e"; }

.fa-satellite {
  --fa: "\f7bf";
  --fa--fa: "\f7bf\f7bf"; }

.fa-truck-fire {
  --fa: "\e65a";
  --fa--fa: "\e65a\e65a"; }

.fa-plane-lock {
  --fa: "\e558";
  --fa--fa: "\e558\e558"; }

.fa-steering-wheel {
  --fa: "\f622";
  --fa--fa: "\f622\f622"; }

.fa-tag {
  --fa: "\f02b";
  --fa--fa: "\f02b\f02b"; }

.fa-stretcher {
  --fa: "\f825";
  --fa--fa: "\f825\f825"; }

.fa-book-section {
  --fa: "\e0c1";
  --fa--fa: "\e0c1\e0c1"; }

.fa-book-law {
  --fa: "\e0c1";
  --fa--fa: "\e0c1\e0c1"; }

.fa-inboxes {
  --fa: "\e1bb";
  --fa--fa: "\e1bb\e1bb"; }

.fa-coffee-bean {
  --fa: "\e13e";
  --fa--fa: "\e13e\e13e"; }

.fa-circle-yen {
  --fa: "\e5d0";
  --fa--fa: "\e5d0\e5d0"; }

.fa-brackets-curly {
  --fa: "\f7ea";
  --fa--fa: "\f7ea\f7ea"; }

.fa-ellipsis-stroke-vertical {
  --fa: "\f39c";
  --fa--fa: "\f39c\f39c"; }

.fa-ellipsis-v-alt {
  --fa: "\f39c";
  --fa--fa: "\f39c\f39c"; }

.fa-comment {
  --fa: "\f075";
  --fa--fa: "\f075\f075"; }

.fa-square-1 {
  --fa: "\e256";
  --fa--fa: "\e256\e256"; }

.fa-cake-candles {
  --fa: "\f1fd";
  --fa--fa: "\f1fd\f1fd"; }

.fa-birthday-cake {
  --fa: "\f1fd";
  --fa--fa: "\f1fd\f1fd"; }

.fa-cake {
  --fa: "\f1fd";
  --fa--fa: "\f1fd\f1fd"; }

.fa-head-side {
  --fa: "\f6e9";
  --fa--fa: "\f6e9\f6e9"; }

.fa-truck-ladder {
  --fa: "\e657";
  --fa--fa: "\e657\e657"; }

.fa-envelope {
  --fa: "\f0e0";
  --fa--fa: "\f0e0\f0e0"; }

.fa-dolly-empty {
  --fa: "\f473";
  --fa--fa: "\f473\f473"; }

.fa-face-tissue {
  --fa: "\e39c";
  --fa--fa: "\e39c\e39c"; }

.fa-angles-up {
  --fa: "\f102";
  --fa--fa: "\f102\f102"; }

.fa-angle-double-up {
  --fa: "\f102";
  --fa--fa: "\f102\f102"; }

.fa-bin-recycle {
  --fa: "\e5f7";
  --fa--fa: "\e5f7\e5f7"; }

.fa-paperclip {
  --fa: "\f0c6";
  --fa--fa: "\f0c6\f0c6"; }

.fa-chart-line-down {
  --fa: "\f64d";
  --fa--fa: "\f64d\f64d"; }

.fa-arrow-right-to-city {
  --fa: "\e4b3";
  --fa--fa: "\e4b3\e4b3"; }

.fa-lock-a {
  --fa: "\e422";
  --fa--fa: "\e422\e422"; }

.fa-ribbon {
  --fa: "\f4d6";
  --fa--fa: "\f4d6\f4d6"; }

.fa-lungs {
  --fa: "\f604";
  --fa--fa: "\f604\f604"; }

.fa-person-pinball {
  --fa: "\e21d";
  --fa--fa: "\e21d\e21d"; }

.fa-arrow-up-9-1 {
  --fa: "\f887";
  --fa--fa: "\f887\f887"; }

.fa-sort-numeric-up-alt {
  --fa: "\f887";
  --fa--fa: "\f887\f887"; }

.fa-apple-core {
  --fa: "\e08f";
  --fa--fa: "\e08f\e08f"; }

.fa-circle-y {
  --fa: "\e12f";
  --fa--fa: "\e12f\e12f"; }

.fa-h6 {
  --fa: "\e413";
  --fa--fa: "\e413\e413"; }

.fa-litecoin-sign {
  --fa: "\e1d3";
  --fa--fa: "\e1d3\e1d3"; }

.fa-bottle-baby {
  --fa: "\e673";
  --fa--fa: "\e673\e673"; }

.fa-circle-small {
  --fa: "\e122";
  --fa--fa: "\e122\e122"; }

.fa-border-none {
  --fa: "\f850";
  --fa--fa: "\f850\f850"; }

.fa-arrow-turn-down-left {
  --fa: "\e2e1";
  --fa--fa: "\e2e1\e2e1"; }

.fa-circle-wifi-circle-wifi {
  --fa: "\e67e";
  --fa--fa: "\e67e\e67e"; }

.fa-circle-wifi-group {
  --fa: "\e67e";
  --fa--fa: "\e67e\e67e"; }

.fa-circle-nodes {
  --fa: "\e4e2";
  --fa--fa: "\e4e2\e4e2"; }

.fa-parachute-box {
  --fa: "\f4cd";
  --fa--fa: "\f4cd\f4cd"; }

.fa-reflect-horizontal {
  --fa: "\e664";
  --fa--fa: "\e664\e664"; }

.fa-message-medical {
  --fa: "\f7f4";
  --fa--fa: "\f7f4\f7f4"; }

.fa-comment-alt-medical {
  --fa: "\f7f4";
  --fa--fa: "\f7f4\f7f4"; }

.fa-rugby-ball {
  --fa: "\e3c6";
  --fa--fa: "\e3c6\e3c6"; }

.fa-comment-music {
  --fa: "\f8b0";
  --fa--fa: "\f8b0\f8b0"; }

.fa-indent {
  --fa: "\f03c";
  --fa--fa: "\f03c\f03c"; }

.fa-tree-deciduous {
  --fa: "\f400";
  --fa--fa: "\f400\f400"; }

.fa-tree-alt {
  --fa: "\f400";
  --fa--fa: "\f400\f400"; }

.fa-puzzle-piece-simple {
  --fa: "\e231";
  --fa--fa: "\e231\e231"; }

.fa-puzzle-piece-alt {
  --fa: "\e231";
  --fa--fa: "\e231\e231"; }

.fa-truck-field-un {
  --fa: "\e58e";
  --fa--fa: "\e58e\e58e"; }

.fa-nfc-trash {
  --fa: "\e1fd";
  --fa--fa: "\e1fd\e1fd"; }

.fa-hourglass {
  --fa: "\f254";
  --fa--fa: "\f254\f254"; }

.fa-hourglass-empty {
  --fa: "\f254";
  --fa--fa: "\f254\f254"; }

.fa-mountain {
  --fa: "\f6fc";
  --fa--fa: "\f6fc\f6fc"; }

.fa-file-xmark {
  --fa: "\f317";
  --fa--fa: "\f317\f317"; }

.fa-file-times {
  --fa: "\f317";
  --fa--fa: "\f317\f317"; }

.fa-house-heart {
  --fa: "\f4c9";
  --fa--fa: "\f4c9\f4c9"; }

.fa-home-heart {
  --fa: "\f4c9";
  --fa--fa: "\f4c9\f4c9"; }

.fa-house-chimney-blank {
  --fa: "\e3b0";
  --fa--fa: "\e3b0\e3b0"; }

.fa-meter-bolt {
  --fa: "\e1e9";
  --fa--fa: "\e1e9\e1e9"; }

.fa-user-doctor {
  --fa: "\f0f0";
  --fa--fa: "\f0f0\f0f0"; }

.fa-user-md {
  --fa: "\f0f0";
  --fa--fa: "\f0f0\f0f0"; }

.fa-slash-back {
  --fa: "\5c";
  --fa--fa: "\5c\5c"; }

.fa-circle-info {
  --fa: "\f05a";
  --fa--fa: "\f05a\f05a"; }

.fa-info-circle {
  --fa: "\f05a";
  --fa--fa: "\f05a\f05a"; }

.fa-fishing-rod {
  --fa: "\e3a8";
  --fa--fa: "\e3a8\e3a8"; }

.fa-hammer-crash {
  --fa: "\e414";
  --fa--fa: "\e414\e414"; }

.fa-message-heart {
  --fa: "\e5c9";
  --fa--fa: "\e5c9\e5c9"; }

.fa-cloud-meatball {
  --fa: "\f73b";
  --fa--fa: "\f73b\f73b"; }

.fa-camera-polaroid {
  --fa: "\f8aa";
  --fa--fa: "\f8aa\f8aa"; }

.fa-camera {
  --fa: "\f030";
  --fa--fa: "\f030\f030"; }

.fa-camera-alt {
  --fa: "\f030";
  --fa--fa: "\f030\f030"; }

.fa-square-virus {
  --fa: "\e578";
  --fa--fa: "\e578\e578"; }

.fa-cart-arrow-up {
  --fa: "\e3ee";
  --fa--fa: "\e3ee\e3ee"; }

.fa-meteor {
  --fa: "\f753";
  --fa--fa: "\f753\f753"; }

.fa-car-on {
  --fa: "\e4dd";
  --fa--fa: "\e4dd\e4dd"; }

.fa-sleigh {
  --fa: "\f7cc";
  --fa--fa: "\f7cc\f7cc"; }

.fa-arrow-down-1-9 {
  --fa: "\f162";
  --fa--fa: "\f162\f162"; }

.fa-sort-numeric-asc {
  --fa: "\f162";
  --fa--fa: "\f162\f162"; }

.fa-sort-numeric-down {
  --fa: "\f162";
  --fa--fa: "\f162\f162"; }

.fa-buoy-mooring {
  --fa: "\e5b6";
  --fa--fa: "\e5b6\e5b6"; }

.fa-square-4 {
  --fa: "\e259";
  --fa--fa: "\e259\e259"; }

.fa-hand-holding-droplet {
  --fa: "\f4c1";
  --fa--fa: "\f4c1\f4c1"; }

.fa-hand-holding-water {
  --fa: "\f4c1";
  --fa--fa: "\f4c1\f4c1"; }

.fa-file-eps {
  --fa: "\e644";
  --fa--fa: "\e644\e644"; }

.fa-tricycle-adult {
  --fa: "\e5c4";
  --fa--fa: "\e5c4\e5c4"; }

.fa-waveform {
  --fa: "\f8f1";
  --fa--fa: "\f8f1\f8f1"; }

.fa-water {
  --fa: "\f773";
  --fa--fa: "\f773\f773"; }

.fa-star-sharp-half-stroke {
  --fa: "\e28d";
  --fa--fa: "\e28d\e28d"; }

.fa-star-sharp-half-alt {
  --fa: "\e28d";
  --fa--fa: "\e28d\e28d"; }

.fa-nfc-signal {
  --fa: "\e1fb";
  --fa--fa: "\e1fb\e1fb"; }

.fa-plane-prop {
  --fa: "\e22b";
  --fa--fa: "\e22b\e22b"; }

.fa-calendar-check {
  --fa: "\f274";
  --fa--fa: "\f274\f274"; }

.fa-clock-desk {
  --fa: "\e134";
  --fa--fa: "\e134\e134"; }

.fa-calendar-clock {
  --fa: "\e0d2";
  --fa--fa: "\e0d2\e0d2"; }

.fa-calendar-time {
  --fa: "\e0d2";
  --fa--fa: "\e0d2\e0d2"; }

.fa-braille {
  --fa: "\f2a1";
  --fa--fa: "\f2a1\f2a1"; }

.fa-prescription-bottle-medical {
  --fa: "\f486";
  --fa--fa: "\f486\f486"; }

.fa-prescription-bottle-alt {
  --fa: "\f486";
  --fa--fa: "\f486\f486"; }

.fa-plate-utensils {
  --fa: "\e43b";
  --fa--fa: "\e43b\e43b"; }

.fa-family-pants {
  --fa: "\e302";
  --fa--fa: "\e302\e302"; }

.fa-hose-reel {
  --fa: "\e41a";
  --fa--fa: "\e41a\e41a"; }

.fa-house-window {
  --fa: "\e3b3";
  --fa--fa: "\e3b3\e3b3"; }

.fa-landmark {
  --fa: "\f66f";
  --fa--fa: "\f66f\f66f"; }

.fa-truck {
  --fa: "\f0d1";
  --fa--fa: "\f0d1\f0d1"; }

.fa-music-magnifying-glass {
  --fa: "\e662";
  --fa--fa: "\e662\e662"; }

.fa-crosshairs {
  --fa: "\f05b";
  --fa--fa: "\f05b\f05b"; }

.fa-cloud-rainbow {
  --fa: "\f73e";
  --fa--fa: "\f73e\f73e"; }

.fa-person-cane {
  --fa: "\e53c";
  --fa--fa: "\e53c\e53c"; }

.fa-alien {
  --fa: "\f8f5";
  --fa--fa: "\f8f5\f8f5"; }

.fa-tent {
  --fa: "\e57d";
  --fa--fa: "\e57d\e57d"; }

.fa-laptop-binary {
  --fa: "\e5e7";
  --fa--fa: "\e5e7\e5e7"; }

.fa-vest-patches {
  --fa: "\e086";
  --fa--fa: "\e086\e086"; }

.fa-people-dress-simple {
  --fa: "\e218";
  --fa--fa: "\e218\e218"; }

.fa-check-double {
  --fa: "\f560";
  --fa--fa: "\f560\f560"; }

.fa-arrow-down-a-z {
  --fa: "\f15d";
  --fa--fa: "\f15d\f15d"; }

.fa-sort-alpha-asc {
  --fa: "\f15d";
  --fa--fa: "\f15d\f15d"; }

.fa-sort-alpha-down {
  --fa: "\f15d";
  --fa--fa: "\f15d\f15d"; }

.fa-bowling-ball-pin {
  --fa: "\e0c3";
  --fa--fa: "\e0c3\e0c3"; }

.fa-bell-school-slash {
  --fa: "\f5d6";
  --fa--fa: "\f5d6\f5d6"; }

.fa-plus-large {
  --fa: "\e59e";
  --fa--fa: "\e59e\e59e"; }

.fa-money-bill-wheat {
  --fa: "\e52a";
  --fa--fa: "\e52a\e52a"; }

.fa-camera-viewfinder {
  --fa: "\e0da";
  --fa--fa: "\e0da\e0da"; }

.fa-screenshot {
  --fa: "\e0da";
  --fa--fa: "\e0da\e0da"; }

.fa-message-music {
  --fa: "\f8af";
  --fa--fa: "\f8af\f8af"; }

.fa-comment-alt-music {
  --fa: "\f8af";
  --fa--fa: "\f8af\f8af"; }

.fa-car-building {
  --fa: "\f859";
  --fa--fa: "\f859\f859"; }

.fa-border-bottom-right {
  --fa: "\f854";
  --fa--fa: "\f854\f854"; }

.fa-border-style-alt {
  --fa: "\f854";
  --fa--fa: "\f854\f854"; }

.fa-octagon {
  --fa: "\f306";
  --fa--fa: "\f306\f306"; }

.fa-comment-arrow-up-right {
  --fa: "\e145";
  --fa--fa: "\e145\e145"; }

.fa-octagon-divide {
  --fa: "\e203";
  --fa--fa: "\e203\e203"; }

.fa-cookie {
  --fa: "\f563";
  --fa--fa: "\f563\f563"; }

.fa-arrow-rotate-left {
  --fa: "\f0e2";
  --fa--fa: "\f0e2\f0e2"; }

.fa-arrow-left-rotate {
  --fa: "\f0e2";
  --fa--fa: "\f0e2\f0e2"; }

.fa-arrow-rotate-back {
  --fa: "\f0e2";
  --fa--fa: "\f0e2\f0e2"; }

.fa-arrow-rotate-backward {
  --fa: "\f0e2";
  --fa--fa: "\f0e2\f0e2"; }

.fa-undo {
  --fa: "\f0e2";
  --fa--fa: "\f0e2\f0e2"; }

.fa-tv-music {
  --fa: "\f8e6";
  --fa--fa: "\f8e6\f8e6"; }

.fa-hard-drive {
  --fa: "\f0a0";
  --fa--fa: "\f0a0\f0a0"; }

.fa-hdd {
  --fa: "\f0a0";
  --fa--fa: "\f0a0\f0a0"; }

.fa-reel {
  --fa: "\e238";
  --fa--fa: "\e238\e238"; }

.fa-face-grin-squint-tears {
  --fa: "\f586";
  --fa--fa: "\f586\f586"; }

.fa-grin-squint-tears {
  --fa: "\f586";
  --fa--fa: "\f586\f586"; }

.fa-dumbbell {
  --fa: "\f44b";
  --fa--fa: "\f44b\f44b"; }

.fa-rectangle-list {
  --fa: "\f022";
  --fa--fa: "\f022\f022"; }

.fa-list-alt {
  --fa: "\f022";
  --fa--fa: "\f022\f022"; }

.fa-tarp-droplet {
  --fa: "\e57c";
  --fa--fa: "\e57c\e57c"; }

.fa-alarm-exclamation {
  --fa: "\f843";
  --fa--fa: "\f843\f843"; }

.fa-house-medical-circle-check {
  --fa: "\e511";
  --fa--fa: "\e511\e511"; }

.fa-traffic-cone {
  --fa: "\f636";
  --fa--fa: "\f636\f636"; }

.fa-grate {
  --fa: "\e193";
  --fa--fa: "\e193\e193"; }

.fa-arrow-down-right {
  --fa: "\e093";
  --fa--fa: "\e093\e093"; }

.fa-person-skiing-nordic {
  --fa: "\f7ca";
  --fa--fa: "\f7ca\f7ca"; }

.fa-skiing-nordic {
  --fa: "\f7ca";
  --fa--fa: "\f7ca\f7ca"; }

.fa-calendar-plus {
  --fa: "\f271";
  --fa--fa: "\f271\f271"; }

.fa-person-from-portal {
  --fa: "\e023";
  --fa--fa: "\e023\e023"; }

.fa-portal-exit {
  --fa: "\e023";
  --fa--fa: "\e023\e023"; }

.fa-plane-arrival {
  --fa: "\f5af";
  --fa--fa: "\f5af\f5af"; }

.fa-cowbell-circle-plus {
  --fa: "\f8b4";
  --fa--fa: "\f8b4\f8b4"; }

.fa-cowbell-more {
  --fa: "\f8b4";
  --fa--fa: "\f8b4\f8b4"; }

.fa-circle-left {
  --fa: "\f359";
  --fa--fa: "\f359\f359"; }

.fa-arrow-alt-circle-left {
  --fa: "\f359";
  --fa--fa: "\f359\f359"; }

.fa-distribute-spacing-vertical {
  --fa: "\e366";
  --fa--fa: "\e366\e366"; }

.fa-signal-bars-fair {
  --fa: "\f692";
  --fa--fa: "\f692\f692"; }

.fa-signal-alt-2 {
  --fa: "\f692";
  --fa--fa: "\f692\f692"; }

.fa-sportsball {
  --fa: "\e44b";
  --fa--fa: "\e44b\e44b"; }

.fa-game-console-handheld-crank {
  --fa: "\e5b9";
  --fa--fa: "\e5b9\e5b9"; }

.fa-train-subway {
  --fa: "\f239";
  --fa--fa: "\f239\f239"; }

.fa-subway {
  --fa: "\f239";
  --fa--fa: "\f239\f239"; }

.fa-chart-gantt {
  --fa: "\e0e4";
  --fa--fa: "\e0e4\e0e4"; }

.fa-face-smile-upside-down {
  --fa: "\e395";
  --fa--fa: "\e395\e395"; }

.fa-ball-pile {
  --fa: "\f77e";
  --fa--fa: "\f77e\f77e"; }

.fa-badge-dollar {
  --fa: "\f645";
  --fa--fa: "\f645\f645"; }

.fa-money-bills-simple {
  --fa: "\e1f4";
  --fa--fa: "\e1f4\e1f4"; }

.fa-money-bills-alt {
  --fa: "\e1f4";
  --fa--fa: "\e1f4\e1f4"; }

.fa-list-timeline {
  --fa: "\e1d1";
  --fa--fa: "\e1d1\e1d1"; }

.fa-indian-rupee-sign {
  --fa: "\e1bc";
  --fa--fa: "\e1bc\e1bc"; }

.fa-indian-rupee {
  --fa: "\e1bc";
  --fa--fa: "\e1bc\e1bc"; }

.fa-inr {
  --fa: "\e1bc";
  --fa--fa: "\e1bc\e1bc"; }

.fa-crop-simple {
  --fa: "\f565";
  --fa--fa: "\f565\f565"; }

.fa-crop-alt {
  --fa: "\f565";
  --fa--fa: "\f565\f565"; }

.fa-money-bill-1 {
  --fa: "\f3d1";
  --fa--fa: "\f3d1\f3d1"; }

.fa-money-bill-alt {
  --fa: "\f3d1";
  --fa--fa: "\f3d1\f3d1"; }

.fa-left-long {
  --fa: "\f30a";
  --fa--fa: "\f30a\f30a"; }

.fa-long-arrow-alt-left {
  --fa: "\f30a";
  --fa--fa: "\f30a\f30a"; }

.fa-keyboard-down {
  --fa: "\e1c2";
  --fa--fa: "\e1c2\e1c2"; }

.fa-circle-up-right {
  --fa: "\e129";
  --fa--fa: "\e129\e129"; }

.fa-cloud-bolt-moon {
  --fa: "\f76d";
  --fa--fa: "\f76d\f76d"; }

.fa-thunderstorm-moon {
  --fa: "\f76d";
  --fa--fa: "\f76d\f76d"; }

.fa-turn-left-up {
  --fa: "\e638";
  --fa--fa: "\e638\e638"; }

.fa-dna {
  --fa: "\f471";
  --fa--fa: "\f471\f471"; }

.fa-virus-slash {
  --fa: "\e075";
  --fa--fa: "\e075\e075"; }

.fa-bracket-round-right {
  --fa: "\29";
  --fa--fa: "\29\29"; }

.fa-circle-sterling {
  --fa: "\e5cf";
  --fa--fa: "\e5cf\e5cf"; }

.fa-circle-5 {
  --fa: "\e0f2";
  --fa--fa: "\e0f2\e0f2"; }

.fa-minus {
  --fa: "\f068";
  --fa--fa: "\f068\f068"; }

.fa-subtract {
  --fa: "\f068";
  --fa--fa: "\f068\f068"; }

.fa-fire-flame {
  --fa: "\f6df";
  --fa--fa: "\f6df\f6df"; }

.fa-flame {
  --fa: "\f6df";
  --fa--fa: "\f6df\f6df"; }

.fa-right-to-line {
  --fa: "\f34c";
  --fa--fa: "\f34c\f34c"; }

.fa-arrow-alt-to-right {
  --fa: "\f34c";
  --fa--fa: "\f34c\f34c"; }

.fa-gif {
  --fa: "\e190";
  --fa--fa: "\e190\e190"; }

.fa-chess {
  --fa: "\f439";
  --fa--fa: "\f439\f439"; }

.fa-trash-slash {
  --fa: "\e2b3";
  --fa--fa: "\e2b3\e2b3"; }

.fa-arrow-left-long {
  --fa: "\f177";
  --fa--fa: "\f177\f177"; }

.fa-long-arrow-left {
  --fa: "\f177";
  --fa--fa: "\f177\f177"; }

.fa-plug-circle-check {
  --fa: "\e55c";
  --fa--fa: "\e55c\e55c"; }

.fa-font-case {
  --fa: "\f866";
  --fa--fa: "\f866\f866"; }

.fa-street-view {
  --fa: "\f21d";
  --fa--fa: "\f21d\f21d"; }

.fa-arrow-down-left {
  --fa: "\e091";
  --fa--fa: "\e091\e091"; }

.fa-franc-sign {
  --fa: "\e18f";
  --fa--fa: "\e18f\e18f"; }

.fa-flask-round-poison {
  --fa: "\f6e0";
  --fa--fa: "\f6e0\f6e0"; }

.fa-flask-poison {
  --fa: "\f6e0";
  --fa--fa: "\f6e0\f6e0"; }

.fa-volume-off {
  --fa: "\f026";
  --fa--fa: "\f026\f026"; }

.fa-book-circle-arrow-right {
  --fa: "\e0bc";
  --fa--fa: "\e0bc\e0bc"; }

.fa-chart-user {
  --fa: "\f6a3";
  --fa--fa: "\f6a3\f6a3"; }

.fa-user-chart {
  --fa: "\f6a3";
  --fa--fa: "\f6a3\f6a3"; }

.fa-hands-asl-interpreting {
  --fa: "\f2a3";
  --fa--fa: "\f2a3\f2a3"; }

.fa-american-sign-language-interpreting {
  --fa: "\f2a3";
  --fa--fa: "\f2a3\f2a3"; }

.fa-asl-interpreting {
  --fa: "\f2a3";
  --fa--fa: "\f2a3\f2a3"; }

.fa-hands-american-sign-language-interpreting {
  --fa: "\f2a3";
  --fa--fa: "\f2a3\f2a3"; }

.fa-presentation-screen {
  --fa: "\f685";
  --fa--fa: "\f685\f685"; }

.fa-presentation {
  --fa: "\f685";
  --fa--fa: "\f685\f685"; }

.fa-circle-bolt {
  --fa: "\e0fe";
  --fa--fa: "\e0fe\e0fe"; }

.fa-face-smile-halo {
  --fa: "\e38f";
  --fa--fa: "\e38f\e38f"; }

.fa-cart-circle-arrow-down {
  --fa: "\e3ef";
  --fa--fa: "\e3ef\e3ef"; }

.fa-house-person-return {
  --fa: "\e011";
  --fa--fa: "\e011\e011"; }

.fa-house-person-arrive {
  --fa: "\e011";
  --fa--fa: "\e011\e011"; }

.fa-house-return {
  --fa: "\e011";
  --fa--fa: "\e011\e011"; }

.fa-message-xmark {
  --fa: "\f4ab";
  --fa--fa: "\f4ab\f4ab"; }

.fa-comment-alt-times {
  --fa: "\f4ab";
  --fa--fa: "\f4ab\f4ab"; }

.fa-message-times {
  --fa: "\f4ab";
  --fa--fa: "\f4ab\f4ab"; }

.fa-file-certificate {
  --fa: "\f5f3";
  --fa--fa: "\f5f3\f5f3"; }

.fa-file-award {
  --fa: "\f5f3";
  --fa--fa: "\f5f3\f5f3"; }

.fa-user-doctor-hair-long {
  --fa: "\e459";
  --fa--fa: "\e459\e459"; }

.fa-camera-security {
  --fa: "\f8fe";
  --fa--fa: "\f8fe\f8fe"; }

.fa-camera-home {
  --fa: "\f8fe";
  --fa--fa: "\f8fe\f8fe"; }

.fa-gear {
  --fa: "\f013";
  --fa--fa: "\f013\f013"; }

.fa-cog {
  --fa: "\f013";
  --fa--fa: "\f013\f013"; }

.fa-droplet-slash {
  --fa: "\f5c7";
  --fa--fa: "\f5c7\f5c7"; }

.fa-tint-slash {
  --fa: "\f5c7";
  --fa--fa: "\f5c7\f5c7"; }

.fa-book-heart {
  --fa: "\f499";
  --fa--fa: "\f499\f499"; }

.fa-mosque {
  --fa: "\f678";
  --fa--fa: "\f678\f678"; }

.fa-duck {
  --fa: "\f6d8";
  --fa--fa: "\f6d8\f6d8"; }

.fa-mosquito {
  --fa: "\e52b";
  --fa--fa: "\e52b\e52b"; }

.fa-star-of-david {
  --fa: "\f69a";
  --fa--fa: "\f69a\f69a"; }

.fa-flag-swallowtail {
  --fa: "\f74c";
  --fa--fa: "\f74c\f74c"; }

.fa-flag-alt {
  --fa: "\f74c";
  --fa--fa: "\f74c\f74c"; }

.fa-person-military-rifle {
  --fa: "\e54b";
  --fa--fa: "\e54b\e54b"; }

.fa-car-garage {
  --fa: "\f5e2";
  --fa--fa: "\f5e2\f5e2"; }

.fa-cart-shopping {
  --fa: "\f07a";
  --fa--fa: "\f07a\f07a"; }

.fa-shopping-cart {
  --fa: "\f07a";
  --fa--fa: "\f07a\f07a"; }

.fa-book-font {
  --fa: "\e0bf";
  --fa--fa: "\e0bf\e0bf"; }

.fa-shield-plus {
  --fa: "\e24a";
  --fa--fa: "\e24a\e24a"; }

.fa-vials {
  --fa: "\f493";
  --fa--fa: "\f493\f493"; }

.fa-eye-dropper-full {
  --fa: "\e172";
  --fa--fa: "\e172\e172"; }

.fa-distribute-spacing-horizontal {
  --fa: "\e365";
  --fa--fa: "\e365\e365"; }

.fa-tablet-rugged {
  --fa: "\f48f";
  --fa--fa: "\f48f\f48f"; }

.fa-temperature-snow {
  --fa: "\f768";
  --fa--fa: "\f768\f768"; }

.fa-temperature-frigid {
  --fa: "\f768";
  --fa--fa: "\f768\f768"; }

.fa-moped {
  --fa: "\e3b9";
  --fa--fa: "\e3b9\e3b9"; }

.fa-face-smile-plus {
  --fa: "\f5b9";
  --fa--fa: "\f5b9\f5b9"; }

.fa-smile-plus {
  --fa: "\f5b9";
  --fa--fa: "\f5b9\f5b9"; }

.fa-radio-tuner {
  --fa: "\f8d8";
  --fa--fa: "\f8d8\f8d8"; }

.fa-radio-alt {
  --fa: "\f8d8";
  --fa--fa: "\f8d8\f8d8"; }

.fa-face-swear {
  --fa: "\e399";
  --fa--fa: "\e399\e399"; }

.fa-water-arrow-down {
  --fa: "\f774";
  --fa--fa: "\f774\f774"; }

.fa-water-lower {
  --fa: "\f774";
  --fa--fa: "\f774\f774"; }

.fa-scanner-touchscreen {
  --fa: "\f48a";
  --fa--fa: "\f48a\f48a"; }

.fa-circle-7 {
  --fa: "\e0f4";
  --fa--fa: "\e0f4\e0f4"; }

.fa-plug-circle-plus {
  --fa: "\e55f";
  --fa--fa: "\e55f\e55f"; }

.fa-person-ski-jumping {
  --fa: "\f7c7";
  --fa--fa: "\f7c7\f7c7"; }

.fa-ski-jump {
  --fa: "\f7c7";
  --fa--fa: "\f7c7\f7c7"; }

.fa-place-of-worship {
  --fa: "\f67f";
  --fa--fa: "\f67f\f67f"; }

.fa-water-arrow-up {
  --fa: "\f775";
  --fa--fa: "\f775\f775"; }

.fa-water-rise {
  --fa: "\f775";
  --fa--fa: "\f775\f775"; }

.fa-waveform-lines {
  --fa: "\f8f2";
  --fa--fa: "\f8f2\f8f2"; }

.fa-waveform-path {
  --fa: "\f8f2";
  --fa--fa: "\f8f2\f8f2"; }

.fa-split {
  --fa: "\e254";
  --fa--fa: "\e254\e254"; }

.fa-film-canister {
  --fa: "\f8b7";
  --fa--fa: "\f8b7\f8b7"; }

.fa-film-cannister {
  --fa: "\f8b7";
  --fa--fa: "\f8b7\f8b7"; }

.fa-folder-xmark {
  --fa: "\f65f";
  --fa--fa: "\f65f\f65f"; }

.fa-folder-times {
  --fa: "\f65f";
  --fa--fa: "\f65f\f65f"; }

.fa-toilet-paper-blank {
  --fa: "\f71f";
  --fa--fa: "\f71f\f71f"; }

.fa-toilet-paper-alt {
  --fa: "\f71f";
  --fa--fa: "\f71f\f71f"; }

.fa-tablet-screen {
  --fa: "\f3fc";
  --fa--fa: "\f3fc\f3fc"; }

.fa-tablet-android-alt {
  --fa: "\f3fc";
  --fa--fa: "\f3fc\f3fc"; }

.fa-hexagon-vertical-nft-slanted {
  --fa: "\e506";
  --fa--fa: "\e506\e506"; }

.fa-folder-music {
  --fa: "\e18d";
  --fa--fa: "\e18d\e18d"; }

.fa-display-medical {
  --fa: "\e166";
  --fa--fa: "\e166\e166"; }

.fa-desktop-medical {
  --fa: "\e166";
  --fa--fa: "\e166\e166"; }

.fa-share-all {
  --fa: "\f367";
  --fa--fa: "\f367\f367"; }

.fa-peapod {
  --fa: "\e31c";
  --fa--fa: "\e31c\e31c"; }

.fa-chess-clock {
  --fa: "\f43d";
  --fa--fa: "\f43d\f43d"; }

.fa-axe {
  --fa: "\f6b2";
  --fa--fa: "\f6b2\f6b2"; }

.fa-square-d {
  --fa: "\e268";
  --fa--fa: "\e268\e268"; }

.fa-grip-vertical {
  --fa: "\f58e";
  --fa--fa: "\f58e\f58e"; }

.fa-mobile-signal-out {
  --fa: "\e1f0";
  --fa--fa: "\e1f0\e1f0"; }

.fa-hexagon-nodes {
  --fa: "\e699";
  --fa--fa: "\e699\e699"; }

.fa-arrow-turn-up {
  --fa: "\f148";
  --fa--fa: "\f148\f148"; }

.fa-level-up {
  --fa: "\f148";
  --fa--fa: "\f148\f148"; }

.fa-u {
  --fa: "\55";
  --fa--fa: "\55\55"; }

.fa-arrow-up-from-dotted-line {
  --fa: "\e09b";
  --fa--fa: "\e09b\e09b"; }

.fa-square-root-variable {
  --fa: "\f698";
  --fa--fa: "\f698\f698"; }

.fa-square-root-alt {
  --fa: "\f698";
  --fa--fa: "\f698\f698"; }

.fa-light-switch-on {
  --fa: "\e019";
  --fa--fa: "\e019\e019"; }

.fa-arrow-down-arrow-up {
  --fa: "\f883";
  --fa--fa: "\f883\f883"; }

.fa-sort-alt {
  --fa: "\f883";
  --fa--fa: "\f883\f883"; }

.fa-raindrops {
  --fa: "\f75c";
  --fa--fa: "\f75c\f75c"; }

.fa-dash {
  --fa: "\e404";
  --fa--fa: "\e404\e404"; }

.fa-minus-large {
  --fa: "\e404";
  --fa--fa: "\e404\e404"; }

.fa-clock {
  --fa: "\f017";
  --fa--fa: "\f017\f017"; }

.fa-clock-four {
  --fa: "\f017";
  --fa--fa: "\f017\f017"; }

.fa-input-numeric {
  --fa: "\e1bd";
  --fa--fa: "\e1bd\e1bd"; }

.fa-truck-tow {
  --fa: "\e2b8";
  --fa--fa: "\e2b8\e2b8"; }

.fa-backward-step {
  --fa: "\f048";
  --fa--fa: "\f048\f048"; }

.fa-step-backward {
  --fa: "\f048";
  --fa--fa: "\f048\f048"; }

.fa-pallet {
  --fa: "\f482";
  --fa--fa: "\f482\f482"; }

.fa-car-bolt {
  --fa: "\e341";
  --fa--fa: "\e341\e341"; }

.fa-arrows-maximize {
  --fa: "\f31d";
  --fa--fa: "\f31d\f31d"; }

.fa-expand-arrows {
  --fa: "\f31d";
  --fa--fa: "\f31d\f31d"; }

.fa-faucet {
  --fa: "\e005";
  --fa--fa: "\e005\e005"; }

.fa-cloud-sleet {
  --fa: "\f741";
  --fa--fa: "\f741\f741"; }

.fa-lamp-street {
  --fa: "\e1c5";
  --fa--fa: "\e1c5\e1c5"; }

.fa-list-radio {
  --fa: "\e1d0";
  --fa--fa: "\e1d0\e1d0"; }

.fa-pen-nib-slash {
  --fa: "\e4a1";
  --fa--fa: "\e4a1\e4a1"; }

.fa-baseball-bat-ball {
  --fa: "\f432";
  --fa--fa: "\f432\f432"; }

.fa-square-up-left {
  --fa: "\e282";
  --fa--fa: "\e282\e282"; }

.fa-overline {
  --fa: "\f876";
  --fa--fa: "\f876\f876"; }

.fa-s {
  --fa: "\53";
  --fa--fa: "\53\53"; }

.fa-timeline {
  --fa: "\e29c";
  --fa--fa: "\e29c\e29c"; }

.fa-keyboard {
  --fa: "\f11c";
  --fa--fa: "\f11c\f11c"; }

.fa-arrows-from-dotted-line {
  --fa: "\e0a3";
  --fa--fa: "\e0a3\e0a3"; }

.fa-usb-drive {
  --fa: "\f8e9";
  --fa--fa: "\f8e9\f8e9"; }

.fa-ballot {
  --fa: "\f732";
  --fa--fa: "\f732\f732"; }

.fa-caret-down {
  --fa: "\f0d7";
  --fa--fa: "\f0d7\f0d7"; }

.fa-location-dot-slash {
  --fa: "\f605";
  --fa--fa: "\f605\f605"; }

.fa-map-marker-alt-slash {
  --fa: "\f605";
  --fa--fa: "\f605\f605"; }

.fa-cards {
  --fa: "\e3ed";
  --fa--fa: "\e3ed\e3ed"; }

.fa-house-chimney-medical {
  --fa: "\f7f2";
  --fa--fa: "\f7f2\f7f2"; }

.fa-clinic-medical {
  --fa: "\f7f2";
  --fa--fa: "\f7f2\f7f2"; }

.fa-boxing-glove {
  --fa: "\f438";
  --fa--fa: "\f438\f438"; }

.fa-glove-boxing {
  --fa: "\f438";
  --fa--fa: "\f438\f438"; }

.fa-temperature-three-quarters {
  --fa: "\f2c8";
  --fa--fa: "\f2c8\f2c8"; }

.fa-temperature-3 {
  --fa: "\f2c8";
  --fa--fa: "\f2c8\f2c8"; }

.fa-thermometer-3 {
  --fa: "\f2c8";
  --fa--fa: "\f2c8\f2c8"; }

.fa-thermometer-three-quarters {
  --fa: "\f2c8";
  --fa--fa: "\f2c8\f2c8"; }

.fa-bell-school {
  --fa: "\f5d5";
  --fa--fa: "\f5d5\f5d5"; }

.fa-mobile-screen {
  --fa: "\f3cf";
  --fa--fa: "\f3cf\f3cf"; }

.fa-mobile-android-alt {
  --fa: "\f3cf";
  --fa--fa: "\f3cf\f3cf"; }

.fa-plane-up {
  --fa: "\e22d";
  --fa--fa: "\e22d\e22d"; }

.fa-folder-heart {
  --fa: "\e189";
  --fa--fa: "\e189\e189"; }

.fa-circle-location-arrow {
  --fa: "\f602";
  --fa--fa: "\f602\f602"; }

.fa-location-circle {
  --fa: "\f602";
  --fa--fa: "\f602\f602"; }

.fa-face-head-bandage {
  --fa: "\e37a";
  --fa--fa: "\e37a\e37a"; }

.fa-sushi-roll {
  --fa: "\e48b";
  --fa--fa: "\e48b\e48b"; }

.fa-maki-roll {
  --fa: "\e48b";
  --fa--fa: "\e48b\e48b"; }

.fa-makizushi {
  --fa: "\e48b";
  --fa--fa: "\e48b\e48b"; }

.fa-car-bump {
  --fa: "\f5e0";
  --fa--fa: "\f5e0\f5e0"; }

.fa-piggy-bank {
  --fa: "\f4d3";
  --fa--fa: "\f4d3\f4d3"; }

.fa-racquet {
  --fa: "\f45a";
  --fa--fa: "\f45a\f45a"; }

.fa-car-mirrors {
  --fa: "\e343";
  --fa--fa: "\e343\e343"; }

.fa-industry-windows {
  --fa: "\f3b3";
  --fa--fa: "\f3b3\f3b3"; }

.fa-industry-alt {
  --fa: "\f3b3";
  --fa--fa: "\f3b3\f3b3"; }

.fa-bolt-auto {
  --fa: "\e0b6";
  --fa--fa: "\e0b6\e0b6"; }

.fa-battery-half {
  --fa: "\f242";
  --fa--fa: "\f242\f242"; }

.fa-battery-3 {
  --fa: "\f242";
  --fa--fa: "\f242\f242"; }

.fa-flux-capacitor {
  --fa: "\f8ba";
  --fa--fa: "\f8ba\f8ba"; }

.fa-mountain-city {
  --fa: "\e52e";
  --fa--fa: "\e52e\e52e"; }

.fa-coins {
  --fa: "\f51e";
  --fa--fa: "\f51e\f51e"; }

.fa-honey-pot {
  --fa: "\e418";
  --fa--fa: "\e418\e418"; }

.fa-olive {
  --fa: "\e316";
  --fa--fa: "\e316\e316"; }

.fa-khanda {
  --fa: "\f66d";
  --fa--fa: "\f66d\f66d"; }

.fa-filter-list {
  --fa: "\e17c";
  --fa--fa: "\e17c\e17c"; }

.fa-outlet {
  --fa: "\e01c";
  --fa--fa: "\e01c\e01c"; }

.fa-sliders {
  --fa: "\f1de";
  --fa--fa: "\f1de\f1de"; }

.fa-sliders-h {
  --fa: "\f1de";
  --fa--fa: "\f1de\f1de"; }

.fa-cauldron {
  --fa: "\f6bf";
  --fa--fa: "\f6bf\f6bf"; }

.fa-people {
  --fa: "\e216";
  --fa--fa: "\e216\e216"; }

.fa-folder-tree {
  --fa: "\f802";
  --fa--fa: "\f802\f802"; }

.fa-network-wired {
  --fa: "\f6ff";
  --fa--fa: "\f6ff\f6ff"; }

.fa-croissant {
  --fa: "\f7f6";
  --fa--fa: "\f7f6\f7f6"; }

.fa-map-pin {
  --fa: "\f276";
  --fa--fa: "\f276\f276"; }

.fa-hamsa {
  --fa: "\f665";
  --fa--fa: "\f665\f665"; }

.fa-cent-sign {
  --fa: "\e3f5";
  --fa--fa: "\e3f5\e3f5"; }

.fa-swords-laser {
  --fa: "\e03d";
  --fa--fa: "\e03d\e03d"; }

.fa-flask {
  --fa: "\f0c3";
  --fa--fa: "\f0c3\f0c3"; }

.fa-person-pregnant {
  --fa: "\e31e";
  --fa--fa: "\e31e\e31e"; }

.fa-square-u {
  --fa: "\e281";
  --fa--fa: "\e281\e281"; }

.fa-wand-sparkles {
  --fa: "\f72b";
  --fa--fa: "\f72b\f72b"; }

.fa-router {
  --fa: "\f8da";
  --fa--fa: "\f8da\f8da"; }

.fa-ellipsis-vertical {
  --fa: "\f142";
  --fa--fa: "\f142\f142"; }

.fa-ellipsis-v {
  --fa: "\f142";
  --fa--fa: "\f142\f142"; }

.fa-sword-laser-alt {
  --fa: "\e03c";
  --fa--fa: "\e03c\e03c"; }

.fa-ticket {
  --fa: "\f145";
  --fa--fa: "\f145\f145"; }

.fa-power-off {
  --fa: "\f011";
  --fa--fa: "\f011\f011"; }

.fa-coin {
  --fa: "\f85c";
  --fa--fa: "\f85c\f85c"; }

.fa-laptop-slash {
  --fa: "\e1c7";
  --fa--fa: "\e1c7\e1c7"; }

.fa-right-long {
  --fa: "\f30b";
  --fa--fa: "\f30b\f30b"; }

.fa-long-arrow-alt-right {
  --fa: "\f30b";
  --fa--fa: "\f30b\f30b"; }

.fa-circle-b {
  --fa: "\e0fd";
  --fa--fa: "\e0fd\e0fd"; }

.fa-person-dress-simple {
  --fa: "\e21c";
  --fa--fa: "\e21c\e21c"; }

.fa-pipe-collar {
  --fa: "\e437";
  --fa--fa: "\e437\e437"; }

.fa-lights-holiday {
  --fa: "\f7b2";
  --fa--fa: "\f7b2\f7b2"; }

.fa-citrus {
  --fa: "\e2f4";
  --fa--fa: "\e2f4\e2f4"; }

.fa-flag-usa {
  --fa: "\f74d";
  --fa--fa: "\f74d\f74d"; }

.fa-laptop-file {
  --fa: "\e51d";
  --fa--fa: "\e51d\e51d"; }

.fa-tty {
  --fa: "\f1e4";
  --fa--fa: "\f1e4\f1e4"; }

.fa-teletype {
  --fa: "\f1e4";
  --fa--fa: "\f1e4\f1e4"; }

.fa-chart-tree-map {
  --fa: "\e0ea";
  --fa--fa: "\e0ea\e0ea"; }

.fa-diagram-next {
  --fa: "\e476";
  --fa--fa: "\e476\e476"; }

.fa-person-rifle {
  --fa: "\e54e";
  --fa--fa: "\e54e\e54e"; }

.fa-clock-five-thirty {
  --fa: "\e34a";
  --fa--fa: "\e34a\e34a"; }

.fa-pipe-valve {
  --fa: "\e439";
  --fa--fa: "\e439\e439"; }

.fa-lightbulb-message {
  --fa: "\e687";
  --fa--fa: "\e687\e687"; }

.fa-arrow-up-from-arc {
  --fa: "\e4b4";
  --fa--fa: "\e4b4\e4b4"; }

.fa-face-spiral-eyes {
  --fa: "\e485";
  --fa--fa: "\e485\e485"; }

.fa-compress-wide {
  --fa: "\f326";
  --fa--fa: "\f326\f326"; }

.fa-circle-phone-hangup {
  --fa: "\e11d";
  --fa--fa: "\e11d\e11d"; }

.fa-phone-circle-down {
  --fa: "\e11d";
  --fa--fa: "\e11d\e11d"; }

.fa-gear-complex-code {
  --fa: "\e5eb";
  --fa--fa: "\e5eb\e5eb"; }

.fa-house-medical-circle-exclamation {
  --fa: "\e512";
  --fa--fa: "\e512\e512"; }

.fa-badminton {
  --fa: "\e33a";
  --fa--fa: "\e33a\e33a"; }

.fa-closed-captioning {
  --fa: "\f20a";
  --fa--fa: "\f20a\f20a"; }

.fa-person-hiking {
  --fa: "\f6ec";
  --fa--fa: "\f6ec\f6ec"; }

.fa-hiking {
  --fa: "\f6ec";
  --fa--fa: "\f6ec\f6ec"; }

.fa-right-from-line {
  --fa: "\f347";
  --fa--fa: "\f347\f347"; }

.fa-arrow-alt-from-left {
  --fa: "\f347";
  --fa--fa: "\f347\f347"; }

.fa-venus-double {
  --fa: "\f226";
  --fa--fa: "\f226\f226"; }

.fa-images {
  --fa: "\f302";
  --fa--fa: "\f302\f302"; }

.fa-calculator {
  --fa: "\f1ec";
  --fa--fa: "\f1ec\f1ec"; }

.fa-shuttlecock {
  --fa: "\f45b";
  --fa--fa: "\f45b\f45b"; }

.fa-user-hair {
  --fa: "\e45a";
  --fa--fa: "\e45a\e45a"; }

.fa-eye-evil {
  --fa: "\f6db";
  --fa--fa: "\f6db\f6db"; }

.fa-people-pulling {
  --fa: "\e535";
  --fa--fa: "\e535\e535"; }

.fa-n {
  --fa: "\4e";
  --fa--fa: "\4e\4e"; }

.fa-swap {
  --fa: "\e609";
  --fa--fa: "\e609\e609"; }

.fa-garage {
  --fa: "\e009";
  --fa--fa: "\e009\e009"; }

.fa-cable-car {
  --fa: "\f7da";
  --fa--fa: "\f7da\f7da"; }

.fa-tram {
  --fa: "\f7da";
  --fa--fa: "\f7da\f7da"; }

.fa-shovel-snow {
  --fa: "\f7c3";
  --fa--fa: "\f7c3\f7c3"; }

.fa-cloud-rain {
  --fa: "\f73d";
  --fa--fa: "\f73d\f73d"; }

.fa-face-lying {
  --fa: "\e37e";
  --fa--fa: "\e37e\e37e"; }

.fa-sprinkler {
  --fa: "\e035";
  --fa--fa: "\e035\e035"; }

.fa-building-circle-xmark {
  --fa: "\e4d4";
  --fa--fa: "\e4d4\e4d4"; }

.fa-person-sledding {
  --fa: "\f7cb";
  --fa--fa: "\f7cb\f7cb"; }

.fa-sledding {
  --fa: "\f7cb";
  --fa--fa: "\f7cb\f7cb"; }

.fa-game-console-handheld {
  --fa: "\f8bb";
  --fa--fa: "\f8bb\f8bb"; }

.fa-ship {
  --fa: "\f21a";
  --fa--fa: "\f21a\f21a"; }

.fa-clock-six-thirty {
  --fa: "\e353";
  --fa--fa: "\e353\e353"; }

.fa-battery-slash {
  --fa: "\f377";
  --fa--fa: "\f377\f377"; }

.fa-tugrik-sign {
  --fa: "\e2ba";
  --fa--fa: "\e2ba\e2ba"; }

.fa-arrows-down-to-line {
  --fa: "\e4b8";
  --fa--fa: "\e4b8\e4b8"; }

.fa-download {
  --fa: "\f019";
  --fa--fa: "\f019\f019"; }

.fa-angles-up-down {
  --fa: "\e60d";
  --fa--fa: "\e60d\e60d"; }

.fa-shelves {
  --fa: "\f480";
  --fa--fa: "\f480\f480"; }

.fa-inventory {
  --fa: "\f480";
  --fa--fa: "\f480\f480"; }

.fa-cloud-snow {
  --fa: "\f742";
  --fa--fa: "\f742\f742"; }

.fa-face-grin {
  --fa: "\f580";
  --fa--fa: "\f580\f580"; }

.fa-grin {
  --fa: "\f580";
  --fa--fa: "\f580\f580"; }

.fa-delete-left {
  --fa: "\f55a";
  --fa--fa: "\f55a\f55a"; }

.fa-backspace {
  --fa: "\f55a";
  --fa--fa: "\f55a\f55a"; }

.fa-oven {
  --fa: "\e01d";
  --fa--fa: "\e01d\e01d"; }

.fa-cloud-binary {
  --fa: "\e601";
  --fa--fa: "\e601\e601"; }

.fa-eye-dropper {
  --fa: "\f1fb";
  --fa--fa: "\f1fb\f1fb"; }

.fa-eye-dropper-empty {
  --fa: "\f1fb";
  --fa--fa: "\f1fb\f1fb"; }

.fa-eyedropper {
  --fa: "\f1fb";
  --fa--fa: "\f1fb\f1fb"; }

.fa-comment-captions {
  --fa: "\e146";
  --fa--fa: "\e146\e146"; }

.fa-comments-question {
  --fa: "\e14e";
  --fa--fa: "\e14e\e14e"; }

.fa-scribble {
  --fa: "\e23f";
  --fa--fa: "\e23f\e23f"; }

.fa-rotate-exclamation {
  --fa: "\e23c";
  --fa--fa: "\e23c\e23c"; }

.fa-file-circle-check {
  --fa: "\e5a0";
  --fa--fa: "\e5a0\e5a0"; }

.fa-glass {
  --fa: "\f804";
  --fa--fa: "\f804\f804"; }

.fa-loader {
  --fa: "\e1d4";
  --fa--fa: "\e1d4\e1d4"; }

.fa-forward {
  --fa: "\f04e";
  --fa--fa: "\f04e\f04e"; }

.fa-user-pilot {
  --fa: "\e2c0";
  --fa--fa: "\e2c0\e2c0"; }

.fa-mobile {
  --fa: "\f3ce";
  --fa--fa: "\f3ce\f3ce"; }

.fa-mobile-android {
  --fa: "\f3ce";
  --fa--fa: "\f3ce\f3ce"; }

.fa-mobile-phone {
  --fa: "\f3ce";
  --fa--fa: "\f3ce\f3ce"; }

.fa-code-pull-request-closed {
  --fa: "\e3f9";
  --fa--fa: "\e3f9\e3f9"; }

.fa-face-meh {
  --fa: "\f11a";
  --fa--fa: "\f11a\f11a"; }

.fa-meh {
  --fa: "\f11a";
  --fa--fa: "\f11a\f11a"; }

.fa-align-center {
  --fa: "\f037";
  --fa--fa: "\f037\f037"; }

.fa-book-skull {
  --fa: "\f6b7";
  --fa--fa: "\f6b7\f6b7"; }

.fa-book-dead {
  --fa: "\f6b7";
  --fa--fa: "\f6b7\f6b7"; }

.fa-id-card {
  --fa: "\f2c2";
  --fa--fa: "\f2c2\f2c2"; }

.fa-drivers-license {
  --fa: "\f2c2";
  --fa--fa: "\f2c2\f2c2"; }

.fa-face-dotted {
  --fa: "\e47f";
  --fa--fa: "\e47f\e47f"; }

.fa-face-worried {
  --fa: "\e3a3";
  --fa--fa: "\e3a3\e3a3"; }

.fa-outdent {
  --fa: "\f03b";
  --fa--fa: "\f03b\f03b"; }

.fa-dedent {
  --fa: "\f03b";
  --fa--fa: "\f03b\f03b"; }

.fa-court-sport {
  --fa: "\e643";
  --fa--fa: "\e643\e643"; }

.fa-heart-circle-exclamation {
  --fa: "\e4fe";
  --fa--fa: "\e4fe\e4fe"; }

.fa-house {
  --fa: "\f015";
  --fa--fa: "\f015\f015"; }

.fa-home {
  --fa: "\f015";
  --fa--fa: "\f015\f015"; }

.fa-home-alt {
  --fa: "\f015";
  --fa--fa: "\f015\f015"; }

.fa-home-lg-alt {
  --fa: "\f015";
  --fa--fa: "\f015\f015"; }

.fa-vector-circle {
  --fa: "\e2c6";
  --fa--fa: "\e2c6\e2c6"; }

.fa-car-circle-bolt {
  --fa: "\e342";
  --fa--fa: "\e342\e342"; }

.fa-calendar-week {
  --fa: "\f784";
  --fa--fa: "\f784\f784"; }

.fa-flying-disc {
  --fa: "\e3a9";
  --fa--fa: "\e3a9\e3a9"; }

.fa-laptop-medical {
  --fa: "\f812";
  --fa--fa: "\f812\f812"; }

.fa-square-down-right {
  --fa: "\e26c";
  --fa--fa: "\e26c\e26c"; }

.fa-b {
  --fa: "\42";
  --fa--fa: "\42\42"; }

.fa-seat-airline {
  --fa: "\e244";
  --fa--fa: "\e244\e244"; }

.fa-moon-over-sun {
  --fa: "\f74a";
  --fa--fa: "\f74a\f74a"; }

.fa-eclipse-alt {
  --fa: "\f74a";
  --fa--fa: "\f74a\f74a"; }

.fa-pipe {
  --fa: "\7c";
  --fa--fa: "\7c\7c"; }

.fa-file-medical {
  --fa: "\f477";
  --fa--fa: "\f477\f477"; }

.fa-potato {
  --fa: "\e440";
  --fa--fa: "\e440\e440"; }

.fa-dice-one {
  --fa: "\f525";
  --fa--fa: "\f525\f525"; }

.fa-circle-a {
  --fa: "\e0f7";
  --fa--fa: "\e0f7\e0f7"; }

.fa-helmet-battle {
  --fa: "\f6eb";
  --fa--fa: "\f6eb\f6eb"; }

.fa-butter {
  --fa: "\e3e4";
  --fa--fa: "\e3e4\e3e4"; }

.fa-blanket-fire {
  --fa: "\e3da";
  --fa--fa: "\e3da\e3da"; }

.fa-kiwi-bird {
  --fa: "\f535";
  --fa--fa: "\f535\f535"; }

.fa-castle {
  --fa: "\e0de";
  --fa--fa: "\e0de\e0de"; }

.fa-golf-club {
  --fa: "\f451";
  --fa--fa: "\f451\f451"; }

.fa-arrow-right-arrow-left {
  --fa: "\f0ec";
  --fa--fa: "\f0ec\f0ec"; }

.fa-exchange {
  --fa: "\f0ec";
  --fa--fa: "\f0ec\f0ec"; }

.fa-rotate-right {
  --fa: "\f2f9";
  --fa--fa: "\f2f9\f2f9"; }

.fa-redo-alt {
  --fa: "\f2f9";
  --fa--fa: "\f2f9\f2f9"; }

.fa-rotate-forward {
  --fa: "\f2f9";
  --fa--fa: "\f2f9\f2f9"; }

.fa-utensils {
  --fa: "\f2e7";
  --fa--fa: "\f2e7\f2e7"; }

.fa-cutlery {
  --fa: "\f2e7";
  --fa--fa: "\f2e7\f2e7"; }

.fa-arrow-up-wide-short {
  --fa: "\f161";
  --fa--fa: "\f161\f161"; }

.fa-sort-amount-up {
  --fa: "\f161";
  --fa--fa: "\f161\f161"; }

.fa-chart-pie-simple-circle-dollar {
  --fa: "\e605";
  --fa--fa: "\e605\e605"; }

.fa-balloons {
  --fa: "\e2e4";
  --fa--fa: "\e2e4\e2e4"; }

.fa-mill-sign {
  --fa: "\e1ed";
  --fa--fa: "\e1ed\e1ed"; }

.fa-bowl-rice {
  --fa: "\e2eb";
  --fa--fa: "\e2eb\e2eb"; }

.fa-timeline-arrow {
  --fa: "\e29d";
  --fa--fa: "\e29d\e29d"; }

.fa-skull {
  --fa: "\f54c";
  --fa--fa: "\f54c\f54c"; }

.fa-game-board-simple {
  --fa: "\f868";
  --fa--fa: "\f868\f868"; }

.fa-game-board-alt {
  --fa: "\f868";
  --fa--fa: "\f868\f868"; }

.fa-circle-video {
  --fa: "\e12b";
  --fa--fa: "\e12b\e12b"; }

.fa-video-circle {
  --fa: "\e12b";
  --fa--fa: "\e12b\e12b"; }

.fa-chart-scatter-bubble {
  --fa: "\e0e9";
  --fa--fa: "\e0e9\e0e9"; }

.fa-house-turret {
  --fa: "\e1b4";
  --fa--fa: "\e1b4\e1b4"; }

.fa-banana {
  --fa: "\e2e5";
  --fa--fa: "\e2e5\e2e5"; }

.fa-hand-holding-skull {
  --fa: "\e1a4";
  --fa--fa: "\e1a4\e1a4"; }

.fa-people-dress {
  --fa: "\e217";
  --fa--fa: "\e217\e217"; }

.fa-loveseat {
  --fa: "\f4cc";
  --fa--fa: "\f4cc\f4cc"; }

.fa-couch-small {
  --fa: "\f4cc";
  --fa--fa: "\f4cc\f4cc"; }

.fa-tower-broadcast {
  --fa: "\f519";
  --fa--fa: "\f519\f519"; }

.fa-broadcast-tower {
  --fa: "\f519";
  --fa--fa: "\f519\f519"; }

.fa-truck-pickup {
  --fa: "\f63c";
  --fa--fa: "\f63c\f63c"; }

.fa-block-quote {
  --fa: "\e0b5";
  --fa--fa: "\e0b5\e0b5"; }

.fa-up-long {
  --fa: "\f30c";
  --fa--fa: "\f30c\f30c"; }

.fa-long-arrow-alt-up {
  --fa: "\f30c";
  --fa--fa: "\f30c\f30c"; }

.fa-stop {
  --fa: "\f04d";
  --fa--fa: "\f04d\f04d"; }

.fa-code-merge {
  --fa: "\f387";
  --fa--fa: "\f387\f387"; }

.fa-money-check-dollar-pen {
  --fa: "\f873";
  --fa--fa: "\f873\f873"; }

.fa-money-check-edit-alt {
  --fa: "\f873";
  --fa--fa: "\f873\f873"; }

.fa-up-from-line {
  --fa: "\f346";
  --fa--fa: "\f346\f346"; }

.fa-arrow-alt-from-bottom {
  --fa: "\f346";
  --fa--fa: "\f346\f346"; }

.fa-upload {
  --fa: "\f093";
  --fa--fa: "\f093\f093"; }

.fa-hurricane {
  --fa: "\f751";
  --fa--fa: "\f751\f751"; }

.fa-grid-round-2-plus {
  --fa: "\e5dc";
  --fa--fa: "\e5dc\e5dc"; }

.fa-people-pants {
  --fa: "\e219";
  --fa--fa: "\e219\e219"; }

.fa-mound {
  --fa: "\e52d";
  --fa--fa: "\e52d\e52d"; }

.fa-windsock {
  --fa: "\f777";
  --fa--fa: "\f777\f777"; }

.fa-circle-half {
  --fa: "\e110";
  --fa--fa: "\e110\e110"; }

.fa-brake-warning {
  --fa: "\e0c7";
  --fa--fa: "\e0c7\e0c7"; }

.fa-toilet-portable {
  --fa: "\e583";
  --fa--fa: "\e583\e583"; }

.fa-compact-disc {
  --fa: "\f51f";
  --fa--fa: "\f51f\f51f"; }

.fa-file-arrow-down {
  --fa: "\f56d";
  --fa--fa: "\f56d\f56d"; }

.fa-file-download {
  --fa: "\f56d";
  --fa--fa: "\f56d\f56d"; }

.fa-saxophone-fire {
  --fa: "\f8db";
  --fa--fa: "\f8db\f8db"; }

.fa-sax-hot {
  --fa: "\f8db";
  --fa--fa: "\f8db\f8db"; }

.fa-camera-web-slash {
  --fa: "\f833";
  --fa--fa: "\f833\f833"; }

.fa-webcam-slash {
  --fa: "\f833";
  --fa--fa: "\f833\f833"; }

.fa-folder-medical {
  --fa: "\e18c";
  --fa--fa: "\e18c\e18c"; }

.fa-folder-gear {
  --fa: "\e187";
  --fa--fa: "\e187\e187"; }

.fa-folder-cog {
  --fa: "\e187";
  --fa--fa: "\e187\e187"; }

.fa-hand-wave {
  --fa: "\e1a7";
  --fa--fa: "\e1a7\e1a7"; }

.fa-arrow-up-arrow-down {
  --fa: "\e099";
  --fa--fa: "\e099\e099"; }

.fa-sort-up-down {
  --fa: "\e099";
  --fa--fa: "\e099\e099"; }

.fa-caravan {
  --fa: "\f8ff";
  --fa--fa: "\f8ff\f8ff"; }

.fa-shield-cat {
  --fa: "\e572";
  --fa--fa: "\e572\e572"; }

.fa-message-slash {
  --fa: "\f4a9";
  --fa--fa: "\f4a9\f4a9"; }

.fa-comment-alt-slash {
  --fa: "\f4a9";
  --fa--fa: "\f4a9\f4a9"; }

.fa-bolt {
  --fa: "\f0e7";
  --fa--fa: "\f0e7\f0e7"; }

.fa-zap {
  --fa: "\f0e7";
  --fa--fa: "\f0e7\f0e7"; }

.fa-trash-can-check {
  --fa: "\e2a9";
  --fa--fa: "\e2a9\e2a9"; }

.fa-glass-water {
  --fa: "\e4f4";
  --fa--fa: "\e4f4\e4f4"; }

.fa-oil-well {
  --fa: "\e532";
  --fa--fa: "\e532\e532"; }

.fa-table-cells-column-unlock {
  --fa: "\e690";
  --fa--fa: "\e690\e690"; }

.fa-person-simple {
  --fa: "\e220";
  --fa--fa: "\e220\e220"; }

.fa-arrow-turn-left-up {
  --fa: "\e634";
  --fa--fa: "\e634\e634"; }

.fa-vault {
  --fa: "\e2c5";
  --fa--fa: "\e2c5\e2c5"; }

.fa-mars {
  --fa: "\f222";
  --fa--fa: "\f222\f222"; }

.fa-toilet {
  --fa: "\f7d8";
  --fa--fa: "\f7d8\f7d8"; }

.fa-plane-circle-xmark {
  --fa: "\e557";
  --fa--fa: "\e557\e557"; }

.fa-yen-sign {
  --fa: "\f157";
  --fa--fa: "\f157\f157"; }

.fa-cny {
  --fa: "\f157";
  --fa--fa: "\f157\f157"; }

.fa-jpy {
  --fa: "\f157";
  --fa--fa: "\f157\f157"; }

.fa-rmb {
  --fa: "\f157";
  --fa--fa: "\f157\f157"; }

.fa-yen {
  --fa: "\f157";
  --fa--fa: "\f157\f157"; }

.fa-gear-code {
  --fa: "\e5e8";
  --fa--fa: "\e5e8\e5e8"; }

.fa-notes {
  --fa: "\e202";
  --fa--fa: "\e202\e202"; }

.fa-ruble-sign {
  --fa: "\f158";
  --fa--fa: "\f158\f158"; }

.fa-rouble {
  --fa: "\f158";
  --fa--fa: "\f158\f158"; }

.fa-rub {
  --fa: "\f158";
  --fa--fa: "\f158\f158"; }

.fa-ruble {
  --fa: "\f158";
  --fa--fa: "\f158\f158"; }

.fa-trash-undo {
  --fa: "\f895";
  --fa--fa: "\f895\f895"; }

.fa-trash-arrow-turn-left {
  --fa: "\f895";
  --fa--fa: "\f895\f895"; }

.fa-champagne-glass {
  --fa: "\f79e";
  --fa--fa: "\f79e\f79e"; }

.fa-glass-champagne {
  --fa: "\f79e";
  --fa--fa: "\f79e\f79e"; }

.fa-objects-align-center-horizontal {
  --fa: "\e3bc";
  --fa--fa: "\e3bc\e3bc"; }

.fa-sun {
  --fa: "\f185";
  --fa--fa: "\f185\f185"; }

.fa-trash-can-slash {
  --fa: "\e2ad";
  --fa--fa: "\e2ad\e2ad"; }

.fa-trash-alt-slash {
  --fa: "\e2ad";
  --fa--fa: "\e2ad\e2ad"; }

.fa-screen-users {
  --fa: "\f63d";
  --fa--fa: "\f63d\f63d"; }

.fa-users-class {
  --fa: "\f63d";
  --fa--fa: "\f63d\f63d"; }

.fa-guitar {
  --fa: "\f7a6";
  --fa--fa: "\f7a6\f7a6"; }

.fa-square-arrow-left {
  --fa: "\f33a";
  --fa--fa: "\f33a\f33a"; }

.fa-arrow-square-left {
  --fa: "\f33a";
  --fa--fa: "\f33a\f33a"; }

.fa-square-8 {
  --fa: "\e25d";
  --fa--fa: "\e25d\e25d"; }

.fa-face-smile-hearts {
  --fa: "\e390";
  --fa--fa: "\e390\e390"; }

.fa-brackets-square {
  --fa: "\f7e9";
  --fa--fa: "\f7e9\f7e9"; }

.fa-brackets {
  --fa: "\f7e9";
  --fa--fa: "\f7e9\f7e9"; }

.fa-laptop-arrow-down {
  --fa: "\e1c6";
  --fa--fa: "\e1c6\e1c6"; }

.fa-hockey-stick-puck {
  --fa: "\e3ae";
  --fa--fa: "\e3ae\e3ae"; }

.fa-house-tree {
  --fa: "\e1b3";
  --fa--fa: "\e1b3\e1b3"; }

.fa-signal-fair {
  --fa: "\f68d";
  --fa--fa: "\f68d\f68d"; }

.fa-signal-2 {
  --fa: "\f68d";
  --fa--fa: "\f68d\f68d"; }

.fa-face-laugh-wink {
  --fa: "\f59c";
  --fa--fa: "\f59c\f59c"; }

.fa-laugh-wink {
  --fa: "\f59c";
  --fa--fa: "\f59c\f59c"; }

.fa-circle-dollar {
  --fa: "\f2e8";
  --fa--fa: "\f2e8\f2e8"; }

.fa-dollar-circle {
  --fa: "\f2e8";
  --fa--fa: "\f2e8\f2e8"; }

.fa-usd-circle {
  --fa: "\f2e8";
  --fa--fa: "\f2e8\f2e8"; }

.fa-horse-head {
  --fa: "\f7ab";
  --fa--fa: "\f7ab\f7ab"; }

.fa-arrows-repeat {
  --fa: "\f364";
  --fa--fa: "\f364\f364"; }

.fa-repeat-alt {
  --fa: "\f364";
  --fa--fa: "\f364\f364"; }

.fa-bore-hole {
  --fa: "\e4c3";
  --fa--fa: "\e4c3\e4c3"; }

.fa-industry {
  --fa: "\f275";
  --fa--fa: "\f275\f275"; }

.fa-image-polaroid {
  --fa: "\f8c4";
  --fa--fa: "\f8c4\f8c4"; }

.fa-wave-triangle {
  --fa: "\f89a";
  --fa--fa: "\f89a\f89a"; }

.fa-turn-left-down {
  --fa: "\e637";
  --fa--fa: "\e637\e637"; }

.fa-person-running-fast {
  --fa: "\e5ff";
  --fa--fa: "\e5ff\e5ff"; }

.fa-circle-down {
  --fa: "\f358";
  --fa--fa: "\f358\f358"; }

.fa-arrow-alt-circle-down {
  --fa: "\f358";
  --fa--fa: "\f358\f358"; }

.fa-grill {
  --fa: "\e5a3";
  --fa--fa: "\e5a3\e5a3"; }

.fa-arrows-turn-to-dots {
  --fa: "\e4c1";
  --fa--fa: "\e4c1\e4c1"; }

.fa-chart-mixed {
  --fa: "\f643";
  --fa--fa: "\f643\f643"; }

.fa-analytics {
  --fa: "\f643";
  --fa--fa: "\f643\f643"; }

.fa-florin-sign {
  --fa: "\e184";
  --fa--fa: "\e184\e184"; }

.fa-arrow-down-short-wide {
  --fa: "\f884";
  --fa--fa: "\f884\f884"; }

.fa-sort-amount-desc {
  --fa: "\f884";
  --fa--fa: "\f884\f884"; }

.fa-sort-amount-down-alt {
  --fa: "\f884";
  --fa--fa: "\f884\f884"; }

.fa-less-than {
  --fa: "\3c";
  --fa--fa: "\3c\3c"; }

.fa-display-code {
  --fa: "\e165";
  --fa--fa: "\e165\e165"; }

.fa-desktop-code {
  --fa: "\e165";
  --fa--fa: "\e165\e165"; }

.fa-face-drooling {
  --fa: "\e372";
  --fa--fa: "\e372\e372"; }

.fa-oil-temperature {
  --fa: "\f614";
  --fa--fa: "\f614\f614"; }

.fa-oil-temp {
  --fa: "\f614";
  --fa--fa: "\f614\f614"; }

.fa-square-question {
  --fa: "\f2fd";
  --fa--fa: "\f2fd\f2fd"; }

.fa-question-square {
  --fa: "\f2fd";
  --fa--fa: "\f2fd\f2fd"; }

.fa-air-conditioner {
  --fa: "\f8f4";
  --fa--fa: "\f8f4\f8f4"; }

.fa-angle-down {
  --fa: "\f107";
  --fa--fa: "\f107\f107"; }

.fa-mountains {
  --fa: "\f6fd";
  --fa--fa: "\f6fd\f6fd"; }

.fa-omega {
  --fa: "\f67a";
  --fa--fa: "\f67a\f67a"; }

.fa-car-tunnel {
  --fa: "\e4de";
  --fa--fa: "\e4de\e4de"; }

.fa-person-dolly-empty {
  --fa: "\f4d1";
  --fa--fa: "\f4d1\f4d1"; }

.fa-pan-food {
  --fa: "\e42b";
  --fa--fa: "\e42b\e42b"; }

.fa-head-side-cough {
  --fa: "\e061";
  --fa--fa: "\e061\e061"; }

.fa-grip-lines {
  --fa: "\f7a4";
  --fa--fa: "\f7a4\f7a4"; }

.fa-thumbs-down {
  --fa: "\f165";
  --fa--fa: "\f165\f165"; }

.fa-user-lock {
  --fa: "\f502";
  --fa--fa: "\f502\f502"; }

.fa-arrow-right-long {
  --fa: "\f178";
  --fa--fa: "\f178\f178"; }

.fa-long-arrow-right {
  --fa: "\f178";
  --fa--fa: "\f178\f178"; }

.fa-tickets-airline {
  --fa: "\e29b";
  --fa--fa: "\e29b\e29b"; }

.fa-tickets-perforated-plane {
  --fa: "\e29b";
  --fa--fa: "\e29b\e29b"; }

.fa-tickets-plane {
  --fa: "\e29b";
  --fa--fa: "\e29b\e29b"; }

.fa-tent-double-peak {
  --fa: "\e627";
  --fa--fa: "\e627\e627"; }

.fa-anchor-circle-xmark {
  --fa: "\e4ac";
  --fa--fa: "\e4ac\e4ac"; }

.fa-ellipsis {
  --fa: "\f141";
  --fa--fa: "\f141\f141"; }

.fa-ellipsis-h {
  --fa: "\f141";
  --fa--fa: "\f141\f141"; }

.fa-nfc-slash {
  --fa: "\e1fc";
  --fa--fa: "\e1fc\e1fc"; }

.fa-chess-pawn {
  --fa: "\f443";
  --fa--fa: "\f443\f443"; }

.fa-kit-medical {
  --fa: "\f479";
  --fa--fa: "\f479\f479"; }

.fa-first-aid {
  --fa: "\f479";
  --fa--fa: "\f479\f479"; }

.fa-grid-2-plus {
  --fa: "\e197";
  --fa--fa: "\e197\e197"; }

.fa-bells {
  --fa: "\f77f";
  --fa--fa: "\f77f\f77f"; }

.fa-person-through-window {
  --fa: "\e5a9";
  --fa--fa: "\e5a9\e5a9"; }

.fa-toolbox {
  --fa: "\f552";
  --fa--fa: "\f552\f552"; }

.fa-globe-wifi {
  --fa: "\e685";
  --fa--fa: "\e685\e685"; }

.fa-envelope-dot {
  --fa: "\e16f";
  --fa--fa: "\e16f\e16f"; }

.fa-envelope-badge {
  --fa: "\e16f";
  --fa--fa: "\e16f\e16f"; }

.fa-magnifying-glass-waveform {
  --fa: "\e661";
  --fa--fa: "\e661\e661"; }

.fa-hands-holding-circle {
  --fa: "\e4fb";
  --fa--fa: "\e4fb\e4fb"; }

.fa-bug {
  --fa: "\f188";
  --fa--fa: "\f188\f188"; }

.fa-bowl-chopsticks {
  --fa: "\e2e9";
  --fa--fa: "\e2e9\e2e9"; }

.fa-credit-card {
  --fa: "\f09d";
  --fa--fa: "\f09d\f09d"; }

.fa-credit-card-alt {
  --fa: "\f09d";
  --fa--fa: "\f09d\f09d"; }

.fa-circle-s {
  --fa: "\e121";
  --fa--fa: "\e121\e121"; }

.fa-box-ballot {
  --fa: "\f735";
  --fa--fa: "\f735\f735"; }

.fa-car {
  --fa: "\f1b9";
  --fa--fa: "\f1b9\f1b9"; }

.fa-automobile {
  --fa: "\f1b9";
  --fa--fa: "\f1b9\f1b9"; }

.fa-hand-holding-hand {
  --fa: "\e4f7";
  --fa--fa: "\e4f7\e4f7"; }

.fa-user-tie-hair {
  --fa: "\e45f";
  --fa--fa: "\e45f\e45f"; }

.fa-podium-star {
  --fa: "\f758";
  --fa--fa: "\f758\f758"; }

.fa-user-hair-mullet {
  --fa: "\e45c";
  --fa--fa: "\e45c\e45c"; }

.fa-business-front {
  --fa: "\e45c";
  --fa--fa: "\e45c\e45c"; }

.fa-party-back {
  --fa: "\e45c";
  --fa--fa: "\e45c\e45c"; }

.fa-trian-balbot {
  --fa: "\e45c";
  --fa--fa: "\e45c\e45c"; }

.fa-microphone-stand {
  --fa: "\f8cb";
  --fa--fa: "\f8cb\f8cb"; }

.fa-book-open-reader {
  --fa: "\f5da";
  --fa--fa: "\f5da\f5da"; }

.fa-book-reader {
  --fa: "\f5da";
  --fa--fa: "\f5da\f5da"; }

.fa-family-dress {
  --fa: "\e301";
  --fa--fa: "\e301\e301"; }

.fa-circle-x {
  --fa: "\e12e";
  --fa--fa: "\e12e\e12e"; }

.fa-cabin {
  --fa: "\e46d";
  --fa--fa: "\e46d\e46d"; }

.fa-mountain-sun {
  --fa: "\e52f";
  --fa--fa: "\e52f\e52f"; }

.fa-chart-simple-horizontal {
  --fa: "\e474";
  --fa--fa: "\e474\e474"; }

.fa-arrows-left-right-to-line {
  --fa: "\e4ba";
  --fa--fa: "\e4ba\e4ba"; }

.fa-hand-back-point-left {
  --fa: "\e19f";
  --fa--fa: "\e19f\e19f"; }

.fa-message-dots {
  --fa: "\f4a3";
  --fa--fa: "\f4a3\f4a3"; }

.fa-comment-alt-dots {
  --fa: "\f4a3";
  --fa--fa: "\f4a3\f4a3"; }

.fa-messaging {
  --fa: "\f4a3";
  --fa--fa: "\f4a3\f4a3"; }

.fa-file-heart {
  --fa: "\e176";
  --fa--fa: "\e176\e176"; }

.fa-beer-mug {
  --fa: "\e0b3";
  --fa--fa: "\e0b3\e0b3"; }

.fa-beer-foam {
  --fa: "\e0b3";
  --fa--fa: "\e0b3\e0b3"; }

.fa-dice-d20 {
  --fa: "\f6cf";
  --fa--fa: "\f6cf\f6cf"; }

.fa-drone {
  --fa: "\f85f";
  --fa--fa: "\f85f\f85f"; }

.fa-truck-droplet {
  --fa: "\e58c";
  --fa--fa: "\e58c\e58c"; }

.fa-file-circle-xmark {
  --fa: "\e5a1";
  --fa--fa: "\e5a1\e5a1"; }

.fa-temperature-arrow-up {
  --fa: "\e040";
  --fa--fa: "\e040\e040"; }

.fa-temperature-up {
  --fa: "\e040";
  --fa--fa: "\e040\e040"; }

.fa-medal {
  --fa: "\f5a2";
  --fa--fa: "\f5a2\f5a2"; }

.fa-person-fairy {
  --fa: "\e608";
  --fa--fa: "\e608\e608"; }

.fa-bed {
  --fa: "\f236";
  --fa--fa: "\f236\f236"; }

.fa-book-copy {
  --fa: "\e0be";
  --fa--fa: "\e0be\e0be"; }

.fa-square-h {
  --fa: "\f0fd";
  --fa--fa: "\f0fd\f0fd"; }

.fa-h-square {
  --fa: "\f0fd";
  --fa--fa: "\f0fd\f0fd"; }

.fa-square-c {
  --fa: "\e266";
  --fa--fa: "\e266\e266"; }

.fa-clock-two {
  --fa: "\e35a";
  --fa--fa: "\e35a\e35a"; }

.fa-square-ellipsis-vertical {
  --fa: "\e26f";
  --fa--fa: "\e26f\e26f"; }

.fa-calendar-users {
  --fa: "\e5e2";
  --fa--fa: "\e5e2\e5e2"; }

.fa-podcast {
  --fa: "\f2ce";
  --fa--fa: "\f2ce\f2ce"; }

.fa-bee {
  --fa: "\e0b2";
  --fa--fa: "\e0b2\e0b2"; }

.fa-temperature-full {
  --fa: "\f2c7";
  --fa--fa: "\f2c7\f2c7"; }

.fa-temperature-4 {
  --fa: "\f2c7";
  --fa--fa: "\f2c7\f2c7"; }

.fa-thermometer-4 {
  --fa: "\f2c7";
  --fa--fa: "\f2c7\f2c7"; }

.fa-thermometer-full {
  --fa: "\f2c7";
  --fa--fa: "\f2c7\f2c7"; }

.fa-bell {
  --fa: "\f0f3";
  --fa--fa: "\f0f3\f0f3"; }

.fa-candy-bar {
  --fa: "\e3e8";
  --fa--fa: "\e3e8\e3e8"; }

.fa-chocolate-bar {
  --fa: "\e3e8";
  --fa--fa: "\e3e8\e3e8"; }

.fa-xmark-large {
  --fa: "\e59b";
  --fa--fa: "\e59b\e59b"; }

.fa-pinata {
  --fa: "\e3c3";
  --fa--fa: "\e3c3\e3c3"; }

.fa-file-ppt {
  --fa: "\e64a";
  --fa--fa: "\e64a\e64a"; }

.fa-arrows-from-line {
  --fa: "\e0a4";
  --fa--fa: "\e0a4\e0a4"; }

.fa-superscript {
  --fa: "\f12b";
  --fa--fa: "\f12b\f12b"; }

.fa-bowl-spoon {
  --fa: "\e3e0";
  --fa--fa: "\e3e0\e3e0"; }

.fa-hexagon-check {
  --fa: "\e416";
  --fa--fa: "\e416\e416"; }

.fa-plug-circle-xmark {
  --fa: "\e560";
  --fa--fa: "\e560\e560"; }

.fa-star-of-life {
  --fa: "\f621";
  --fa--fa: "\f621\f621"; }

.fa-phone-slash {
  --fa: "\f3dd";
  --fa--fa: "\f3dd\f3dd"; }

.fa-traffic-light-stop {
  --fa: "\f63a";
  --fa--fa: "\f63a\f63a"; }

.fa-paint-roller {
  --fa: "\f5aa";
  --fa--fa: "\f5aa\f5aa"; }

.fa-accent-grave {
  --fa: "\60";
  --fa--fa: "\60\60"; }

.fa-handshake-angle {
  --fa: "\f4c4";
  --fa--fa: "\f4c4\f4c4"; }

.fa-hands-helping {
  --fa: "\f4c4";
  --fa--fa: "\f4c4\f4c4"; }

.fa-circle-0 {
  --fa: "\e0ed";
  --fa--fa: "\e0ed\e0ed"; }

.fa-dial-med-low {
  --fa: "\e160";
  --fa--fa: "\e160\e160"; }

.fa-location-dot {
  --fa: "\f3c5";
  --fa--fa: "\f3c5\f3c5"; }

.fa-map-marker-alt {
  --fa: "\f3c5";
  --fa--fa: "\f3c5\f3c5"; }

.fa-crab {
  --fa: "\e3ff";
  --fa--fa: "\e3ff\e3ff"; }

.fa-box-open-full {
  --fa: "\f49c";
  --fa--fa: "\f49c\f49c"; }

.fa-box-full {
  --fa: "\f49c";
  --fa--fa: "\f49c\f49c"; }

.fa-file {
  --fa: "\f15b";
  --fa--fa: "\f15b\f15b"; }

.fa-greater-than {
  --fa: "\3e";
  --fa--fa: "\3e\3e"; }

.fa-quotes {
  --fa: "\e234";
  --fa--fa: "\e234\e234"; }

.fa-pretzel {
  --fa: "\e441";
  --fa--fa: "\e441\e441"; }

.fa-t-rex {
  --fa: "\e629";
  --fa--fa: "\e629\e629"; }

.fa-person-swimming {
  --fa: "\f5c4";
  --fa--fa: "\f5c4\f5c4"; }

.fa-swimmer {
  --fa: "\f5c4";
  --fa--fa: "\f5c4\f5c4"; }

.fa-arrow-down {
  --fa: "\f063";
  --fa--fa: "\f063\f063"; }

.fa-user-robot-xmarks {
  --fa: "\e4a7";
  --fa--fa: "\e4a7\e4a7"; }

.fa-message-quote {
  --fa: "\e1e4";
  --fa--fa: "\e1e4\e1e4"; }

.fa-comment-alt-quote {
  --fa: "\e1e4";
  --fa--fa: "\e1e4\e1e4"; }

.fa-candy-corn {
  --fa: "\f6bd";
  --fa--fa: "\f6bd\f6bd"; }

.fa-folder-magnifying-glass {
  --fa: "\e18b";
  --fa--fa: "\e18b\e18b"; }

.fa-folder-search {
  --fa: "\e18b";
  --fa--fa: "\e18b\e18b"; }

.fa-notebook {
  --fa: "\e201";
  --fa--fa: "\e201\e201"; }

.fa-circle-wifi {
  --fa: "\e67d";
  --fa--fa: "\e67d\e67d"; }

.fa-droplet {
  --fa: "\f043";
  --fa--fa: "\f043\f043"; }

.fa-tint {
  --fa: "\f043";
  --fa--fa: "\f043\f043"; }

.fa-bullseye-pointer {
  --fa: "\f649";
  --fa--fa: "\f649\f649"; }

.fa-eraser {
  --fa: "\f12d";
  --fa--fa: "\f12d\f12d"; }

.fa-hexagon-image {
  --fa: "\e504";
  --fa--fa: "\e504\e504"; }

.fa-earth-americas {
  --fa: "\f57d";
  --fa--fa: "\f57d\f57d"; }

.fa-earth {
  --fa: "\f57d";
  --fa--fa: "\f57d\f57d"; }

.fa-earth-america {
  --fa: "\f57d";
  --fa--fa: "\f57d\f57d"; }

.fa-globe-americas {
  --fa: "\f57d";
  --fa--fa: "\f57d\f57d"; }

.fa-file-svg {
  --fa: "\e64b";
  --fa--fa: "\e64b\e64b"; }

.fa-crate-apple {
  --fa: "\f6b1";
  --fa--fa: "\f6b1\f6b1"; }

.fa-apple-crate {
  --fa: "\f6b1";
  --fa--fa: "\f6b1\f6b1"; }

.fa-person-burst {
  --fa: "\e53b";
  --fa--fa: "\e53b\e53b"; }

.fa-game-board {
  --fa: "\f867";
  --fa--fa: "\f867\f867"; }

.fa-hat-chef {
  --fa: "\f86b";
  --fa--fa: "\f86b\f86b"; }

.fa-hand-back-point-right {
  --fa: "\e1a1";
  --fa--fa: "\e1a1\e1a1"; }

.fa-dove {
  --fa: "\f4ba";
  --fa--fa: "\f4ba\f4ba"; }

.fa-snowflake-droplets {
  --fa: "\e5c1";
  --fa--fa: "\e5c1\e5c1"; }

.fa-battery-empty {
  --fa: "\f244";
  --fa--fa: "\f244\f244"; }

.fa-battery-0 {
  --fa: "\f244";
  --fa--fa: "\f244\f244"; }

.fa-grid-4 {
  --fa: "\e198";
  --fa--fa: "\e198\e198"; }

.fa-socks {
  --fa: "\f696";
  --fa--fa: "\f696\f696"; }

.fa-face-sunglasses {
  --fa: "\e398";
  --fa--fa: "\e398\e398"; }

.fa-inbox {
  --fa: "\f01c";
  --fa--fa: "\f01c\f01c"; }

.fa-square-0 {
  --fa: "\e255";
  --fa--fa: "\e255\e255"; }

.fa-section {
  --fa: "\e447";
  --fa--fa: "\e447\e447"; }

.fa-square-this-way-up {
  --fa: "\f49f";
  --fa--fa: "\f49f\f49f"; }

.fa-box-up {
  --fa: "\f49f";
  --fa--fa: "\f49f\f49f"; }

.fa-gauge-high {
  --fa: "\f625";
  --fa--fa: "\f625\f625"; }

.fa-tachometer-alt {
  --fa: "\f625";
  --fa--fa: "\f625\f625"; }

.fa-tachometer-alt-fast {
  --fa: "\f625";
  --fa--fa: "\f625\f625"; }

.fa-square-ampersand {
  --fa: "\e260";
  --fa--fa: "\e260\e260"; }

.fa-envelope-open-text {
  --fa: "\f658";
  --fa--fa: "\f658\f658"; }

.fa-lamp-desk {
  --fa: "\e014";
  --fa--fa: "\e014\e014"; }

.fa-hospital {
  --fa: "\f0f8";
  --fa--fa: "\f0f8\f0f8"; }

.fa-hospital-alt {
  --fa: "\f0f8";
  --fa--fa: "\f0f8\f0f8"; }

.fa-hospital-wide {
  --fa: "\f0f8";
  --fa--fa: "\f0f8\f0f8"; }

.fa-poll-people {
  --fa: "\f759";
  --fa--fa: "\f759\f759"; }

.fa-whiskey-glass-ice {
  --fa: "\f7a1";
  --fa--fa: "\f7a1\f7a1"; }

.fa-glass-whiskey-rocks {
  --fa: "\f7a1";
  --fa--fa: "\f7a1\f7a1"; }

.fa-wine-bottle {
  --fa: "\f72f";
  --fa--fa: "\f72f\f72f"; }

.fa-chess-rook {
  --fa: "\f447";
  --fa--fa: "\f447\f447"; }

.fa-user-bounty-hunter {
  --fa: "\e2bf";
  --fa--fa: "\e2bf\e2bf"; }

.fa-bars-staggered {
  --fa: "\f550";
  --fa--fa: "\f550\f550"; }

.fa-reorder {
  --fa: "\f550";
  --fa--fa: "\f550\f550"; }

.fa-stream {
  --fa: "\f550";
  --fa--fa: "\f550\f550"; }

.fa-diagram-sankey {
  --fa: "\e158";
  --fa--fa: "\e158\e158"; }

.fa-cloud-hail-mixed {
  --fa: "\f73a";
  --fa--fa: "\f73a\f73a"; }

.fa-circle-up-left {
  --fa: "\e128";
  --fa--fa: "\e128\e128"; }

.fa-dharmachakra {
  --fa: "\f655";
  --fa--fa: "\f655\f655"; }

.fa-objects-align-left {
  --fa: "\e3be";
  --fa--fa: "\e3be\e3be"; }

.fa-oil-can-drip {
  --fa: "\e205";
  --fa--fa: "\e205\e205"; }

.fa-face-smiling-hands {
  --fa: "\e396";
  --fa--fa: "\e396\e396"; }

.fa-broccoli {
  --fa: "\e3e2";
  --fa--fa: "\e3e2\e3e2"; }

.fa-route-interstate {
  --fa: "\f61b";
  --fa--fa: "\f61b\f61b"; }

.fa-ear-muffs {
  --fa: "\f795";
  --fa--fa: "\f795\f795"; }

.fa-hotdog {
  --fa: "\f80f";
  --fa--fa: "\f80f\f80f"; }

.fa-transporter-empty {
  --fa: "\e046";
  --fa--fa: "\e046\e046"; }

.fa-person-walking-with-cane {
  --fa: "\f29d";
  --fa--fa: "\f29d\f29d"; }

.fa-blind {
  --fa: "\f29d";
  --fa--fa: "\f29d\f29d"; }

.fa-angle-90 {
  --fa: "\e08d";
  --fa--fa: "\e08d\e08d"; }

.fa-rectangle-terminal {
  --fa: "\e236";
  --fa--fa: "\e236\e236"; }

.fa-kite {
  --fa: "\f6f4";
  --fa--fa: "\f6f4\f6f4"; }

.fa-drum {
  --fa: "\f569";
  --fa--fa: "\f569\f569"; }

.fa-scrubber {
  --fa: "\f2f8";
  --fa--fa: "\f2f8\f2f8"; }

.fa-ice-cream {
  --fa: "\f810";
  --fa--fa: "\f810\f810"; }

.fa-heart-circle-bolt {
  --fa: "\e4fc";
  --fa--fa: "\e4fc\e4fc"; }

.fa-fish-bones {
  --fa: "\e304";
  --fa--fa: "\e304\e304"; }

.fa-deer-rudolph {
  --fa: "\f78f";
  --fa--fa: "\f78f\f78f"; }

.fa-fax {
  --fa: "\f1ac";
  --fa--fa: "\f1ac\f1ac"; }

.fa-paragraph {
  --fa: "\f1dd";
  --fa--fa: "\f1dd\f1dd"; }

.fa-head-side-heart {
  --fa: "\e1aa";
  --fa--fa: "\e1aa\e1aa"; }

.fa-square-e {
  --fa: "\e26d";
  --fa--fa: "\e26d\e26d"; }

.fa-meter-fire {
  --fa: "\e1eb";
  --fa--fa: "\e1eb\e1eb"; }

.fa-cloud-hail {
  --fa: "\f739";
  --fa--fa: "\f739\f739"; }

.fa-check-to-slot {
  --fa: "\f772";
  --fa--fa: "\f772\f772"; }

.fa-vote-yea {
  --fa: "\f772";
  --fa--fa: "\f772\f772"; }

.fa-money-from-bracket {
  --fa: "\e312";
  --fa--fa: "\e312\e312"; }

.fa-star-half {
  --fa: "\f089";
  --fa--fa: "\f089\f089"; }

.fa-car-bus {
  --fa: "\f85a";
  --fa--fa: "\f85a\f85a"; }

.fa-speaker {
  --fa: "\f8df";
  --fa--fa: "\f8df\f8df"; }

.fa-timer {
  --fa: "\e29e";
  --fa--fa: "\e29e\e29e"; }

.fa-boxes-stacked {
  --fa: "\f468";
  --fa--fa: "\f468\f468"; }

.fa-boxes {
  --fa: "\f468";
  --fa--fa: "\f468\f468"; }

.fa-boxes-alt {
  --fa: "\f468";
  --fa--fa: "\f468\f468"; }

.fa-landmark-magnifying-glass {
  --fa: "\e622";
  --fa--fa: "\e622\e622"; }

.fa-grill-hot {
  --fa: "\e5a5";
  --fa--fa: "\e5a5\e5a5"; }

.fa-ballot-check {
  --fa: "\f733";
  --fa--fa: "\f733\f733"; }

.fa-link {
  --fa: "\f0c1";
  --fa--fa: "\f0c1\f0c1"; }

.fa-chain {
  --fa: "\f0c1";
  --fa--fa: "\f0c1\f0c1"; }

.fa-ear-listen {
  --fa: "\f2a2";
  --fa--fa: "\f2a2\f2a2"; }

.fa-assistive-listening-systems {
  --fa: "\f2a2";
  --fa--fa: "\f2a2\f2a2"; }

.fa-file-minus {
  --fa: "\f318";
  --fa--fa: "\f318\f318"; }

.fa-tree-city {
  --fa: "\e587";
  --fa--fa: "\e587\e587"; }

.fa-play {
  --fa: "\f04b";
  --fa--fa: "\f04b\f04b"; }

.fa-font {
  --fa: "\f031";
  --fa--fa: "\f031\f031"; }

.fa-cup-togo {
  --fa: "\f6c5";
  --fa--fa: "\f6c5\f6c5"; }

.fa-coffee-togo {
  --fa: "\f6c5";
  --fa--fa: "\f6c5\f6c5"; }

.fa-square-down-left {
  --fa: "\e26b";
  --fa--fa: "\e26b\e26b"; }

.fa-burger-lettuce {
  --fa: "\e3e3";
  --fa--fa: "\e3e3\e3e3"; }

.fa-table-cells-row-lock {
  --fa: "\e67a";
  --fa--fa: "\e67a\e67a"; }

.fa-rupiah-sign {
  --fa: "\e23d";
  --fa--fa: "\e23d\e23d"; }

.fa-magnifying-glass {
  --fa: "\f002";
  --fa--fa: "\f002\f002"; }

.fa-search {
  --fa: "\f002";
  --fa--fa: "\f002\f002"; }

.fa-table-tennis-paddle-ball {
  --fa: "\f45d";
  --fa--fa: "\f45d\f45d"; }

.fa-ping-pong-paddle-ball {
  --fa: "\f45d";
  --fa--fa: "\f45d\f45d"; }

.fa-table-tennis {
  --fa: "\f45d";
  --fa--fa: "\f45d\f45d"; }

.fa-person-dots-from-line {
  --fa: "\f470";
  --fa--fa: "\f470\f470"; }

.fa-diagnoses {
  --fa: "\f470";
  --fa--fa: "\f470\f470"; }

.fa-chevrons-down {
  --fa: "\f322";
  --fa--fa: "\f322\f322"; }

.fa-chevron-double-down {
  --fa: "\f322";
  --fa--fa: "\f322\f322"; }

.fa-trash-can-arrow-up {
  --fa: "\f82a";
  --fa--fa: "\f82a\f82a"; }

.fa-trash-restore-alt {
  --fa: "\f82a";
  --fa--fa: "\f82a\f82a"; }

.fa-signal-good {
  --fa: "\f68e";
  --fa--fa: "\f68e\f68e"; }

.fa-signal-3 {
  --fa: "\f68e";
  --fa--fa: "\f68e\f68e"; }

.fa-location-question {
  --fa: "\f60b";
  --fa--fa: "\f60b\f60b"; }

.fa-map-marker-question {
  --fa: "\f60b";
  --fa--fa: "\f60b\f60b"; }

.fa-floppy-disk-circle-xmark {
  --fa: "\e181";
  --fa--fa: "\e181\e181"; }

.fa-floppy-disk-times {
  --fa: "\e181";
  --fa--fa: "\e181\e181"; }

.fa-save-circle-xmark {
  --fa: "\e181";
  --fa--fa: "\e181\e181"; }

.fa-save-times {
  --fa: "\e181";
  --fa--fa: "\e181\e181"; }

.fa-naira-sign {
  --fa: "\e1f6";
  --fa--fa: "\e1f6\e1f6"; }

.fa-peach {
  --fa: "\e20b";
  --fa--fa: "\e20b\e20b"; }

.fa-circles-overlap-3 {
  --fa: "\e6a1";
  --fa--fa: "\e6a1\e6a1"; }

.fa-pronoun {
  --fa: "\e6a1";
  --fa--fa: "\e6a1\e6a1"; }

.fa-taxi-bus {
  --fa: "\e298";
  --fa--fa: "\e298\e298"; }

.fa-bracket-curly {
  --fa: "\7b";
  --fa--fa: "\7b\7b"; }

.fa-bracket-curly-left {
  --fa: "\7b";
  --fa--fa: "\7b\7b"; }

.fa-lobster {
  --fa: "\e421";
  --fa--fa: "\e421\e421"; }

.fa-cart-flatbed-empty {
  --fa: "\f476";
  --fa--fa: "\f476\f476"; }

.fa-dolly-flatbed-empty {
  --fa: "\f476";
  --fa--fa: "\f476\f476"; }

.fa-colon {
  --fa: "\3a";
  --fa--fa: "\3a\3a"; }

.fa-cart-arrow-down {
  --fa: "\f218";
  --fa--fa: "\f218\f218"; }

.fa-wand {
  --fa: "\f72a";
  --fa--fa: "\f72a\f72a"; }

.fa-walkie-talkie {
  --fa: "\f8ef";
  --fa--fa: "\f8ef\f8ef"; }

.fa-file-pen {
  --fa: "\f31c";
  --fa--fa: "\f31c\f31c"; }

.fa-file-edit {
  --fa: "\f31c";
  --fa--fa: "\f31c\f31c"; }

.fa-receipt {
  --fa: "\f543";
  --fa--fa: "\f543\f543"; }

.fa-table-picnic {
  --fa: "\e32d";
  --fa--fa: "\e32d\e32d"; }

.fa-square-pen {
  --fa: "\f14b";
  --fa--fa: "\f14b\f14b"; }

.fa-pen-square {
  --fa: "\f14b";
  --fa--fa: "\f14b\f14b"; }

.fa-pencil-square {
  --fa: "\f14b";
  --fa--fa: "\f14b\f14b"; }

.fa-circle-microphone-lines {
  --fa: "\e117";
  --fa--fa: "\e117\e117"; }

.fa-microphone-circle-alt {
  --fa: "\e117";
  --fa--fa: "\e117\e117"; }

.fa-display-slash {
  --fa: "\e2fa";
  --fa--fa: "\e2fa\e2fa"; }

.fa-desktop-slash {
  --fa: "\e2fa";
  --fa--fa: "\e2fa\e2fa"; }

.fa-suitcase-rolling {
  --fa: "\f5c1";
  --fa--fa: "\f5c1\f5c1"; }

.fa-person-circle-exclamation {
  --fa: "\e53f";
  --fa--fa: "\e53f\e53f"; }

.fa-transporter-2 {
  --fa: "\e044";
  --fa--fa: "\e044\e044"; }

.fa-user-hoodie {
  --fa: "\e68a";
  --fa--fa: "\e68a\e68a"; }

.fa-hands-holding-diamond {
  --fa: "\f47c";
  --fa--fa: "\f47c\f47c"; }

.fa-hand-receiving {
  --fa: "\f47c";
  --fa--fa: "\f47c\f47c"; }

.fa-money-bill-simple-wave {
  --fa: "\e1f2";
  --fa--fa: "\e1f2\e1f2"; }

.fa-chevron-down {
  --fa: "\f078";
  --fa--fa: "\f078\f078"; }

.fa-battery-full {
  --fa: "\f240";
  --fa--fa: "\f240\f240"; }

.fa-battery {
  --fa: "\f240";
  --fa--fa: "\f240\f240"; }

.fa-battery-5 {
  --fa: "\f240";
  --fa--fa: "\f240\f240"; }

.fa-bell-plus {
  --fa: "\f849";
  --fa--fa: "\f849\f849"; }

.fa-book-arrow-right {
  --fa: "\e0b9";
  --fa--fa: "\e0b9\e0b9"; }

.fa-hospitals {
  --fa: "\f80e";
  --fa--fa: "\f80e\f80e"; }

.fa-club {
  --fa: "\f327";
  --fa--fa: "\f327\f327"; }

.fa-skull-crossbones {
  --fa: "\f714";
  --fa--fa: "\f714\f714"; }

.fa-droplet-degree {
  --fa: "\f748";
  --fa--fa: "\f748\f748"; }

.fa-dewpoint {
  --fa: "\f748";
  --fa--fa: "\f748\f748"; }

.fa-code-compare {
  --fa: "\e13a";
  --fa--fa: "\e13a\e13a"; }

.fa-list-ul {
  --fa: "\f0ca";
  --fa--fa: "\f0ca\f0ca"; }

.fa-list-dots {
  --fa: "\f0ca";
  --fa--fa: "\f0ca\f0ca"; }

.fa-hand-holding-magic {
  --fa: "\f6e5";
  --fa--fa: "\f6e5\f6e5"; }

.fa-watermelon-slice {
  --fa: "\e337";
  --fa--fa: "\e337\e337"; }

.fa-circle-ellipsis {
  --fa: "\e10a";
  --fa--fa: "\e10a\e10a"; }

.fa-school-lock {
  --fa: "\e56f";
  --fa--fa: "\e56f\e56f"; }

.fa-tower-cell {
  --fa: "\e585";
  --fa--fa: "\e585\e585"; }

.fa-sd-cards {
  --fa: "\e240";
  --fa--fa: "\e240\e240"; }

.fa-jug-bottle {
  --fa: "\e5fb";
  --fa--fa: "\e5fb\e5fb"; }

.fa-down-long {
  --fa: "\f309";
  --fa--fa: "\f309\f309"; }

.fa-long-arrow-alt-down {
  --fa: "\f309";
  --fa--fa: "\f309\f309"; }

.fa-envelopes {
  --fa: "\e170";
  --fa--fa: "\e170\e170"; }

.fa-phone-office {
  --fa: "\f67d";
  --fa--fa: "\f67d\f67d"; }

.fa-ranking-star {
  --fa: "\e561";
  --fa--fa: "\e561\e561"; }

.fa-chess-king {
  --fa: "\f43f";
  --fa--fa: "\f43f\f43f"; }

.fa-nfc-pen {
  --fa: "\e1fa";
  --fa--fa: "\e1fa\e1fa"; }

.fa-person-harassing {
  --fa: "\e549";
  --fa--fa: "\e549\e549"; }

.fa-magnifying-glass-play {
  --fa: "\e660";
  --fa--fa: "\e660\e660"; }

.fa-hat-winter {
  --fa: "\f7a8";
  --fa--fa: "\f7a8\f7a8"; }

.fa-brazilian-real-sign {
  --fa: "\e46c";
  --fa--fa: "\e46c\e46c"; }

.fa-landmark-dome {
  --fa: "\f752";
  --fa--fa: "\f752\f752"; }

.fa-landmark-alt {
  --fa: "\f752";
  --fa--fa: "\f752\f752"; }

.fa-bone-break {
  --fa: "\f5d8";
  --fa--fa: "\f5d8\f5d8"; }

.fa-arrow-up {
  --fa: "\f062";
  --fa--fa: "\f062\f062"; }

.fa-down-from-dotted-line {
  --fa: "\e407";
  --fa--fa: "\e407\e407"; }

.fa-tv {
  --fa: "\f26c";
  --fa--fa: "\f26c\f26c"; }

.fa-television {
  --fa: "\f26c";
  --fa--fa: "\f26c\f26c"; }

.fa-tv-alt {
  --fa: "\f26c";
  --fa--fa: "\f26c\f26c"; }

.fa-border-left {
  --fa: "\f84f";
  --fa--fa: "\f84f\f84f"; }

.fa-circle-divide {
  --fa: "\e106";
  --fa--fa: "\e106\e106"; }

.fa-shrimp {
  --fa: "\e448";
  --fa--fa: "\e448\e448"; }

.fa-list-check {
  --fa: "\f0ae";
  --fa--fa: "\f0ae\f0ae"; }

.fa-tasks {
  --fa: "\f0ae";
  --fa--fa: "\f0ae\f0ae"; }

.fa-diagram-subtask {
  --fa: "\e479";
  --fa--fa: "\e479\e479"; }

.fa-jug-detergent {
  --fa: "\e519";
  --fa--fa: "\e519\e519"; }

.fa-circle-user {
  --fa: "\f2bd";
  --fa--fa: "\f2bd\f2bd"; }

.fa-user-circle {
  --fa: "\f2bd";
  --fa--fa: "\f2bd\f2bd"; }

.fa-square-y {
  --fa: "\e287";
  --fa--fa: "\e287\e287"; }

.fa-user-doctor-hair {
  --fa: "\e458";
  --fa--fa: "\e458\e458"; }

.fa-planet-ringed {
  --fa: "\e020";
  --fa--fa: "\e020\e020"; }

.fa-mushroom {
  --fa: "\e425";
  --fa--fa: "\e425\e425"; }

.fa-user-shield {
  --fa: "\f505";
  --fa--fa: "\f505\f505"; }

.fa-megaphone {
  --fa: "\f675";
  --fa--fa: "\f675\f675"; }

.fa-wreath-laurel {
  --fa: "\e5d2";
  --fa--fa: "\e5d2\e5d2"; }

.fa-circle-exclamation-check {
  --fa: "\e10d";
  --fa--fa: "\e10d\e10d"; }

.fa-wind {
  --fa: "\f72e";
  --fa--fa: "\f72e\f72e"; }

.fa-box-dollar {
  --fa: "\f4a0";
  --fa--fa: "\f4a0\f4a0"; }

.fa-box-usd {
  --fa: "\f4a0";
  --fa--fa: "\f4a0\f4a0"; }

.fa-car-burst {
  --fa: "\f5e1";
  --fa--fa: "\f5e1\f5e1"; }

.fa-car-crash {
  --fa: "\f5e1";
  --fa--fa: "\f5e1\f5e1"; }

.fa-y {
  --fa: "\59";
  --fa--fa: "\59\59"; }

.fa-user-headset {
  --fa: "\f82d";
  --fa--fa: "\f82d\f82d"; }

.fa-arrows-retweet {
  --fa: "\f361";
  --fa--fa: "\f361\f361"; }

.fa-retweet-alt {
  --fa: "\f361";
  --fa--fa: "\f361\f361"; }

.fa-person-snowboarding {
  --fa: "\f7ce";
  --fa--fa: "\f7ce\f7ce"; }

.fa-snowboarding {
  --fa: "\f7ce";
  --fa--fa: "\f7ce\f7ce"; }

.fa-square-chevron-right {
  --fa: "\f32b";
  --fa--fa: "\f32b\f32b"; }

.fa-chevron-square-right {
  --fa: "\f32b";
  --fa--fa: "\f32b\f32b"; }

.fa-lacrosse-stick-ball {
  --fa: "\e3b6";
  --fa--fa: "\e3b6\e3b6"; }

.fa-truck-fast {
  --fa: "\f48b";
  --fa--fa: "\f48b\f48b"; }

.fa-shipping-fast {
  --fa: "\f48b";
  --fa--fa: "\f48b\f48b"; }

.fa-user-magnifying-glass {
  --fa: "\e5c5";
  --fa--fa: "\e5c5\e5c5"; }

.fa-star-sharp {
  --fa: "\e28b";
  --fa--fa: "\e28b\e28b"; }

.fa-comment-heart {
  --fa: "\e5c8";
  --fa--fa: "\e5c8\e5c8"; }

.fa-circle-1 {
  --fa: "\e0ee";
  --fa--fa: "\e0ee\e0ee"; }

.fa-circle-star {
  --fa: "\e123";
  --fa--fa: "\e123\e123"; }

.fa-star-circle {
  --fa: "\e123";
  --fa--fa: "\e123\e123"; }

.fa-fish {
  --fa: "\f578";
  --fa--fa: "\f578\f578"; }

.fa-cloud-fog {
  --fa: "\f74e";
  --fa--fa: "\f74e\f74e"; }

.fa-fog {
  --fa: "\f74e";
  --fa--fa: "\f74e\f74e"; }

.fa-waffle {
  --fa: "\e466";
  --fa--fa: "\e466\e466"; }

.fa-music-note {
  --fa: "\f8cf";
  --fa--fa: "\f8cf\f8cf"; }

.fa-music-alt {
  --fa: "\f8cf";
  --fa--fa: "\f8cf\f8cf"; }

.fa-hexagon-exclamation {
  --fa: "\e417";
  --fa--fa: "\e417\e417"; }

.fa-cart-shopping-fast {
  --fa: "\e0dc";
  --fa--fa: "\e0dc\e0dc"; }

.fa-object-union {
  --fa: "\e49f";
  --fa--fa: "\e49f\e49f"; }

.fa-user-graduate {
  --fa: "\f501";
  --fa--fa: "\f501\f501"; }

.fa-starfighter {
  --fa: "\e037";
  --fa--fa: "\e037\e037"; }

.fa-circle-half-stroke {
  --fa: "\f042";
  --fa--fa: "\f042\f042"; }

.fa-adjust {
  --fa: "\f042";
  --fa--fa: "\f042\f042"; }

.fa-arrow-right-long-to-line {
  --fa: "\e3d5";
  --fa--fa: "\e3d5\e3d5"; }

.fa-square-arrow-down {
  --fa: "\f339";
  --fa--fa: "\f339\f339"; }

.fa-arrow-square-down {
  --fa: "\f339";
  --fa--fa: "\f339\f339"; }

.fa-diamond-half-stroke {
  --fa: "\e5b8";
  --fa--fa: "\e5b8\e5b8"; }

.fa-clapperboard {
  --fa: "\e131";
  --fa--fa: "\e131\e131"; }

.fa-square-chevron-left {
  --fa: "\f32a";
  --fa--fa: "\f32a\f32a"; }

.fa-chevron-square-left {
  --fa: "\f32a";
  --fa--fa: "\f32a\f32a"; }

.fa-phone-intercom {
  --fa: "\e434";
  --fa--fa: "\e434\e434"; }

.fa-link-horizontal {
  --fa: "\e1cb";
  --fa--fa: "\e1cb\e1cb"; }

.fa-chain-horizontal {
  --fa: "\e1cb";
  --fa--fa: "\e1cb\e1cb"; }

.fa-mango {
  --fa: "\e30f";
  --fa--fa: "\e30f\e30f"; }

.fa-music-note-slash {
  --fa: "\f8d0";
  --fa--fa: "\f8d0\f8d0"; }

.fa-music-alt-slash {
  --fa: "\f8d0";
  --fa--fa: "\f8d0\f8d0"; }

.fa-circle-radiation {
  --fa: "\f7ba";
  --fa--fa: "\f7ba\f7ba"; }

.fa-radiation-alt {
  --fa: "\f7ba";
  --fa--fa: "\f7ba\f7ba"; }

.fa-face-tongue-sweat {
  --fa: "\e39e";
  --fa--fa: "\e39e\e39e"; }

.fa-globe-stand {
  --fa: "\f5f6";
  --fa--fa: "\f5f6\f5f6"; }

.fa-baseball {
  --fa: "\f433";
  --fa--fa: "\f433\f433"; }

.fa-baseball-ball {
  --fa: "\f433";
  --fa--fa: "\f433\f433"; }

.fa-circle-p {
  --fa: "\e11a";
  --fa--fa: "\e11a\e11a"; }

.fa-award-simple {
  --fa: "\e0ab";
  --fa--fa: "\e0ab\e0ab"; }

.fa-jet-fighter-up {
  --fa: "\e518";
  --fa--fa: "\e518\e518"; }

.fa-diagram-project {
  --fa: "\f542";
  --fa--fa: "\f542\f542"; }

.fa-project-diagram {
  --fa: "\f542";
  --fa--fa: "\f542\f542"; }

.fa-pedestal {
  --fa: "\e20d";
  --fa--fa: "\e20d\e20d"; }

.fa-chart-pyramid {
  --fa: "\e0e6";
  --fa--fa: "\e0e6\e0e6"; }

.fa-sidebar {
  --fa: "\e24e";
  --fa--fa: "\e24e\e24e"; }

.fa-snowman-head {
  --fa: "\f79b";
  --fa--fa: "\f79b\f79b"; }

.fa-frosty-head {
  --fa: "\f79b";
  --fa--fa: "\f79b\f79b"; }

.fa-copy {
  --fa: "\f0c5";
  --fa--fa: "\f0c5\f0c5"; }

.fa-burger-glass {
  --fa: "\e0ce";
  --fa--fa: "\e0ce\e0ce"; }

.fa-volume-xmark {
  --fa: "\f6a9";
  --fa--fa: "\f6a9\f6a9"; }

.fa-volume-mute {
  --fa: "\f6a9";
  --fa--fa: "\f6a9\f6a9"; }

.fa-volume-times {
  --fa: "\f6a9";
  --fa--fa: "\f6a9\f6a9"; }

.fa-hand-sparkles {
  --fa: "\e05d";
  --fa--fa: "\e05d\e05d"; }

.fa-bars-filter {
  --fa: "\e0ad";
  --fa--fa: "\e0ad\e0ad"; }

.fa-paintbrush-pencil {
  --fa: "\e206";
  --fa--fa: "\e206\e206"; }

.fa-party-bell {
  --fa: "\e31a";
  --fa--fa: "\e31a\e31a"; }

.fa-user-vneck-hair {
  --fa: "\e462";
  --fa--fa: "\e462\e462"; }

.fa-jack-o-lantern {
  --fa: "\f30e";
  --fa--fa: "\f30e\f30e"; }

.fa-grip {
  --fa: "\f58d";
  --fa--fa: "\f58d\f58d"; }

.fa-grip-horizontal {
  --fa: "\f58d";
  --fa--fa: "\f58d\f58d"; }

.fa-share-from-square {
  --fa: "\f14d";
  --fa--fa: "\f14d\f14d"; }

.fa-share-square {
  --fa: "\f14d";
  --fa--fa: "\f14d\f14d"; }

.fa-keynote {
  --fa: "\f66c";
  --fa--fa: "\f66c\f66c"; }

.fa-child-combatant {
  --fa: "\e4e0";
  --fa--fa: "\e4e0\e4e0"; }

.fa-child-rifle {
  --fa: "\e4e0";
  --fa--fa: "\e4e0\e4e0"; }

.fa-gun {
  --fa: "\e19b";
  --fa--fa: "\e19b\e19b"; }

.fa-square-phone {
  --fa: "\f098";
  --fa--fa: "\f098\f098"; }

.fa-phone-square {
  --fa: "\f098";
  --fa--fa: "\f098\f098"; }

.fa-hat-beach {
  --fa: "\e606";
  --fa--fa: "\e606\e606"; }

.fa-plus {
  --fa: "\2b";
  --fa--fa: "\2b\2b"; }

.fa-add {
  --fa: "\2b";
  --fa--fa: "\2b\2b"; }

.fa-expand {
  --fa: "\f065";
  --fa--fa: "\f065\f065"; }

.fa-computer {
  --fa: "\e4e5";
  --fa--fa: "\e4e5\e4e5"; }

.fa-fort {
  --fa: "\e486";
  --fa--fa: "\e486\e486"; }

.fa-cloud-check {
  --fa: "\e35c";
  --fa--fa: "\e35c\e35c"; }

.fa-xmark {
  --fa: "\f00d";
  --fa--fa: "\f00d\f00d"; }

.fa-close {
  --fa: "\f00d";
  --fa--fa: "\f00d\f00d"; }

.fa-multiply {
  --fa: "\f00d";
  --fa--fa: "\f00d\f00d"; }

.fa-remove {
  --fa: "\f00d";
  --fa--fa: "\f00d\f00d"; }

.fa-times {
  --fa: "\f00d";
  --fa--fa: "\f00d\f00d"; }

.fa-face-smirking {
  --fa: "\e397";
  --fa--fa: "\e397\e397"; }

.fa-arrows-up-down-left-right {
  --fa: "\f047";
  --fa--fa: "\f047\f047"; }

.fa-arrows {
  --fa: "\f047";
  --fa--fa: "\f047\f047"; }

.fa-chalkboard-user {
  --fa: "\f51c";
  --fa--fa: "\f51c\f51c"; }

.fa-chalkboard-teacher {
  --fa: "\f51c";
  --fa--fa: "\f51c\f51c"; }

.fa-rhombus {
  --fa: "\e23b";
  --fa--fa: "\e23b\e23b"; }

.fa-claw-marks {
  --fa: "\f6c2";
  --fa--fa: "\f6c2\f6c2"; }

.fa-peso-sign {
  --fa: "\e222";
  --fa--fa: "\e222\e222"; }

.fa-face-smile-tongue {
  --fa: "\e394";
  --fa--fa: "\e394\e394"; }

.fa-cart-circle-xmark {
  --fa: "\e3f4";
  --fa--fa: "\e3f4\e3f4"; }

.fa-building-shield {
  --fa: "\e4d8";
  --fa--fa: "\e4d8\e4d8"; }

.fa-circle-phone-flip {
  --fa: "\e11c";
  --fa--fa: "\e11c\e11c"; }

.fa-phone-circle-alt {
  --fa: "\e11c";
  --fa--fa: "\e11c\e11c"; }

.fa-baby {
  --fa: "\f77c";
  --fa--fa: "\f77c\f77c"; }

.fa-users-line {
  --fa: "\e592";
  --fa--fa: "\e592\e592"; }

.fa-quote-left {
  --fa: "\f10d";
  --fa--fa: "\f10d\f10d"; }

.fa-quote-left-alt {
  --fa: "\f10d";
  --fa--fa: "\f10d\f10d"; }

.fa-tractor {
  --fa: "\f722";
  --fa--fa: "\f722\f722"; }

.fa-down-from-bracket {
  --fa: "\e66b";
  --fa--fa: "\e66b\e66b"; }

.fa-key-skeleton {
  --fa: "\f6f3";
  --fa--fa: "\f6f3\f6f3"; }

.fa-trash-arrow-up {
  --fa: "\f829";
  --fa--fa: "\f829\f829"; }

.fa-trash-restore {
  --fa: "\f829";
  --fa--fa: "\f829\f829"; }

.fa-arrow-down-up-lock {
  --fa: "\e4b0";
  --fa--fa: "\e4b0\e4b0"; }

.fa-arrow-down-to-bracket {
  --fa: "\e094";
  --fa--fa: "\e094\e094"; }

.fa-lines-leaning {
  --fa: "\e51e";
  --fa--fa: "\e51e\e51e"; }

.fa-square-q {
  --fa: "\e27b";
  --fa--fa: "\e27b\e27b"; }

.fa-ruler-combined {
  --fa: "\f546";
  --fa--fa: "\f546\f546"; }

.fa-symbols {
  --fa: "\f86e";
  --fa--fa: "\f86e\f86e"; }

.fa-icons-alt {
  --fa: "\f86e";
  --fa--fa: "\f86e\f86e"; }

.fa-copyright {
  --fa: "\f1f9";
  --fa--fa: "\f1f9\f1f9"; }

.fa-flask-gear {
  --fa: "\e5f1";
  --fa--fa: "\e5f1\e5f1"; }

.fa-highlighter-line {
  --fa: "\e1af";
  --fa--fa: "\e1af\e1af"; }

.fa-bracket-square {
  --fa: "\5b";
  --fa--fa: "\5b\5b"; }

.fa-bracket {
  --fa: "\5b";
  --fa--fa: "\5b\5b"; }

.fa-bracket-left {
  --fa: "\5b";
  --fa--fa: "\5b\5b"; }

.fa-island-tropical {
  --fa: "\f811";
  --fa--fa: "\f811\f811"; }

.fa-island-tree-palm {
  --fa: "\f811";
  --fa--fa: "\f811\f811"; }

.fa-arrow-right-from-line {
  --fa: "\f343";
  --fa--fa: "\f343\f343"; }

.fa-arrow-from-left {
  --fa: "\f343";
  --fa--fa: "\f343\f343"; }

.fa-h2 {
  --fa: "\f314";
  --fa--fa: "\f314\f314"; }

.fa-equals {
  --fa: "\3d";
  --fa--fa: "\3d\3d"; }

.fa-cake-slice {
  --fa: "\e3e5";
  --fa--fa: "\e3e5\e3e5"; }

.fa-shortcake {
  --fa: "\e3e5";
  --fa--fa: "\e3e5\e3e5"; }

.fa-building-magnifying-glass {
  --fa: "\e61c";
  --fa--fa: "\e61c\e61c"; }

.fa-peanut {
  --fa: "\e430";
  --fa--fa: "\e430\e430"; }

.fa-wrench-simple {
  --fa: "\e2d1";
  --fa--fa: "\e2d1\e2d1"; }

.fa-blender {
  --fa: "\f517";
  --fa--fa: "\f517\f517"; }

.fa-teeth {
  --fa: "\f62e";
  --fa--fa: "\f62e\f62e"; }

.fa-tally-2 {
  --fa: "\e295";
  --fa--fa: "\e295\e295"; }

.fa-shekel-sign {
  --fa: "\f20b";
  --fa--fa: "\f20b\f20b"; }

.fa-ils {
  --fa: "\f20b";
  --fa--fa: "\f20b\f20b"; }

.fa-shekel {
  --fa: "\f20b";
  --fa--fa: "\f20b\f20b"; }

.fa-sheqel {
  --fa: "\f20b";
  --fa--fa: "\f20b\f20b"; }

.fa-sheqel-sign {
  --fa: "\f20b";
  --fa--fa: "\f20b\f20b"; }

.fa-cars {
  --fa: "\f85b";
  --fa--fa: "\f85b\f85b"; }

.fa-axe-battle {
  --fa: "\f6b3";
  --fa--fa: "\f6b3\f6b3"; }

.fa-user-hair-long {
  --fa: "\e45b";
  --fa--fa: "\e45b\e45b"; }

.fa-map {
  --fa: "\f279";
  --fa--fa: "\f279\f279"; }

.fa-arrow-left-from-arc {
  --fa: "\e615";
  --fa--fa: "\e615\e615"; }

.fa-file-circle-info {
  --fa: "\e493";
  --fa--fa: "\e493\e493"; }

.fa-face-disappointed {
  --fa: "\e36f";
  --fa--fa: "\e36f\e36f"; }

.fa-lasso-sparkles {
  --fa: "\e1c9";
  --fa--fa: "\e1c9\e1c9"; }

.fa-clock-eleven {
  --fa: "\e347";
  --fa--fa: "\e347\e347"; }

.fa-rocket {
  --fa: "\f135";
  --fa--fa: "\f135\f135"; }

.fa-siren-on {
  --fa: "\e02e";
  --fa--fa: "\e02e\e02e"; }

.fa-clock-ten {
  --fa: "\e354";
  --fa--fa: "\e354\e354"; }

.fa-candle-holder {
  --fa: "\f6bc";
  --fa--fa: "\f6bc\f6bc"; }

.fa-video-arrow-down-left {
  --fa: "\e2c8";
  --fa--fa: "\e2c8\e2c8"; }

.fa-photo-film {
  --fa: "\f87c";
  --fa--fa: "\f87c\f87c"; }

.fa-photo-video {
  --fa: "\f87c";
  --fa--fa: "\f87c\f87c"; }

.fa-floppy-disk-circle-arrow-right {
  --fa: "\e180";
  --fa--fa: "\e180\e180"; }

.fa-save-circle-arrow-right {
  --fa: "\e180";
  --fa--fa: "\e180\e180"; }

.fa-folder-minus {
  --fa: "\f65d";
  --fa--fa: "\f65d\f65d"; }

.fa-hexagon-nodes-bolt {
  --fa: "\e69a";
  --fa--fa: "\e69a\e69a"; }

.fa-planet-moon {
  --fa: "\e01f";
  --fa--fa: "\e01f\e01f"; }

.fa-face-eyes-xmarks {
  --fa: "\e374";
  --fa--fa: "\e374\e374"; }

.fa-chart-scatter {
  --fa: "\f7ee";
  --fa--fa: "\f7ee\f7ee"; }

.fa-circle-gf {
  --fa: "\e67f";
  --fa--fa: "\e67f\e67f"; }

.fa-display-arrow-down {
  --fa: "\e164";
  --fa--fa: "\e164\e164"; }

.fa-store {
  --fa: "\f54e";
  --fa--fa: "\f54e\f54e"; }

.fa-arrow-trend-up {
  --fa: "\e098";
  --fa--fa: "\e098\e098"; }

.fa-plug-circle-minus {
  --fa: "\e55e";
  --fa--fa: "\e55e\e55e"; }

.fa-olive-branch {
  --fa: "\e317";
  --fa--fa: "\e317\e317"; }

.fa-angle {
  --fa: "\e08c";
  --fa--fa: "\e08c\e08c"; }

.fa-vacuum-robot {
  --fa: "\e04e";
  --fa--fa: "\e04e\e04e"; }

.fa-sign-hanging {
  --fa: "\f4d9";
  --fa--fa: "\f4d9\f4d9"; }

.fa-sign {
  --fa: "\f4d9";
  --fa--fa: "\f4d9\f4d9"; }

.fa-square-divide {
  --fa: "\e26a";
  --fa--fa: "\e26a\e26a"; }

.fa-folder-check {
  --fa: "\e64e";
  --fa--fa: "\e64e\e64e"; }

.fa-signal-stream-slash {
  --fa: "\e250";
  --fa--fa: "\e250\e250"; }

.fa-bezier-curve {
  --fa: "\f55b";
  --fa--fa: "\f55b\f55b"; }

.fa-eye-dropper-half {
  --fa: "\e173";
  --fa--fa: "\e173\e173"; }

.fa-store-lock {
  --fa: "\e4a6";
  --fa--fa: "\e4a6\e4a6"; }

.fa-bell-slash {
  --fa: "\f1f6";
  --fa--fa: "\f1f6\f1f6"; }

.fa-cloud-bolt-sun {
  --fa: "\f76e";
  --fa--fa: "\f76e\f76e"; }

.fa-thunderstorm-sun {
  --fa: "\f76e";
  --fa--fa: "\f76e\f76e"; }

.fa-camera-slash {
  --fa: "\e0d9";
  --fa--fa: "\e0d9\e0d9"; }

.fa-comment-quote {
  --fa: "\e14c";
  --fa--fa: "\e14c\e14c"; }

.fa-tablet {
  --fa: "\f3fb";
  --fa--fa: "\f3fb\f3fb"; }

.fa-tablet-android {
  --fa: "\f3fb";
  --fa--fa: "\f3fb\f3fb"; }

.fa-school-flag {
  --fa: "\e56e";
  --fa--fa: "\e56e\e56e"; }

.fa-message-code {
  --fa: "\e1df";
  --fa--fa: "\e1df\e1df"; }

.fa-glass-half {
  --fa: "\e192";
  --fa--fa: "\e192\e192"; }

.fa-glass-half-empty {
  --fa: "\e192";
  --fa--fa: "\e192\e192"; }

.fa-glass-half-full {
  --fa: "\e192";
  --fa--fa: "\e192\e192"; }

.fa-fill {
  --fa: "\f575";
  --fa--fa: "\f575\f575"; }

.fa-message-minus {
  --fa: "\f4a7";
  --fa--fa: "\f4a7\f4a7"; }

.fa-comment-alt-minus {
  --fa: "\f4a7";
  --fa--fa: "\f4a7\f4a7"; }

.fa-angle-up {
  --fa: "\f106";
  --fa--fa: "\f106\f106"; }

.fa-dinosaur {
  --fa: "\e5fe";
  --fa--fa: "\e5fe\e5fe"; }

.fa-drumstick-bite {
  --fa: "\f6d7";
  --fa--fa: "\f6d7\f6d7"; }

.fa-link-horizontal-slash {
  --fa: "\e1cc";
  --fa--fa: "\e1cc\e1cc"; }

.fa-chain-horizontal-slash {
  --fa: "\e1cc";
  --fa--fa: "\e1cc\e1cc"; }

.fa-holly-berry {
  --fa: "\f7aa";
  --fa--fa: "\f7aa\f7aa"; }

.fa-nose {
  --fa: "\e5bd";
  --fa--fa: "\e5bd\e5bd"; }

.fa-arrow-left-to-arc {
  --fa: "\e616";
  --fa--fa: "\e616\e616"; }

.fa-chevron-left {
  --fa: "\f053";
  --fa--fa: "\f053\f053"; }

.fa-bacteria {
  --fa: "\e059";
  --fa--fa: "\e059\e059"; }

.fa-clouds {
  --fa: "\f744";
  --fa--fa: "\f744\f744"; }

.fa-money-bill-simple {
  --fa: "\e1f1";
  --fa--fa: "\e1f1\e1f1"; }

.fa-hand-lizard {
  --fa: "\f258";
  --fa--fa: "\f258\f258"; }

.fa-table-pivot {
  --fa: "\e291";
  --fa--fa: "\e291\e291"; }

.fa-filter-slash {
  --fa: "\e17d";
  --fa--fa: "\e17d\e17d"; }

.fa-trash-can-undo {
  --fa: "\f896";
  --fa--fa: "\f896\f896"; }

.fa-trash-can-arrow-turn-left {
  --fa: "\f896";
  --fa--fa: "\f896\f896"; }

.fa-trash-undo-alt {
  --fa: "\f896";
  --fa--fa: "\f896\f896"; }

.fa-notdef {
  --fa: "\e1fe";
  --fa--fa: "\e1fe\e1fe"; }

.fa-disease {
  --fa: "\f7fa";
  --fa--fa: "\f7fa\f7fa"; }

.fa-person-to-door {
  --fa: "\e433";
  --fa--fa: "\e433\e433"; }

.fa-turntable {
  --fa: "\f8e4";
  --fa--fa: "\f8e4\f8e4"; }

.fa-briefcase-medical {
  --fa: "\f469";
  --fa--fa: "\f469\f469"; }

.fa-genderless {
  --fa: "\f22d";
  --fa--fa: "\f22d\f22d"; }

.fa-chevron-right {
  --fa: "\f054";
  --fa--fa: "\f054\f054"; }

.fa-signal-weak {
  --fa: "\f68c";
  --fa--fa: "\f68c\f68c"; }

.fa-signal-1 {
  --fa: "\f68c";
  --fa--fa: "\f68c\f68c"; }

.fa-clock-five {
  --fa: "\e349";
  --fa--fa: "\e349\e349"; }

.fa-retweet {
  --fa: "\f079";
  --fa--fa: "\f079\f079"; }

.fa-car-rear {
  --fa: "\f5de";
  --fa--fa: "\f5de\f5de"; }

.fa-car-alt {
  --fa: "\f5de";
  --fa--fa: "\f5de\f5de"; }

.fa-pump-soap {
  --fa: "\e06b";
  --fa--fa: "\e06b\e06b"; }

.fa-computer-classic {
  --fa: "\f8b1";
  --fa--fa: "\f8b1\f8b1"; }

.fa-frame {
  --fa: "\e495";
  --fa--fa: "\e495\e495"; }

.fa-video-slash {
  --fa: "\f4e2";
  --fa--fa: "\f4e2\f4e2"; }

.fa-battery-quarter {
  --fa: "\f243";
  --fa--fa: "\f243\f243"; }

.fa-battery-2 {
  --fa: "\f243";
  --fa--fa: "\f243\f243"; }

.fa-ellipsis-stroke {
  --fa: "\f39b";
  --fa--fa: "\f39b\f39b"; }

.fa-ellipsis-h-alt {
  --fa: "\f39b";
  --fa--fa: "\f39b\f39b"; }

.fa-radio {
  --fa: "\f8d7";
  --fa--fa: "\f8d7\f8d7"; }

.fa-baby-carriage {
  --fa: "\f77d";
  --fa--fa: "\f77d\f77d"; }

.fa-carriage-baby {
  --fa: "\f77d";
  --fa--fa: "\f77d\f77d"; }

.fa-face-expressionless {
  --fa: "\e373";
  --fa--fa: "\e373\e373"; }

.fa-down-to-dotted-line {
  --fa: "\e408";
  --fa--fa: "\e408\e408"; }

.fa-cloud-music {
  --fa: "\f8ae";
  --fa--fa: "\f8ae\f8ae"; }

.fa-traffic-light {
  --fa: "\f637";
  --fa--fa: "\f637\f637"; }

.fa-cloud-minus {
  --fa: "\e35d";
  --fa--fa: "\e35d\e35d"; }

.fa-thermometer {
  --fa: "\f491";
  --fa--fa: "\f491\f491"; }

.fa-shield-minus {
  --fa: "\e249";
  --fa--fa: "\e249\e249"; }

.fa-vr-cardboard {
  --fa: "\f729";
  --fa--fa: "\f729\f729"; }

.fa-car-tilt {
  --fa: "\f5e5";
  --fa--fa: "\f5e5\f5e5"; }

.fa-gauge-circle-minus {
  --fa: "\e497";
  --fa--fa: "\e497\e497"; }

.fa-brightness-low {
  --fa: "\e0ca";
  --fa--fa: "\e0ca\e0ca"; }

.fa-hand-middle-finger {
  --fa: "\f806";
  --fa--fa: "\f806\f806"; }

.fa-percent {
  --fa: "\25";
  --fa--fa: "\25\25"; }

.fa-percentage {
  --fa: "\25";
  --fa--fa: "\25\25"; }

.fa-truck-moving {
  --fa: "\f4df";
  --fa--fa: "\f4df\f4df"; }

.fa-glass-water-droplet {
  --fa: "\e4f5";
  --fa--fa: "\e4f5\e4f5"; }

.fa-conveyor-belt {
  --fa: "\f46e";
  --fa--fa: "\f46e\f46e"; }

.fa-location-check {
  --fa: "\f606";
  --fa--fa: "\f606\f606"; }

.fa-map-marker-check {
  --fa: "\f606";
  --fa--fa: "\f606\f606"; }

.fa-coin-vertical {
  --fa: "\e3fd";
  --fa--fa: "\e3fd\e3fd"; }

.fa-display {
  --fa: "\e163";
  --fa--fa: "\e163\e163"; }

.fa-person-sign {
  --fa: "\f757";
  --fa--fa: "\f757\f757"; }

.fa-face-smile {
  --fa: "\f118";
  --fa--fa: "\f118\f118"; }

.fa-smile {
  --fa: "\f118";
  --fa--fa: "\f118\f118"; }

.fa-phone-hangup {
  --fa: "\e225";
  --fa--fa: "\e225\e225"; }

.fa-signature-slash {
  --fa: "\e3cb";
  --fa--fa: "\e3cb\e3cb"; }

.fa-thumbtack {
  --fa: "\f08d";
  --fa--fa: "\f08d\f08d"; }

.fa-thumb-tack {
  --fa: "\f08d";
  --fa--fa: "\f08d\f08d"; }

.fa-wheat-slash {
  --fa: "\e339";
  --fa--fa: "\e339\e339"; }

.fa-trophy {
  --fa: "\f091";
  --fa--fa: "\f091\f091"; }

.fa-clouds-sun {
  --fa: "\f746";
  --fa--fa: "\f746\f746"; }

.fa-person-praying {
  --fa: "\f683";
  --fa--fa: "\f683\f683"; }

.fa-pray {
  --fa: "\f683";
  --fa--fa: "\f683\f683"; }

.fa-hammer {
  --fa: "\f6e3";
  --fa--fa: "\f6e3\f6e3"; }

.fa-face-vomit {
  --fa: "\e3a0";
  --fa--fa: "\e3a0\e3a0"; }

.fa-speakers {
  --fa: "\f8e0";
  --fa--fa: "\f8e0\f8e0"; }

.fa-tty-answer {
  --fa: "\e2b9";
  --fa--fa: "\e2b9\e2b9"; }

.fa-teletype-answer {
  --fa: "\e2b9";
  --fa--fa: "\e2b9\e2b9"; }

.fa-mug-tea-saucer {
  --fa: "\e1f5";
  --fa--fa: "\e1f5\e1f5"; }

.fa-diagram-lean-canvas {
  --fa: "\e156";
  --fa--fa: "\e156\e156"; }

.fa-alt {
  --fa: "\e08a";
  --fa--fa: "\e08a\e08a"; }

.fa-dial {
  --fa: "\e15b";
  --fa--fa: "\e15b\e15b"; }

.fa-dial-med-high {
  --fa: "\e15b";
  --fa--fa: "\e15b\e15b"; }

.fa-hand-peace {
  --fa: "\f25b";
  --fa--fa: "\f25b\f25b"; }

.fa-circle-trash {
  --fa: "\e126";
  --fa--fa: "\e126\e126"; }

.fa-trash-circle {
  --fa: "\e126";
  --fa--fa: "\e126\e126"; }

.fa-rotate {
  --fa: "\f2f1";
  --fa--fa: "\f2f1\f2f1"; }

.fa-sync-alt {
  --fa: "\f2f1";
  --fa--fa: "\f2f1\f2f1"; }

.fa-circle-quarters {
  --fa: "\e3f8";
  --fa--fa: "\e3f8\e3f8"; }

.fa-spinner {
  --fa: "\f110";
  --fa--fa: "\f110\f110"; }

.fa-tower-control {
  --fa: "\e2a2";
  --fa--fa: "\e2a2\e2a2"; }

.fa-arrow-up-triangle-square {
  --fa: "\f88a";
  --fa--fa: "\f88a\f88a"; }

.fa-sort-shapes-up {
  --fa: "\f88a";
  --fa--fa: "\f88a\f88a"; }

.fa-whale {
  --fa: "\f72c";
  --fa--fa: "\f72c\f72c"; }

.fa-robot {
  --fa: "\f544";
  --fa--fa: "\f544\f544"; }

.fa-peace {
  --fa: "\f67c";
  --fa--fa: "\f67c\f67c"; }

.fa-party-horn {
  --fa: "\e31b";
  --fa--fa: "\e31b\e31b"; }

.fa-gears {
  --fa: "\f085";
  --fa--fa: "\f085\f085"; }

.fa-cogs {
  --fa: "\f085";
  --fa--fa: "\f085\f085"; }

.fa-sun-bright {
  --fa: "\e28f";
  --fa--fa: "\e28f\e28f"; }

.fa-sun-alt {
  --fa: "\e28f";
  --fa--fa: "\e28f\e28f"; }

.fa-warehouse {
  --fa: "\f494";
  --fa--fa: "\f494\f494"; }

.fa-conveyor-belt-arm {
  --fa: "\e5f8";
  --fa--fa: "\e5f8\e5f8"; }

.fa-lock-keyhole-open {
  --fa: "\f3c2";
  --fa--fa: "\f3c2\f3c2"; }

.fa-lock-open-alt {
  --fa: "\f3c2";
  --fa--fa: "\f3c2\f3c2"; }

.fa-square-fragile {
  --fa: "\f49b";
  --fa--fa: "\f49b\f49b"; }

.fa-box-fragile {
  --fa: "\f49b";
  --fa--fa: "\f49b\f49b"; }

.fa-square-wine-glass-crack {
  --fa: "\f49b";
  --fa--fa: "\f49b\f49b"; }

.fa-arrow-up-right-dots {
  --fa: "\e4b7";
  --fa--fa: "\e4b7\e4b7"; }

.fa-square-n {
  --fa: "\e277";
  --fa--fa: "\e277\e277"; }

.fa-splotch {
  --fa: "\f5bc";
  --fa--fa: "\f5bc\f5bc"; }

.fa-face-grin-hearts {
  --fa: "\f584";
  --fa--fa: "\f584\f584"; }

.fa-grin-hearts {
  --fa: "\f584";
  --fa--fa: "\f584\f584"; }

.fa-meter {
  --fa: "\e1e8";
  --fa--fa: "\e1e8\e1e8"; }

.fa-mandolin {
  --fa: "\f6f9";
  --fa--fa: "\f6f9\f6f9"; }

.fa-dice-four {
  --fa: "\f524";
  --fa--fa: "\f524\f524"; }

.fa-sim-card {
  --fa: "\f7c4";
  --fa--fa: "\f7c4\f7c4"; }

.fa-transgender {
  --fa: "\f225";
  --fa--fa: "\f225\f225"; }

.fa-transgender-alt {
  --fa: "\f225";
  --fa--fa: "\f225\f225"; }

.fa-mercury {
  --fa: "\f223";
  --fa--fa: "\f223\f223"; }

.fa-up-from-bracket {
  --fa: "\e590";
  --fa--fa: "\e590\e590"; }

.fa-knife-kitchen {
  --fa: "\f6f5";
  --fa--fa: "\f6f5\f6f5"; }

.fa-border-right {
  --fa: "\f852";
  --fa--fa: "\f852\f852"; }

.fa-arrow-turn-down {
  --fa: "\f149";
  --fa--fa: "\f149\f149"; }

.fa-level-down {
  --fa: "\f149";
  --fa--fa: "\f149\f149"; }

.fa-spade {
  --fa: "\f2f4";
  --fa--fa: "\f2f4\f2f4"; }

.fa-card-spade {
  --fa: "\e3ec";
  --fa--fa: "\e3ec\e3ec"; }

.fa-line-columns {
  --fa: "\f870";
  --fa--fa: "\f870\f870"; }

.fa-ant {
  --fa: "\e680";
  --fa--fa: "\e680\e680"; }

.fa-arrow-right-to-line {
  --fa: "\f340";
  --fa--fa: "\f340\f340"; }

.fa-arrow-to-right {
  --fa: "\f340";
  --fa--fa: "\f340\f340"; }

.fa-person-falling-burst {
  --fa: "\e547";
  --fa--fa: "\e547\e547"; }

.fa-flag-pennant {
  --fa: "\f456";
  --fa--fa: "\f456\f456"; }

.fa-pennant {
  --fa: "\f456";
  --fa--fa: "\f456\f456"; }

.fa-conveyor-belt-empty {
  --fa: "\e150";
  --fa--fa: "\e150\e150"; }

.fa-user-group-simple {
  --fa: "\e603";
  --fa--fa: "\e603\e603"; }

.fa-award {
  --fa: "\f559";
  --fa--fa: "\f559\f559"; }

.fa-ticket-simple {
  --fa: "\f3ff";
  --fa--fa: "\f3ff\f3ff"; }

.fa-ticket-alt {
  --fa: "\f3ff";
  --fa--fa: "\f3ff\f3ff"; }

.fa-building {
  --fa: "\f1ad";
  --fa--fa: "\f1ad\f1ad"; }

.fa-angles-left {
  --fa: "\f100";
  --fa--fa: "\f100\f100"; }

.fa-angle-double-left {
  --fa: "\f100";
  --fa--fa: "\f100\f100"; }

.fa-camcorder {
  --fa: "\f8a8";
  --fa--fa: "\f8a8\f8a8"; }

.fa-video-handheld {
  --fa: "\f8a8";
  --fa--fa: "\f8a8\f8a8"; }

.fa-pancakes {
  --fa: "\e42d";
  --fa--fa: "\e42d\e42d"; }

.fa-album-circle-user {
  --fa: "\e48d";
  --fa--fa: "\e48d\e48d"; }

.fa-subtitles-slash {
  --fa: "\e610";
  --fa--fa: "\e610\e610"; }

.fa-qrcode {
  --fa: "\f029";
  --fa--fa: "\f029\f029"; }

.fa-dice-d10 {
  --fa: "\f6cd";
  --fa--fa: "\f6cd\f6cd"; }

.fa-fireplace {
  --fa: "\f79a";
  --fa--fa: "\f79a\f79a"; }

.fa-browser {
  --fa: "\f37e";
  --fa--fa: "\f37e\f37e"; }

.fa-pen-paintbrush {
  --fa: "\f618";
  --fa--fa: "\f618\f618"; }

.fa-pencil-paintbrush {
  --fa: "\f618";
  --fa--fa: "\f618\f618"; }

.fa-fish-cooked {
  --fa: "\f7fe";
  --fa--fa: "\f7fe\f7fe"; }

.fa-chair-office {
  --fa: "\f6c1";
  --fa--fa: "\f6c1\f6c1"; }

.fa-magnifying-glass-music {
  --fa: "\e65f";
  --fa--fa: "\e65f\e65f"; }

.fa-nesting-dolls {
  --fa: "\e3ba";
  --fa--fa: "\e3ba\e3ba"; }

.fa-clock-rotate-left {
  --fa: "\f1da";
  --fa--fa: "\f1da\f1da"; }

.fa-history {
  --fa: "\f1da";
  --fa--fa: "\f1da\f1da"; }

.fa-trumpet {
  --fa: "\f8e3";
  --fa--fa: "\f8e3\f8e3"; }

.fa-face-grin-beam-sweat {
  --fa: "\f583";
  --fa--fa: "\f583\f583"; }

.fa-grin-beam-sweat {
  --fa: "\f583";
  --fa--fa: "\f583\f583"; }

.fa-fire-smoke {
  --fa: "\f74b";
  --fa--fa: "\f74b\f74b"; }

.fa-phone-missed {
  --fa: "\e226";
  --fa--fa: "\e226\e226"; }

.fa-file-export {
  --fa: "\f56e";
  --fa--fa: "\f56e\f56e"; }

.fa-arrow-right-from-file {
  --fa: "\f56e";
  --fa--fa: "\f56e\f56e"; }

.fa-shield {
  --fa: "\f132";
  --fa--fa: "\f132\f132"; }

.fa-shield-blank {
  --fa: "\f132";
  --fa--fa: "\f132\f132"; }

.fa-arrow-up-short-wide {
  --fa: "\f885";
  --fa--fa: "\f885\f885"; }

.fa-sort-amount-up-alt {
  --fa: "\f885";
  --fa--fa: "\f885\f885"; }

.fa-arrows-repeat-1 {
  --fa: "\f366";
  --fa--fa: "\f366\f366"; }

.fa-repeat-1-alt {
  --fa: "\f366";
  --fa--fa: "\f366\f366"; }

.fa-gun-slash {
  --fa: "\e19c";
  --fa--fa: "\e19c\e19c"; }

.fa-avocado {
  --fa: "\e0aa";
  --fa--fa: "\e0aa\e0aa"; }

.fa-binary {
  --fa: "\e33b";
  --fa--fa: "\e33b\e33b"; }

.fa-comment-nodes {
  --fa: "\e696";
  --fa--fa: "\e696\e696"; }

.fa-glasses-round {
  --fa: "\f5f5";
  --fa--fa: "\f5f5\f5f5"; }

.fa-glasses-alt {
  --fa: "\f5f5";
  --fa--fa: "\f5f5\f5f5"; }

.fa-phone-plus {
  --fa: "\f4d2";
  --fa--fa: "\f4d2\f4d2"; }

.fa-ditto {
  --fa: "\22";
  --fa--fa: "\22\22"; }

.fa-person-seat {
  --fa: "\e21e";
  --fa--fa: "\e21e\e21e"; }

.fa-house-medical {
  --fa: "\e3b2";
  --fa--fa: "\e3b2\e3b2"; }

.fa-golf-ball-tee {
  --fa: "\f450";
  --fa--fa: "\f450\f450"; }

.fa-golf-ball {
  --fa: "\f450";
  --fa--fa: "\f450\f450"; }

.fa-circle-chevron-left {
  --fa: "\f137";
  --fa--fa: "\f137\f137"; }

.fa-chevron-circle-left {
  --fa: "\f137";
  --fa--fa: "\f137\f137"; }

.fa-house-chimney-window {
  --fa: "\e00d";
  --fa--fa: "\e00d\e00d"; }

.fa-scythe {
  --fa: "\f710";
  --fa--fa: "\f710\f710"; }

.fa-pen-nib {
  --fa: "\f5ad";
  --fa--fa: "\f5ad\f5ad"; }

.fa-ban-parking {
  --fa: "\f616";
  --fa--fa: "\f616\f616"; }

.fa-parking-circle-slash {
  --fa: "\f616";
  --fa--fa: "\f616\f616"; }

.fa-tent-arrow-turn-left {
  --fa: "\e580";
  --fa--fa: "\e580\e580"; }

.fa-face-diagonal-mouth {
  --fa: "\e47e";
  --fa--fa: "\e47e\e47e"; }

.fa-diagram-cells {
  --fa: "\e475";
  --fa--fa: "\e475\e475"; }

.fa-cricket-bat-ball {
  --fa: "\f449";
  --fa--fa: "\f449\f449"; }

.fa-cricket {
  --fa: "\f449";
  --fa--fa: "\f449\f449"; }

.fa-tents {
  --fa: "\e582";
  --fa--fa: "\e582\e582"; }

.fa-wand-magic {
  --fa: "\f0d0";
  --fa--fa: "\f0d0\f0d0"; }

.fa-magic {
  --fa: "\f0d0";
  --fa--fa: "\f0d0\f0d0"; }

.fa-dog {
  --fa: "\f6d3";
  --fa--fa: "\f6d3\f6d3"; }

.fa-pen-line {
  --fa: "\e212";
  --fa--fa: "\e212\e212"; }

.fa-atom-simple {
  --fa: "\f5d3";
  --fa--fa: "\f5d3\f5d3"; }

.fa-atom-alt {
  --fa: "\f5d3";
  --fa--fa: "\f5d3\f5d3"; }

.fa-ampersand {
  --fa: "\26";
  --fa--fa: "\26\26"; }

.fa-carrot {
  --fa: "\f787";
  --fa--fa: "\f787\f787"; }

.fa-arrow-up-from-line {
  --fa: "\f342";
  --fa--fa: "\f342\f342"; }

.fa-arrow-from-bottom {
  --fa: "\f342";
  --fa--fa: "\f342\f342"; }

.fa-moon {
  --fa: "\f186";
  --fa--fa: "\f186\f186"; }

.fa-pen-slash {
  --fa: "\e213";
  --fa--fa: "\e213\e213"; }

.fa-wine-glass-empty {
  --fa: "\f5ce";
  --fa--fa: "\f5ce\f5ce"; }

.fa-wine-glass-alt {
  --fa: "\f5ce";
  --fa--fa: "\f5ce\f5ce"; }

.fa-square-star {
  --fa: "\e27f";
  --fa--fa: "\e27f\e27f"; }

.fa-cheese {
  --fa: "\f7ef";
  --fa--fa: "\f7ef\f7ef"; }

.fa-send-backward {
  --fa: "\f87f";
  --fa--fa: "\f87f\f87f"; }

.fa-yin-yang {
  --fa: "\f6ad";
  --fa--fa: "\f6ad\f6ad"; }

.fa-music {
  --fa: "\f001";
  --fa--fa: "\f001\f001"; }

.fa-compass-slash {
  --fa: "\f5e9";
  --fa--fa: "\f5e9\f5e9"; }

.fa-clock-one {
  --fa: "\e34e";
  --fa--fa: "\e34e\e34e"; }

.fa-file-music {
  --fa: "\f8b6";
  --fa--fa: "\f8b6\f8b6"; }

.fa-code-commit {
  --fa: "\f386";
  --fa--fa: "\f386\f386"; }

.fa-temperature-low {
  --fa: "\f76b";
  --fa--fa: "\f76b\f76b"; }

.fa-person-biking {
  --fa: "\f84a";
  --fa--fa: "\f84a\f84a"; }

.fa-biking {
  --fa: "\f84a";
  --fa--fa: "\f84a\f84a"; }

.fa-display-chart-up-circle-currency {
  --fa: "\e5e5";
  --fa--fa: "\e5e5\e5e5"; }

.fa-skeleton {
  --fa: "\f620";
  --fa--fa: "\f620\f620"; }

.fa-circle-g {
  --fa: "\e10f";
  --fa--fa: "\e10f\e10f"; }

.fa-circle-arrow-up-left {
  --fa: "\e0fb";
  --fa--fa: "\e0fb\e0fb"; }

.fa-coin-blank {
  --fa: "\e3fb";
  --fa--fa: "\e3fb\e3fb"; }

.fa-broom {
  --fa: "\f51a";
  --fa--fa: "\f51a\f51a"; }

.fa-vacuum {
  --fa: "\e04d";
  --fa--fa: "\e04d\e04d"; }

.fa-shield-heart {
  --fa: "\e574";
  --fa--fa: "\e574\e574"; }

.fa-card-heart {
  --fa: "\e3eb";
  --fa--fa: "\e3eb\e3eb"; }

.fa-lightbulb-cfl-on {
  --fa: "\e5a7";
  --fa--fa: "\e5a7\e5a7"; }

.fa-melon {
  --fa: "\e310";
  --fa--fa: "\e310\e310"; }

.fa-gopuram {
  --fa: "\f664";
  --fa--fa: "\f664\f664"; }

.fa-earth-oceania {
  --fa: "\e47b";
  --fa--fa: "\e47b\e47b"; }

.fa-globe-oceania {
  --fa: "\e47b";
  --fa--fa: "\e47b\e47b"; }

.fa-container-storage {
  --fa: "\f4b7";
  --fa--fa: "\f4b7\f4b7"; }

.fa-face-pouting {
  --fa: "\e387";
  --fa--fa: "\e387\e387"; }

.fa-square-xmark {
  --fa: "\f2d3";
  --fa--fa: "\f2d3\f2d3"; }

.fa-times-square {
  --fa: "\f2d3";
  --fa--fa: "\f2d3\f2d3"; }

.fa-xmark-square {
  --fa: "\f2d3";
  --fa--fa: "\f2d3\f2d3"; }

.fa-face-explode {
  --fa: "\e2fe";
  --fa--fa: "\e2fe\e2fe"; }

.fa-exploding-head {
  --fa: "\e2fe";
  --fa--fa: "\e2fe\e2fe"; }

.fa-hashtag {
  --fa: "\23";
  --fa--fa: "\23\23"; }

.fa-up-right-and-down-left-from-center {
  --fa: "\f424";
  --fa--fa: "\f424\f424"; }

.fa-expand-alt {
  --fa: "\f424";
  --fa--fa: "\f424\f424"; }

.fa-oil-can {
  --fa: "\f613";
  --fa--fa: "\f613\f613"; }

.fa-t {
  --fa: "\54";
  --fa--fa: "\54\54"; }

.fa-transformer-bolt {
  --fa: "\e2a4";
  --fa--fa: "\e2a4\e2a4"; }

.fa-hippo {
  --fa: "\f6ed";
  --fa--fa: "\f6ed\f6ed"; }

.fa-chart-column {
  --fa: "\e0e3";
  --fa--fa: "\e0e3\e0e3"; }

.fa-cassette-vhs {
  --fa: "\f8ec";
  --fa--fa: "\f8ec\f8ec"; }

.fa-vhs {
  --fa: "\f8ec";
  --fa--fa: "\f8ec\f8ec"; }

.fa-infinity {
  --fa: "\f534";
  --fa--fa: "\f534\f534"; }

.fa-vial-circle-check {
  --fa: "\e596";
  --fa--fa: "\e596\e596"; }

.fa-chimney {
  --fa: "\f78b";
  --fa--fa: "\f78b\f78b"; }

.fa-object-intersect {
  --fa: "\e49d";
  --fa--fa: "\e49d\e49d"; }

.fa-person-arrow-down-to-line {
  --fa: "\e538";
  --fa--fa: "\e538\e538"; }

.fa-voicemail {
  --fa: "\f897";
  --fa--fa: "\f897\f897"; }

.fa-block-brick {
  --fa: "\e3db";
  --fa--fa: "\e3db\e3db"; }

.fa-wall-brick {
  --fa: "\e3db";
  --fa--fa: "\e3db\e3db"; }

.fa-fan {
  --fa: "\f863";
  --fa--fa: "\f863\f863"; }

.fa-bags-shopping {
  --fa: "\f847";
  --fa--fa: "\f847\f847"; }

.fa-paragraph-left {
  --fa: "\f878";
  --fa--fa: "\f878\f878"; }

.fa-paragraph-rtl {
  --fa: "\f878";
  --fa--fa: "\f878\f878"; }

.fa-person-walking-luggage {
  --fa: "\e554";
  --fa--fa: "\e554\e554"; }

.fa-caravan-simple {
  --fa: "\e000";
  --fa--fa: "\e000\e000"; }

.fa-caravan-alt {
  --fa: "\e000";
  --fa--fa: "\e000\e000"; }

.fa-turtle {
  --fa: "\f726";
  --fa--fa: "\f726\f726"; }

.fa-pencil-mechanical {
  --fa: "\e5ca";
  --fa--fa: "\e5ca\e5ca"; }

.fa-up-down {
  --fa: "\f338";
  --fa--fa: "\f338\f338"; }

.fa-arrows-alt-v {
  --fa: "\f338";
  --fa--fa: "\f338\f338"; }

.fa-cloud-moon-rain {
  --fa: "\f73c";
  --fa--fa: "\f73c\f73c"; }

.fa-booth-curtain {
  --fa: "\f734";
  --fa--fa: "\f734\f734"; }

.fa-calendar {
  --fa: "\f133";
  --fa--fa: "\f133\f133"; }

.fa-box-heart {
  --fa: "\f49d";
  --fa--fa: "\f49d\f49d"; }

.fa-trailer {
  --fa: "\e041";
  --fa--fa: "\e041\e041"; }

.fa-user-doctor-message {
  --fa: "\f82e";
  --fa--fa: "\f82e\f82e"; }

.fa-user-md-chat {
  --fa: "\f82e";
  --fa--fa: "\f82e\f82e"; }

.fa-bahai {
  --fa: "\f666";
  --fa--fa: "\f666\f666"; }

.fa-haykal {
  --fa: "\f666";
  --fa--fa: "\f666\f666"; }

.fa-lighthouse {
  --fa: "\e612";
  --fa--fa: "\e612\e612"; }

.fa-amp-guitar {
  --fa: "\f8a1";
  --fa--fa: "\f8a1\f8a1"; }

.fa-sd-card {
  --fa: "\f7c2";
  --fa--fa: "\f7c2\f7c2"; }

.fa-volume-slash {
  --fa: "\f2e2";
  --fa--fa: "\f2e2\f2e2"; }

.fa-border-bottom {
  --fa: "\f84d";
  --fa--fa: "\f84d\f84d"; }

.fa-wifi-weak {
  --fa: "\f6aa";
  --fa--fa: "\f6aa\f6aa"; }

.fa-wifi-1 {
  --fa: "\f6aa";
  --fa--fa: "\f6aa\f6aa"; }

.fa-dragon {
  --fa: "\f6d5";
  --fa--fa: "\f6d5\f6d5"; }

.fa-shoe-prints {
  --fa: "\f54b";
  --fa--fa: "\f54b\f54b"; }

.fa-circle-plus {
  --fa: "\f055";
  --fa--fa: "\f055\f055"; }

.fa-plus-circle {
  --fa: "\f055";
  --fa--fa: "\f055\f055"; }

.fa-face-grin-tongue-wink {
  --fa: "\f58b";
  --fa--fa: "\f58b\f58b"; }

.fa-grin-tongue-wink {
  --fa: "\f58b";
  --fa--fa: "\f58b\f58b"; }

.fa-hand-holding {
  --fa: "\f4bd";
  --fa--fa: "\f4bd\f4bd"; }

.fa-plug-circle-exclamation {
  --fa: "\e55d";
  --fa--fa: "\e55d\e55d"; }

.fa-link-slash {
  --fa: "\f127";
  --fa--fa: "\f127\f127"; }

.fa-chain-broken {
  --fa: "\f127";
  --fa--fa: "\f127\f127"; }

.fa-chain-slash {
  --fa: "\f127";
  --fa--fa: "\f127\f127"; }

.fa-unlink {
  --fa: "\f127";
  --fa--fa: "\f127\f127"; }

.fa-clone {
  --fa: "\f24d";
  --fa--fa: "\f24d\f24d"; }

.fa-person-walking-arrow-loop-left {
  --fa: "\e551";
  --fa--fa: "\e551\e551"; }

.fa-arrow-up-z-a {
  --fa: "\f882";
  --fa--fa: "\f882\f882"; }

.fa-sort-alpha-up-alt {
  --fa: "\f882";
  --fa--fa: "\f882\f882"; }

.fa-fire-flame-curved {
  --fa: "\f7e4";
  --fa--fa: "\f7e4\f7e4"; }

.fa-fire-alt {
  --fa: "\f7e4";
  --fa--fa: "\f7e4\f7e4"; }

.fa-tornado {
  --fa: "\f76f";
  --fa--fa: "\f76f\f76f"; }

.fa-file-circle-plus {
  --fa: "\e494";
  --fa--fa: "\e494\e494"; }

.fa-delete-right {
  --fa: "\e154";
  --fa--fa: "\e154\e154"; }

.fa-book-quran {
  --fa: "\f687";
  --fa--fa: "\f687\f687"; }

.fa-quran {
  --fa: "\f687";
  --fa--fa: "\f687\f687"; }

.fa-circle-quarter {
  --fa: "\e11f";
  --fa--fa: "\e11f\e11f"; }

.fa-anchor {
  --fa: "\f13d";
  --fa--fa: "\f13d\f13d"; }

.fa-border-all {
  --fa: "\f84c";
  --fa--fa: "\f84c\f84c"; }

.fa-function {
  --fa: "\f661";
  --fa--fa: "\f661\f661"; }

.fa-face-angry {
  --fa: "\f556";
  --fa--fa: "\f556\f556"; }

.fa-angry {
  --fa: "\f556";
  --fa--fa: "\f556\f556"; }

.fa-people-simple {
  --fa: "\e21b";
  --fa--fa: "\e21b\e21b"; }

.fa-cookie-bite {
  --fa: "\f564";
  --fa--fa: "\f564\f564"; }

.fa-arrow-trend-down {
  --fa: "\e097";
  --fa--fa: "\e097\e097"; }

.fa-rss {
  --fa: "\f09e";
  --fa--fa: "\f09e\f09e"; }

.fa-feed {
  --fa: "\f09e";
  --fa--fa: "\f09e\f09e"; }

.fa-face-monocle {
  --fa: "\e380";
  --fa--fa: "\e380\e380"; }

.fa-draw-polygon {
  --fa: "\f5ee";
  --fa--fa: "\f5ee\f5ee"; }

.fa-scale-balanced {
  --fa: "\f24e";
  --fa--fa: "\f24e\f24e"; }

.fa-balance-scale {
  --fa: "\f24e";
  --fa--fa: "\f24e\f24e"; }

.fa-calendar-lines {
  --fa: "\e0d5";
  --fa--fa: "\e0d5\e0d5"; }

.fa-calendar-note {
  --fa: "\e0d5";
  --fa--fa: "\e0d5\e0d5"; }

.fa-arrow-down-big-small {
  --fa: "\f88c";
  --fa--fa: "\f88c\f88c"; }

.fa-sort-size-down {
  --fa: "\f88c";
  --fa--fa: "\f88c\f88c"; }

.fa-gauge-simple-high {
  --fa: "\f62a";
  --fa--fa: "\f62a\f62a"; }

.fa-tachometer {
  --fa: "\f62a";
  --fa--fa: "\f62a\f62a"; }

.fa-tachometer-fast {
  --fa: "\f62a";
  --fa--fa: "\f62a\f62a"; }

.fa-do-not-enter {
  --fa: "\f5ec";
  --fa--fa: "\f5ec\f5ec"; }

.fa-shower {
  --fa: "\f2cc";
  --fa--fa: "\f2cc\f2cc"; }

.fa-dice-d8 {
  --fa: "\f6d2";
  --fa--fa: "\f6d2\f6d2"; }

.fa-desktop {
  --fa: "\f390";
  --fa--fa: "\f390\f390"; }

.fa-desktop-alt {
  --fa: "\f390";
  --fa--fa: "\f390\f390"; }

.fa-m {
  --fa: "\4d";
  --fa--fa: "\4d\4d"; }

.fa-spinner-scale {
  --fa: "\e62a";
  --fa--fa: "\e62a\e62a"; }

.fa-grip-dots-vertical {
  --fa: "\e411";
  --fa--fa: "\e411\e411"; }

.fa-face-viewfinder {
  --fa: "\e2ff";
  --fa--fa: "\e2ff\e2ff"; }

.fa-soft-serve {
  --fa: "\e400";
  --fa--fa: "\e400\e400"; }

.fa-creemee {
  --fa: "\e400";
  --fa--fa: "\e400\e400"; }

.fa-h5 {
  --fa: "\e412";
  --fa--fa: "\e412\e412"; }

.fa-hand-back-point-down {
  --fa: "\e19e";
  --fa--fa: "\e19e\e19e"; }

.fa-table-list {
  --fa: "\f00b";
  --fa--fa: "\f00b\f00b"; }

.fa-th-list {
  --fa: "\f00b";
  --fa--fa: "\f00b\f00b"; }

.fa-basket-shopping-minus {
  --fa: "\e652";
  --fa--fa: "\e652\e652"; }

.fa-comment-sms {
  --fa: "\f7cd";
  --fa--fa: "\f7cd\f7cd"; }

.fa-sms {
  --fa: "\f7cd";
  --fa--fa: "\f7cd\f7cd"; }

.fa-rectangle {
  --fa: "\f2fa";
  --fa--fa: "\f2fa\f2fa"; }

.fa-rectangle-landscape {
  --fa: "\f2fa";
  --fa--fa: "\f2fa\f2fa"; }

.fa-clipboard-list-check {
  --fa: "\f737";
  --fa--fa: "\f737\f737"; }

.fa-turkey {
  --fa: "\f725";
  --fa--fa: "\f725\f725"; }

.fa-book {
  --fa: "\f02d";
  --fa--fa: "\f02d\f02d"; }

.fa-user-plus {
  --fa: "\f234";
  --fa--fa: "\f234\f234"; }

.fa-ice-skate {
  --fa: "\f7ac";
  --fa--fa: "\f7ac\f7ac"; }

.fa-check {
  --fa: "\f00c";
  --fa--fa: "\f00c\f00c"; }

.fa-battery-three-quarters {
  --fa: "\f241";
  --fa--fa: "\f241\f241"; }

.fa-battery-4 {
  --fa: "\f241";
  --fa--fa: "\f241\f241"; }

.fa-tomato {
  --fa: "\e330";
  --fa--fa: "\e330\e330"; }

.fa-sword-laser {
  --fa: "\e03b";
  --fa--fa: "\e03b\e03b"; }

.fa-house-circle-check {
  --fa: "\e509";
  --fa--fa: "\e509\e509"; }

.fa-buildings {
  --fa: "\e0cc";
  --fa--fa: "\e0cc\e0cc"; }

.fa-angle-left {
  --fa: "\f104";
  --fa--fa: "\f104\f104"; }

.fa-cart-flatbed-boxes {
  --fa: "\f475";
  --fa--fa: "\f475\f475"; }

.fa-dolly-flatbed-alt {
  --fa: "\f475";
  --fa--fa: "\f475\f475"; }

.fa-diagram-successor {
  --fa: "\e47a";
  --fa--fa: "\e47a\e47a"; }

.fa-truck-arrow-right {
  --fa: "\e58b";
  --fa--fa: "\e58b\e58b"; }

.fa-square-w {
  --fa: "\e285";
  --fa--fa: "\e285\e285"; }

.fa-arrows-split-up-and-left {
  --fa: "\e4bc";
  --fa--fa: "\e4bc\e4bc"; }

.fa-lamp {
  --fa: "\f4ca";
  --fa--fa: "\f4ca\f4ca"; }

.fa-airplay {
  --fa: "\e089";
  --fa--fa: "\e089\e089"; }

.fa-hand-fist {
  --fa: "\f6de";
  --fa--fa: "\f6de\f6de"; }

.fa-fist-raised {
  --fa: "\f6de";
  --fa--fa: "\f6de\f6de"; }

.fa-shield-quartered {
  --fa: "\e575";
  --fa--fa: "\e575\e575"; }

.fa-slash-forward {
  --fa: "\2f";
  --fa--fa: "\2f\2f"; }

.fa-location-pen {
  --fa: "\f607";
  --fa--fa: "\f607\f607"; }

.fa-map-marker-edit {
  --fa: "\f607";
  --fa--fa: "\f607\f607"; }

.fa-cloud-moon {
  --fa: "\f6c3";
  --fa--fa: "\f6c3\f6c3"; }

.fa-pot-food {
  --fa: "\e43f";
  --fa--fa: "\e43f\e43f"; }

.fa-briefcase {
  --fa: "\f0b1";
  --fa--fa: "\f0b1\f0b1"; }

.fa-person-falling {
  --fa: "\e546";
  --fa--fa: "\e546\e546"; }

.fa-image-portrait {
  --fa: "\f3e0";
  --fa--fa: "\f3e0\f3e0"; }

.fa-portrait {
  --fa: "\f3e0";
  --fa--fa: "\f3e0\f3e0"; }

.fa-user-tag {
  --fa: "\f507";
  --fa--fa: "\f507\f507"; }

.fa-rug {
  --fa: "\e569";
  --fa--fa: "\e569\e569"; }

.fa-print-slash {
  --fa: "\f686";
  --fa--fa: "\f686\f686"; }

.fa-earth-europe {
  --fa: "\f7a2";
  --fa--fa: "\f7a2\f7a2"; }

.fa-globe-europe {
  --fa: "\f7a2";
  --fa--fa: "\f7a2\f7a2"; }

.fa-cart-flatbed-suitcase {
  --fa: "\f59d";
  --fa--fa: "\f59d\f59d"; }

.fa-luggage-cart {
  --fa: "\f59d";
  --fa--fa: "\f59d\f59d"; }

.fa-hand-back-point-ribbon {
  --fa: "\e1a0";
  --fa--fa: "\e1a0\e1a0"; }

.fa-rectangle-xmark {
  --fa: "\f410";
  --fa--fa: "\f410\f410"; }

.fa-rectangle-times {
  --fa: "\f410";
  --fa--fa: "\f410\f410"; }

.fa-times-rectangle {
  --fa: "\f410";
  --fa--fa: "\f410\f410"; }

.fa-window-close {
  --fa: "\f410";
  --fa--fa: "\f410\f410"; }

.fa-tire-rugged {
  --fa: "\f634";
  --fa--fa: "\f634\f634"; }

.fa-lightbulb-dollar {
  --fa: "\f670";
  --fa--fa: "\f670\f670"; }

.fa-cowbell {
  --fa: "\f8b3";
  --fa--fa: "\f8b3\f8b3"; }

.fa-baht-sign {
  --fa: "\e0ac";
  --fa--fa: "\e0ac\e0ac"; }

.fa-corner {
  --fa: "\e3fe";
  --fa--fa: "\e3fe\e3fe"; }

.fa-chevrons-right {
  --fa: "\f324";
  --fa--fa: "\f324\f324"; }

.fa-chevron-double-right {
  --fa: "\f324";
  --fa--fa: "\f324\f324"; }

.fa-book-open {
  --fa: "\f518";
  --fa--fa: "\f518\f518"; }

.fa-book-journal-whills {
  --fa: "\f66a";
  --fa--fa: "\f66a\f66a"; }

.fa-journal-whills {
  --fa: "\f66a";
  --fa--fa: "\f66a\f66a"; }

.fa-inhaler {
  --fa: "\f5f9";
  --fa--fa: "\f5f9\f5f9"; }

.fa-handcuffs {
  --fa: "\e4f8";
  --fa--fa: "\e4f8\e4f8"; }

.fa-snake {
  --fa: "\f716";
  --fa--fa: "\f716\f716"; }

.fa-triangle-exclamation {
  --fa: "\f071";
  --fa--fa: "\f071\f071"; }

.fa-exclamation-triangle {
  --fa: "\f071";
  --fa--fa: "\f071\f071"; }

.fa-warning {
  --fa: "\f071";
  --fa--fa: "\f071\f071"; }

.fa-note-medical {
  --fa: "\e200";
  --fa--fa: "\e200\e200"; }

.fa-database {
  --fa: "\f1c0";
  --fa--fa: "\f1c0\f1c0"; }

.fa-down-left {
  --fa: "\e16a";
  --fa--fa: "\e16a\e16a"; }

.fa-share {
  --fa: "\f064";
  --fa--fa: "\f064\f064"; }

.fa-mail-forward {
  --fa: "\f064";
  --fa--fa: "\f064\f064"; }

.fa-face-thinking {
  --fa: "\e39b";
  --fa--fa: "\e39b\e39b"; }

.fa-turn-down-right {
  --fa: "\e455";
  --fa--fa: "\e455\e455"; }

.fa-bottle-droplet {
  --fa: "\e4c4";
  --fa--fa: "\e4c4\e4c4"; }

.fa-mask-face {
  --fa: "\e1d7";
  --fa--fa: "\e1d7\e1d7"; }

.fa-hill-rockslide {
  --fa: "\e508";
  --fa--fa: "\e508\e508"; }

.fa-scanner-keyboard {
  --fa: "\f489";
  --fa--fa: "\f489\f489"; }

.fa-circle-o {
  --fa: "\e119";
  --fa--fa: "\e119\e119"; }

.fa-grid-horizontal {
  --fa: "\e307";
  --fa--fa: "\e307\e307"; }

.fa-message-dollar {
  --fa: "\f650";
  --fa--fa: "\f650\f650"; }

.fa-comment-alt-dollar {
  --fa: "\f650";
  --fa--fa: "\f650\f650"; }

.fa-right-left {
  --fa: "\f362";
  --fa--fa: "\f362\f362"; }

.fa-exchange-alt {
  --fa: "\f362";
  --fa--fa: "\f362\f362"; }

.fa-columns-3 {
  --fa: "\e361";
  --fa--fa: "\e361\e361"; }

.fa-paper-plane {
  --fa: "\f1d8";
  --fa--fa: "\f1d8\f1d8"; }

.fa-road-circle-exclamation {
  --fa: "\e565";
  --fa--fa: "\e565\e565"; }

.fa-dungeon {
  --fa: "\f6d9";
  --fa--fa: "\f6d9\f6d9"; }

.fa-hand-holding-box {
  --fa: "\f47b";
  --fa--fa: "\f47b\f47b"; }

.fa-input-text {
  --fa: "\e1bf";
  --fa--fa: "\e1bf\e1bf"; }

.fa-window-flip {
  --fa: "\f40f";
  --fa--fa: "\f40f\f40f"; }

.fa-window-alt {
  --fa: "\f40f";
  --fa--fa: "\f40f\f40f"; }

.fa-align-right {
  --fa: "\f038";
  --fa--fa: "\f038\f038"; }

.fa-scanner-gun {
  --fa: "\f488";
  --fa--fa: "\f488\f488"; }

.fa-scanner {
  --fa: "\f488";
  --fa--fa: "\f488\f488"; }

.fa-tire {
  --fa: "\f631";
  --fa--fa: "\f631\f631"; }

.fa-engine {
  --fa: "\e16e";
  --fa--fa: "\e16e\e16e"; }

.fa-money-bill-1-wave {
  --fa: "\f53b";
  --fa--fa: "\f53b\f53b"; }

.fa-money-bill-wave-alt {
  --fa: "\f53b";
  --fa--fa: "\f53b\f53b"; }

.fa-life-ring {
  --fa: "\f1cd";
  --fa--fa: "\f1cd\f1cd"; }

.fa-hands {
  --fa: "\f2a7";
  --fa--fa: "\f2a7\f2a7"; }

.fa-sign-language {
  --fa: "\f2a7";
  --fa--fa: "\f2a7\f2a7"; }

.fa-signing {
  --fa: "\f2a7";
  --fa--fa: "\f2a7\f2a7"; }

.fa-circle-caret-right {
  --fa: "\f330";
  --fa--fa: "\f330\f330"; }

.fa-caret-circle-right {
  --fa: "\f330";
  --fa--fa: "\f330\f330"; }

.fa-turn-left {
  --fa: "\e636";
  --fa--fa: "\e636\e636"; }

.fa-wheat {
  --fa: "\f72d";
  --fa--fa: "\f72d\f72d"; }

.fa-file-spreadsheet {
  --fa: "\f65b";
  --fa--fa: "\f65b\f65b"; }

.fa-audio-description-slash {
  --fa: "\e0a8";
  --fa--fa: "\e0a8\e0a8"; }

.fa-bell-ring {
  --fa: "\e62c";
  --fa--fa: "\e62c\e62c"; }

.fa-calendar-day {
  --fa: "\f783";
  --fa--fa: "\f783\f783"; }

.fa-water-ladder {
  --fa: "\f5c5";
  --fa--fa: "\f5c5\f5c5"; }

.fa-ladder-water {
  --fa: "\f5c5";
  --fa--fa: "\f5c5\f5c5"; }

.fa-swimming-pool {
  --fa: "\f5c5";
  --fa--fa: "\f5c5\f5c5"; }

.fa-arrows-up-down {
  --fa: "\f07d";
  --fa--fa: "\f07d\f07d"; }

.fa-arrows-v {
  --fa: "\f07d";
  --fa--fa: "\f07d\f07d"; }

.fa-chess-pawn-piece {
  --fa: "\f444";
  --fa--fa: "\f444\f444"; }

.fa-chess-pawn-alt {
  --fa: "\f444";
  --fa--fa: "\f444\f444"; }

.fa-face-grimace {
  --fa: "\f57f";
  --fa--fa: "\f57f\f57f"; }

.fa-grimace {
  --fa: "\f57f";
  --fa--fa: "\f57f\f57f"; }

.fa-wheelchair-move {
  --fa: "\e2ce";
  --fa--fa: "\e2ce\e2ce"; }

.fa-wheelchair-alt {
  --fa: "\e2ce";
  --fa--fa: "\e2ce\e2ce"; }

.fa-turn-down {
  --fa: "\f3be";
  --fa--fa: "\f3be\f3be"; }

.fa-level-down-alt {
  --fa: "\f3be";
  --fa--fa: "\f3be\f3be"; }

.fa-square-s {
  --fa: "\e27d";
  --fa--fa: "\e27d\e27d"; }

.fa-rectangle-barcode {
  --fa: "\f463";
  --fa--fa: "\f463\f463"; }

.fa-barcode-alt {
  --fa: "\f463";
  --fa--fa: "\f463\f463"; }

.fa-person-walking-arrow-right {
  --fa: "\e552";
  --fa--fa: "\e552\e552"; }

.fa-square-envelope {
  --fa: "\f199";
  --fa--fa: "\f199\f199"; }

.fa-envelope-square {
  --fa: "\f199";
  --fa--fa: "\f199\f199"; }

.fa-dice {
  --fa: "\f522";
  --fa--fa: "\f522\f522"; }

.fa-unicorn {
  --fa: "\f727";
  --fa--fa: "\f727\f727"; }

.fa-bowling-ball {
  --fa: "\f436";
  --fa--fa: "\f436\f436"; }

.fa-pompebled {
  --fa: "\e43d";
  --fa--fa: "\e43d\e43d"; }

.fa-brain {
  --fa: "\f5dc";
  --fa--fa: "\f5dc\f5dc"; }

.fa-watch-smart {
  --fa: "\e2cc";
  --fa--fa: "\e2cc\e2cc"; }

.fa-book-user {
  --fa: "\f7e7";
  --fa--fa: "\f7e7\f7e7"; }

.fa-sensor-cloud {
  --fa: "\e02c";
  --fa--fa: "\e02c\e02c"; }

.fa-sensor-smoke {
  --fa: "\e02c";
  --fa--fa: "\e02c\e02c"; }

.fa-clapperboard-play {
  --fa: "\e132";
  --fa--fa: "\e132\e132"; }

.fa-bandage {
  --fa: "\f462";
  --fa--fa: "\f462\f462"; }

.fa-band-aid {
  --fa: "\f462";
  --fa--fa: "\f462\f462"; }

.fa-calendar-minus {
  --fa: "\f272";
  --fa--fa: "\f272\f272"; }

.fa-circle-xmark {
  --fa: "\f057";
  --fa--fa: "\f057\f057"; }

.fa-times-circle {
  --fa: "\f057";
  --fa--fa: "\f057\f057"; }

.fa-xmark-circle {
  --fa: "\f057";
  --fa--fa: "\f057\f057"; }

.fa-circle-4 {
  --fa: "\e0f1";
  --fa--fa: "\e0f1\e0f1"; }

.fa-gifts {
  --fa: "\f79c";
  --fa--fa: "\f79c\f79c"; }

.fa-album-collection {
  --fa: "\f8a0";
  --fa--fa: "\f8a0\f8a0"; }

.fa-hotel {
  --fa: "\f594";
  --fa--fa: "\f594\f594"; }

.fa-earth-asia {
  --fa: "\f57e";
  --fa--fa: "\f57e\f57e"; }

.fa-globe-asia {
  --fa: "\f57e";
  --fa--fa: "\f57e\f57e"; }

.fa-id-card-clip {
  --fa: "\f47f";
  --fa--fa: "\f47f\f47f"; }

.fa-id-card-alt {
  --fa: "\f47f";
  --fa--fa: "\f47f\f47f"; }

.fa-magnifying-glass-plus {
  --fa: "\f00e";
  --fa--fa: "\f00e\f00e"; }

.fa-search-plus {
  --fa: "\f00e";
  --fa--fa: "\f00e\f00e"; }

.fa-thumbs-up {
  --fa: "\f164";
  --fa--fa: "\f164\f164"; }

.fa-cloud-showers {
  --fa: "\f73f";
  --fa--fa: "\f73f\f73f"; }

.fa-user-clock {
  --fa: "\f4fd";
  --fa--fa: "\f4fd\f4fd"; }

.fa-onion {
  --fa: "\e427";
  --fa--fa: "\e427\e427"; }

.fa-clock-twelve-thirty {
  --fa: "\e359";
  --fa--fa: "\e359\e359"; }

.fa-arrow-down-to-dotted-line {
  --fa: "\e095";
  --fa--fa: "\e095\e095"; }

.fa-hand-dots {
  --fa: "\f461";
  --fa--fa: "\f461\f461"; }

.fa-allergies {
  --fa: "\f461";
  --fa--fa: "\f461\f461"; }

.fa-file-invoice {
  --fa: "\f570";
  --fa--fa: "\f570\f570"; }

.fa-window-minimize {
  --fa: "\f2d1";
  --fa--fa: "\f2d1\f2d1"; }

.fa-rectangle-wide {
  --fa: "\f2fc";
  --fa--fa: "\f2fc\f2fc"; }

.fa-comment-arrow-up {
  --fa: "\e144";
  --fa--fa: "\e144\e144"; }

.fa-garlic {
  --fa: "\e40e";
  --fa--fa: "\e40e\e40e"; }

.fa-mug-saucer {
  --fa: "\f0f4";
  --fa--fa: "\f0f4\f0f4"; }

.fa-coffee {
  --fa: "\f0f4";
  --fa--fa: "\f0f4\f0f4"; }

.fa-brush {
  --fa: "\f55d";
  --fa--fa: "\f55d\f55d"; }

.fa-file-half-dashed {
  --fa: "\e698";
  --fa--fa: "\e698\e698"; }

.fa-tree-decorated {
  --fa: "\f7dc";
  --fa--fa: "\f7dc\f7dc"; }

.fa-mask {
  --fa: "\f6fa";
  --fa--fa: "\f6fa\f6fa"; }

.fa-calendar-heart {
  --fa: "\e0d3";
  --fa--fa: "\e0d3\e0d3"; }

.fa-magnifying-glass-minus {
  --fa: "\f010";
  --fa--fa: "\f010\f010"; }

.fa-search-minus {
  --fa: "\f010";
  --fa--fa: "\f010\f010"; }

.fa-flower {
  --fa: "\f7ff";
  --fa--fa: "\f7ff\f7ff"; }

.fa-arrow-down-from-arc {
  --fa: "\e614";
  --fa--fa: "\e614\e614"; }

.fa-right-left-large {
  --fa: "\e5e1";
  --fa--fa: "\e5e1\e5e1"; }

.fa-ruler-vertical {
  --fa: "\f548";
  --fa--fa: "\f548\f548"; }

.fa-circles-overlap {
  --fa: "\e600";
  --fa--fa: "\e600\e600"; }

.fa-user-large {
  --fa: "\f406";
  --fa--fa: "\f406\f406"; }

.fa-user-alt {
  --fa: "\f406";
  --fa--fa: "\f406\f406"; }

.fa-starship-freighter {
  --fa: "\e03a";
  --fa--fa: "\e03a\e03a"; }

.fa-train-tram {
  --fa: "\e5b4";
  --fa--fa: "\e5b4\e5b4"; }

.fa-bridge-suspension {
  --fa: "\e4cd";
  --fa--fa: "\e4cd\e4cd"; }

.fa-trash-check {
  --fa: "\e2af";
  --fa--fa: "\e2af\e2af"; }

.fa-user-nurse {
  --fa: "\f82f";
  --fa--fa: "\f82f\f82f"; }

.fa-boombox {
  --fa: "\f8a5";
  --fa--fa: "\f8a5\f8a5"; }

.fa-syringe {
  --fa: "\f48e";
  --fa--fa: "\f48e\f48e"; }

.fa-cloud-sun {
  --fa: "\f6c4";
  --fa--fa: "\f6c4\f6c4"; }

.fa-shield-exclamation {
  --fa: "\e247";
  --fa--fa: "\e247\e247"; }

.fa-stopwatch-20 {
  --fa: "\e06f";
  --fa--fa: "\e06f\e06f"; }

.fa-square-full {
  --fa: "\f45c";
  --fa--fa: "\f45c\f45c"; }

.fa-grip-dots {
  --fa: "\e410";
  --fa--fa: "\e410\e410"; }

.fa-comment-exclamation {
  --fa: "\f4af";
  --fa--fa: "\f4af\f4af"; }

.fa-pen-swirl {
  --fa: "\e214";
  --fa--fa: "\e214\e214"; }

.fa-falafel {
  --fa: "\e40a";
  --fa--fa: "\e40a\e40a"; }

.fa-circle-2 {
  --fa: "\e0ef";
  --fa--fa: "\e0ef\e0ef"; }

.fa-magnet {
  --fa: "\f076";
  --fa--fa: "\f076\f076"; }

.fa-jar {
  --fa: "\e516";
  --fa--fa: "\e516\e516"; }

.fa-gramophone {
  --fa: "\f8bd";
  --fa--fa: "\f8bd\f8bd"; }

.fa-dice-d12 {
  --fa: "\f6ce";
  --fa--fa: "\f6ce\f6ce"; }

.fa-note-sticky {
  --fa: "\f249";
  --fa--fa: "\f249\f249"; }

.fa-sticky-note {
  --fa: "\f249";
  --fa--fa: "\f249\f249"; }

.fa-down {
  --fa: "\f354";
  --fa--fa: "\f354\f354"; }

.fa-arrow-alt-down {
  --fa: "\f354";
  --fa--fa: "\f354\f354"; }

.fa-hundred-points {
  --fa: "\e41c";
  --fa--fa: "\e41c\e41c"; }

.fa-100 {
  --fa: "\e41c";
  --fa--fa: "\e41c\e41c"; }

.fa-paperclip-vertical {
  --fa: "\e3c2";
  --fa--fa: "\e3c2\e3c2"; }

.fa-wind-warning {
  --fa: "\f776";
  --fa--fa: "\f776\f776"; }

.fa-wind-circle-exclamation {
  --fa: "\f776";
  --fa--fa: "\f776\f776"; }

.fa-location-pin-slash {
  --fa: "\f60c";
  --fa--fa: "\f60c\f60c"; }

.fa-map-marker-slash {
  --fa: "\f60c";
  --fa--fa: "\f60c\f60c"; }

.fa-face-sad-sweat {
  --fa: "\e38a";
  --fa--fa: "\e38a\e38a"; }

.fa-bug-slash {
  --fa: "\e490";
  --fa--fa: "\e490\e490"; }

.fa-cupcake {
  --fa: "\e402";
  --fa--fa: "\e402\e402"; }

.fa-light-switch-off {
  --fa: "\e018";
  --fa--fa: "\e018\e018"; }

.fa-toggle-large-off {
  --fa: "\e5b0";
  --fa--fa: "\e5b0\e5b0"; }

.fa-pen-fancy-slash {
  --fa: "\e210";
  --fa--fa: "\e210\e210"; }

.fa-truck-container {
  --fa: "\f4dc";
  --fa--fa: "\f4dc\f4dc"; }

.fa-boot {
  --fa: "\f782";
  --fa--fa: "\f782\f782"; }

.fa-arrow-up-from-water-pump {
  --fa: "\e4b6";
  --fa--fa: "\e4b6\e4b6"; }

.fa-file-check {
  --fa: "\f316";
  --fa--fa: "\f316\f316"; }

.fa-bone {
  --fa: "\f5d7";
  --fa--fa: "\f5d7\f5d7"; }

.fa-cards-blank {
  --fa: "\e4df";
  --fa--fa: "\e4df\e4df"; }

.fa-circle-3 {
  --fa: "\e0f0";
  --fa--fa: "\e0f0\e0f0"; }

.fa-bench-tree {
  --fa: "\e2e7";
  --fa--fa: "\e2e7\e2e7"; }

.fa-keyboard-brightness-low {
  --fa: "\e1c1";
  --fa--fa: "\e1c1\e1c1"; }

.fa-ski-boot-ski {
  --fa: "\e3cd";
  --fa--fa: "\e3cd\e3cd"; }

.fa-brain-circuit {
  --fa: "\e0c6";
  --fa--fa: "\e0c6\e0c6"; }

.fa-table-cells-row-unlock {
  --fa: "\e691";
  --fa--fa: "\e691\e691"; }

.fa-user-injured {
  --fa: "\f728";
  --fa--fa: "\f728\f728"; }

.fa-block-brick-fire {
  --fa: "\e3dc";
  --fa--fa: "\e3dc\e3dc"; }

.fa-firewall {
  --fa: "\e3dc";
  --fa--fa: "\e3dc\e3dc"; }

.fa-face-sad-tear {
  --fa: "\f5b4";
  --fa--fa: "\f5b4\f5b4"; }

.fa-sad-tear {
  --fa: "\f5b4";
  --fa--fa: "\f5b4\f5b4"; }

.fa-plane {
  --fa: "\f072";
  --fa--fa: "\f072\f072"; }

.fa-tent-arrows-down {
  --fa: "\e581";
  --fa--fa: "\e581\e581"; }

.fa-exclamation {
  --fa: "\21";
  --fa--fa: "\21\21"; }

.fa-arrows-spin {
  --fa: "\e4bb";
  --fa--fa: "\e4bb\e4bb"; }

.fa-face-smile-relaxed {
  --fa: "\e392";
  --fa--fa: "\e392\e392"; }

.fa-comment-xmark {
  --fa: "\f4b5";
  --fa--fa: "\f4b5\f4b5"; }

.fa-comment-times {
  --fa: "\f4b5";
  --fa--fa: "\f4b5\f4b5"; }

.fa-print {
  --fa: "\f02f";
  --fa--fa: "\f02f\f02f"; }

.fa-turkish-lira-sign {
  --fa: "\e2bb";
  --fa--fa: "\e2bb\e2bb"; }

.fa-try {
  --fa: "\e2bb";
  --fa--fa: "\e2bb\e2bb"; }

.fa-turkish-lira {
  --fa: "\e2bb";
  --fa--fa: "\e2bb\e2bb"; }

.fa-face-nose-steam {
  --fa: "\e382";
  --fa--fa: "\e382\e382"; }

.fa-circle-waveform-lines {
  --fa: "\e12d";
  --fa--fa: "\e12d\e12d"; }

.fa-waveform-circle {
  --fa: "\e12d";
  --fa--fa: "\e12d\e12d"; }

.fa-dollar-sign {
  --fa: "\24";
  --fa--fa: "\24\24"; }

.fa-dollar {
  --fa: "\24";
  --fa--fa: "\24\24"; }

.fa-usd {
  --fa: "\24";
  --fa--fa: "\24\24"; }

.fa-ferris-wheel {
  --fa: "\e174";
  --fa--fa: "\e174\e174"; }

.fa-computer-speaker {
  --fa: "\f8b2";
  --fa--fa: "\f8b2\f8b2"; }

.fa-skull-cow {
  --fa: "\f8de";
  --fa--fa: "\f8de\f8de"; }

.fa-x {
  --fa: "\58";
  --fa--fa: "\58\58"; }

.fa-magnifying-glass-dollar {
  --fa: "\f688";
  --fa--fa: "\f688\f688"; }

.fa-search-dollar {
  --fa: "\f688";
  --fa--fa: "\f688\f688"; }

.fa-users-gear {
  --fa: "\f509";
  --fa--fa: "\f509\f509"; }

.fa-users-cog {
  --fa: "\f509";
  --fa--fa: "\f509\f509"; }

.fa-person-military-pointing {
  --fa: "\e54a";
  --fa--fa: "\e54a\e54a"; }

.fa-building-columns {
  --fa: "\f19c";
  --fa--fa: "\f19c\f19c"; }

.fa-bank {
  --fa: "\f19c";
  --fa--fa: "\f19c\f19c"; }

.fa-institution {
  --fa: "\f19c";
  --fa--fa: "\f19c\f19c"; }

.fa-museum {
  --fa: "\f19c";
  --fa--fa: "\f19c\f19c"; }

.fa-university {
  --fa: "\f19c";
  --fa--fa: "\f19c\f19c"; }

.fa-circle-t {
  --fa: "\e124";
  --fa--fa: "\e124\e124"; }

.fa-sack {
  --fa: "\f81c";
  --fa--fa: "\f81c\f81c"; }

.fa-grid-2 {
  --fa: "\e196";
  --fa--fa: "\e196\e196"; }

.fa-camera-cctv {
  --fa: "\f8ac";
  --fa--fa: "\f8ac\f8ac"; }

.fa-cctv {
  --fa: "\f8ac";
  --fa--fa: "\f8ac\f8ac"; }

.fa-umbrella {
  --fa: "\f0e9";
  --fa--fa: "\f0e9\f0e9"; }

.fa-trowel {
  --fa: "\e589";
  --fa--fa: "\e589\e589"; }

.fa-horizontal-rule {
  --fa: "\f86c";
  --fa--fa: "\f86c\f86c"; }

.fa-bed-front {
  --fa: "\f8f7";
  --fa--fa: "\f8f7\f8f7"; }

.fa-bed-alt {
  --fa: "\f8f7";
  --fa--fa: "\f8f7\f8f7"; }

.fa-d {
  --fa: "\44";
  --fa--fa: "\44\44"; }

.fa-stapler {
  --fa: "\e5af";
  --fa--fa: "\e5af\e5af"; }

.fa-masks-theater {
  --fa: "\f630";
  --fa--fa: "\f630\f630"; }

.fa-theater-masks {
  --fa: "\f630";
  --fa--fa: "\f630\f630"; }

.fa-file-gif {
  --fa: "\e645";
  --fa--fa: "\e645\e645"; }

.fa-kip-sign {
  --fa: "\e1c4";
  --fa--fa: "\e1c4\e1c4"; }

.fa-face-woozy {
  --fa: "\e3a2";
  --fa--fa: "\e3a2\e3a2"; }

.fa-cloud-question {
  --fa: "\e492";
  --fa--fa: "\e492\e492"; }

.fa-pineapple {
  --fa: "\e31f";
  --fa--fa: "\e31f\e31f"; }

.fa-hand-point-left {
  --fa: "\f0a5";
  --fa--fa: "\f0a5\f0a5"; }

.fa-gallery-thumbnails {
  --fa: "\e3aa";
  --fa--fa: "\e3aa\e3aa"; }

.fa-circle-j {
  --fa: "\e112";
  --fa--fa: "\e112\e112"; }

.fa-eyes {
  --fa: "\e367";
  --fa--fa: "\e367\e367"; }

.fa-handshake-simple {
  --fa: "\f4c6";
  --fa--fa: "\f4c6\f4c6"; }

.fa-handshake-alt {
  --fa: "\f4c6";
  --fa--fa: "\f4c6\f4c6"; }

.fa-page-caret-up {
  --fa: "\e42a";
  --fa--fa: "\e42a\e42a"; }

.fa-file-caret-up {
  --fa: "\e42a";
  --fa--fa: "\e42a\e42a"; }

.fa-jet-fighter {
  --fa: "\f0fb";
  --fa--fa: "\f0fb\f0fb"; }

.fa-fighter-jet {
  --fa: "\f0fb";
  --fa--fa: "\f0fb\f0fb"; }

.fa-comet {
  --fa: "\e003";
  --fa--fa: "\e003\e003"; }

.fa-square-share-nodes {
  --fa: "\f1e1";
  --fa--fa: "\f1e1\f1e1"; }

.fa-share-alt-square {
  --fa: "\f1e1";
  --fa--fa: "\f1e1\f1e1"; }

.fa-reflect-vertical {
  --fa: "\e665";
  --fa--fa: "\e665\e665"; }

.fa-shield-keyhole {
  --fa: "\e248";
  --fa--fa: "\e248\e248"; }

.fa-file-mp4 {
  --fa: "\e649";
  --fa--fa: "\e649\e649"; }

.fa-barcode {
  --fa: "\f02a";
  --fa--fa: "\f02a\f02a"; }

.fa-bulldozer {
  --fa: "\e655";
  --fa--fa: "\e655\e655"; }

.fa-plus-minus {
  --fa: "\e43c";
  --fa--fa: "\e43c\e43c"; }

.fa-square-sliders-vertical {
  --fa: "\f3f2";
  --fa--fa: "\f3f2\f3f2"; }

.fa-sliders-v-square {
  --fa: "\f3f2";
  --fa--fa: "\f3f2\f3f2"; }

.fa-video {
  --fa: "\f03d";
  --fa--fa: "\f03d\f03d"; }

.fa-video-camera {
  --fa: "\f03d";
  --fa--fa: "\f03d\f03d"; }

.fa-message-middle {
  --fa: "\e1e1";
  --fa--fa: "\e1e1\e1e1"; }

.fa-comment-middle-alt {
  --fa: "\e1e1";
  --fa--fa: "\e1e1\e1e1"; }

.fa-graduation-cap {
  --fa: "\f19d";
  --fa--fa: "\f19d\f19d"; }

.fa-mortar-board {
  --fa: "\f19d";
  --fa--fa: "\f19d\f19d"; }

.fa-hand-holding-medical {
  --fa: "\e05c";
  --fa--fa: "\e05c\e05c"; }

.fa-person-circle-check {
  --fa: "\e53e";
  --fa--fa: "\e53e\e53e"; }

.fa-square-z {
  --fa: "\e288";
  --fa--fa: "\e288\e288"; }

.fa-message-text {
  --fa: "\e1e6";
  --fa--fa: "\e1e6\e1e6"; }

.fa-comment-alt-text {
  --fa: "\e1e6";
  --fa--fa: "\e1e6\e1e6"; }

.fa-turn-up {
  --fa: "\f3bf";
  --fa--fa: "\f3bf\f3bf"; }

.fa-level-up-alt {
  --fa: "\f3bf";
  --fa--fa: "\f3bf\f3bf"; }

.sr-only,
.fa-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0; }

.sr-only-focusable:not(:focus),
.fa-sr-only-focusable:not(:focus) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0; }

/*!
 * Font Awesome Pro 6.7.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2024 Fonticons, Inc.
 */
:root, :host {
  --fa-style-family-classic: 'Font Awesome 6 Pro';
  --fa-font-solid: normal 900 1em/1 'Font Awesome 6 Pro'; }

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../fonts/fa-solid-900.woff2") format("woff2"), url("../fonts/fa-solid-900.ttf") format("truetype"); }

.fas,
.fa-solid {
  font-weight: 900; }

/*!
 * Font Awesome Pro 6.7.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2024 Fonticons, Inc.
 */
:root, :host {
  --fa-style-family-classic: 'Font Awesome 6 Pro';
  --fa-font-regular: normal 400 1em/1 'Font Awesome 6 Pro'; }

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fa-regular-400.woff2") format("woff2"), url("../fonts/fa-regular-400.ttf") format("truetype"); }

.far,
.fa-regular {
  font-weight: 400; }

/*!
 * Font Awesome Pro 6.7.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2024 Fonticons, Inc.
 */
:root, :host {
  --fa-style-family-classic: 'Font Awesome 6 Pro';
  --fa-font-light: normal 300 1em/1 'Font Awesome 6 Pro'; }

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("../fonts/fa-light-300.woff2") format("woff2"), url("../fonts/fa-light-300.ttf") format("truetype"); }

.fal,
.fa-light {
  font-weight: 300; }

/*!
 * Font Awesome Pro 6.7.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2024 Fonticons, Inc.
 */
:root, :host {
  --fa-style-family-brands: 'Font Awesome 6 Brands';
  --fa-font-brands: normal 400 1em/1 'Font Awesome 6 Brands'; }

@font-face {
  font-family: 'Font Awesome 6 Brands';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fa-brands-400.woff2") format("woff2"), url("../fonts/fa-brands-400.ttf") format("truetype"); }

.fab,
.fa-brands {
  font-weight: 400; }

.fa-monero {
  --fa: "\f3d0"; }

.fa-hooli {
  --fa: "\f427"; }

.fa-yelp {
  --fa: "\f1e9"; }

.fa-cc-visa {
  --fa: "\f1f0"; }

.fa-lastfm {
  --fa: "\f202"; }

.fa-shopware {
  --fa: "\f5b5"; }

.fa-creative-commons-nc {
  --fa: "\f4e8"; }

.fa-aws {
  --fa: "\f375"; }

.fa-redhat {
  --fa: "\f7bc"; }

.fa-yoast {
  --fa: "\f2b1"; }

.fa-cloudflare {
  --fa: "\e07d"; }

.fa-ups {
  --fa: "\f7e0"; }

.fa-pixiv {
  --fa: "\e640"; }

.fa-wpexplorer {
  --fa: "\f2de"; }

.fa-dyalog {
  --fa: "\f399"; }

.fa-bity {
  --fa: "\f37a"; }

.fa-stackpath {
  --fa: "\f842"; }

.fa-buysellads {
  --fa: "\f20d"; }

.fa-first-order {
  --fa: "\f2b0"; }

.fa-modx {
  --fa: "\f285"; }

.fa-guilded {
  --fa: "\e07e"; }

.fa-vnv {
  --fa: "\f40b"; }

.fa-square-js {
  --fa: "\f3b9"; }

.fa-js-square {
  --fa: "\f3b9"; }

.fa-microsoft {
  --fa: "\f3ca"; }

.fa-qq {
  --fa: "\f1d6"; }

.fa-orcid {
  --fa: "\f8d2"; }

.fa-java {
  --fa: "\f4e4"; }

.fa-invision {
  --fa: "\f7b0"; }

.fa-creative-commons-pd-alt {
  --fa: "\f4ed"; }

.fa-centercode {
  --fa: "\f380"; }

.fa-glide-g {
  --fa: "\f2a6"; }

.fa-drupal {
  --fa: "\f1a9"; }

.fa-jxl {
  --fa: "\e67b"; }

.fa-dart-lang {
  --fa: "\e693"; }

.fa-hire-a-helper {
  --fa: "\f3b0"; }

.fa-creative-commons-by {
  --fa: "\f4e7"; }

.fa-unity {
  --fa: "\e049"; }

.fa-whmcs {
  --fa: "\f40d"; }

.fa-rocketchat {
  --fa: "\f3e8"; }

.fa-vk {
  --fa: "\f189"; }

.fa-untappd {
  --fa: "\f405"; }

.fa-mailchimp {
  --fa: "\f59e"; }

.fa-css3-alt {
  --fa: "\f38b"; }

.fa-square-reddit {
  --fa: "\f1a2"; }

.fa-reddit-square {
  --fa: "\f1a2"; }

.fa-vimeo-v {
  --fa: "\f27d"; }

.fa-contao {
  --fa: "\f26d"; }

.fa-square-font-awesome {
  --fa: "\e5ad"; }

.fa-deskpro {
  --fa: "\f38f"; }

.fa-brave {
  --fa: "\e63c"; }

.fa-sistrix {
  --fa: "\f3ee"; }

.fa-square-instagram {
  --fa: "\e055"; }

.fa-instagram-square {
  --fa: "\e055"; }

.fa-battle-net {
  --fa: "\f835"; }

.fa-the-red-yeti {
  --fa: "\f69d"; }

.fa-square-hacker-news {
  --fa: "\f3af"; }

.fa-hacker-news-square {
  --fa: "\f3af"; }

.fa-edge {
  --fa: "\f282"; }

.fa-threads {
  --fa: "\e618"; }

.fa-napster {
  --fa: "\f3d2"; }

.fa-square-snapchat {
  --fa: "\f2ad"; }

.fa-snapchat-square {
  --fa: "\f2ad"; }

.fa-google-plus-g {
  --fa: "\f0d5"; }

.fa-artstation {
  --fa: "\f77a"; }

.fa-markdown {
  --fa: "\f60f"; }

.fa-sourcetree {
  --fa: "\f7d3"; }

.fa-google-plus {
  --fa: "\f2b3"; }

.fa-diaspora {
  --fa: "\f791"; }

.fa-foursquare {
  --fa: "\f180"; }

.fa-stack-overflow {
  --fa: "\f16c"; }

.fa-github-alt {
  --fa: "\f113"; }

.fa-phoenix-squadron {
  --fa: "\f511"; }

.fa-pagelines {
  --fa: "\f18c"; }

.fa-algolia {
  --fa: "\f36c"; }

.fa-red-river {
  --fa: "\f3e3"; }

.fa-creative-commons-sa {
  --fa: "\f4ef"; }

.fa-safari {
  --fa: "\f267"; }

.fa-google {
  --fa: "\f1a0"; }

.fa-square-font-awesome-stroke {
  --fa: "\f35c"; }

.fa-font-awesome-alt {
  --fa: "\f35c"; }

.fa-atlassian {
  --fa: "\f77b"; }

.fa-linkedin-in {
  --fa: "\f0e1"; }

.fa-digital-ocean {
  --fa: "\f391"; }

.fa-nimblr {
  --fa: "\f5a8"; }

.fa-chromecast {
  --fa: "\f838"; }

.fa-evernote {
  --fa: "\f839"; }

.fa-hacker-news {
  --fa: "\f1d4"; }

.fa-creative-commons-sampling {
  --fa: "\f4f0"; }

.fa-adversal {
  --fa: "\f36a"; }

.fa-creative-commons {
  --fa: "\f25e"; }

.fa-watchman-monitoring {
  --fa: "\e087"; }

.fa-fonticons {
  --fa: "\f280"; }

.fa-weixin {
  --fa: "\f1d7"; }

.fa-shirtsinbulk {
  --fa: "\f214"; }

.fa-codepen {
  --fa: "\f1cb"; }

.fa-git-alt {
  --fa: "\f841"; }

.fa-lyft {
  --fa: "\f3c3"; }

.fa-rev {
  --fa: "\f5b2"; }

.fa-windows {
  --fa: "\f17a"; }

.fa-wizards-of-the-coast {
  --fa: "\f730"; }

.fa-square-viadeo {
  --fa: "\f2aa"; }

.fa-viadeo-square {
  --fa: "\f2aa"; }

.fa-meetup {
  --fa: "\f2e0"; }

.fa-centos {
  --fa: "\f789"; }

.fa-adn {
  --fa: "\f170"; }

.fa-cloudsmith {
  --fa: "\f384"; }

.fa-opensuse {
  --fa: "\e62b"; }

.fa-pied-piper-alt {
  --fa: "\f1a8"; }

.fa-square-dribbble {
  --fa: "\f397"; }

.fa-dribbble-square {
  --fa: "\f397"; }

.fa-codiepie {
  --fa: "\f284"; }

.fa-node {
  --fa: "\f419"; }

.fa-mix {
  --fa: "\f3cb"; }

.fa-steam {
  --fa: "\f1b6"; }

.fa-cc-apple-pay {
  --fa: "\f416"; }

.fa-scribd {
  --fa: "\f28a"; }

.fa-debian {
  --fa: "\e60b"; }

.fa-openid {
  --fa: "\f19b"; }

.fa-instalod {
  --fa: "\e081"; }

.fa-files-pinwheel {
  --fa: "\e69f"; }

.fa-expeditedssl {
  --fa: "\f23e"; }

.fa-sellcast {
  --fa: "\f2da"; }

.fa-square-twitter {
  --fa: "\f081"; }

.fa-twitter-square {
  --fa: "\f081"; }

.fa-r-project {
  --fa: "\f4f7"; }

.fa-delicious {
  --fa: "\f1a5"; }

.fa-freebsd {
  --fa: "\f3a4"; }

.fa-vuejs {
  --fa: "\f41f"; }

.fa-accusoft {
  --fa: "\f369"; }

.fa-ioxhost {
  --fa: "\f208"; }

.fa-fonticons-fi {
  --fa: "\f3a2"; }

.fa-app-store {
  --fa: "\f36f"; }

.fa-cc-mastercard {
  --fa: "\f1f1"; }

.fa-itunes-note {
  --fa: "\f3b5"; }

.fa-golang {
  --fa: "\e40f"; }

.fa-kickstarter {
  --fa: "\f3bb"; }

.fa-square-kickstarter {
  --fa: "\f3bb"; }

.fa-grav {
  --fa: "\f2d6"; }

.fa-weibo {
  --fa: "\f18a"; }

.fa-uncharted {
  --fa: "\e084"; }

.fa-firstdraft {
  --fa: "\f3a1"; }

.fa-square-youtube {
  --fa: "\f431"; }

.fa-youtube-square {
  --fa: "\f431"; }

.fa-wikipedia-w {
  --fa: "\f266"; }

.fa-wpressr {
  --fa: "\f3e4"; }

.fa-rendact {
  --fa: "\f3e4"; }

.fa-angellist {
  --fa: "\f209"; }

.fa-galactic-republic {
  --fa: "\f50c"; }

.fa-nfc-directional {
  --fa: "\e530"; }

.fa-skype {
  --fa: "\f17e"; }

.fa-joget {
  --fa: "\f3b7"; }

.fa-fedora {
  --fa: "\f798"; }

.fa-stripe-s {
  --fa: "\f42a"; }

.fa-meta {
  --fa: "\e49b"; }

.fa-laravel {
  --fa: "\f3bd"; }

.fa-hotjar {
  --fa: "\f3b1"; }

.fa-bluetooth-b {
  --fa: "\f294"; }

.fa-square-letterboxd {
  --fa: "\e62e"; }

.fa-sticker-mule {
  --fa: "\f3f7"; }

.fa-creative-commons-zero {
  --fa: "\f4f3"; }

.fa-hips {
  --fa: "\f452"; }

.fa-css {
  --fa: "\e6a2"; }

.fa-behance {
  --fa: "\f1b4"; }

.fa-reddit {
  --fa: "\f1a1"; }

.fa-discord {
  --fa: "\f392"; }

.fa-chrome {
  --fa: "\f268"; }

.fa-app-store-ios {
  --fa: "\f370"; }

.fa-cc-discover {
  --fa: "\f1f2"; }

.fa-wpbeginner {
  --fa: "\f297"; }

.fa-confluence {
  --fa: "\f78d"; }

.fa-shoelace {
  --fa: "\e60c"; }

.fa-mdb {
  --fa: "\f8ca"; }

.fa-dochub {
  --fa: "\f394"; }

.fa-accessible-icon {
  --fa: "\f368"; }

.fa-ebay {
  --fa: "\f4f4"; }

.fa-amazon {
  --fa: "\f270"; }

.fa-unsplash {
  --fa: "\e07c"; }

.fa-yarn {
  --fa: "\f7e3"; }

.fa-square-steam {
  --fa: "\f1b7"; }

.fa-steam-square {
  --fa: "\f1b7"; }

.fa-500px {
  --fa: "\f26e"; }

.fa-square-vimeo {
  --fa: "\f194"; }

.fa-vimeo-square {
  --fa: "\f194"; }

.fa-asymmetrik {
  --fa: "\f372"; }

.fa-font-awesome {
  --fa: "\f2b4"; }

.fa-font-awesome-flag {
  --fa: "\f2b4"; }

.fa-font-awesome-logo-full {
  --fa: "\f2b4"; }

.fa-gratipay {
  --fa: "\f184"; }

.fa-apple {
  --fa: "\f179"; }

.fa-hive {
  --fa: "\e07f"; }

.fa-gitkraken {
  --fa: "\f3a6"; }

.fa-keybase {
  --fa: "\f4f5"; }

.fa-apple-pay {
  --fa: "\f415"; }

.fa-padlet {
  --fa: "\e4a0"; }

.fa-amazon-pay {
  --fa: "\f42c"; }

.fa-square-github {
  --fa: "\f092"; }

.fa-github-square {
  --fa: "\f092"; }

.fa-stumbleupon {
  --fa: "\f1a4"; }

.fa-fedex {
  --fa: "\f797"; }

.fa-phoenix-framework {
  --fa: "\f3dc"; }

.fa-shopify {
  --fa: "\e057"; }

.fa-neos {
  --fa: "\f612"; }

.fa-square-threads {
  --fa: "\e619"; }

.fa-hackerrank {
  --fa: "\f5f7"; }

.fa-researchgate {
  --fa: "\f4f8"; }

.fa-swift {
  --fa: "\f8e1"; }

.fa-angular {
  --fa: "\f420"; }

.fa-speakap {
  --fa: "\f3f3"; }

.fa-angrycreative {
  --fa: "\f36e"; }

.fa-y-combinator {
  --fa: "\f23b"; }

.fa-empire {
  --fa: "\f1d1"; }

.fa-envira {
  --fa: "\f299"; }

.fa-google-scholar {
  --fa: "\e63b"; }

.fa-square-gitlab {
  --fa: "\e5ae"; }

.fa-gitlab-square {
  --fa: "\e5ae"; }

.fa-studiovinari {
  --fa: "\f3f8"; }

.fa-pied-piper {
  --fa: "\f2ae"; }

.fa-wordpress {
  --fa: "\f19a"; }

.fa-product-hunt {
  --fa: "\f288"; }

.fa-firefox {
  --fa: "\f269"; }

.fa-linode {
  --fa: "\f2b8"; }

.fa-goodreads {
  --fa: "\f3a8"; }

.fa-square-odnoklassniki {
  --fa: "\f264"; }

.fa-odnoklassniki-square {
  --fa: "\f264"; }

.fa-jsfiddle {
  --fa: "\f1cc"; }

.fa-sith {
  --fa: "\f512"; }

.fa-themeisle {
  --fa: "\f2b2"; }

.fa-page4 {
  --fa: "\f3d7"; }

.fa-hashnode {
  --fa: "\e499"; }

.fa-react {
  --fa: "\f41b"; }

.fa-cc-paypal {
  --fa: "\f1f4"; }

.fa-squarespace {
  --fa: "\f5be"; }

.fa-cc-stripe {
  --fa: "\f1f5"; }

.fa-creative-commons-share {
  --fa: "\f4f2"; }

.fa-bitcoin {
  --fa: "\f379"; }

.fa-keycdn {
  --fa: "\f3ba"; }

.fa-opera {
  --fa: "\f26a"; }

.fa-itch-io {
  --fa: "\f83a"; }

.fa-umbraco {
  --fa: "\f8e8"; }

.fa-galactic-senate {
  --fa: "\f50d"; }

.fa-ubuntu {
  --fa: "\f7df"; }

.fa-draft2digital {
  --fa: "\f396"; }

.fa-stripe {
  --fa: "\f429"; }

.fa-houzz {
  --fa: "\f27c"; }

.fa-gg {
  --fa: "\f260"; }

.fa-dhl {
  --fa: "\f790"; }

.fa-square-pinterest {
  --fa: "\f0d3"; }

.fa-pinterest-square {
  --fa: "\f0d3"; }

.fa-xing {
  --fa: "\f168"; }

.fa-blackberry {
  --fa: "\f37b"; }

.fa-creative-commons-pd {
  --fa: "\f4ec"; }

.fa-playstation {
  --fa: "\f3df"; }

.fa-quinscape {
  --fa: "\f459"; }

.fa-less {
  --fa: "\f41d"; }

.fa-blogger-b {
  --fa: "\f37d"; }

.fa-opencart {
  --fa: "\f23d"; }

.fa-vine {
  --fa: "\f1ca"; }

.fa-signal-messenger {
  --fa: "\e663"; }

.fa-paypal {
  --fa: "\f1ed"; }

.fa-gitlab {
  --fa: "\f296"; }

.fa-typo3 {
  --fa: "\f42b"; }

.fa-reddit-alien {
  --fa: "\f281"; }

.fa-yahoo {
  --fa: "\f19e"; }

.fa-dailymotion {
  --fa: "\e052"; }

.fa-affiliatetheme {
  --fa: "\f36b"; }

.fa-pied-piper-pp {
  --fa: "\f1a7"; }

.fa-bootstrap {
  --fa: "\f836"; }

.fa-odnoklassniki {
  --fa: "\f263"; }

.fa-nfc-symbol {
  --fa: "\e531"; }

.fa-mintbit {
  --fa: "\e62f"; }

.fa-ethereum {
  --fa: "\f42e"; }

.fa-speaker-deck {
  --fa: "\f83c"; }

.fa-creative-commons-nc-eu {
  --fa: "\f4e9"; }

.fa-patreon {
  --fa: "\f3d9"; }

.fa-avianex {
  --fa: "\f374"; }

.fa-ello {
  --fa: "\f5f1"; }

.fa-gofore {
  --fa: "\f3a7"; }

.fa-bimobject {
  --fa: "\f378"; }

.fa-brave-reverse {
  --fa: "\e63d"; }

.fa-facebook-f {
  --fa: "\f39e"; }

.fa-square-google-plus {
  --fa: "\f0d4"; }

.fa-google-plus-square {
  --fa: "\f0d4"; }

.fa-web-awesome {
  --fa: "\e682"; }

.fa-mandalorian {
  --fa: "\f50f"; }

.fa-first-order-alt {
  --fa: "\f50a"; }

.fa-osi {
  --fa: "\f41a"; }

.fa-google-wallet {
  --fa: "\f1ee"; }

.fa-d-and-d-beyond {
  --fa: "\f6ca"; }

.fa-periscope {
  --fa: "\f3da"; }

.fa-fulcrum {
  --fa: "\f50b"; }

.fa-cloudscale {
  --fa: "\f383"; }

.fa-forumbee {
  --fa: "\f211"; }

.fa-mizuni {
  --fa: "\f3cc"; }

.fa-schlix {
  --fa: "\f3ea"; }

.fa-square-xing {
  --fa: "\f169"; }

.fa-xing-square {
  --fa: "\f169"; }

.fa-bandcamp {
  --fa: "\f2d5"; }

.fa-wpforms {
  --fa: "\f298"; }

.fa-cloudversify {
  --fa: "\f385"; }

.fa-usps {
  --fa: "\f7e1"; }

.fa-megaport {
  --fa: "\f5a3"; }

.fa-magento {
  --fa: "\f3c4"; }

.fa-spotify {
  --fa: "\f1bc"; }

.fa-optin-monster {
  --fa: "\f23c"; }

.fa-fly {
  --fa: "\f417"; }

.fa-square-bluesky {
  --fa: "\e6a3"; }

.fa-aviato {
  --fa: "\f421"; }

.fa-itunes {
  --fa: "\f3b4"; }

.fa-cuttlefish {
  --fa: "\f38c"; }

.fa-blogger {
  --fa: "\f37c"; }

.fa-flickr {
  --fa: "\f16e"; }

.fa-viber {
  --fa: "\f409"; }

.fa-soundcloud {
  --fa: "\f1be"; }

.fa-digg {
  --fa: "\f1a6"; }

.fa-tencent-weibo {
  --fa: "\f1d5"; }

.fa-letterboxd {
  --fa: "\e62d"; }

.fa-symfony {
  --fa: "\f83d"; }

.fa-maxcdn {
  --fa: "\f136"; }

.fa-etsy {
  --fa: "\f2d7"; }

.fa-facebook-messenger {
  --fa: "\f39f"; }

.fa-audible {
  --fa: "\f373"; }

.fa-think-peaks {
  --fa: "\f731"; }

.fa-bilibili {
  --fa: "\e3d9"; }

.fa-erlang {
  --fa: "\f39d"; }

.fa-x-twitter {
  --fa: "\e61b"; }

.fa-cotton-bureau {
  --fa: "\f89e"; }

.fa-dashcube {
  --fa: "\f210"; }

.fa-42-group {
  --fa: "\e080"; }

.fa-innosoft {
  --fa: "\e080"; }

.fa-stack-exchange {
  --fa: "\f18d"; }

.fa-elementor {
  --fa: "\f430"; }

.fa-square-pied-piper {
  --fa: "\e01e"; }

.fa-pied-piper-square {
  --fa: "\e01e"; }

.fa-creative-commons-nd {
  --fa: "\f4eb"; }

.fa-palfed {
  --fa: "\f3d8"; }

.fa-superpowers {
  --fa: "\f2dd"; }

.fa-resolving {
  --fa: "\f3e7"; }

.fa-xbox {
  --fa: "\f412"; }

.fa-square-web-awesome-stroke {
  --fa: "\e684"; }

.fa-searchengin {
  --fa: "\f3eb"; }

.fa-tiktok {
  --fa: "\e07b"; }

.fa-square-facebook {
  --fa: "\f082"; }

.fa-facebook-square {
  --fa: "\f082"; }

.fa-renren {
  --fa: "\f18b"; }

.fa-linux {
  --fa: "\f17c"; }

.fa-glide {
  --fa: "\f2a5"; }

.fa-linkedin {
  --fa: "\f08c"; }

.fa-hubspot {
  --fa: "\f3b2"; }

.fa-deploydog {
  --fa: "\f38e"; }

.fa-twitch {
  --fa: "\f1e8"; }

.fa-flutter {
  --fa: "\e694"; }

.fa-ravelry {
  --fa: "\f2d9"; }

.fa-mixer {
  --fa: "\e056"; }

.fa-square-lastfm {
  --fa: "\f203"; }

.fa-lastfm-square {
  --fa: "\f203"; }

.fa-vimeo {
  --fa: "\f40a"; }

.fa-mendeley {
  --fa: "\f7b3"; }

.fa-uniregistry {
  --fa: "\f404"; }

.fa-figma {
  --fa: "\f799"; }

.fa-creative-commons-remix {
  --fa: "\f4ee"; }

.fa-cc-amazon-pay {
  --fa: "\f42d"; }

.fa-dropbox {
  --fa: "\f16b"; }

.fa-instagram {
  --fa: "\f16d"; }

.fa-cmplid {
  --fa: "\e360"; }

.fa-upwork {
  --fa: "\e641"; }

.fa-facebook {
  --fa: "\f09a"; }

.fa-gripfire {
  --fa: "\f3ac"; }

.fa-jedi-order {
  --fa: "\f50e"; }

.fa-uikit {
  --fa: "\f403"; }

.fa-fort-awesome-alt {
  --fa: "\f3a3"; }

.fa-phabricator {
  --fa: "\f3db"; }

.fa-ussunnah {
  --fa: "\f407"; }

.fa-earlybirds {
  --fa: "\f39a"; }

.fa-trade-federation {
  --fa: "\f513"; }

.fa-autoprefixer {
  --fa: "\f41c"; }

.fa-whatsapp {
  --fa: "\f232"; }

.fa-square-upwork {
  --fa: "\e67c"; }

.fa-slideshare {
  --fa: "\f1e7"; }

.fa-google-play {
  --fa: "\f3ab"; }

.fa-viadeo {
  --fa: "\f2a9"; }

.fa-line {
  --fa: "\f3c0"; }

.fa-google-drive {
  --fa: "\f3aa"; }

.fa-servicestack {
  --fa: "\f3ec"; }

.fa-simplybuilt {
  --fa: "\f215"; }

.fa-bitbucket {
  --fa: "\f171"; }

.fa-imdb {
  --fa: "\f2d8"; }

.fa-deezer {
  --fa: "\e077"; }

.fa-raspberry-pi {
  --fa: "\f7bb"; }

.fa-jira {
  --fa: "\f7b1"; }

.fa-docker {
  --fa: "\f395"; }

.fa-screenpal {
  --fa: "\e570"; }

.fa-bluetooth {
  --fa: "\f293"; }

.fa-gitter {
  --fa: "\f426"; }

.fa-d-and-d {
  --fa: "\f38d"; }

.fa-microblog {
  --fa: "\e01a"; }

.fa-cc-diners-club {
  --fa: "\f24c"; }

.fa-gg-circle {
  --fa: "\f261"; }

.fa-pied-piper-hat {
  --fa: "\f4e5"; }

.fa-kickstarter-k {
  --fa: "\f3bc"; }

.fa-yandex {
  --fa: "\f413"; }

.fa-readme {
  --fa: "\f4d5"; }

.fa-html5 {
  --fa: "\f13b"; }

.fa-sellsy {
  --fa: "\f213"; }

.fa-square-web-awesome {
  --fa: "\e683"; }

.fa-sass {
  --fa: "\f41e"; }

.fa-wirsindhandwerk {
  --fa: "\e2d0"; }

.fa-wsh {
  --fa: "\e2d0"; }

.fa-buromobelexperte {
  --fa: "\f37f"; }

.fa-salesforce {
  --fa: "\f83b"; }

.fa-octopus-deploy {
  --fa: "\e082"; }

.fa-medapps {
  --fa: "\f3c6"; }

.fa-ns8 {
  --fa: "\f3d5"; }

.fa-pinterest-p {
  --fa: "\f231"; }

.fa-apper {
  --fa: "\f371"; }

.fa-fort-awesome {
  --fa: "\f286"; }

.fa-waze {
  --fa: "\f83f"; }

.fa-bluesky {
  --fa: "\e671"; }

.fa-cc-jcb {
  --fa: "\f24b"; }

.fa-snapchat {
  --fa: "\f2ab"; }

.fa-snapchat-ghost {
  --fa: "\f2ab"; }

.fa-fantasy-flight-games {
  --fa: "\f6dc"; }

.fa-rust {
  --fa: "\e07a"; }

.fa-wix {
  --fa: "\f5cf"; }

.fa-square-behance {
  --fa: "\f1b5"; }

.fa-behance-square {
  --fa: "\f1b5"; }

.fa-supple {
  --fa: "\f3f9"; }

.fa-webflow {
  --fa: "\e65c"; }

.fa-rebel {
  --fa: "\f1d0"; }

.fa-css3 {
  --fa: "\f13c"; }

.fa-staylinked {
  --fa: "\f3f5"; }

.fa-kaggle {
  --fa: "\f5fa"; }

.fa-space-awesome {
  --fa: "\e5ac"; }

.fa-deviantart {
  --fa: "\f1bd"; }

.fa-cpanel {
  --fa: "\f388"; }

.fa-goodreads-g {
  --fa: "\f3a9"; }

.fa-square-git {
  --fa: "\f1d2"; }

.fa-git-square {
  --fa: "\f1d2"; }

.fa-square-tumblr {
  --fa: "\f174"; }

.fa-tumblr-square {
  --fa: "\f174"; }

.fa-trello {
  --fa: "\f181"; }

.fa-creative-commons-nc-jp {
  --fa: "\f4ea"; }

.fa-get-pocket {
  --fa: "\f265"; }

.fa-perbyte {
  --fa: "\e083"; }

.fa-grunt {
  --fa: "\f3ad"; }

.fa-weebly {
  --fa: "\f5cc"; }

.fa-connectdevelop {
  --fa: "\f20e"; }

.fa-leanpub {
  --fa: "\f212"; }

.fa-black-tie {
  --fa: "\f27e"; }

.fa-themeco {
  --fa: "\f5c6"; }

.fa-python {
  --fa: "\f3e2"; }

.fa-android {
  --fa: "\f17b"; }

.fa-bots {
  --fa: "\e340"; }

.fa-free-code-camp {
  --fa: "\f2c5"; }

.fa-hornbill {
  --fa: "\f592"; }

.fa-js {
  --fa: "\f3b8"; }

.fa-ideal {
  --fa: "\e013"; }

.fa-git {
  --fa: "\f1d3"; }

.fa-dev {
  --fa: "\f6cc"; }

.fa-sketch {
  --fa: "\f7c6"; }

.fa-yandex-international {
  --fa: "\f414"; }

.fa-cc-amex {
  --fa: "\f1f3"; }

.fa-uber {
  --fa: "\f402"; }

.fa-github {
  --fa: "\f09b"; }

.fa-php {
  --fa: "\f457"; }

.fa-alipay {
  --fa: "\f642"; }

.fa-youtube {
  --fa: "\f167"; }

.fa-skyatlas {
  --fa: "\f216"; }

.fa-firefox-browser {
  --fa: "\e007"; }

.fa-replyd {
  --fa: "\f3e6"; }

.fa-suse {
  --fa: "\f7d6"; }

.fa-jenkins {
  --fa: "\f3b6"; }

.fa-twitter {
  --fa: "\f099"; }

.fa-rockrms {
  --fa: "\f3e9"; }

.fa-pinterest {
  --fa: "\f0d2"; }

.fa-buffer {
  --fa: "\f837"; }

.fa-npm {
  --fa: "\f3d4"; }

.fa-yammer {
  --fa: "\f840"; }

.fa-btc {
  --fa: "\f15a"; }

.fa-dribbble {
  --fa: "\f17d"; }

.fa-stumbleupon-circle {
  --fa: "\f1a3"; }

.fa-internet-explorer {
  --fa: "\f26b"; }

.fa-stubber {
  --fa: "\e5c7"; }

.fa-telegram {
  --fa: "\f2c6"; }

.fa-telegram-plane {
  --fa: "\f2c6"; }

.fa-old-republic {
  --fa: "\f510"; }

.fa-odysee {
  --fa: "\e5c6"; }

.fa-square-whatsapp {
  --fa: "\f40c"; }

.fa-whatsapp-square {
  --fa: "\f40c"; }

.fa-node-js {
  --fa: "\f3d3"; }

.fa-edge-legacy {
  --fa: "\e078"; }

.fa-slack {
  --fa: "\f198"; }

.fa-slack-hash {
  --fa: "\f198"; }

.fa-medrt {
  --fa: "\f3c8"; }

.fa-usb {
  --fa: "\f287"; }

.fa-tumblr {
  --fa: "\f173"; }

.fa-vaadin {
  --fa: "\f408"; }

.fa-quora {
  --fa: "\f2c4"; }

.fa-square-x-twitter {
  --fa: "\e61a"; }

.fa-reacteurope {
  --fa: "\f75d"; }

.fa-medium {
  --fa: "\f23a"; }

.fa-medium-m {
  --fa: "\f23a"; }

.fa-amilia {
  --fa: "\f36d"; }

.fa-mixcloud {
  --fa: "\f289"; }

.fa-flipboard {
  --fa: "\f44d"; }

.fa-viacoin {
  --fa: "\f237"; }

.fa-critical-role {
  --fa: "\f6c9"; }

.fa-sitrox {
  --fa: "\e44a"; }

.fa-discourse {
  --fa: "\f393"; }

.fa-joomla {
  --fa: "\f1aa"; }

.fa-mastodon {
  --fa: "\f4f6"; }

.fa-airbnb {
  --fa: "\f834"; }

.fa-wolf-pack-battalion {
  --fa: "\f514"; }

.fa-buy-n-large {
  --fa: "\f8a6"; }

.fa-gulp {
  --fa: "\f3ae"; }

.fa-creative-commons-sampling-plus {
  --fa: "\f4f1"; }

.fa-strava {
  --fa: "\f428"; }

.fa-ember {
  --fa: "\f423"; }

.fa-canadian-maple-leaf {
  --fa: "\f785"; }

.fa-teamspeak {
  --fa: "\f4f9"; }

.fa-pushed {
  --fa: "\f3e1"; }

.fa-wordpress-simple {
  --fa: "\f411"; }

.fa-nutritionix {
  --fa: "\f3d6"; }

.fa-wodu {
  --fa: "\e088"; }

.fa-google-pay {
  --fa: "\e079"; }

.fa-intercom {
  --fa: "\f7af"; }

.fa-zhihu {
  --fa: "\f63f"; }

.fa-korvue {
  --fa: "\f42f"; }

.fa-pix {
  --fa: "\e43a"; }

.fa-steam-symbol {
  --fa: "\f3f6"; }

/* ========================================================================
   Component: Base
 ========================================================================== */
/*
 * 1. Set `font-size` to support `rem` units
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 * 3. Style
 */
html {
  /* 1 */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 3 */
  background: #fff;
  color: #666; }

/*
 * Remove the margin in all browsers.
 */
body {
  margin: 0; }

/* Links
 ========================================================================== */
/*
 * Style
 */
a,
.uk-link {
  color: #1e87f0;
  text-decoration: none;
  cursor: pointer; }

a:hover,
.uk-link:hover,
.uk-link-toggle:hover .uk-link {
  color: #0f6ecd;
  text-decoration: underline; }

/* Text-level semantics
 ========================================================================== */
/*
 * 1. Add the correct text decoration in Edge.
 * 2. The shorthand declaration `underline dotted` is not supported in Safari.
 */
abbr[title] {
  /* 1 */
  text-decoration: underline dotted;
  /* 2 */
  -webkit-text-decoration-style: dotted; }

/*
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/*
 * 1. Consolas has a better baseline in running text compared to `Courier`
 * 2. Correct the odd `em` font sizing in all browsers.
 * 3. Style
 */
:not(pre) > code,
:not(pre) > kbd,
:not(pre) > samp {
  /* 1 */
  font-family: Consolas, monaco, monospace;
  /* 2 */
  font-size: 0.875rem;
  /* 3 */
  color: #f0506e;
  white-space: nowrap; }

/*
 * Emphasize
 */
em {
  color: #f0506e; }

/*
 * Insert
 */
ins {
  background: #ffd;
  color: #666;
  text-decoration: none; }

/*
 * Mark
 */
mark {
  background: #ffd;
  color: #666; }

/*
 * Quote
 */
q {
  font-style: italic; }

/*
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%; }

/*
 * Prevents `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Embedded content
 ========================================================================== */
/*
 * Remove the gap between the element and the bottom of its parent container.
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

/*
 * 1. Constrain the element to its parent width.
 * 2. Preserve the intrinsic aspect ratio and auto-scale the height of an image if the `height` attribute is present.
 * 3. Take border and padding into account.
 */
canvas,
img,
svg,
video {
  /* 1 */
  max-width: 100%;
  /* 2 */
  height: auto;
  /* 3 */
  box-sizing: border-box; }

/*
 * Deprecated: only needed for `img` elements with `uk-img`
 * 1. Hide `alt` text for lazy load images.
 * 2. Fix lazy loading images if parent element is set to `display: inline` and has `overflow: hidden`.
 */
img:not([src]) {
  /* 1 */
  visibility: hidden;
  /* 2 */
  min-width: 1px; }

/*
 * Iframe
 * Remove border in all browsers
 */
iframe {
  border: 0; }

/* Block elements
 ========================================================================== */
/*
 * Margins
 */
p,
ul,
ol,
dl,
pre,
address,
fieldset,
figure {
  margin: 0 0 20px 0; }

/* Add margin if adjacent element */
* + p,
* + ul,
* + ol,
* + dl,
* + pre,
* + address,
* + fieldset,
* + figure {
  margin-top: 20px; }

/* Headings
 ========================================================================== */
h1, .uk-h1,
h2, .uk-h2,
h3, .uk-h3,
h4, .uk-h4,
h5, .uk-h5,
h6, .uk-h6,
.uk-heading-small,
.uk-heading-medium,
.uk-heading-large,
.uk-heading-xlarge,
.uk-heading-2xlarge {
  margin: 0 0 20px 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: normal;
  color: #333;
  text-transform: none; }

/* Add margin if adjacent element */
* + h1, * + .uk-h1,
* + h2, * + .uk-h2,
* + h3, * + .uk-h3,
* + h4, * + .uk-h4,
* + h5, * + .uk-h5,
* + h6, * + .uk-h6,
* + .uk-heading-small,
* + .uk-heading-medium,
* + .uk-heading-large,
* + .uk-heading-xlarge,
* + .uk-heading-2xlarge {
  margin-top: 40px; }

/*
 * Sizes
 */
h1, .uk-h1 {
  font-size: 2.23125rem;
  line-height: 1.2; }

h2, .uk-h2 {
  font-size: 1.7rem;
  line-height: 1.3; }

h3, .uk-h3 {
  font-size: 1.5rem;
  line-height: 1.4; }

h4, .uk-h4 {
  font-size: 1.25rem;
  line-height: 1.4; }

h5, .uk-h5 {
  font-size: 16px;
  line-height: 1.4; }

h6, .uk-h6 {
  font-size: 0.875rem;
  line-height: 1.4; }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  h1, .uk-h1 {
    font-size: 2.625rem; }
  h2, .uk-h2 {
    font-size: 2rem; } }

/* Lists
 ========================================================================== */
ul,
ol {
  padding-left: 30px; }

/*
 * Reset margin for nested lists
 */
ul > li > ul,
ul > li > ol,
ol > li > ol,
ol > li > ul {
  margin: 0; }

/* Description lists
 ========================================================================== */
dt {
  font-weight: bold; }

dd {
  margin-left: 0; }

/* Horizontal rules
 ========================================================================== */
/*
 * 1. Show the overflow in Chrome, Edge and IE.
 * 2. Add the correct text-align in Edge and IE.
 * 3. Style
 */
hr, .uk-hr {
  /* 1 */
  overflow: visible;
  /* 2 */
  text-align: inherit;
  /* 3 */
  margin: 0 0 20px 0;
  border: 0;
  border-top: 1px solid #e5e5e5; }

/* Add margin if adjacent element */
* + hr,
* + .uk-hr {
  margin-top: 20px; }

/* Address
 ========================================================================== */
address {
  font-style: normal; }

/* Blockquotes
 ========================================================================== */
blockquote {
  margin: 0 0 20px 0;
  font-size: 1.25rem;
  line-height: 1.5;
  font-style: italic; }

/* Add margin if adjacent element */
* + blockquote {
  margin-top: 20px; }

/*
 * Content
 */
blockquote p:last-of-type {
  margin-bottom: 0; }

blockquote footer {
  margin-top: 10px;
  font-size: 0.875rem;
  line-height: 1.5; }

/* Preformatted text
 ========================================================================== */
/*
 * 1. Contain overflow in all browsers.
 */
pre {
  font: 0.875rem / 1.5 Consolas, monaco, monospace;
  color: #666;
  -moz-tab-size: 4;
  tab-size: 4;
  /* 1 */
  overflow: auto; }

pre code {
  font-family: Consolas, monaco, monospace; }

/* Focus
 ========================================================================== */
:focus {
  outline: none; }

:focus-visible {
  outline: 2px dotted #333; }

/* Selection pseudo-element
 ========================================================================== */
::selection {
  background: #39f;
  color: #fff;
  text-shadow: none; }

/* HTML5 elements
 ========================================================================== */
/*
 * 1. Add the correct display in Edge, IE 10+, and Firefox.
 * 2. Add the correct display in IE.
 */
details,
main {
  /* 2 */
  display: block; }

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item; }

/*
 * Add the correct display in IE.
 */
template {
  display: none; }

/* Pass media breakpoints to JS
 ========================================================================== */
/*
 * Breakpoints
 */
:root {
  --uk-breakpoint-s: 640px;
  --uk-breakpoint-m: 960px;
  --uk-breakpoint-l: 1200px;
  --uk-breakpoint-xl: 1600px; }

/* ========================================================================
   Component: Link
 ========================================================================== */
/* Muted
 ========================================================================== */
a.uk-link-muted,
.uk-link-muted a,
.uk-link-toggle .uk-link-muted {
  color: #999; }

a.uk-link-muted:hover,
.uk-link-muted a:hover,
.uk-link-toggle:hover .uk-link-muted {
  color: #666; }

/* Text
 ========================================================================== */
a.uk-link-text,
.uk-link-text a,
.uk-link-toggle .uk-link-text {
  color: inherit; }

a.uk-link-text:hover,
.uk-link-text a:hover,
.uk-link-toggle:hover .uk-link-text {
  color: #999; }

/* Heading
 ========================================================================== */
a.uk-link-heading,
.uk-link-heading a,
.uk-link-toggle .uk-link-heading {
  color: inherit; }

a.uk-link-heading:hover,
.uk-link-heading a:hover,
.uk-link-toggle:hover .uk-link-heading {
  color: #1e87f0;
  text-decoration: none; }

/* Reset
 ========================================================================== */
/*
 * `!important` needed to override inverse component
 */
a.uk-link-reset,
.uk-link-reset a {
  color: inherit !important;
  text-decoration: none !important; }

/* Toggle
 ========================================================================== */
.uk-link-toggle {
  color: inherit !important;
  text-decoration: none !important; }

/* ========================================================================
   Component: Heading
 ========================================================================== */
.uk-heading-small {
  font-size: 2.6rem;
  line-height: 1.2; }

.uk-heading-medium {
  font-size: 2.8875rem;
  line-height: 1.1; }

.uk-heading-large {
  font-size: 3.4rem;
  line-height: 1.1; }

.uk-heading-xlarge {
  font-size: 4rem;
  line-height: 1; }

.uk-heading-2xlarge {
  font-size: 6rem;
  line-height: 1; }

/* Tablet Landscape and bigger */
@media (min-width: 960px) {
  .uk-heading-small {
    font-size: 3.25rem; }
  .uk-heading-medium {
    font-size: 3.5rem; }
  .uk-heading-large {
    font-size: 4rem; }
  .uk-heading-xlarge {
    font-size: 6rem; }
  .uk-heading-2xlarge {
    font-size: 8rem; } }

/* Laptop and bigger */
@media (min-width: 1200px) {
  .uk-heading-medium {
    font-size: 4rem; }
  .uk-heading-large {
    font-size: 6rem; }
  .uk-heading-xlarge {
    font-size: 8rem; }
  .uk-heading-2xlarge {
    font-size: 11rem; } }

/* Primary
   Deprecated: Use `uk-heading-medium` instead
 ========================================================================== */
/* Tablet landscape and bigger */
/* Desktop and bigger */
/* Hero
   Deprecated: Use `uk-heading-xlarge` instead
 ========================================================================== */
/* Tablet landscape and bigger */
/* Desktop and bigger */
/* Divider
 ========================================================================== */
.uk-heading-divider {
  padding-bottom: calc(5px + 0.1em);
  border-bottom: calc(0.2px + 0.05em) solid #e5e5e5; }

/* Bullet
 ========================================================================== */
.uk-heading-bullet {
  position: relative; }

/*
 * 1. Using `inline-block` to make it work with text alignment
 * 2. Center vertically
 * 3. Style
 */
.uk-heading-bullet::before {
  content: "";
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  top: calc(-0.1 * 1em);
  vertical-align: middle;
  /* 3 */
  height: calc(4px + 0.7em);
  margin-right: calc(5px + 0.2em);
  border-left: calc(5px + 0.1em) solid #e5e5e5; }

/* Line
 ========================================================================== */
/*
 * Clip the child element
 */
.uk-heading-line {
  overflow: hidden; }

/*
 * Extra markup is needed to make it work with text align
 */
.uk-heading-line > * {
  display: inline-block;
  position: relative; }

/*
 * 1. Center vertically
 * 2. Make the element as large as possible. It's clipped by the container.
 * 3. Style
 */
.uk-heading-line > ::before,
.uk-heading-line > ::after {
  content: "";
  /* 1 */
  position: absolute;
  top: calc(50% - (calc(0.2px + 0.05em) / 2));
  /* 2 */
  width: 2000px;
  /* 3 */
  border-bottom: calc(0.2px + 0.05em) solid #e5e5e5; }

.uk-heading-line > ::before {
  right: 100%;
  margin-right: calc(5px + 0.3em); }

.uk-heading-line > ::after {
  left: 100%;
  margin-left: calc(5px + 0.3em); }

/* ========================================================================
   Component: Divider
 ========================================================================== */
/*
 * 1. Reset default `hr`
 * 2. Set margin if a `div` is used for semantical reason
 */
[class*='uk-divider'] {
  /* 1 */
  border: none;
  /* 2 */
  margin-bottom: 20px; }

/* Add margin if adjacent element */
* + [class*='uk-divider'] {
  margin-top: 20px; }

/* Icon
 ========================================================================== */
.uk-divider-icon {
  position: relative;
  height: 20px;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22none%22%20stroke%3D%22%23e5e5e5%22%20stroke-width%3D%222%22%20cx%3D%2210%22%20cy%3D%2210%22%20r%3D%227%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
  background-repeat: no-repeat;
  background-position: 50% 50%; }

.uk-divider-icon::before,
.uk-divider-icon::after {
  content: "";
  position: absolute;
  top: 50%;
  max-width: calc(50% - (50px / 2));
  border-bottom: 1px solid #e5e5e5; }

.uk-divider-icon::before {
  right: calc(50% + (50px / 2));
  width: 100%; }

.uk-divider-icon::after {
  left: calc(50% + (50px / 2));
  width: 100%; }

/* Small
 ========================================================================== */
/*
 * 1. Fix height because of `inline-block`
 * 2. Using ::after and inline-block to make `text-align` work
 */
/* 1 */
.uk-divider-small {
  line-height: 0; }

/* 2 */
.uk-divider-small::after {
  content: "";
  display: inline-block;
  width: 100px;
  max-width: 100%;
  border-top: 1px solid #e5e5e5;
  vertical-align: top; }

/* Vertical
 ========================================================================== */
.uk-divider-vertical {
  width: max-content;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  border-left: 1px solid #e5e5e5; }

/* ========================================================================
   Component: List
 ========================================================================== */
.uk-list {
  padding: 0;
  list-style: none; }

/*
 * Avoid column break within the list item, when using `column-count`
 */
.uk-list > * {
  break-inside: avoid-column; }

/*
 * Remove margin from the last-child
 */
.uk-list > * > :last-child {
  margin-bottom: 0; }

/*
 * Style
 */
.uk-list > :nth-child(n+2),
.uk-list > * > ul {
  margin-top: 10px; }

/* Marker modifiers
 * Moving `::marker` inside `::before` to style it differently
 * To style the `::marker` is currently only supported in Firefox and Safari
 ========================================================================== */
.uk-list-disc > *,
.uk-list-circle > *,
.uk-list-square > *,
.uk-list-decimal > *,
.uk-list-hyphen > * {
  padding-left: 30px; }

/*
 * Type modifiers
 */
.uk-list-decimal {
  counter-reset: decimal; }

.uk-list-decimal > * {
  counter-increment: decimal; }

.uk-list-disc > ::before,
.uk-list-circle > ::before,
.uk-list-square > ::before,
.uk-list-decimal > ::before,
.uk-list-hyphen > ::before {
  content: "";
  position: relative;
  left: -30px;
  width: 30px;
  height: 1.5em;
  margin-bottom: -1.5em;
  display: list-item;
  list-style-position: inside;
  text-align: right; }

.uk-list-disc > ::before {
  list-style-type: disc; }

.uk-list-circle > ::before {
  list-style-type: circle; }

.uk-list-square > ::before {
  list-style-type: square; }

.uk-list-decimal > ::before {
  content: counter(decimal, decimal) " . "; }

.uk-list-hyphen > ::before {
  content: '–\00A0\00A0'; }

/*
 * Color modifiers
 */
.uk-list-muted > ::before {
  color: #999 !important; }

.uk-list-emphasis > ::before {
  color: #333 !important; }

.uk-list-primary > ::before {
  color: #1e87f0 !important; }

.uk-list-secondary > ::before {
  color: #222 !important; }

/* Image bullet modifier
 ========================================================================== */
.uk-list-bullet > * {
  padding-left: 30px; }

.uk-list-bullet > ::before {
  content: "";
  display: list-item;
  position: relative;
  left: -30px;
  width: 30px;
  height: 1.5em;
  margin-bottom: -1.5em;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%226%22%20height%3D%226%22%20viewBox%3D%220%200%206%206%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23666%22%20cx%3D%223%22%20cy%3D%223%22%20r%3D%223%22%20%2F%3E%0A%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%; }

/* Style modifiers
 ========================================================================== */
/*
 * Divider
 */
.uk-list-divider > :nth-child(n+2) {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #e5e5e5; }

/*
 * Striped
 */
.uk-list-striped > * {
  padding: 10px 10px; }

.uk-list-striped > :nth-of-type(odd) {
  background: #f8f8f8; }

.uk-list-striped > :nth-child(n+2) {
  margin-top: 0; }

/* Size modifier
 ========================================================================== */
.uk-list-large > :nth-child(n+2),
.uk-list-large > * > ul {
  margin-top: 20px; }

.uk-list-collapse > :nth-child(n+2),
.uk-list-collapse > * > ul {
  margin-top: 0; }

/*
 * Divider
 */
.uk-list-large.uk-list-divider > :nth-child(n+2) {
  margin-top: 20px;
  padding-top: 20px; }

.uk-list-collapse.uk-list-divider > :nth-child(n+2) {
  margin-top: 0;
  padding-top: 0; }

/*
 * Striped
 */
.uk-list-large.uk-list-striped > * {
  padding: 20px 10px; }

.uk-list-collapse.uk-list-striped > * {
  padding-top: 0;
  padding-bottom: 0; }

.uk-list-large.uk-list-striped > :nth-child(n+2),
.uk-list-collapse.uk-list-striped > :nth-child(n+2) {
  margin-top: 0; }

/* ========================================================================
   Component: Description list
 ========================================================================== */
/*
 * Term
 */
.uk-description-list > dt {
  color: #333; }

.uk-description-list > dt:nth-child(n+2) {
  margin-top: 20px; }

/*
 * Description
 */
/* Style modifier
 ========================================================================== */
/*
 * Line
 */
.uk-description-list-divider > dt:nth-child(n+2) {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #e5e5e5; }

/* ========================================================================
   Component: Table
 ========================================================================== */
/*
 * 1. Remove most spacing between table cells.
 * 2. Behave like a block element
 * 3. Style
 */
.uk-table {
  /* 1 */
  border-collapse: collapse;
  border-spacing: 0;
  /* 2 */
  width: 100%;
  /* 3 */
  margin-bottom: 20px; }

/* Add margin if adjacent element */
* + .uk-table {
  margin-top: 20px; }

/* Header cell
 ========================================================================== */
/*
 * 1. Style
 */
.uk-table th {
  padding: 16px 12px;
  text-align: left;
  vertical-align: bottom;
  /* 1 */
  font-size: 16px;
  font-weight: bold;
  color: #666; }

/* Cell
 ========================================================================== */
.uk-table td {
  padding: 16px 12px;
  vertical-align: top; }

/*
 * Remove margin from the last-child
 */
.uk-table td > :last-child {
  margin-bottom: 0; }

/* Footer
 ========================================================================== */
.uk-table tfoot {
  font-size: 0.875rem; }

/* Caption
 ========================================================================== */
.uk-table caption {
  font-size: 0.875rem;
  text-align: left;
  color: #999; }

/* Alignment modifier
 ========================================================================== */
.uk-table-middle,
.uk-table-middle td {
  vertical-align: middle !important; }

/* Style modifiers
 ========================================================================== */
/*
 * Divider
 */
.uk-table-divider > tr:not(:first-child),
.uk-table-divider > :not(:first-child) > tr,
.uk-table-divider > :first-child > tr:not(:first-child) {
  border-top: 1px solid #e5e5e5; }

/*
 * Striped
 */
.uk-table-striped > tr:nth-of-type(odd),
.uk-table-striped tbody tr:nth-of-type(odd) {
  background: #f8f8f8; }

/*
 * Hover
 */
.uk-table-hover > tr:hover,
.uk-table-hover tbody tr:hover {
  background: #ffd; }

/* Active state
 ========================================================================== */
.uk-table > tr.uk-active,
.uk-table tbody tr.uk-active {
  background: #ffd; }

/* Size modifier
 ========================================================================== */
.uk-table-small th,
.uk-table-small td {
  padding: 10px 12px; }

.uk-table-large th,
.uk-table-large td {
  padding: 22px 12px; }

/* Justify modifier
 ========================================================================== */
.uk-table-justify th:first-child,
.uk-table-justify td:first-child {
  padding-left: 0; }

.uk-table-justify th:last-child,
.uk-table-justify td:last-child {
  padding-right: 0; }

/* Cell size modifier
 ========================================================================== */
.uk-table-shrink {
  width: 1px; }

.uk-table-expand {
  min-width: 150px; }

/* Cell link modifier
 ========================================================================== */
/*
 * Does not work with `uk-table-justify` at the moment
 */
.uk-table-link {
  padding: 0 !important; }

.uk-table-link > a {
  display: block;
  padding: 16px 12px; }

.uk-table-small .uk-table-link > a {
  padding: 10px 12px; }

/* Responsive table
 ========================================================================== */
/* Phone landscape and smaller */
@media (max-width: 959px) {
  .uk-table-responsive,
  .uk-table-responsive tbody,
  .uk-table-responsive th,
  .uk-table-responsive td,
  .uk-table-responsive tr {
    display: block; }
  .uk-table-responsive thead {
    display: none; }
  .uk-table-responsive th,
  .uk-table-responsive td {
    width: auto !important;
    max-width: none !important;
    min-width: 0 !important;
    overflow: visible !important;
    white-space: normal !important; }
  .uk-table-responsive th:not(:first-child):not(.uk-table-link),
  .uk-table-responsive td:not(:first-child):not(.uk-table-link),
  .uk-table-responsive .uk-table-link:not(:first-child) > a {
    padding-top: 5px !important; }
  .uk-table-responsive th:not(:last-child):not(.uk-table-link),
  .uk-table-responsive td:not(:last-child):not(.uk-table-link),
  .uk-table-responsive .uk-table-link:not(:last-child) > a {
    padding-bottom: 5px !important; }
  .uk-table-justify.uk-table-responsive th,
  .uk-table-justify.uk-table-responsive td {
    padding-left: 0;
    padding-right: 0; } }

/* ========================================================================
   Component: Icon
 ========================================================================== */
/*
 * Note: 1. - 7. is required for `button` elements. Needed for Close and Form Icon component.
 * 1. Remove margins in Chrome, Safari and Opera.
 * 2. Remove borders for `button`.
 * 3. Remove border-radius in Chrome.
 * 4. Address `overflow` set to `hidden` in IE.
 * 5. Correct `font` properties and `color` not being inherited for `button`.
 * 6. Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 7. Remove default `button` padding and background color
 * 8. Style
 * 9. Fill all SVG elements with the current text color if no `fill` attribute is set
 * 10. Let the container fit the height of the icon
 */
.uk-icon {
  /* 1 */
  margin: 0;
  /* 2 */
  border: none;
  /* 3 */
  border-radius: 0;
  /* 4 */
  overflow: visible;
  /* 5 */
  font: inherit;
  color: inherit;
  /* 6 */
  text-transform: none;
  /* 7. */
  padding: 0;
  background-color: transparent;
  /* 8 */
  display: inline-block;
  /* 9 */
  fill: currentcolor;
  /* 10 */
  line-height: 0; }

/* Required for `button`. */
button.uk-icon:not(:disabled) {
  cursor: pointer; }

/*
 * Remove the inner border and padding in Firefox.
 */
.uk-icon::-moz-focus-inner {
  border: 0;
  padding: 0; }

/*
 * Set the fill and stroke color of all SVG elements to the current text color
 */
.uk-icon:not(.uk-preserve) [fill*='#']:not(.uk-preserve) {
  fill: currentcolor; }

.uk-icon:not(.uk-preserve) [stroke*='#']:not(.uk-preserve) {
  stroke: currentcolor; }

/*
 * Fix Firefox blurry SVG rendering: https://bugzilla.mozilla.org/show_bug.cgi?id=1046835
 */
.uk-icon > * {
  transform: translate(0, 0); }

/* Image modifier
 ========================================================================== */
/*
 * Display images in icon dimensions
 * 1. Required for `span` with background image
 * 2. Required for `image`
 */
.uk-icon-image {
  width: 20px;
  height: 20px;
  /* 1 */
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  vertical-align: middle;
  /* 2 */
  object-fit: scale-down;
  max-width: none; }

/* Style modifiers
 ========================================================================== */
/*
 * Link
 * 1. Allow text within link
 */
.uk-icon-link {
  color: #999;
  /* 1 */
  text-decoration: none !important; }

.uk-icon-link:hover {
  color: #666; }

/* OnClick + Active */
.uk-icon-link:active,
.uk-active > .uk-icon-link {
  color: #595959; }

/*
 * Button
 * 1. Center icon vertically and horizontally
 */
.uk-icon-button {
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  border-radius: 500px;
  background: #f8f8f8;
  color: #999;
  vertical-align: middle;
  /* 1 */
  display: inline-flex;
  justify-content: center;
  align-items: center; }

/* Hover */
.uk-icon-button:hover {
  background-color: #ebebeb;
  color: #666; }

/* OnClick + Active */
.uk-icon-button:active,
.uk-active > .uk-icon-button {
  background-color: #dfdede;
  color: #666; }

/* ========================================================================
   Component: Form Range
 ========================================================================== */
/*
 * 1. Remove default style.
 * 2. Define consistent box sizing.
 * 3. Remove `margin` in all browsers.
 * 4. Align to the center of the line box.
 * 5. Prevent content overflow if a fixed width is used.
 * 6. Take the full width.
 * 7. Remove white background in Chrome.
 */
.uk-range {
  /* 1 */
  -webkit-appearance: none;
  /* 2 */
  box-sizing: border-box;
  /* 3 */
  margin: 0;
  /* 4 */
  vertical-align: middle;
  /* 5 */
  max-width: 100%;
  /* 6 */
  width: 100%;
  /* 7 */
  background: transparent; }

/* Focus */
.uk-range:focus {
  outline: none; }

.uk-range::-moz-focus-outer {
  border: none; }

/*
 * Improves consistency of cursor style for clickable elements
 */
.uk-range:not(:disabled)::-webkit-slider-thumb {
  cursor: pointer; }

.uk-range:not(:disabled)::-moz-range-thumb {
  cursor: pointer; }

/*
 * Track
 * 1. Safari doesn't have a focus state. Using active instead.
 */
/* Webkit */
.uk-range::-webkit-slider-runnable-track {
  height: 3px;
  background: #ebebeb; }

.uk-range:focus::-webkit-slider-runnable-track,
.uk-range:active::-webkit-slider-runnable-track {
  background: #dfdede; }

/* Firefox */
.uk-range::-moz-range-track {
  height: 3px;
  background: #ebebeb; }

.uk-range:focus::-moz-range-track {
  background: #dfdede; }

/*
 * Thumb
 * 1. Reset
 * 2. Style
 */
/* Webkit */
.uk-range::-webkit-slider-thumb {
  /* 1 */
  -webkit-appearance: none;
  margin-top: -7px;
  /* 2 */
  height: 15px;
  width: 15px;
  border-radius: 500px;
  background: #666; }

/* Firefox */
.uk-range::-moz-range-thumb {
  /* 1 */
  border: none;
  /* 2 */
  height: 15px;
  width: 15px;
  margin-top: -7px;
  border-radius: 500px;
  background: #666; }

/* ========================================================================
   Component: Form
 ========================================================================== */
/*
 * 1. Define consistent box sizing.
 *    Default is `content-box` with following exceptions set to `border-box`
 *    `select`, `input[type="checkbox"]` and `input[type="radio"]`
 *    `input[type="search"]` in Chrome, Safari and Opera
 *    `input[type="color"]` in Firefox
 * 2. Address margins set differently in Firefox/IE and Chrome/Safari/Opera.
 * 3. Remove `border-radius` in iOS.
 * 4. Change font properties to `inherit` in all browsers.
 */
.uk-input,
.uk-select,
.uk-textarea,
.uk-radio,
.uk-checkbox {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  margin: 0;
  /* 3 */
  border-radius: 0;
  /* 4 */
  font: inherit; }

/*
 * Show the overflow in Edge.
 */
.uk-input {
  overflow: visible; }

/*
 * Remove the inheritance of text transform in Firefox.
 */
.uk-select {
  text-transform: none; }

/*
 * 1. Change font properties to `inherit` in all browsers
 * 2. Don't inherit the `font-weight` and use `bold` instead.
 * NOTE: Both declarations don't work in Chrome, Safari and Opera.
 */
.uk-select optgroup {
  /* 1 */
  font: inherit;
  /* 2 */
  font-weight: bold; }

/*
 * Remove the default vertical scrollbar in IE 10+.
 */
.uk-textarea {
  overflow: auto; }

/*
 * Remove the inner padding and cancel buttons in Chrome on OS X and Safari on OS X.
 */
.uk-input[type="search"]::-webkit-search-cancel-button,
.uk-input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/*
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
.uk-input[type="number"]::-webkit-inner-spin-button,
.uk-input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/*
 * Removes placeholder transparency in Firefox.
 */
.uk-input::-moz-placeholder,
.uk-textarea::-moz-placeholder {
  opacity: 1; }

/*
 * Improves consistency of cursor style for clickable elements
 */
.uk-radio:not(:disabled),
.uk-checkbox:not(:disabled) {
  cursor: pointer; }

/*
 * Define consistent border, margin, and padding.
 */
.uk-fieldset {
  border: none;
  margin: 0;
  padding: 0; }

/* Input, select and textarea
 * Allowed: `text`, `password`, `datetime`, `datetime-local`, `date`,  `month`,
            `time`, `week`, `number`, `email`, `url`, `search`, `tel`, `color`
 * Disallowed: `range`, `radio`, `checkbox`, `file`, `submit`, `reset` and `image`
 ========================================================================== */
/*
 * Remove default style in iOS.
 */
.uk-input,
.uk-textarea {
  -webkit-appearance: none; }

/*
 * 1. Prevent content overflow if a fixed width is used
 * 2. Take the full width
 * 3. Reset default
 * 4. Style
 */
.uk-input,
.uk-select,
.uk-textarea {
  /* 1 */
  max-width: 100%;
  /* 2 */
  width: 100%;
  /* 3 */
  border: 0 none;
  /* 4 */
  padding: 0 10px;
  background: #f8f8f8;
  color: #666; }

/*
 * Single-line
 * 1. Allow any element to look like an `input` or `select` element
 * 2. Make sure line-height is not larger than height
 *    Also needed to center the text vertically
 */
.uk-input,
.uk-select:not([multiple]):not([size]) {
  height: 40px;
  vertical-align: middle;
  /* 1 */
  display: inline-block; }

/* 2 */
.uk-input:not(input),
.uk-select:not(select) {
  line-height: 40px; }

/*
 * Multi-line
 */
.uk-select[multiple],
.uk-select[size],
.uk-textarea {
  padding-top: 6px;
  padding-bottom: 6px;
  vertical-align: top; }

.uk-select[multiple],
.uk-select[size] {
  resize: vertical; }

/* Focus */
.uk-input:focus,
.uk-select:focus,
.uk-textarea:focus {
  outline: none;
  background-color: #ebebeb;
  color: #666; }

/* Disabled */
.uk-input:disabled,
.uk-select:disabled,
.uk-textarea:disabled {
  background-color: #f8f8f8;
  color: #999; }

/*
 * Placeholder
 */
.uk-input::placeholder {
  color: #999; }

.uk-textarea::placeholder {
  color: #999; }

/* Style modifier (`uk-input`, `uk-select` and `uk-textarea`)
 ========================================================================== */
/*
 * Small
 */
.uk-form-small {
  font-size: 0.875rem; }

/* Single-line */
.uk-form-small:not(textarea):not([multiple]):not([size]) {
  height: 30px;
  padding-left: 8px;
  padding-right: 8px; }

/* Multi-line */
textarea.uk-form-small,
[multiple].uk-form-small,
[size].uk-form-small {
  padding: 5px 8px; }

.uk-form-small:not(select):not(input):not(textarea) {
  line-height: 30px; }

/*
 * Large
 */
.uk-form-large {
  font-size: 1.25rem; }

/* Single-line */
.uk-form-large:not(textarea):not([multiple]):not([size]) {
  height: 55px;
  padding-left: 12px;
  padding-right: 12px; }

/* Multi-line */
textarea.uk-form-large,
[multiple].uk-form-large,
[size].uk-form-large {
  padding: 7px 12px; }

.uk-form-large:not(select):not(input):not(textarea) {
  line-height: 55px; }

/* Style modifier (`uk-input`, `uk-select` and `uk-textarea`)
 ========================================================================== */
/*
 * Error
 */
.uk-form-danger,
.uk-form-danger:focus {
  color: #f0506e; }

/*
 * Success
 */
.uk-form-success,
.uk-form-success:focus {
  color: #32d296; }

/*
 * Blank
 */
.uk-form-blank {
  background: none; }

/* Width modifiers (`uk-input`, `uk-select` and `uk-textarea`)
 ========================================================================== */
/*
 * Fixed widths
 * Different widths for mini sized `input` and `select` elements
 */
input.uk-form-width-xsmall {
  width: 50px; }

select.uk-form-width-xsmall {
  width: 75px; }

.uk-form-width-small {
  width: 130px; }

.uk-form-width-medium {
  width: 200px; }

.uk-form-width-large {
  width: 500px; }

/* Select
 ========================================================================== */
/*
 * 1. Remove default style. Also works in Firefox
 * 2. Style
 * 3. Set `color` for options in the select dropdown, because the inherited `color` might be too light.
 */
.uk-select:not([multiple]):not([size]) {
  /* 1 */
  -webkit-appearance: none;
  -moz-appearance: none;
  /* 2 */
  padding-right: 20px;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
  background-repeat: no-repeat;
  background-position: 100% 50%; }

/* 3 */
.uk-select:not([multiple]):not([size]) option {
  color: #666; }

/*
 * Disabled
 */
.uk-select:not([multiple]):not([size]):disabled {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23999%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23999%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A"); }

/* Datalist
 ========================================================================== */
/*
 * 1. Remove default style in Chrome
 */
.uk-input[list] {
  padding-right: 20px;
  background-repeat: no-repeat;
  background-position: 100% 50%; }

.uk-input[list]:hover,
.uk-input[list]:focus {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%2012%208%206%2016%206%22%20%2F%3E%0A%3C%2Fsvg%3E%0A"); }

/* 1 */
.uk-input[list]::-webkit-calendar-picker-indicator {
  display: none !important; }

/* Radio and checkbox
 ========================================================================== */
/*
 * 1. Style
 * 2. Make box more robust so it clips the child element
 * 3. Vertical alignment
 * 4. Remove default style
 * 5. Fix black background on iOS
 * 6. Center icons
 */
.uk-radio,
.uk-checkbox {
  /* 1 */
  display: inline-block;
  height: 16px;
  width: 16px;
  /* 2 */
  overflow: hidden;
  /* 3 */
  margin-top: -4px;
  vertical-align: middle;
  /* 4 */
  -webkit-appearance: none;
  -moz-appearance: none;
  /* 5 */
  background-color: #ebebeb;
  /* 6 */
  background-repeat: no-repeat;
  background-position: 50% 50%; }

.uk-radio {
  border-radius: 50%; }

/* Focus */
.uk-radio:focus,
.uk-checkbox:focus {
  background-color: #dfdede;
  outline: none; }

/*
 * Checked
 */
.uk-radio:checked,
.uk-checkbox:checked,
.uk-checkbox:indeterminate {
  background-color: #1e87f0; }

/* Focus */
.uk-radio:checked:focus,
.uk-checkbox:checked:focus,
.uk-checkbox:indeterminate:focus {
  background-color: #0e6ecd; }

/*
 * Icons
 */
.uk-radio:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23fff%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E"); }

.uk-checkbox:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23fff%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A"); }

.uk-checkbox:indeterminate {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23fff%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%20%2F%3E%0A%3C%2Fsvg%3E"); }

/*
 * Disabled
 */
.uk-radio:disabled,
.uk-checkbox:disabled {
  background-color: #f8f8f8; }

.uk-radio:disabled:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23999%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E"); }

.uk-checkbox:disabled:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23999%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A"); }

.uk-checkbox:disabled:indeterminate {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23999%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%20%2F%3E%0A%3C%2Fsvg%3E"); }

/* Legend
 ========================================================================== */
/*
 * Legend
 * 1. Behave like block element
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove padding so people aren't caught out if they zero out fieldsets.
 * 4. Style
 */
.uk-legend {
  /* 1 */
  width: 100%;
  /* 2 */
  color: inherit;
  /* 3 */
  padding: 0;
  /* 4 */
  font-size: 1.5rem;
  line-height: 1.4; }

/* Custom controls
 ========================================================================== */
/*
 * 1. Container fits its content
 * 2. Create position context
 * 3. Prevent content overflow
 * 4. Behave like most inline-block elements
 */
.uk-form-custom {
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  /* 3 */
  max-width: 100%;
  /* 4 */
  vertical-align: middle; }

/*
 * 1. Position and resize the form control to always cover its container
 * 2. Required for Firefox for positioning to the left
 * 3. Required for Webkit to make `height` work
 * 4. Hide controle and show cursor
 * 5. Needed for the cursor
 * 6. Clip height caused by 5. Needed for Webkit only
 */
.uk-form-custom select,
.uk-form-custom input[type="file"] {
  /* 1 */
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  /* 2 */
  left: 0;
  /* 3 */
  -webkit-appearance: none;
  /* 4 */
  opacity: 0;
  cursor: pointer; }

.uk-form-custom input[type="file"] {
  /* 5 */
  font-size: 500px;
  /* 6 */
  overflow: hidden; }

/* Label
 ========================================================================== */
/* Layout
 ========================================================================== */
/*
 * Stacked
 */
.uk-form-stacked .uk-form-label {
  display: block;
  margin-bottom: 10px; }

/*
 * Horizontal
 */
/* Tablet portrait and smaller */
@media (max-width: 959px) {
  /* Behave like `uk-form-stacked` */
  .uk-form-horizontal .uk-form-label {
    display: block;
    margin-bottom: 10px; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-form-horizontal .uk-form-label {
    width: 200px;
    margin-top: 7px;
    float: left; }
  .uk-form-horizontal .uk-form-controls {
    margin-left: 215px; }
  /* Better vertical alignment if controls are checkboxes and radio buttons with text */
  .uk-form-horizontal .uk-form-controls-text {
    padding-top: 7px; } }

/* Icons
 ========================================================================== */
/*
 * 1. Set position
 * 2. Set width
 * 3. Center icon vertically and horizontally
 * 4. Style
 */
.uk-form-icon {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  width: 40px;
  /* 3 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* 4 */
  color: #999; }

/*
 * Required for `a`.
 */
.uk-form-icon:hover {
  color: #666; }

/*
 * Make `input` element clickable through icon, e.g. if it's a `span`
 */
.uk-form-icon:not(a):not(button):not(input) {
  pointer-events: none; }

/*
 * Input padding
 */
.uk-form-icon:not(.uk-form-icon-flip) ~ .uk-input {
  padding-left: 40px !important; }

/*
 * Position modifier
 */
.uk-form-icon-flip {
  right: 0;
  left: auto; }

.uk-form-icon-flip ~ .uk-input {
  padding-right: 40px !important; }

/* ========================================================================
   Component: Button
 ========================================================================== */
/*
 * 1. Remove margins in Chrome, Safari and Opera.
 * 2. Remove borders for `button`.
 * 3. Address `overflow` set to `hidden` in IE.
 * 4. Correct `font` properties and `color` not being inherited for `button`.
 * 5. Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 6. Remove default style for `input type="submit"`in iOS.
 * 7. Style
 * 8. `line-height` is used to create a height because it also centers the text vertically for `a` elements.
 *    Better would be to use height and flexbox to center the text vertically but flexbox doesn't work in Firefox on `button` elements.
 * 9. Align text if button has a width
 * 10. Required for `a`.
 */
.uk-button {
  /* 1 */
  margin: 0;
  /* 2 */
  border: none;
  /* 3 */
  overflow: visible;
  /* 4 */
  font: inherit;
  color: inherit;
  /* 5 */
  text-transform: none;
  /* 6 */
  -webkit-appearance: none;
  border-radius: 0;
  /* 7 */
  display: inline-block;
  box-sizing: border-box;
  padding: 0 30px;
  vertical-align: middle;
  font-size: 16px;
  /* 8 */
  line-height: 40px;
  /* 9 */
  text-align: center;
  /* 10 */
  text-decoration: none; }

.uk-button:not(:disabled) {
  cursor: pointer; }

/*
 * Remove the inner border and padding in Firefox.
 */
.uk-button::-moz-focus-inner {
  border: 0;
  padding: 0; }

/* Hover */
.uk-button:hover {
  /* 9 */
  text-decoration: none; }

/* OnClick + Active */
/* Style modifiers
 ========================================================================== */
/*
 * Default
 */
.uk-button-default {
  background-color: #f8f8f8;
  color: #333; }

/* Hover */
.uk-button-default:hover {
  background-color: #ebebeb;
  color: #333; }

/* OnClick + Active */
.uk-button-default:active,
.uk-button-default.uk-active {
  background-color: #dfdede;
  color: #333; }

/*
 * Primary
 */
.uk-button-primary {
  background-color: #1e87f0;
  color: #fff; }

/* Hover */
.uk-button-primary:hover {
  background-color: #0f7ae5;
  color: #fff; }

/* OnClick + Active */
.uk-button-primary:active,
.uk-button-primary.uk-active {
  background-color: #0e6ecd;
  color: #fff; }

/*
 * Secondary
 */
.uk-button-secondary {
  background-color: #222;
  color: #fff; }

/* Hover */
.uk-button-secondary:hover {
  background-color: #151515;
  color: #fff; }

/* OnClick + Active */
.uk-button-secondary:active,
.uk-button-secondary.uk-active {
  background-color: #090808;
  color: #fff; }

/*
 * Danger
 */
.uk-button-danger {
  background-color: #f0506e;
  color: #fff; }

/* Hover */
.uk-button-danger:hover {
  background-color: #ee395b;
  color: #fff; }

/* OnClick + Active */
.uk-button-danger:active,
.uk-button-danger.uk-active {
  background-color: #ec2147;
  color: #fff; }

/*
 * Disabled
 * The same for all style modifiers
 */
.uk-button-default:disabled,
.uk-button-primary:disabled,
.uk-button-secondary:disabled,
.uk-button-danger:disabled {
  background-color: #f8f8f8;
  color: #999; }

/* Size modifiers
 ========================================================================== */
.uk-button-small {
  padding: 0 15px;
  line-height: 30px;
  font-size: 0.875rem; }

.uk-button-large {
  padding: 0 40px;
  line-height: 55px;
  font-size: 1.25rem; }

/* Text modifiers
 ========================================================================== */
/*
 * Text
 * 1. Reset
 * 2. Style
 */
.uk-button-text {
  /* 1 */
  padding: 0;
  line-height: 1.5;
  background: none;
  /* 2 */
  color: #333; }

/* Hover */
.uk-button-text:hover {
  color: #999; }

/* Disabled */
.uk-button-text:disabled {
  color: #999; }

/*
 * Link
 * 1. Reset
 * 2. Style
 */
.uk-button-link {
  /* 1 */
  padding: 0;
  line-height: 1.5;
  background: none;
  /* 2 */
  color: #333; }

/* Hover */
.uk-button-link:hover {
  color: #999;
  text-decoration: none; }

/* Disabled */
.uk-button-link:disabled {
  color: #999;
  text-decoration: none; }

/* Group
 ========================================================================== */
/*
 * 1. Using `flex` instead of `inline-block` to prevent whitespace betweent child elements
 * 2. Behave like button
 * 3. Create position context
 */
.uk-button-group {
  /* 1 */
  display: inline-flex;
  /* 2 */
  vertical-align: middle;
  /* 3 */
  position: relative; }

/* ========================================================================
   Component: Progress
 ========================================================================== */
/*
 * 1. Add the correct vertical alignment in all browsers.
 * 2. Behave like a block element.
 * 3. Remove borders in Firefox.
 * 4. Remove default style in Chrome, Safari and Edge.
 * 5. Style
 */
.uk-progress {
  /* 1 */
  vertical-align: baseline;
  /* 2 */
  display: block;
  width: 100%;
  /* 3 */
  border: 0;
  /* 4 */
  background-color: #f8f8f8;
  /* 5 */
  margin-bottom: 20px;
  height: 15px; }

/* Add margin if adjacent element */
* + .uk-progress {
  margin-top: 20px; }

/*
 * Show background color set on `uk-progress` in Chrome, Safari and Edge.
 */
.uk-progress::-webkit-progress-bar {
  background-color: transparent; }

/*
 * Progress Bar
 * 1. Transitions don't work on `::-moz-progress-bar` pseudo element in Firefox yet.
 *    https://bugzilla.mozilla.org/show_bug.cgi?id=662351
 */
.uk-progress::-webkit-progress-value {
  background-color: #1e87f0;
  transition: width 0.6s ease; }

.uk-progress::-moz-progress-bar {
  background-color: #1e87f0;
  /* 1 */
  transition: width 0.6s ease; }

/* ========================================================================
   Component: Section
 ========================================================================== */
/*
 * 1. Make it work with `100vh` and height in general
 */
.uk-section {
  display: flow-root;
  box-sizing: border-box;
  /* 1 */
  padding-top: 40px;
  padding-bottom: 40px; }

/* Desktop and bigger */
@media (min-width: 960px) {
  .uk-section {
    padding-top: 70px;
    padding-bottom: 70px; } }

/*
 * Remove margin from the last-child
 */
.uk-section > :last-child {
  margin-bottom: 0; }

/* Size modifiers
 ========================================================================== */
/*
 * XSmall
 */
.uk-section-xsmall {
  padding-top: 20px;
  padding-bottom: 20px; }

/*
 * Small
 */
.uk-section-small {
  padding-top: 40px;
  padding-bottom: 40px; }

/*
 * Large
 */
.uk-section-large {
  padding-top: 70px;
  padding-bottom: 70px; }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-section-large {
    padding-top: 140px;
    padding-bottom: 140px; } }

/*
 * XLarge
 */
.uk-section-xlarge {
  padding-top: 140px;
  padding-bottom: 140px; }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-section-xlarge {
    padding-top: 210px;
    padding-bottom: 210px; } }

/* Style modifiers
 ========================================================================== */
/*
 * Default
 */
.uk-section-default {
  background: #fff; }

/*
 * Muted
 */
.uk-section-muted {
  background: #f8f8f8; }

/*
 * Primary
 */
.uk-section-primary {
  background: #1e87f0; }

/*
 * Secondary
 */
.uk-section-secondary {
  background: #222; }

/* Overlap modifier
 ========================================================================== */
/*
 * Reserved modifier to make a section overlap another section with an border image
 * Implemented by the theme
 */
/* ========================================================================
   Component: Container
 ========================================================================== */
/*
 * 1. Box sizing has to be `content-box` so the max-width is always the same and
 *    unaffected by the padding on different breakpoints. It's important for the size modifiers.
 */
.uk-container {
  display: flow-root;
  /* 1 */
  box-sizing: content-box;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-container {
    padding-left: 30px;
    padding-right: 30px; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-container {
    padding-left: 40px;
    padding-right: 40px; } }

/*
 * Remove margin from the last-child
 */
.uk-container > :last-child {
  margin-bottom: 0; }

/*
 * Remove padding from nested containers
 */
.uk-container .uk-container {
  padding-left: 0;
  padding-right: 0; }

/* Size modifier
 ========================================================================== */
.uk-container-xsmall {
  max-width: 750px; }

.uk-container-small {
  max-width: 900px; }

.uk-container-large {
  max-width: 1400px; }

.uk-container-xlarge {
  max-width: 1600px; }

.uk-container-expand {
  max-width: none; }

/* Expand modifier
 ========================================================================== */
/*
 * Expand one side only
 */
.uk-container-expand-left {
  margin-left: 0; }

.uk-container-expand-right {
  margin-right: 0; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-container-expand-left.uk-container-xsmall,
  .uk-container-expand-right.uk-container-xsmall {
    max-width: calc(50% + (750px / 2) - 30px); }
  .uk-container-expand-left.uk-container-small,
  .uk-container-expand-right.uk-container-small {
    max-width: calc(50% + (900px / 2) - 30px); } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-container-expand-left,
  .uk-container-expand-right {
    max-width: calc(50% + (1200px / 2) - 40px); }
  .uk-container-expand-left.uk-container-xsmall,
  .uk-container-expand-right.uk-container-xsmall {
    max-width: calc(50% + (750px / 2) - 40px); }
  .uk-container-expand-left.uk-container-small,
  .uk-container-expand-right.uk-container-small {
    max-width: calc(50% + (900px / 2) - 40px); }
  .uk-container-expand-left.uk-container-large,
  .uk-container-expand-right.uk-container-large {
    max-width: calc(50% + (1400px / 2) - 40px); }
  .uk-container-expand-left.uk-container-xlarge,
  .uk-container-expand-right.uk-container-xlarge {
    max-width: calc(50% + (1600px / 2) - 40px); } }

/* Item
 ========================================================================== */
/*
 * Utility classes to reset container padding on the left or right side
 * Note: It has to be negative margin on the item, because it's specific to the item.
 */
.uk-container-item-padding-remove-left,
.uk-container-item-padding-remove-right {
  width: calc(100% + 15px); }

.uk-container-item-padding-remove-left {
  margin-left: -15px; }

.uk-container-item-padding-remove-right {
  margin-right: -15px; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-container-item-padding-remove-left,
  .uk-container-item-padding-remove-right {
    width: calc(100% + 30px); }
  .uk-container-item-padding-remove-left {
    margin-left: -30px; }
  .uk-container-item-padding-remove-right {
    margin-right: -30px; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-container-item-padding-remove-left,
  .uk-container-item-padding-remove-right {
    width: calc(100% + 40px); }
  .uk-container-item-padding-remove-left {
    margin-left: -40px; }
  .uk-container-item-padding-remove-right {
    margin-right: -40px; } }

/* ========================================================================
   Component: Tile
 ========================================================================== */
.uk-tile {
  display: flow-root;
  position: relative;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 40px;
  padding-bottom: 40px; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-tile {
    padding-left: 30px;
    padding-right: 30px; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-tile {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 70px;
    padding-bottom: 70px; } }

/*
 * Remove margin from the last-child
 */
.uk-tile > :last-child {
  margin-bottom: 0; }

/* Size modifiers
 ========================================================================== */
/*
 * XSmall
 */
.uk-tile-xsmall {
  padding-top: 20px;
  padding-bottom: 20px; }

/*
 * Small
 */
.uk-tile-small {
  padding-top: 40px;
  padding-bottom: 40px; }

/*
 * Large
 */
.uk-tile-large {
  padding-top: 70px;
  padding-bottom: 70px; }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-tile-large {
    padding-top: 140px;
    padding-bottom: 140px; } }

/*
 * XLarge
 */
.uk-tile-xlarge {
  padding-top: 140px;
  padding-bottom: 140px; }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-tile-xlarge {
    padding-top: 210px;
    padding-bottom: 210px; } }

/* Style modifiers
 ========================================================================== */
/*
 * Default
 */
.uk-tile-default {
  background-color: #fff; }

/*
 * Muted
 */
.uk-tile-muted {
  background-color: #f8f8f8; }

/*
 * Primary
 */
.uk-tile-primary {
  background-color: #1e87f0; }

/*
 * Secondary
 */
.uk-tile-secondary {
  background-color: #222; }

/* ========================================================================
   Component: Card
 ========================================================================== */
.uk-card {
  position: relative;
  box-sizing: border-box; }

/* Sections
 ========================================================================== */
.uk-card-body {
  display: flow-root;
  padding: 30px 30px; }

.uk-card-header {
  display: flow-root;
  padding: 15px 30px; }

.uk-card-footer {
  display: flow-root;
  padding: 15px 30px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-card-body {
    padding: 40px 40px; }
  .uk-card-header {
    padding: 20px 40px; }
  .uk-card-footer {
    padding: 20px 40px; } }

/*
 * Remove margin from the last-child
 */
.uk-card-body > :last-child,
.uk-card-header > :last-child,
.uk-card-footer > :last-child {
  margin-bottom: 0; }

/* Media
 ========================================================================== */
/*
 * Reserved alignment modifier to style the media element, e.g. with `border-radius`
 * Implemented by the theme
 */
/* Title
 ========================================================================== */
.uk-card-title {
  font-size: 1.5rem;
  line-height: 1.4; }

/* Badge
 ========================================================================== */
/*
 * 1. Position
 * 2. Size
 * 3. Style
 * 4. Center child vertically
 */
.uk-card-badge {
  /* 1 */
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
  /* 2 */
  height: 22px;
  padding: 0 10px;
  /* 3 */
  background: #1e87f0;
  color: #fff;
  font-size: 0.875rem;
  /* 4 */
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0; }

/*
 * Remove margin from adjacent element
 */
.uk-card-badge:first-child + * {
  margin-top: 0; }

/* Hover modifier
 ========================================================================== */
.uk-card-hover:not(.uk-card-default):not(.uk-card-primary):not(.uk-card-secondary):hover {
  background-color: #f8f8f8; }

/* Style modifiers
 ========================================================================== */
/*
 * Default
 * Note: Header and Footer are only implemented for the default style
 */
.uk-card-default {
  background-color: #f8f8f8;
  color: #666; }

.uk-card-default .uk-card-title {
  color: #333; }

.uk-card-default.uk-card-hover:hover {
  background-color: #ebebeb; }

/*
 * Primary
 */
.uk-card-primary {
  background-color: #1e87f0;
  color: #fff; }

.uk-card-primary .uk-card-title {
  color: #fff; }

.uk-card-primary.uk-card-hover:hover {
  background-color: #0f7ae5; }

/*
 * Secondary
 */
.uk-card-secondary {
  background-color: #222;
  color: #fff; }

.uk-card-secondary .uk-card-title {
  color: #fff; }

.uk-card-secondary.uk-card-hover:hover {
  background-color: #151515; }

/* Size modifier
 ========================================================================== */
/*
 * Small
 */
.uk-card-small.uk-card-body,
.uk-card-small .uk-card-body {
  padding: 20px 20px; }

.uk-card-small .uk-card-header {
  padding: 13px 20px; }

.uk-card-small .uk-card-footer {
  padding: 13px 20px; }

/*
 * Large
 */
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-card-large.uk-card-body,
  .uk-card-large .uk-card-body {
    padding: 70px 70px; }
  .uk-card-large .uk-card-header {
    padding: 35px 70px; }
  .uk-card-large .uk-card-footer {
    padding: 35px 70px; } }

/* ========================================================================
   Component: Close
 ========================================================================== */
/*
 * Adopts `uk-icon`
 */
.uk-close {
  color: #999; }

/* Hover */
.uk-close:hover {
  color: #666; }

/* ========================================================================
   Component: Spinner
 ========================================================================== */
/*
 * Adopts `uk-icon`
 */
/* SVG
 ========================================================================== */
.uk-spinner > * {
  animation: uk-spinner-rotate 1.4s linear infinite; }

@keyframes uk-spinner-rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(270deg); } }

/*
 * Circle
 */
.uk-spinner > * > * {
  stroke-dasharray: 88px;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: uk-spinner-dash 1.4s ease-in-out infinite;
  stroke-width: 1;
  stroke-linecap: round; }

@keyframes uk-spinner-dash {
  0% {
    stroke-dashoffset: 88px; }
  50% {
    stroke-dashoffset: 22px;
    transform: rotate(135deg); }
  100% {
    stroke-dashoffset: 88px;
    transform: rotate(450deg); } }

/* ========================================================================
   Component: Totop
 ========================================================================== */
/*
 * Addopts `uk-icon`
 */
.uk-totop {
  padding: 5px;
  color: #999; }

/* Hover */
.uk-totop:hover {
  color: #666; }

/* OnClick */
.uk-totop:active {
  color: #333; }

/* ========================================================================
   Component: Marker
 ========================================================================== */
/*
 * Addopts `uk-icon`
 */
.uk-marker {
  padding: 5px;
  background: #222;
  color: #fff; }

/* Hover */
.uk-marker:hover {
  color: #fff; }

/* ========================================================================
   Component: Alert
 ========================================================================== */
.uk-alert {
  position: relative;
  margin-bottom: 20px;
  padding: 15px 29px 15px 15px;
  background: #f8f8f8;
  color: #666; }

/* Add margin if adjacent element */
* + .uk-alert {
  margin-top: 20px; }

/*
 * Remove margin from the last-child
 */
.uk-alert > :last-child {
  margin-bottom: 0; }

/* Close
 * Adopts `uk-close`
 ========================================================================== */
.uk-alert-close {
  position: absolute;
  top: 20px;
  right: 15px; }

/*
 * Remove margin from adjacent element
 */
.uk-alert-close:first-child + * {
  margin-top: 0; }

/*
 * Hover
 */
/* Style modifiers
 ========================================================================== */
/*
 * Primary
 */
.uk-alert-primary {
  background: #d8eafc;
  color: #1e87f0; }

/*
 * Success
 */
.uk-alert-success {
  background: #edfbf6;
  color: #32d296; }

/*
 * Warning
 */
.uk-alert-warning {
  background: #fef5ee;
  color: #faa05a; }

/*
 * Danger
 */
.uk-alert-danger {
  background: #fef4f6;
  color: #f0506e; }

/* ========================================================================
   Component: Placeholder
 ========================================================================== */
.uk-placeholder {
  margin-bottom: 20px;
  padding: 30px 30px;
  background: #f8f8f8; }

/* Add margin if adjacent element */
* + .uk-placeholder {
  margin-top: 20px; }

/*
 * Remove margin from the last-child
 */
.uk-placeholder > :last-child {
  margin-bottom: 0; }

/* ========================================================================
   Component: Badge
 ========================================================================== */
/*
 * 1. Style
 * 2. Center child vertically and horizontally
 */
.uk-badge {
  box-sizing: border-box;
  min-width: 18px;
  height: 18px;
  padding: 0 5px;
  border-radius: 500px;
  vertical-align: middle;
  /* 1 */
  background: #1e87f0;
  color: #fff !important;
  font-size: 11px;
  /* 2 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 0; }

/*
 * Required for `a`
 */
.uk-badge:hover {
  text-decoration: none; }

/* ========================================================================
   Component: Label
 ========================================================================== */
.uk-label {
  display: inline-block;
  padding: 0 10px;
  background: #1e87f0;
  line-height: 1.5;
  font-size: 0.875rem;
  color: #fff;
  vertical-align: middle;
  white-space: nowrap; }

/* Color modifiers
 ========================================================================== */
/*
 * Success
 */
.uk-label-success {
  background-color: #32d296;
  color: #fff; }

/*
 * Warning
 */
.uk-label-warning {
  background-color: #faa05a;
  color: #fff; }

/*
 * Danger
 */
.uk-label-danger {
  background-color: #f0506e;
  color: #fff; }

/* ========================================================================
   Component: Overlay
 ========================================================================== */
.uk-overlay {
  padding: 30px 30px; }

/*
 * Remove margin from the last-child
 */
.uk-overlay > :last-child {
  margin-bottom: 0; }

/* Icon
 ========================================================================== */
/* Style modifiers
 ========================================================================== */
/*
 * Default
 */
.uk-overlay-default {
  background: rgba(255, 255, 255, 0.8); }

/*
 * Primary
 */
.uk-overlay-primary {
  background: rgba(34, 34, 34, 0.8); }

/* ========================================================================
   Component: Article
 ========================================================================== */
.uk-article {
  display: flow-root; }

/*
 * Remove margin from the last-child
 */
.uk-article > :last-child {
  margin-bottom: 0; }

/* Adjacent sibling
 ========================================================================== */
.uk-article + .uk-article {
  margin-top: 70px; }

/* Title
 ========================================================================== */
.uk-article-title {
  font-size: 2.23125rem;
  line-height: 1.2; }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-article-title {
    font-size: 2.625rem; } }

/* Meta
 ========================================================================== */
.uk-article-meta {
  font-size: 0.875rem;
  line-height: 1.4;
  color: #999; }

/* ========================================================================
   Component: Comment
 ========================================================================== */
/* Sections
 ========================================================================== */
.uk-comment-body {
  display: flow-root;
  overflow-wrap: break-word;
  word-wrap: break-word; }

.uk-comment-header {
  display: flow-root;
  margin-bottom: 20px; }

/*
 * Remove margin from the last-child
 */
.uk-comment-body > :last-child,
.uk-comment-header > :last-child {
  margin-bottom: 0; }

/* Title
 ========================================================================== */
.uk-comment-title {
  font-size: 1.25rem;
  line-height: 1.4; }

/* Meta
 ========================================================================== */
.uk-comment-meta {
  font-size: 0.875rem;
  line-height: 1.4;
  color: #999; }

/* Avatar
 ========================================================================== */
/* List
 ========================================================================== */
.uk-comment-list {
  padding: 0;
  list-style: none; }

/* Adjacent siblings */
.uk-comment-list > :nth-child(n+2) {
  margin-top: 70px; }

/*
 * Sublists
 * Note: General sibling selector allows reply block between comment and sublist
 */
.uk-comment-list .uk-comment ~ ul {
  margin: 70px 0 0 0;
  padding-left: 30px;
  list-style: none; }

/* Tablet and bigger */
@media (min-width: 960px) {
  .uk-comment-list .uk-comment ~ ul {
    padding-left: 100px; } }

/* Adjacent siblings */
.uk-comment-list .uk-comment ~ ul > :nth-child(n+2) {
  margin-top: 70px; }

/* Style modifier
 ========================================================================== */
/* ========================================================================
   Component: Search
 ========================================================================== */
/*
 * 1. Container fits its content
 * 2. Create position context
 * 3. Prevent content overflow
 * 4. Reset `form`
 */
.uk-search {
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  /* 3 */
  max-width: 100%;
  /* 4 */
  margin: 0; }

/* Input
 ========================================================================== */
/*
 * Remove the inner padding and cancel buttons in Chrome on OS X and Safari on OS X.
 */
.uk-search-input::-webkit-search-cancel-button,
.uk-search-input::-webkit-search-decoration {
  -webkit-appearance: none; }

/*
 * Removes placeholder transparency in Firefox.
 */
.uk-search-input::-moz-placeholder {
  opacity: 1; }

/*
 * 1. Define consistent box sizing.
 * 2. Address margins set differently in Firefox/IE and Chrome/Safari/Opera.
 * 3. Remove `border-radius` in iOS.
 * 4. Change font properties to `inherit` in all browsers
 * 5. Show the overflow in Edge.
 * 6. Remove default style in iOS.
 * 7. Vertical alignment
 * 8. Take the full container width
 * 9. Style
 */
.uk-search-input {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  margin: 0;
  /* 3 */
  border-radius: 0;
  /* 4 */
  font: inherit;
  /* 5 */
  overflow: visible;
  /* 6 */
  -webkit-appearance: none;
  /* 7 */
  vertical-align: middle;
  /* 8 */
  width: 100%;
  /* 9 */
  border: none;
  color: #666; }

.uk-search-input:focus {
  outline: none; }

/* Placeholder */
.uk-search-input::placeholder {
  color: #999; }

/* Icon (Adopts `uk-icon`)
 ========================================================================== */
/*
 * Position above input
 * 1. Set position
 * 2. Center icon vertically and horizontally
 * 3. Style
 */
.uk-search .uk-search-icon {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* 3 */
  color: #999; }

/*
 * Required for `a`.
 */
.uk-search .uk-search-icon:hover {
  color: #999; }

/*
 * Make `input` element clickable through icon, e.g. if it's a `span`
 */
.uk-search .uk-search-icon:not(a):not(button):not(input) {
  pointer-events: none; }

/*
 * Position modifier
 */
.uk-search .uk-search-icon-flip {
  right: 0;
  left: auto; }

/* Default modifier
 ========================================================================== */
.uk-search-default {
  width: 240px; }

/*
 * Input
 */
.uk-search-default .uk-search-input {
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  background: #f8f8f8; }

/* Focus */
.uk-search-default .uk-search-input:focus {
  background-color: #ebebeb; }

/*
 * Icon
 */
.uk-search-default .uk-search-icon {
  width: 40px; }

.uk-search-default .uk-search-icon:not(.uk-search-icon-flip) ~ .uk-search-input {
  padding-left: 40px; }

.uk-search-default .uk-search-icon-flip ~ .uk-search-input {
  padding-right: 40px; }

/* Navbar modifier
 ========================================================================== */
.uk-search-navbar {
  width: 400px; }

/*
 * Input
 */
.uk-search-navbar .uk-search-input {
  height: 40px;
  background: transparent;
  font-size: 1.5rem; }

/* Focus */
/*
 * Icon
 */
.uk-search-navbar .uk-search-icon {
  width: 40px; }

.uk-search-navbar .uk-search-icon:not(.uk-search-icon-flip) ~ .uk-search-input {
  padding-left: 40px; }

.uk-search-navbar .uk-search-icon-flip ~ .uk-search-input {
  padding-right: 40px; }

/* Large modifier
 ========================================================================== */
.uk-search-large {
  width: 500px; }

/*
 * Input
 */
.uk-search-large .uk-search-input {
  height: 80px;
  background: transparent;
  font-size: 2.625rem; }

/* Focus */
/*
 * Icon
 */
.uk-search-large .uk-search-icon {
  width: 80px; }

.uk-search-large .uk-search-icon:not(.uk-search-icon-flip) ~ .uk-search-input {
  padding-left: 80px; }

.uk-search-large .uk-search-icon-flip ~ .uk-search-input {
  padding-right: 80px; }

/* Toggle
 ========================================================================== */
.uk-search-toggle {
  color: #999; }

/* Hover */
.uk-search-toggle:hover {
  color: #666; }

/* ========================================================================
   Component: Accordion
 ========================================================================== */
.uk-accordion {
  padding: 0;
  list-style: none; }

/* Item
 ========================================================================== */
.uk-accordion > :nth-child(n+2) {
  margin-top: 20px; }

/* Title
 ========================================================================== */
.uk-accordion-title {
  display: block;
  font-size: 1.25rem;
  line-height: 1.4;
  color: #333; }

/* Hover */
.uk-accordion-title:hover {
  color: #666;
  text-decoration: none; }

/* Content
 ========================================================================== */
.uk-accordion-content {
  display: flow-root;
  margin-top: 20px; }

/*
 * Remove margin from the last-child
 */
.uk-accordion-content > :last-child {
  margin-bottom: 0; }

/* ========================================================================
   Component: Drop
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Set a default width
 */
.uk-drop {
  /* 1 */
  display: none;
  /* 2 */
  position: absolute;
  z-index: 1020;
  --uk-position-offset: 20px;
  --uk-position-viewport-offset: 15px;
  /* 3 */
  box-sizing: border-box;
  width: 300px; }

/* Show */
.uk-drop.uk-open {
  display: block; }

/* Grid modifiers
 ========================================================================== */
.uk-drop-stack .uk-drop-grid > * {
  width: 100% !important; }

/* ========================================================================
   Component: Dropdown
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Set a default width
 * 4. Style
 */
.uk-dropdown {
  /* 1 */
  display: none;
  /* 2 */
  position: absolute;
  z-index: 1020;
  --uk-position-offset: 10px;
  --uk-position-viewport-offset: 15px;
  /* 3 */
  box-sizing: border-box;
  min-width: 200px;
  /* 4 */
  padding: 15px;
  background: #f8f8f8;
  color: #666; }

/* Show */
.uk-dropdown.uk-open {
  display: block; }

/*
 * Remove margin from the last-child
 */
.uk-dropdown > :last-child {
  margin-bottom: 0; }

/* Size modifier
 ========================================================================== */
.uk-dropdown-large {
  padding: 40px; }

/* Nav
 * Adopts `uk-nav`
 ========================================================================== */
/*
 * Items
 */
.uk-dropdown-nav > li > a {
  color: #999; }

/* Hover + Active */
.uk-dropdown-nav > li > a:hover,
.uk-dropdown-nav > li.uk-active > a {
  color: #666; }

/*
 * Subtitle
 */
.uk-dropdown-nav .uk-nav-subtitle {
  font-size: 0.875rem; }

/*
 * Header
 */
.uk-dropdown-nav .uk-nav-header {
  color: #333; }

/*
 * Divider
 */
.uk-dropdown-nav .uk-nav-divider {
  border-top: 1px solid #e5e5e5; }

/*
 * Sublists
 */
.uk-dropdown-nav .uk-nav-sub a {
  color: #999; }

.uk-dropdown-nav .uk-nav-sub a:hover,
.uk-dropdown-nav .uk-nav-sub li.uk-active > a {
  color: #666; }

/* Grid modifiers
 ========================================================================== */
.uk-dropdown-stack .uk-dropdown-grid > * {
  width: 100% !important; }

/* ========================================================================
   Component: Dropbar
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Style
 */
.uk-dropbar {
  --uk-position-offset: 0;
  --uk-position-shift-offset: 0;
  --uk-position-viewport-offset: 0;
  /* 1 */
  display: none;
  /* 2 */
  position: absolute;
  z-index: 1020;
  /* 3 */
  box-sizing: border-box;
  padding: 15px 15px 15px 15px;
  background: #f8f8f8;
  color: #666; }

/* Show */
.uk-dropbar.uk-open {
  display: block; }

/*
 * Remove margin from the last-child
 */
.uk-dropbar > :last-child {
  margin-bottom: 0; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-dropbar {
    padding-left: 30px;
    padding-right: 30px; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-dropbar {
    padding-left: 40px;
    padding-right: 40px; } }

/* Size modifier
 ========================================================================== */
.uk-dropbar-large {
  padding-top: 40px;
  padding-bottom: 40px; }

/* Direction modifier
 ========================================================================== */
/* ========================================================================
   Component: Modal
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Allow scrolling for the modal dialog
 * 4. Horizontal padding
 * 5. Mask the background page
 * 6. Fade-in transition
 */
.uk-modal {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  /* 3 */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  /* 4 */
  padding: 15px 15px;
  /* 5 */
  background: rgba(0, 0, 0, 0.6);
  /* 6 */
  opacity: 0;
  transition: opacity 0.15s linear; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-modal {
    padding: 50px 30px; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-modal {
    padding-left: 40px;
    padding-right: 40px; } }

/*
 * Open
 */
.uk-modal.uk-open {
  opacity: 1; }

/* Page
 ========================================================================== */
/*
 * Prevent scrollbars
 */
.uk-modal-page {
  overflow: hidden; }

/* Dialog
 ========================================================================== */
/*
 * 1. Create position context for spinner and close button
 * 2. Dimensions
 * 3. `!important` is needed to overwrite `uk-width-auto`. See `#modal-media-image` in tests
 * 4. Style
 * 5. Slide-in transition
 */
.uk-modal-dialog {
  /* 1 */
  position: relative;
  /* 2 */
  box-sizing: border-box;
  margin: 0 auto;
  width: 600px;
  /* 3 */
  max-width: 100% !important;
  /* 4 */
  background: #fff;
  /* 5 */
  opacity: 0;
  transform: translateY(-100px);
  transition: 0.3s linear;
  transition-property: opacity, transform; }

/*
 * Open
 */
.uk-open > .uk-modal-dialog {
  opacity: 1;
  transform: translateY(0); }

/* Size modifier
 ========================================================================== */
/*
 * Container size
 * Take the same size as the Container component
 */
.uk-modal-container .uk-modal-dialog {
  width: 1200px; }

/*
 * Full size
 * 1. Remove padding and background from modal
 * 2. Reset all default declarations from modal dialog
 */
/* 1 */
.uk-modal-full {
  padding: 0;
  background: none; }

/* 2 */
.uk-modal-full .uk-modal-dialog {
  margin: 0;
  width: 100%;
  max-width: 100%;
  transform: translateY(0); }

/* Sections
 ========================================================================== */
.uk-modal-body {
  display: flow-root;
  padding: 20px 20px; }

.uk-modal-header {
  display: flow-root;
  padding: 10px 20px;
  background: #f8f8f8; }

.uk-modal-footer {
  display: flow-root;
  padding: 10px 20px;
  background: #f8f8f8; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-modal-body {
    padding: 30px 30px; }
  .uk-modal-header {
    padding: 15px 30px; }
  .uk-modal-footer {
    padding: 15px 30px; } }

/*
 * Remove margin from the last-child
 */
.uk-modal-body > :last-child,
.uk-modal-header > :last-child,
.uk-modal-footer > :last-child {
  margin-bottom: 0; }

/* Title
 ========================================================================== */
.uk-modal-title {
  font-size: 2rem;
  line-height: 1.3; }

/* Close
 * Adopts `uk-close`
 ========================================================================== */
[class*='uk-modal-close-'] {
  position: absolute;
  z-index: 1010;
  top: 10px;
  right: 10px;
  padding: 5px; }

/*
 * Remove margin from adjacent element
 */
[class*='uk-modal-close-']:first-child + * {
  margin-top: 0; }

/*
 * Hover
 */
/*
 * Default
 */
/*
 * Outside
 * 1. Prevent scrollbar on small devices
 */
.uk-modal-close-outside {
  top: 0;
  /* 1 */
  right: -5px;
  transform: translate(0, -100%);
  color: white; }

.uk-modal-close-outside:hover {
  color: #fff; }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  /* 1 */
  .uk-modal-close-outside {
    right: 0;
    transform: translate(100%, -100%); } }

/*
 * Full
 */
/* ========================================================================
   Component: Slideshow
 ========================================================================== */
/*
 * 1. Prevent tab highlighting on iOS.
 */
.uk-slideshow {
  /* 1 */
  -webkit-tap-highlight-color: transparent; }

/* Items
 ========================================================================== */
/*
 * 1. Create position and stacking context
 * 2. Reset list
 * 3. Clip child elements
 * 4. Prevent displaying the callout information on iOS.
 * 5. Disable horizontal panning gestures
 */
.uk-slideshow-items {
  /* 1 */
  position: relative;
  z-index: 0;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 3 */
  overflow: hidden;
  /* 4 */
  -webkit-touch-callout: none;
  /* 5 */
  touch-action: pan-y; }

/* Item
 ========================================================================== */
/*
 * 1. Position items above each other
 * 2. Take the full width
 * 3. Clip child elements, e.g. for `uk-cover`
 * 4. Optimize animation
 */
.uk-slideshow-items > * {
  /* 1 */
  position: absolute;
  top: 0;
  left: 0;
  /* 2 */
  right: 0;
  bottom: 0;
  /* 3 */
  overflow: hidden;
  /* 4 */
  will-change: transform, opacity; }

/*
 * Hide not active items
 */
.uk-slideshow-items > :not(.uk-active) {
  display: none; }

/* ========================================================================
   Component: Slider
 ========================================================================== */
/*
 * 1. Prevent tab highlighting on iOS.
 */
.uk-slider {
  /* 1 */
  -webkit-tap-highlight-color: transparent; }

/* Container
 ========================================================================== */
/*
 * Clip child elements
 */
.uk-slider-container {
  overflow: hidden; }

/*
 * Widen container to prevent box-shadows from clipping, `large-box-shadow`
 */
.uk-slider-container-offset {
  margin: -11px -25px -39px -25px;
  padding: 11px 25px 39px 25px; }

/* Items
 ========================================================================== */
/*
 * 1. Optimize animation
 * 2. Create a containing block. In Safari it's neither created by `transform` nor `will-change`.
 * 3. Disable horizontal panning gestures
 */
.uk-slider-items {
  /* 1 */
  will-change: transform;
  /* 2 */
  position: relative;
  /* 3 */
  touch-action: pan-y; }

/*
 * 1. Reset list style without interfering with grid
 * 2. Prevent displaying the callout information on iOS.
 */
.uk-slider-items:not(.uk-grid) {
  display: flex;
  /* 1 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 2 */
  -webkit-touch-callout: none; }

.uk-slider-items.uk-grid {
  flex-wrap: nowrap; }

/* Item
 ========================================================================== */
/*
 * 1. Let items take content dimensions (0 0 auto)
 *    `max-width` needed to keep image responsiveness and prevent content overflow
 * 3. Create position context
 */
.uk-slider-items > * {
  /* 1 */
  flex: none;
  max-width: 100%;
  /* 3 */
  position: relative; }

/* ========================================================================
   Component: Sticky
 ========================================================================== */
/*
 * 1. Create position context so it's t the same like when fixed.
 * 2. More robust if padding and border are used and the sticky height is transitioned
 */
.uk-sticky {
  /* 1 */
  position: relative;
  /* 2 */
  box-sizing: border-box; }

/*
 * 1. Force new layer to resolve frame rate issues on devices with lower frame rates
 */
.uk-sticky-fixed {
  z-index: 980;
  margin: 0 !important;
  /* 1 */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

/*
 * Faster animations
 */
.uk-sticky[class*='uk-animation-'] {
  animation-duration: 0.2s; }

.uk-sticky.uk-animation-reverse {
  animation-duration: 0.2s; }

/*
 * Placeholder
 * Make content clickable for sticky cover and reveal effects
 */
.uk-sticky-placeholder {
  pointer-events: none; }

/* ========================================================================
   Component: Off-canvas
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 */
.uk-offcanvas {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1000; }

/*
 * Flip modifier
 */
.uk-offcanvas-flip .uk-offcanvas {
  right: 0;
  left: auto; }

/* Bar
 ========================================================================== */
/*
 * 1. Set position
 * 2. Size and style
 * 3. Allow scrolling
 */
.uk-offcanvas-bar {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: -270px;
  /* 2 */
  box-sizing: border-box;
  width: 270px;
  padding: 20px 20px;
  background: #222;
  /* 3 */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-offcanvas-bar {
    left: -350px;
    width: 350px;
    padding: 30px 30px; } }

/* Flip modifier */
.uk-offcanvas-flip .uk-offcanvas-bar {
  left: auto;
  right: -270px; }

/* Tablet landscape and bigger */
@media (min-width: 640px) {
  .uk-offcanvas-flip .uk-offcanvas-bar {
    right: -350px; } }

/*
 * Open
 */
.uk-open > .uk-offcanvas-bar {
  left: 0; }

.uk-offcanvas-flip .uk-open > .uk-offcanvas-bar {
  left: auto;
  right: 0; }

/*
 * Slide Animation (Used in slide and push mode)
 */
.uk-offcanvas-bar-animation {
  transition: left 0.3s ease-out; }

.uk-offcanvas-flip .uk-offcanvas-bar-animation {
  transition-property: right; }

/*
 * Reveal Animation
 * 1. Set position
 * 2. Clip the bar
 * 3. Animation
 * 4. Reset position
 */
.uk-offcanvas-reveal {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  width: 0;
  overflow: hidden;
  /* 3 */
  transition: width 0.3s ease-out; }

.uk-offcanvas-reveal .uk-offcanvas-bar {
  /* 4 */
  left: 0; }

.uk-offcanvas-flip .uk-offcanvas-reveal .uk-offcanvas-bar {
  /* 4 */
  left: auto;
  right: 0; }

.uk-open > .uk-offcanvas-reveal {
  width: 270px; }

/* Tablet landscape and bigger */
@media (min-width: 640px) {
  .uk-open > .uk-offcanvas-reveal {
    width: 350px; } }

/*
 * Flip modifier
 */
.uk-offcanvas-flip .uk-offcanvas-reveal {
  right: 0;
  left: auto; }

/* Close
 * Adopts `uk-close`
 ========================================================================== */
.uk-offcanvas-close {
  position: absolute;
  z-index: 1000;
  top: 5px;
  right: 5px;
  padding: 5px; }

/* Tablet landscape and bigger */
@media (min-width: 640px) {
  .uk-offcanvas-close {
    top: 10px;
    right: 10px; } }

/*
 * Remove margin from adjacent element
 */
.uk-offcanvas-close:first-child + * {
  margin-top: 0; }

/* Overlay
 ========================================================================== */
/*
 * Overlay the whole page. Needed for the `::before`
 * 1. Using `100vw` so no modification is needed when off-canvas is flipped
 * 2. Allow for closing with swipe gesture on devices with pointer events.
 */
.uk-offcanvas-overlay {
  /* 1 */
  width: 100vw;
  /* 2 */
  touch-action: none; }

/*
 * 1. Mask the whole page
 * 2. Fade-in transition
 */
.uk-offcanvas-overlay::before {
  /* 1 */
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
  /* 2 */
  opacity: 0;
  transition: opacity 0.15s linear; }

.uk-offcanvas-overlay.uk-open::before {
  opacity: 1; }

/* Prevent scrolling
 ========================================================================== */
/*
 * Prevent horizontal scrollbar when the content is slide-out
 * Has to be on the `html` element too to make it work on the `body`
 */
.uk-offcanvas-page,
.uk-offcanvas-container {
  overflow-x: hidden; }

/* Container
 ========================================================================== */
/*
 * Prepare slide-out animation (Used in reveal and push mode)
 * Using `position: left` instead of `transform` because position `fixed` elements like sticky navbars
 * lose their fixed state and behaves like `absolute` within a transformed container
 * 1. Provide a fixed width and prevent shrinking
 */
.uk-offcanvas-container {
  position: relative;
  left: 0;
  transition: left 0.3s ease-out;
  /* 1 */
  box-sizing: border-box;
  width: 100%; }

/*
 * Activate slide-out animation
 */
:not(.uk-offcanvas-flip).uk-offcanvas-container-animation {
  left: 270px; }

.uk-offcanvas-flip.uk-offcanvas-container-animation {
  left: -270px; }

/* Tablet landscape and bigger */
@media (min-width: 640px) {
  :not(.uk-offcanvas-flip).uk-offcanvas-container-animation {
    left: 350px; }
  .uk-offcanvas-flip.uk-offcanvas-container-animation {
    left: -350px; } }

/* ========================================================================
   Component: Switcher
 ========================================================================== */
/*
 * Reset list
 */
.uk-switcher {
  margin: 0;
  padding: 0;
  list-style: none; }

/* Items
 ========================================================================== */
/*
 * Hide not active items
 */
.uk-switcher > :not(.uk-active) {
  display: none; }

/*
 * Remove margin from the last-child
 */
.uk-switcher > * > :last-child {
  margin-bottom: 0; }

/* ========================================================================
   Component: Leader
 ========================================================================== */
.uk-leader {
  overflow: hidden; }

/*
 * 1. Place element in text flow
 * 2. Never break into a new line
 * 3. Get a string back with as many repeating characters to fill the container
 * 4. Prevent wrapping. Overflowing characters will be clipped by the container
 */
.uk-leader-fill::after {
  /* 1 */
  display: inline-block;
  margin-left: 15px;
  /* 2 */
  width: 0;
  /* 3 */
  content: attr(data-fill);
  /* 4 */
  white-space: nowrap; }

/*
 * Hide if media does not match
 */
.uk-leader-fill.uk-leader-hide::after {
  display: none; }

/*
 * Pass fill character to JS
 */
:root {
  --uk-leader-fill-content: .; }

/* ========================================================================
   Component: Notification
 ========================================================================== */
/*
 * 1. Set position
 * 2. Dimensions
 */
.uk-notification {
  /* 1 */
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1040;
  /* 2 */
  box-sizing: border-box;
  width: 350px; }

/* Position modifiers
========================================================================== */
.uk-notification-top-right,
.uk-notification-bottom-right {
  left: auto;
  right: 10px; }

.uk-notification-top-center,
.uk-notification-bottom-center {
  left: 50%;
  margin-left: -175px; }

.uk-notification-bottom-left,
.uk-notification-bottom-right,
.uk-notification-bottom-center {
  top: auto;
  bottom: 10px; }

/* Responsiveness
========================================================================== */
/* Phones portrait and smaller */
@media (max-width: 639px) {
  .uk-notification {
    left: 10px;
    right: 10px;
    width: auto;
    margin: 0; } }

/* Message
========================================================================== */
.uk-notification-message {
  position: relative;
  padding: 15px;
  background: #f8f8f8;
  color: #666;
  font-size: 1.25rem;
  line-height: 1.4;
  cursor: pointer; }

* + .uk-notification-message {
  margin-top: 10px; }

/* Close
 * Adopts `uk-close`
 ========================================================================== */
.uk-notification-close {
  display: none;
  position: absolute;
  top: 20px;
  right: 15px; }

.uk-notification-message:hover .uk-notification-close {
  display: block; }

/* Style modifiers
 ========================================================================== */
/*
 * Primary
 */
.uk-notification-message-primary {
  color: #1e87f0; }

/*
 * Success
 */
.uk-notification-message-success {
  color: #32d296; }

/*
 * Warning
 */
.uk-notification-message-warning {
  color: #faa05a; }

/*
 * Danger
 */
.uk-notification-message-danger {
  color: #f0506e; }

/* ========================================================================
   Component: Tooltip
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Position
 * 3. Remove tooltip from document flow to keep the UIkit container from changing its size when injected into the document initially
 * 4. Dimensions
 * 5. Style
 */
.uk-tooltip {
  /* 1 */
  display: none;
  /* 2 */
  position: absolute;
  z-index: 1030;
  --uk-position-offset: 10px;
  --uk-position-viewport-offset: 10;
  /* 3 */
  top: 0;
  /* 4 */
  box-sizing: border-box;
  max-width: 200px;
  padding: 3px 6px;
  /* 5 */
  background: #666;
  border-radius: 2px;
  color: #fff;
  font-size: 12px; }

/* Show */
.uk-tooltip.uk-active {
  display: block; }

/* ========================================================================
   Component: Sortable
 ========================================================================== */
.uk-sortable {
  position: relative; }

/*
 * Remove margin from the last-child
 */
.uk-sortable > :last-child {
  margin-bottom: 0; }

/* Drag
 ========================================================================== */
.uk-sortable-drag {
  position: fixed !important;
  z-index: 1050 !important;
  pointer-events: none; }

/* Placeholder
 ========================================================================== */
.uk-sortable-placeholder {
  opacity: 0;
  pointer-events: none; }

/* Empty modifier
 ========================================================================== */
.uk-sortable-empty {
  min-height: 50px; }

/* Handle
 ========================================================================== */
/* Hover */
.uk-sortable-handle:hover {
  cursor: move; }

/* ========================================================================
   Component: Countdown
 ========================================================================== */
/* Item
 ========================================================================== */
/* Number
 ========================================================================== */
/*
 * 1. Make numbers all of the same size to prevent jumping. Must be supported by the font.
 * 2. Style
 */
.uk-countdown-number {
  /* 1 */
  font-variant-numeric: tabular-nums;
  /* 2 */
  font-size: 2rem;
  line-height: 0.8; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-countdown-number {
    font-size: 4rem; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-countdown-number {
    font-size: 6rem; } }

/* Separator
 ========================================================================== */
.uk-countdown-separator {
  font-size: 1rem;
  line-height: 1.6; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-countdown-separator {
    font-size: 2rem; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-countdown-separator {
    font-size: 3rem; } }

/* Label
 ========================================================================== */
/* ========================================================================
   Component: Grid
 ========================================================================== */
/*
 * 1. Allow cells to wrap into the next line
 * 2. Reset list
 */
.uk-grid {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none; }

/*
 * Grid cell
 * Note: Space is allocated solely based on content dimensions, but shrinks: 0 1 auto
 * Reset margin for e.g. paragraphs
 */
.uk-grid > * {
  margin: 0; }

/*
 * Remove margin from the last-child
 */
.uk-grid > * > :last-child {
  margin-bottom: 0; }

/* Gutter
 ========================================================================== */
/*
 * Default
 */
/* Horizontal */
.uk-grid {
  margin-left: -30px; }

.uk-grid > * {
  padding-left: 30px; }

/* Vertical */
.uk-grid + .uk-grid,
.uk-grid > .uk-grid-margin,
* + .uk-grid-margin {
  margin-top: 30px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid {
    margin-left: -40px; }
  .uk-grid > * {
    padding-left: 40px; }
  /* Vertical */
  .uk-grid + .uk-grid,
  .uk-grid > .uk-grid-margin,
  * + .uk-grid-margin {
    margin-top: 40px; } }

/*
 * Small
 */
/* Horizontal */
.uk-grid-small,
.uk-grid-column-small {
  margin-left: -15px; }

.uk-grid-small > *,
.uk-grid-column-small > * {
  padding-left: 15px; }

/* Vertical */
.uk-grid + .uk-grid-small,
.uk-grid + .uk-grid-row-small,
.uk-grid-small > .uk-grid-margin,
.uk-grid-row-small > .uk-grid-margin,
* + .uk-grid-margin-small {
  margin-top: 15px; }

/*
 * Medium
 */
/* Horizontal */
.uk-grid-medium,
.uk-grid-column-medium {
  margin-left: -30px; }

.uk-grid-medium > *,
.uk-grid-column-medium > * {
  padding-left: 30px; }

/* Vertical */
.uk-grid + .uk-grid-medium,
.uk-grid + .uk-grid-row-medium,
.uk-grid-medium > .uk-grid-margin,
.uk-grid-row-medium > .uk-grid-margin,
* + .uk-grid-margin-medium {
  margin-top: 30px; }

/*
 * Large
 */
/* Horizontal */
.uk-grid-large,
.uk-grid-column-large {
  margin-left: -40px; }

.uk-grid-large > *,
.uk-grid-column-large > * {
  padding-left: 40px; }

/* Vertical */
.uk-grid + .uk-grid-large,
.uk-grid + .uk-grid-row-large,
.uk-grid-large > .uk-grid-margin,
.uk-grid-row-large > .uk-grid-margin,
* + .uk-grid-margin-large {
  margin-top: 40px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid-large,
  .uk-grid-column-large {
    margin-left: -70px; }
  .uk-grid-large > *,
  .uk-grid-column-large > * {
    padding-left: 70px; }
  /* Vertical */
  .uk-grid + .uk-grid-large,
  .uk-grid + .uk-grid-row-large,
  .uk-grid-large > .uk-grid-margin,
  .uk-grid-row-large > .uk-grid-margin,
  * + .uk-grid-margin-large {
    margin-top: 70px; } }

/*
 * Collapse
 */
/* Horizontal */
.uk-grid-collapse,
.uk-grid-column-collapse {
  margin-left: 0; }

.uk-grid-collapse > *,
.uk-grid-column-collapse > * {
  padding-left: 0; }

/* Vertical */
.uk-grid + .uk-grid-collapse,
.uk-grid + .uk-grid-row-collapse,
.uk-grid-collapse > .uk-grid-margin,
.uk-grid-row-collapse > .uk-grid-margin {
  margin-top: 0; }

/* Divider
 ========================================================================== */
.uk-grid-divider > * {
  position: relative; }

.uk-grid-divider > :not(.uk-first-column)::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  border-left: 1px solid #e5e5e5; }

/* Vertical */
.uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  border-top: 1px solid #e5e5e5; }

/*
 * Default
 */
/* Horizontal */
.uk-grid-divider {
  margin-left: -60px; }

.uk-grid-divider > * {
  padding-left: 60px; }

.uk-grid-divider > :not(.uk-first-column)::before {
  left: 30px; }

/* Vertical */
.uk-grid-divider.uk-grid-stack > .uk-grid-margin {
  margin-top: 60px; }

.uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
  top: -30px;
  left: 60px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid-divider {
    margin-left: -80px; }
  .uk-grid-divider > * {
    padding-left: 80px; }
  .uk-grid-divider > :not(.uk-first-column)::before {
    left: 40px; }
  /* Vertical */
  .uk-grid-divider.uk-grid-stack > .uk-grid-margin {
    margin-top: 80px; }
  .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
    top: -40px;
    left: 80px; } }

/*
 * Small
 */
/* Horizontal */
.uk-grid-divider.uk-grid-small,
.uk-grid-divider.uk-grid-column-small {
  margin-left: -30px; }

.uk-grid-divider.uk-grid-small > *,
.uk-grid-divider.uk-grid-column-small > * {
  padding-left: 30px; }

.uk-grid-divider.uk-grid-small > :not(.uk-first-column)::before,
.uk-grid-divider.uk-grid-column-small > :not(.uk-first-column)::before {
  left: 15px; }

/* Vertical */
.uk-grid-divider.uk-grid-small.uk-grid-stack > .uk-grid-margin,
.uk-grid-divider.uk-grid-row-small.uk-grid-stack > .uk-grid-margin {
  margin-top: 30px; }

.uk-grid-divider.uk-grid-small.uk-grid-stack > .uk-grid-margin::before {
  top: -15px;
  left: 30px; }

.uk-grid-divider.uk-grid-row-small.uk-grid-stack > .uk-grid-margin::before {
  top: -15px; }

.uk-grid-divider.uk-grid-column-small.uk-grid-stack > .uk-grid-margin::before {
  left: 30px; }

/*
 * Medium
 */
/* Horizontal */
.uk-grid-divider.uk-grid-medium,
.uk-grid-divider.uk-grid-column-medium {
  margin-left: -60px; }

.uk-grid-divider.uk-grid-medium > *,
.uk-grid-divider.uk-grid-column-medium > * {
  padding-left: 60px; }

.uk-grid-divider.uk-grid-medium > :not(.uk-first-column)::before,
.uk-grid-divider.uk-grid-column-medium > :not(.uk-first-column)::before {
  left: 30px; }

/* Vertical */
.uk-grid-divider.uk-grid-medium.uk-grid-stack > .uk-grid-margin,
.uk-grid-divider.uk-grid-row-medium.uk-grid-stack > .uk-grid-margin {
  margin-top: 60px; }

.uk-grid-divider.uk-grid-medium.uk-grid-stack > .uk-grid-margin::before {
  top: -30px;
  left: 60px; }

.uk-grid-divider.uk-grid-row-medium.uk-grid-stack > .uk-grid-margin::before {
  top: -30px; }

.uk-grid-divider.uk-grid-column-medium.uk-grid-stack > .uk-grid-margin::before {
  left: 60px; }

/*
 * Large
 */
/* Horizontal */
.uk-grid-divider.uk-grid-large,
.uk-grid-divider.uk-grid-column-large {
  margin-left: -80px; }

.uk-grid-divider.uk-grid-large > *,
.uk-grid-divider.uk-grid-column-large > * {
  padding-left: 80px; }

.uk-grid-divider.uk-grid-large > :not(.uk-first-column)::before,
.uk-grid-divider.uk-grid-column-large > :not(.uk-first-column)::before {
  left: 40px; }

/* Vertical */
.uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin,
.uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin {
  margin-top: 80px; }

.uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin::before {
  top: -40px;
  left: 80px; }

.uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin::before {
  top: -40px; }

.uk-grid-divider.uk-grid-column-large.uk-grid-stack > .uk-grid-margin::before {
  left: 80px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid-divider.uk-grid-large,
  .uk-grid-divider.uk-grid-column-large {
    margin-left: -140px; }
  .uk-grid-divider.uk-grid-large > *,
  .uk-grid-divider.uk-grid-column-large > * {
    padding-left: 140px; }
  .uk-grid-divider.uk-grid-large > :not(.uk-first-column)::before,
  .uk-grid-divider.uk-grid-column-large > :not(.uk-first-column)::before {
    left: 70px; }
  /* Vertical */
  .uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin,
  .uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin {
    margin-top: 140px; }
  .uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin::before {
    top: -70px;
    left: 140px; }
  .uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin::before {
    top: -70px; }
  .uk-grid-divider.uk-grid-column-large.uk-grid-stack > .uk-grid-margin::before {
    left: 140px; } }

/* Match child of a grid cell
 ========================================================================== */
/*
 * Behave like a block element
 * 1. Wrap into the next line
 * 2. Take the full width, at least 100%. Only if no class from the Width component is set.
 * 3. Expand width even if larger than 100%, e.g. because of negative margin (Needed for nested grids)
 */
.uk-grid-match > *,
.uk-grid-item-match {
  display: flex;
  /* 1 */
  flex-wrap: wrap; }

.uk-grid-match > * > :not([class*='uk-width']),
.uk-grid-item-match > :not([class*='uk-width']) {
  /* 2 */
  box-sizing: border-box;
  width: 100%;
  /* 3 */
  flex: auto; }

/* ========================================================================
   Component: Nav
 ========================================================================== */
/*
 * Reset
 */
.uk-nav,
.uk-nav ul {
  margin: 0;
  padding: 0;
  list-style: none; }

/*
* 1. Center content vertically, e.g. an icon
* 2. Imitate white space gap when using flexbox
* 3. Reset link
 */
.uk-nav li > a {
  /* 1 */
  display: flex;
  align-items: center;
  /* 2 */
  column-gap: 0.25em;
  /* 3*/
  text-decoration: none; }

/*
 * Items
 * Must target `a` elements to exclude other elements (e.g. lists)
 */
.uk-nav > li > a {
  padding: 5px 0; }

/* Sublists
 ========================================================================== */
/*
 * Level 2
 * `ul` needed for higher specificity to override padding
 */
ul.uk-nav-sub {
  padding: 5px 0 5px 15px; }

/*
 * Level 3 and deeper
 */
.uk-nav-sub ul {
  padding-left: 15px; }

/*
 * Items
 */
.uk-nav-sub a {
  padding: 2px 0; }

/* Parent icon
 ========================================================================== */
.uk-nav-parent-icon {
  margin-left: auto; }

.uk-nav > li > a[aria-expanded="true"] .uk-nav-parent-icon {
  transform: rotate(180deg); }

/* Header
 ========================================================================== */
.uk-nav-header {
  padding: 5px 0;
  text-transform: uppercase;
  font-size: 0.875rem; }

.uk-nav-header:not(:first-child) {
  margin-top: 20px; }

/* Divider
 ========================================================================== */
.uk-nav .uk-nav-divider {
  margin: 5px 0; }

/* Default modifier
 ========================================================================== */
.uk-nav-default {
  font-size: 16px;
  line-height: 1.5; }

/*
 * Items
 */
.uk-nav-default > li > a {
  color: #999; }

/* Hover */
.uk-nav-default > li > a:hover {
  color: #666; }

/* Active */
.uk-nav-default > li.uk-active > a {
  color: #333; }

/*
 * Subtitle
 */
.uk-nav-default .uk-nav-subtitle {
  font-size: 0.875rem; }

/*
 * Header
 */
.uk-nav-default .uk-nav-header {
  color: #333; }

/*
 * Divider
 */
.uk-nav-default .uk-nav-divider {
  border-top: 1px solid #e5e5e5; }

/*
 * Sublists
 */
.uk-nav-default .uk-nav-sub {
  font-size: 16px;
  line-height: 1.5; }

.uk-nav-default .uk-nav-sub a {
  color: #999; }

.uk-nav-default .uk-nav-sub a:hover {
  color: #666; }

.uk-nav-default .uk-nav-sub li.uk-active > a {
  color: #333; }

/* Primary modifier
 ========================================================================== */
.uk-nav-primary {
  font-size: 1.5rem;
  line-height: 1.5; }

/*
 * Items
 */
.uk-nav-primary > li > a {
  color: #999; }

/* Hover */
.uk-nav-primary > li > a:hover {
  color: #666; }

/* Active */
.uk-nav-primary > li.uk-active > a {
  color: #333; }

/*
 * Subtitle
 */
.uk-nav-primary .uk-nav-subtitle {
  font-size: 1.25rem; }

/*
 * Header
 */
.uk-nav-primary .uk-nav-header {
  color: #333; }

/*
 * Divider
 */
.uk-nav-primary .uk-nav-divider {
  border-top: 1px solid #e5e5e5; }

/*
 * Sublists
 */
.uk-nav-primary .uk-nav-sub {
  font-size: 1.25rem;
  line-height: 1.5; }

.uk-nav-primary .uk-nav-sub a {
  color: #999; }

.uk-nav-primary .uk-nav-sub a:hover {
  color: #666; }

.uk-nav-primary .uk-nav-sub li.uk-active > a {
  color: #333; }

/* Secondary modifier
 ========================================================================== */
.uk-nav-secondary {
  font-size: 16px;
  line-height: 1.5; }

/*
 * Items
 */
.uk-nav-secondary > li > a {
  color: #333; }

/* Hover */
.uk-nav-secondary > li > a:hover {
  color: #333; }

/* Active */
.uk-nav-secondary > li.uk-active > a {
  color: #333; }

/*
 * Subtitle
 */
.uk-nav-secondary .uk-nav-subtitle {
  font-size: 0.875rem;
  color: #999; }

/* Hover */
.uk-nav-secondary > li > a:hover .uk-nav-subtitle {
  color: #666; }

/* Active */
.uk-nav-secondary > li.uk-active > a .uk-nav-subtitle {
  color: #333; }

/*
 * Header
 */
.uk-nav-secondary .uk-nav-header {
  color: #333; }

/*
 * Divider
 */
.uk-nav-secondary .uk-nav-divider {
  border-top: 1px solid #e5e5e5; }

/*
 * Sublists
 */
.uk-nav-secondary .uk-nav-sub {
  font-size: 0.875rem;
  line-height: 1.5; }

.uk-nav-secondary .uk-nav-sub a {
  color: #999; }

.uk-nav-secondary .uk-nav-sub a:hover {
  color: #666; }

.uk-nav-secondary .uk-nav-sub li.uk-active > a {
  color: #333; }

/* Alignment modifier
 ========================================================================== */
/*
 * 1. Center header
 * 2. Center items
 */
/* 1 */
.uk-nav-center {
  text-align: center; }

/* 2 */
.uk-nav-center li > a {
  justify-content: center; }

/* Sublists */
.uk-nav-center .uk-nav-sub,
.uk-nav-center .uk-nav-sub ul {
  padding-left: 0; }

/* Parent icon  */
.uk-nav-center .uk-nav-parent-icon {
  margin-left: 4px; }

/* Style modifier
 ========================================================================== */
/*
 * Divider
 * Naming is in plural to prevent conflicts with divider sub object.
 */
.uk-nav.uk-nav-divider > :not(.uk-nav-header):not(.uk-nav-divider) + :not(.uk-nav-header):not(.uk-nav-divider) {
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px solid #e5e5e5; }

/* ========================================================================
   Component: Navbar
 ========================================================================== */
/*
 * 1. Create position context to center navbar group
 */
.uk-navbar {
  display: flex;
  /* 1 */
  position: relative; }

/* Container
 ========================================================================== */
.uk-navbar-container:not(.uk-navbar-transparent) {
  background: #f8f8f8; }

/* Groups
 ========================================================================== */
/*
 * 1. Align navs and items vertically if they have a different height
 */
.uk-navbar-left,
.uk-navbar-right,
[class*='uk-navbar-center'] {
  display: flex;
  gap: 0px;
  /* 1 */
  align-items: center; }

/*
 * Horizontal alignment
 * 1. Create position context for centered navbar with sub groups (left/right)
 * 2. Fix text wrapping if content is larger than 50% of the container.
 * 3. Needed for dropdowns because a new position context is created
 *    `z-index` must be smaller than off-canvas
 * 4. Align sub groups for centered navbar
 */
.uk-navbar-right {
  margin-left: auto; }

.uk-navbar-center:only-child {
  margin-left: auto;
  margin-right: auto;
  /* 1 */
  position: relative; }

.uk-navbar-center:not(:only-child) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* 2 */
  width: max-content;
  box-sizing: border-box;
  /* 3 */
  z-index: 990; }

/* 4 */
.uk-navbar-center-left,
.uk-navbar-center-right {
  position: absolute;
  top: 0; }

.uk-navbar-center-left {
  right: calc(100% + 0px); }

.uk-navbar-center-right {
  left: calc(100% + 0px); }

[class*='uk-navbar-center-'] {
  width: max-content;
  box-sizing: border-box; }

/* Nav
 ========================================================================== */
/*
 * 1. Reset list
 */
.uk-navbar-nav {
  display: flex;
  gap: 0px;
  /* 1 */
  margin: 0;
  padding: 0;
  list-style: none; }

/*
 * Allow items to wrap into the next line
 * Only not `absolute` positioned groups
 */
.uk-navbar-left,
.uk-navbar-right,
.uk-navbar-center:only-child {
  flex-wrap: wrap; }

/*
 * Items
 * 1. Center content vertically and horizontally
 * 2. Imitate white space gap when using flexbox
 * 3. Dimensions
 * 4. Style
 * 5. Required for `a`
 */
.uk-navbar-nav > li > a,
.uk-navbar-item,
.uk-navbar-toggle {
  /* 1 */
  display: flex;
  justify-content: center;
  align-items: center;
  /* 2 */
  column-gap: 0.25em;
  /* 3 */
  box-sizing: border-box;
  min-height: 80px;
  /* 4 */
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  /* 5 */
  text-decoration: none; }

/*
 * Nav items
 */
.uk-navbar-nav > li > a {
  padding: 0 15px;
  color: #999; }

/*
 * Hover
 * Apply hover style also if dropdown is opened
 */
.uk-navbar-nav > li:hover > a,
.uk-navbar-nav > li > a[aria-expanded="true"] {
  color: #666; }

/* OnClick */
.uk-navbar-nav > li > a:active {
  color: #333; }

/* Active */
.uk-navbar-nav > li.uk-active > a {
  color: #333; }

/* Parent icon modifier
 ========================================================================== */
.uk-navbar-parent-icon {
  margin-left: 4px; }

.uk-navbar-nav > li > a[aria-expanded="true"] .uk-navbar-parent-icon {
  transform: rotate(180deg); }

/* Item
 ========================================================================== */
.uk-navbar-item {
  padding: 0 15px;
  color: #666; }

/*
 * Remove margin from the last-child
 */
.uk-navbar-item > :last-child {
  margin-bottom: 0; }

/* Toggle
 ========================================================================== */
.uk-navbar-toggle {
  padding: 0 15px;
  color: #999; }

.uk-navbar-toggle:hover,
.uk-navbar-toggle[aria-expanded="true"] {
  color: #666;
  text-decoration: none; }

/*
 * Icon
 * Adopts `uk-icon`
 */
/* Hover */
/* Subtitle
 ========================================================================== */
.uk-navbar-subtitle {
  font-size: 0.875rem; }

/* Justify modifier
 ========================================================================== */
.uk-navbar-justify .uk-navbar-left,
.uk-navbar-justify .uk-navbar-right,
.uk-navbar-justify .uk-navbar-nav,
.uk-navbar-justify .uk-navbar-nav > li,
.uk-navbar-justify .uk-navbar-item,
.uk-navbar-justify .uk-navbar-toggle {
  flex-grow: 1; }

/* Style modifiers
 ========================================================================== */
/* Dropdown
 ========================================================================== */
/*
 * Adopts `uk-dropdown`
 * 1. Hide by default
 * 2. Set position
 * 3. Set a default width
 * 4. Style
 */
.uk-navbar-dropdown {
  /* 1 */
  display: none;
  /* 2 */
  position: absolute;
  z-index: 1020;
  --uk-position-offset: 0;
  --uk-position-shift-offset: 0;
  --uk-position-viewport-offset: 15px;
  /* 3 */
  box-sizing: border-box;
  width: 200px;
  /* 4 */
  padding: 15px;
  background: #f8f8f8;
  color: #666; }

/* Show */
.uk-navbar-dropdown.uk-open {
  display: block; }

/*
 * Remove margin from the last-child
 */
.uk-navbar-dropdown > :last-child {
  margin-bottom: 0; }

/*
 * Grid
 * Adopts `uk-grid`
 */
/* Gutter Horizontal */
.uk-navbar-dropdown-grid {
  margin-left: -30px; }

.uk-navbar-dropdown-grid > * {
  padding-left: 30px; }

/* Gutter Vertical */
.uk-navbar-dropdown-grid > .uk-grid-margin {
  margin-top: 30px; }

/* Stack */
.uk-navbar-dropdown-stack .uk-navbar-dropdown-grid > * {
  width: 100% !important; }

/*
 * Width modifier
 */
.uk-navbar-dropdown-width-2:not(.uk-navbar-dropdown-stack) {
  width: 400px; }

.uk-navbar-dropdown-width-3:not(.uk-navbar-dropdown-stack) {
  width: 600px; }

.uk-navbar-dropdown-width-4:not(.uk-navbar-dropdown-stack) {
  width: 800px; }

.uk-navbar-dropdown-width-5:not(.uk-navbar-dropdown-stack) {
  width: 1000px; }

/*
 * Size modifier
 */
.uk-navbar-dropdown-large {
  --uk-position-shift-offset: 0;
  padding: 40px; }

/*
 * Dropbar modifier
 * 1. Reset dropdown width to prevent to early shifting
 * 2. Reset style
 * 3. Padding
 */
.uk-navbar-dropdown-dropbar {
  /* 1 */
  width: auto;
  /* 2 */
  background: transparent;
  /* 3 */
  padding: 15px 0 15px 0;
  --uk-position-offset: 0;
  --uk-position-shift-offset: 0;
  --uk-position-viewport-offset: 15px; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-navbar-dropdown-dropbar {
    --uk-position-viewport-offset: 30px; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-navbar-dropdown-dropbar {
    --uk-position-viewport-offset: 40px; } }

.uk-navbar-dropdown-dropbar-large {
  --uk-position-shift-offset: 0;
  padding-top: 40px;
  padding-bottom: 40px; }

/* Dropdown Nav
 * Adopts `uk-nav`
 ========================================================================== */
/*
 * Items
 */
.uk-navbar-dropdown-nav > li > a {
  color: #999; }

/* Hover */
.uk-navbar-dropdown-nav > li > a:hover {
  color: #666; }

/* Active */
.uk-navbar-dropdown-nav > li.uk-active > a {
  color: #333; }

/*
 * Subtitle
 */
.uk-navbar-dropdown-nav .uk-nav-subtitle {
  font-size: 0.875rem; }

/*
 * Header
 */
.uk-navbar-dropdown-nav .uk-nav-header {
  color: #333; }

/*
 * Divider
 */
.uk-navbar-dropdown-nav .uk-nav-divider {
  border-top: 1px solid #e5e5e5; }

/*
 * Sublists
 */
.uk-navbar-dropdown-nav .uk-nav-sub a {
  color: #999; }

.uk-navbar-dropdown-nav .uk-nav-sub a:hover {
  color: #666; }

.uk-navbar-dropdown-nav .uk-nav-sub li.uk-active > a {
  color: #333; }

/* Dropbar
 ========================================================================== */
/*
 * 1. Reset dropbar
 * 2. Width
 */
.uk-navbar-dropbar {
  /* 1 */
  display: block !important;
  z-index: 980;
  padding: 0;
  /* 2 */
  left: 0;
  right: 0; }

/* ========================================================================
   Component: Subnav
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Center items vertically if they have a different height
 * 3. Gutter
 * 4. Reset list
 */
.uk-subnav {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  align-items: center;
  /* 3 */
  margin-left: -20px;
  /* 4 */
  padding: 0;
  list-style: none; }

/*
 * 1. Space is allocated solely based on content dimensions: 0 0 auto
 * 2. Gutter
 * 3. Create position context for dropdowns
 */
.uk-subnav > * {
  /* 1 */
  flex: none;
  /* 2 */
  padding-left: 20px;
  /* 3 */
  position: relative; }

/* Items
 ========================================================================== */
/*
 * Items must target `a` elements to exclude other elements (e.g. dropdowns)
 * Using `:first-child` instead of `a` to support `span` elements for text
 * 1. Center content vertically, e.g. an icon
 * 2. Imitate white space gap when using flexbox
 * 3. Style
 */
.uk-subnav > * > :first-child {
  /* 1 */
  display: flex;
  align-items: center;
  /* 2 */
  column-gap: 0.25em;
  /* 3 */
  color: #999; }

/* Hover */
.uk-subnav > * > a:hover {
  color: #666;
  text-decoration: none; }

/* Active */
.uk-subnav > .uk-active > a {
  color: #333; }

/* Divider modifier
 ========================================================================== */
/*
 * Set gutter
 */
.uk-subnav-divider {
  margin-left: -41px; }

/*
 * Align items and divider vertically
 */
.uk-subnav-divider > * {
  display: flex;
  align-items: center; }

/*
 * Divider
 * 1. `nth-child` makes it also work without JS if it's only one row
 */
.uk-subnav-divider > ::before {
  content: "";
  height: 1.5em;
  margin-left: 0px;
  margin-right: 20px;
  border-left: 1px solid transparent; }

/* 1 */
.uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before {
  border-left-color: #e5e5e5; }

/* Pill modifier
 ========================================================================== */
.uk-subnav-pill > * > :first-child {
  padding: 5px 10px;
  background: transparent;
  color: #999; }

/* Hover */
.uk-subnav-pill > * > a:hover {
  background-color: #f8f8f8;
  color: #666; }

/* OnClick */
.uk-subnav-pill > * > a:active {
  background-color: #f8f8f8;
  color: #666; }

/* Active */
.uk-subnav-pill > .uk-active > a {
  background-color: #1e87f0;
  color: #fff; }

/* Disabled
 * The same for all style modifiers
 ========================================================================== */
.uk-subnav > .uk-disabled > a {
  color: #999; }

/* ========================================================================
   Component: Breadcrumb
 ========================================================================== */
/*
 * Reset list
 */
.uk-breadcrumb {
  padding: 0;
  list-style: none; }

/*
 * 1. Doesn't generate any box and replaced by child boxes
 */
.uk-breadcrumb > * {
  display: contents; }

/* Items
 ========================================================================== */
.uk-breadcrumb > * > * {
  font-size: 0.875rem;
  color: #999; }

/* Hover */
.uk-breadcrumb > * > :hover {
  color: #666;
  text-decoration: none; }

/* Disabled */
/* Active */
.uk-breadcrumb > :last-child > span,
.uk-breadcrumb > :last-child > a:not([href]) {
  color: #666; }

/*
 * Divider
 * `nth-child` makes it also work without JS if it's only one row
 * 1. Remove space between inline block elements.
 * 2. Style
 */
.uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before {
  content: "/";
  display: inline-block;
  /* 1 */
  margin: 0 20px 0 calc(20px - 4px);
  /* 2 */
  font-size: 0.875rem;
  color: #999; }

/* ========================================================================
   Component: Pagination
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Gutter
 * 3. Reset list
 */
.uk-pagination {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin-left: 0;
  /* 3 */
  padding: 0;
  list-style: none; }

/*
 * 1. Space is allocated solely based on content dimensions: 0 0 auto
 * 2. Gutter
 * 3. Create position context for dropdowns
 */
.uk-pagination > * {
  /* 1 */
  flex: none;
  /* 2 */
  padding-left: 0;
  /* 3 */
  position: relative; }

/* Items
 ========================================================================== */
/*
 * 1. Prevent gap if child element is `inline-block`, e.g. an icon
 * 2. Style
 */
.uk-pagination > * > * {
  /* 1 */
  display: block;
  /* 2 */
  padding: 5px 10px;
  color: #999; }

/* Hover */
.uk-pagination > * > :hover {
  color: #666;
  text-decoration: none; }

/* Active */
.uk-pagination > .uk-active > * {
  color: #666; }

/* Disabled */
.uk-pagination > .uk-disabled > * {
  color: #999; }

/* ========================================================================
   Component: Tab
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Gutter
 * 3. Reset list
 */
.uk-tab {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin-left: -20px;
  /* 3 */
  padding: 0;
  list-style: none; }

/*
 * 1. Space is allocated solely based on content dimensions: 0 0 auto
 * 2. Gutter
 * 3. Create position context for dropdowns
 */
.uk-tab > * {
  /* 1 */
  flex: none;
  /* 2 */
  padding-left: 20px;
  /* 3 */
  position: relative; }

/* Items
 ========================================================================== */
/*
 * Items must target `a` elements to exclude other elements (e.g. dropdowns)
 * 1. Center content vertically, e.g. an icon
 * 2. Imitate white space gap when using flexbox
 * 3. Center content if a width is set
 * 4. Style
 */
.uk-tab > * > a {
  /* 1 */
  display: flex;
  align-items: center;
  /* 2 */
  column-gap: 0.25em;
  /* 3 */
  justify-content: center;
  /* 4 */
  padding: 5px 10px;
  color: #999; }

/* Hover */
.uk-tab > * > a:hover {
  color: #666;
  text-decoration: none; }

/* Active */
.uk-tab > .uk-active > a {
  color: #333; }

/* Disabled */
.uk-tab > .uk-disabled > a {
  color: #999; }

/* Position modifier
 ========================================================================== */
/*
 * Bottom
 */
/*
 * Left + Right
 * 1. Reset Gutter
 */
.uk-tab-left,
.uk-tab-right {
  flex-direction: column;
  /* 1 */
  margin-left: 0; }

/* 1 */
.uk-tab-left > *,
.uk-tab-right > * {
  padding-left: 0; }

.uk-tab-left > * > a {
  justify-content: left; }

.uk-tab-right > * > a {
  justify-content: left; }

/* ========================================================================
   Component: Slidenav
 ========================================================================== */
/*
 * Adopts `uk-icon`
 */
.uk-slidenav {
  padding: 5px 10px;
  color: rgba(102, 102, 102, 0.5); }

/* Hover */
.uk-slidenav:hover {
  color: rgba(102, 102, 102, 0.9); }

/* OnClick */
.uk-slidenav:active {
  color: rgba(102, 102, 102, 0.5); }

/* Icon modifier
 ========================================================================== */
/*
 * Previous
 */
/*
 * Next
 */
/* Size modifier
 ========================================================================== */
.uk-slidenav-large {
  padding: 10px 10px; }

/* Container
 ========================================================================== */
.uk-slidenav-container {
  display: flex; }

/* ========================================================================
   Component: Dotnav
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Reset list
 * 3. Gutter
 */
.uk-dotnav {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 3 */
  margin-left: -12px; }

/*
 * 1. Space is allocated solely based on content dimensions: 0 0 auto
 * 2. Gutter
 */
.uk-dotnav > * {
  /* 1 */
  flex: none;
  /* 2 */
  padding-left: 12px; }

/* Items
 ========================================================================== */
/*
 * Items
 * 1. Hide text if present
 */
.uk-dotnav > * > * {
  display: block;
  box-sizing: border-box;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgba(102, 102, 102, 0.2);
  /* 1 */
  text-indent: 100%;
  overflow: hidden;
  white-space: nowrap; }

/* Hover */
.uk-dotnav > * > :hover {
  background-color: rgba(102, 102, 102, 0.6); }

/* OnClick */
.uk-dotnav > * > :active {
  background-color: rgba(102, 102, 102, 0.2); }

/* Active */
.uk-dotnav > .uk-active > * {
  background-color: rgba(102, 102, 102, 0.6); }

/* Modifier: 'uk-dotnav-vertical'
 ========================================================================== */
/*
 * 1. Change direction
 * 2. Gutter
 */
.uk-dotnav-vertical {
  /* 1 */
  flex-direction: column;
  /* 2 */
  margin-left: 0;
  margin-top: -12px; }

/* 2 */
.uk-dotnav-vertical > * {
  padding-left: 0;
  padding-top: 12px; }

/* ========================================================================
   Component: Thumbnav
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Reset list
 * 3. Gutter
 */
.uk-thumbnav {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 3 */
  margin-left: -15px; }

/*
 * Space is allocated based on content dimensions, but shrinks: 0 1 auto
 * 1. Gutter
 */
.uk-thumbnav > * {
  /* 1 */
  padding-left: 15px; }

/* Items
 ========================================================================== */
/*
 * Items
 */
.uk-thumbnav > * > * {
  display: inline-block; }

/* Hover */
/* Active */
/* Modifier: 'uk-thumbnav-vertical'
 ========================================================================== */
/*
 * 1. Change direction
 * 2. Gutter
 */
.uk-thumbnav-vertical {
  /* 1 */
  flex-direction: column;
  /* 2 */
  margin-left: 0;
  margin-top: -15px; }

/* 2 */
.uk-thumbnav-vertical > * {
  padding-left: 0;
  padding-top: 15px; }

/* ========================================================================
   Component: Iconnav
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Reset list
 * 3. Gutter
 */
.uk-iconnav {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 3 */
  margin-left: -10px; }

/*
 * Space is allocated based on content dimensions, but shrinks: 0 1 auto
 * 1. Gutter
 */
.uk-iconnav > * {
  /* 1 */
  padding-left: 10px; }

/* Items
 ========================================================================== */
/*
 * Items must target `a` elements to exclude other elements (e.g. dropdowns)
 * 1. Center content vertically if there is still some text
 * 2. Imitate white space gap when using flexbox
 * 3. Force text not to affect item height
 * 4. Style
 * 5. Required for `a` if there is still some text
 */
.uk-iconnav > * > a {
  /* 1 */
  display: flex;
  align-items: center;
  /* 2 */
  column-gap: 0.25em;
  /* 3 */
  line-height: 0;
  /* 4 */
  color: #999;
  /* 5 */
  text-decoration: none; }

/* Hover */
.uk-iconnav > * > a:hover {
  color: #666; }

/* Active */
.uk-iconnav > .uk-active > a {
  color: #666; }

/* Modifier: 'uk-iconnav-vertical'
 ========================================================================== */
/*
 * 1. Change direction
 * 2. Gutter
 */
.uk-iconnav-vertical {
  /* 1 */
  flex-direction: column;
  /* 2 */
  margin-left: 0;
  margin-top: -10px; }

/* 2 */
.uk-iconnav-vertical > * {
  padding-left: 0;
  padding-top: 10px; }

/* ========================================================================
   Component: Lightbox
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Allow scrolling for the modal dialog
 * 4. Horizontal padding
 * 5. Mask the background page
 * 6. Fade-in transition
 * 7. Prevent cancellation of pointer events while dragging
 */
.uk-lightbox {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  /* 5 */
  background: #000;
  /* 6 */
  opacity: 0;
  transition: opacity 0.15s linear;
  /* 7 */
  touch-action: pinch-zoom; }

/*
 * Open
 * 1. Center child
 * 2. Fade-in
 */
.uk-lightbox.uk-open {
  display: block;
  /* 2 */
  opacity: 1; }

/*
 * Focus
 */
.uk-lightbox :focus {
  outline-color: rgba(255, 255, 255, 0.7); }

.uk-lightbox :focus-visible {
  outline-color: rgba(255, 255, 255, 0.7); }

/* Page
 ========================================================================== */
/*
 * Prevent scrollbars
 */
.uk-lightbox-page {
  overflow: hidden; }

/* Item
 ========================================================================== */
/*
 * 1. Center child within the viewport
 * 2. Not visible by default
 * 3. Color needed for spinner icon
 * 4. Optimize animation
 * 5. Responsiveness
 *    Using `vh` for `max-height` to fix image proportions after resize in Safari and Opera
 */
.uk-lightbox-items > * {
  /* 1 */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  display: none;
  justify-content: center;
  align-items: center;
  /* 3 */
  color: rgba(255, 255, 255, 0.7);
  /* 4 */
  will-change: transform, opacity; }

/* 5 */
.uk-lightbox-items > * > * {
  max-width: 100vw;
  max-height: 100vh; }

.uk-lightbox-items > * > :not(iframe) {
  width: auto;
  height: auto; }

.uk-lightbox-items > .uk-active {
  display: flex; }

/* Toolbar
 ========================================================================== */
.uk-lightbox-toolbar {
  padding: 10px 10px;
  background: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.7); }

.uk-lightbox-toolbar > * {
  color: rgba(255, 255, 255, 0.7); }

/* Toolbar Icon (Close)
 ========================================================================== */
.uk-lightbox-toolbar-icon {
  padding: 5px;
  color: rgba(255, 255, 255, 0.7); }

/*
 * Hover
 */
.uk-lightbox-toolbar-icon:hover {
  color: #fff; }

/* Button (Slidenav)
 ========================================================================== */
/*
 * 1. Center icon vertically and horizontally
 */
.uk-lightbox-button {
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.7);
  /* 1 */
  display: inline-flex;
  justify-content: center;
  align-items: center; }

/* Hover */
.uk-lightbox-button:hover {
  color: #fff; }

/* OnClick */
/* Caption
 ========================================================================== */
.uk-lightbox-caption:empty {
  display: none; }

/* Iframe
 ========================================================================== */
.uk-lightbox-iframe {
  width: 80%;
  height: 80%; }

/* ========================================================================
   Component: Animation
 ========================================================================== */
[class*='uk-animation-'] {
  animation: 0.5s ease-out both; }

/* Animations
 ========================================================================== */
/*
 * Fade
 */
.uk-animation-fade {
  animation-name: uk-fade;
  animation-duration: 0.8s;
  animation-timing-function: linear; }

/*
 * Scale
 */
.uk-animation-scale-up {
  animation-name: uk-fade, uk-scale-up; }

.uk-animation-scale-down {
  animation-name: uk-fade, uk-scale-down; }

/*
 * Slide
 */
.uk-animation-slide-top {
  animation-name: uk-fade, uk-slide-top; }

.uk-animation-slide-bottom {
  animation-name: uk-fade, uk-slide-bottom; }

.uk-animation-slide-left {
  animation-name: uk-fade, uk-slide-left; }

.uk-animation-slide-right {
  animation-name: uk-fade, uk-slide-right; }

/*
 * Slide Small
 */
.uk-animation-slide-top-small {
  animation-name: uk-fade, uk-slide-top-small; }

.uk-animation-slide-bottom-small {
  animation-name: uk-fade, uk-slide-bottom-small; }

.uk-animation-slide-left-small {
  animation-name: uk-fade, uk-slide-left-small; }

.uk-animation-slide-right-small {
  animation-name: uk-fade, uk-slide-right-small; }

/*
 * Slide Medium
 */
.uk-animation-slide-top-medium {
  animation-name: uk-fade, uk-slide-top-medium; }

.uk-animation-slide-bottom-medium {
  animation-name: uk-fade, uk-slide-bottom-medium; }

.uk-animation-slide-left-medium {
  animation-name: uk-fade, uk-slide-left-medium; }

.uk-animation-slide-right-medium {
  animation-name: uk-fade, uk-slide-right-medium; }

/*
 * Kenburns
 */
.uk-animation-kenburns {
  animation-name: uk-kenburns;
  animation-duration: 15s; }

/*
 * Shake
 */
.uk-animation-shake {
  animation-name: uk-shake; }

/*
 * SVG Stroke
 * The `--uk-animation-stroke` custom property contains the longest path length.
 * Set it manually or use `uk-svg="stroke-animation: true"` to set it automatically.
 * All strokes are animated by the same pace and doesn't end simultaneously.
 * To end simultaneously, `pathLength="1"` could be used, but it's not working in Safari yet.
 */
.uk-animation-stroke {
  animation-name: uk-stroke;
  animation-duration: 2s;
  stroke-dasharray: var(--uk-animation-stroke); }

/* Direction modifier
 ========================================================================== */
.uk-animation-reverse {
  animation-direction: reverse;
  animation-timing-function: ease-in; }

/* Duration modifier
 ========================================================================== */
.uk-animation-fast {
  animation-duration: 0.1s; }

/* Toggle animation based on the State of the Parent Element
 ========================================================================== */
.uk-animation-toggle:not(:hover):not(:focus) [class*='uk-animation-'] {
  animation-name: none; }

/* Keyframes used by animation classes
 ========================================================================== */
/*
 * Fade
 */
@keyframes uk-fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/*
 * Scale
 */
@keyframes uk-scale-up {
  0% {
    transform: scale(0.9); }
  100% {
    transform: scale(1); } }

@keyframes uk-scale-down {
  0% {
    transform: scale(1.1); }
  100% {
    transform: scale(1); } }

/*
 * Slide
 */
@keyframes uk-slide-top {
  0% {
    transform: translateY(-100%); }
  100% {
    transform: translateY(0); } }

@keyframes uk-slide-bottom {
  0% {
    transform: translateY(100%); }
  100% {
    transform: translateY(0); } }

@keyframes uk-slide-left {
  0% {
    transform: translateX(-100%); }
  100% {
    transform: translateX(0); } }

@keyframes uk-slide-right {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(0); } }

/*
 * Slide Small
 */
@keyframes uk-slide-top-small {
  0% {
    transform: translateY(-10px); }
  100% {
    transform: translateY(0); } }

@keyframes uk-slide-bottom-small {
  0% {
    transform: translateY(10px); }
  100% {
    transform: translateY(0); } }

@keyframes uk-slide-left-small {
  0% {
    transform: translateX(-10px); }
  100% {
    transform: translateX(0); } }

@keyframes uk-slide-right-small {
  0% {
    transform: translateX(10px); }
  100% {
    transform: translateX(0); } }

/*
 * Slide Medium
 */
@keyframes uk-slide-top-medium {
  0% {
    transform: translateY(-50px); }
  100% {
    transform: translateY(0); } }

@keyframes uk-slide-bottom-medium {
  0% {
    transform: translateY(50px); }
  100% {
    transform: translateY(0); } }

@keyframes uk-slide-left-medium {
  0% {
    transform: translateX(-50px); }
  100% {
    transform: translateX(0); } }

@keyframes uk-slide-right-medium {
  0% {
    transform: translateX(50px); }
  100% {
    transform: translateX(0); } }

/*
 * Kenburns
 */
@keyframes uk-kenburns {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(1.2); } }

/*
 * Shake
 */
@keyframes uk-shake {
  0%, 100% {
    transform: translateX(0); }
  10% {
    transform: translateX(-9px); }
  20% {
    transform: translateX(8px); }
  30% {
    transform: translateX(-7px); }
  40% {
    transform: translateX(6px); }
  50% {
    transform: translateX(-5px); }
  60% {
    transform: translateX(4px); }
  70% {
    transform: translateX(-3px); }
  80% {
    transform: translateX(2px); }
  90% {
    transform: translateX(-1px); } }

/*
 * Stroke
 */
@keyframes uk-stroke {
  0% {
    stroke-dashoffset: var(--uk-animation-stroke); }
  100% {
    stroke-dashoffset: 0; } }

/* ========================================================================
   Component: Width
 ========================================================================== */
/* Equal child widths
 ========================================================================== */
[class*='uk-child-width'] > * {
  box-sizing: border-box;
  width: 100%; }

.uk-child-width-1-2 > * {
  width: 50%; }

.uk-child-width-1-3 > * {
  width: calc(100% * 1 / 3.001); }

.uk-child-width-1-4 > * {
  width: 25%; }

.uk-child-width-1-5 > * {
  width: 20%; }

.uk-child-width-1-6 > * {
  width: calc(100% * 1 / 6.001); }

.uk-child-width-auto > * {
  width: auto; }

/*
 * 1. Reset the `min-width`, which is set to auto by default, because
 *    flex items won't shrink below their minimum intrinsic content size.
 *    Using `1px` instead of `0`, so items still wrap into the next line,
 *    if they have zero width and padding and the predecessor is 100% wide.
 */
.uk-child-width-expand > :not([class*='uk-width']) {
  flex: 1;
  /* 1 */
  min-width: 1px; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-child-width-1-1\@s > * {
    width: 100%; }
  .uk-child-width-1-2\@s > * {
    width: 50%; }
  .uk-child-width-1-3\@s > * {
    width: calc(100% * 1 / 3.001); }
  .uk-child-width-1-4\@s > * {
    width: 25%; }
  .uk-child-width-1-5\@s > * {
    width: 20%; }
  .uk-child-width-1-6\@s > * {
    width: calc(100% * 1 / 6.001); }
  .uk-child-width-auto\@s > * {
    width: auto; }
  .uk-child-width-expand\@s > :not([class*='uk-width']) {
    flex: 1;
    min-width: 1px; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-child-width-1-1\@m > * {
    width: 100%; }
  .uk-child-width-1-2\@m > * {
    width: 50%; }
  .uk-child-width-1-3\@m > * {
    width: calc(100% * 1 / 3.001); }
  .uk-child-width-1-4\@m > * {
    width: 25%; }
  .uk-child-width-1-5\@m > * {
    width: 20%; }
  .uk-child-width-1-6\@m > * {
    width: calc(100% * 1 / 6.001); }
  .uk-child-width-auto\@m > * {
    width: auto; }
  .uk-child-width-expand\@m > :not([class*='uk-width']) {
    flex: 1;
    min-width: 1px; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-child-width-1-1\@l > * {
    width: 100%; }
  .uk-child-width-1-2\@l > * {
    width: 50%; }
  .uk-child-width-1-3\@l > * {
    width: calc(100% * 1 / 3.001); }
  .uk-child-width-1-4\@l > * {
    width: 25%; }
  .uk-child-width-1-5\@l > * {
    width: 20%; }
  .uk-child-width-1-6\@l > * {
    width: calc(100% * 1 / 6.001); }
  .uk-child-width-auto\@l > * {
    width: auto; }
  .uk-child-width-expand\@l > :not([class*='uk-width']) {
    flex: 1;
    min-width: 1px; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-child-width-1-1\@xl > * {
    width: 100%; }
  .uk-child-width-1-2\@xl > * {
    width: 50%; }
  .uk-child-width-1-3\@xl > * {
    width: calc(100% * 1 / 3.001); }
  .uk-child-width-1-4\@xl > * {
    width: 25%; }
  .uk-child-width-1-5\@xl > * {
    width: 20%; }
  .uk-child-width-1-6\@xl > * {
    width: calc(100% * 1 / 6.001); }
  .uk-child-width-auto\@xl > * {
    width: auto; }
  .uk-child-width-expand\@xl > :not([class*='uk-width']) {
    flex: 1;
    min-width: 1px; } }

/* Single Widths
 ========================================================================== */
/*
 * 1. `max-width` is needed for the pixel-based classes
 */
[class*='uk-width'] {
  box-sizing: border-box;
  width: 100%;
  /* 1 */
  max-width: 100%; }

/* Halves */
.uk-width-1-2 {
  width: 50%; }

/* Thirds */
.uk-width-1-3 {
  width: calc(100% * 1 / 3.001); }

.uk-width-2-3 {
  width: calc(100% * 2 / 3.001); }

/* Quarters */
.uk-width-1-4 {
  width: 25%; }

.uk-width-3-4 {
  width: 75%; }

/* Fifths */
.uk-width-1-5 {
  width: 20%; }

.uk-width-2-5 {
  width: 40%; }

.uk-width-3-5 {
  width: 60%; }

.uk-width-4-5 {
  width: 80%; }

/* Sixths */
.uk-width-1-6 {
  width: calc(100% * 1 / 6.001); }

.uk-width-5-6 {
  width: calc(100% * 5 / 6.001); }

/* Pixel */
.uk-width-small {
  width: 150px; }

.uk-width-medium {
  width: 300px; }

.uk-width-large {
  width: 450px; }

.uk-width-xlarge {
  width: 600px; }

.uk-width-2xlarge {
  width: 750px; }

/* Auto */
.uk-width-auto {
  width: auto; }

/* Expand */
.uk-width-expand {
  flex: 1;
  min-width: 1px; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  /* Whole */
  .uk-width-1-1\@s {
    width: 100%; }
  /* Halves */
  .uk-width-1-2\@s {
    width: 50%; }
  /* Thirds */
  .uk-width-1-3\@s {
    width: calc(100% * 1 / 3.001); }
  .uk-width-2-3\@s {
    width: calc(100% * 2 / 3.001); }
  /* Quarters */
  .uk-width-1-4\@s {
    width: 25%; }
  .uk-width-3-4\@s {
    width: 75%; }
  /* Fifths */
  .uk-width-1-5\@s {
    width: 20%; }
  .uk-width-2-5\@s {
    width: 40%; }
  .uk-width-3-5\@s {
    width: 60%; }
  .uk-width-4-5\@s {
    width: 80%; }
  /* Sixths */
  .uk-width-1-6\@s {
    width: calc(100% * 1 / 6.001); }
  .uk-width-5-6\@s {
    width: calc(100% * 5 / 6.001); }
  /* Pixel */
  .uk-width-small\@s {
    width: 150px; }
  .uk-width-medium\@s {
    width: 300px; }
  .uk-width-large\@s {
    width: 450px; }
  .uk-width-xlarge\@s {
    width: 600px; }
  .uk-width-2xlarge\@s {
    width: 750px; }
  /* Auto */
  .uk-width-auto\@s {
    width: auto; }
  /* Expand */
  .uk-width-expand\@s {
    flex: 1;
    min-width: 1px; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  /* Whole */
  .uk-width-1-1\@m {
    width: 100%; }
  /* Halves */
  .uk-width-1-2\@m {
    width: 50%; }
  /* Thirds */
  .uk-width-1-3\@m {
    width: calc(100% * 1 / 3.001); }
  .uk-width-2-3\@m {
    width: calc(100% * 2 / 3.001); }
  /* Quarters */
  .uk-width-1-4\@m {
    width: 25%; }
  .uk-width-3-4\@m {
    width: 75%; }
  /* Fifths */
  .uk-width-1-5\@m {
    width: 20%; }
  .uk-width-2-5\@m {
    width: 40%; }
  .uk-width-3-5\@m {
    width: 60%; }
  .uk-width-4-5\@m {
    width: 80%; }
  /* Sixths */
  .uk-width-1-6\@m {
    width: calc(100% * 1 / 6.001); }
  .uk-width-5-6\@m {
    width: calc(100% * 5 / 6.001); }
  /* Pixel */
  .uk-width-small\@m {
    width: 150px; }
  .uk-width-medium\@m {
    width: 300px; }
  .uk-width-large\@m {
    width: 450px; }
  .uk-width-xlarge\@m {
    width: 600px; }
  .uk-width-2xlarge\@m {
    width: 750px; }
  /* Auto */
  .uk-width-auto\@m {
    width: auto; }
  /* Expand */
  .uk-width-expand\@m {
    flex: 1;
    min-width: 1px; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Whole */
  .uk-width-1-1\@l {
    width: 100%; }
  /* Halves */
  .uk-width-1-2\@l {
    width: 50%; }
  /* Thirds */
  .uk-width-1-3\@l {
    width: calc(100% * 1 / 3.001); }
  .uk-width-2-3\@l {
    width: calc(100% * 2 / 3.001); }
  /* Quarters */
  .uk-width-1-4\@l {
    width: 25%; }
  .uk-width-3-4\@l {
    width: 75%; }
  /* Fifths */
  .uk-width-1-5\@l {
    width: 20%; }
  .uk-width-2-5\@l {
    width: 40%; }
  .uk-width-3-5\@l {
    width: 60%; }
  .uk-width-4-5\@l {
    width: 80%; }
  /* Sixths */
  .uk-width-1-6\@l {
    width: calc(100% * 1 / 6.001); }
  .uk-width-5-6\@l {
    width: calc(100% * 5 / 6.001); }
  /* Pixel */
  .uk-width-small\@l {
    width: 150px; }
  .uk-width-medium\@l {
    width: 300px; }
  .uk-width-large\@l {
    width: 450px; }
  .uk-width-xlarge\@l {
    width: 600px; }
  .uk-width-2xlarge\@l {
    width: 750px; }
  /* Auto */
  .uk-width-auto\@l {
    width: auto; }
  /* Expand */
  .uk-width-expand\@l {
    flex: 1;
    min-width: 1px; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  /* Whole */
  .uk-width-1-1\@xl {
    width: 100%; }
  /* Halves */
  .uk-width-1-2\@xl {
    width: 50%; }
  /* Thirds */
  .uk-width-1-3\@xl {
    width: calc(100% * 1 / 3.001); }
  .uk-width-2-3\@xl {
    width: calc(100% * 2 / 3.001); }
  /* Quarters */
  .uk-width-1-4\@xl {
    width: 25%; }
  .uk-width-3-4\@xl {
    width: 75%; }
  /* Fifths */
  .uk-width-1-5\@xl {
    width: 20%; }
  .uk-width-2-5\@xl {
    width: 40%; }
  .uk-width-3-5\@xl {
    width: 60%; }
  .uk-width-4-5\@xl {
    width: 80%; }
  /* Sixths */
  .uk-width-1-6\@xl {
    width: calc(100% * 1 / 6.001); }
  .uk-width-5-6\@xl {
    width: calc(100% * 5 / 6.001); }
  /* Pixel */
  .uk-width-small\@xl {
    width: 150px; }
  .uk-width-medium\@xl {
    width: 300px; }
  .uk-width-large\@xl {
    width: 450px; }
  .uk-width-xlarge\@xl {
    width: 600px; }
  .uk-width-2xlarge\@xl {
    width: 750px; }
  /* Auto */
  .uk-width-auto\@xl {
    width: auto; }
  /* Expand */
  .uk-width-expand\@xl {
    flex: 1;
    min-width: 1px; } }

/* Intrinsic Widths
 ========================================================================== */
.uk-width-max-content {
  width: max-content; }

.uk-width-min-content {
  width: min-content; }

/* ========================================================================
   Component: Height
 ========================================================================== */
[class*='uk-height'] {
  box-sizing: border-box; }

/*
 * Only works if parent element has a height set
 */
.uk-height-1-1 {
  height: 100%; }

/*
 * Useful to create image teasers
 */
.uk-height-viewport {
  min-height: 100vh; }

.uk-height-viewport-2 {
  min-height: 200vh; }

.uk-height-viewport-3 {
  min-height: 300vh; }

.uk-height-viewport-4 {
  min-height: 400vh; }

/*
 * Pixel
 * Useful for `overflow: auto`
 */
.uk-height-small {
  height: 150px; }

.uk-height-medium {
  height: 300px; }

.uk-height-large {
  height: 450px; }

.uk-height-max-small {
  max-height: 150px; }

.uk-height-max-medium {
  max-height: 300px; }

.uk-height-max-large {
  max-height: 450px; }

/* ========================================================================
   Component: Text
 ========================================================================== */
/* Style modifiers
 ========================================================================== */
.uk-text-lead {
  font-size: 1.5rem;
  line-height: 1.5;
  color: #333; }

.uk-text-meta {
  font-size: 0.875rem;
  line-height: 1.4;
  color: #999; }

/* Size modifiers
 ========================================================================== */
.uk-text-small {
  font-size: 0.875rem;
  line-height: 1.5; }

.uk-text-large {
  font-size: 1.5rem;
  line-height: 1.5; }

.uk-text-default {
  font-size: 16px;
  line-height: 1.5; }

/* Weight modifier
 ========================================================================== */
.uk-text-light {
  font-weight: 300; }

.uk-text-normal {
  font-weight: 400; }

.uk-text-bold {
  font-weight: 700; }

.uk-text-lighter {
  font-weight: lighter; }

.uk-text-bolder {
  font-weight: bolder; }

/* Style modifier
 ========================================================================== */
.uk-text-italic {
  font-style: italic; }

/* Transform modifier
 ========================================================================== */
.uk-text-capitalize {
  text-transform: capitalize !important; }

.uk-text-uppercase {
  text-transform: uppercase !important; }

.uk-text-lowercase {
  text-transform: lowercase !important; }

/* Decoration modifier
 ========================================================================== */
.uk-text-decoration-none {
  text-decoration: none !important; }

/* Color modifiers
 ========================================================================== */
.uk-text-muted {
  color: #999 !important; }

.uk-text-emphasis {
  color: #333 !important; }

.uk-text-primary {
  color: #1e87f0 !important; }

.uk-text-secondary {
  color: #222 !important; }

.uk-text-success {
  color: #32d296 !important; }

.uk-text-warning {
  color: #faa05a !important; }

.uk-text-danger {
  color: #f0506e !important; }

/* Background modifier
 ========================================================================== */
/*
 * 1. The background clips to the foreground text. Works in all browsers.
 * 2. Default color is set to transparent.
 * 3. Container fits the text
 * 4. Style
 */
.uk-text-background {
  /* 1 */
  -webkit-background-clip: text;
  /* 2 */
  color: transparent !important;
  /* 3 */
  display: inline-block;
  /* 4 */
  background-color: #1e87f0; }

/* Alignment modifiers
 ========================================================================== */
.uk-text-left {
  text-align: left !important; }

.uk-text-right {
  text-align: right !important; }

.uk-text-center {
  text-align: center !important; }

.uk-text-justify {
  text-align: justify !important; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-text-left\@s {
    text-align: left !important; }
  .uk-text-right\@s {
    text-align: right !important; }
  .uk-text-center\@s {
    text-align: center !important; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-text-left\@m {
    text-align: left !important; }
  .uk-text-right\@m {
    text-align: right !important; }
  .uk-text-center\@m {
    text-align: center !important; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-text-left\@l {
    text-align: left !important; }
  .uk-text-right\@l {
    text-align: right !important; }
  .uk-text-center\@l {
    text-align: center !important; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-text-left\@xl {
    text-align: left !important; }
  .uk-text-right\@xl {
    text-align: right !important; }
  .uk-text-center\@xl {
    text-align: center !important; } }

/*
 * Vertical
 */
.uk-text-top {
  vertical-align: top !important; }

.uk-text-middle {
  vertical-align: middle !important; }

.uk-text-bottom {
  vertical-align: bottom !important; }

.uk-text-baseline {
  vertical-align: baseline !important; }

/* Wrap modifiers
 ========================================================================== */
/*
 * Prevent text from wrapping onto multiple lines
 */
.uk-text-nowrap {
  white-space: nowrap; }

/*
 * 1. Make sure a max-width is set after which truncation can occur
 * 2. Prevent text from wrapping onto multiple lines, and truncate with an ellipsis
 * 3. Fix for table cells
 */
.uk-text-truncate {
  /* 1 */
  max-width: 100%;
  /* 2 */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

/* 2 */
th.uk-text-truncate,
td.uk-text-truncate {
  max-width: 0; }

/*
 * Wrap long words onto the next line and break them if they are too long to fit.
 * 1. Make it work with table cells in all browsers.
 * Note: Not using `hyphens: auto` because it hyphenates text even if not needed.
 */
.uk-text-break {
  overflow-wrap: break-word; }

/* 1 */
th.uk-text-break,
td.uk-text-break {
  word-break: break-word; }

/* ========================================================================
   Component: Column
 ========================================================================== */
[class*='uk-column-'] {
  column-gap: 30px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  [class*='uk-column-'] {
    column-gap: 40px; } }

/*
 * Fix image 1px line wrapping into the next column in Chrome
 */
[class*='uk-column-'] img {
  transform: translate3d(0, 0, 0); }

/* Divider
 ========================================================================== */
/*
 * 1. Double the column gap
 */
.uk-column-divider {
  column-rule: 1px solid #e5e5e5;
  /* 1 */
  column-gap: 60px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-column-divider {
    column-gap: 80px; } }

/* Width modifiers
 ========================================================================== */
.uk-column-1-2 {
  column-count: 2; }

.uk-column-1-3 {
  column-count: 3; }

.uk-column-1-4 {
  column-count: 4; }

.uk-column-1-5 {
  column-count: 5; }

.uk-column-1-6 {
  column-count: 6; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-column-1-2\@s {
    column-count: 2; }
  .uk-column-1-3\@s {
    column-count: 3; }
  .uk-column-1-4\@s {
    column-count: 4; }
  .uk-column-1-5\@s {
    column-count: 5; }
  .uk-column-1-6\@s {
    column-count: 6; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-column-1-2\@m {
    column-count: 2; }
  .uk-column-1-3\@m {
    column-count: 3; }
  .uk-column-1-4\@m {
    column-count: 4; }
  .uk-column-1-5\@m {
    column-count: 5; }
  .uk-column-1-6\@m {
    column-count: 6; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-column-1-2\@l {
    column-count: 2; }
  .uk-column-1-3\@l {
    column-count: 3; }
  .uk-column-1-4\@l {
    column-count: 4; }
  .uk-column-1-5\@l {
    column-count: 5; }
  .uk-column-1-6\@l {
    column-count: 6; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-column-1-2\@xl {
    column-count: 2; }
  .uk-column-1-3\@xl {
    column-count: 3; }
  .uk-column-1-4\@xl {
    column-count: 4; }
  .uk-column-1-5\@xl {
    column-count: 5; }
  .uk-column-1-6\@xl {
    column-count: 6; } }

/* Make element span across all columns
 * Does not work in Firefox yet
 ========================================================================== */
.uk-column-span {
  column-span: all; }

/* ========================================================================
   Component: Cover
 ========================================================================== */
/*
 * Works with iframes and embedded content
 * 1. Use attribute to apply transform instantly. Needed if transform is transitioned.
 * 2. Reset responsiveness for embedded content
 * 3. Center object
 * Note: Percent values on the `top` property only works if this element
 *       is absolute positioned or if the container has a height
 */
/* 1 */
[uk-cover],
[data-uk-cover] {
  /* 2 */
  max-width: none;
  /* 3 */
  position: absolute;
  left: 50%;
  top: 50%;
  --uk-position-translate-x: -50%;
  --uk-position-translate-y: -50%;
  transform: translate(var(--uk-position-translate-x), var(--uk-position-translate-y)); }

iframe[uk-cover],
iframe[data-uk-cover] {
  pointer-events: none; }

/* Container
 ========================================================================== */
/*
 * 1. Parent container which clips resized object
 * 2. Needed if the child is positioned absolute. See note above
 */
.uk-cover-container {
  /* 1 */
  overflow: hidden;
  /* 2 */
  position: relative; }

/* ========================================================================
   Component: Background
 ========================================================================== */
/* Color
 ========================================================================== */
.uk-background-default {
  background-color: #fff; }

.uk-background-muted {
  background-color: #f8f8f8; }

.uk-background-primary {
  background-color: #1e87f0; }

.uk-background-secondary {
  background-color: #222; }

/* Size
 ========================================================================== */
.uk-background-cover,
.uk-background-contain,
.uk-background-width-1-1,
.uk-background-height-1-1 {
  background-position: 50% 50%;
  background-repeat: no-repeat; }

.uk-background-cover {
  background-size: cover; }

.uk-background-contain {
  background-size: contain; }

.uk-background-width-1-1 {
  background-size: 100%; }

.uk-background-height-1-1 {
  background-size: auto 100%; }

/* Position
 ========================================================================== */
.uk-background-top-left {
  background-position: 0 0; }

.uk-background-top-center {
  background-position: 50% 0; }

.uk-background-top-right {
  background-position: 100% 0; }

.uk-background-center-left {
  background-position: 0 50%; }

.uk-background-center-center {
  background-position: 50% 50%; }

.uk-background-center-right {
  background-position: 100% 50%; }

.uk-background-bottom-left {
  background-position: 0 100%; }

.uk-background-bottom-center {
  background-position: 50% 100%; }

.uk-background-bottom-right {
  background-position: 100% 100%; }

/* Repeat
 ========================================================================== */
.uk-background-norepeat {
  background-repeat: no-repeat; }

/* Attachment
 ========================================================================== */
/*
 * 1. Fix bug introduced in Chrome 67: the background image is not visible if any element on the page uses `translate3d`
 */
.uk-background-fixed {
  background-attachment: fixed;
  /* 1 */
  backface-visibility: hidden; }

/*
 * Exclude touch devices because `fixed` doesn't work on iOS and Android
 */
@media (pointer: coarse) {
  .uk-background-fixed {
    background-attachment: scroll; } }

/* Image
 ========================================================================== */
/* Phone portrait and smaller */
@media (max-width: 639px) {
  .uk-background-image\@s {
    background-image: none !important; } }

/* Phone landscape and smaller */
@media (max-width: 959px) {
  .uk-background-image\@m {
    background-image: none !important; } }

/* Tablet landscape and smaller */
@media (max-width: 1199px) {
  .uk-background-image\@l {
    background-image: none !important; } }

/* Desktop and smaller */
@media (max-width: 1599px) {
  .uk-background-image\@xl {
    background-image: none !important; } }

/* Blend modes
 ========================================================================== */
.uk-background-blend-multiply {
  background-blend-mode: multiply; }

.uk-background-blend-screen {
  background-blend-mode: screen; }

.uk-background-blend-overlay {
  background-blend-mode: overlay; }

.uk-background-blend-darken {
  background-blend-mode: darken; }

.uk-background-blend-lighten {
  background-blend-mode: lighten; }

.uk-background-blend-color-dodge {
  background-blend-mode: color-dodge; }

.uk-background-blend-color-burn {
  background-blend-mode: color-burn; }

.uk-background-blend-hard-light {
  background-blend-mode: hard-light; }

.uk-background-blend-soft-light {
  background-blend-mode: soft-light; }

.uk-background-blend-difference {
  background-blend-mode: difference; }

.uk-background-blend-exclusion {
  background-blend-mode: exclusion; }

.uk-background-blend-hue {
  background-blend-mode: hue; }

.uk-background-blend-saturation {
  background-blend-mode: saturation; }

.uk-background-blend-color {
  background-blend-mode: color; }

.uk-background-blend-luminosity {
  background-blend-mode: luminosity; }

/* ========================================================================
   Component: Align
 ========================================================================== */
/*
 * Default
 */
[class*='uk-align'] {
  display: block;
  margin-bottom: 30px; }

* + [class*='uk-align'] {
  margin-top: 30px; }

/*
 * Center
 */
.uk-align-center {
  margin-left: auto;
  margin-right: auto; }

/*
 * Left/Right
 */
.uk-align-left {
  margin-top: 0;
  margin-right: 30px;
  float: left; }

.uk-align-right {
  margin-top: 0;
  margin-left: 30px;
  float: right; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-align-left\@s {
    margin-top: 0;
    margin-right: 30px;
    float: left; }
  .uk-align-right\@s {
    margin-top: 0;
    margin-left: 30px;
    float: right; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-align-left\@m {
    margin-top: 0;
    margin-right: 30px;
    float: left; }
  .uk-align-right\@m {
    margin-top: 0;
    margin-left: 30px;
    float: right; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-align-left\@l {
    margin-top: 0;
    float: left; }
  .uk-align-right\@l {
    margin-top: 0;
    float: right; }
  .uk-align-left,
  .uk-align-left\@s,
  .uk-align-left\@m,
  .uk-align-left\@l {
    margin-right: 40px; }
  .uk-align-right,
  .uk-align-right\@s,
  .uk-align-right\@m,
  .uk-align-right\@l {
    margin-left: 40px; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-align-left\@xl {
    margin-top: 0;
    margin-right: 40px;
    float: left; }
  .uk-align-right\@xl {
    margin-top: 0;
    margin-left: 40px;
    float: right; } }

/* ========================================================================
   Component: SVG
 ========================================================================== */
/*
 * 1. Fill all SVG elements with the current text color if no `fill` attribute is set
 * 2. Set the fill and stroke color of all SVG elements to the current text color
 */
/* 1 */
.uk-svg,
.uk-svg:not(.uk-preserve) [fill*='#']:not(.uk-preserve) {
  fill: currentcolor; }

.uk-svg:not(.uk-preserve) [stroke*='#']:not(.uk-preserve) {
  stroke: currentcolor; }

/*
 * Fix Firefox blurry SVG rendering: https://bugzilla.mozilla.org/show_bug.cgi?id=1046835
 */
.uk-svg {
  transform: translate(0, 0); }

/* ========================================================================
   Component: Utility
 ========================================================================== */
/* Panel
 ========================================================================== */
.uk-panel {
  display: flow-root;
  position: relative;
  box-sizing: border-box; }

/*
 * Remove margin from the last-child
 */
.uk-panel > :last-child {
  margin-bottom: 0; }

/*
 * Scrollable
 */
.uk-panel-scrollable {
  height: 170px;
  padding: 10px;
  border: 1px solid #e5e5e5;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  resize: both; }

/* Clearfix
 ========================================================================== */
/*
 * 1. `table-cell` is used with `::before` because `table` creates a 1px gap when it becomes a flex item, only in Webkit
 * 2. `table` is used again with `::after` because `clear` only works with block elements.
 * Note: `display: block` with `overflow: hidden` is currently not working in the latest Safari
 */
/* 1 */
.uk-clearfix::before {
  content: "";
  display: table-cell; }

/* 2 */
.uk-clearfix::after {
  content: "";
  display: table;
  clear: both; }

/* Float
 ========================================================================== */
/*
 * 1. Prevent content overflow
 */
.uk-float-left {
  float: left; }

.uk-float-right {
  float: right; }

/* 1 */
[class*='uk-float-'] {
  max-width: 100%; }

/* Overfow
 ========================================================================== */
.uk-overflow-hidden {
  overflow: hidden; }

/*
 * Enable scrollbars if content is clipped
 */
.uk-overflow-auto {
  overflow: auto;
  -webkit-overflow-scrolling: touch; }

.uk-overflow-auto > :last-child {
  margin-bottom: 0; }

/* Box Sizing
 ========================================================================== */
.uk-box-sizing-content {
  box-sizing: content-box; }

.uk-box-sizing-border {
  box-sizing: border-box; }

/* Resize
 ========================================================================== */
.uk-resize {
  resize: both; }

.uk-resize-horizontal {
  resize: horizontal; }

.uk-resize-vertical {
  resize: vertical; }

/* Display
 ========================================================================== */
.uk-display-block {
  display: block !important; }

.uk-display-inline {
  display: inline !important; }

.uk-display-inline-block {
  display: inline-block !important; }

/* Inline
 ========================================================================== */
/*
 * 1. Container fits its content
 * 2. Create position context
 * 3. Prevent content overflow
 * 4. Behave like most inline-block elements
 * 5. Force new layer without creating a new stacking context
 *    to fix 1px glitch when combined with overlays and transitions in Webkit
 * 6. Clip child elements
 */
[class*='uk-inline'] {
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  /* 3 */
  max-width: 100%;
  /* 4 */
  vertical-align: middle;
  /* 5 */
  -webkit-backface-visibility: hidden; }

.uk-inline-clip {
  /* 6 */
  overflow: hidden; }

/* Responsive objects
 ========================================================================== */
/*
 * Preserve original dimensions
 * Because `img, `video`, `canvas` and  `audio` are already responsive by default, see Base component
 */
.uk-preserve-width,
.uk-preserve-width canvas,
.uk-preserve-width img,
.uk-preserve-width svg,
.uk-preserve-width video {
  max-width: none; }

/*
 * Responsiveness
 * Corrects `max-width` and `max-height` behavior if padding and border are used
 */
.uk-responsive-width,
.uk-responsive-height {
  box-sizing: border-box; }

/*
 * 1. Set a maximum width. `important` needed to override `uk-preserve-width img`
 * 2. Auto scale the height. Only needed if `height` attribute is present
 */
.uk-responsive-width {
  /* 1 */
  max-width: 100% !important;
  /* 2 */
  height: auto; }

/*
 * 1. Set a maximum height. Only works if the parent element has a fixed height
 * 2. Auto scale the width. Only needed if `width` attribute is present
 * 3. Reset max-width, which `img, `video`, `canvas` and  `audio` already have by default
 */
.uk-responsive-height {
  /* 1 */
  max-height: 100%;
  /* 2 */
  width: auto;
  /* 3 */
  max-width: none; }

/*
 * Fix initial iframe width. Without the viewport is expanded on iOS devices
 */
[uk-responsive],
[data-uk-responsive] {
  max-width: 100%; }

/* Object
 ========================================================================== */
.uk-object-cover {
  object-fit: cover; }

.uk-object-contain {
  object-fit: contain; }

.uk-object-fill {
  object-fit: fill; }

.uk-object-none {
  object-fit: none; }

.uk-object-scale-down {
  object-fit: scale-down; }

/* 
 * Position
 */
.uk-object-top-left {
  object-position: 0 0; }

.uk-object-top-center {
  object-position: 50% 0; }

.uk-object-top-right {
  object-position: 100% 0; }

.uk-object-center-left {
  object-position: 0 50%; }

.uk-object-center-center {
  object-position: 50% 50%; }

.uk-object-center-right {
  object-position: 100% 50%; }

.uk-object-bottom-left {
  object-position: 0 100%; }

.uk-object-bottom-center {
  object-position: 50% 100%; }

.uk-object-bottom-right {
  object-position: 100% 100%; }

/* Border
 ========================================================================== */
.uk-border-circle {
  border-radius: 50%; }

.uk-border-pill {
  border-radius: 500px; }

.uk-border-rounded {
  border-radius: 5px; }

/*
 * Fix `overflow: hidden` to be ignored with border-radius and CSS transforms in Webkit
 */
.uk-inline-clip[class*='uk-border-'] {
  -webkit-transform: translateZ(0); }

/* Box-shadow
 ========================================================================== */
.uk-box-shadow-small {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08); }

.uk-box-shadow-medium {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08); }

.uk-box-shadow-large {
  box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16); }

.uk-box-shadow-xlarge {
  box-shadow: 0 28px 50px rgba(0, 0, 0, 0.16); }

/*
 * Hover
 */
[class*='uk-box-shadow-hover'] {
  transition: box-shadow 0.1s ease-in-out; }

.uk-box-shadow-hover-small:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08); }

.uk-box-shadow-hover-medium:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08); }

.uk-box-shadow-hover-large:hover {
  box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16); }

.uk-box-shadow-hover-xlarge:hover {
  box-shadow: 0 28px 50px rgba(0, 0, 0, 0.16); }

/* Box-shadow bottom
 ========================================================================== */
/*
 * 1. Set position.
 * 2. Set style
 * 3. Fix shadow being clipped in Safari if container is animated
 */
@supports (filter: blur(0)) {
  .uk-box-shadow-bottom {
    display: inline-block;
    position: relative;
    z-index: 0;
    max-width: 100%;
    vertical-align: middle; }
  .uk-box-shadow-bottom::after {
    content: "";
    /* 1 */
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    z-index: -1;
    /* 2 */
    height: 30px;
    border-radius: 100%;
    background: #444;
    filter: blur(20px);
    /* 3 */
    will-change: filter; } }

/* Drop cap
 ========================================================================== */
/*
 * 1. Firefox doesn't apply `::first-letter` if the first letter is inside child elements
 *    https://bugzilla.mozilla.org/show_bug.cgi?id=214004
 * 2. In Firefox, a floating `::first-letter` doesn't have a line box and there for no `line-height`
 *    https://bugzilla.mozilla.org/show_bug.cgi?id=317933
 */
.uk-dropcap::first-letter,
.uk-dropcap > p:first-of-type::first-letter {
  display: block;
  margin-right: 10px;
  float: left;
  font-size: 4.5em;
  line-height: 1; }

/* 2 */
@-moz-document url-prefix() {
  .uk-dropcap::first-letter,
  .uk-dropcap > p:first-of-type::first-letter {
    margin-top: 1.1%; } }

/* Logo
 ========================================================================== */
/*
 * 1. Style
 * 2. Required for `a`
 * 3. Behave like image but can be overridden through flex utility classes
 */
.uk-logo {
  /* 1 */
  font-size: 1.5rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #333;
  /* 2 */
  text-decoration: none; }

/* 3 */
:where(.uk-logo) {
  display: inline-block;
  vertical-align: middle; }

/* Hover */
.uk-logo:hover {
  color: #333;
  /* 1 */
  text-decoration: none; }

.uk-logo :where(img, svg, video) {
  display: block; }

.uk-logo-inverse {
  display: none; }

/* Disabled State
 ========================================================================== */
.uk-disabled {
  pointer-events: none; }

/* Drag State
 ========================================================================== */
/*
 * 1. Needed if moving over elements with have their own cursor on hover, e.g. links or buttons
 * 2. Fix dragging over iframes
 */
.uk-drag,
.uk-drag * {
  cursor: move; }

/* 2 */
.uk-drag iframe {
  pointer-events: none; }

/* Dragover State
 ========================================================================== */
/*
 * Create a box-shadow when dragging a file over the upload area
 */
.uk-dragover {
  box-shadow: 0 0 20px rgba(100, 100, 100, 0.3); }

/* Blend modes
 ========================================================================== */
.uk-blend-multiply {
  mix-blend-mode: multiply; }

.uk-blend-screen {
  mix-blend-mode: screen; }

.uk-blend-overlay {
  mix-blend-mode: overlay; }

.uk-blend-darken {
  mix-blend-mode: darken; }

.uk-blend-lighten {
  mix-blend-mode: lighten; }

.uk-blend-color-dodge {
  mix-blend-mode: color-dodge; }

.uk-blend-color-burn {
  mix-blend-mode: color-burn; }

.uk-blend-hard-light {
  mix-blend-mode: hard-light; }

.uk-blend-soft-light {
  mix-blend-mode: soft-light; }

.uk-blend-difference {
  mix-blend-mode: difference; }

.uk-blend-exclusion {
  mix-blend-mode: exclusion; }

.uk-blend-hue {
  mix-blend-mode: hue; }

.uk-blend-saturation {
  mix-blend-mode: saturation; }

.uk-blend-color {
  mix-blend-mode: color; }

.uk-blend-luminosity {
  mix-blend-mode: luminosity; }

/* Transform
========================================================================== */
.uk-transform-center {
  transform: translate(-50%, -50%); }

/* Transform Origin
========================================================================== */
.uk-transform-origin-top-left {
  transform-origin: 0 0; }

.uk-transform-origin-top-center {
  transform-origin: 50% 0; }

.uk-transform-origin-top-right {
  transform-origin: 100% 0; }

.uk-transform-origin-center-left {
  transform-origin: 0 50%; }

.uk-transform-origin-center-right {
  transform-origin: 100% 50%; }

.uk-transform-origin-bottom-left {
  transform-origin: 0 100%; }

.uk-transform-origin-bottom-center {
  transform-origin: 50% 100%; }

.uk-transform-origin-bottom-right {
  transform-origin: 100% 100%; }

/* ========================================================================
   Component: Flex
 ========================================================================== */
.uk-flex {
  display: flex; }

.uk-flex-inline {
  display: inline-flex; }

/* Alignment
 ========================================================================== */
/*
 * Align items along the main axis of the current line of the flex container
 * Row: Horizontal
 */
.uk-flex-left {
  justify-content: flex-start; }

.uk-flex-center {
  justify-content: center; }

.uk-flex-right {
  justify-content: flex-end; }

.uk-flex-between {
  justify-content: space-between; }

.uk-flex-around {
  justify-content: space-around; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-flex-left\@s {
    justify-content: flex-start; }
  .uk-flex-center\@s {
    justify-content: center; }
  .uk-flex-right\@s {
    justify-content: flex-end; }
  .uk-flex-between\@s {
    justify-content: space-between; }
  .uk-flex-around\@s {
    justify-content: space-around; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-flex-left\@m {
    justify-content: flex-start; }
  .uk-flex-center\@m {
    justify-content: center; }
  .uk-flex-right\@m {
    justify-content: flex-end; }
  .uk-flex-between\@m {
    justify-content: space-between; }
  .uk-flex-around\@m {
    justify-content: space-around; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-flex-left\@l {
    justify-content: flex-start; }
  .uk-flex-center\@l {
    justify-content: center; }
  .uk-flex-right\@l {
    justify-content: flex-end; }
  .uk-flex-between\@l {
    justify-content: space-between; }
  .uk-flex-around\@l {
    justify-content: space-around; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-flex-left\@xl {
    justify-content: flex-start; }
  .uk-flex-center\@xl {
    justify-content: center; }
  .uk-flex-right\@xl {
    justify-content: flex-end; }
  .uk-flex-between\@xl {
    justify-content: space-between; }
  .uk-flex-around\@xl {
    justify-content: space-around; } }

/*
 * Align items in the cross axis of the current line of the flex container
 * Row: Vertical
 */
.uk-flex-stretch {
  align-items: stretch; }

.uk-flex-top {
  align-items: flex-start; }

.uk-flex-middle {
  align-items: center; }

.uk-flex-bottom {
  align-items: flex-end; }

/* Direction
 ========================================================================== */
.uk-flex-row {
  flex-direction: row; }

.uk-flex-row-reverse {
  flex-direction: row-reverse; }

.uk-flex-column {
  flex-direction: column; }

.uk-flex-column-reverse {
  flex-direction: column-reverse; }

/* Wrap
 ========================================================================== */
.uk-flex-nowrap {
  flex-wrap: nowrap; }

.uk-flex-wrap {
  flex-wrap: wrap; }

.uk-flex-wrap-reverse {
  flex-wrap: wrap-reverse; }

/*
 * Aligns items within the flex container when there is extra space in the cross-axis
 * Only works if there is more than one line of flex items
 */
.uk-flex-wrap-stretch {
  align-content: stretch; }

.uk-flex-wrap-top {
  align-content: flex-start; }

.uk-flex-wrap-middle {
  align-content: center; }

.uk-flex-wrap-bottom {
  align-content: flex-end; }

.uk-flex-wrap-between {
  align-content: space-between; }

.uk-flex-wrap-around {
  align-content: space-around; }

/* Item ordering
 ========================================================================== */
/*
 * Default is 0
 */
.uk-flex-first {
  order: -1; }

.uk-flex-last {
  order: 99; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-flex-first\@s {
    order: -1; }
  .uk-flex-last\@s {
    order: 99; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-flex-first\@m {
    order: -1; }
  .uk-flex-last\@m {
    order: 99; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-flex-first\@l {
    order: -1; }
  .uk-flex-last\@l {
    order: 99; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-flex-first\@xl {
    order: -1; }
  .uk-flex-last\@xl {
    order: 99; } }

/* Item dimensions
 ========================================================================== */
/*
 * Initial: 0 1 auto
 * Content dimensions, but shrinks
 */
/*
 * No Flex: 0 0 auto
 * Content dimensions
 */
.uk-flex-none {
  flex: none; }

/*
 * Relative Flex: 1 1 auto
 * Space is allocated considering content
 */
.uk-flex-auto {
  flex: auto; }

/*
 * Absolute Flex: 1 1 0%
 * Space is allocated solely based on flex
 */
.uk-flex-1 {
  flex: 1; }

/* ========================================================================
   Component: Margin
 ========================================================================== */
/*
 * Default
 */
.uk-margin {
  margin-bottom: 20px; }

* + .uk-margin {
  margin-top: 20px !important; }

.uk-margin-top {
  margin-top: 20px !important; }

.uk-margin-bottom {
  margin-bottom: 20px !important; }

.uk-margin-left {
  margin-left: 20px !important; }

.uk-margin-right {
  margin-right: 20px !important; }

/* Small
 ========================================================================== */
.uk-margin-small {
  margin-bottom: 10px; }

* + .uk-margin-small {
  margin-top: 10px !important; }

.uk-margin-small-top {
  margin-top: 10px !important; }

.uk-margin-small-bottom {
  margin-bottom: 10px !important; }

.uk-margin-small-left {
  margin-left: 10px !important; }

.uk-margin-small-right {
  margin-right: 10px !important; }

/* Medium
 ========================================================================== */
.uk-margin-medium {
  margin-bottom: 40px; }

* + .uk-margin-medium {
  margin-top: 40px !important; }

.uk-margin-medium-top {
  margin-top: 40px !important; }

.uk-margin-medium-bottom {
  margin-bottom: 40px !important; }

.uk-margin-medium-left {
  margin-left: 40px !important; }

.uk-margin-medium-right {
  margin-right: 40px !important; }

/* Large
 ========================================================================== */
.uk-margin-large {
  margin-bottom: 40px; }

* + .uk-margin-large {
  margin-top: 40px !important; }

.uk-margin-large-top {
  margin-top: 40px !important; }

.uk-margin-large-bottom {
  margin-bottom: 40px !important; }

.uk-margin-large-left {
  margin-left: 40px !important; }

.uk-margin-large-right {
  margin-right: 40px !important; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-margin-large {
    margin-bottom: 70px; }
  * + .uk-margin-large {
    margin-top: 70px !important; }
  .uk-margin-large-top {
    margin-top: 70px !important; }
  .uk-margin-large-bottom {
    margin-bottom: 70px !important; }
  .uk-margin-large-left {
    margin-left: 70px !important; }
  .uk-margin-large-right {
    margin-right: 70px !important; } }

/* XLarge
 ========================================================================== */
.uk-margin-xlarge {
  margin-bottom: 70px; }

* + .uk-margin-xlarge {
  margin-top: 70px !important; }

.uk-margin-xlarge-top {
  margin-top: 70px !important; }

.uk-margin-xlarge-bottom {
  margin-bottom: 70px !important; }

.uk-margin-xlarge-left {
  margin-left: 70px !important; }

.uk-margin-xlarge-right {
  margin-right: 70px !important; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-margin-xlarge {
    margin-bottom: 140px; }
  * + .uk-margin-xlarge {
    margin-top: 140px !important; }
  .uk-margin-xlarge-top {
    margin-top: 140px !important; }
  .uk-margin-xlarge-bottom {
    margin-bottom: 140px !important; }
  .uk-margin-xlarge-left {
    margin-left: 140px !important; }
  .uk-margin-xlarge-right {
    margin-right: 140px !important; } }

/* Auto
 ========================================================================== */
.uk-margin-auto {
  margin-left: auto !important;
  margin-right: auto !important; }

.uk-margin-auto-top {
  margin-top: auto !important; }

.uk-margin-auto-bottom {
  margin-bottom: auto !important; }

.uk-margin-auto-left {
  margin-left: auto !important; }

.uk-margin-auto-right {
  margin-right: auto !important; }

.uk-margin-auto-vertical {
  margin-top: auto !important;
  margin-bottom: auto !important; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-margin-auto\@s {
    margin-left: auto !important;
    margin-right: auto !important; }
  .uk-margin-auto-left\@s {
    margin-left: auto !important; }
  .uk-margin-auto-right\@s {
    margin-right: auto !important; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-margin-auto\@m {
    margin-left: auto !important;
    margin-right: auto !important; }
  .uk-margin-auto-left\@m {
    margin-left: auto !important; }
  .uk-margin-auto-right\@m {
    margin-right: auto !important; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-margin-auto\@l {
    margin-left: auto !important;
    margin-right: auto !important; }
  .uk-margin-auto-left\@l {
    margin-left: auto !important; }
  .uk-margin-auto-right\@l {
    margin-right: auto !important; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-margin-auto\@xl {
    margin-left: auto !important;
    margin-right: auto !important; }
  .uk-margin-auto-left\@xl {
    margin-left: auto !important; }
  .uk-margin-auto-right\@xl {
    margin-right: auto !important; } }

/* Remove
 ========================================================================== */
.uk-margin-remove {
  margin: 0 !important; }

.uk-margin-remove-top {
  margin-top: 0 !important; }

.uk-margin-remove-bottom {
  margin-bottom: 0 !important; }

.uk-margin-remove-left {
  margin-left: 0 !important; }

.uk-margin-remove-right {
  margin-right: 0 !important; }

.uk-margin-remove-vertical {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.uk-margin-remove-adjacent + *,
.uk-margin-remove-first-child > :first-child {
  margin-top: 0 !important; }

.uk-margin-remove-last-child > :last-child {
  margin-bottom: 0 !important; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-margin-remove-left\@s {
    margin-left: 0 !important; }
  .uk-margin-remove-right\@s {
    margin-right: 0 !important; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-margin-remove-left\@m {
    margin-left: 0 !important; }
  .uk-margin-remove-right\@m {
    margin-right: 0 !important; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-margin-remove-left\@l {
    margin-left: 0 !important; }
  .uk-margin-remove-right\@l {
    margin-right: 0 !important; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-margin-remove-left\@xl {
    margin-left: 0 !important; }
  .uk-margin-remove-right\@xl {
    margin-right: 0 !important; } }

/* ========================================================================
   Component: Padding
 ========================================================================== */
.uk-padding {
  padding: 30px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-padding {
    padding: 40px; } }

/* Small
 ========================================================================== */
.uk-padding-small {
  padding: 15px; }

/* Large
 ========================================================================== */
.uk-padding-large {
  padding: 40px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-padding-large {
    padding: 70px; } }

/* Remove
 ========================================================================== */
.uk-padding-remove {
  padding: 0 !important; }

.uk-padding-remove-top {
  padding-top: 0 !important; }

.uk-padding-remove-bottom {
  padding-bottom: 0 !important; }

.uk-padding-remove-left {
  padding-left: 0 !important; }

.uk-padding-remove-right {
  padding-right: 0 !important; }

.uk-padding-remove-vertical {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.uk-padding-remove-horizontal {
  padding-left: 0 !important;
  padding-right: 0 !important; }

/* ========================================================================
   Component: Position
 ========================================================================== */
:root {
  --uk-position-margin-offset: 0px; }

/* Directions
 ========================================================================== */
/*
 * 1. Prevent content overflow.
 */
[class*='uk-position-top'],
[class*='uk-position-bottom'],
[class*='uk-position-left'],
[class*='uk-position-right'],
[class*='uk-position-center'] {
  position: absolute !important;
  /* 1 */
  max-width: calc(100% - (var(--uk-position-margin-offset) * 2));
  box-sizing: border-box; }

/*
 * Edges
 * Don't use `width: 100%` because it's wrong if the parent has padding.
 */
.uk-position-top {
  top: 0;
  left: 0;
  right: 0; }

.uk-position-bottom {
  bottom: 0;
  left: 0;
  right: 0; }

.uk-position-left {
  top: 0;
  bottom: 0;
  left: 0; }

.uk-position-right {
  top: 0;
  bottom: 0;
  right: 0; }

/*
 * Corners
 */
.uk-position-top-left {
  top: 0;
  left: 0; }

.uk-position-top-right {
  top: 0;
  right: 0; }

.uk-position-bottom-left {
  bottom: 0;
  left: 0; }

.uk-position-bottom-right {
  bottom: 0;
  right: 0; }

/*
 * Center
 * 1. Fix text wrapping if content is larger than 50% of the container.
 *    Using `max-content` requires `max-width` of 100% which is set generally.
 */
.uk-position-center {
  top: calc(50% - var(--uk-position-margin-offset));
  left: calc(50% - var(--uk-position-margin-offset));
  --uk-position-translate-x: -50%;
  --uk-position-translate-y: -50%;
  transform: translate(var(--uk-position-translate-x), var(--uk-position-translate-y));
  /* 1 */
  width: max-content; }

/* Vertical */
[class*='uk-position-center-left'],
[class*='uk-position-center-right'] {
  top: calc(50% - var(--uk-position-margin-offset));
  --uk-position-translate-y: -50%;
  transform: translate(0, var(--uk-position-translate-y)); }

.uk-position-center-left {
  left: 0; }

.uk-position-center-right {
  right: 0; }

.uk-position-center-left-out {
  right: 100%;
  width: max-content; }

.uk-position-center-right-out {
  left: 100%;
  width: max-content; }

/* Horizontal */
.uk-position-top-center,
.uk-position-bottom-center {
  left: calc(50% - var(--uk-position-margin-offset));
  --uk-position-translate-x: -50%;
  transform: translate(var(--uk-position-translate-x), 0);
  /* 1 */
  width: max-content; }

.uk-position-top-center {
  top: 0; }

.uk-position-bottom-center {
  bottom: 0; }

/*
 * Cover
 */
.uk-position-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

/* Margin
 ========================================================================== */
.uk-position-small {
  margin: 15px;
  --uk-position-margin-offset: 15px; }

.uk-position-medium {
  margin: 30px;
  --uk-position-margin-offset: 30px; }

.uk-position-large {
  margin: 30px;
  --uk-position-margin-offset: 30px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-position-large {
    margin: 50px;
    --uk-position-margin-offset: 50px; } }

/* Schemes
 ========================================================================== */
.uk-position-relative {
  position: relative  !important; }

.uk-position-absolute {
  position: absolute  !important; }

.uk-position-fixed {
  position: fixed  !important; }

.uk-position-sticky {
  position: sticky  !important; }

/* Layer
 ========================================================================== */
.uk-position-z-index {
  z-index: 1; }

.uk-position-z-index-zero {
  z-index: 0; }

.uk-position-z-index-negative {
  z-index: -1; }

/* ========================================================================
   Component: Transition
 ========================================================================== */
/* Transitions
 ========================================================================== */
/*
 * The toggle is triggered on touch devices by two methods:
 * 1. Using `:focus` and tabindex
 * 2. Using `:hover` and a `touchstart` event listener registered on the document
 *    (Doesn't work on Surface touch devices)
 */
:where(.uk-transition-fade),
:where([class*='uk-transition-scale']),
:where([class*='uk-transition-slide']) {
  --uk-position-translate-x: 0;
  --uk-position-translate-y: 0; }

.uk-transition-fade,
[class*='uk-transition-scale'],
[class*='uk-transition-slide'] {
  --uk-translate-x: 0;
  --uk-translate-y: 0;
  --uk-scale-x: 1;
  --uk-scale-y: 1;
  transform: translate(var(--uk-position-translate-x), var(--uk-position-translate-y)) translate(var(--uk-translate-x), var(--uk-translate-y)) scale(var(--uk-scale-x), var(--uk-scale-y));
  transition: 0.3s ease-out;
  transition-property: opacity, transform, filter;
  opacity: 0; }

/*
 * Fade
 */
.uk-transition-toggle:hover .uk-transition-fade,
.uk-transition-toggle:focus .uk-transition-fade,
.uk-transition-toggle .uk-transition-fade:focus-within,
.uk-transition-active.uk-active .uk-transition-fade {
  opacity: 1; }

/*
 * Scale
 * 1. Make image rendering the same during the transition as before and after. Prefixed because of Safari.
 */
/* 1 */
[class*='uk-transition-scale'] {
  -webkit-backface-visibility: hidden; }

.uk-transition-scale-up {
  --uk-scale-x: 1;
  --uk-scale-y: 1; }

.uk-transition-scale-down {
  --uk-scale-x: 1.03;
  --uk-scale-y: 1.03; }

/* Show */
.uk-transition-toggle:hover .uk-transition-scale-up,
.uk-transition-toggle:focus .uk-transition-scale-up,
.uk-transition-toggle .uk-transition-scale-up:focus-within,
.uk-transition-active.uk-active .uk-transition-scale-up {
  --uk-scale-x: 1.03;
  --uk-scale-y: 1.03;
  opacity: 1; }

.uk-transition-toggle:hover .uk-transition-scale-down,
.uk-transition-toggle:focus .uk-transition-scale-down,
.uk-transition-toggle .uk-transition-scale-down:focus-within,
.uk-transition-active.uk-active .uk-transition-scale-down {
  --uk-scale-x: 1;
  --uk-scale-y: 1;
  opacity: 1; }

/*
 * Slide
 */
.uk-transition-slide-top {
  --uk-translate-y: -100%; }

.uk-transition-slide-bottom {
  --uk-translate-y: 100%; }

.uk-transition-slide-left {
  --uk-translate-x: -100%; }

.uk-transition-slide-right {
  --uk-translate-x: 100%; }

.uk-transition-slide-top-small {
  --uk-translate-y: calc(-1 * 10px); }

.uk-transition-slide-bottom-small {
  --uk-translate-y: 10px; }

.uk-transition-slide-left-small {
  --uk-translate-x: calc(-1 * 10px); }

.uk-transition-slide-right-small {
  --uk-translate-x: 10px; }

.uk-transition-slide-top-medium {
  --uk-translate-y: calc(-1 * 50px); }

.uk-transition-slide-bottom-medium {
  --uk-translate-y: 50px; }

.uk-transition-slide-left-medium {
  --uk-translate-x: calc(-1 * 50px); }

.uk-transition-slide-right-medium {
  --uk-translate-x: 50px; }

/* Show */
.uk-transition-toggle:hover [class*='uk-transition-slide'],
.uk-transition-toggle:focus [class*='uk-transition-slide'],
.uk-transition-toggle [class*='uk-transition-slide']:focus-within,
.uk-transition-active.uk-active [class*='uk-transition-slide'] {
  --uk-translate-x: 0;
  --uk-translate-y: 0;
  opacity: 1; }

/* Opacity modifier
 ========================================================================== */
.uk-transition-opaque {
  opacity: 1; }

/* Duration modifiers
 ========================================================================== */
.uk-transition-slow {
  transition-duration: 0.7s; }

/* ========================================================================
   Component: Visibility
 ========================================================================== */
/*
 * Hidden
 * `hidden` attribute also set here to make it stronger
 */
[hidden],
.uk-hidden {
  display: none !important; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-hidden\@s {
    display: none !important; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-hidden\@m {
    display: none !important; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-hidden\@l {
    display: none !important; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-hidden\@xl {
    display: none !important; } }

/*
 * Visible
 */
/* Phone portrait and smaller */
@media (max-width: 639px) {
  .uk-visible\@s {
    display: none !important; } }

/* Phone landscape and smaller */
@media (max-width: 959px) {
  .uk-visible\@m {
    display: none !important; } }

/* Tablet landscape and smaller */
@media (max-width: 1199px) {
  .uk-visible\@l {
    display: none !important; } }

/* Desktop and smaller */
@media (max-width: 1599px) {
  .uk-visible\@xl {
    display: none !important; } }

/* Visibility
 ========================================================================== */
.uk-invisible {
  visibility: hidden !important; }

/* Based on the State of the Parent Element
 ========================================================================== */
/*
 * Can't use `display: none` nor `visibility: hidden` because both are not focusable.
 * The target stays visible if any element within receives focus through keyboard.
 */
/*
 * Discard space when hidden.
 */
.uk-visible-toggle:not(:hover):not(:focus) .uk-hidden-hover:not(:focus-within) {
  position: absolute !important;
  width: 0 !important;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden !important; }

/*
 * Keep space when hidden.
 */
.uk-visible-toggle:not(:hover):not(:focus) .uk-invisible-hover:not(:focus-within) {
  opacity: 0 !important; }

/* Based on Hover Capability of the Pointing Device
 ========================================================================== */
/*
 * Hover
 */
/* Hide if primary pointing device doesn't support hover, e.g. touch screens. */
@media (hover: none) {
  .uk-hidden-touch {
    display: none !important; } }

/* Hide if primary pointing device supports hover, e.g. mice. */
@media (hover) {
  .uk-hidden-notouch {
    display: none !important; } }

/* ========================================================================
   Component: Inverse
 ========================================================================== */
/*
 * Implemented class depends on the general theme color
 * `uk-light` is for light colors on dark backgrounds
 * `uk-dark` is or dark colors on light backgrounds
 */
.uk-light, .uk-section-primary:not(.uk-preserve-color), .uk-section-secondary:not(.uk-preserve-color), .uk-tile-primary:not(.uk-preserve-color), .uk-tile-secondary:not(.uk-preserve-color), .uk-card-primary.uk-card-body, .uk-card-primary > :not([class*='uk-card-media']), .uk-card-secondary.uk-card-body, .uk-card-secondary > :not([class*='uk-card-media']), .uk-overlay-primary, .uk-offcanvas-bar {
  color: rgba(255, 255, 255, 0.7); }
  .uk-light a, .uk-section-primary:not(.uk-preserve-color) a, .uk-section-secondary:not(.uk-preserve-color) a, .uk-tile-primary:not(.uk-preserve-color) a, .uk-tile-secondary:not(.uk-preserve-color) a, .uk-card-primary.uk-card-body a, .uk-card-primary > :not([class*='uk-card-media']) a, .uk-card-secondary.uk-card-body a, .uk-card-secondary > :not([class*='uk-card-media']) a, .uk-overlay-primary a, .uk-offcanvas-bar a,
  .uk-light .uk-link,
  .uk-section-primary:not(.uk-preserve-color) .uk-link,
  .uk-section-secondary:not(.uk-preserve-color) .uk-link,
  .uk-tile-primary:not(.uk-preserve-color) .uk-link,
  .uk-tile-secondary:not(.uk-preserve-color) .uk-link,
  .uk-card-primary.uk-card-body .uk-link,
  .uk-card-primary > :not([class*='uk-card-media']) .uk-link,
  .uk-card-secondary.uk-card-body .uk-link,
  .uk-card-secondary > :not([class*='uk-card-media']) .uk-link,
  .uk-overlay-primary .uk-link,
  .uk-offcanvas-bar .uk-link {
    color: #fff; }
  .uk-light a:hover, .uk-section-primary:not(.uk-preserve-color) a:hover, .uk-section-secondary:not(.uk-preserve-color) a:hover, .uk-tile-primary:not(.uk-preserve-color) a:hover, .uk-tile-secondary:not(.uk-preserve-color) a:hover, .uk-card-primary.uk-card-body a:hover, .uk-card-primary > :not([class*='uk-card-media']) a:hover, .uk-card-secondary.uk-card-body a:hover, .uk-card-secondary > :not([class*='uk-card-media']) a:hover, .uk-overlay-primary a:hover, .uk-offcanvas-bar a:hover,
  .uk-light .uk-link:hover,
  .uk-section-primary:not(.uk-preserve-color) .uk-link:hover,
  .uk-section-secondary:not(.uk-preserve-color) .uk-link:hover,
  .uk-tile-primary:not(.uk-preserve-color) .uk-link:hover,
  .uk-tile-secondary:not(.uk-preserve-color) .uk-link:hover,
  .uk-card-primary.uk-card-body .uk-link:hover,
  .uk-card-primary > :not([class*='uk-card-media']) .uk-link:hover,
  .uk-card-secondary.uk-card-body .uk-link:hover,
  .uk-card-secondary > :not([class*='uk-card-media']) .uk-link:hover,
  .uk-overlay-primary .uk-link:hover,
  .uk-offcanvas-bar .uk-link:hover,
  .uk-light .uk-link-toggle:hover .uk-link,
  .uk-section-primary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link,
  .uk-section-secondary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link,
  .uk-tile-primary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link,
  .uk-tile-secondary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link,
  .uk-card-primary.uk-card-body .uk-link-toggle:hover .uk-link,
  .uk-card-primary > :not([class*='uk-card-media']) .uk-link-toggle:hover .uk-link,
  .uk-card-secondary.uk-card-body .uk-link-toggle:hover .uk-link,
  .uk-card-secondary > :not([class*='uk-card-media']) .uk-link-toggle:hover .uk-link,
  .uk-overlay-primary .uk-link-toggle:hover .uk-link,
  .uk-offcanvas-bar .uk-link-toggle:hover .uk-link {
    color: #fff; }
  .uk-light :not(pre) > code, .uk-section-primary:not(.uk-preserve-color) :not(pre) > code, .uk-section-secondary:not(.uk-preserve-color) :not(pre) > code, .uk-tile-primary:not(.uk-preserve-color) :not(pre) > code, .uk-tile-secondary:not(.uk-preserve-color) :not(pre) > code, .uk-card-primary.uk-card-body :not(pre) > code, .uk-card-primary > :not([class*='uk-card-media']) :not(pre) > code, .uk-card-secondary.uk-card-body :not(pre) > code, .uk-card-secondary > :not([class*='uk-card-media']) :not(pre) > code, .uk-overlay-primary :not(pre) > code, .uk-offcanvas-bar :not(pre) > code,
  .uk-light :not(pre) > kbd,
  .uk-section-primary:not(.uk-preserve-color) :not(pre) > kbd,
  .uk-section-secondary:not(.uk-preserve-color) :not(pre) > kbd,
  .uk-tile-primary:not(.uk-preserve-color) :not(pre) > kbd,
  .uk-tile-secondary:not(.uk-preserve-color) :not(pre) > kbd,
  .uk-card-primary.uk-card-body :not(pre) > kbd,
  .uk-card-primary > :not([class*='uk-card-media']) :not(pre) > kbd,
  .uk-card-secondary.uk-card-body :not(pre) > kbd,
  .uk-card-secondary > :not([class*='uk-card-media']) :not(pre) > kbd,
  .uk-overlay-primary :not(pre) > kbd,
  .uk-offcanvas-bar :not(pre) > kbd,
  .uk-light :not(pre) > samp,
  .uk-section-primary:not(.uk-preserve-color) :not(pre) > samp,
  .uk-section-secondary:not(.uk-preserve-color) :not(pre) > samp,
  .uk-tile-primary:not(.uk-preserve-color) :not(pre) > samp,
  .uk-tile-secondary:not(.uk-preserve-color) :not(pre) > samp,
  .uk-card-primary.uk-card-body :not(pre) > samp,
  .uk-card-primary > :not([class*='uk-card-media']) :not(pre) > samp,
  .uk-card-secondary.uk-card-body :not(pre) > samp,
  .uk-card-secondary > :not([class*='uk-card-media']) :not(pre) > samp,
  .uk-overlay-primary :not(pre) > samp,
  .uk-offcanvas-bar :not(pre) > samp {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light em, .uk-section-primary:not(.uk-preserve-color) em, .uk-section-secondary:not(.uk-preserve-color) em, .uk-tile-primary:not(.uk-preserve-color) em, .uk-tile-secondary:not(.uk-preserve-color) em, .uk-card-primary.uk-card-body em, .uk-card-primary > :not([class*='uk-card-media']) em, .uk-card-secondary.uk-card-body em, .uk-card-secondary > :not([class*='uk-card-media']) em, .uk-overlay-primary em, .uk-offcanvas-bar em {
    color: #fff; }
  .uk-light h1, .uk-section-primary:not(.uk-preserve-color) h1, .uk-section-secondary:not(.uk-preserve-color) h1, .uk-tile-primary:not(.uk-preserve-color) h1, .uk-tile-secondary:not(.uk-preserve-color) h1, .uk-card-primary.uk-card-body h1, .uk-card-primary > :not([class*='uk-card-media']) h1, .uk-card-secondary.uk-card-body h1, .uk-card-secondary > :not([class*='uk-card-media']) h1, .uk-overlay-primary h1, .uk-offcanvas-bar h1, .uk-light .uk-h1, .uk-section-primary:not(.uk-preserve-color) .uk-h1, .uk-section-secondary:not(.uk-preserve-color) .uk-h1, .uk-tile-primary:not(.uk-preserve-color) .uk-h1, .uk-tile-secondary:not(.uk-preserve-color) .uk-h1, .uk-card-primary.uk-card-body .uk-h1, .uk-card-primary > :not([class*='uk-card-media']) .uk-h1, .uk-card-secondary.uk-card-body .uk-h1, .uk-card-secondary > :not([class*='uk-card-media']) .uk-h1, .uk-overlay-primary .uk-h1, .uk-offcanvas-bar .uk-h1,
  .uk-light h2,
  .uk-section-primary:not(.uk-preserve-color) h2,
  .uk-section-secondary:not(.uk-preserve-color) h2,
  .uk-tile-primary:not(.uk-preserve-color) h2,
  .uk-tile-secondary:not(.uk-preserve-color) h2,
  .uk-card-primary.uk-card-body h2,
  .uk-card-primary > :not([class*='uk-card-media']) h2,
  .uk-card-secondary.uk-card-body h2,
  .uk-card-secondary > :not([class*='uk-card-media']) h2,
  .uk-overlay-primary h2,
  .uk-offcanvas-bar h2, .uk-light .uk-h2, .uk-section-primary:not(.uk-preserve-color) .uk-h2, .uk-section-secondary:not(.uk-preserve-color) .uk-h2, .uk-tile-primary:not(.uk-preserve-color) .uk-h2, .uk-tile-secondary:not(.uk-preserve-color) .uk-h2, .uk-card-primary.uk-card-body .uk-h2, .uk-card-primary > :not([class*='uk-card-media']) .uk-h2, .uk-card-secondary.uk-card-body .uk-h2, .uk-card-secondary > :not([class*='uk-card-media']) .uk-h2, .uk-overlay-primary .uk-h2, .uk-offcanvas-bar .uk-h2,
  .uk-light h3,
  .uk-section-primary:not(.uk-preserve-color) h3,
  .uk-section-secondary:not(.uk-preserve-color) h3,
  .uk-tile-primary:not(.uk-preserve-color) h3,
  .uk-tile-secondary:not(.uk-preserve-color) h3,
  .uk-card-primary.uk-card-body h3,
  .uk-card-primary > :not([class*='uk-card-media']) h3,
  .uk-card-secondary.uk-card-body h3,
  .uk-card-secondary > :not([class*='uk-card-media']) h3,
  .uk-overlay-primary h3,
  .uk-offcanvas-bar h3, .uk-light .uk-h3, .uk-section-primary:not(.uk-preserve-color) .uk-h3, .uk-section-secondary:not(.uk-preserve-color) .uk-h3, .uk-tile-primary:not(.uk-preserve-color) .uk-h3, .uk-tile-secondary:not(.uk-preserve-color) .uk-h3, .uk-card-primary.uk-card-body .uk-h3, .uk-card-primary > :not([class*='uk-card-media']) .uk-h3, .uk-card-secondary.uk-card-body .uk-h3, .uk-card-secondary > :not([class*='uk-card-media']) .uk-h3, .uk-overlay-primary .uk-h3, .uk-offcanvas-bar .uk-h3,
  .uk-light h4,
  .uk-section-primary:not(.uk-preserve-color) h4,
  .uk-section-secondary:not(.uk-preserve-color) h4,
  .uk-tile-primary:not(.uk-preserve-color) h4,
  .uk-tile-secondary:not(.uk-preserve-color) h4,
  .uk-card-primary.uk-card-body h4,
  .uk-card-primary > :not([class*='uk-card-media']) h4,
  .uk-card-secondary.uk-card-body h4,
  .uk-card-secondary > :not([class*='uk-card-media']) h4,
  .uk-overlay-primary h4,
  .uk-offcanvas-bar h4, .uk-light .uk-h4, .uk-section-primary:not(.uk-preserve-color) .uk-h4, .uk-section-secondary:not(.uk-preserve-color) .uk-h4, .uk-tile-primary:not(.uk-preserve-color) .uk-h4, .uk-tile-secondary:not(.uk-preserve-color) .uk-h4, .uk-card-primary.uk-card-body .uk-h4, .uk-card-primary > :not([class*='uk-card-media']) .uk-h4, .uk-card-secondary.uk-card-body .uk-h4, .uk-card-secondary > :not([class*='uk-card-media']) .uk-h4, .uk-overlay-primary .uk-h4, .uk-offcanvas-bar .uk-h4,
  .uk-light h5,
  .uk-section-primary:not(.uk-preserve-color) h5,
  .uk-section-secondary:not(.uk-preserve-color) h5,
  .uk-tile-primary:not(.uk-preserve-color) h5,
  .uk-tile-secondary:not(.uk-preserve-color) h5,
  .uk-card-primary.uk-card-body h5,
  .uk-card-primary > :not([class*='uk-card-media']) h5,
  .uk-card-secondary.uk-card-body h5,
  .uk-card-secondary > :not([class*='uk-card-media']) h5,
  .uk-overlay-primary h5,
  .uk-offcanvas-bar h5, .uk-light .uk-h5, .uk-section-primary:not(.uk-preserve-color) .uk-h5, .uk-section-secondary:not(.uk-preserve-color) .uk-h5, .uk-tile-primary:not(.uk-preserve-color) .uk-h5, .uk-tile-secondary:not(.uk-preserve-color) .uk-h5, .uk-card-primary.uk-card-body .uk-h5, .uk-card-primary > :not([class*='uk-card-media']) .uk-h5, .uk-card-secondary.uk-card-body .uk-h5, .uk-card-secondary > :not([class*='uk-card-media']) .uk-h5, .uk-overlay-primary .uk-h5, .uk-offcanvas-bar .uk-h5,
  .uk-light h6,
  .uk-section-primary:not(.uk-preserve-color) h6,
  .uk-section-secondary:not(.uk-preserve-color) h6,
  .uk-tile-primary:not(.uk-preserve-color) h6,
  .uk-tile-secondary:not(.uk-preserve-color) h6,
  .uk-card-primary.uk-card-body h6,
  .uk-card-primary > :not([class*='uk-card-media']) h6,
  .uk-card-secondary.uk-card-body h6,
  .uk-card-secondary > :not([class*='uk-card-media']) h6,
  .uk-overlay-primary h6,
  .uk-offcanvas-bar h6, .uk-light .uk-h6, .uk-section-primary:not(.uk-preserve-color) .uk-h6, .uk-section-secondary:not(.uk-preserve-color) .uk-h6, .uk-tile-primary:not(.uk-preserve-color) .uk-h6, .uk-tile-secondary:not(.uk-preserve-color) .uk-h6, .uk-card-primary.uk-card-body .uk-h6, .uk-card-primary > :not([class*='uk-card-media']) .uk-h6, .uk-card-secondary.uk-card-body .uk-h6, .uk-card-secondary > :not([class*='uk-card-media']) .uk-h6, .uk-overlay-primary .uk-h6, .uk-offcanvas-bar .uk-h6,
  .uk-light .uk-heading-small,
  .uk-section-primary:not(.uk-preserve-color) .uk-heading-small,
  .uk-section-secondary:not(.uk-preserve-color) .uk-heading-small,
  .uk-tile-primary:not(.uk-preserve-color) .uk-heading-small,
  .uk-tile-secondary:not(.uk-preserve-color) .uk-heading-small,
  .uk-card-primary.uk-card-body .uk-heading-small,
  .uk-card-primary > :not([class*='uk-card-media']) .uk-heading-small,
  .uk-card-secondary.uk-card-body .uk-heading-small,
  .uk-card-secondary > :not([class*='uk-card-media']) .uk-heading-small,
  .uk-overlay-primary .uk-heading-small,
  .uk-offcanvas-bar .uk-heading-small,
  .uk-light .uk-heading-medium,
  .uk-section-primary:not(.uk-preserve-color) .uk-heading-medium,
  .uk-section-secondary:not(.uk-preserve-color) .uk-heading-medium,
  .uk-tile-primary:not(.uk-preserve-color) .uk-heading-medium,
  .uk-tile-secondary:not(.uk-preserve-color) .uk-heading-medium,
  .uk-card-primary.uk-card-body .uk-heading-medium,
  .uk-card-primary > :not([class*='uk-card-media']) .uk-heading-medium,
  .uk-card-secondary.uk-card-body .uk-heading-medium,
  .uk-card-secondary > :not([class*='uk-card-media']) .uk-heading-medium,
  .uk-overlay-primary .uk-heading-medium,
  .uk-offcanvas-bar .uk-heading-medium,
  .uk-light .uk-heading-large,
  .uk-section-primary:not(.uk-preserve-color) .uk-heading-large,
  .uk-section-secondary:not(.uk-preserve-color) .uk-heading-large,
  .uk-tile-primary:not(.uk-preserve-color) .uk-heading-large,
  .uk-tile-secondary:not(.uk-preserve-color) .uk-heading-large,
  .uk-card-primary.uk-card-body .uk-heading-large,
  .uk-card-primary > :not([class*='uk-card-media']) .uk-heading-large,
  .uk-card-secondary.uk-card-body .uk-heading-large,
  .uk-card-secondary > :not([class*='uk-card-media']) .uk-heading-large,
  .uk-overlay-primary .uk-heading-large,
  .uk-offcanvas-bar .uk-heading-large,
  .uk-light .uk-heading-xlarge,
  .uk-section-primary:not(.uk-preserve-color) .uk-heading-xlarge,
  .uk-section-secondary:not(.uk-preserve-color) .uk-heading-xlarge,
  .uk-tile-primary:not(.uk-preserve-color) .uk-heading-xlarge,
  .uk-tile-secondary:not(.uk-preserve-color) .uk-heading-xlarge,
  .uk-card-primary.uk-card-body .uk-heading-xlarge,
  .uk-card-primary > :not([class*='uk-card-media']) .uk-heading-xlarge,
  .uk-card-secondary.uk-card-body .uk-heading-xlarge,
  .uk-card-secondary > :not([class*='uk-card-media']) .uk-heading-xlarge,
  .uk-overlay-primary .uk-heading-xlarge,
  .uk-offcanvas-bar .uk-heading-xlarge,
  .uk-light .uk-heading-2xlarge,
  .uk-section-primary:not(.uk-preserve-color) .uk-heading-2xlarge,
  .uk-section-secondary:not(.uk-preserve-color) .uk-heading-2xlarge,
  .uk-tile-primary:not(.uk-preserve-color) .uk-heading-2xlarge,
  .uk-tile-secondary:not(.uk-preserve-color) .uk-heading-2xlarge,
  .uk-card-primary.uk-card-body .uk-heading-2xlarge,
  .uk-card-primary > :not([class*='uk-card-media']) .uk-heading-2xlarge,
  .uk-card-secondary.uk-card-body .uk-heading-2xlarge,
  .uk-card-secondary > :not([class*='uk-card-media']) .uk-heading-2xlarge,
  .uk-overlay-primary .uk-heading-2xlarge,
  .uk-offcanvas-bar .uk-heading-2xlarge {
    color: #fff; }
  .uk-light hr, .uk-section-primary:not(.uk-preserve-color) hr, .uk-section-secondary:not(.uk-preserve-color) hr, .uk-tile-primary:not(.uk-preserve-color) hr, .uk-tile-secondary:not(.uk-preserve-color) hr, .uk-card-primary.uk-card-body hr, .uk-card-primary > :not([class*='uk-card-media']) hr, .uk-card-secondary.uk-card-body hr, .uk-card-secondary > :not([class*='uk-card-media']) hr, .uk-overlay-primary hr, .uk-offcanvas-bar hr, .uk-light .uk-hr, .uk-section-primary:not(.uk-preserve-color) .uk-hr, .uk-section-secondary:not(.uk-preserve-color) .uk-hr, .uk-tile-primary:not(.uk-preserve-color) .uk-hr, .uk-tile-secondary:not(.uk-preserve-color) .uk-hr, .uk-card-primary.uk-card-body .uk-hr, .uk-card-primary > :not([class*='uk-card-media']) .uk-hr, .uk-card-secondary.uk-card-body .uk-hr, .uk-card-secondary > :not([class*='uk-card-media']) .uk-hr, .uk-overlay-primary .uk-hr, .uk-offcanvas-bar .uk-hr {
    border-top-color: rgba(255, 255, 255, 0.2); }
  .uk-light :focus, .uk-section-primary:not(.uk-preserve-color) :focus, .uk-section-secondary:not(.uk-preserve-color) :focus, .uk-tile-primary:not(.uk-preserve-color) :focus, .uk-tile-secondary:not(.uk-preserve-color) :focus, .uk-card-primary.uk-card-body :focus, .uk-card-primary > :not([class*='uk-card-media']) :focus, .uk-card-secondary.uk-card-body :focus, .uk-card-secondary > :not([class*='uk-card-media']) :focus, .uk-overlay-primary :focus, .uk-offcanvas-bar :focus {
    outline-color: #fff; }
  .uk-light :focus-visible, .uk-section-primary:not(.uk-preserve-color) :focus-visible, .uk-section-secondary:not(.uk-preserve-color) :focus-visible, .uk-tile-primary:not(.uk-preserve-color) :focus-visible, .uk-tile-secondary:not(.uk-preserve-color) :focus-visible, .uk-card-primary.uk-card-body :focus-visible, .uk-card-primary > :not([class*='uk-card-media']) :focus-visible, .uk-card-secondary.uk-card-body :focus-visible, .uk-card-secondary > :not([class*='uk-card-media']) :focus-visible, .uk-overlay-primary :focus-visible, .uk-offcanvas-bar :focus-visible {
    outline-color: #fff; }
  .uk-light a.uk-link-muted, .uk-section-primary:not(.uk-preserve-color) a.uk-link-muted, .uk-section-secondary:not(.uk-preserve-color) a.uk-link-muted, .uk-tile-primary:not(.uk-preserve-color) a.uk-link-muted, .uk-tile-secondary:not(.uk-preserve-color) a.uk-link-muted, .uk-card-primary.uk-card-body a.uk-link-muted, .uk-card-primary > :not([class*='uk-card-media']) a.uk-link-muted, .uk-card-secondary.uk-card-body a.uk-link-muted, .uk-card-secondary > :not([class*='uk-card-media']) a.uk-link-muted, .uk-overlay-primary a.uk-link-muted, .uk-offcanvas-bar a.uk-link-muted,
  .uk-light .uk-link-muted a,
  .uk-section-primary:not(.uk-preserve-color) .uk-link-muted a,
  .uk-section-secondary:not(.uk-preserve-color) .uk-link-muted a,
  .uk-tile-primary:not(.uk-preserve-color) .uk-link-muted a,
  .uk-tile-secondary:not(.uk-preserve-color) .uk-link-muted a,
  .uk-card-primary.uk-card-body .uk-link-muted a,
  .uk-card-primary > :not([class*='uk-card-media']) .uk-link-muted a,
  .uk-card-secondary.uk-card-body .uk-link-muted a,
  .uk-card-secondary > :not([class*='uk-card-media']) .uk-link-muted a,
  .uk-overlay-primary .uk-link-muted a,
  .uk-offcanvas-bar .uk-link-muted a {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light a.uk-link-muted:hover, .uk-section-primary:not(.uk-preserve-color) a.uk-link-muted:hover, .uk-section-secondary:not(.uk-preserve-color) a.uk-link-muted:hover, .uk-tile-primary:not(.uk-preserve-color) a.uk-link-muted:hover, .uk-tile-secondary:not(.uk-preserve-color) a.uk-link-muted:hover, .uk-card-primary.uk-card-body a.uk-link-muted:hover, .uk-card-primary > :not([class*='uk-card-media']) a.uk-link-muted:hover, .uk-card-secondary.uk-card-body a.uk-link-muted:hover, .uk-card-secondary > :not([class*='uk-card-media']) a.uk-link-muted:hover, .uk-overlay-primary a.uk-link-muted:hover, .uk-offcanvas-bar a.uk-link-muted:hover,
  .uk-light .uk-link-muted a:hover,
  .uk-section-primary:not(.uk-preserve-color) .uk-link-muted a:hover,
  .uk-section-secondary:not(.uk-preserve-color) .uk-link-muted a:hover,
  .uk-tile-primary:not(.uk-preserve-color) .uk-link-muted a:hover,
  .uk-tile-secondary:not(.uk-preserve-color) .uk-link-muted a:hover,
  .uk-card-primary.uk-card-body .uk-link-muted a:hover,
  .uk-card-primary > :not([class*='uk-card-media']) .uk-link-muted a:hover,
  .uk-card-secondary.uk-card-body .uk-link-muted a:hover,
  .uk-card-secondary > :not([class*='uk-card-media']) .uk-link-muted a:hover,
  .uk-overlay-primary .uk-link-muted a:hover,
  .uk-offcanvas-bar .uk-link-muted a:hover,
  .uk-light .uk-link-toggle:hover .uk-link-muted,
  .uk-section-primary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-muted,
  .uk-section-secondary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-muted,
  .uk-tile-primary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-muted,
  .uk-tile-secondary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-muted,
  .uk-card-primary.uk-card-body .uk-link-toggle:hover .uk-link-muted,
  .uk-card-primary > :not([class*='uk-card-media']) .uk-link-toggle:hover .uk-link-muted,
  .uk-card-secondary.uk-card-body .uk-link-toggle:hover .uk-link-muted,
  .uk-card-secondary > :not([class*='uk-card-media']) .uk-link-toggle:hover .uk-link-muted,
  .uk-overlay-primary .uk-link-toggle:hover .uk-link-muted,
  .uk-offcanvas-bar .uk-link-toggle:hover .uk-link-muted {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light a.uk-link-text:hover, .uk-section-primary:not(.uk-preserve-color) a.uk-link-text:hover, .uk-section-secondary:not(.uk-preserve-color) a.uk-link-text:hover, .uk-tile-primary:not(.uk-preserve-color) a.uk-link-text:hover, .uk-tile-secondary:not(.uk-preserve-color) a.uk-link-text:hover, .uk-card-primary.uk-card-body a.uk-link-text:hover, .uk-card-primary > :not([class*='uk-card-media']) a.uk-link-text:hover, .uk-card-secondary.uk-card-body a.uk-link-text:hover, .uk-card-secondary > :not([class*='uk-card-media']) a.uk-link-text:hover, .uk-overlay-primary a.uk-link-text:hover, .uk-offcanvas-bar a.uk-link-text:hover,
  .uk-light .uk-link-text a:hover,
  .uk-section-primary:not(.uk-preserve-color) .uk-link-text a:hover,
  .uk-section-secondary:not(.uk-preserve-color) .uk-link-text a:hover,
  .uk-tile-primary:not(.uk-preserve-color) .uk-link-text a:hover,
  .uk-tile-secondary:not(.uk-preserve-color) .uk-link-text a:hover,
  .uk-card-primary.uk-card-body .uk-link-text a:hover,
  .uk-card-primary > :not([class*='uk-card-media']) .uk-link-text a:hover,
  .uk-card-secondary.uk-card-body .uk-link-text a:hover,
  .uk-card-secondary > :not([class*='uk-card-media']) .uk-link-text a:hover,
  .uk-overlay-primary .uk-link-text a:hover,
  .uk-offcanvas-bar .uk-link-text a:hover,
  .uk-light .uk-link-toggle:hover .uk-link-text,
  .uk-section-primary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-text,
  .uk-section-secondary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-text,
  .uk-tile-primary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-text,
  .uk-tile-secondary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-text,
  .uk-card-primary.uk-card-body .uk-link-toggle:hover .uk-link-text,
  .uk-card-primary > :not([class*='uk-card-media']) .uk-link-toggle:hover .uk-link-text,
  .uk-card-secondary.uk-card-body .uk-link-toggle:hover .uk-link-text,
  .uk-card-secondary > :not([class*='uk-card-media']) .uk-link-toggle:hover .uk-link-text,
  .uk-overlay-primary .uk-link-toggle:hover .uk-link-text,
  .uk-offcanvas-bar .uk-link-toggle:hover .uk-link-text {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light a.uk-link-heading:hover, .uk-section-primary:not(.uk-preserve-color) a.uk-link-heading:hover, .uk-section-secondary:not(.uk-preserve-color) a.uk-link-heading:hover, .uk-tile-primary:not(.uk-preserve-color) a.uk-link-heading:hover, .uk-tile-secondary:not(.uk-preserve-color) a.uk-link-heading:hover, .uk-card-primary.uk-card-body a.uk-link-heading:hover, .uk-card-primary > :not([class*='uk-card-media']) a.uk-link-heading:hover, .uk-card-secondary.uk-card-body a.uk-link-heading:hover, .uk-card-secondary > :not([class*='uk-card-media']) a.uk-link-heading:hover, .uk-overlay-primary a.uk-link-heading:hover, .uk-offcanvas-bar a.uk-link-heading:hover,
  .uk-light .uk-link-heading a:hover,
  .uk-section-primary:not(.uk-preserve-color) .uk-link-heading a:hover,
  .uk-section-secondary:not(.uk-preserve-color) .uk-link-heading a:hover,
  .uk-tile-primary:not(.uk-preserve-color) .uk-link-heading a:hover,
  .uk-tile-secondary:not(.uk-preserve-color) .uk-link-heading a:hover,
  .uk-card-primary.uk-card-body .uk-link-heading a:hover,
  .uk-card-primary > :not([class*='uk-card-media']) .uk-link-heading a:hover,
  .uk-card-secondary.uk-card-body .uk-link-heading a:hover,
  .uk-card-secondary > :not([class*='uk-card-media']) .uk-link-heading a:hover,
  .uk-overlay-primary .uk-link-heading a:hover,
  .uk-offcanvas-bar .uk-link-heading a:hover,
  .uk-light .uk-link-toggle:hover .uk-link-heading,
  .uk-section-primary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-heading,
  .uk-section-secondary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-heading,
  .uk-tile-primary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-heading,
  .uk-tile-secondary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-heading,
  .uk-card-primary.uk-card-body .uk-link-toggle:hover .uk-link-heading,
  .uk-card-primary > :not([class*='uk-card-media']) .uk-link-toggle:hover .uk-link-heading,
  .uk-card-secondary.uk-card-body .uk-link-toggle:hover .uk-link-heading,
  .uk-card-secondary > :not([class*='uk-card-media']) .uk-link-toggle:hover .uk-link-heading,
  .uk-overlay-primary .uk-link-toggle:hover .uk-link-heading,
  .uk-offcanvas-bar .uk-link-toggle:hover .uk-link-heading {
    color: #fff; }
  .uk-light .uk-heading-divider, .uk-section-primary:not(.uk-preserve-color) .uk-heading-divider, .uk-section-secondary:not(.uk-preserve-color) .uk-heading-divider, .uk-tile-primary:not(.uk-preserve-color) .uk-heading-divider, .uk-tile-secondary:not(.uk-preserve-color) .uk-heading-divider, .uk-card-primary.uk-card-body .uk-heading-divider, .uk-card-primary > :not([class*='uk-card-media']) .uk-heading-divider, .uk-card-secondary.uk-card-body .uk-heading-divider, .uk-card-secondary > :not([class*='uk-card-media']) .uk-heading-divider, .uk-overlay-primary .uk-heading-divider, .uk-offcanvas-bar .uk-heading-divider {
    border-bottom-color: rgba(255, 255, 255, 0.2); }
  .uk-light .uk-heading-bullet::before, .uk-section-primary:not(.uk-preserve-color) .uk-heading-bullet::before, .uk-section-secondary:not(.uk-preserve-color) .uk-heading-bullet::before, .uk-tile-primary:not(.uk-preserve-color) .uk-heading-bullet::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-heading-bullet::before, .uk-card-primary.uk-card-body .uk-heading-bullet::before, .uk-card-primary > :not([class*='uk-card-media']) .uk-heading-bullet::before, .uk-card-secondary.uk-card-body .uk-heading-bullet::before, .uk-card-secondary > :not([class*='uk-card-media']) .uk-heading-bullet::before, .uk-overlay-primary .uk-heading-bullet::before, .uk-offcanvas-bar .uk-heading-bullet::before {
    border-left-color: rgba(255, 255, 255, 0.2); }
  .uk-light .uk-heading-line > ::before, .uk-section-primary:not(.uk-preserve-color) .uk-heading-line > ::before, .uk-section-secondary:not(.uk-preserve-color) .uk-heading-line > ::before, .uk-tile-primary:not(.uk-preserve-color) .uk-heading-line > ::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-heading-line > ::before, .uk-card-primary.uk-card-body .uk-heading-line > ::before, .uk-card-primary > :not([class*='uk-card-media']) .uk-heading-line > ::before, .uk-card-secondary.uk-card-body .uk-heading-line > ::before, .uk-card-secondary > :not([class*='uk-card-media']) .uk-heading-line > ::before, .uk-overlay-primary .uk-heading-line > ::before, .uk-offcanvas-bar .uk-heading-line > ::before,
  .uk-light .uk-heading-line > ::after,
  .uk-section-primary:not(.uk-preserve-color) .uk-heading-line > ::after,
  .uk-section-secondary:not(.uk-preserve-color) .uk-heading-line > ::after,
  .uk-tile-primary:not(.uk-preserve-color) .uk-heading-line > ::after,
  .uk-tile-secondary:not(.uk-preserve-color) .uk-heading-line > ::after,
  .uk-card-primary.uk-card-body .uk-heading-line > ::after,
  .uk-card-primary > :not([class*='uk-card-media']) .uk-heading-line > ::after,
  .uk-card-secondary.uk-card-body .uk-heading-line > ::after,
  .uk-card-secondary > :not([class*='uk-card-media']) .uk-heading-line > ::after,
  .uk-overlay-primary .uk-heading-line > ::after,
  .uk-offcanvas-bar .uk-heading-line > ::after {
    border-bottom-color: rgba(255, 255, 255, 0.2); }
  .uk-light .uk-divider-icon, .uk-section-primary:not(.uk-preserve-color) .uk-divider-icon, .uk-section-secondary:not(.uk-preserve-color) .uk-divider-icon, .uk-tile-primary:not(.uk-preserve-color) .uk-divider-icon, .uk-tile-secondary:not(.uk-preserve-color) .uk-divider-icon, .uk-card-primary.uk-card-body .uk-divider-icon, .uk-card-primary > :not([class*='uk-card-media']) .uk-divider-icon, .uk-card-secondary.uk-card-body .uk-divider-icon, .uk-card-secondary > :not([class*='uk-card-media']) .uk-divider-icon, .uk-overlay-primary .uk-divider-icon, .uk-offcanvas-bar .uk-divider-icon {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22none%22%20stroke%3D%22rgba(255, 255, 255, 0.2)%22%20stroke-width%3D%222%22%20cx%3D%2210%22%20cy%3D%2210%22%20r%3D%227%22%20%2F%3E%0A%3C%2Fsvg%3E%0A"); }
  .uk-light .uk-divider-icon::before, .uk-section-primary:not(.uk-preserve-color) .uk-divider-icon::before, .uk-section-secondary:not(.uk-preserve-color) .uk-divider-icon::before, .uk-tile-primary:not(.uk-preserve-color) .uk-divider-icon::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-divider-icon::before, .uk-card-primary.uk-card-body .uk-divider-icon::before, .uk-card-primary > :not([class*='uk-card-media']) .uk-divider-icon::before, .uk-card-secondary.uk-card-body .uk-divider-icon::before, .uk-card-secondary > :not([class*='uk-card-media']) .uk-divider-icon::before, .uk-overlay-primary .uk-divider-icon::before, .uk-offcanvas-bar .uk-divider-icon::before,
  .uk-light .uk-divider-icon::after,
  .uk-section-primary:not(.uk-preserve-color) .uk-divider-icon::after,
  .uk-section-secondary:not(.uk-preserve-color) .uk-divider-icon::after,
  .uk-tile-primary:not(.uk-preserve-color) .uk-divider-icon::after,
  .uk-tile-secondary:not(.uk-preserve-color) .uk-divider-icon::after,
  .uk-card-primary.uk-card-body .uk-divider-icon::after,
  .uk-card-primary > :not([class*='uk-card-media']) .uk-divider-icon::after,
  .uk-card-secondary.uk-card-body .uk-divider-icon::after,
  .uk-card-secondary > :not([class*='uk-card-media']) .uk-divider-icon::after,
  .uk-overlay-primary .uk-divider-icon::after,
  .uk-offcanvas-bar .uk-divider-icon::after {
    border-bottom-color: rgba(255, 255, 255, 0.2); }
  .uk-light .uk-divider-small::after, .uk-section-primary:not(.uk-preserve-color) .uk-divider-small::after, .uk-section-secondary:not(.uk-preserve-color) .uk-divider-small::after, .uk-tile-primary:not(.uk-preserve-color) .uk-divider-small::after, .uk-tile-secondary:not(.uk-preserve-color) .uk-divider-small::after, .uk-card-primary.uk-card-body .uk-divider-small::after, .uk-card-primary > :not([class*='uk-card-media']) .uk-divider-small::after, .uk-card-secondary.uk-card-body .uk-divider-small::after, .uk-card-secondary > :not([class*='uk-card-media']) .uk-divider-small::after, .uk-overlay-primary .uk-divider-small::after, .uk-offcanvas-bar .uk-divider-small::after {
    border-top-color: rgba(255, 255, 255, 0.2); }
  .uk-light .uk-divider-vertical, .uk-section-primary:not(.uk-preserve-color) .uk-divider-vertical, .uk-section-secondary:not(.uk-preserve-color) .uk-divider-vertical, .uk-tile-primary:not(.uk-preserve-color) .uk-divider-vertical, .uk-tile-secondary:not(.uk-preserve-color) .uk-divider-vertical, .uk-card-primary.uk-card-body .uk-divider-vertical, .uk-card-primary > :not([class*='uk-card-media']) .uk-divider-vertical, .uk-card-secondary.uk-card-body .uk-divider-vertical, .uk-card-secondary > :not([class*='uk-card-media']) .uk-divider-vertical, .uk-overlay-primary .uk-divider-vertical, .uk-offcanvas-bar .uk-divider-vertical {
    border-left-color: rgba(255, 255, 255, 0.2); }
  .uk-light .uk-list-muted > ::before, .uk-section-primary:not(.uk-preserve-color) .uk-list-muted > ::before, .uk-section-secondary:not(.uk-preserve-color) .uk-list-muted > ::before, .uk-tile-primary:not(.uk-preserve-color) .uk-list-muted > ::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-list-muted > ::before, .uk-card-primary.uk-card-body .uk-list-muted > ::before, .uk-card-primary > :not([class*='uk-card-media']) .uk-list-muted > ::before, .uk-card-secondary.uk-card-body .uk-list-muted > ::before, .uk-card-secondary > :not([class*='uk-card-media']) .uk-list-muted > ::before, .uk-overlay-primary .uk-list-muted > ::before, .uk-offcanvas-bar .uk-list-muted > ::before {
    color: rgba(255, 255, 255, 0.5) !important; }
  .uk-light .uk-list-emphasis > ::before, .uk-section-primary:not(.uk-preserve-color) .uk-list-emphasis > ::before, .uk-section-secondary:not(.uk-preserve-color) .uk-list-emphasis > ::before, .uk-tile-primary:not(.uk-preserve-color) .uk-list-emphasis > ::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-list-emphasis > ::before, .uk-card-primary.uk-card-body .uk-list-emphasis > ::before, .uk-card-primary > :not([class*='uk-card-media']) .uk-list-emphasis > ::before, .uk-card-secondary.uk-card-body .uk-list-emphasis > ::before, .uk-card-secondary > :not([class*='uk-card-media']) .uk-list-emphasis > ::before, .uk-overlay-primary .uk-list-emphasis > ::before, .uk-offcanvas-bar .uk-list-emphasis > ::before {
    color: #fff !important; }
  .uk-light .uk-list-primary > ::before, .uk-section-primary:not(.uk-preserve-color) .uk-list-primary > ::before, .uk-section-secondary:not(.uk-preserve-color) .uk-list-primary > ::before, .uk-tile-primary:not(.uk-preserve-color) .uk-list-primary > ::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-list-primary > ::before, .uk-card-primary.uk-card-body .uk-list-primary > ::before, .uk-card-primary > :not([class*='uk-card-media']) .uk-list-primary > ::before, .uk-card-secondary.uk-card-body .uk-list-primary > ::before, .uk-card-secondary > :not([class*='uk-card-media']) .uk-list-primary > ::before, .uk-overlay-primary .uk-list-primary > ::before, .uk-offcanvas-bar .uk-list-primary > ::before {
    color: #fff !important; }
  .uk-light .uk-list-secondary > ::before, .uk-section-primary:not(.uk-preserve-color) .uk-list-secondary > ::before, .uk-section-secondary:not(.uk-preserve-color) .uk-list-secondary > ::before, .uk-tile-primary:not(.uk-preserve-color) .uk-list-secondary > ::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-list-secondary > ::before, .uk-card-primary.uk-card-body .uk-list-secondary > ::before, .uk-card-primary > :not([class*='uk-card-media']) .uk-list-secondary > ::before, .uk-card-secondary.uk-card-body .uk-list-secondary > ::before, .uk-card-secondary > :not([class*='uk-card-media']) .uk-list-secondary > ::before, .uk-overlay-primary .uk-list-secondary > ::before, .uk-offcanvas-bar .uk-list-secondary > ::before {
    color: #fff !important; }
  .uk-light .uk-list-bullet > ::before, .uk-section-primary:not(.uk-preserve-color) .uk-list-bullet > ::before, .uk-section-secondary:not(.uk-preserve-color) .uk-list-bullet > ::before, .uk-tile-primary:not(.uk-preserve-color) .uk-list-bullet > ::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-list-bullet > ::before, .uk-card-primary.uk-card-body .uk-list-bullet > ::before, .uk-card-primary > :not([class*='uk-card-media']) .uk-list-bullet > ::before, .uk-card-secondary.uk-card-body .uk-list-bullet > ::before, .uk-card-secondary > :not([class*='uk-card-media']) .uk-list-bullet > ::before, .uk-overlay-primary .uk-list-bullet > ::before, .uk-offcanvas-bar .uk-list-bullet > ::before {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%226%22%20height%3D%226%22%20viewBox%3D%220%200%206%206%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22rgba(255, 255, 255, 0.7)%22%20cx%3D%223%22%20cy%3D%223%22%20r%3D%223%22%20%2F%3E%0A%3C%2Fsvg%3E"); }
  .uk-light .uk-list-divider > :nth-child(n+2), .uk-section-primary:not(.uk-preserve-color) .uk-list-divider > :nth-child(n+2), .uk-section-secondary:not(.uk-preserve-color) .uk-list-divider > :nth-child(n+2), .uk-tile-primary:not(.uk-preserve-color) .uk-list-divider > :nth-child(n+2), .uk-tile-secondary:not(.uk-preserve-color) .uk-list-divider > :nth-child(n+2), .uk-card-primary.uk-card-body .uk-list-divider > :nth-child(n+2), .uk-card-primary > :not([class*='uk-card-media']) .uk-list-divider > :nth-child(n+2), .uk-card-secondary.uk-card-body .uk-list-divider > :nth-child(n+2), .uk-card-secondary > :not([class*='uk-card-media']) .uk-list-divider > :nth-child(n+2), .uk-overlay-primary .uk-list-divider > :nth-child(n+2), .uk-offcanvas-bar .uk-list-divider > :nth-child(n+2) {
    border-top-color: rgba(255, 255, 255, 0.2); }
  .uk-light .uk-list-striped > :nth-of-type(odd), .uk-section-primary:not(.uk-preserve-color) .uk-list-striped > :nth-of-type(odd), .uk-section-secondary:not(.uk-preserve-color) .uk-list-striped > :nth-of-type(odd), .uk-tile-primary:not(.uk-preserve-color) .uk-list-striped > :nth-of-type(odd), .uk-tile-secondary:not(.uk-preserve-color) .uk-list-striped > :nth-of-type(odd), .uk-card-primary.uk-card-body .uk-list-striped > :nth-of-type(odd), .uk-card-primary > :not([class*='uk-card-media']) .uk-list-striped > :nth-of-type(odd), .uk-card-secondary.uk-card-body .uk-list-striped > :nth-of-type(odd), .uk-card-secondary > :not([class*='uk-card-media']) .uk-list-striped > :nth-of-type(odd), .uk-overlay-primary .uk-list-striped > :nth-of-type(odd), .uk-offcanvas-bar .uk-list-striped > :nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.1); }
  .uk-light .uk-icon-link, .uk-section-primary:not(.uk-preserve-color) .uk-icon-link, .uk-section-secondary:not(.uk-preserve-color) .uk-icon-link, .uk-tile-primary:not(.uk-preserve-color) .uk-icon-link, .uk-tile-secondary:not(.uk-preserve-color) .uk-icon-link, .uk-card-primary.uk-card-body .uk-icon-link, .uk-card-primary > :not([class*='uk-card-media']) .uk-icon-link, .uk-card-secondary.uk-card-body .uk-icon-link, .uk-card-secondary > :not([class*='uk-card-media']) .uk-icon-link, .uk-overlay-primary .uk-icon-link, .uk-offcanvas-bar .uk-icon-link {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-icon-link:hover, .uk-section-primary:not(.uk-preserve-color) .uk-icon-link:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-icon-link:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-icon-link:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-icon-link:hover, .uk-card-primary.uk-card-body .uk-icon-link:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-icon-link:hover, .uk-card-secondary.uk-card-body .uk-icon-link:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-icon-link:hover, .uk-overlay-primary .uk-icon-link:hover, .uk-offcanvas-bar .uk-icon-link:hover {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-icon-link:active, .uk-section-primary:not(.uk-preserve-color) .uk-icon-link:active, .uk-section-secondary:not(.uk-preserve-color) .uk-icon-link:active, .uk-tile-primary:not(.uk-preserve-color) .uk-icon-link:active, .uk-tile-secondary:not(.uk-preserve-color) .uk-icon-link:active, .uk-card-primary.uk-card-body .uk-icon-link:active, .uk-card-primary > :not([class*='uk-card-media']) .uk-icon-link:active, .uk-card-secondary.uk-card-body .uk-icon-link:active, .uk-card-secondary > :not([class*='uk-card-media']) .uk-icon-link:active, .uk-overlay-primary .uk-icon-link:active, .uk-offcanvas-bar .uk-icon-link:active,
  .uk-light .uk-active > .uk-icon-link,
  .uk-section-primary:not(.uk-preserve-color) .uk-active > .uk-icon-link,
  .uk-section-secondary:not(.uk-preserve-color) .uk-active > .uk-icon-link,
  .uk-tile-primary:not(.uk-preserve-color) .uk-active > .uk-icon-link,
  .uk-tile-secondary:not(.uk-preserve-color) .uk-active > .uk-icon-link,
  .uk-card-primary.uk-card-body .uk-active > .uk-icon-link,
  .uk-card-primary > :not([class*='uk-card-media']) .uk-active > .uk-icon-link,
  .uk-card-secondary.uk-card-body .uk-active > .uk-icon-link,
  .uk-card-secondary > :not([class*='uk-card-media']) .uk-active > .uk-icon-link,
  .uk-overlay-primary .uk-active > .uk-icon-link,
  .uk-offcanvas-bar .uk-active > .uk-icon-link {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-icon-button, .uk-section-primary:not(.uk-preserve-color) .uk-icon-button, .uk-section-secondary:not(.uk-preserve-color) .uk-icon-button, .uk-tile-primary:not(.uk-preserve-color) .uk-icon-button, .uk-tile-secondary:not(.uk-preserve-color) .uk-icon-button, .uk-card-primary.uk-card-body .uk-icon-button, .uk-card-primary > :not([class*='uk-card-media']) .uk-icon-button, .uk-card-secondary.uk-card-body .uk-icon-button, .uk-card-secondary > :not([class*='uk-card-media']) .uk-icon-button, .uk-overlay-primary .uk-icon-button, .uk-offcanvas-bar .uk-icon-button {
    background-color: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-icon-button:hover, .uk-section-primary:not(.uk-preserve-color) .uk-icon-button:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-icon-button:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-icon-button:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-icon-button:hover, .uk-card-primary.uk-card-body .uk-icon-button:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-icon-button:hover, .uk-card-secondary.uk-card-body .uk-icon-button:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-icon-button:hover, .uk-overlay-primary .uk-icon-button:hover, .uk-offcanvas-bar .uk-icon-button:hover {
    background-color: rgba(255, 255, 255, 0.15);
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-icon-button:active, .uk-section-primary:not(.uk-preserve-color) .uk-icon-button:active, .uk-section-secondary:not(.uk-preserve-color) .uk-icon-button:active, .uk-tile-primary:not(.uk-preserve-color) .uk-icon-button:active, .uk-tile-secondary:not(.uk-preserve-color) .uk-icon-button:active, .uk-card-primary.uk-card-body .uk-icon-button:active, .uk-card-primary > :not([class*='uk-card-media']) .uk-icon-button:active, .uk-card-secondary.uk-card-body .uk-icon-button:active, .uk-card-secondary > :not([class*='uk-card-media']) .uk-icon-button:active, .uk-overlay-primary .uk-icon-button:active, .uk-offcanvas-bar .uk-icon-button:active {
    background-color: rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-input, .uk-section-primary:not(.uk-preserve-color) .uk-input, .uk-section-secondary:not(.uk-preserve-color) .uk-input, .uk-tile-primary:not(.uk-preserve-color) .uk-input, .uk-tile-secondary:not(.uk-preserve-color) .uk-input, .uk-card-primary.uk-card-body .uk-input, .uk-card-primary > :not([class*='uk-card-media']) .uk-input, .uk-card-secondary.uk-card-body .uk-input, .uk-card-secondary > :not([class*='uk-card-media']) .uk-input, .uk-overlay-primary .uk-input, .uk-offcanvas-bar .uk-input,
  .uk-light .uk-select,
  .uk-section-primary:not(.uk-preserve-color) .uk-select,
  .uk-section-secondary:not(.uk-preserve-color) .uk-select,
  .uk-tile-primary:not(.uk-preserve-color) .uk-select,
  .uk-tile-secondary:not(.uk-preserve-color) .uk-select,
  .uk-card-primary.uk-card-body .uk-select,
  .uk-card-primary > :not([class*='uk-card-media']) .uk-select,
  .uk-card-secondary.uk-card-body .uk-select,
  .uk-card-secondary > :not([class*='uk-card-media']) .uk-select,
  .uk-overlay-primary .uk-select,
  .uk-offcanvas-bar .uk-select,
  .uk-light .uk-textarea,
  .uk-section-primary:not(.uk-preserve-color) .uk-textarea,
  .uk-section-secondary:not(.uk-preserve-color) .uk-textarea,
  .uk-tile-primary:not(.uk-preserve-color) .uk-textarea,
  .uk-tile-secondary:not(.uk-preserve-color) .uk-textarea,
  .uk-card-primary.uk-card-body .uk-textarea,
  .uk-card-primary > :not([class*='uk-card-media']) .uk-textarea,
  .uk-card-secondary.uk-card-body .uk-textarea,
  .uk-card-secondary > :not([class*='uk-card-media']) .uk-textarea,
  .uk-overlay-primary .uk-textarea,
  .uk-offcanvas-bar .uk-textarea {
    background-color: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.7);
    background-clip: padding-box; }
    .uk-light .uk-input:focus, .uk-section-primary:not(.uk-preserve-color) .uk-input:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-input:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-input:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-input:focus, .uk-card-primary.uk-card-body .uk-input:focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-input:focus, .uk-card-secondary.uk-card-body .uk-input:focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-input:focus, .uk-overlay-primary .uk-input:focus, .uk-offcanvas-bar .uk-input:focus,
    .uk-light .uk-select:focus,
    .uk-section-primary:not(.uk-preserve-color) .uk-select:focus,
    .uk-section-secondary:not(.uk-preserve-color) .uk-select:focus,
    .uk-tile-primary:not(.uk-preserve-color) .uk-select:focus,
    .uk-tile-secondary:not(.uk-preserve-color) .uk-select:focus,
    .uk-card-primary.uk-card-body .uk-select:focus,
    .uk-card-primary > :not([class*='uk-card-media']) .uk-select:focus,
    .uk-card-secondary.uk-card-body .uk-select:focus,
    .uk-card-secondary > :not([class*='uk-card-media']) .uk-select:focus,
    .uk-overlay-primary .uk-select:focus,
    .uk-offcanvas-bar .uk-select:focus,
    .uk-light .uk-textarea:focus,
    .uk-section-primary:not(.uk-preserve-color) .uk-textarea:focus,
    .uk-section-secondary:not(.uk-preserve-color) .uk-textarea:focus,
    .uk-tile-primary:not(.uk-preserve-color) .uk-textarea:focus,
    .uk-tile-secondary:not(.uk-preserve-color) .uk-textarea:focus,
    .uk-card-primary.uk-card-body .uk-textarea:focus,
    .uk-card-primary > :not([class*='uk-card-media']) .uk-textarea:focus,
    .uk-card-secondary.uk-card-body .uk-textarea:focus,
    .uk-card-secondary > :not([class*='uk-card-media']) .uk-textarea:focus,
    .uk-overlay-primary .uk-textarea:focus,
    .uk-offcanvas-bar .uk-textarea:focus {
      background-color: rgba(255, 255, 255, 0.15);
      color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-input::placeholder, .uk-section-primary:not(.uk-preserve-color) .uk-input::placeholder, .uk-section-secondary:not(.uk-preserve-color) .uk-input::placeholder, .uk-tile-primary:not(.uk-preserve-color) .uk-input::placeholder, .uk-tile-secondary:not(.uk-preserve-color) .uk-input::placeholder, .uk-card-primary.uk-card-body .uk-input::placeholder, .uk-card-primary > :not([class*='uk-card-media']) .uk-input::placeholder, .uk-card-secondary.uk-card-body .uk-input::placeholder, .uk-card-secondary > :not([class*='uk-card-media']) .uk-input::placeholder, .uk-overlay-primary .uk-input::placeholder, .uk-offcanvas-bar .uk-input::placeholder {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-textarea::placeholder, .uk-section-primary:not(.uk-preserve-color) .uk-textarea::placeholder, .uk-section-secondary:not(.uk-preserve-color) .uk-textarea::placeholder, .uk-tile-primary:not(.uk-preserve-color) .uk-textarea::placeholder, .uk-tile-secondary:not(.uk-preserve-color) .uk-textarea::placeholder, .uk-card-primary.uk-card-body .uk-textarea::placeholder, .uk-card-primary > :not([class*='uk-card-media']) .uk-textarea::placeholder, .uk-card-secondary.uk-card-body .uk-textarea::placeholder, .uk-card-secondary > :not([class*='uk-card-media']) .uk-textarea::placeholder, .uk-overlay-primary .uk-textarea::placeholder, .uk-offcanvas-bar .uk-textarea::placeholder {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-select:not([multiple]):not([size]), .uk-section-primary:not(.uk-preserve-color) .uk-select:not([multiple]):not([size]), .uk-section-secondary:not(.uk-preserve-color) .uk-select:not([multiple]):not([size]), .uk-tile-primary:not(.uk-preserve-color) .uk-select:not([multiple]):not([size]), .uk-tile-secondary:not(.uk-preserve-color) .uk-select:not([multiple]):not([size]), .uk-card-primary.uk-card-body .uk-select:not([multiple]):not([size]), .uk-card-primary > :not([class*='uk-card-media']) .uk-select:not([multiple]):not([size]), .uk-card-secondary.uk-card-body .uk-select:not([multiple]):not([size]), .uk-card-secondary > :not([class*='uk-card-media']) .uk-select:not([multiple]):not([size]), .uk-overlay-primary .uk-select:not([multiple]):not([size]), .uk-offcanvas-bar .uk-select:not([multiple]):not([size]) {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22rgba(255, 255, 255, 0.7)%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22rgba(255, 255, 255, 0.7)%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A"); }
  .uk-light .uk-input[list]:hover, .uk-section-primary:not(.uk-preserve-color) .uk-input[list]:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-input[list]:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-input[list]:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-input[list]:hover, .uk-card-primary.uk-card-body .uk-input[list]:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-input[list]:hover, .uk-card-secondary.uk-card-body .uk-input[list]:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-input[list]:hover, .uk-overlay-primary .uk-input[list]:hover, .uk-offcanvas-bar .uk-input[list]:hover,
  .uk-light .uk-input[list]:focus,
  .uk-section-primary:not(.uk-preserve-color) .uk-input[list]:focus,
  .uk-section-secondary:not(.uk-preserve-color) .uk-input[list]:focus,
  .uk-tile-primary:not(.uk-preserve-color) .uk-input[list]:focus,
  .uk-tile-secondary:not(.uk-preserve-color) .uk-input[list]:focus,
  .uk-card-primary.uk-card-body .uk-input[list]:focus,
  .uk-card-primary > :not([class*='uk-card-media']) .uk-input[list]:focus,
  .uk-card-secondary.uk-card-body .uk-input[list]:focus,
  .uk-card-secondary > :not([class*='uk-card-media']) .uk-input[list]:focus,
  .uk-overlay-primary .uk-input[list]:focus,
  .uk-offcanvas-bar .uk-input[list]:focus {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22rgba(255, 255, 255, 0.7)%22%20points%3D%2212%2012%208%206%2016%206%22%20%2F%3E%0A%3C%2Fsvg%3E%0A"); }
  .uk-light .uk-radio, .uk-section-primary:not(.uk-preserve-color) .uk-radio, .uk-section-secondary:not(.uk-preserve-color) .uk-radio, .uk-tile-primary:not(.uk-preserve-color) .uk-radio, .uk-tile-secondary:not(.uk-preserve-color) .uk-radio, .uk-card-primary.uk-card-body .uk-radio, .uk-card-primary > :not([class*='uk-card-media']) .uk-radio, .uk-card-secondary.uk-card-body .uk-radio, .uk-card-secondary > :not([class*='uk-card-media']) .uk-radio, .uk-overlay-primary .uk-radio, .uk-offcanvas-bar .uk-radio,
  .uk-light .uk-checkbox,
  .uk-section-primary:not(.uk-preserve-color) .uk-checkbox,
  .uk-section-secondary:not(.uk-preserve-color) .uk-checkbox,
  .uk-tile-primary:not(.uk-preserve-color) .uk-checkbox,
  .uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox,
  .uk-card-primary.uk-card-body .uk-checkbox,
  .uk-card-primary > :not([class*='uk-card-media']) .uk-checkbox,
  .uk-card-secondary.uk-card-body .uk-checkbox,
  .uk-card-secondary > :not([class*='uk-card-media']) .uk-checkbox,
  .uk-overlay-primary .uk-checkbox,
  .uk-offcanvas-bar .uk-checkbox {
    background-color: rgba(255, 255, 255, 0.1); }
  .uk-light .uk-radio:focus, .uk-section-primary:not(.uk-preserve-color) .uk-radio:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-radio:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-radio:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-radio:focus, .uk-card-primary.uk-card-body .uk-radio:focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-radio:focus, .uk-card-secondary.uk-card-body .uk-radio:focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-radio:focus, .uk-overlay-primary .uk-radio:focus, .uk-offcanvas-bar .uk-radio:focus,
  .uk-light .uk-checkbox:focus,
  .uk-section-primary:not(.uk-preserve-color) .uk-checkbox:focus,
  .uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:focus,
  .uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:focus,
  .uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:focus,
  .uk-card-primary.uk-card-body .uk-checkbox:focus,
  .uk-card-primary > :not([class*='uk-card-media']) .uk-checkbox:focus,
  .uk-card-secondary.uk-card-body .uk-checkbox:focus,
  .uk-card-secondary > :not([class*='uk-card-media']) .uk-checkbox:focus,
  .uk-overlay-primary .uk-checkbox:focus,
  .uk-offcanvas-bar .uk-checkbox:focus {
    background-color: rgba(255, 255, 255, 0.15); }
  .uk-light .uk-radio:checked, .uk-section-primary:not(.uk-preserve-color) .uk-radio:checked, .uk-section-secondary:not(.uk-preserve-color) .uk-radio:checked, .uk-tile-primary:not(.uk-preserve-color) .uk-radio:checked, .uk-tile-secondary:not(.uk-preserve-color) .uk-radio:checked, .uk-card-primary.uk-card-body .uk-radio:checked, .uk-card-primary > :not([class*='uk-card-media']) .uk-radio:checked, .uk-card-secondary.uk-card-body .uk-radio:checked, .uk-card-secondary > :not([class*='uk-card-media']) .uk-radio:checked, .uk-overlay-primary .uk-radio:checked, .uk-offcanvas-bar .uk-radio:checked,
  .uk-light .uk-checkbox:checked,
  .uk-section-primary:not(.uk-preserve-color) .uk-checkbox:checked,
  .uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:checked,
  .uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:checked,
  .uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:checked,
  .uk-card-primary.uk-card-body .uk-checkbox:checked,
  .uk-card-primary > :not([class*='uk-card-media']) .uk-checkbox:checked,
  .uk-card-secondary.uk-card-body .uk-checkbox:checked,
  .uk-card-secondary > :not([class*='uk-card-media']) .uk-checkbox:checked,
  .uk-overlay-primary .uk-checkbox:checked,
  .uk-offcanvas-bar .uk-checkbox:checked,
  .uk-light .uk-checkbox:indeterminate,
  .uk-section-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate,
  .uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:indeterminate,
  .uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate,
  .uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:indeterminate,
  .uk-card-primary.uk-card-body .uk-checkbox:indeterminate,
  .uk-card-primary > :not([class*='uk-card-media']) .uk-checkbox:indeterminate,
  .uk-card-secondary.uk-card-body .uk-checkbox:indeterminate,
  .uk-card-secondary > :not([class*='uk-card-media']) .uk-checkbox:indeterminate,
  .uk-overlay-primary .uk-checkbox:indeterminate,
  .uk-offcanvas-bar .uk-checkbox:indeterminate {
    background-color: #fff; }
  .uk-light .uk-radio:checked:focus, .uk-section-primary:not(.uk-preserve-color) .uk-radio:checked:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-radio:checked:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-radio:checked:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-radio:checked:focus, .uk-card-primary.uk-card-body .uk-radio:checked:focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-radio:checked:focus, .uk-card-secondary.uk-card-body .uk-radio:checked:focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-radio:checked:focus, .uk-overlay-primary .uk-radio:checked:focus, .uk-offcanvas-bar .uk-radio:checked:focus,
  .uk-light .uk-checkbox:checked:focus,
  .uk-section-primary:not(.uk-preserve-color) .uk-checkbox:checked:focus,
  .uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:checked:focus,
  .uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:checked:focus,
  .uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:checked:focus,
  .uk-card-primary.uk-card-body .uk-checkbox:checked:focus,
  .uk-card-primary > :not([class*='uk-card-media']) .uk-checkbox:checked:focus,
  .uk-card-secondary.uk-card-body .uk-checkbox:checked:focus,
  .uk-card-secondary > :not([class*='uk-card-media']) .uk-checkbox:checked:focus,
  .uk-overlay-primary .uk-checkbox:checked:focus,
  .uk-offcanvas-bar .uk-checkbox:checked:focus,
  .uk-light .uk-checkbox:indeterminate:focus,
  .uk-section-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate:focus,
  .uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:indeterminate:focus,
  .uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate:focus,
  .uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:indeterminate:focus,
  .uk-card-primary.uk-card-body .uk-checkbox:indeterminate:focus,
  .uk-card-primary > :not([class*='uk-card-media']) .uk-checkbox:indeterminate:focus,
  .uk-card-secondary.uk-card-body .uk-checkbox:indeterminate:focus,
  .uk-card-secondary > :not([class*='uk-card-media']) .uk-checkbox:indeterminate:focus,
  .uk-overlay-primary .uk-checkbox:indeterminate:focus,
  .uk-offcanvas-bar .uk-checkbox:indeterminate:focus {
    background-color: white; }
  .uk-light .uk-radio:checked, .uk-section-primary:not(.uk-preserve-color) .uk-radio:checked, .uk-section-secondary:not(.uk-preserve-color) .uk-radio:checked, .uk-tile-primary:not(.uk-preserve-color) .uk-radio:checked, .uk-tile-secondary:not(.uk-preserve-color) .uk-radio:checked, .uk-card-primary.uk-card-body .uk-radio:checked, .uk-card-primary > :not([class*='uk-card-media']) .uk-radio:checked, .uk-card-secondary.uk-card-body .uk-radio:checked, .uk-card-secondary > :not([class*='uk-card-media']) .uk-radio:checked, .uk-overlay-primary .uk-radio:checked, .uk-offcanvas-bar .uk-radio:checked {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23666%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E"); }
  
  .uk-light .uk-checkbox:checked,
  .uk-section-primary:not(.uk-preserve-color) .uk-checkbox:checked,
  .uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:checked,
  .uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:checked,
  .uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:checked,
  .uk-card-primary.uk-card-body .uk-checkbox:checked,
  .uk-card-primary > :not([class*='uk-card-media']) .uk-checkbox:checked,
  .uk-card-secondary.uk-card-body .uk-checkbox:checked,
  .uk-card-secondary > :not([class*='uk-card-media']) .uk-checkbox:checked,
  .uk-overlay-primary .uk-checkbox:checked,
  .uk-offcanvas-bar .uk-checkbox:checked {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A"); }
  
  .uk-light .uk-checkbox:indeterminate,
  .uk-section-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate,
  .uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:indeterminate,
  .uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate,
  .uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:indeterminate,
  .uk-card-primary.uk-card-body .uk-checkbox:indeterminate,
  .uk-card-primary > :not([class*='uk-card-media']) .uk-checkbox:indeterminate,
  .uk-card-secondary.uk-card-body .uk-checkbox:indeterminate,
  .uk-card-secondary > :not([class*='uk-card-media']) .uk-checkbox:indeterminate,
  .uk-overlay-primary .uk-checkbox:indeterminate,
  .uk-offcanvas-bar .uk-checkbox:indeterminate {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23666%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%20%2F%3E%0A%3C%2Fsvg%3E"); }
  .uk-light .uk-form-icon, .uk-section-primary:not(.uk-preserve-color) .uk-form-icon, .uk-section-secondary:not(.uk-preserve-color) .uk-form-icon, .uk-tile-primary:not(.uk-preserve-color) .uk-form-icon, .uk-tile-secondary:not(.uk-preserve-color) .uk-form-icon, .uk-card-primary.uk-card-body .uk-form-icon, .uk-card-primary > :not([class*='uk-card-media']) .uk-form-icon, .uk-card-secondary.uk-card-body .uk-form-icon, .uk-card-secondary > :not([class*='uk-card-media']) .uk-form-icon, .uk-overlay-primary .uk-form-icon, .uk-offcanvas-bar .uk-form-icon {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-form-icon:hover, .uk-section-primary:not(.uk-preserve-color) .uk-form-icon:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-form-icon:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-form-icon:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-form-icon:hover, .uk-card-primary.uk-card-body .uk-form-icon:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-form-icon:hover, .uk-card-secondary.uk-card-body .uk-form-icon:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-form-icon:hover, .uk-overlay-primary .uk-form-icon:hover, .uk-offcanvas-bar .uk-form-icon:hover {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-button-default, .uk-section-primary:not(.uk-preserve-color) .uk-button-default, .uk-section-secondary:not(.uk-preserve-color) .uk-button-default, .uk-tile-primary:not(.uk-preserve-color) .uk-button-default, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-default, .uk-card-primary.uk-card-body .uk-button-default, .uk-card-primary > :not([class*='uk-card-media']) .uk-button-default, .uk-card-secondary.uk-card-body .uk-button-default, .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-default, .uk-overlay-primary .uk-button-default, .uk-offcanvas-bar .uk-button-default {
    background-color: #fff;
    color: #666; }
  .uk-light .uk-button-default:hover, .uk-section-primary:not(.uk-preserve-color) .uk-button-default:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-button-default:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-button-default:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-default:hover, .uk-card-primary.uk-card-body .uk-button-default:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-button-default:hover, .uk-card-secondary.uk-card-body .uk-button-default:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-default:hover, .uk-overlay-primary .uk-button-default:hover, .uk-offcanvas-bar .uk-button-default:hover {
    background-color: #f2f2f2;
    color: #666; }
  .uk-light .uk-button-default:active, .uk-section-primary:not(.uk-preserve-color) .uk-button-default:active, .uk-section-secondary:not(.uk-preserve-color) .uk-button-default:active, .uk-tile-primary:not(.uk-preserve-color) .uk-button-default:active, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-default:active, .uk-card-primary.uk-card-body .uk-button-default:active, .uk-card-primary > :not([class*='uk-card-media']) .uk-button-default:active, .uk-card-secondary.uk-card-body .uk-button-default:active, .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-default:active, .uk-overlay-primary .uk-button-default:active, .uk-offcanvas-bar .uk-button-default:active,
  .uk-light .uk-button-default.uk-active,
  .uk-section-primary:not(.uk-preserve-color) .uk-button-default.uk-active,
  .uk-section-secondary:not(.uk-preserve-color) .uk-button-default.uk-active,
  .uk-tile-primary:not(.uk-preserve-color) .uk-button-default.uk-active,
  .uk-tile-secondary:not(.uk-preserve-color) .uk-button-default.uk-active,
  .uk-card-primary.uk-card-body .uk-button-default.uk-active,
  .uk-card-primary > :not([class*='uk-card-media']) .uk-button-default.uk-active,
  .uk-card-secondary.uk-card-body .uk-button-default.uk-active,
  .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-default.uk-active,
  .uk-overlay-primary .uk-button-default.uk-active,
  .uk-offcanvas-bar .uk-button-default.uk-active {
    background-color: #e6e5e5;
    color: #666; }
  .uk-light .uk-button-primary, .uk-section-primary:not(.uk-preserve-color) .uk-button-primary, .uk-section-secondary:not(.uk-preserve-color) .uk-button-primary, .uk-tile-primary:not(.uk-preserve-color) .uk-button-primary, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-primary, .uk-card-primary.uk-card-body .uk-button-primary, .uk-card-primary > :not([class*='uk-card-media']) .uk-button-primary, .uk-card-secondary.uk-card-body .uk-button-primary, .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-primary, .uk-overlay-primary .uk-button-primary, .uk-offcanvas-bar .uk-button-primary {
    background-color: #fff;
    color: #666; }
  .uk-light .uk-button-primary:hover, .uk-section-primary:not(.uk-preserve-color) .uk-button-primary:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-button-primary:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-button-primary:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-primary:hover, .uk-card-primary.uk-card-body .uk-button-primary:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-button-primary:hover, .uk-card-secondary.uk-card-body .uk-button-primary:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-primary:hover, .uk-overlay-primary .uk-button-primary:hover, .uk-offcanvas-bar .uk-button-primary:hover {
    background-color: #f2f2f2;
    color: #666; }
  .uk-light .uk-button-primary:active, .uk-section-primary:not(.uk-preserve-color) .uk-button-primary:active, .uk-section-secondary:not(.uk-preserve-color) .uk-button-primary:active, .uk-tile-primary:not(.uk-preserve-color) .uk-button-primary:active, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-primary:active, .uk-card-primary.uk-card-body .uk-button-primary:active, .uk-card-primary > :not([class*='uk-card-media']) .uk-button-primary:active, .uk-card-secondary.uk-card-body .uk-button-primary:active, .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-primary:active, .uk-overlay-primary .uk-button-primary:active, .uk-offcanvas-bar .uk-button-primary:active,
  .uk-light .uk-button-primary.uk-active,
  .uk-section-primary:not(.uk-preserve-color) .uk-button-primary.uk-active,
  .uk-section-secondary:not(.uk-preserve-color) .uk-button-primary.uk-active,
  .uk-tile-primary:not(.uk-preserve-color) .uk-button-primary.uk-active,
  .uk-tile-secondary:not(.uk-preserve-color) .uk-button-primary.uk-active,
  .uk-card-primary.uk-card-body .uk-button-primary.uk-active,
  .uk-card-primary > :not([class*='uk-card-media']) .uk-button-primary.uk-active,
  .uk-card-secondary.uk-card-body .uk-button-primary.uk-active,
  .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-primary.uk-active,
  .uk-overlay-primary .uk-button-primary.uk-active,
  .uk-offcanvas-bar .uk-button-primary.uk-active {
    background-color: #e6e5e5;
    color: #666; }
  .uk-light .uk-button-secondary, .uk-section-primary:not(.uk-preserve-color) .uk-button-secondary, .uk-section-secondary:not(.uk-preserve-color) .uk-button-secondary, .uk-tile-primary:not(.uk-preserve-color) .uk-button-secondary, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-secondary, .uk-card-primary.uk-card-body .uk-button-secondary, .uk-card-primary > :not([class*='uk-card-media']) .uk-button-secondary, .uk-card-secondary.uk-card-body .uk-button-secondary, .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-secondary, .uk-overlay-primary .uk-button-secondary, .uk-offcanvas-bar .uk-button-secondary {
    background-color: #fff;
    color: #666; }
  .uk-light .uk-button-secondary:hover, .uk-section-primary:not(.uk-preserve-color) .uk-button-secondary:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-button-secondary:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-button-secondary:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-secondary:hover, .uk-card-primary.uk-card-body .uk-button-secondary:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-button-secondary:hover, .uk-card-secondary.uk-card-body .uk-button-secondary:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-secondary:hover, .uk-overlay-primary .uk-button-secondary:hover, .uk-offcanvas-bar .uk-button-secondary:hover {
    background-color: #f2f2f2;
    color: #666; }
  .uk-light .uk-button-secondary:active, .uk-section-primary:not(.uk-preserve-color) .uk-button-secondary:active, .uk-section-secondary:not(.uk-preserve-color) .uk-button-secondary:active, .uk-tile-primary:not(.uk-preserve-color) .uk-button-secondary:active, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-secondary:active, .uk-card-primary.uk-card-body .uk-button-secondary:active, .uk-card-primary > :not([class*='uk-card-media']) .uk-button-secondary:active, .uk-card-secondary.uk-card-body .uk-button-secondary:active, .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-secondary:active, .uk-overlay-primary .uk-button-secondary:active, .uk-offcanvas-bar .uk-button-secondary:active,
  .uk-light .uk-button-secondary.uk-active,
  .uk-section-primary:not(.uk-preserve-color) .uk-button-secondary.uk-active,
  .uk-section-secondary:not(.uk-preserve-color) .uk-button-secondary.uk-active,
  .uk-tile-primary:not(.uk-preserve-color) .uk-button-secondary.uk-active,
  .uk-tile-secondary:not(.uk-preserve-color) .uk-button-secondary.uk-active,
  .uk-card-primary.uk-card-body .uk-button-secondary.uk-active,
  .uk-card-primary > :not([class*='uk-card-media']) .uk-button-secondary.uk-active,
  .uk-card-secondary.uk-card-body .uk-button-secondary.uk-active,
  .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-secondary.uk-active,
  .uk-overlay-primary .uk-button-secondary.uk-active,
  .uk-offcanvas-bar .uk-button-secondary.uk-active {
    background-color: #e6e5e5;
    color: #666; }
  .uk-light .uk-button-text, .uk-section-primary:not(.uk-preserve-color) .uk-button-text, .uk-section-secondary:not(.uk-preserve-color) .uk-button-text, .uk-tile-primary:not(.uk-preserve-color) .uk-button-text, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-text, .uk-card-primary.uk-card-body .uk-button-text, .uk-card-primary > :not([class*='uk-card-media']) .uk-button-text, .uk-card-secondary.uk-card-body .uk-button-text, .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-text, .uk-overlay-primary .uk-button-text, .uk-offcanvas-bar .uk-button-text {
    color: #fff; }
  .uk-light .uk-button-text:hover, .uk-section-primary:not(.uk-preserve-color) .uk-button-text:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-button-text:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-button-text:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-text:hover, .uk-card-primary.uk-card-body .uk-button-text:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-button-text:hover, .uk-card-secondary.uk-card-body .uk-button-text:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-text:hover, .uk-overlay-primary .uk-button-text:hover, .uk-offcanvas-bar .uk-button-text:hover {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-button-text:disabled, .uk-section-primary:not(.uk-preserve-color) .uk-button-text:disabled, .uk-section-secondary:not(.uk-preserve-color) .uk-button-text:disabled, .uk-tile-primary:not(.uk-preserve-color) .uk-button-text:disabled, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-text:disabled, .uk-card-primary.uk-card-body .uk-button-text:disabled, .uk-card-primary > :not([class*='uk-card-media']) .uk-button-text:disabled, .uk-card-secondary.uk-card-body .uk-button-text:disabled, .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-text:disabled, .uk-overlay-primary .uk-button-text:disabled, .uk-offcanvas-bar .uk-button-text:disabled {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-button-link, .uk-section-primary:not(.uk-preserve-color) .uk-button-link, .uk-section-secondary:not(.uk-preserve-color) .uk-button-link, .uk-tile-primary:not(.uk-preserve-color) .uk-button-link, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-link, .uk-card-primary.uk-card-body .uk-button-link, .uk-card-primary > :not([class*='uk-card-media']) .uk-button-link, .uk-card-secondary.uk-card-body .uk-button-link, .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-link, .uk-overlay-primary .uk-button-link, .uk-offcanvas-bar .uk-button-link {
    color: #fff; }
  .uk-light .uk-button-link:hover, .uk-section-primary:not(.uk-preserve-color) .uk-button-link:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-button-link:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-button-link:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-link:hover, .uk-card-primary.uk-card-body .uk-button-link:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-button-link:hover, .uk-card-secondary.uk-card-body .uk-button-link:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-link:hover, .uk-overlay-primary .uk-button-link:hover, .uk-offcanvas-bar .uk-button-link:hover {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-grid-divider > :not(.uk-first-column)::before, .uk-section-primary:not(.uk-preserve-color) .uk-grid-divider > :not(.uk-first-column)::before, .uk-section-secondary:not(.uk-preserve-color) .uk-grid-divider > :not(.uk-first-column)::before, .uk-tile-primary:not(.uk-preserve-color) .uk-grid-divider > :not(.uk-first-column)::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-grid-divider > :not(.uk-first-column)::before, .uk-card-primary.uk-card-body .uk-grid-divider > :not(.uk-first-column)::before, .uk-card-primary > :not([class*='uk-card-media']) .uk-grid-divider > :not(.uk-first-column)::before, .uk-card-secondary.uk-card-body .uk-grid-divider > :not(.uk-first-column)::before, .uk-card-secondary > :not([class*='uk-card-media']) .uk-grid-divider > :not(.uk-first-column)::before, .uk-overlay-primary .uk-grid-divider > :not(.uk-first-column)::before, .uk-offcanvas-bar .uk-grid-divider > :not(.uk-first-column)::before {
    border-left-color: rgba(255, 255, 255, 0.2); }
  .uk-light .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-section-primary:not(.uk-preserve-color) .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-section-secondary:not(.uk-preserve-color) .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-tile-primary:not(.uk-preserve-color) .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-card-primary.uk-card-body .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-card-primary > :not([class*='uk-card-media']) .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-card-secondary.uk-card-body .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-card-secondary > :not([class*='uk-card-media']) .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-overlay-primary .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-offcanvas-bar .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
    border-top-color: rgba(255, 255, 255, 0.2); }
  .uk-light .uk-close, .uk-section-primary:not(.uk-preserve-color) .uk-close, .uk-section-secondary:not(.uk-preserve-color) .uk-close, .uk-tile-primary:not(.uk-preserve-color) .uk-close, .uk-tile-secondary:not(.uk-preserve-color) .uk-close, .uk-card-primary.uk-card-body .uk-close, .uk-card-primary > :not([class*='uk-card-media']) .uk-close, .uk-card-secondary.uk-card-body .uk-close, .uk-card-secondary > :not([class*='uk-card-media']) .uk-close, .uk-overlay-primary .uk-close, .uk-offcanvas-bar .uk-close {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-close:hover, .uk-section-primary:not(.uk-preserve-color) .uk-close:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-close:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-close:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-close:hover, .uk-card-primary.uk-card-body .uk-close:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-close:hover, .uk-card-secondary.uk-card-body .uk-close:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-close:hover, .uk-overlay-primary .uk-close:hover, .uk-offcanvas-bar .uk-close:hover {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-totop, .uk-section-primary:not(.uk-preserve-color) .uk-totop, .uk-section-secondary:not(.uk-preserve-color) .uk-totop, .uk-tile-primary:not(.uk-preserve-color) .uk-totop, .uk-tile-secondary:not(.uk-preserve-color) .uk-totop, .uk-card-primary.uk-card-body .uk-totop, .uk-card-primary > :not([class*='uk-card-media']) .uk-totop, .uk-card-secondary.uk-card-body .uk-totop, .uk-card-secondary > :not([class*='uk-card-media']) .uk-totop, .uk-overlay-primary .uk-totop, .uk-offcanvas-bar .uk-totop {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-totop:hover, .uk-section-primary:not(.uk-preserve-color) .uk-totop:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-totop:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-totop:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-totop:hover, .uk-card-primary.uk-card-body .uk-totop:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-totop:hover, .uk-card-secondary.uk-card-body .uk-totop:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-totop:hover, .uk-overlay-primary .uk-totop:hover, .uk-offcanvas-bar .uk-totop:hover {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-totop:active, .uk-section-primary:not(.uk-preserve-color) .uk-totop:active, .uk-section-secondary:not(.uk-preserve-color) .uk-totop:active, .uk-tile-primary:not(.uk-preserve-color) .uk-totop:active, .uk-tile-secondary:not(.uk-preserve-color) .uk-totop:active, .uk-card-primary.uk-card-body .uk-totop:active, .uk-card-primary > :not([class*='uk-card-media']) .uk-totop:active, .uk-card-secondary.uk-card-body .uk-totop:active, .uk-card-secondary > :not([class*='uk-card-media']) .uk-totop:active, .uk-overlay-primary .uk-totop:active, .uk-offcanvas-bar .uk-totop:active {
    color: #fff; }
  .uk-light .uk-badge, .uk-section-primary:not(.uk-preserve-color) .uk-badge, .uk-section-secondary:not(.uk-preserve-color) .uk-badge, .uk-tile-primary:not(.uk-preserve-color) .uk-badge, .uk-tile-secondary:not(.uk-preserve-color) .uk-badge, .uk-card-primary.uk-card-body .uk-badge, .uk-card-primary > :not([class*='uk-card-media']) .uk-badge, .uk-card-secondary.uk-card-body .uk-badge, .uk-card-secondary > :not([class*='uk-card-media']) .uk-badge, .uk-overlay-primary .uk-badge, .uk-offcanvas-bar .uk-badge {
    background-color: #fff;
    color: #666 !important; }
  .uk-light .uk-label, .uk-section-primary:not(.uk-preserve-color) .uk-label, .uk-section-secondary:not(.uk-preserve-color) .uk-label, .uk-tile-primary:not(.uk-preserve-color) .uk-label, .uk-tile-secondary:not(.uk-preserve-color) .uk-label, .uk-card-primary.uk-card-body .uk-label, .uk-card-primary > :not([class*='uk-card-media']) .uk-label, .uk-card-secondary.uk-card-body .uk-label, .uk-card-secondary > :not([class*='uk-card-media']) .uk-label, .uk-overlay-primary .uk-label, .uk-offcanvas-bar .uk-label {
    background-color: #fff;
    color: #666; }
  .uk-light .uk-article-meta, .uk-section-primary:not(.uk-preserve-color) .uk-article-meta, .uk-section-secondary:not(.uk-preserve-color) .uk-article-meta, .uk-tile-primary:not(.uk-preserve-color) .uk-article-meta, .uk-tile-secondary:not(.uk-preserve-color) .uk-article-meta, .uk-card-primary.uk-card-body .uk-article-meta, .uk-card-primary > :not([class*='uk-card-media']) .uk-article-meta, .uk-card-secondary.uk-card-body .uk-article-meta, .uk-card-secondary > :not([class*='uk-card-media']) .uk-article-meta, .uk-overlay-primary .uk-article-meta, .uk-offcanvas-bar .uk-article-meta {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-search-input, .uk-section-primary:not(.uk-preserve-color) .uk-search-input, .uk-section-secondary:not(.uk-preserve-color) .uk-search-input, .uk-tile-primary:not(.uk-preserve-color) .uk-search-input, .uk-tile-secondary:not(.uk-preserve-color) .uk-search-input, .uk-card-primary.uk-card-body .uk-search-input, .uk-card-primary > :not([class*='uk-card-media']) .uk-search-input, .uk-card-secondary.uk-card-body .uk-search-input, .uk-card-secondary > :not([class*='uk-card-media']) .uk-search-input, .uk-overlay-primary .uk-search-input, .uk-offcanvas-bar .uk-search-input {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-search-input::placeholder, .uk-section-primary:not(.uk-preserve-color) .uk-search-input::placeholder, .uk-section-secondary:not(.uk-preserve-color) .uk-search-input::placeholder, .uk-tile-primary:not(.uk-preserve-color) .uk-search-input::placeholder, .uk-tile-secondary:not(.uk-preserve-color) .uk-search-input::placeholder, .uk-card-primary.uk-card-body .uk-search-input::placeholder, .uk-card-primary > :not([class*='uk-card-media']) .uk-search-input::placeholder, .uk-card-secondary.uk-card-body .uk-search-input::placeholder, .uk-card-secondary > :not([class*='uk-card-media']) .uk-search-input::placeholder, .uk-overlay-primary .uk-search-input::placeholder, .uk-offcanvas-bar .uk-search-input::placeholder {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-search .uk-search-icon, .uk-section-primary:not(.uk-preserve-color) .uk-search .uk-search-icon, .uk-section-secondary:not(.uk-preserve-color) .uk-search .uk-search-icon, .uk-tile-primary:not(.uk-preserve-color) .uk-search .uk-search-icon, .uk-tile-secondary:not(.uk-preserve-color) .uk-search .uk-search-icon, .uk-card-primary.uk-card-body .uk-search .uk-search-icon, .uk-card-primary > :not([class*='uk-card-media']) .uk-search .uk-search-icon, .uk-card-secondary.uk-card-body .uk-search .uk-search-icon, .uk-card-secondary > :not([class*='uk-card-media']) .uk-search .uk-search-icon, .uk-overlay-primary .uk-search .uk-search-icon, .uk-offcanvas-bar .uk-search .uk-search-icon {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-search .uk-search-icon:hover, .uk-section-primary:not(.uk-preserve-color) .uk-search .uk-search-icon:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-search .uk-search-icon:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-search .uk-search-icon:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-search .uk-search-icon:hover, .uk-card-primary.uk-card-body .uk-search .uk-search-icon:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-search .uk-search-icon:hover, .uk-card-secondary.uk-card-body .uk-search .uk-search-icon:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-search .uk-search-icon:hover, .uk-overlay-primary .uk-search .uk-search-icon:hover, .uk-offcanvas-bar .uk-search .uk-search-icon:hover {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-search-default .uk-search-input, .uk-section-primary:not(.uk-preserve-color) .uk-search-default .uk-search-input, .uk-section-secondary:not(.uk-preserve-color) .uk-search-default .uk-search-input, .uk-tile-primary:not(.uk-preserve-color) .uk-search-default .uk-search-input, .uk-tile-secondary:not(.uk-preserve-color) .uk-search-default .uk-search-input, .uk-card-primary.uk-card-body .uk-search-default .uk-search-input, .uk-card-primary > :not([class*='uk-card-media']) .uk-search-default .uk-search-input, .uk-card-secondary.uk-card-body .uk-search-default .uk-search-input, .uk-card-secondary > :not([class*='uk-card-media']) .uk-search-default .uk-search-input, .uk-overlay-primary .uk-search-default .uk-search-input, .uk-offcanvas-bar .uk-search-default .uk-search-input {
    background-color: rgba(255, 255, 255, 0.1); }
  .uk-light .uk-search-default .uk-search-input:focus, .uk-section-primary:not(.uk-preserve-color) .uk-search-default .uk-search-input:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-search-default .uk-search-input:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-search-default .uk-search-input:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-search-default .uk-search-input:focus, .uk-card-primary.uk-card-body .uk-search-default .uk-search-input:focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-search-default .uk-search-input:focus, .uk-card-secondary.uk-card-body .uk-search-default .uk-search-input:focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-search-default .uk-search-input:focus, .uk-overlay-primary .uk-search-default .uk-search-input:focus, .uk-offcanvas-bar .uk-search-default .uk-search-input:focus {
    background-color: rgba(255, 255, 255, 0.15); }
  .uk-light .uk-search-navbar .uk-search-input, .uk-section-primary:not(.uk-preserve-color) .uk-search-navbar .uk-search-input, .uk-section-secondary:not(.uk-preserve-color) .uk-search-navbar .uk-search-input, .uk-tile-primary:not(.uk-preserve-color) .uk-search-navbar .uk-search-input, .uk-tile-secondary:not(.uk-preserve-color) .uk-search-navbar .uk-search-input, .uk-card-primary.uk-card-body .uk-search-navbar .uk-search-input, .uk-card-primary > :not([class*='uk-card-media']) .uk-search-navbar .uk-search-input, .uk-card-secondary.uk-card-body .uk-search-navbar .uk-search-input, .uk-card-secondary > :not([class*='uk-card-media']) .uk-search-navbar .uk-search-input, .uk-overlay-primary .uk-search-navbar .uk-search-input, .uk-offcanvas-bar .uk-search-navbar .uk-search-input {
    background-color: transparent; }
  .uk-light .uk-search-large .uk-search-input, .uk-section-primary:not(.uk-preserve-color) .uk-search-large .uk-search-input, .uk-section-secondary:not(.uk-preserve-color) .uk-search-large .uk-search-input, .uk-tile-primary:not(.uk-preserve-color) .uk-search-large .uk-search-input, .uk-tile-secondary:not(.uk-preserve-color) .uk-search-large .uk-search-input, .uk-card-primary.uk-card-body .uk-search-large .uk-search-input, .uk-card-primary > :not([class*='uk-card-media']) .uk-search-large .uk-search-input, .uk-card-secondary.uk-card-body .uk-search-large .uk-search-input, .uk-card-secondary > :not([class*='uk-card-media']) .uk-search-large .uk-search-input, .uk-overlay-primary .uk-search-large .uk-search-input, .uk-offcanvas-bar .uk-search-large .uk-search-input {
    background-color: transparent; }
  .uk-light .uk-search-toggle, .uk-section-primary:not(.uk-preserve-color) .uk-search-toggle, .uk-section-secondary:not(.uk-preserve-color) .uk-search-toggle, .uk-tile-primary:not(.uk-preserve-color) .uk-search-toggle, .uk-tile-secondary:not(.uk-preserve-color) .uk-search-toggle, .uk-card-primary.uk-card-body .uk-search-toggle, .uk-card-primary > :not([class*='uk-card-media']) .uk-search-toggle, .uk-card-secondary.uk-card-body .uk-search-toggle, .uk-card-secondary > :not([class*='uk-card-media']) .uk-search-toggle, .uk-overlay-primary .uk-search-toggle, .uk-offcanvas-bar .uk-search-toggle {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-search-toggle:hover, .uk-section-primary:not(.uk-preserve-color) .uk-search-toggle:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-search-toggle:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-search-toggle:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-search-toggle:hover, .uk-card-primary.uk-card-body .uk-search-toggle:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-search-toggle:hover, .uk-card-secondary.uk-card-body .uk-search-toggle:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-search-toggle:hover, .uk-overlay-primary .uk-search-toggle:hover, .uk-offcanvas-bar .uk-search-toggle:hover {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-nav-default > li > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default > li > a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-default > li > a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-default > li > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default > li > a, .uk-card-primary.uk-card-body .uk-nav-default > li > a, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-default > li > a, .uk-card-secondary.uk-card-body .uk-nav-default > li > a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-default > li > a, .uk-overlay-primary .uk-nav-default > li > a, .uk-offcanvas-bar .uk-nav-default > li > a {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-nav-default > li > a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default > li > a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-default > li > a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-default > li > a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default > li > a:hover, .uk-card-primary.uk-card-body .uk-nav-default > li > a:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-default > li > a:hover, .uk-card-secondary.uk-card-body .uk-nav-default > li > a:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-default > li > a:hover, .uk-overlay-primary .uk-nav-default > li > a:hover, .uk-offcanvas-bar .uk-nav-default > li > a:hover {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-nav-default > li.uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default > li.uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-default > li.uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-default > li.uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default > li.uk-active > a, .uk-card-primary.uk-card-body .uk-nav-default > li.uk-active > a, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-default > li.uk-active > a, .uk-card-secondary.uk-card-body .uk-nav-default > li.uk-active > a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-default > li.uk-active > a, .uk-overlay-primary .uk-nav-default > li.uk-active > a, .uk-offcanvas-bar .uk-nav-default > li.uk-active > a {
    color: #fff; }
  .uk-light .uk-nav-default .uk-nav-header, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-header, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-header, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-header, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-header, .uk-card-primary.uk-card-body .uk-nav-default .uk-nav-header, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-default .uk-nav-header, .uk-card-secondary.uk-card-body .uk-nav-default .uk-nav-header, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-default .uk-nav-header, .uk-overlay-primary .uk-nav-default .uk-nav-header, .uk-offcanvas-bar .uk-nav-default .uk-nav-header {
    color: #fff; }
  .uk-light .uk-nav-default .uk-nav-divider, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-divider, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-divider, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-divider, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-divider, .uk-card-primary.uk-card-body .uk-nav-default .uk-nav-divider, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-default .uk-nav-divider, .uk-card-secondary.uk-card-body .uk-nav-default .uk-nav-divider, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-default .uk-nav-divider, .uk-overlay-primary .uk-nav-default .uk-nav-divider, .uk-offcanvas-bar .uk-nav-default .uk-nav-divider {
    border-top-color: rgba(255, 255, 255, 0.2); }
  .uk-light .uk-nav-default .uk-nav-sub a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a, .uk-card-primary.uk-card-body .uk-nav-default .uk-nav-sub a, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-default .uk-nav-sub a, .uk-card-secondary.uk-card-body .uk-nav-default .uk-nav-sub a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-default .uk-nav-sub a, .uk-overlay-primary .uk-nav-default .uk-nav-sub a, .uk-offcanvas-bar .uk-nav-default .uk-nav-sub a {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-nav-default .uk-nav-sub a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a:hover, .uk-card-primary.uk-card-body .uk-nav-default .uk-nav-sub a:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-default .uk-nav-sub a:hover, .uk-card-secondary.uk-card-body .uk-nav-default .uk-nav-sub a:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-default .uk-nav-sub a:hover, .uk-overlay-primary .uk-nav-default .uk-nav-sub a:hover, .uk-offcanvas-bar .uk-nav-default .uk-nav-sub a:hover {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-card-primary.uk-card-body .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-card-secondary.uk-card-body .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-overlay-primary .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-offcanvas-bar .uk-nav-default .uk-nav-sub li.uk-active > a {
    color: #fff; }
  .uk-light .uk-nav-primary > li > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary > li > a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary > li > a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary > li > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary > li > a, .uk-card-primary.uk-card-body .uk-nav-primary > li > a, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-primary > li > a, .uk-card-secondary.uk-card-body .uk-nav-primary > li > a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-primary > li > a, .uk-overlay-primary .uk-nav-primary > li > a, .uk-offcanvas-bar .uk-nav-primary > li > a {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-nav-primary > li > a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary > li > a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary > li > a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary > li > a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary > li > a:hover, .uk-card-primary.uk-card-body .uk-nav-primary > li > a:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-primary > li > a:hover, .uk-card-secondary.uk-card-body .uk-nav-primary > li > a:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-primary > li > a:hover, .uk-overlay-primary .uk-nav-primary > li > a:hover, .uk-offcanvas-bar .uk-nav-primary > li > a:hover {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-nav-primary > li.uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary > li.uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary > li.uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary > li.uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary > li.uk-active > a, .uk-card-primary.uk-card-body .uk-nav-primary > li.uk-active > a, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-primary > li.uk-active > a, .uk-card-secondary.uk-card-body .uk-nav-primary > li.uk-active > a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-primary > li.uk-active > a, .uk-overlay-primary .uk-nav-primary > li.uk-active > a, .uk-offcanvas-bar .uk-nav-primary > li.uk-active > a {
    color: #fff; }
  .uk-light .uk-nav-primary .uk-nav-header, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-header, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-header, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-header, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-header, .uk-card-primary.uk-card-body .uk-nav-primary .uk-nav-header, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-primary .uk-nav-header, .uk-card-secondary.uk-card-body .uk-nav-primary .uk-nav-header, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-primary .uk-nav-header, .uk-overlay-primary .uk-nav-primary .uk-nav-header, .uk-offcanvas-bar .uk-nav-primary .uk-nav-header {
    color: #fff; }
  .uk-light .uk-nav-primary .uk-nav-divider, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-divider, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-divider, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-divider, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-divider, .uk-card-primary.uk-card-body .uk-nav-primary .uk-nav-divider, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-primary .uk-nav-divider, .uk-card-secondary.uk-card-body .uk-nav-primary .uk-nav-divider, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-primary .uk-nav-divider, .uk-overlay-primary .uk-nav-primary .uk-nav-divider, .uk-offcanvas-bar .uk-nav-primary .uk-nav-divider {
    border-top-color: rgba(255, 255, 255, 0.2); }
  .uk-light .uk-nav-primary .uk-nav-sub a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a, .uk-card-primary.uk-card-body .uk-nav-primary .uk-nav-sub a, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-primary .uk-nav-sub a, .uk-card-secondary.uk-card-body .uk-nav-primary .uk-nav-sub a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-primary .uk-nav-sub a, .uk-overlay-primary .uk-nav-primary .uk-nav-sub a, .uk-offcanvas-bar .uk-nav-primary .uk-nav-sub a {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-nav-primary .uk-nav-sub a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a:hover, .uk-card-primary.uk-card-body .uk-nav-primary .uk-nav-sub a:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-primary .uk-nav-sub a:hover, .uk-card-secondary.uk-card-body .uk-nav-primary .uk-nav-sub a:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-primary .uk-nav-sub a:hover, .uk-overlay-primary .uk-nav-primary .uk-nav-sub a:hover, .uk-offcanvas-bar .uk-nav-primary .uk-nav-sub a:hover {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-card-primary.uk-card-body .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-card-secondary.uk-card-body .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-overlay-primary .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-offcanvas-bar .uk-nav-primary .uk-nav-sub li.uk-active > a {
    color: #fff; }
  .uk-light .uk-nav-secondary > li > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary > li > a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-secondary > li > a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-secondary > li > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-secondary > li > a, .uk-card-primary.uk-card-body .uk-nav-secondary > li > a, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-secondary > li > a, .uk-card-secondary.uk-card-body .uk-nav-secondary > li > a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-secondary > li > a, .uk-overlay-primary .uk-nav-secondary > li > a, .uk-offcanvas-bar .uk-nav-secondary > li > a {
    color: #fff; }
  .uk-light .uk-nav-secondary > li > a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary > li > a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-secondary > li > a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-secondary > li > a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-secondary > li > a:hover, .uk-card-primary.uk-card-body .uk-nav-secondary > li > a:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-secondary > li > a:hover, .uk-card-secondary.uk-card-body .uk-nav-secondary > li > a:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-secondary > li > a:hover, .uk-overlay-primary .uk-nav-secondary > li > a:hover, .uk-offcanvas-bar .uk-nav-secondary > li > a:hover {
    color: #fff; }
  .uk-light .uk-nav-secondary > li.uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary > li.uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-secondary > li.uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-secondary > li.uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-secondary > li.uk-active > a, .uk-card-primary.uk-card-body .uk-nav-secondary > li.uk-active > a, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-secondary > li.uk-active > a, .uk-card-secondary.uk-card-body .uk-nav-secondary > li.uk-active > a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-secondary > li.uk-active > a, .uk-overlay-primary .uk-nav-secondary > li.uk-active > a, .uk-offcanvas-bar .uk-nav-secondary > li.uk-active > a {
    color: #fff; }
  .uk-light .uk-nav-secondary .uk-nav-subtitle, .uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-subtitle, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-subtitle, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-subtitle, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-subtitle, .uk-card-primary.uk-card-body .uk-nav-secondary .uk-nav-subtitle, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-secondary .uk-nav-subtitle, .uk-card-secondary.uk-card-body .uk-nav-secondary .uk-nav-subtitle, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-secondary .uk-nav-subtitle, .uk-overlay-primary .uk-nav-secondary .uk-nav-subtitle, .uk-offcanvas-bar .uk-nav-secondary .uk-nav-subtitle {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-nav-secondary > li > a:hover .uk-nav-subtitle, .uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary > li > a:hover .uk-nav-subtitle, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-secondary > li > a:hover .uk-nav-subtitle, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-secondary > li > a:hover .uk-nav-subtitle, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-secondary > li > a:hover .uk-nav-subtitle, .uk-card-primary.uk-card-body .uk-nav-secondary > li > a:hover .uk-nav-subtitle, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-secondary > li > a:hover .uk-nav-subtitle, .uk-card-secondary.uk-card-body .uk-nav-secondary > li > a:hover .uk-nav-subtitle, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-secondary > li > a:hover .uk-nav-subtitle, .uk-overlay-primary .uk-nav-secondary > li > a:hover .uk-nav-subtitle, .uk-offcanvas-bar .uk-nav-secondary > li > a:hover .uk-nav-subtitle {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-nav-secondary > li.uk-active > a .uk-nav-subtitle, .uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary > li.uk-active > a .uk-nav-subtitle, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-secondary > li.uk-active > a .uk-nav-subtitle, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-secondary > li.uk-active > a .uk-nav-subtitle, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-secondary > li.uk-active > a .uk-nav-subtitle, .uk-card-primary.uk-card-body .uk-nav-secondary > li.uk-active > a .uk-nav-subtitle, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-secondary > li.uk-active > a .uk-nav-subtitle, .uk-card-secondary.uk-card-body .uk-nav-secondary > li.uk-active > a .uk-nav-subtitle, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-secondary > li.uk-active > a .uk-nav-subtitle, .uk-overlay-primary .uk-nav-secondary > li.uk-active > a .uk-nav-subtitle, .uk-offcanvas-bar .uk-nav-secondary > li.uk-active > a .uk-nav-subtitle {
    color: #fff; }
  .uk-light .uk-nav-secondary .uk-nav-header, .uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-header, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-header, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-header, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-header, .uk-card-primary.uk-card-body .uk-nav-secondary .uk-nav-header, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-secondary .uk-nav-header, .uk-card-secondary.uk-card-body .uk-nav-secondary .uk-nav-header, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-secondary .uk-nav-header, .uk-overlay-primary .uk-nav-secondary .uk-nav-header, .uk-offcanvas-bar .uk-nav-secondary .uk-nav-header {
    color: #fff; }
  .uk-light .uk-nav-secondary .uk-nav-divider, .uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-divider, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-divider, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-divider, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-divider, .uk-card-primary.uk-card-body .uk-nav-secondary .uk-nav-divider, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-secondary .uk-nav-divider, .uk-card-secondary.uk-card-body .uk-nav-secondary .uk-nav-divider, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-secondary .uk-nav-divider, .uk-overlay-primary .uk-nav-secondary .uk-nav-divider, .uk-offcanvas-bar .uk-nav-secondary .uk-nav-divider {
    border-top-color: rgba(255, 255, 255, 0.2); }
  .uk-light .uk-nav-secondary .uk-nav-sub a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub a, .uk-card-primary.uk-card-body .uk-nav-secondary .uk-nav-sub a, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-secondary .uk-nav-sub a, .uk-card-secondary.uk-card-body .uk-nav-secondary .uk-nav-sub a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-secondary .uk-nav-sub a, .uk-overlay-primary .uk-nav-secondary .uk-nav-sub a, .uk-offcanvas-bar .uk-nav-secondary .uk-nav-sub a {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-nav-secondary .uk-nav-sub a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub a:hover, .uk-card-primary.uk-card-body .uk-nav-secondary .uk-nav-sub a:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-secondary .uk-nav-sub a:hover, .uk-card-secondary.uk-card-body .uk-nav-secondary .uk-nav-sub a:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-secondary .uk-nav-sub a:hover, .uk-overlay-primary .uk-nav-secondary .uk-nav-sub a:hover, .uk-offcanvas-bar .uk-nav-secondary .uk-nav-sub a:hover {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-nav-secondary .uk-nav-sub li.uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub li.uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub li.uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub li.uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub li.uk-active > a, .uk-card-primary.uk-card-body .uk-nav-secondary .uk-nav-sub li.uk-active > a, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-secondary .uk-nav-sub li.uk-active > a, .uk-card-secondary.uk-card-body .uk-nav-secondary .uk-nav-sub li.uk-active > a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-secondary .uk-nav-sub li.uk-active > a, .uk-overlay-primary .uk-nav-secondary .uk-nav-sub li.uk-active > a, .uk-offcanvas-bar .uk-nav-secondary .uk-nav-sub li.uk-active > a {
    color: #fff; }
  .uk-light .uk-nav.uk-nav-divider > :not(.uk-nav-divider) +  , .uk-section-primary:not(.uk-preserve-color) .uk-nav.uk-nav-divider > :not(.uk-nav-divider) +  , .uk-section-secondary:not(.uk-preserve-color) .uk-nav.uk-nav-divider > :not(.uk-nav-divider) +  , .uk-tile-primary:not(.uk-preserve-color) .uk-nav.uk-nav-divider > :not(.uk-nav-divider) +  , .uk-tile-secondary:not(.uk-preserve-color) .uk-nav.uk-nav-divider > :not(.uk-nav-divider) +  , .uk-card-primary.uk-card-body .uk-nav.uk-nav-divider > :not(.uk-nav-divider) +  , .uk-card-primary > :not([class*='uk-card-media']) .uk-nav.uk-nav-divider > :not(.uk-nav-divider) +  , .uk-card-secondary.uk-card-body .uk-nav.uk-nav-divider > :not(.uk-nav-divider) +  , .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav.uk-nav-divider > :not(.uk-nav-divider) +  , .uk-overlay-primary .uk-nav.uk-nav-divider > :not(.uk-nav-divider) +  , .uk-offcanvas-bar .uk-nav.uk-nav-divider > :not(.uk-nav-divider) +  {
    border-top-color: rgba(255, 255, 255, 0.2); }
  .uk-light .uk-navbar-nav > li > a, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-nav > li > a, .uk-section-secondary:not(.uk-preserve-color) .uk-navbar-nav > li > a, .uk-tile-primary:not(.uk-preserve-color) .uk-navbar-nav > li > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-nav > li > a, .uk-card-primary.uk-card-body .uk-navbar-nav > li > a, .uk-card-primary > :not([class*='uk-card-media']) .uk-navbar-nav > li > a, .uk-card-secondary.uk-card-body .uk-navbar-nav > li > a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-navbar-nav > li > a, .uk-overlay-primary .uk-navbar-nav > li > a, .uk-offcanvas-bar .uk-navbar-nav > li > a {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-navbar-nav > li:hover > a, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-nav > li:hover > a, .uk-section-secondary:not(.uk-preserve-color) .uk-navbar-nav > li:hover > a, .uk-tile-primary:not(.uk-preserve-color) .uk-navbar-nav > li:hover > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-nav > li:hover > a, .uk-card-primary.uk-card-body .uk-navbar-nav > li:hover > a, .uk-card-primary > :not([class*='uk-card-media']) .uk-navbar-nav > li:hover > a, .uk-card-secondary.uk-card-body .uk-navbar-nav > li:hover > a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-navbar-nav > li:hover > a, .uk-overlay-primary .uk-navbar-nav > li:hover > a, .uk-offcanvas-bar .uk-navbar-nav > li:hover > a,
  .uk-light .uk-navbar-nav > li > a[aria-expanded="true"],
  .uk-section-primary:not(.uk-preserve-color) .uk-navbar-nav > li > a[aria-expanded="true"],
  .uk-section-secondary:not(.uk-preserve-color) .uk-navbar-nav > li > a[aria-expanded="true"],
  .uk-tile-primary:not(.uk-preserve-color) .uk-navbar-nav > li > a[aria-expanded="true"],
  .uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-nav > li > a[aria-expanded="true"],
  .uk-card-primary.uk-card-body .uk-navbar-nav > li > a[aria-expanded="true"],
  .uk-card-primary > :not([class*='uk-card-media']) .uk-navbar-nav > li > a[aria-expanded="true"],
  .uk-card-secondary.uk-card-body .uk-navbar-nav > li > a[aria-expanded="true"],
  .uk-card-secondary > :not([class*='uk-card-media']) .uk-navbar-nav > li > a[aria-expanded="true"],
  .uk-overlay-primary .uk-navbar-nav > li > a[aria-expanded="true"],
  .uk-offcanvas-bar .uk-navbar-nav > li > a[aria-expanded="true"] {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-navbar-nav > li > a:active, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-nav > li > a:active, .uk-section-secondary:not(.uk-preserve-color) .uk-navbar-nav > li > a:active, .uk-tile-primary:not(.uk-preserve-color) .uk-navbar-nav > li > a:active, .uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-nav > li > a:active, .uk-card-primary.uk-card-body .uk-navbar-nav > li > a:active, .uk-card-primary > :not([class*='uk-card-media']) .uk-navbar-nav > li > a:active, .uk-card-secondary.uk-card-body .uk-navbar-nav > li > a:active, .uk-card-secondary > :not([class*='uk-card-media']) .uk-navbar-nav > li > a:active, .uk-overlay-primary .uk-navbar-nav > li > a:active, .uk-offcanvas-bar .uk-navbar-nav > li > a:active {
    color: #fff; }
  .uk-light .uk-navbar-nav > li.uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-nav > li.uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-navbar-nav > li.uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-navbar-nav > li.uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-nav > li.uk-active > a, .uk-card-primary.uk-card-body .uk-navbar-nav > li.uk-active > a, .uk-card-primary > :not([class*='uk-card-media']) .uk-navbar-nav > li.uk-active > a, .uk-card-secondary.uk-card-body .uk-navbar-nav > li.uk-active > a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-navbar-nav > li.uk-active > a, .uk-overlay-primary .uk-navbar-nav > li.uk-active > a, .uk-offcanvas-bar .uk-navbar-nav > li.uk-active > a {
    color: #fff; }
  .uk-light .uk-navbar-item, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-item, .uk-section-secondary:not(.uk-preserve-color) .uk-navbar-item, .uk-tile-primary:not(.uk-preserve-color) .uk-navbar-item, .uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-item, .uk-card-primary.uk-card-body .uk-navbar-item, .uk-card-primary > :not([class*='uk-card-media']) .uk-navbar-item, .uk-card-secondary.uk-card-body .uk-navbar-item, .uk-card-secondary > :not([class*='uk-card-media']) .uk-navbar-item, .uk-overlay-primary .uk-navbar-item, .uk-offcanvas-bar .uk-navbar-item {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-navbar-toggle, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-toggle, .uk-section-secondary:not(.uk-preserve-color) .uk-navbar-toggle, .uk-tile-primary:not(.uk-preserve-color) .uk-navbar-toggle, .uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-toggle, .uk-card-primary.uk-card-body .uk-navbar-toggle, .uk-card-primary > :not([class*='uk-card-media']) .uk-navbar-toggle, .uk-card-secondary.uk-card-body .uk-navbar-toggle, .uk-card-secondary > :not([class*='uk-card-media']) .uk-navbar-toggle, .uk-overlay-primary .uk-navbar-toggle, .uk-offcanvas-bar .uk-navbar-toggle {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-navbar-toggle:hover, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-toggle:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-navbar-toggle:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-navbar-toggle:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-toggle:hover, .uk-card-primary.uk-card-body .uk-navbar-toggle:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-navbar-toggle:hover, .uk-card-secondary.uk-card-body .uk-navbar-toggle:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-navbar-toggle:hover, .uk-overlay-primary .uk-navbar-toggle:hover, .uk-offcanvas-bar .uk-navbar-toggle:hover,
  .uk-light .uk-navbar-toggle[aria-expanded="true"],
  .uk-section-primary:not(.uk-preserve-color) .uk-navbar-toggle[aria-expanded="true"],
  .uk-section-secondary:not(.uk-preserve-color) .uk-navbar-toggle[aria-expanded="true"],
  .uk-tile-primary:not(.uk-preserve-color) .uk-navbar-toggle[aria-expanded="true"],
  .uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-toggle[aria-expanded="true"],
  .uk-card-primary.uk-card-body .uk-navbar-toggle[aria-expanded="true"],
  .uk-card-primary > :not([class*='uk-card-media']) .uk-navbar-toggle[aria-expanded="true"],
  .uk-card-secondary.uk-card-body .uk-navbar-toggle[aria-expanded="true"],
  .uk-card-secondary > :not([class*='uk-card-media']) .uk-navbar-toggle[aria-expanded="true"],
  .uk-overlay-primary .uk-navbar-toggle[aria-expanded="true"],
  .uk-offcanvas-bar .uk-navbar-toggle[aria-expanded="true"] {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-subnav > * > :first-child, .uk-section-primary:not(.uk-preserve-color) .uk-subnav > * > :first-child, .uk-section-secondary:not(.uk-preserve-color) .uk-subnav > * > :first-child, .uk-tile-primary:not(.uk-preserve-color) .uk-subnav > * > :first-child, .uk-tile-secondary:not(.uk-preserve-color) .uk-subnav > * > :first-child, .uk-card-primary.uk-card-body .uk-subnav > * > :first-child, .uk-card-primary > :not([class*='uk-card-media']) .uk-subnav > * > :first-child, .uk-card-secondary.uk-card-body .uk-subnav > * > :first-child, .uk-card-secondary > :not([class*='uk-card-media']) .uk-subnav > * > :first-child, .uk-overlay-primary .uk-subnav > * > :first-child, .uk-offcanvas-bar .uk-subnav > * > :first-child {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-subnav > * > a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-subnav > * > a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-subnav > * > a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-subnav > * > a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-subnav > * > a:hover, .uk-card-primary.uk-card-body .uk-subnav > * > a:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-subnav > * > a:hover, .uk-card-secondary.uk-card-body .uk-subnav > * > a:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-subnav > * > a:hover, .uk-overlay-primary .uk-subnav > * > a:hover, .uk-offcanvas-bar .uk-subnav > * > a:hover {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-subnav > .uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-subnav > .uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-subnav > .uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-subnav > .uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-subnav > .uk-active > a, .uk-card-primary.uk-card-body .uk-subnav > .uk-active > a, .uk-card-primary > :not([class*='uk-card-media']) .uk-subnav > .uk-active > a, .uk-card-secondary.uk-card-body .uk-subnav > .uk-active > a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-subnav > .uk-active > a, .uk-overlay-primary .uk-subnav > .uk-active > a, .uk-offcanvas-bar .uk-subnav > .uk-active > a {
    color: #fff; }
  .uk-light .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-section-primary:not(.uk-preserve-color) .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-section-secondary:not(.uk-preserve-color) .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-tile-primary:not(.uk-preserve-color) .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-card-primary.uk-card-body .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-card-primary > :not([class*='uk-card-media']) .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-card-secondary.uk-card-body .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-card-secondary > :not([class*='uk-card-media']) .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-overlay-primary .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-offcanvas-bar .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before {
    border-left-color: rgba(255, 255, 255, 0.2); }
  .uk-light .uk-subnav-pill > * > :first-child, .uk-section-primary:not(.uk-preserve-color) .uk-subnav-pill > * > :first-child, .uk-section-secondary:not(.uk-preserve-color) .uk-subnav-pill > * > :first-child, .uk-tile-primary:not(.uk-preserve-color) .uk-subnav-pill > * > :first-child, .uk-tile-secondary:not(.uk-preserve-color) .uk-subnav-pill > * > :first-child, .uk-card-primary.uk-card-body .uk-subnav-pill > * > :first-child, .uk-card-primary > :not([class*='uk-card-media']) .uk-subnav-pill > * > :first-child, .uk-card-secondary.uk-card-body .uk-subnav-pill > * > :first-child, .uk-card-secondary > :not([class*='uk-card-media']) .uk-subnav-pill > * > :first-child, .uk-overlay-primary .uk-subnav-pill > * > :first-child, .uk-offcanvas-bar .uk-subnav-pill > * > :first-child {
    background-color: transparent;
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-subnav-pill > * > a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-subnav-pill > * > a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-subnav-pill > * > a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-subnav-pill > * > a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-subnav-pill > * > a:hover, .uk-card-primary.uk-card-body .uk-subnav-pill > * > a:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-subnav-pill > * > a:hover, .uk-card-secondary.uk-card-body .uk-subnav-pill > * > a:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-subnav-pill > * > a:hover, .uk-overlay-primary .uk-subnav-pill > * > a:hover, .uk-offcanvas-bar .uk-subnav-pill > * > a:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-subnav-pill > * > a:active, .uk-section-primary:not(.uk-preserve-color) .uk-subnav-pill > * > a:active, .uk-section-secondary:not(.uk-preserve-color) .uk-subnav-pill > * > a:active, .uk-tile-primary:not(.uk-preserve-color) .uk-subnav-pill > * > a:active, .uk-tile-secondary:not(.uk-preserve-color) .uk-subnav-pill > * > a:active, .uk-card-primary.uk-card-body .uk-subnav-pill > * > a:active, .uk-card-primary > :not([class*='uk-card-media']) .uk-subnav-pill > * > a:active, .uk-card-secondary.uk-card-body .uk-subnav-pill > * > a:active, .uk-card-secondary > :not([class*='uk-card-media']) .uk-subnav-pill > * > a:active, .uk-overlay-primary .uk-subnav-pill > * > a:active, .uk-offcanvas-bar .uk-subnav-pill > * > a:active {
    background-color: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-subnav-pill > .uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-subnav-pill > .uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-subnav-pill > .uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-subnav-pill > .uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-subnav-pill > .uk-active > a, .uk-card-primary.uk-card-body .uk-subnav-pill > .uk-active > a, .uk-card-primary > :not([class*='uk-card-media']) .uk-subnav-pill > .uk-active > a, .uk-card-secondary.uk-card-body .uk-subnav-pill > .uk-active > a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-subnav-pill > .uk-active > a, .uk-overlay-primary .uk-subnav-pill > .uk-active > a, .uk-offcanvas-bar .uk-subnav-pill > .uk-active > a {
    background-color: #fff;
    color: #666; }
  .uk-light .uk-subnav > .uk-disabled > a, .uk-section-primary:not(.uk-preserve-color) .uk-subnav > .uk-disabled > a, .uk-section-secondary:not(.uk-preserve-color) .uk-subnav > .uk-disabled > a, .uk-tile-primary:not(.uk-preserve-color) .uk-subnav > .uk-disabled > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-subnav > .uk-disabled > a, .uk-card-primary.uk-card-body .uk-subnav > .uk-disabled > a, .uk-card-primary > :not([class*='uk-card-media']) .uk-subnav > .uk-disabled > a, .uk-card-secondary.uk-card-body .uk-subnav > .uk-disabled > a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-subnav > .uk-disabled > a, .uk-overlay-primary .uk-subnav > .uk-disabled > a, .uk-offcanvas-bar .uk-subnav > .uk-disabled > a {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-breadcrumb > * > *, .uk-section-primary:not(.uk-preserve-color) .uk-breadcrumb > * > *, .uk-section-secondary:not(.uk-preserve-color) .uk-breadcrumb > * > *, .uk-tile-primary:not(.uk-preserve-color) .uk-breadcrumb > * > *, .uk-tile-secondary:not(.uk-preserve-color) .uk-breadcrumb > * > *, .uk-card-primary.uk-card-body .uk-breadcrumb > * > *, .uk-card-primary > :not([class*='uk-card-media']) .uk-breadcrumb > * > *, .uk-card-secondary.uk-card-body .uk-breadcrumb > * > *, .uk-card-secondary > :not([class*='uk-card-media']) .uk-breadcrumb > * > *, .uk-overlay-primary .uk-breadcrumb > * > *, .uk-offcanvas-bar .uk-breadcrumb > * > * {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-breadcrumb > * > :hover, .uk-section-primary:not(.uk-preserve-color) .uk-breadcrumb > * > :hover, .uk-section-secondary:not(.uk-preserve-color) .uk-breadcrumb > * > :hover, .uk-tile-primary:not(.uk-preserve-color) .uk-breadcrumb > * > :hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-breadcrumb > * > :hover, .uk-card-primary.uk-card-body .uk-breadcrumb > * > :hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-breadcrumb > * > :hover, .uk-card-secondary.uk-card-body .uk-breadcrumb > * > :hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-breadcrumb > * > :hover, .uk-overlay-primary .uk-breadcrumb > * > :hover, .uk-offcanvas-bar .uk-breadcrumb > * > :hover {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-breadcrumb > :last-child > *, .uk-section-primary:not(.uk-preserve-color) .uk-breadcrumb > :last-child > *, .uk-section-secondary:not(.uk-preserve-color) .uk-breadcrumb > :last-child > *, .uk-tile-primary:not(.uk-preserve-color) .uk-breadcrumb > :last-child > *, .uk-tile-secondary:not(.uk-preserve-color) .uk-breadcrumb > :last-child > *, .uk-card-primary.uk-card-body .uk-breadcrumb > :last-child > *, .uk-card-primary > :not([class*='uk-card-media']) .uk-breadcrumb > :last-child > *, .uk-card-secondary.uk-card-body .uk-breadcrumb > :last-child > *, .uk-card-secondary > :not([class*='uk-card-media']) .uk-breadcrumb > :last-child > *, .uk-overlay-primary .uk-breadcrumb > :last-child > *, .uk-offcanvas-bar .uk-breadcrumb > :last-child > * {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-section-primary:not(.uk-preserve-color) .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-section-secondary:not(.uk-preserve-color) .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-tile-primary:not(.uk-preserve-color) .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-card-primary.uk-card-body .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-card-primary > :not([class*='uk-card-media']) .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-card-secondary.uk-card-body .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-card-secondary > :not([class*='uk-card-media']) .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-overlay-primary .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-offcanvas-bar .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-pagination > * > *, .uk-section-primary:not(.uk-preserve-color) .uk-pagination > * > *, .uk-section-secondary:not(.uk-preserve-color) .uk-pagination > * > *, .uk-tile-primary:not(.uk-preserve-color) .uk-pagination > * > *, .uk-tile-secondary:not(.uk-preserve-color) .uk-pagination > * > *, .uk-card-primary.uk-card-body .uk-pagination > * > *, .uk-card-primary > :not([class*='uk-card-media']) .uk-pagination > * > *, .uk-card-secondary.uk-card-body .uk-pagination > * > *, .uk-card-secondary > :not([class*='uk-card-media']) .uk-pagination > * > *, .uk-overlay-primary .uk-pagination > * > *, .uk-offcanvas-bar .uk-pagination > * > * {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-pagination > * > :hover, .uk-section-primary:not(.uk-preserve-color) .uk-pagination > * > :hover, .uk-section-secondary:not(.uk-preserve-color) .uk-pagination > * > :hover, .uk-tile-primary:not(.uk-preserve-color) .uk-pagination > * > :hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-pagination > * > :hover, .uk-card-primary.uk-card-body .uk-pagination > * > :hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-pagination > * > :hover, .uk-card-secondary.uk-card-body .uk-pagination > * > :hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-pagination > * > :hover, .uk-overlay-primary .uk-pagination > * > :hover, .uk-offcanvas-bar .uk-pagination > * > :hover {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-pagination > .uk-active > *, .uk-section-primary:not(.uk-preserve-color) .uk-pagination > .uk-active > *, .uk-section-secondary:not(.uk-preserve-color) .uk-pagination > .uk-active > *, .uk-tile-primary:not(.uk-preserve-color) .uk-pagination > .uk-active > *, .uk-tile-secondary:not(.uk-preserve-color) .uk-pagination > .uk-active > *, .uk-card-primary.uk-card-body .uk-pagination > .uk-active > *, .uk-card-primary > :not([class*='uk-card-media']) .uk-pagination > .uk-active > *, .uk-card-secondary.uk-card-body .uk-pagination > .uk-active > *, .uk-card-secondary > :not([class*='uk-card-media']) .uk-pagination > .uk-active > *, .uk-overlay-primary .uk-pagination > .uk-active > *, .uk-offcanvas-bar .uk-pagination > .uk-active > * {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-pagination > .uk-disabled > *, .uk-section-primary:not(.uk-preserve-color) .uk-pagination > .uk-disabled > *, .uk-section-secondary:not(.uk-preserve-color) .uk-pagination > .uk-disabled > *, .uk-tile-primary:not(.uk-preserve-color) .uk-pagination > .uk-disabled > *, .uk-tile-secondary:not(.uk-preserve-color) .uk-pagination > .uk-disabled > *, .uk-card-primary.uk-card-body .uk-pagination > .uk-disabled > *, .uk-card-primary > :not([class*='uk-card-media']) .uk-pagination > .uk-disabled > *, .uk-card-secondary.uk-card-body .uk-pagination > .uk-disabled > *, .uk-card-secondary > :not([class*='uk-card-media']) .uk-pagination > .uk-disabled > *, .uk-overlay-primary .uk-pagination > .uk-disabled > *, .uk-offcanvas-bar .uk-pagination > .uk-disabled > * {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-tab > * > a, .uk-section-primary:not(.uk-preserve-color) .uk-tab > * > a, .uk-section-secondary:not(.uk-preserve-color) .uk-tab > * > a, .uk-tile-primary:not(.uk-preserve-color) .uk-tab > * > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-tab > * > a, .uk-card-primary.uk-card-body .uk-tab > * > a, .uk-card-primary > :not([class*='uk-card-media']) .uk-tab > * > a, .uk-card-secondary.uk-card-body .uk-tab > * > a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-tab > * > a, .uk-overlay-primary .uk-tab > * > a, .uk-offcanvas-bar .uk-tab > * > a {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-tab > * > a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-tab > * > a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-tab > * > a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-tab > * > a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-tab > * > a:hover, .uk-card-primary.uk-card-body .uk-tab > * > a:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-tab > * > a:hover, .uk-card-secondary.uk-card-body .uk-tab > * > a:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-tab > * > a:hover, .uk-overlay-primary .uk-tab > * > a:hover, .uk-offcanvas-bar .uk-tab > * > a:hover {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-tab > .uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-tab > .uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-tab > .uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-tab > .uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-tab > .uk-active > a, .uk-card-primary.uk-card-body .uk-tab > .uk-active > a, .uk-card-primary > :not([class*='uk-card-media']) .uk-tab > .uk-active > a, .uk-card-secondary.uk-card-body .uk-tab > .uk-active > a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-tab > .uk-active > a, .uk-overlay-primary .uk-tab > .uk-active > a, .uk-offcanvas-bar .uk-tab > .uk-active > a {
    color: #fff; }
  .uk-light .uk-tab > .uk-disabled > a, .uk-section-primary:not(.uk-preserve-color) .uk-tab > .uk-disabled > a, .uk-section-secondary:not(.uk-preserve-color) .uk-tab > .uk-disabled > a, .uk-tile-primary:not(.uk-preserve-color) .uk-tab > .uk-disabled > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-tab > .uk-disabled > a, .uk-card-primary.uk-card-body .uk-tab > .uk-disabled > a, .uk-card-primary > :not([class*='uk-card-media']) .uk-tab > .uk-disabled > a, .uk-card-secondary.uk-card-body .uk-tab > .uk-disabled > a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-tab > .uk-disabled > a, .uk-overlay-primary .uk-tab > .uk-disabled > a, .uk-offcanvas-bar .uk-tab > .uk-disabled > a {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-slidenav, .uk-section-primary:not(.uk-preserve-color) .uk-slidenav, .uk-section-secondary:not(.uk-preserve-color) .uk-slidenav, .uk-tile-primary:not(.uk-preserve-color) .uk-slidenav, .uk-tile-secondary:not(.uk-preserve-color) .uk-slidenav, .uk-card-primary.uk-card-body .uk-slidenav, .uk-card-primary > :not([class*='uk-card-media']) .uk-slidenav, .uk-card-secondary.uk-card-body .uk-slidenav, .uk-card-secondary > :not([class*='uk-card-media']) .uk-slidenav, .uk-overlay-primary .uk-slidenav, .uk-offcanvas-bar .uk-slidenav {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-slidenav:hover, .uk-section-primary:not(.uk-preserve-color) .uk-slidenav:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-slidenav:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-slidenav:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-slidenav:hover, .uk-card-primary.uk-card-body .uk-slidenav:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-slidenav:hover, .uk-card-secondary.uk-card-body .uk-slidenav:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-slidenav:hover, .uk-overlay-primary .uk-slidenav:hover, .uk-offcanvas-bar .uk-slidenav:hover {
    color: rgba(255, 255, 255, 0.95); }
  .uk-light .uk-slidenav:active, .uk-section-primary:not(.uk-preserve-color) .uk-slidenav:active, .uk-section-secondary:not(.uk-preserve-color) .uk-slidenav:active, .uk-tile-primary:not(.uk-preserve-color) .uk-slidenav:active, .uk-tile-secondary:not(.uk-preserve-color) .uk-slidenav:active, .uk-card-primary.uk-card-body .uk-slidenav:active, .uk-card-primary > :not([class*='uk-card-media']) .uk-slidenav:active, .uk-card-secondary.uk-card-body .uk-slidenav:active, .uk-card-secondary > :not([class*='uk-card-media']) .uk-slidenav:active, .uk-overlay-primary .uk-slidenav:active, .uk-offcanvas-bar .uk-slidenav:active {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-dotnav > * > *, .uk-section-primary:not(.uk-preserve-color) .uk-dotnav > * > *, .uk-section-secondary:not(.uk-preserve-color) .uk-dotnav > * > *, .uk-tile-primary:not(.uk-preserve-color) .uk-dotnav > * > *, .uk-tile-secondary:not(.uk-preserve-color) .uk-dotnav > * > *, .uk-card-primary.uk-card-body .uk-dotnav > * > *, .uk-card-primary > :not([class*='uk-card-media']) .uk-dotnav > * > *, .uk-card-secondary.uk-card-body .uk-dotnav > * > *, .uk-card-secondary > :not([class*='uk-card-media']) .uk-dotnav > * > *, .uk-overlay-primary .uk-dotnav > * > *, .uk-offcanvas-bar .uk-dotnav > * > * {
    background-color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-dotnav > * > :hover, .uk-section-primary:not(.uk-preserve-color) .uk-dotnav > * > :hover, .uk-section-secondary:not(.uk-preserve-color) .uk-dotnav > * > :hover, .uk-tile-primary:not(.uk-preserve-color) .uk-dotnav > * > :hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-dotnav > * > :hover, .uk-card-primary.uk-card-body .uk-dotnav > * > :hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-dotnav > * > :hover, .uk-card-secondary.uk-card-body .uk-dotnav > * > :hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-dotnav > * > :hover, .uk-overlay-primary .uk-dotnav > * > :hover, .uk-offcanvas-bar .uk-dotnav > * > :hover {
    background-color: rgba(255, 255, 255, 0.9); }
  .uk-light .uk-dotnav > * > :active, .uk-section-primary:not(.uk-preserve-color) .uk-dotnav > * > :active, .uk-section-secondary:not(.uk-preserve-color) .uk-dotnav > * > :active, .uk-tile-primary:not(.uk-preserve-color) .uk-dotnav > * > :active, .uk-tile-secondary:not(.uk-preserve-color) .uk-dotnav > * > :active, .uk-card-primary.uk-card-body .uk-dotnav > * > :active, .uk-card-primary > :not([class*='uk-card-media']) .uk-dotnav > * > :active, .uk-card-secondary.uk-card-body .uk-dotnav > * > :active, .uk-card-secondary > :not([class*='uk-card-media']) .uk-dotnav > * > :active, .uk-overlay-primary .uk-dotnav > * > :active, .uk-offcanvas-bar .uk-dotnav > * > :active {
    background-color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-dotnav > .uk-active > *, .uk-section-primary:not(.uk-preserve-color) .uk-dotnav > .uk-active > *, .uk-section-secondary:not(.uk-preserve-color) .uk-dotnav > .uk-active > *, .uk-tile-primary:not(.uk-preserve-color) .uk-dotnav > .uk-active > *, .uk-tile-secondary:not(.uk-preserve-color) .uk-dotnav > .uk-active > *, .uk-card-primary.uk-card-body .uk-dotnav > .uk-active > *, .uk-card-primary > :not([class*='uk-card-media']) .uk-dotnav > .uk-active > *, .uk-card-secondary.uk-card-body .uk-dotnav > .uk-active > *, .uk-card-secondary > :not([class*='uk-card-media']) .uk-dotnav > .uk-active > *, .uk-overlay-primary .uk-dotnav > .uk-active > *, .uk-offcanvas-bar .uk-dotnav > .uk-active > * {
    background-color: rgba(255, 255, 255, 0.9); }
  .uk-light .uk-accordion-title, .uk-section-primary:not(.uk-preserve-color) .uk-accordion-title, .uk-section-secondary:not(.uk-preserve-color) .uk-accordion-title, .uk-tile-primary:not(.uk-preserve-color) .uk-accordion-title, .uk-tile-secondary:not(.uk-preserve-color) .uk-accordion-title, .uk-card-primary.uk-card-body .uk-accordion-title, .uk-card-primary > :not([class*='uk-card-media']) .uk-accordion-title, .uk-card-secondary.uk-card-body .uk-accordion-title, .uk-card-secondary > :not([class*='uk-card-media']) .uk-accordion-title, .uk-overlay-primary .uk-accordion-title, .uk-offcanvas-bar .uk-accordion-title {
    color: #fff; }
  .uk-light .uk-accordion-title:hover, .uk-section-primary:not(.uk-preserve-color) .uk-accordion-title:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-accordion-title:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-accordion-title:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-accordion-title:hover, .uk-card-primary.uk-card-body .uk-accordion-title:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-accordion-title:hover, .uk-card-secondary.uk-card-body .uk-accordion-title:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-accordion-title:hover, .uk-overlay-primary .uk-accordion-title:hover, .uk-offcanvas-bar .uk-accordion-title:hover {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-iconnav > * > a, .uk-section-primary:not(.uk-preserve-color) .uk-iconnav > * > a, .uk-section-secondary:not(.uk-preserve-color) .uk-iconnav > * > a, .uk-tile-primary:not(.uk-preserve-color) .uk-iconnav > * > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-iconnav > * > a, .uk-card-primary.uk-card-body .uk-iconnav > * > a, .uk-card-primary > :not([class*='uk-card-media']) .uk-iconnav > * > a, .uk-card-secondary.uk-card-body .uk-iconnav > * > a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-iconnav > * > a, .uk-overlay-primary .uk-iconnav > * > a, .uk-offcanvas-bar .uk-iconnav > * > a {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-iconnav > * > a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-iconnav > * > a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-iconnav > * > a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-iconnav > * > a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-iconnav > * > a:hover, .uk-card-primary.uk-card-body .uk-iconnav > * > a:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-iconnav > * > a:hover, .uk-card-secondary.uk-card-body .uk-iconnav > * > a:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-iconnav > * > a:hover, .uk-overlay-primary .uk-iconnav > * > a:hover, .uk-offcanvas-bar .uk-iconnav > * > a:hover {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-iconnav > .uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-iconnav > .uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-iconnav > .uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-iconnav > .uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-iconnav > .uk-active > a, .uk-card-primary.uk-card-body .uk-iconnav > .uk-active > a, .uk-card-primary > :not([class*='uk-card-media']) .uk-iconnav > .uk-active > a, .uk-card-secondary.uk-card-body .uk-iconnav > .uk-active > a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-iconnav > .uk-active > a, .uk-overlay-primary .uk-iconnav > .uk-active > a, .uk-offcanvas-bar .uk-iconnav > .uk-active > a {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-text-lead, .uk-section-primary:not(.uk-preserve-color) .uk-text-lead, .uk-section-secondary:not(.uk-preserve-color) .uk-text-lead, .uk-tile-primary:not(.uk-preserve-color) .uk-text-lead, .uk-tile-secondary:not(.uk-preserve-color) .uk-text-lead, .uk-card-primary.uk-card-body .uk-text-lead, .uk-card-primary > :not([class*='uk-card-media']) .uk-text-lead, .uk-card-secondary.uk-card-body .uk-text-lead, .uk-card-secondary > :not([class*='uk-card-media']) .uk-text-lead, .uk-overlay-primary .uk-text-lead, .uk-offcanvas-bar .uk-text-lead {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-text-meta, .uk-section-primary:not(.uk-preserve-color) .uk-text-meta, .uk-section-secondary:not(.uk-preserve-color) .uk-text-meta, .uk-tile-primary:not(.uk-preserve-color) .uk-text-meta, .uk-tile-secondary:not(.uk-preserve-color) .uk-text-meta, .uk-card-primary.uk-card-body .uk-text-meta, .uk-card-primary > :not([class*='uk-card-media']) .uk-text-meta, .uk-card-secondary.uk-card-body .uk-text-meta, .uk-card-secondary > :not([class*='uk-card-media']) .uk-text-meta, .uk-overlay-primary .uk-text-meta, .uk-offcanvas-bar .uk-text-meta {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-text-muted, .uk-section-primary:not(.uk-preserve-color) .uk-text-muted, .uk-section-secondary:not(.uk-preserve-color) .uk-text-muted, .uk-tile-primary:not(.uk-preserve-color) .uk-text-muted, .uk-tile-secondary:not(.uk-preserve-color) .uk-text-muted, .uk-card-primary.uk-card-body .uk-text-muted, .uk-card-primary > :not([class*='uk-card-media']) .uk-text-muted, .uk-card-secondary.uk-card-body .uk-text-muted, .uk-card-secondary > :not([class*='uk-card-media']) .uk-text-muted, .uk-overlay-primary .uk-text-muted, .uk-offcanvas-bar .uk-text-muted {
    color: rgba(255, 255, 255, 0.5) !important; }
  .uk-light .uk-text-emphasis, .uk-section-primary:not(.uk-preserve-color) .uk-text-emphasis, .uk-section-secondary:not(.uk-preserve-color) .uk-text-emphasis, .uk-tile-primary:not(.uk-preserve-color) .uk-text-emphasis, .uk-tile-secondary:not(.uk-preserve-color) .uk-text-emphasis, .uk-card-primary.uk-card-body .uk-text-emphasis, .uk-card-primary > :not([class*='uk-card-media']) .uk-text-emphasis, .uk-card-secondary.uk-card-body .uk-text-emphasis, .uk-card-secondary > :not([class*='uk-card-media']) .uk-text-emphasis, .uk-overlay-primary .uk-text-emphasis, .uk-offcanvas-bar .uk-text-emphasis {
    color: #fff !important; }
  .uk-light .uk-text-primary, .uk-section-primary:not(.uk-preserve-color) .uk-text-primary, .uk-section-secondary:not(.uk-preserve-color) .uk-text-primary, .uk-tile-primary:not(.uk-preserve-color) .uk-text-primary, .uk-tile-secondary:not(.uk-preserve-color) .uk-text-primary, .uk-card-primary.uk-card-body .uk-text-primary, .uk-card-primary > :not([class*='uk-card-media']) .uk-text-primary, .uk-card-secondary.uk-card-body .uk-text-primary, .uk-card-secondary > :not([class*='uk-card-media']) .uk-text-primary, .uk-overlay-primary .uk-text-primary, .uk-offcanvas-bar .uk-text-primary {
    color: #fff !important; }
  .uk-light .uk-text-secondary, .uk-section-primary:not(.uk-preserve-color) .uk-text-secondary, .uk-section-secondary:not(.uk-preserve-color) .uk-text-secondary, .uk-tile-primary:not(.uk-preserve-color) .uk-text-secondary, .uk-tile-secondary:not(.uk-preserve-color) .uk-text-secondary, .uk-card-primary.uk-card-body .uk-text-secondary, .uk-card-primary > :not([class*='uk-card-media']) .uk-text-secondary, .uk-card-secondary.uk-card-body .uk-text-secondary, .uk-card-secondary > :not([class*='uk-card-media']) .uk-text-secondary, .uk-overlay-primary .uk-text-secondary, .uk-offcanvas-bar .uk-text-secondary {
    color: #fff !important; }
  .uk-light .uk-column-divider, .uk-section-primary:not(.uk-preserve-color) .uk-column-divider, .uk-section-secondary:not(.uk-preserve-color) .uk-column-divider, .uk-tile-primary:not(.uk-preserve-color) .uk-column-divider, .uk-tile-secondary:not(.uk-preserve-color) .uk-column-divider, .uk-card-primary.uk-card-body .uk-column-divider, .uk-card-primary > :not([class*='uk-card-media']) .uk-column-divider, .uk-card-secondary.uk-card-body .uk-column-divider, .uk-card-secondary > :not([class*='uk-card-media']) .uk-column-divider, .uk-overlay-primary .uk-column-divider, .uk-offcanvas-bar .uk-column-divider {
    column-rule-color: rgba(255, 255, 255, 0.2); }
  .uk-light .uk-logo, .uk-section-primary:not(.uk-preserve-color) .uk-logo, .uk-section-secondary:not(.uk-preserve-color) .uk-logo, .uk-tile-primary:not(.uk-preserve-color) .uk-logo, .uk-tile-secondary:not(.uk-preserve-color) .uk-logo, .uk-card-primary.uk-card-body .uk-logo, .uk-card-primary > :not([class*='uk-card-media']) .uk-logo, .uk-card-secondary.uk-card-body .uk-logo, .uk-card-secondary > :not([class*='uk-card-media']) .uk-logo, .uk-overlay-primary .uk-logo, .uk-offcanvas-bar .uk-logo {
    color: #fff; }
  .uk-light .uk-logo:hover, .uk-section-primary:not(.uk-preserve-color) .uk-logo:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-logo:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-logo:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-logo:hover, .uk-card-primary.uk-card-body .uk-logo:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-logo:hover, .uk-card-secondary.uk-card-body .uk-logo:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-logo:hover, .uk-overlay-primary .uk-logo:hover, .uk-offcanvas-bar .uk-logo:hover {
    color: #fff; }
  .uk-light .uk-logo > picture:not(:only-of-type) > :not(.uk-logo-inverse), .uk-section-primary:not(.uk-preserve-color) .uk-logo > picture:not(:only-of-type) > :not(.uk-logo-inverse), .uk-section-secondary:not(.uk-preserve-color) .uk-logo > picture:not(:only-of-type) > :not(.uk-logo-inverse), .uk-tile-primary:not(.uk-preserve-color) .uk-logo > picture:not(:only-of-type) > :not(.uk-logo-inverse), .uk-tile-secondary:not(.uk-preserve-color) .uk-logo > picture:not(:only-of-type) > :not(.uk-logo-inverse), .uk-card-primary.uk-card-body .uk-logo > picture:not(:only-of-type) > :not(.uk-logo-inverse), .uk-card-primary > :not([class*='uk-card-media']) .uk-logo > picture:not(:only-of-type) > :not(.uk-logo-inverse), .uk-card-secondary.uk-card-body .uk-logo > picture:not(:only-of-type) > :not(.uk-logo-inverse), .uk-card-secondary > :not([class*='uk-card-media']) .uk-logo > picture:not(:only-of-type) > :not(.uk-logo-inverse), .uk-overlay-primary .uk-logo > picture:not(:only-of-type) > :not(.uk-logo-inverse), .uk-offcanvas-bar .uk-logo > picture:not(:only-of-type) > :not(.uk-logo-inverse),
  .uk-light .uk-logo > :not(picture):not(.uk-logo-inverse):not(:only-of-type),
  .uk-section-primary:not(.uk-preserve-color) .uk-logo > :not(picture):not(.uk-logo-inverse):not(:only-of-type),
  .uk-section-secondary:not(.uk-preserve-color) .uk-logo > :not(picture):not(.uk-logo-inverse):not(:only-of-type),
  .uk-tile-primary:not(.uk-preserve-color) .uk-logo > :not(picture):not(.uk-logo-inverse):not(:only-of-type),
  .uk-tile-secondary:not(.uk-preserve-color) .uk-logo > :not(picture):not(.uk-logo-inverse):not(:only-of-type),
  .uk-card-primary.uk-card-body .uk-logo > :not(picture):not(.uk-logo-inverse):not(:only-of-type),
  .uk-card-primary > :not([class*='uk-card-media']) .uk-logo > :not(picture):not(.uk-logo-inverse):not(:only-of-type),
  .uk-card-secondary.uk-card-body .uk-logo > :not(picture):not(.uk-logo-inverse):not(:only-of-type),
  .uk-card-secondary > :not([class*='uk-card-media']) .uk-logo > :not(picture):not(.uk-logo-inverse):not(:only-of-type),
  .uk-overlay-primary .uk-logo > :not(picture):not(.uk-logo-inverse):not(:only-of-type),
  .uk-offcanvas-bar .uk-logo > :not(picture):not(.uk-logo-inverse):not(:only-of-type) {
    display: none; }
  .uk-light .uk-logo-inverse, .uk-section-primary:not(.uk-preserve-color) .uk-logo-inverse, .uk-section-secondary:not(.uk-preserve-color) .uk-logo-inverse, .uk-tile-primary:not(.uk-preserve-color) .uk-logo-inverse, .uk-tile-secondary:not(.uk-preserve-color) .uk-logo-inverse, .uk-card-primary.uk-card-body .uk-logo-inverse, .uk-card-primary > :not([class*='uk-card-media']) .uk-logo-inverse, .uk-card-secondary.uk-card-body .uk-logo-inverse, .uk-card-secondary > :not([class*='uk-card-media']) .uk-logo-inverse, .uk-overlay-primary .uk-logo-inverse, .uk-offcanvas-bar .uk-logo-inverse {
    display: block; }

/* ========================================================================
   Component: Print
 ========================================================================== */
@media print {
  *,
  *::before,
  *::after {
    background: transparent !important;
    color: black !important;
    box-shadow: none !important;
    text-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; } }

/*@font-face {
    font-family: "Nunito Sans";
    src: url('../../assets/fonts/NunitoSans-Regular.ttf') format('truetype');
    font-style: normal;
} */
body,
h1,
h2,
h3,
p,
input,
textarea,
em {
  font-family: "Nunito Sans", Arial, sans-serif;
  color: #181f23; }

h1 {
  font-size: 36px;
  font-weight: 800; }
  @media all and (min-width: 640px) {
    h1 {
      font-size: 64px; } }

h2 {
  font-size: 36px;
  font-weight: 800; }
  @media all and (min-width: 640px) {
    h2 {
      font-size: 48px; } }

h3 {
  font-size: 20px;
  font-weight: 600; }
  @media all and (min-width: 640px) {
    h3 {
      font-size: 36px; } }

i {
  font-family: "Font Awesome 6 Pro"; }

ul {
  padding-left: 36px; }
  ul li {
    list-style: none;
    padding-bottom: 8.5px;
    margin-bottom: 10px; }
    ul li::before {
      font-family: "Font Awesome 6 Pro";
      content: "\e404";
      font-size: 16px;
      margin-left: -35px;
      padding-right: 20px; }
    ul li > ul {
      padding-left: 26px; }
      ul li > ul li {
        padding: 8.5px 10px 8.5px 0px; }
        ul li > ul li::before {
          vertical-align: middle;
          font-size: 8px;
          padding-right: 8px;
          margin-left: -15px;
          padding-right: 8px; }

ol {
  padding-left: 36px; }
  ol li {
    margin-bottom: 12px; }

.uk-container {
  padding-left: 16px;
  padding-right: 16px; }
  @media (min-width: 960px) {
    .uk-container {
      padding-left: 40px;
      padding-right: 40px; } }

.uk-accordion li::before,
.uk-slider-items li::before,
.uk-dotnav li::before {
  content: "";
  display: none; }

.ham {
  cursor: pointer;
  transition: transform 600ms;
  user-select: none;
  height: 40px;
  width: 40px; }
  .ham.active {
    transform: rotate(45deg); }
    .ham.active .top {
      stroke-dashoffset: -64px; }
    .ham.active .middle {
      transform: rotate(90deg); }
    .ham.active .bottom {
      stroke-dashoffset: -64px; }
  .ham .top {
    stroke-dasharray: 40 160; }
  .ham .middle {
    stroke-dasharray: 40 142;
    transform-origin: 50%;
    transition: transform 600ms; }
  .ham .bottom {
    stroke-dasharray: 40 85;
    transform-origin: 50%;
    transition: transform 600ms, stroke-dashoffset 600ms; }

.hamRotate180.active {
  transform: rotate(180deg); }

.line {
  fill: none;
  transition: stroke-dasharray 600ms, stroke-dashoffset 600ms;
  stroke: #fefefe;
  stroke-width: 5.5;
  stroke-linecap: round; }

.gform_wrapper .gform_heading .gform_required_legend,
.gform_wrapper .gform_validation_errors {
  display: none; }

.newsletter-form-section {
  background-image: url("../../dist/images/newsletter-background.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 90px;
  padding-bottom: 80px; }
  @media all and (min-width: 960px) {
    .newsletter-form-section {
      padding-top: 116px;
      padding-bottom: 62px;
      width: calc(100% + 2px);
      position: relative;
      left: -2px; } }
  .newsletter-form-section .newsletter-header h2 {
    color: #fefefe;
    margin-bottom: 70px; }
    @media all and (min-width: 640px) {
      .newsletter-form-section .newsletter-header h2 {
        margin-bottom: 60px; } }
  .newsletter-form-section .newsletter-form-container {
    max-width: 732px; }
    .newsletter-form-section .newsletter-form-container .gform_wrapper .gform_required_legend,
    .newsletter-form-section .newsletter-form-container .gform_wrapper .gfield_required,
    .newsletter-form-section .newsletter-form-container .gform_wrapper .gfield_validation_message {
      color: #ff5757; }
    .newsletter-form-section .newsletter-form-container .gform_wrapper form {
      position: relative; }
      .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields {
        display: block; }
        .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield {
          position: relative;
          font-family: "Nunito Sans", Arial, sans-serif;
          margin-bottom: 20px; }
          .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .gfield_required.gfield_required_asterisk,
          .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .gfield_required .gfield_required.gfield_required_asterisk {
            display: none; }
          .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container input,
          .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container select,
          .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container textarea {
            padding-left: 18px;
            padding-top: 17px;
            padding-bottom: 17px; }
          .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container input,
          .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container select,
          .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container .chosen-container,
          .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container textarea {
            border: 3px solid #ff5757; }
          .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.gfield_error .validation_message {
            background-color: transparent;
            border: unset;
            margin-top: unset;
            padding-top: 12px;
            padding-left: 20px; }
          .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .gfield_label {
            color: rgba(24, 31, 35, 0.68);
            background-color: #fefefe;
            padding: unset;
            font-size: 18px;
            font-weight: 400;
            margin-left: 21px;
            margin-bottom: 0px;
            position: absolute;
            top: 19px;
            z-index: 3; }
            .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .gfield_label a {
              font-size: inherit; }
            .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .gfield_label .gfield_required {
              display: inline-block;
              font-size: 13.008px;
              padding-left: 0.125em; }
            .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .gfield_label.hidden {
              color: transparent;
              background-color: transparent; }
              .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .gfield_label.hidden .gfield_required {
                color: transparent; }
          .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container input,
          .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container select,
          .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container textarea {
            color: #181f23;
            border: 1px solid rgba(24, 31, 35, 0.68);
            padding-left: 20px;
            padding-top: 19px;
            padding-bottom: 19px;
            line-height: 24px;
            font-size: 18px;
            width: 100%;
            border-radius: 4px;
            font-family: inherit; }
            .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container input:focus,
            .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container select:focus,
            .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container textarea:focus {
              outline: unset;
              border: 3px solid #80fff3;
              padding-left: 18px;
              padding-top: 17px;
              padding-bottom: 17px; }
          .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_checkbox .gfield_checkbox .gchoice {
            vertical-align: top; }
            .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_checkbox .gfield_checkbox .gchoice input.gfield-choice-input {
              vertical-align: top;
              width: unset; }
          .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_select {
            position: relative;
            z-index: 0;
            background-color: #fefefe;
            border-radius: 4px; }
            .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_select::after {
              content: "\f078";
              font-family: "Font Awesome 6 Pro";
              bottom: 20px;
              right: 15px;
              position: absolute;
              font-size: 18px;
              line-height: 25px;
              color: rgba(24, 31, 35, 0.68);
              z-index: -1; }
              @media all and (min-width: 640px) {
                .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_select::after {
                  right: 28px; } }
            .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_select select {
              background-color: transparent;
              appearance: none;
              z-index: 2;
              position: relative;
              width: 100%;
              padding-right: 30px; }
          @media all and (min-width: 640px) {
            .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_email {
              margin-right: 242px; } }
          .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_email input {
            border-radius: 4px 0 0 4px; }
            .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_email input:focus {
              border-right: unset; }
          .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container {
            color: #fefefe; }
            .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container.ginput_container_consent input[type="checkbox"] {
              -webkit-appearance: none;
              -moz-appearance: none;
              outline: 0;
              appearance: none;
              max-width: 16px;
              width: 100%;
              height: 16px;
              border-radius: 3.3px;
              margin: 0;
              background: transparent;
              float: left;
              padding: 0; }
            .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container.ginput_container_consent input[type="checkbox"]:checked {
              background-size: 100%;
              background-repeat: no-repeat;
              background-position: center; }
            .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container.ginput_container_consent label {
              display: block;
              font-weight: normal;
              padding-left: 25px;
              font-size: 14px;
              margin-bottom: 0;
              position: relative;
              top: -2px; }
            .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container.ginput_container_consent input[type="checkbox"] {
              border: 1px solid #fefefe; }
            .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container.ginput_container_consent input[type="checkbox"]:checked {
              background-image: url("../../dist/images/checkbox-white.svg"); }
            .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container.ginput_container_consent a {
              color: #fefefe;
              font-weight: 800; }
          .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container label {
            color: #ff5757; }
      .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_footer {
        padding-bottom: unset;
        padding-top: 0px;
        margin: unset;
        display: block; }
        .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_footer input.gform_button.button {
          margin-bottom: unset; }
        @media all and (min-width: 640px) {
          .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_footer {
            position: absolute;
            right: 0;
            top: 0; } }
        .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_footer input.gform_button.button {
          text-align: center;
          text-transform: uppercase;
          line-height: 22px;
          display: inline-block;
          border-radius: 4px;
          padding: 16px 10px;
          width: fit-content;
          cursor: pointer;
          font-weight: 700;
          -webkit-appearance: unset;
          font-size: 12px;
          color: #80fff3;
          background-color: #2a2a2a;
          border: 1px solid #80fff3;
          font-size: 16px;
          border-radius: 0 4px 4px 0;
          padding: 20px 10px;
          width: 100%; }
          @media all and (min-width: 640px) {
            .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_footer input.gform_button.button {
              font-size: 16px; } }
          .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_footer input.gform_button.button:hover {
            text-decoration: none; }
          .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_footer input.gform_button.button:hover {
            background-color: #494949; }
          @media all and (min-width: 640px) {
            .newsletter-form-section .newsletter-form-container .gform_wrapper form .gform_footer input.gform_button.button {
              padding: 20px 76px;
              width: fit-content; } }
    .newsletter-form-section .newsletter-form-container .gform_confirmation_wrapper .gform_confirmation_message {
      text-align: center;
      color: #fefefe; }
      @media all and (min-width: 960px) {
        .newsletter-form-section .newsletter-form-container .gform_confirmation_wrapper .gform_confirmation_message {
          padding-bottom: 44px; } }
      .newsletter-form-section .newsletter-form-container .gform_confirmation_wrapper .gform_confirmation_message a,
      .newsletter-form-section .newsletter-form-container .gform_confirmation_wrapper .gform_confirmation_message p {
        color: inherit; }
      .newsletter-form-section .newsletter-form-container .gform_confirmation_wrapper .gform_confirmation_message .title {
        font-weight: 800;
        margin-bottom: 30px;
        font-size: 32px; }
      .newsletter-form-section .newsletter-form-container .gform_confirmation_wrapper .gform_confirmation_message .body {
        font-size: 18px;
        margin-bottom: 42px; }
      .newsletter-form-section .newsletter-form-container .gform_confirmation_wrapper .gform_confirmation_message .social-media {
        font-size: 35px; }
        .newsletter-form-section .newsletter-form-container .gform_confirmation_wrapper .gform_confirmation_message .social-media a:hover {
          color: #80fff3; }
        .newsletter-form-section .newsletter-form-container .gform_confirmation_wrapper .gform_confirmation_message .social-media a.twitter-icon {
          margin-right: 100px; }

.case-studies-slider {
  background-color: #2a2a2a;
  padding-right: 16px;
  padding-left: 16px; }
  @media all and (min-width: 960px) {
    .case-studies-slider {
      padding-left: 55px;
      padding-right: 0; } }
  .case-studies-slider .case-studies-slider-container {
    color: #fefefe; }
    .case-studies-slider .case-studies-slider-container .case-studies-heading h2 {
      color: inherit;
      margin-bottom: 50px; }
      @media all and (min-width: 640px) {
        .case-studies-slider .case-studies-slider-container .case-studies-heading h2 {
          margin-bottom: 50px; } }
    @media all and (min-width: 640px) {
      .case-studies-slider .case-studies-slider-container .uk-grid {
        margin-left: -81px; }
        .case-studies-slider .case-studies-slider-container .uk-grid > * {
          padding-left: 81px; } }
    .case-studies-slider .case-studies-slider-container .case-study-container {
      font-size: 14px;
      border-left: 1px solid #80fff3;
      border-bottom: 1px solid #80fff3;
      margin-bottom: 44px;
      padding: 0 10px 30px 30px; }
      @media all and (min-width: 640px) {
        .case-studies-slider .case-studies-slider-container .case-study-container {
          padding: 0 2px 20px 20px;
          max-width: 625px; } }
      .case-studies-slider .case-studies-slider-container .case-study-container .image-container {
        position: relative;
        margin-bottom: 24px; }
        .case-studies-slider .case-studies-slider-container .case-study-container .image-container img {
          max-height: 299px;
          width: 100%; }
        .case-studies-slider .case-studies-slider-container .case-study-container .image-container .image-overlay {
          padding-top: 5px; }
          @media all and (min-width: 640px) {
            .case-studies-slider .case-studies-slider-container .case-study-container .image-container .image-overlay {
              padding: 0 0 20px 20px;
              background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%);
              position: absolute;
              bottom: 0;
              left: 0;
              box-sizing: border-box;
              height: 100%;
              width: 100%; } }
          .case-studies-slider .case-studies-slider-container .case-study-container .image-container .image-overlay .logo {
            margin-bottom: 10px; }
            @media all and (min-width: 640px) {
              .case-studies-slider .case-studies-slider-container .case-study-container .image-container .image-overlay .logo {
                margin-bottom: 64px; } }
          .case-studies-slider .case-studies-slider-container .case-study-container .image-container .image-overlay .info-container .case-title {
            margin-bottom: 10px;
            font-size: 26px; }
            @media all and (min-width: 640px) {
              .case-studies-slider .case-studies-slider-container .case-study-container .image-container .image-overlay .info-container .case-title {
                font-size: 36px;
                line-height: 44px;
                font-weight: 600; } }
          .case-studies-slider .case-studies-slider-container .case-study-container .image-container .image-overlay .info-container .location {
            font-size: 20px;
            font-weight: 500; }
      .case-studies-slider .case-studies-slider-container .case-study-container .text-container {
        margin-bottom: 40px; }
      .case-studies-slider .case-studies-slider-container .case-study-container .button-container .crossover-button {
        text-align: center;
        text-transform: uppercase;
        line-height: 22px;
        display: inline-block;
        border-radius: 4px;
        padding: 16px 10px;
        width: fit-content;
        cursor: pointer;
        font-weight: 700;
        -webkit-appearance: unset;
        font-size: 12px;
        color: #80fff3;
        background-color: #2a2a2a;
        border: 2px solid #80fff3;
        padding: 16px 20px; }
        @media all and (min-width: 640px) {
          .case-studies-slider .case-studies-slider-container .case-study-container .button-container .crossover-button {
            font-size: 16px; } }
        .case-studies-slider .case-studies-slider-container .case-study-container .button-container .crossover-button:hover {
          text-decoration: none; }
        .case-studies-slider .case-studies-slider-container .case-study-container .button-container .crossover-button:hover {
          background-color: #494949; }
        @media all and (min-width: 640px) {
          .case-studies-slider .case-studies-slider-container .case-study-container .button-container .crossover-button {
            width: fit-content; } }
    .case-studies-slider .case-studies-slider-container .navigation-container {
      color: #80fff3; }
      .case-studies-slider .case-studies-slider-container .navigation-container .navigation {
        display: flex;
        width: 100%;
        justify-content: space-between; }
        @media all and (min-width: 640px) {
          .case-studies-slider .case-studies-slider-container .navigation-container .navigation {
            display: block;
            width: unset;
            justify-content: unset; } }
        @media all and (min-width: 1200px) {
          .case-studies-slider .case-studies-slider-container .navigation-container .navigation {
            margin-right: 39px; } }
        .case-studies-slider .case-studies-slider-container .navigation-container .navigation a {
          color: inherit; }
          .case-studies-slider .case-studies-slider-container .navigation-container .navigation a img.arrow-left {
            margin: 0 16px; }
            @media all and (min-width: 640px) {
              .case-studies-slider .case-studies-slider-container .navigation-container .navigation a img.arrow-left {
                margin-left: 0; } }
          .case-studies-slider .case-studies-slider-container .navigation-container .navigation a img.arrow-right {
            margin: 0 16px; }
          @media all and (min-width: 640px) {
            .case-studies-slider .case-studies-slider-container .navigation-container .navigation a.next {
              margin-left: 80px; } }
          .case-studies-slider .case-studies-slider-container .navigation-container .navigation a:hover {
            text-decoration: none;
            opacity: 0.68; }
          .case-studies-slider .case-studies-slider-container .navigation-container .navigation a.uk-invisible {
            cursor: default;
            visibility: unset !important;
            opacity: 0.5; }

@media all and (min-width: 768px) {
  .dropdown-category-list-container {
    display: none; } }

.dropdown-category-list-container button {
  width: 210px; }

.dropdown-category-list-container .dropdown-category-list {
  top: 40px !important;
  width: 210px; }
  .dropdown-category-list-container .dropdown-category-list p {
    margin: 0 0 20px; }
    .dropdown-category-list-container .dropdown-category-list p:last-child {
      margin: 0; }

.crossover-header-container {
  background-color: #2a2a2a; }
  .crossover-header-container .uk-container {
    max-width: 1440px;
    padding-left: 20px;
    padding-right: 30px; }
    @media (min-width: 1200px) {
      .crossover-header-container .uk-container {
        padding-left: 10px;
        padding-right: 10px; } }
    @media (min-width: 1480px) {
      .crossover-header-container .uk-container {
        padding-left: 50px;
        padding-right: 50px; } }
  .crossover-header-container .header-items {
    padding: 24px 0; }
    @media all and (min-width: 1200px) {
      .crossover-header-container .header-items {
        padding: 30px 0; } }
    .crossover-header-container .header-items .uk-icon {
      color: #fefefe; }
    .crossover-header-container .header-items .mobile-items.logo-container {
      display: none; }
      .crossover-header-container .header-items .mobile-items.logo-container.mobile-header-toggle {
        display: flex; }
    .crossover-header-container .header-items .uk-navbar-toggle {
      min-height: 30px;
      padding: unset; }
      .crossover-header-container .header-items .uk-navbar-toggle svg {
        width: 30px; }
    .crossover-header-container .header-items .nav-primary .primary-menu li {
      padding: unset;
      margin-bottom: 0; }
      .crossover-header-container .header-items .nav-primary .primary-menu li a {
        font-family: "Nunito Sans", Arial, sans-serif;
        color: #fefefe;
        font-weight: 700;
        min-height: unset; }
        .crossover-header-container .header-items .nav-primary .primary-menu li a.main-menu-link {
          text-transform: uppercase;
          text-align: center;
          padding: 16px 14px 11px; }
      .crossover-header-container .header-items .nav-primary .primary-menu li.current_page_item > a,
      .crossover-header-container .header-items .nav-primary .primary-menu li a:hover {
        color: #80fff3; }
      .crossover-header-container .header-items .nav-primary .primary-menu li.menu-item-has-children a.main-menu-link::after {
        font-family: "Font Awesome 6 Pro";
        content: "\f078";
        border: none;
        margin-left: 5px; }
      .crossover-header-container .header-items .nav-primary .primary-menu li.menu-item-has-children .uk-navbar-dropdown.uk-open {
        position: absolute;
        background-color: #494949;
        padding: 0;
        min-width: 200px; }
        .crossover-header-container .header-items .nav-primary .primary-menu li.menu-item-has-children .uk-navbar-dropdown.uk-open ul li {
          padding: 11px 0 11px 20px;
          margin-bottom: 0; }
      .crossover-header-container .header-items .nav-primary .primary-menu li::before {
        content: "";
        display: none; }
  .crossover-header-container .mobile-navbar-container .mobile-navbar {
    display: none;
    width: 100%; }
    .crossover-header-container .mobile-navbar-container .mobile-navbar ul {
      margin-bottom: 11px; }
      .crossover-header-container .mobile-navbar-container .mobile-navbar ul li {
        font-size: 16px;
        padding-bottom: 0;
        margin-bottom: 0; }
        .crossover-header-container .mobile-navbar-container .mobile-navbar ul li a {
          font-family: "Nunito Sans", Arial, sans-serif;
          color: #fefefe;
          font-weight: 700;
          min-height: unset; }
          .crossover-header-container .mobile-navbar-container .mobile-navbar ul li a.main-menu-link {
            text-transform: uppercase;
            padding: 18px 0 18px 20px; }
        .crossover-header-container .mobile-navbar-container .mobile-navbar ul li.current_page_item > a,
        .crossover-header-container .mobile-navbar-container .mobile-navbar ul li a:hover {
          color: #80fff3; }
        .crossover-header-container .mobile-navbar-container .mobile-navbar ul li.menu-item-has-children a.main-menu-link::after {
          font-family: "Font Awesome 6 Pro";
          content: "\f078";
          border: none;
          margin-left: 5px; }
        .crossover-header-container .mobile-navbar-container .mobile-navbar ul li.menu-item-has-children.uk-open a.main-menu-link::after {
          transform: rotate(180deg); }
        .crossover-header-container .mobile-navbar-container .mobile-navbar ul li.menu-item-has-children.uk-open .sub-menu {
          margin-left: 18px;
          background-color: #494949;
          padding: 0;
          min-width: 200px; }
          .crossover-header-container .mobile-navbar-container .mobile-navbar ul li.menu-item-has-children.uk-open .sub-menu li a {
            padding: 11px 0 11px 20px; }
        .crossover-header-container .mobile-navbar-container .mobile-navbar ul li::before {
          content: "";
          display: none; }
    .crossover-header-container .mobile-navbar-container .mobile-navbar .mobile-header-social-media {
      margin-top: 39px;
      margin-left: 20px;
      margin-bottom: 30px;
      font-size: 30px; }
      .crossover-header-container .mobile-navbar-container .mobile-navbar .mobile-header-social-media a {
        color: #fefefe; }
        .crossover-header-container .mobile-navbar-container .mobile-navbar .mobile-header-social-media a:hover {
          color: #80fff3; }
    .crossover-header-container .mobile-navbar-container .mobile-navbar.mobile-header-toggle {
      display: block; }
  @media all and (min-width: 640px) {
    .crossover-header-container .button-container {
      flex: unset; } }
  .crossover-header-container .button-container a {
    text-align: center;
    text-transform: uppercase;
    line-height: 22px;
    display: inline-block;
    border-radius: 4px;
    padding: 16px 10px;
    width: fit-content;
    cursor: pointer;
    font-weight: 700;
    -webkit-appearance: unset;
    font-size: 12px;
    color: #80fff3;
    background-color: #2a2a2a;
    border: 2px solid #80fff3;
    padding: 11px 16px;
    line-height: 16px; }
    @media all and (min-width: 640px) {
      .crossover-header-container .button-container a {
        font-size: 16px; } }
    .crossover-header-container .button-container a:hover {
      text-decoration: none; }
    .crossover-header-container .button-container a:hover {
      background-color: #494949; }
    @media all and (min-width: 640px) {
      .crossover-header-container .button-container a {
        padding: 14px 20px;
        font-size: 16px;
        line-height: 21px; } }
  @media all and (max-width: 640px) {
    .crossover-header-container .button-container.mobile-header-toggle {
      display: none; } }

.mobile-dark-header {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 132;
  overflow: scroll;
  height: 100%; }

.page-header-container {
  padding-top: 50px;
  padding-bottom: 30px; }
  @media all and (min-width: 640px) {
    .page-header-container {
      padding-top: 100px;
      padding-bottom: 60px; } }
  .page-header-container h1 {
    font-weight: 800;
    margin-bottom: unset; }

footer {
  border-top: 1px solid rgba(24, 31, 35, 0.68); }
  @media all and (min-width: 1480px) {
    footer .top-footer-section .partner-footer-form-section {
      display: flex;
      justify-content: flex-end; } }
  footer .top-footer-section .partner-footer-form-section .partner-form-container {
    margin: 80px 16px 80px 16px; }
    @media all and (min-width: 640px) {
      footer .top-footer-section .partner-footer-form-section .partner-form-container {
        margin: 80px 55px 101px 55px; } }
    @media all and (min-width: 1200px) {
      footer .top-footer-section .partner-footer-form-section .partner-form-container {
        max-width: 720px; } }
    footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper .gform_required_legend,
    footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper .gfield_required,
    footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper .gfield_validation_message {
      color: #e02424; }
    footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper .gform_heading h2 {
      text-transform: uppercase;
      margin-bottom: 40px;
      text-align: center; }
      @media all and (min-width: 960px) {
        footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper .gform_heading h2 {
          text-align: left; } }
    footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields {
      display: block; }
      footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield {
        position: relative;
        font-family: "Nunito Sans", Arial, sans-serif;
        margin-bottom: 20px;
        margin-bottom: 17px; }
        footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .gfield_required.gfield_required_asterisk,
        footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .gfield_required .gfield_required.gfield_required_asterisk {
          display: none; }
        footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container input,
        footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container select,
        footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container textarea {
          padding-left: 18px;
          padding-top: 17px;
          padding-bottom: 17px; }
        footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container input,
        footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container select,
        footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container .chosen-container,
        footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container textarea {
          border: 3px solid #ff5757; }
        footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.gfield_error .validation_message {
          background-color: transparent;
          border: unset;
          margin-top: unset;
          padding-top: 12px;
          padding-left: 20px; }
        footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .gfield_label {
          color: rgba(24, 31, 35, 0.68);
          background-color: #fefefe;
          padding: unset;
          font-size: 18px;
          font-weight: 400;
          margin-left: 21px;
          margin-bottom: 0px;
          position: absolute;
          top: 19px;
          z-index: 3; }
          footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .gfield_label a {
            font-size: inherit; }
          footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .gfield_label .gfield_required {
            display: inline-block;
            font-size: 13.008px;
            padding-left: 0.125em; }
          footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .gfield_label.hidden {
            color: transparent;
            background-color: transparent; }
            footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .gfield_label.hidden .gfield_required {
              color: transparent; }
        footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container input,
        footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container select,
        footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container textarea {
          color: #181f23;
          border: 1px solid rgba(24, 31, 35, 0.68);
          padding-left: 20px;
          padding-top: 19px;
          padding-bottom: 19px;
          line-height: 24px;
          font-size: 18px;
          width: 100%;
          border-radius: 4px;
          font-family: inherit; }
          footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container input:focus,
          footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container select:focus,
          footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container textarea:focus {
            outline: unset;
            border: 3px solid #80fff3;
            padding-left: 18px;
            padding-top: 17px;
            padding-bottom: 17px; }
        footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_checkbox .gfield_checkbox .gchoice {
          vertical-align: top; }
          footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_checkbox .gfield_checkbox .gchoice input.gfield-choice-input {
            vertical-align: top;
            width: unset; }
        footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_select {
          position: relative;
          z-index: 0;
          background-color: #fefefe;
          border-radius: 4px; }
          footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_select::after {
            content: "\f078";
            font-family: "Font Awesome 6 Pro";
            bottom: 20px;
            right: 15px;
            position: absolute;
            font-size: 18px;
            line-height: 25px;
            color: rgba(24, 31, 35, 0.68);
            z-index: -1; }
            @media all and (min-width: 640px) {
              footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_select::after {
                right: 28px; } }
          footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_select select {
            background-color: transparent;
            appearance: none;
            z-index: 2;
            position: relative;
            width: 100%;
            padding-right: 30px; }
        footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_consent {
          color: rgba(24, 31, 35, 0.68); }
          footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_consent input[type="checkbox"] {
            -webkit-appearance: none;
            -moz-appearance: none;
            outline: 0;
            appearance: none;
            max-width: 16px;
            width: 100%;
            height: 16px;
            border-radius: 3.3px;
            margin: 0;
            background: transparent;
            float: left;
            padding: 0; }
          footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_consent input[type="checkbox"]:checked {
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center; }
          footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_consent label {
            display: block;
            font-weight: normal;
            padding-left: 25px;
            font-size: 14px;
            margin-bottom: 0;
            position: relative;
            top: -2px; }
          footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_consent input[type="checkbox"] {
            border: 1px solid rgba(24, 31, 35, 0.68); }
          footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_consent input[type="checkbox"]:checked {
            background-image: url("../../assets/images/checkbox-grey.svg"); }
          footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_consent a {
            color: inherit;
            font-weight: 700; }
        footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield textarea {
          height: unset;
          max-height: 120px; }
          @media all and (min-width: 640px) {
            footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield textarea {
              max-height: 180px; } }
        footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container label {
          color: #e02424; }
    footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_footer {
      padding-bottom: unset;
      padding-top: 40px;
      margin: unset;
      display: block; }
      footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_footer input.gform_button.button {
        margin-bottom: unset; }
      footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_footer input.gform_button.button {
        text-align: center;
        text-transform: uppercase;
        line-height: 22px;
        display: inline-block;
        border-radius: 4px;
        padding: 16px 10px;
        width: fit-content;
        cursor: pointer;
        font-weight: 700;
        -webkit-appearance: unset;
        font-size: 12px;
        color: #80fff3;
        background-color: #2a2a2a;
        border: 1px solid #80fff3;
        padding: 16px;
        width: 100%;
        font-size: 16px; }
        @media all and (min-width: 640px) {
          footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_footer input.gform_button.button {
            font-size: 16px; } }
        footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_footer input.gform_button.button:hover {
          text-decoration: none; }
        footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_footer input.gform_button.button:hover {
          background-color: #494949; }
        @media all and (min-width: 640px) {
          footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_wrapper form .gform_footer input.gform_button.button {
            padding: 16px 89px;
            width: fit-content; } }
    footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_confirmation_wrapper {
      text-align: center;
      margin: 0 0 70px 0; }
      @media all and (min-width: 640px) {
        footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_confirmation_wrapper {
          margin: 170px 0 139px 0; } }
      footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_confirmation_wrapper a {
        color: #181f23; }
      footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_confirmation_wrapper .title {
        font-weight: 800;
        font-size: 32px;
        margin-bottom: 15px; }
        @media all and (min-width: 640px) {
          footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_confirmation_wrapper .title {
            margin-bottom: 30px; } }
      footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_confirmation_wrapper .body {
        font-size: 14px;
        margin-bottom: 26px; }
        @media all and (min-width: 640px) {
          footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_confirmation_wrapper .body {
            font-size: 18px;
            margin-bottom: 52px; } }
        footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_confirmation_wrapper .body strong {
          font-weight: 800; }
      footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_confirmation_wrapper .social-media {
        font-size: 43px; }
        footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_confirmation_wrapper .social-media a:hover {
          color: #80fff3; }
        footer .top-footer-section .partner-footer-form-section .partner-form-container .gform_confirmation_wrapper .social-media a.twitter-icon {
          margin-right: 100px; }
  footer .top-footer-section .footer-nav-section {
    background-color: #181f23; }
    @media all and (min-width: 1480px) {
      footer .top-footer-section .footer-nav-section {
        display: flex;
        justify-content: flex-start; } }
    footer .top-footer-section .footer-nav-section .footer-nav-container {
      font-size: 14px;
      padding: 50px 16px 0px 16px; }
      @media all and (min-width: 960px) {
        footer .top-footer-section .footer-nav-section .footer-nav-container {
          font-size: 16px;
          padding: 90px 120px 90px 54px; } }
      @media all and (min-width: 1200px) {
        footer .top-footer-section .footer-nav-section .footer-nav-container {
          box-sizing: border-box;
          max-width: 720px; } }
      footer .top-footer-section .footer-nav-section .footer-nav-container a {
        color: #fefefe; }
      footer .top-footer-section .footer-nav-section .footer-nav-container ul {
        padding-left: unset;
        margin-bottom: 46px; }
        footer .top-footer-section .footer-nav-section .footer-nav-container ul li {
          padding-bottom: 0;
          list-style: none; }
          footer .top-footer-section .footer-nav-section .footer-nav-container ul li.menu-item {
            margin-bottom: 24px; }
            @media all and (min-width: 640px) {
              footer .top-footer-section .footer-nav-section .footer-nav-container ul li.menu-item {
                margin-bottom: 40px; } }
            footer .top-footer-section .footer-nav-section .footer-nav-container ul li.menu-item a {
              font-weight: 700;
              text-transform: uppercase; }
            footer .top-footer-section .footer-nav-section .footer-nav-container ul li.menu-item.menu-item-has-children ul.sub-menu {
              margin-top: 26px; }
              footer .top-footer-section .footer-nav-section .footer-nav-container ul li.menu-item.menu-item-has-children ul.sub-menu li {
                padding-top: 0;
                padding-bottom: 0;
                margin-bottom: 22px; }
                footer .top-footer-section .footer-nav-section .footer-nav-container ul li.menu-item.menu-item-has-children ul.sub-menu li a {
                  font-weight: 400;
                  text-transform: unset; }
                footer .top-footer-section .footer-nav-section .footer-nav-container ul li.menu-item.menu-item-has-children ul.sub-menu li:last-child {
                  margin-bottom: 0; }
          footer .top-footer-section .footer-nav-section .footer-nav-container ul li:last-child {
            margin-bottom: 0; }
          footer .top-footer-section .footer-nav-section .footer-nav-container ul li::before {
            content: "";
            display: none; }
      footer .top-footer-section .footer-nav-section .footer-nav-container .footer-top-items li {
        float: left;
        width: 50%; }
        @media all and (min-width: 640px) {
          footer .top-footer-section .footer-nav-section .footer-nav-container .footer-top-items li {
            width: 33.32223%; } }
      footer .top-footer-section .footer-nav-section .footer-nav-container .footer-top-items-container {
        margin-bottom: 16px; }
        @media all and (min-width: 640px) {
          footer .top-footer-section .footer-nav-section .footer-nav-container .footer-top-items-container {
            margin-bottom: 0; } }
      footer .top-footer-section .footer-nav-section .footer-nav-container .footer-column-2-container,
      footer .top-footer-section .footer-nav-section .footer-nav-container .footer-column-3-container {
        margin-bottom: 18px; }
        @media all and (min-width: 640px) {
          footer .top-footer-section .footer-nav-section .footer-nav-container .footer-column-2-container,
          footer .top-footer-section .footer-nav-section .footer-nav-container .footer-column-3-container {
            margin-bottom: 0; } }
  footer .bottom-footer-section {
    background-color: #181f23; }
    footer .bottom-footer-section .bottom-footer-section-container {
      max-width: 918px;
      color: #fefefe; }
      footer .bottom-footer-section .bottom-footer-section-container .copyright,
      footer .bottom-footer-section .bottom-footer-section-container .site-creator {
        text-align: left;
        font-size: 12px; }
        @media all and (min-width: 640px) {
          footer .bottom-footer-section .bottom-footer-section-container .copyright,
          footer .bottom-footer-section .bottom-footer-section-container .site-creator {
            text-align: center;
            font-size: 16px; } }
        @media all and (min-width: 1200px) {
          footer .bottom-footer-section .bottom-footer-section-container .copyright,
          footer .bottom-footer-section .bottom-footer-section-container .site-creator {
            display: inline-block;
            margin-right: 60px; } }
        footer .bottom-footer-section .bottom-footer-section-container .copyright a,
        footer .bottom-footer-section .bottom-footer-section-container .site-creator a {
          color: #80fff3; }
      footer .bottom-footer-section .bottom-footer-section-container .copyright {
        padding: 20px 0px; }
      footer .bottom-footer-section .bottom-footer-section-container .site-creator {
        float: right;
        padding-top: 3px;
        padding-bottom: 3px; }
        @media all and (min-width: 640px) {
          footer .bottom-footer-section .bottom-footer-section-container .site-creator {
            float: unset; } }
      footer .bottom-footer-section .bottom-footer-section-container .footer-social-media-container {
        float: left; }
        @media all and (min-width: 640px) {
          footer .bottom-footer-section .bottom-footer-section-container .footer-social-media-container {
            float: unset; } }
        @media all and (min-width: 1200px) {
          footer .bottom-footer-section .bottom-footer-section-container .footer-social-media-container {
            display: inline-block; } }
        footer .bottom-footer-section .bottom-footer-section-container .footer-social-media-container .footer-social-media {
          margin-bottom: 20px; }
          @media all and (min-width: 640px) {
            footer .bottom-footer-section .bottom-footer-section-container .footer-social-media-container .footer-social-media {
              margin-top: 20px; } }
          @media all and (min-width: 1200px) {
            footer .bottom-footer-section .bottom-footer-section-container .footer-social-media-container .footer-social-media {
              margin-top: 0px; } }
          footer .bottom-footer-section .bottom-footer-section-container .footer-social-media-container .footer-social-media a {
            font-size: 22px;
            color: #fefefe; }
            footer .bottom-footer-section .bottom-footer-section-container .footer-social-media-container .footer-social-media a:hover {
              color: #80fff3; }
            footer .bottom-footer-section .bottom-footer-section-container .footer-social-media-container .footer-social-media a:not(:first-child) {
              margin-left: 40px; }

.cross-about-page-container {
  padding: 60px 0 0; }
  @media all and (min-width: 960px) {
    .cross-about-page-container {
      padding: 100px 0 0; } }
  .cross-about-page-container .uk-container {
    max-width: 1300px; }
  .cross-about-page-container h1.page-title {
    margin: 0 0 60px; }
  .cross-about-page-container .about-main-content-container h2 {
    font-weight: 600;
    font-size: 32px;
    line-height: 44px; }
  .cross-about-page-container .about-main-content-container p {
    font-size: 24px;
    line-height: 33px; }
  .cross-about-page-container .about-team-container {
    padding: 60px 0; }
    @media all and (min-width: 960px) {
      .cross-about-page-container .about-team-container {
        padding: 120px 0; } }
    .cross-about-page-container .about-team-container h2.team-header {
      font-weight: 800;
      margin: 0 0 60px; }
      @media all and (min-width: 960px) {
        .cross-about-page-container .about-team-container h2.team-header {
          margin: 0 0 65px; } }
    .cross-about-page-container .about-team-container .team-member-card {
      padding: 60px 32px;
      text-align: center;
      background: #fefefe;
      display: block;
      box-shadow: -6px 6px 30px rgba(0, 0, 0, 0.17);
      border-radius: 4px;
      transition: all 0.2s linear; }
      @media all and (min-width: 960px) {
        .cross-about-page-container .about-team-container .team-member-card {
          padding: 60px 32px 84px; } }
      .cross-about-page-container .about-team-container .team-member-card:active, .cross-about-page-container .about-team-container .team-member-card:focus, .cross-about-page-container .about-team-container .team-member-card:hover {
        text-decoration: none;
        box-shadow: -6px 6px 30px rgba(0, 0, 0, 0.37); }
      .cross-about-page-container .about-team-container .team-member-card img {
        max-height: 218px;
        max-width: 185px;
        width: 100%;
        object-fit: cover;
        margin: 0 0 40px; }
      .cross-about-page-container .about-team-container .team-member-card .name {
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        margin: 0 0 14px; }
      .cross-about-page-container .about-team-container .team-member-card .title {
        font-family: "Nunito Sans", Arial, sans-serif;
        background: #2a2a2a;
        padding: 4px;
        font-size: 18px;
        margin: 0 0 18px;
        color: #fefefe;
        line-height: 25px; }
      .cross-about-page-container .about-team-container .team-member-card .location {
        font-size: 16px;
        line-height: 22px;
        color: #181f23;
        margin: 0; }
  .cross-about-page-container .about-career-container {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 60px 0;
    position: relative; }
    @media all and (min-width: 960px) {
      .cross-about-page-container .about-career-container {
        padding: 120px 0 107px; } }
    .cross-about-page-container .about-career-container .uk-container {
      max-width: 1120px; }
    .cross-about-page-container .about-career-container .background-gradient {
      background: linear-gradient(0deg, #181f23 0%, rgba(24, 31, 35, 0) 100%);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0; }
    .cross-about-page-container .about-career-container h2 {
      font-weight: 800;
      font-size: 48px;
      line-height: 65px;
      text-transform: uppercase;
      text-align: center;
      color: #ffffff;
      margin: 0 0 60px; }
      @media all and (min-width: 960px) {
        .cross-about-page-container .about-career-container h2 {
          margin: 0 0 65px; } }
    .cross-about-page-container .about-career-container p {
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      color: #fefefe;
      margin: 0 0 43px; }
      @media all and (min-width: 960px) {
        .cross-about-page-container .about-career-container p {
          font-size: 32px;
          line-height: 44px; } }
    .cross-about-page-container .about-career-container a {
      text-align: center;
      text-transform: uppercase;
      line-height: 22px;
      display: inline-block;
      border-radius: 4px;
      padding: 16px 10px;
      width: fit-content;
      cursor: pointer;
      font-weight: 700;
      -webkit-appearance: unset;
      font-size: 12px;
      color: #80fff3;
      background-color: #2a2a2a;
      border: 2px solid #80fff3;
      box-sizing: border-box;
      text-align: center;
      margin: 60px auto 0;
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      display: block;
      max-width: 576px;
      width: 100%; }
      @media all and (min-width: 640px) {
        .cross-about-page-container .about-career-container a {
          font-size: 16px; } }
      .cross-about-page-container .about-career-container a:hover {
        text-decoration: none; }
      .cross-about-page-container .about-career-container a:hover {
        background-color: #494949; }
      @media all and (min-width: 960px) {
        .cross-about-page-container .about-career-container a {
          margin: 86px auto 0;
          font-size: 36px;
          line-height: 49px; } }

.team-member-modal {
  width: 100%;
  max-width: 375px !important;
  padding: 40px 20px 30px; }
  @media all and (min-width: 960px) {
    .team-member-modal {
      padding: 80px 60px 50px;
      max-width: 995px !important; } }
  .team-member-modal button {
    appearance: none;
    border: none;
    background: transparent;
    font-size: 23px;
    right: 20px;
    position: absolute;
    top: 15px;
    color: rgba(24, 31, 35, 0.68); }
    @media all and (min-width: 960px) {
      .team-member-modal button {
        font-size: 44px;
        right: 33px;
        top: 24px; } }
    .team-member-modal button:active, .team-member-modal button:focus, .team-member-modal button:hover {
      cursor: pointer;
      opacity: 0.7; }
  .team-member-modal .name {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #181f23;
    margin: 0 0 10px;
    text-align: center; }
    @media all and (min-width: 960px) {
      .team-member-modal .name {
        text-align: left;
        font-weight: 800;
        font-size: 48px;
        line-height: 65px; } }
  .team-member-modal .title {
    color: #fefefe;
    background: #2a2a2a;
    padding: 2px 10px;
    margin: 0 40px 20px;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    text-align: center; }
    @media all and (min-width: 960px) {
      .team-member-modal .title {
        text-align: left;
        font-weight: 600;
        font-size: 32px;
        line-height: 44px;
        margin: 0 0 30px; } }
  .team-member-modal .bio p {
    font-size: 18px;
    line-height: 25px;
    margin: 0 0 30px; }

.cross-home-page-container .home-page-header-content .header-video-container {
  position: relative;
  margin-left: auto;
  margin-right: auto; }
  .cross-home-page-container .home-page-header-content .header-video-container .header-image,
  .cross-home-page-container .home-page-header-content .header-video-container .header-video {
    min-height: 665px;
    position: relative;
    vertical-align: bottom;
    display: flex; }
    @media all and (min-width: 640px) {
      .cross-home-page-container .home-page-header-content .header-video-container .header-image,
      .cross-home-page-container .home-page-header-content .header-video-container .header-video {
        min-height: 785px; } }
    @media all and (min-width: 960px) {
      .cross-home-page-container .home-page-header-content .header-video-container .header-image,
      .cross-home-page-container .home-page-header-content .header-video-container .header-video {
        height: 961px; } }
    .cross-home-page-container .home-page-header-content .header-video-container .header-image img,
    .cross-home-page-container .home-page-header-content .header-video-container .header-video img {
      width: 100%;
      object-fit: cover;
      min-height: 665px; }
      @media all and (min-width: 960px) {
        .cross-home-page-container .home-page-header-content .header-video-container .header-image img,
        .cross-home-page-container .home-page-header-content .header-video-container .header-video img {
          height: 100%; } }
  .cross-home-page-container .home-page-header-content .header-video-container .header-image {
    display: flex; }
  .cross-home-page-container .home-page-header-content .header-video-container .header-video {
    overflow: hidden;
    display: block; }
    .cross-home-page-container .home-page-header-content .header-video-container .header-video .video-embed-container {
      position: relative;
      padding-bottom: 56.25%;
      height: 0; }
      .cross-home-page-container .home-page-header-content .header-video-container .header-video .video-embed-container iframe {
        position: absolute;
        top: -42px;
        left: -140px;
        width: 100%;
        height: 100%; }
        @media all and (min-width: 1200px) {
          .cross-home-page-container .home-page-header-content .header-video-container .header-video .video-embed-container iframe {
            width: 140%;
            height: 140%; } }
        @media all and (min-width: 1480px) {
          .cross-home-page-container .home-page-header-content .header-video-container .header-video .video-embed-container iframe {
            width: 120%;
            height: 120%; } }
  .cross-home-page-container .home-page-header-content .header-video-container .video-image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }
    .cross-home-page-container .home-page-header-content .header-video-container .video-image-overlay .crossover-header-container {
      background-color: unset;
      margin-bottom: 70px; }
      @media all and (min-width: 640px) {
        .cross-home-page-container .home-page-header-content .header-video-container .video-image-overlay .crossover-header-container {
          margin-bottom: 75px; } }
      @media all and (min-width: 960px) {
        .cross-home-page-container .home-page-header-content .header-video-container .video-image-overlay .crossover-header-container {
          margin-bottom: 154px; } }
      .cross-home-page-container .home-page-header-content .header-video-container .video-image-overlay .crossover-header-container.default-background-color {
        background-color: #2a2a2a; }
    .cross-home-page-container .home-page-header-content .header-video-container .video-image-overlay .top-logo-slider-container {
      color: #fefefe;
      font-weight: 600;
      font-size: 22px;
      max-width: 1440px; }
      @media all and (min-width: 640px) {
        .cross-home-page-container .home-page-header-content .header-video-container .video-image-overlay .top-logo-slider-container {
          font-size: 36px;
          margin-left: 10px; } }
      @media all and (min-width: 960px) {
        .cross-home-page-container .home-page-header-content .header-video-container .video-image-overlay .top-logo-slider-container {
          margin-left: 56px; } }
      @media all and (min-width: 1480px) {
        .cross-home-page-container .home-page-header-content .header-video-container .video-image-overlay .top-logo-slider-container {
          margin-left: auto;
          margin-right: auto; } }
      .cross-home-page-container .home-page-header-content .header-video-container .video-image-overlay .top-logo-slider-container .logo {
        margin-bottom: 48px; }
        @media all and (min-width: 1200px) {
          .cross-home-page-container .home-page-header-content .header-video-container .video-image-overlay .top-logo-slider-container .logo {
            height: auto;
            margin-bottom: 58px; } }
      .cross-home-page-container .home-page-header-content .header-video-container .video-image-overlay .top-logo-slider-container .header-text {
        margin: 0 20px 100px 20px;
        line-height: 30px; }
        @media all and (min-width: 640px) {
          .cross-home-page-container .home-page-header-content .header-video-container .video-image-overlay .top-logo-slider-container .header-text {
            margin-bottom: 75px;
            line-height: 43px; } }
        @media all and (min-width: 960px) {
          .cross-home-page-container .home-page-header-content .header-video-container .video-image-overlay .top-logo-slider-container .header-text {
            margin-bottom: 158px; } }
      .cross-home-page-container .home-page-header-content .header-video-container .video-image-overlay .top-logo-slider-container .header-slider {
        margin-left: 16px; }
        @media all and (min-width: 640px) {
          .cross-home-page-container .home-page-header-content .header-video-container .video-image-overlay .top-logo-slider-container .header-slider {
            margin-left: 0px; } }
        .cross-home-page-container .home-page-header-content .header-video-container .video-image-overlay .top-logo-slider-container .header-slider .slider-header-text {
          margin-bottom: 20px;
          line-height: 30px; }
          @media all and (min-width: 640px) {
            .cross-home-page-container .home-page-header-content .header-video-container .video-image-overlay .top-logo-slider-container .header-slider .slider-header-text {
              margin-bottom: 40px;
              line-height: 49px; } }
        .cross-home-page-container .home-page-header-content .header-video-container .video-image-overlay .top-logo-slider-container .header-slider .uk-slider {
          position: relative; }
        .cross-home-page-container .home-page-header-content .header-video-container .video-image-overlay .top-logo-slider-container .header-slider .uk-slider-items.uk-grid {
          cursor: ew-resize; }
          @media all and (min-width: 640px) {
            .cross-home-page-container .home-page-header-content .header-video-container .video-image-overlay .top-logo-slider-container .header-slider .uk-slider-items.uk-grid {
              margin-left: -20px; }
              .cross-home-page-container .home-page-header-content .header-video-container .video-image-overlay .top-logo-slider-container .header-slider .uk-slider-items.uk-grid > * {
                padding-left: 20px; } }
        .cross-home-page-container .home-page-header-content .header-video-container .video-image-overlay .top-logo-slider-container .header-slider li {
          padding-bottom: 0; }
          .cross-home-page-container .home-page-header-content .header-video-container .video-image-overlay .top-logo-slider-container .header-slider li a {
            cursor: inherit; }
          .cross-home-page-container .home-page-header-content .header-video-container .video-image-overlay .top-logo-slider-container .header-slider li .article-container {
            position: relative;
            width: 278px; }
            @media all and (min-width: 640px) {
              .cross-home-page-container .home-page-header-content .header-video-container .video-image-overlay .top-logo-slider-container .header-slider li .article-container {
                width: 404px; } }
            .cross-home-page-container .home-page-header-content .header-video-container .video-image-overlay .top-logo-slider-container .header-slider li .article-container .thumbnail {
              max-width: 278px;
              height: 137px; }
              @media all and (min-width: 640px) {
                .cross-home-page-container .home-page-header-content .header-video-container .video-image-overlay .top-logo-slider-container .header-slider li .article-container .thumbnail {
                  max-width: unset;
                  height: 200px;
                  width: 404px; } }
              .cross-home-page-container .home-page-header-content .header-video-container .video-image-overlay .top-logo-slider-container .header-slider li .article-container .thumbnail img {
                height: 100%;
                width: 100%;
                object-fit: cover; }
            .cross-home-page-container .home-page-header-content .header-video-container .video-image-overlay .top-logo-slider-container .header-slider li .article-container .thumbnail-overlay {
              background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0) 1.56%, rgba(0, 0, 0, 0.8) 100%);
              height: 100%;
              align-items: flex-start;
              display: flex;
              flex-direction: column;
              justify-content: end;
              width: 100%;
              box-sizing: border-box;
              position: absolute;
              left: 0px;
              bottom: 0px;
              font-size: 12px;
              padding: 0 10px 16px 10px; }
              @media all and (min-width: 640px) {
                .cross-home-page-container .home-page-header-content .header-video-container .video-image-overlay .top-logo-slider-container .header-slider li .article-container .thumbnail-overlay {
                  font-size: 14px;
                  padding: 0 20px 18px 20px; } }
              .cross-home-page-container .home-page-header-content .header-video-container .video-image-overlay .top-logo-slider-container .header-slider li .article-container .thumbnail-overlay .headline h5 {
                color: #fefefe;
                font-weight: 700;
                margin-bottom: 9px; }
                @media all and (min-width: 640px) {
                  .cross-home-page-container .home-page-header-content .header-video-container .video-image-overlay .top-logo-slider-container .header-slider li .article-container .thumbnail-overlay .headline h5 {
                    margin-bottom: 11px; } }
              .cross-home-page-container .home-page-header-content .header-video-container .video-image-overlay .top-logo-slider-container .header-slider li .article-container .thumbnail-overlay .text {
                font-size: inherit;
                color: #fefefe;
                font-weight: 400;
                margin-right: 33px; }
                @media all and (min-width: 640px) {
                  .cross-home-page-container .home-page-header-content .header-video-container .video-image-overlay .top-logo-slider-container .header-slider li .article-container .thumbnail-overlay .text {
                    margin-right: 43px; } }

.cross-home-page-container .vendor-partners-section {
  max-width: 1470px;
  padding-top: 70px;
  padding-bottom: 60px; }
  @media all and (min-width: 960px) {
    .cross-home-page-container .vendor-partners-section {
      padding-top: 130px;
      padding-bottom: 97px; } }
  .cross-home-page-container .vendor-partners-section h2 {
    margin-bottom: 25px; }
    @media all and (min-width: 640px) {
      .cross-home-page-container .vendor-partners-section h2 {
        margin-bottom: 45px; } }
  .cross-home-page-container .vendor-partners-section .uk-grid {
    margin-left: -15px; }
    .cross-home-page-container .vendor-partners-section .uk-grid > * {
      padding-left: 15px; }
  .cross-home-page-container .vendor-partners-section .vendor-partner {
    margin: 25px 0; }
    @media all and (min-width: 640px) {
      .cross-home-page-container .vendor-partners-section .vendor-partner .vendor-partner-image-container {
        width: 150px; } }
    .cross-home-page-container .vendor-partners-section .vendor-partner .vendor-partner-image-container img {
      max-height: 100px;
      object-fit: contain; }
  .cross-home-page-container .vendor-partners-section .vendor-partners-mobile .uk-slider-nav {
    margin-top: 6px; }
    .cross-home-page-container .vendor-partners-section .vendor-partners-mobile .uk-slider-nav li {
      padding-bottom: 0;
      margin-bottom: 0; }

.cross-home-page-container .our-products-section {
  background-color: #2a2a2a;
  background-image: url("../../dist/images/crossover-pyramid-horizontal.png");
  background-position: top right;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fefefe;
  padding-top: 60px;
  padding-bottom: 67px; }
  @media all and (min-width: 960px) {
    .cross-home-page-container .our-products-section {
      padding-top: 120px;
      padding-bottom: 227px; } }
  .cross-home-page-container .our-products-section .our-products-section-container {
    max-width: 1247px; }
    .cross-home-page-container .our-products-section .our-products-section-container .products-heading h2 {
      color: inherit;
      margin-bottom: 50px; }
      @media all and (min-width: 960px) {
        .cross-home-page-container .our-products-section .our-products-section-container .products-heading h2 {
          margin-bottom: 100px; } }
    .cross-home-page-container .our-products-section .our-products-section-container .products-container ul {
      column-gap: 42px; }
      @media all and (min-width: 768px) {
        .cross-home-page-container .our-products-section .our-products-section-container .products-container ul.uk-accordion {
          display: grid;
          grid-template-columns: repeat(2, 1fr); } }
      @media all and (min-width: 1480px) {
        .cross-home-page-container .our-products-section .our-products-section-container .products-container ul.uk-accordion {
          column-gap: 165px; } }
      .cross-home-page-container .our-products-section .our-products-section-container .products-container ul.uk-accordion > :nth-child(n + 2) {
        margin-top: unset; }
    .cross-home-page-container .our-products-section .our-products-section-container .products-container li.product-category-container {
      max-width: 541px;
      width: 100%;
      padding-bottom: 42px; }
      @media all and (min-width: 1200px) {
        .cross-home-page-container .our-products-section .our-products-section-container .products-container li.product-category-container {
          padding-bottom: 84px; } }
      .cross-home-page-container .our-products-section .our-products-section-container .products-container li.product-category-container a {
        color: #fefefe;
        font-size: 20px;
        line-height: 27px;
        font-weight: 600; }
        @media all and (min-width: 960px) {
          .cross-home-page-container .our-products-section .our-products-section-container .products-container li.product-category-container a {
            font-size: 36px;
            line-height: 49px; } }
        .cross-home-page-container .our-products-section .our-products-section-container .products-container li.product-category-container a .category-item {
          padding: 4px 1.5px 4px 0; }
          .cross-home-page-container .our-products-section .our-products-section-container .products-container li.product-category-container a .category-item .category-name {
            margin-left: 20px;
            color: inherit; }
            @media all and (min-width: 1200px) {
              .cross-home-page-container .our-products-section .our-products-section-container .products-container li.product-category-container a .category-item .category-name {
                margin-left: 40px;
                min-width: 341px; } }
          .cross-home-page-container .our-products-section .our-products-section-container .products-container li.product-category-container a .category-item .arrow-container img {
            margin-right: 10px;
            margin-left: 10px; }
            @media all and (min-width: 960px) {
              .cross-home-page-container .our-products-section .our-products-section-container .products-container li.product-category-container a .category-item .arrow-container img {
                margin-left: 40px; } }
          .cross-home-page-container .our-products-section .our-products-section-container .products-container li.product-category-container a .category-item:hover {
            text-decoration: none;
            padding-bottom: 3px;
            border-bottom: 1px solid #80fff3; }
      .cross-home-page-container .our-products-section .our-products-section-container .products-container li.product-category-container .accordion-content {
        margin-top: 10px; }
        .cross-home-page-container .our-products-section .our-products-section-container .products-container li.product-category-container .accordion-content ul {
          padding: 16px 0 6px 0;
          margin-bottom: 0;
          background-color: rgba(255, 255, 255, 0.18);
          backdrop-filter: blur(10px); }
          .cross-home-page-container .our-products-section .our-products-section-container .products-container li.product-category-container .accordion-content ul li {
            list-style: none;
            color: #fefefe;
            position: relative;
            margin-bottom: 10px;
            padding-bottom: 0; }
            .cross-home-page-container .our-products-section .our-products-section-container .products-container li.product-category-container .accordion-content ul li:hover {
              background-color: rgba(254, 254, 254, 0.38); }
            .cross-home-page-container .our-products-section .our-products-section-container .products-container li.product-category-container .accordion-content ul li a {
              font-size: 18px;
              line-height: 24px;
              font-weight: 400;
              text-decoration: none;
              display: flex;
              padding: 8px 10px 8px 44px; }
              @media all and (min-width: 960px) {
                .cross-home-page-container .our-products-section .our-products-section-container .products-container li.product-category-container .accordion-content ul li a {
                  padding: 8.5px 10px 8.5px 56px; } }
              .cross-home-page-container .our-products-section .our-products-section-container .products-container li.product-category-container .accordion-content ul li a::before {
                content: "";
                height: 2px;
                width: 16px;
                background-color: #fefefe;
                position: absolute;
                left: 20px;
                top: 18px; }
            .cross-home-page-container .our-products-section .our-products-section-container .products-container li.product-category-container .accordion-content ul li::before {
              content: "";
              display: none; }
        .cross-home-page-container .our-products-section .our-products-section-container .products-container li.product-category-container .accordion-content.show {
          display: block; }
      .cross-home-page-container .our-products-section .our-products-section-container .products-container li.product-category-container:not(.uk-open) .arrow-container .arrow-up {
        display: none; }
      .cross-home-page-container .our-products-section .our-products-section-container .products-container li.product-category-container.uk-open .arrow-container .arrow-down {
        display: none; }
      .cross-home-page-container .our-products-section .our-products-section-container .products-container li.product-category-container.uk-open .arrow-container .arrow-up {
        display: block; }

.cross-home-page-container .why-crossover-section .why-crossover-section-container {
  max-width: 1328px;
  padding: 60px 0 80px 0; }
  @media all and (min-width: 960px) {
    .cross-home-page-container .why-crossover-section .why-crossover-section-container {
      padding: 120px 0 120px 0; } }
  .cross-home-page-container .why-crossover-section .why-crossover-section-container .why-choose-heading-container {
    margin-bottom: 40px; }
    @media all and (min-width: 640px) {
      .cross-home-page-container .why-crossover-section .why-crossover-section-container .why-choose-heading-container {
        margin-bottom: 61px; } }
  .cross-home-page-container .why-crossover-section .why-crossover-section-container .sub-heading-container {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    background-image: url("../../dist/images/why-choose-subheading-background.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    min-height: 406px;
    margin-bottom: 30px; }
    @media all and (min-width: 640px) {
      .cross-home-page-container .why-crossover-section .why-crossover-section-container .sub-heading-container {
        background-image: unset;
        min-height: unset;
        margin-bottom: unset; } }
    .cross-home-page-container .why-crossover-section .why-crossover-section-container .sub-heading-container .sub-heading {
      margin-bottom: 0;
      color: #fefefe;
      font-size: 22px;
      padding: 0 29px 44px 29px; }
      @media all and (min-width: 640px) {
        .cross-home-page-container .why-crossover-section .why-crossover-section-container .sub-heading-container .sub-heading {
          color: #181f23;
          font-size: 32px;
          padding: 0 0 61px 0; } }
      .cross-home-page-container .why-crossover-section .why-crossover-section-container .sub-heading-container .sub-heading p {
        margin: 0; }
      .cross-home-page-container .why-crossover-section .why-crossover-section-container .sub-heading-container .sub-heading strong {
        font-weight: 700; }
  .cross-home-page-container .why-crossover-section .why-crossover-section-container .why-choose-content-container {
    font-size: 16px;
    padding: 0 16px; }
    @media all and (min-width: 960px) {
      .cross-home-page-container .why-crossover-section .why-crossover-section-container .why-choose-content-container {
        font-size: 24px;
        padding: 0; } }
    @media all and (min-width: 960px) {
      .cross-home-page-container .why-crossover-section .why-crossover-section-container .why-choose-content-container .uk-grid {
        gap: 80px; } }
    .cross-home-page-container .why-crossover-section .why-crossover-section-container .why-choose-content-container .why-choose-content {
      margin-bottom: 41px; }
      @media all and (min-width: 640px) {
        .cross-home-page-container .why-crossover-section .why-crossover-section-container .why-choose-content-container .why-choose-content {
          margin-bottom: 53px; } }
    @media all and (min-width: 640px) {
      .cross-home-page-container .why-crossover-section .why-crossover-section-container .why-choose-content-container .why-choose-list-container {
        background-image: url("../../dist/images/why-choose-background.jpeg");
        background-position: center;
        background-repeat: no-repeat;
        min-height: 800px; } }
    .cross-home-page-container .why-crossover-section .why-crossover-section-container .why-choose-content-container .why-choose-list-container .why-choose-list {
      width: 100%;
      column-count: 1;
      font-size: 14px; }
      @media all and (min-width: 640px) {
        .cross-home-page-container .why-crossover-section .why-crossover-section-container .why-choose-content-container .why-choose-list-container .why-choose-list {
          column-count: 2;
          font-size: 24px;
          font-weight: 700;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.85) 13.67%, rgba(0, 0, 0, 0) 105.77%);
          padding: 0 18px 71px 68px;
          color: #fefefe; } }
      .cross-home-page-container .why-crossover-section .why-crossover-section-container .why-choose-content-container .why-choose-list-container .why-choose-list ul {
        padding: 0; }
        .cross-home-page-container .why-crossover-section .why-crossover-section-container .why-choose-content-container .why-choose-list-container .why-choose-list ul li {
          position: relative;
          list-style: none;
          padding-left: 27px;
          margin-bottom: 22px;
          padding-bottom: 0; }
          @media all and (min-width: 960px) {
            .cross-home-page-container .why-crossover-section .why-crossover-section-container .why-choose-content-container .why-choose-list-container .why-choose-list ul li {
              padding-left: 38px;
              margin-bottom: 20px; } }
    .cross-home-page-container .why-crossover-section .why-crossover-section-container .why-choose-content-container .become-partner-button-container {
      margin-top: 49px; }
      @media all and (min-width: 960px) {
        .cross-home-page-container .why-crossover-section .why-crossover-section-container .why-choose-content-container .become-partner-button-container {
          margin-top: 79px; } }
      .cross-home-page-container .why-crossover-section .why-crossover-section-container .why-choose-content-container .become-partner-button-container .crossover-button {
        text-align: center;
        text-transform: uppercase;
        line-height: 22px;
        display: inline-block;
        border-radius: 4px;
        padding: 16px 10px;
        width: fit-content;
        cursor: pointer;
        font-weight: 700;
        -webkit-appearance: unset;
        font-size: 12px;
        color: #80fff3;
        background-color: #2a2a2a;
        border: 2px solid #80fff3;
        font-size: 16px;
        padding: 16px 0;
        width: 100%; }
        @media all and (min-width: 640px) {
          .cross-home-page-container .why-crossover-section .why-crossover-section-container .why-choose-content-container .become-partner-button-container .crossover-button {
            font-size: 16px; } }
        .cross-home-page-container .why-crossover-section .why-crossover-section-container .why-choose-content-container .become-partner-button-container .crossover-button:hover {
          text-decoration: none; }
        .cross-home-page-container .why-crossover-section .why-crossover-section-container .why-choose-content-container .become-partner-button-container .crossover-button:hover {
          background-color: #494949; }
        @media all and (min-width: 640px) {
          .cross-home-page-container .why-crossover-section .why-crossover-section-container .why-choose-content-container .become-partner-button-container .crossover-button {
            padding: 16px 100px;
            width: fit-content; } }

.cross-home-page-container .services-section {
  background-color: #2a2a2a;
  background-image: url("../../dist/images/services-homepage-background.png");
  background-position: top right;
  background-size: auto;
  background-repeat: no-repeat;
  color: #fefefe;
  padding-top: 60px;
  padding-bottom: 20px; }
  @media all and (min-width: 960px) {
    .cross-home-page-container .services-section {
      padding-top: 120px;
      padding-bottom: 49px; } }
  .cross-home-page-container .services-section .services-section-container {
    max-width: 1339px; }
    .cross-home-page-container .services-section .services-section-container .services-heading h2 {
      color: inherit;
      margin-bottom: 40px; }
      @media all and (min-width: 960px) {
        .cross-home-page-container .services-section .services-section-container .services-heading h2 {
          margin-bottom: 138px; } }
    .cross-home-page-container .services-section .services-section-container .products-container ul {
      column-gap: 8px; }
      @media all and (min-width: 768px) {
        .cross-home-page-container .services-section .services-section-container .products-container ul.uk-accordion {
          display: grid;
          grid-template-columns: repeat(2, 1fr); } }
      @media all and (min-width: 1480px) {
        .cross-home-page-container .services-section .services-section-container .products-container ul.uk-accordion {
          column-gap: 66px; } }
      .cross-home-page-container .services-section .services-section-container .products-container ul.uk-accordion > :nth-child(n + 2) {
        margin-top: unset; }
    .cross-home-page-container .services-section .services-section-container .products-container li.product-category-container {
      max-width: 638px;
      width: 100%;
      padding-bottom: 40px; }
      @media all and (min-width: 1200px) {
        .cross-home-page-container .services-section .services-section-container .products-container li.product-category-container {
          padding-bottom: 180px; } }
      .cross-home-page-container .services-section .services-section-container .products-container li.product-category-container a {
        color: #fefefe;
        font-weight: 600; }
        .cross-home-page-container .services-section .services-section-container .products-container li.product-category-container a .category-item {
          padding: 4px 1.5px 4px 0; }
          .cross-home-page-container .services-section .services-section-container .products-container li.product-category-container a .category-item .category-icon {
            font-family: "Inter", Arial, sans-serif;
            color: #80fff3;
            font-weight: 600;
            line-height: 1;
            letter-spacing: 0em;
            font-size: 60px; }
            @media all and (min-width: 960px) {
              .cross-home-page-container .services-section .services-section-container .products-container li.product-category-container a .category-item .category-icon {
                font-size: 150px; } }
          .cross-home-page-container .services-section .services-section-container .products-container li.product-category-container a .category-item .category-name {
            margin-left: 20px;
            font-size: 20px;
            line-height: 37px;
            color: inherit; }
            @media all and (min-width: 640px) {
              .cross-home-page-container .services-section .services-section-container .products-container li.product-category-container a .category-item .category-name {
                font-size: 36px; } }
            @media all and (min-width: 960px) {
              .cross-home-page-container .services-section .services-section-container .products-container li.product-category-container a .category-item .category-name {
                margin-left: 40px;
                line-height: 49px; } }
          .cross-home-page-container .services-section .services-section-container .products-container li.product-category-container a .category-item .arrow-container img {
            margin-right: 10px;
            margin-left: 10px; }
            @media all and (min-width: 960px) {
              .cross-home-page-container .services-section .services-section-container .products-container li.product-category-container a .category-item .arrow-container img {
                margin-left: 40px; } }
          .cross-home-page-container .services-section .services-section-container .products-container li.product-category-container a .category-item:hover {
            border-bottom: 1px solid #80fff3;
            padding-bottom: 3px;
            text-decoration: none; }
      .cross-home-page-container .services-section .services-section-container .products-container li.product-category-container .accordion-content {
        padding: 12px;
        margin-bottom: 0;
        background-color: rgba(255, 255, 255, 0.18);
        backdrop-filter: blur(10px);
        margin-top: 10px; }
        .cross-home-page-container .services-section .services-section-container .products-container li.product-category-container .accordion-content a {
          font-size: 18px;
          line-height: 24px;
          font-weight: 400;
          text-decoration: none; }
          .cross-home-page-container .services-section .services-section-container .products-container li.product-category-container .accordion-content a p {
            color: inherit; }
        .cross-home-page-container .services-section .services-section-container .products-container li.product-category-container .accordion-content.show {
          display: block; }
      .cross-home-page-container .services-section .services-section-container .products-container li.product-category-container:not(.uk-open) .arrow-container .arrow-up {
        display: none; }
      .cross-home-page-container .services-section .services-section-container .products-container li.product-category-container.uk-open .arrow-container .arrow-down {
        display: none; }
      .cross-home-page-container .services-section .services-section-container .products-container li.product-category-container.uk-open .arrow-container .arrow-up {
        display: block; }
      @media all and (min-width: 768px) {
        .cross-home-page-container .services-section .services-section-container .products-container li.product-category-container:nth-child(2) {
          grid-row: 2/3; } }

.cross-home-page-container .testimonials-section {
  background-color: #2a2a2a;
  padding: 60px 16px 30px 16px; }
  @media all and (min-width: 960px) {
    .cross-home-page-container .testimonials-section {
      padding: 120px 0 60px 55px; } }
  .cross-home-page-container .testimonials-section .testimonials-section-container {
    color: #fefefe; }
    .cross-home-page-container .testimonials-section .testimonials-section-container .testimonials-heading h2 {
      color: inherit;
      margin-bottom: 50px; }
      @media all and (min-width: 640px) {
        .cross-home-page-container .testimonials-section .testimonials-section-container .testimonials-heading h2 {
          margin-bottom: 50px; } }
    @media all and (min-width: 640px) {
      .cross-home-page-container .testimonials-section .testimonials-section-container .uk-grid {
        margin-left: -60px; }
        .cross-home-page-container .testimonials-section .testimonials-section-container .uk-grid > * {
          padding-left: 60px; } }
    .cross-home-page-container .testimonials-section .testimonials-section-container .testimonial-container {
      font-size: 14px;
      border-left: 1px solid #80fff3;
      border-bottom: 1px solid #80fff3;
      padding: 4px 2px 20px 20px;
      margin-bottom: 50px;
      min-height: 348px; }
      @media all and (min-width: 640px) {
        .cross-home-page-container .testimonials-section .testimonials-section-container .testimonial-container {
          padding: 4px 2px 0px 20px;
          width: 300px; } }
      .cross-home-page-container .testimonials-section .testimonials-section-container .testimonial-container .text-container {
        margin-bottom: 48px;
        min-height: 225px; }
      .cross-home-page-container .testimonials-section .testimonials-section-container .testimonial-container .info-container {
        font-weight: 700;
        font-size: 16px; }
        .cross-home-page-container .testimonials-section .testimonials-section-container .testimonial-container .info-container .testimonial-name {
          color: #fefefe;
          font-weight: 700;
          font-size: 16px;
          line-height: 18px;
          margin-bottom: 10px; }
        .cross-home-page-container .testimonials-section .testimonials-section-container .testimonial-container .info-container .testimonial-company {
          color: #fefefe;
          text-transform: initial;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          margin: 0; }
    .cross-home-page-container .testimonials-section .testimonials-section-container .navigation-container {
      color: #80fff3; }
      .cross-home-page-container .testimonials-section .testimonials-section-container .navigation-container .navigation {
        display: flex;
        width: 100%;
        justify-content: space-between; }
        @media all and (min-width: 640px) {
          .cross-home-page-container .testimonials-section .testimonials-section-container .navigation-container .navigation {
            display: block;
            width: unset;
            justify-content: unset; } }
        @media all and (min-width: 1200px) {
          .cross-home-page-container .testimonials-section .testimonials-section-container .navigation-container .navigation {
            margin-right: 39px; } }
        .cross-home-page-container .testimonials-section .testimonials-section-container .navigation-container .navigation a {
          color: inherit; }
          .cross-home-page-container .testimonials-section .testimonials-section-container .navigation-container .navigation a img.arrow-left {
            margin: 0 16px; }
            @media all and (min-width: 640px) {
              .cross-home-page-container .testimonials-section .testimonials-section-container .navigation-container .navigation a img.arrow-left {
                margin-left: 0; } }
          .cross-home-page-container .testimonials-section .testimonials-section-container .navigation-container .navigation a img.arrow-right {
            margin: 0 16px; }
          @media all and (min-width: 640px) {
            .cross-home-page-container .testimonials-section .testimonials-section-container .navigation-container .navigation a.next {
              margin-left: 80px; } }
          .cross-home-page-container .testimonials-section .testimonials-section-container .navigation-container .navigation a:hover {
            text-decoration: none;
            opacity: 0.68; }
          .cross-home-page-container .testimonials-section .testimonials-section-container .navigation-container .navigation a.uk-invisible {
            cursor: default;
            visibility: unset !important;
            opacity: 0.5; }
        .cross-home-page-container .testimonials-section .testimonials-section-container .navigation-container .navigation .next {
          margin-left: 50px !important; }
          @media all and (min-width: 360px) {
            .cross-home-page-container .testimonials-section .testimonials-section-container .navigation-container .navigation .next {
              margin-left: 80px !important; } }

.cross-home-page-container .case-studies-slider {
  padding-top: 60px;
  padding-bottom: 60px; }
  @media all and (min-width: 960px) {
    .cross-home-page-container .case-studies-slider {
      padding-top: 80px;
      padding-bottom: 67px; } }

.cross-home-page-container .social-media-section {
  background-color: #2a2a2a;
  background-image: url("../../dist/images/home-social-media-background.png");
  background-size: auto;
  background-repeat: no-repeat;
  color: #fefefe;
  padding-bottom: 61px;
  padding: 0 16px 61px 16px;
  background-position: 0 -52%; }
  @media all and (min-width: 960px) {
    .cross-home-page-container .social-media-section {
      padding: 100px 0 122px 0;
      background-position: 0 70%; } }
  .cross-home-page-container .social-media-section .social-media-section-container {
    color: #fefefe; }
    .cross-home-page-container .social-media-section .social-media-section-container .social-media-heading h2 {
      color: inherit;
      margin-bottom: 30px; }
      @media all and (min-width: 640px) {
        .cross-home-page-container .social-media-section .social-media-section-container .social-media-heading h2 {
          margin-bottom: 60px; } }
    .cross-home-page-container .social-media-section .social-media-section-container .social-media-icons {
      margin-bottom: 60px;
      font-size: 32px; }
      @media all and (min-width: 640px) {
        .cross-home-page-container .social-media-section .social-media-section-container .social-media-icons {
          margin-bottom: 106px;
          font-size: 48px; } }
      .cross-home-page-container .social-media-section .social-media-section-container .social-media-icons a {
        color: #fefefe; }
        .cross-home-page-container .social-media-section .social-media-section-container .social-media-icons a:not(:last-child) {
          margin-right: 40px; }
          @media all and (min-width: 640px) {
            .cross-home-page-container .social-media-section .social-media-section-container .social-media-icons a:not(:last-child) {
              margin-right: 150px; } }
        .cross-home-page-container .social-media-section .social-media-section-container .social-media-icons a:hover {
          color: #80fff3; }
    .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list {
      max-width: 1660px; }
      @media all and (min-width: 640px) {
        .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list {
          margin-left: 20px; } }
      @media all and (min-width: 960px) {
        .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list {
          margin-left: 55px; } }
      @media all and (min-width: 1480px) {
        .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list {
          margin-left: auto;
          margin-right: auto; } }
      .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed {
        /* width */
        /* Track */
        /* Handle */
        /* Handle on hover */ }
        .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .referral {
          display: none; }
        .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed ::-webkit-scrollbar {
          height: 10px; }
        .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed ::-webkit-scrollbar-track {
          background: #2a2a2a; }
        .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed ::-webkit-scrollbar-thumb {
          background: #181f23; }
        .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed ::-webkit-scrollbar-thumb:hover {
          background: #80fff3; }
        .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker {
          display: flex;
          flex-direction: column;
          padding-right: 0; }
          @media all and (min-width: 640px) {
            .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker {
              padding-right: 30px;
              flex-direction: row;
              overflow-x: scroll; } }
          .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker > * {
            display: block !important; }
            @media all and (min-width: 640px) {
              .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker > * {
                display: flex !important; } }
          .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn {
            background-color: #2a2a2a;
            float: left !important;
            width: 100% !important;
            margin-top: 0;
            margin-bottom: 0; }
            @media all and (min-width: 640px) {
              .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn {
                margin-bottom: unset;
                margin-block-start: 0px;
                width: unset !important; } }
            .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item {
              background: unset;
              border: 2px solid #80fff3;
              display: flex;
              cursor: pointer;
              min-height: 124px;
              padding: 10px; }
              @media all and (min-width: 640px) {
                .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item {
                  margin-bottom: unset !important;
                  width: 537px;
                  padding: 20px; }
                  .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item:not(:first-child) {
                    margin-left: 20px; } }
              .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item .j-poster {
                display: none;
                padding: 12px 16px 18px; }
                @media all and (min-width: 640px) {
                  .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item .j-poster {
                    padding: 12px 23px 8px; } }
                .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item .j-poster a {
                  color: #fefefe; }
                  .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item .j-poster a .j-twitter-poster {
                    color: inherit;
                    margin-top: 4px; }
                    .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item .j-poster a .j-twitter-poster .j-twitter-display-name {
                      font-size: 16px;
                      font-family: "Nunito Sans", Arial, sans-serif; }
                    .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item .j-poster a .j-twitter-poster .j-twitter-screen-name {
                      display: none; }
                  .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item .j-poster a.j-twitter-text::before {
                    color: inherit;
                    font-size: 19px; }
                    @media all and (min-width: 640px) {
                      .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item .j-poster a.j-twitter-text::before {
                        font-size: 24px; } }
                    .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item .j-poster a.j-twitter-text::before:hover {
                      color: #80fff3; }
                  .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item .j-poster a:first-child img {
                    display: none; }
              .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item .j-image {
                width: 100px;
                min-width: 100px;
                height: 100px;
                min-height: 100px;
                max-height: 100px; }
                @media all and (min-width: 640px) {
                  .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item .j-image {
                    width: 200px;
                    min-width: 200px;
                    height: 200px;
                    min-height: 200px;
                    max-height: 200px; } }
                .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item .j-image img {
                  width: 100% !important;
                  height: 100% !important;
                  object-fit: cover; }
              .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item .j-text {
                padding-top: unset; }
                .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item .j-text .j-message {
                  font-family: "Nunito Sans", Arial, sans-serif;
                  display: inline-block;
                  color: #fefefe;
                  font-size: 12px;
                  line-height: 19px;
                  font-weight: 400; }
                  @media all and (min-width: 640px) {
                    .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item .j-text .j-message {
                      font-size: 14px; } }
                  .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item .j-text .j-message br {
                    content: " "; }
                    .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item .j-text .j-message br:after {
                      content: " "; }
                  .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item .j-text .j-message .j-retweet {
                    display: none; }
                  .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item .j-text .j-message p {
                    color: inherit;
                    font-size: 16px;
                    line-height: 20px;
                    float: left; }
                    .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item .j-text .j-message p:last-child {
                      margin: 0; }
                  .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item .j-text .j-message a {
                    color: #80fff3; }
                    .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item .j-text .j-message a:hover {
                      color: #80fff3;
                      text-decoration: underline !important; }
                  .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item .j-text .j-message .j-read-more {
                    display: none; }
                .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item .j-text .j-image {
                  display: inline; }
                  .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item .j-text .j-image::before {
                    content: "";
                    display: none; }
                  .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item .j-text .j-image .j-content-image {
                    float: left;
                    margin-top: -37px;
                    max-height: 154px;
                    object-fit: cover;
                    max-width: 64px;
                    margin-bottom: 20px; }
                    @media all and (min-width: 640px) {
                      .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item .j-text .j-image .j-content-image {
                        max-width: 153px; } }
                .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item .j-text .j-meta {
                  display: none; }
              .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item.image-post .j-poster, .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item.j-video .j-poster {
                padding-left: 103px; }
                @media all and (min-width: 640px) {
                  .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item.image-post .j-poster, .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item.j-video .j-poster {
                    padding-left: 193px; } }
              .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item.image-post .j-text .j-message, .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item.j-video .j-text .j-message {
                margin-left: 10px; }
                @media all and (min-width: 640px) {
                  .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item.image-post .j-text .j-message, .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item.j-video .j-text .j-message {
                    max-width: 324px;
                    margin-left: 20px; } }
              .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item.words .j-poster {
                padding-left: 20px; }
                @media all and (min-width: 640px) {
                  .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item.words .j-poster {
                    padding-left: 20px; } }
              @media all and (min-width: 640px) {
                .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item.words .j-text .j-message {
                  max-width: 493px; } }
              .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item.juicer {
                display: none; }
              .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker .galcolumn .feed-item:hover {
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.22), rgba(0, 0, 0, 0.09), rgba(43, 215, 193, 0.34)); }
          .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-stacker-wrapper .j-stacker li::before {
            content: "";
            display: none; }
        .cross-home-page-container .social-media-section .social-media-section-container .twitter-slider-list .juicer-feed .j-paginate {
          display: none; }

.cross-products-services-page-container .page-header-container {
  padding-top: 51px;
  padding-bottom: 105px; }
  @media all and (min-width: 640px) {
    .cross-products-services-page-container .page-header-container {
      padding-top: 102px;
      padding-bottom: 110px; } }
  .cross-products-services-page-container .page-header-container h1 {
    color: #fefefe; }

.cross-products-services-page-container .page-content {
  background-color: #2a2a2a;
  background-image: url("../../dist/images/crossover-pyramid-vertical.png");
  background-position: 100% -40px;
  background-repeat: no-repeat; }
  .cross-products-services-page-container .page-content .uk-container {
    max-width: 1250px; }
  .cross-products-services-page-container .page-content .all-categoties {
    margin-bottom: 41px; }
    .cross-products-services-page-container .page-content .all-categoties .object-category {
      margin-bottom: 20px; }
      .cross-products-services-page-container .page-content .all-categoties .object-category a {
        font-size: 20px;
        border-bottom: 4px solid transparent; }
        .cross-products-services-page-container .page-content .all-categoties .object-category a .category-icon {
          min-height: 50px;
          margin-bottom: 24px; }
        .cross-products-services-page-container .page-content .all-categoties .object-category a .category-number {
          font-size: 80px;
          line-height: 97px;
          font-weight: 600;
          color: #80fff3;
          margin-bottom: 24px; }
        .cross-products-services-page-container .page-content .all-categoties .object-category a .category-name {
          min-height: 54px;
          max-width: 186px;
          color: #fefefe;
          margin-bottom: 10px;
          font-weight: 700; }
        .cross-products-services-page-container .page-content .all-categoties .object-category a:hover {
          text-decoration: none; }
          .cross-products-services-page-container .page-content .all-categoties .object-category a:hover .category-name {
            color: #80fff3; }
      .cross-products-services-page-container .page-content .all-categoties .object-category.uk-active a, .cross-products-services-page-container .page-content .all-categoties .object-category:hover a {
        border-bottom: 4px solid #80fff3; }
  .cross-products-services-page-container .page-content .all-items {
    max-width: 1227px;
    padding-bottom: 120px; }
    .cross-products-services-page-container .page-content .all-items .object-container {
      background: #2a2a2a;
      border: 2px solid #80fff3;
      border-radius: 4px;
      margin-bottom: 40px; }
      .cross-products-services-page-container .page-content .all-items .object-container a {
        font-size: 26px; }
        @media all and (min-width: 640px) {
          .cross-products-services-page-container .page-content .all-items .object-container a {
            font-size: 36px; } }
        .cross-products-services-page-container .page-content .all-items .object-container a .object-list-item {
          padding: 10px;
          column-gap: 10px;
          row-gap: 10px; }
          @media all and (min-width: 640px) {
            .cross-products-services-page-container .page-content .all-items .object-container a .object-list-item {
              flex-direction: row; } }
          @media all and (min-width: 1200px) {
            .cross-products-services-page-container .page-content .all-items .object-container a .object-list-item {
              padding: 20px 60px 20px 20px;
              column-gap: 30px; } }
          .cross-products-services-page-container .page-content .all-items .object-container a .object-list-item .object-thumbnail {
            display: flex;
            height: 135px;
            width: 200px; }
            .cross-products-services-page-container .page-content .all-items .object-container a .object-list-item .object-thumbnail img {
              height: 100%;
              object-fit: cover; }
          .cross-products-services-page-container .page-content .all-items .object-container a .object-list-item .object-name h3 {
            color: #fefefe;
            font-size: inherit; }
          .cross-products-services-page-container .page-content .all-items .object-container a .object-list-item i {
            color: #80fff3; }
            @media all and (min-width: 640px) {
              .cross-products-services-page-container .page-content .all-items .object-container a .object-list-item i {
                padding-right: 50px; } }
          .cross-products-services-page-container .page-content .all-items .object-container a .object-list-item:hover {
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.22), rgba(0, 0, 0, 0.09), rgba(43, 215, 193, 0.34));
            backdrop-filter: blur(10px); }
        .cross-products-services-page-container .page-content .all-items .object-container a:hover {
          text-decoration: none; }
      .cross-products-services-page-container .page-content .all-items .object-container .object-info-container {
        background-color: #fefefe;
        padding: 10px;
        gap: 30px; }
        @media all and (min-width: 640px) {
          .cross-products-services-page-container .page-content .all-items .object-container .object-info-container {
            padding: 30px 60px 30px 30px; } }
        @media all and (min-width: 1200px) {
          .cross-products-services-page-container .page-content .all-items .object-container .object-info-container {
            gap: 60px;
            flex-direction: row; } }
        .cross-products-services-page-container .page-content .all-items .object-container .object-info-container .object-image {
          height: 200px; }
          @media all and (min-width: 640px) {
            .cross-products-services-page-container .page-content .all-items .object-container .object-info-container .object-image {
              min-width: 425px; } }
          .cross-products-services-page-container .page-content .all-items .object-container .object-info-container .object-image.service-image {
            height: 230px; }
            @media all and (min-width: 640px) {
              .cross-products-services-page-container .page-content .all-items .object-container .object-info-container .object-image.service-image {
                min-width: 425px; } }
          .cross-products-services-page-container .page-content .all-items .object-container .object-info-container .object-image img {
            height: 100%;
            object-fit: contain; }
            @media all and (min-width: 960px) {
              .cross-products-services-page-container .page-content .all-items .object-container .object-info-container .object-image img {
                width: 100%; } }
        .cross-products-services-page-container .page-content .all-items .object-container .object-info-container .object-info .object-name {
          margin-bottom: 24px; }
          .cross-products-services-page-container .page-content .all-items .object-container .object-info-container .object-info .object-name h3 {
            font-size: 36px; }
          .cross-products-services-page-container .page-content .all-items .object-container .object-info-container .object-info .object-name i {
            color: #2a2a2a; }
        .cross-products-services-page-container .page-content .all-items .object-container .object-info-container .object-info .object-description {
          margin-bottom: 40px; }
        .cross-products-services-page-container .page-content .all-items .object-container .object-info-container .object-info .object-logos {
          margin-bottom: 60px; }
          .cross-products-services-page-container .page-content .all-items .object-container .object-info-container .object-info .object-logos.uk-grid {
            margin-left: -20px; }
            .cross-products-services-page-container .page-content .all-items .object-container .object-info-container .object-info .object-logos.uk-grid > * {
              padding-left: 20px; }
          .cross-products-services-page-container .page-content .all-items .object-container .object-info-container .object-info .object-logos .logo-container {
            width: 100%; }
            .cross-products-services-page-container .page-content .all-items .object-container .object-info-container .object-info .object-logos .logo-container img {
              width: 100%;
              max-height: 100px;
              object-fit: contain; }
        .cross-products-services-page-container .page-content .all-items .object-container .object-info-container .object-info .object-info-footer a {
          text-align: center;
          text-transform: uppercase;
          line-height: 22px;
          display: inline-block;
          border-radius: 4px;
          padding: 16px 10px;
          width: fit-content;
          cursor: pointer;
          font-weight: 700;
          -webkit-appearance: unset;
          font-size: 12px;
          color: #80fff3;
          background-color: #2a2a2a;
          border: 2px solid #80fff3;
          padding: 16px 0;
          width: 100%; }
          @media all and (min-width: 640px) {
            .cross-products-services-page-container .page-content .all-items .object-container .object-info-container .object-info .object-info-footer a {
              font-size: 16px; } }
          .cross-products-services-page-container .page-content .all-items .object-container .object-info-container .object-info .object-info-footer a:hover {
            text-decoration: none; }
          .cross-products-services-page-container .page-content .all-items .object-container .object-info-container .object-info .object-info-footer a:hover {
            background-color: #494949; }
          @media all and (min-width: 640px) {
            .cross-products-services-page-container .page-content .all-items .object-container .object-info-container .object-info .object-info-footer a {
              padding: 16px 73px;
              width: fit-content; } }

.cross-contact-page-container .contact-page-header-content {
  background-image: url("../../dist/images/pexels-blue-arauz.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }
  .cross-contact-page-container .contact-page-header-content .crossover-header-container {
    background-color: unset; }
    .cross-contact-page-container .contact-page-header-content .crossover-header-container.default-background-color {
      background-color: #2a2a2a; }
  .cross-contact-page-container .contact-page-header-content .page-header-container {
    padding-top: 57px;
    padding-bottom: 67px; }
    @media all and (min-width: 640px) {
      .cross-contact-page-container .contact-page-header-content .page-header-container {
        padding-top: 117px;
        padding-bottom: 137px; } }
    .cross-contact-page-container .contact-page-header-content .page-header-container h1 {
      color: #fefefe; }

.cross-contact-page-container .contact-page-top-content {
  background-image: url("../../dist/images/waves.png");
  background-position: 0 -60px;
  background-repeat: no-repeat;
  background-color: #181f23;
  margin-bottom: 20px; }
  .cross-contact-page-container .contact-page-top-content h2 {
    color: #fefefe; }
  .cross-contact-page-container .contact-page-top-content .contact-page-top-heading-container {
    height: 166px; }
    @media all and (min-width: 640px) {
      .cross-contact-page-container .contact-page-top-content .contact-page-top-heading-container {
        height: 266px; } }
  .cross-contact-page-container .contact-page-top-content .contact-form-container {
    font-family: "Nunito Sans", Arial, sans-serif;
    border: 2px solid #80fff3;
    border-radius: 4px;
    width: 100%;
    margin-bottom: 60px; }
    @media all and (min-width: 640px) {
      .cross-contact-page-container .contact-page-top-content .contact-form-container {
        margin-bottom: 120px; } }
    @media all and (min-width: 960px) {
      .cross-contact-page-container .contact-page-top-content .contact-form-container {
        width: unset;
        min-width: 853px; } }
    .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper {
      background: #2a2a2a;
      padding: 20px 10px; }
      @media all and (min-width: 640px) {
        .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper {
          padding: 40px 30px; } }
      .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper .gform_required_legend,
      .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper .gfield_required,
      .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper .gfield_validation_message {
        color: #ff5757; }
      .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper .gform_heading h2 {
        text-align: center;
        margin-bottom: 40px; }
      .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields {
        display: block; }
        .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield {
          position: relative;
          font-family: "Nunito Sans", Arial, sans-serif;
          margin-bottom: 20px;
          margin-bottom: 17px; }
          .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .gfield_required.gfield_required_asterisk,
          .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .gfield_required .gfield_required.gfield_required_asterisk {
            display: none; }
          .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container input,
          .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container select,
          .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container textarea {
            padding-left: 18px;
            padding-top: 17px;
            padding-bottom: 17px; }
          .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container input,
          .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container select,
          .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container .chosen-container,
          .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container textarea {
            border: 3px solid #ff5757; }
          .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.gfield_error .validation_message {
            background-color: transparent;
            border: unset;
            margin-top: unset;
            padding-top: 12px;
            padding-left: 20px; }
          .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .gfield_label {
            color: rgba(24, 31, 35, 0.68);
            background-color: #fefefe;
            padding: unset;
            font-size: 18px;
            font-weight: 400;
            margin-left: 21px;
            margin-bottom: 0px;
            position: absolute;
            top: 19px;
            z-index: 3;
            color: rgba(24, 31, 35, 0.68); }
            .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .gfield_label a {
              font-size: inherit; }
            .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .gfield_label .gfield_required {
              display: inline-block;
              font-size: 13.008px;
              padding-left: 0.125em; }
            .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .gfield_label.hidden {
              color: transparent;
              background-color: transparent; }
              .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .gfield_label.hidden .gfield_required {
                color: transparent; }
          .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.select-field .gfield_label.hidden {
            display: none; }
          .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container input,
          .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container select,
          .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container textarea {
            color: #181f23;
            border: 1px solid rgba(24, 31, 35, 0.68);
            padding-left: 20px;
            padding-top: 19px;
            padding-bottom: 19px;
            line-height: 24px;
            font-size: 18px;
            width: 100%;
            border-radius: 4px;
            font-family: inherit; }
            .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container input:focus,
            .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container select:focus,
            .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container textarea:focus {
              outline: unset;
              border: 3px solid #80fff3;
              padding-left: 18px;
              padding-top: 17px;
              padding-bottom: 17px; }
          .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_checkbox .gfield_checkbox .gchoice {
            vertical-align: top; }
            .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_checkbox .gfield_checkbox .gchoice input.gfield-choice-input {
              vertical-align: top;
              width: unset; }
          .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_select {
            position: relative;
            z-index: 0;
            background-color: #fefefe;
            border-radius: 4px; }
            .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_select::after {
              content: "\f078";
              font-family: "Font Awesome 6 Pro";
              bottom: 20px;
              right: 15px;
              position: absolute;
              font-size: 18px;
              line-height: 25px;
              color: rgba(24, 31, 35, 0.68);
              z-index: -1; }
              @media all and (min-width: 640px) {
                .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_select::after {
                  right: 28px; } }
            .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_select select {
              background-color: transparent;
              appearance: none;
              z-index: 2;
              position: relative;
              width: 100%;
              padding-right: 30px; }
          .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_consent {
            color: #fefefe; }
            .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_consent input[type="checkbox"] {
              -webkit-appearance: none;
              -moz-appearance: none;
              outline: 0;
              appearance: none;
              max-width: 16px;
              width: 100%;
              height: 16px;
              border-radius: 3.3px;
              margin: 0;
              background: transparent;
              float: left;
              padding: 0; }
            .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_consent input[type="checkbox"]:checked {
              background-size: 100%;
              background-repeat: no-repeat;
              background-position: center; }
            .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_consent label {
              display: block;
              font-weight: normal;
              padding-left: 25px;
              font-size: 14px;
              margin-bottom: 0;
              position: relative;
              top: -2px; }
            .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_consent input[type="checkbox"] {
              border: 1px solid #80fff3; }
            .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_consent input[type="checkbox"]:checked {
              background-image: url("../../dist/images/checkbox-tilt.svg"); }
            .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_consent a {
              color: #fefefe;
              font-weight: 700; }
          .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container textarea {
            height: unset;
            max-height: 120px; }
            @media all and (min-width: 640px) {
              .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container textarea {
                max-height: 180px; } }
          .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.dropdown-box {
            z-index: 12; }
          .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.your-message-box textarea {
            display: initial; }
          .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container label {
            color: #ff5757; }
      .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_footer {
        padding-bottom: unset;
        padding-top: 20px;
        margin: unset;
        display: block; }
        .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_footer input.gform_button.button {
          margin-bottom: unset; }
        .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_footer input.gform_button.button {
          text-align: center;
          text-transform: uppercase;
          line-height: 22px;
          display: inline-block;
          border-radius: 4px;
          padding: 16px 10px;
          width: fit-content;
          cursor: pointer;
          font-weight: 700;
          -webkit-appearance: unset;
          font-size: 12px;
          color: #80fff3;
          background-color: #2a2a2a;
          border: 1px solid #80fff3;
          padding: 16px 0;
          width: 100%; }
          @media all and (min-width: 640px) {
            .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_footer input.gform_button.button {
              font-size: 16px; } }
          .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_footer input.gform_button.button:hover {
            text-decoration: none; }
          .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_footer input.gform_button.button:hover {
            background-color: #494949; }
          @media all and (min-width: 640px) {
            .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_wrapper form .gform_footer input.gform_button.button {
              padding: 16px 90px;
              width: fit-content; } }
    .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_confirmation_wrapper {
      background: rgba(255, 255, 255, 0.18);
      backdrop-filter: blur(10px);
      color: #fefefe;
      padding: 25px 30px 30px 30px; }
      @media all and (min-width: 640px) {
        .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_confirmation_wrapper {
          padding: 50px 60px 60px 60px; } }
      @media all and (min-width: 1200px) {
        .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_confirmation_wrapper {
          min-width: 973px; } }
      .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_confirmation_wrapper a {
        color: inherit; }
      .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_confirmation_wrapper .title {
        color: inherit;
        font-weight: 600;
        font-size: 22px;
        margin-bottom: 30px;
        text-align: center; }
        @media all and (min-width: 640px) {
          .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_confirmation_wrapper .title {
            font-weight: 800;
            font-size: 48px;
            margin-bottom: 60px;
            text-align: left; } }
      .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_confirmation_wrapper .body {
        color: inherit;
        margin-bottom: 32px;
        text-align: center; }
        @media all and (min-width: 640px) {
          .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_confirmation_wrapper .body {
            margin-bottom: 62px;
            text-align: left; } }
        .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_confirmation_wrapper .body strong {
          font-weight: 800; }
      .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_confirmation_wrapper .social-media {
        font-size: 43px; }
        .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_confirmation_wrapper .social-media a:hover {
          color: #80fff3; }
        .cross-contact-page-container .contact-page-top-content .contact-form-container .gform_confirmation_wrapper .social-media a.twitter-icon {
          margin-right: 100px; }

.cross-contact-page-container .contact-page-locations .locations-heading {
  height: 245px; }
  .cross-contact-page-container .contact-page-locations .locations-heading h2 {
    margin-bottom: 0px; }

.cross-contact-page-container .contact-page-locations .locations-container {
  max-width: 1109px;
  padding-bottom: 20px; }
  .cross-contact-page-container .contact-page-locations .locations-container .location-row {
    margin-bottom: 40px; }
    @media all and (min-width: 640px) {
      .cross-contact-page-container .contact-page-locations .locations-container .location-row {
        margin-bottom: 80px; } }
    .cross-contact-page-container .contact-page-locations .locations-container .location-row i {
      margin-right: 12px;
      color: rgba(24, 31, 35, 0.68); }
    .cross-contact-page-container .contact-page-locations .locations-container .location-row h3 {
      margin-bottom: 40px; }
    @media all and (min-width: 640px) {
      .cross-contact-page-container .contact-page-locations .locations-container .location-row p {
        font-size: 24px;
        margin-bottom: 32px; } }
    .cross-contact-page-container .contact-page-locations .locations-container .location-row p a {
      color: #181f23; }
    .cross-contact-page-container .contact-page-locations .locations-container .location-row .location-map-container .location-map {
      width: 100%;
      overflow: hidden; }
      .cross-contact-page-container .contact-page-locations .locations-container .location-row .location-map-container .location-map iframe {
        margin-top: -60px; }
        @media all and (max-width: 640px) {
          .cross-contact-page-container .contact-page-locations .locations-container .location-row .location-map-container .location-map iframe {
            width: 100%; } }

.cross-contact-page-container .chosen-single {
  color: #181f23 !important;
  margin: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  border: none !important;
  background: none !important;
  box-shadow: none !important; }
  .cross-contact-page-container .chosen-single b {
    display: none !important; }

.cross-contact-page-container .chosen-drop {
  margin-top: 2px !important;
  border-radius: 4px !important; }
  .cross-contact-page-container .chosen-drop .chosen-search {
    padding: 0 !important; }
  .cross-contact-page-container .chosen-drop .active-result {
    color: rgba(24, 31, 35, 0.68);
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    padding: 10px 0 10px 13px !important;
    border-bottom: 1px solid rgba(42, 42, 42, 0.2); }
    .cross-contact-page-container .chosen-drop .active-result.gf_placeholder {
      display: none !important; }
    .cross-contact-page-container .chosen-drop .active-result:first-child, .cross-contact-page-container .chosen-drop .active-result:last-child {
      border: none; }
    .cross-contact-page-container .chosen-drop .active-result:first-child {
      color: #181f23; }

.cross-contact-page-container .delete-dropdown,
.cross-contact-page-container .chosen-search-input {
  display: none; }

.cross-default-page {
  max-width: 1440px;
  margin: auto auto 130px;
  padding: 0 16px;
  color: #181f23; }
  .cross-default-page h1 {
    height: 87px;
    top: 174px;
    font-weight: 800;
    line-height: 87px;
    margin: 60px 0; }
  .cross-default-page h3 {
    font-weight: 600;
    line-height: 49px;
    color: #181f23;
    margin: 40px 0 8px 10px; }
  .cross-default-page h4 {
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    margin: 40px 0 8px 30px; }
  .cross-default-page p {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 30px; }
  .cross-default-page hr {
    border: 4px solid black;
    margin: 0 0 30px;
    max-block-size: 32px; }

.cross-testimonials-page {
  color: #181f23;
  max-width: 1440px;
  margin: 0 auto 120px;
  padding: 0 16px; }
  .cross-testimonials-page h1 {
    text-transform: uppercase;
    font-weight: 800;
    line-height: 87px;
    margin: 100px 0 90px; }
  .cross-testimonials-page p {
    font-size: 18px; }
  .cross-testimonials-page .testimonial-list-container {
    gap: 100px 93px;
    margin-bottom: 80px; }
  .cross-testimonials-page .testimonial {
    margin: 0;
    width: 611px; }
    .cross-testimonials-page .testimonial .comment-icon {
      color: #2a2a2a;
      margin-bottom: 30px;
      font-size: 54px; }
    .cross-testimonials-page .testimonial .testimonial-explanation {
      font-weight: 400;
      line-height: 25px; }
    .cross-testimonials-page .testimonial .testimonial-quote {
      font-weight: 700;
      font-style: italic;
      margin: 0 0 70px; }
    .cross-testimonials-page .testimonial hr {
      background-color: #2a2a2a;
      width: 621px;
      height: 6px;
      margin: 0 0 10px; }
    .cross-testimonials-page .testimonial ul {
      list-style: none;
      padding: 0;
      gap: 10px;
      margin: 0; }
    .cross-testimonials-page .testimonial .testimonial-info {
      font-weight: 700;
      font-size: 20px;
      line-height: 27px; }
  .cross-testimonials-page .testimonials-load-more {
    margin: 0 auto; }
    .cross-testimonials-page .testimonials-load-more .load-more-testimonials-button {
      color: #181f23;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      border-bottom-style: solid; }
      .cross-testimonials-page .testimonials-load-more .load-more-testimonials-button:hover {
        color: rgba(24, 31, 35, 0.68);
        text-decoration: none; }

.cross-news-page {
  color: #181f23;
  max-width: 1330px;
  margin: 0 auto 120px;
  padding: 0 16px; }
  .cross-news-page a {
    color: #181f23; }
    .cross-news-page a:hover {
      color: rgba(24, 31, 35, 0.68);
      text-decoration: none; }
  .cross-news-page h1 {
    margin: 100px auto 40px;
    font-weight: 800;
    line-height: 87px; }
  .cross-news-page .case-study-heading {
    margin: 80px 0 90px;
    line-height: unset; }
    @media all and (min-width: 640px) {
      .cross-news-page .case-study-heading {
        margin-bottom: 100px; } }
  .cross-news-page .page-header label {
    display: none; }
  .cross-news-page .page-header .input-field-container {
    border-bottom: 1px solid #d7d7d7;
    max-width: 500px;
    margin: 0 auto 50px; }
    .cross-news-page .page-header .input-field-container input {
      color: #181f23;
      height: 33px;
      font-weight: 400;
      font-size: 24px;
      line-height: 33px;
      padding-left: 10px;
      border: none;
      width: 90%; }
      .cross-news-page .page-header .input-field-container input:focus, .cross-news-page .page-header .input-field-container input:active {
        outline: 1px solid rgba(254, 254, 254, 0.38);
        border-radius: 4px; }
    .cross-news-page .page-header .input-field-container i {
      padding-right: 10px; }
    .cross-news-page .page-header .input-field-container .news-search {
      display: inline-block;
      width: 40px;
      font-size: 0;
      opacity: 0;
      margin-left: -30px;
      padding: 0; }
      .cross-news-page .page-header .input-field-container .news-search:hover, .cross-news-page .page-header .input-field-container .news-search:focus, .cross-news-page .page-header .input-field-container .news-search:active {
        cursor: pointer; }
  .cross-news-page .page-header .category-list-container {
    display: none; }
    @media all and (min-width: 768px) {
      .cross-news-page .page-header .category-list-container {
        display: unset; } }
    .cross-news-page .page-header .category-list-container .category-list {
      flex-wrap: wrap;
      list-style: none;
      gap: 50px;
      padding: 0;
      margin-bottom: 114px; }
      .cross-news-page .page-header .category-list-container .category-list .category-all,
      .cross-news-page .page-header .category-list-container .category-list .category {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        padding: 0 30px 8px;
        margin-bottom: 0; }
        .cross-news-page .page-header .category-list-container .category-list .category-all::before,
        .cross-news-page .page-header .category-list-container .category-list .category::before {
          content: "";
          display: none; }
      .cross-news-page .page-header .category-list-container .category-list .current {
        padding-bottom: 3px;
        border-bottom: 2px solid #181f23; }
  .cross-news-page .page-header .dropdown-category-list-container {
    margin-bottom: 114px; }
  .cross-news-page .news-article-container .single-article {
    padding-bottom: 30px;
    margin-bottom: 70px;
    border-bottom: 2px solid #d7d7d7;
    gap: 33px; }
    @media all and (min-width: 960px) {
      .cross-news-page .news-article-container .single-article {
        gap: 67px; } }
    @media all and (min-width: 992px) {
      .cross-news-page .news-article-container .single-article {
        flex-direction: row; } }
    .cross-news-page .news-article-container .single-article:last-child {
      margin-bottom: 80px; }
    .cross-news-page .news-article-container .single-article h3 {
      font-weight: 700;
      font-size: 24px;
      line-height: 33px;
      margin-bottom: 17px; }
      .cross-news-page .news-article-container .single-article h3:hover {
        color: rgba(24, 31, 35, 0.68); }
    @media all and (min-width: 960px) {
      .cross-news-page .news-article-container .single-article .image-container {
        min-width: 499px; } }
    .cross-news-page .news-article-container .single-article .image-container img {
      margin: 0 auto;
      object-fit: cover;
      width: 498px;
      height: 310px; }
      @media all and (min-width: 960px) {
        .cross-news-page .news-article-container .single-article .image-container img {
          min-height: 310px; } }
    .cross-news-page .news-article-container .single-article .date-category {
      margin-bottom: 25px; }
      .cross-news-page .news-article-container .single-article .date-category p {
        margin: 0; }
      .cross-news-page .news-article-container .single-article .date-category .date {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px; }
      .cross-news-page .news-article-container .single-article .date-category .category {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px; }
    .cross-news-page .news-article-container .single-article .case-study-location {
      margin-bottom: 23px; }
    .cross-news-page .news-article-container .single-article .excerpt p {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 40px; }
    .cross-news-page .news-article-container .single-article .excerpt a {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px; }
      .cross-news-page .news-article-container .single-article .excerpt a .arrow-right {
        margin-left: 16px; }
  .cross-news-page .news-article-container .zero-results {
    color: rgba(42, 42, 42, 0.68);
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    margin: -14px 0 0; }
  .cross-news-page .news-load-more {
    margin-bottom: 16px; }
    .cross-news-page .news-load-more .load-more-button {
      color: #80fff3;
      background-color: black;
      font-weight: 700;
      line-height: 22px;
      padding: 16px;
      border: 2px solid #80fff3;
      box-shadow: 0px 2px 6px rgba(42, 42, 42, 0.18);
      border-radius: 4px; }
      .cross-news-page .news-load-more .load-more-button:hover {
        background-color: rgba(24, 31, 35, 0.68);
        text-decoration: none; }

.cross-news-single-page {
  color: #181f23;
  max-width: 1142px;
  margin: 0 auto 120px;
  padding: 0 16px; }
  .cross-news-single-page .back-to-news {
    margin: 70px 0; }
    .cross-news-single-page .back-to-news p {
      margin: 0; }
    .cross-news-single-page .back-to-news a {
      color: #181f23; }
      .cross-news-single-page .back-to-news a .arrow-left {
        margin-right: 15px; }
      .cross-news-single-page .back-to-news a:hover {
        color: rgba(24, 31, 35, 0.68);
        text-decoration: none; }
  .cross-news-single-page h1 {
    font-weight: 800;
    line-height: 44px;
    margin: 0 0 40px; }
    @media all and (min-width: 640px) {
      .cross-news-single-page h1 {
        line-height: 87px; } }
  .cross-news-single-page .article-container {
    margin-bottom: 100px; }
    .cross-news-single-page .article-container .date-category {
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      margin-bottom: 35px; }
      .cross-news-single-page .article-container .date-category .date {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        margin: 0; }
      .cross-news-single-page .article-container .date-category .category {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin: 0; }
    .cross-news-single-page .article-container .image-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto; }
      .cross-news-single-page .article-container .image-container img {
        object-fit: cover;
        display: block;
        margin: 0 auto 36px;
        width: 285px;
        height: 177px; }
        @media all and (min-width: 360px) {
          .cross-news-single-page .article-container .image-container img {
            width: 325px;
            height: 202px; } }
        @media all and (min-width: 500px) {
          .cross-news-single-page .article-container .image-container img {
            width: 468px;
            height: 291px; } }
        @media all and (min-width: 640px) {
          .cross-news-single-page .article-container .image-container img {
            width: 608px;
            height: 378px; } }
        @media all and (min-width: 768px) {
          .cross-news-single-page .article-container .image-container img {
            width: 736px;
            height: 274px; } }
        @media all and (min-width: 960px) {
          .cross-news-single-page .article-container .image-container img {
            width: 928px;
            height: 418px; } }
        @media all and (min-width: 992px) {
          .cross-news-single-page .article-container .image-container img {
            width: 960px;
            height: 433px; } }
        @media all and (min-width: 1200px) {
          .cross-news-single-page .article-container .image-container img {
            width: 1140px;
            height: 510px; } }
    .cross-news-single-page .article-container .article-text-container {
      max-width: 785px;
      margin: 0 auto; }
      .cross-news-single-page .article-container .article-text-container p {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        max-width: 785px;
        margin: 0 auto 40px; }
  .cross-news-single-page .is-layout-flex {
    display: flex;
    justify-content: center; }
    .cross-news-single-page .is-layout-flex .wp-block-button {
      margin-top: 10px; }
    .cross-news-single-page .is-layout-flex .wp-block-button__link {
      color: #80fff3;
      background-color: black;
      font-weight: 700;
      line-height: 22px;
      text-transform: uppercase;
      padding: 20px 66px;
      border: 2px solid #80fff3;
      box-shadow: 0px 2px 6px rgba(42, 42, 42, 0.18);
      border-radius: 4px; }
      .cross-news-single-page .is-layout-flex .wp-block-button__link:hover {
        background-color: #494949;
        text-decoration: none; }
  .cross-news-single-page .bottom-articles-container .about-crossover {
    margin-bottom: 40px; }
    .cross-news-single-page .bottom-articles-container .about-crossover a {
      color: #181f23; }
  .cross-news-single-page .bottom-articles-container .about-crossover,
  .cross-news-single-page .bottom-articles-container .extra-article {
    margin-bottom: 40px; }
    @media all and (min-width: 992px) {
      .cross-news-single-page .bottom-articles-container .about-crossover,
      .cross-news-single-page .bottom-articles-container .extra-article {
        flex-direction: row;
        align-items: flex-start;
        gap: 174px; } }
    .cross-news-single-page .bottom-articles-container .about-crossover img,
    .cross-news-single-page .bottom-articles-container .extra-article img {
      object-fit: contain;
      width: 356px;
      height: 88px;
      margin-bottom: 20px;
      width: 356px;
      height: 88px; }
    .cross-news-single-page .bottom-articles-container .about-crossover p,
    .cross-news-single-page .bottom-articles-container .extra-article p {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      max-width: 612px;
      margin: 0 0 10px; }

.case-study-page-container {
  color: #fefefe;
  background-color: #2a2a2a; }
  .case-study-page-container em {
    color: #fefefe; }
  .case-study-page-container .back-link-container {
    padding-top: 40px; }
    .case-study-page-container .back-link-container .back-link {
      color: #fefefe; }
      .case-study-page-container .back-link-container .back-link .arrow-left {
        margin-right: 15px;
        vertical-align: middle; }
  .case-study-page-container .back-link-container,
  .case-study-page-container .info-container {
    max-width: 1320px;
    margin: 0 auto; }
  .case-study-page-container .page-header-container {
    padding-top: 70px; }
    .case-study-page-container .page-header-container .case-study-heading h1 {
      margin-bottom: 40px;
      color: inherit; }
  .case-study-page-container .top-image-container {
    position: relative;
    margin-bottom: 40px; }
    @media all and (min-width: 1200px) {
      .case-study-page-container .top-image-container {
        min-width: 976px; } }
    .case-study-page-container .top-image-container img.case-study-image {
      height: 520px;
      width: 976px;
      object-fit: cover; }
    .case-study-page-container .top-image-container .image-overlay {
      line-height: 1;
      margin-top: 10px; }
      @media all and (min-width: 640px) {
        .case-study-page-container .top-image-container .image-overlay {
          margin-top: 0;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
          line-height: unset;
          position: absolute;
          top: 0;
          height: 520px;
          width: 100%; } }
      .case-study-page-container .top-image-container .image-overlay .logo {
        margin-bottom: 10px; }
        @media all and (min-width: 640px) {
          .case-study-page-container .top-image-container .image-overlay .logo {
            margin: 42px auto auto 40px; } }
      .case-study-page-container .top-image-container .image-overlay .overlay-info {
        margin: 0 0 40px 0; }
        @media all and (min-width: 640px) {
          .case-study-page-container .top-image-container .image-overlay .overlay-info {
            margin: 0 0 40px 51px; } }
        .case-study-page-container .top-image-container .image-overlay .overlay-info .case-title h2 {
          color: #fefefe;
          line-height: 48px;
          margin-bottom: 11px; }
        .case-study-page-container .top-image-container .image-overlay .overlay-info .location {
          font-size: 32px;
          font-weight: 600px; }
  .case-study-page-container .top-text-container .logo {
    margin-bottom: 16px; }
  .case-study-page-container .top-text-container .case-title h2 {
    color: #fefefe;
    margin-bottom: 11px; }
  .case-study-page-container .top-text-container .location {
    font-size: 32px;
    font-weight: 600px;
    margin-bottom: 105px; }
  .case-study-page-container .info-container ul li {
    padding-right: 10px; }
  .case-study-page-container .info-container .top-list {
    margin: 0 auto 25px; }
    @media all and (min-width: 640px) {
      .case-study-page-container .info-container .top-list {
        margin-bottom: 50px; } }
  .case-study-page-container .info-container .heading {
    font-size: 24px;
    margin-bottom: 50px; }
    @media all and (min-width: 640px) {
      .case-study-page-container .info-container .heading {
        font-size: 48px;
        margin-bottom: 0;
        font-weight: 800; } }
    @media all and (min-width: 640px) {
      .case-study-page-container .info-container .heading.col-heading {
        width: 400px; } }
  .case-study-page-container .info-container .col-text {
    margin-bottom: 40px; }
    @media all and (min-width: 640px) {
      .case-study-page-container .info-container .col-text {
        margin-bottom: 80px; } }
    .case-study-page-container .info-container .col-text > * {
      color: #fefefe; }
      .case-study-page-container .info-container .col-text > *:last-child {
        margin-bottom: 0; }
  @media all and (min-width: 640px) {
    .case-study-page-container .info-container .outcomes-container {
      margin-bottom: 20px; } }
  .case-study-page-container .info-container .outcomes-container .outcomes-heading {
    font-weight: 800;
    font-size: 24px;
    margin-bottom: 50px; }
    @media all and (min-width: 640px) {
      .case-study-page-container .info-container .outcomes-container .outcomes-heading {
        font-size: 48px;
        margin-bottom: 50px; } }
  .case-study-page-container .info-container .case-study-list .list-item .list-heading h3 {
    color: #fefefe;
    margin-bottom: 20px; }
  .case-study-page-container .case-studies-slider {
    padding-top: 60px;
    padding-bottom: 60px; }
    @media all and (min-width: 960px) {
      .case-study-page-container .case-studies-slider {
        padding-top: 80px;
        padding-bottom: 67px; } }

.cross-shop-index-page {
  color: #181f23;
  max-width: 983px;
  margin: 0 auto 120px;
  padding: 0 16px;
  /* .pagenation-numbers {
    gap: 36px;
  } */ }
  .cross-shop-index-page h1 {
    margin: 80px auto 60px;
    font-weight: 800;
    font-size: 64px;
    line-height: 87px; }
  .cross-shop-index-page h3 {
    font-weight: 600;
    line-height: 49px; }
  .cross-shop-index-page a {
    color: #181f23;
    background-color: #fefefe; }
    .cross-shop-index-page a.crossover-button {
      text-align: center;
      text-transform: uppercase;
      line-height: 22px;
      display: inline-block;
      border-radius: 4px;
      padding: 16px 10px;
      width: fit-content;
      cursor: pointer;
      font-weight: 700;
      -webkit-appearance: unset;
      font-size: 12px;
      color: #80fff3;
      background-color: #2a2a2a;
      border: 2px solid #80fff3;
      padding: 16px 20px; }
      @media all and (min-width: 640px) {
        .cross-shop-index-page a.crossover-button {
          font-size: 16px; } }
      .cross-shop-index-page a.crossover-button:hover {
        text-decoration: none; }
      .cross-shop-index-page a.crossover-button:hover {
        background-color: #494949; }
  .cross-shop-index-page .shop-all-categolies {
    gap: 40px; }
    .cross-shop-index-page .shop-all-categolies .shop-category {
      gap: 40px;
      border: 1px solid rgba(42, 42, 42, 0.2);
      box-shadow: 0px 2px 6px rgba(42, 42, 42, 0.18); }
      .cross-shop-index-page .shop-all-categolies .shop-category .shop-category-outline {
        padding: 30px 30px 35px;
        gap: 30px; }
        @media all and (min-width: 768px) {
          .cross-shop-index-page .shop-all-categolies .shop-category .shop-category-outline {
            align-items: unset; } }
        .cross-shop-index-page .shop-all-categolies .shop-category .shop-category-outline .load-query-toggle-for-card {
          background-color: unset;
          text-align: unset;
          padding: 0; }
          .cross-shop-index-page .shop-all-categolies .shop-category .shop-category-outline .load-query-toggle-for-card img {
            display: block; }
        @media all and (min-width: 768px) {
          .cross-shop-index-page .shop-all-categolies .shop-category .shop-category-outline .title-more-products {
            width: 100%;
            flex-direction: row; } }
        .cross-shop-index-page .shop-all-categolies .shop-category .shop-category-outline .title-more-products img.logo {
          margin-bottom: 30px; }
        .cross-shop-index-page .shop-all-categolies .shop-category .shop-category-outline h3 {
          margin: 0; }
        .cross-shop-index-page .shop-all-categolies .shop-category .shop-category-outline .view-more-products a {
          background-color: unset;
          font-weight: 700;
          font-size: 16px;
          line-height: 22px;
          padding-left: 0;
          padding-right: 0; }
          @media all and (min-width: 640px) {
            .cross-shop-index-page .shop-all-categolies .shop-category .shop-category-outline .view-more-products a {
              padding-right: 30px; } }
          .cross-shop-index-page .shop-all-categolies .shop-category .shop-category-outline .view-more-products a:hover {
            color: rgba(24, 31, 35, 0.68);
            text-decoration: none; }
          .cross-shop-index-page .shop-all-categolies .shop-category .shop-category-outline .view-more-products a i {
            margin-left: 20px; }
  .cross-shop-index-page .shop-products-table {
    width: 100%;
    text-align: left;
    border-collapse: collapse; }
    .cross-shop-index-page .shop-products-table thead {
      display: none; }
      @media all and (min-width: 500px) {
        .cross-shop-index-page .shop-products-table thead {
          display: table-header-group; } }
    .cross-shop-index-page .shop-products-table th {
      color: #fefefe;
      background-color: #2a2a2a;
      font-weight: 700;
      font-size: 16px;
      line-height: 18px; }
      .cross-shop-index-page .shop-products-table th.th-product-image {
        width: 18.6%;
        padding-left: 30px; }
      .cross-shop-index-page .shop-products-table th.th-product-name {
        width: 32.6%;
        padding-left: 16px; }
      .cross-shop-index-page .shop-products-table th.th-part-number {
        width: 22.8%;
        padding-left: 50px; }
    .cross-shop-index-page .shop-products-table tr {
      border: 1px solid rgba(42, 42, 42, 0.2); }
    .cross-shop-index-page .shop-products-table .product-row {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      min-height: 141px; }
      @media all and (min-width: 500px) {
        .cross-shop-index-page .shop-products-table .product-row {
          display: table-row; } }
      .cross-shop-index-page .shop-products-table .product-row .td-product-image {
        width: 180px;
        height: 130px; }
      .cross-shop-index-page .shop-products-table .product-row .td-product-name {
        padding-left: 16px;
        text-decoration-line: underline; }
        .cross-shop-index-page .shop-products-table .product-row .td-product-name a {
          font-weight: 400;
          font-size: 16px;
          line-height: 22px; }
      .cross-shop-index-page .shop-products-table .product-row .td-model {
        padding-left: 50px;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        padding-left: 16px; }
        @media all and (min-width: 500px) {
          .cross-shop-index-page .shop-products-table .product-row .td-model {
            padding-left: 50px; } }
      .cross-shop-index-page .shop-products-table .product-row .td-details {
        text-align: center; }
        .cross-shop-index-page .shop-products-table .product-row .td-details .crosover-button {
          text-align: center;
          text-transform: uppercase;
          line-height: 22px;
          display: inline-block;
          border-radius: 4px;
          padding: 16px 10px;
          width: fit-content;
          cursor: pointer;
          font-weight: 700;
          -webkit-appearance: unset;
          font-size: 12px;
          color: #181f23;
          background-color: #fefefe;
          border: 2px solid #181f23;
          padding: 8px 35px; }
          @media all and (min-width: 640px) {
            .cross-shop-index-page .shop-products-table .product-row .td-details .crosover-button {
              font-size: 16px; } }
          .cross-shop-index-page .shop-products-table .product-row .td-details .crosover-button:hover {
            text-decoration: none; }
          .cross-shop-index-page .shop-products-table .product-row .td-details .crosover-button:hover {
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.22), rgba(0, 0, 0, 0.09), rgba(43, 215, 193, 0.34)); }
  .cross-shop-index-page .pagination-container {
    gap: 60px;
    margin: 30px 0 39px; }
    .cross-shop-index-page .pagination-container .pagination {
      font-weight: 700;
      font-size: 20px;
      line-height: 27px; }
      .cross-shop-index-page .pagination-container .pagination .page-numbers {
        margin-right: 36px; }
        .cross-shop-index-page .pagination-container .pagination .page-numbers .current {
          color: #181f23; }
      .cross-shop-index-page .pagination-container .pagination .next.page-numbers {
        margin-right: 0;
        margin-left: 24px; }
      .cross-shop-index-page .pagination-container .pagination .prev.page-numbers {
        margin-left: 0;
        margin-right: 60px; }
      .cross-shop-index-page .pagination-container .pagination a {
        color: rgba(24, 31, 35, 0.68); }
        .cross-shop-index-page .pagination-container .pagination a:hover {
          color: rgba(24, 31, 35, 0.68);
          text-decoration: none; }

.cross-product-detail-page {
  color: #181f23;
  max-width: 1265px;
  margin: 0 auto 120px;
  padding: 0 16px; }
  .cross-product-detail-page a {
    color: #181f23; }
  .cross-product-detail-page .back-to-shop-container {
    margin: 70px 0 18px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px; }
    .cross-product-detail-page .back-to-shop-container p {
      margin: 0;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px; }
      .cross-product-detail-page .back-to-shop-container p:hover {
        color: rgba(24, 31, 35, 0.68);
        text-decoration: none; }
    .cross-product-detail-page .back-to-shop-container .arrow-left {
      margin-right: 15px; }
  .cross-product-detail-page .product-container {
    margin-bottom: 90px; }
    .cross-product-detail-page .product-container img {
      width: 197px; }
      @media all and (min-width: 640px) {
        .cross-product-detail-page .product-container img {
          min-width: 450px;
          width: 450px; } }
    @media all and (min-width: 992px) {
      .cross-product-detail-page .product-container {
        flex-direction: row;
        gap: 110px; } }
    .cross-product-detail-page .product-container h2 {
      font-weight: 800;
      line-height: 1;
      margin-bottom: 10px; }
      @media all and (min-width: 640px) {
        .cross-product-detail-page .product-container h2 {
          line-height: 65px; } }
    .cross-product-detail-page .product-container .part-number {
      margin: 0 0 8px;
      font-weight: 700;
      font-size: 20px;
      line-height: 27px; }
      .cross-product-detail-page .product-container .part-number span {
        color: rgba(24, 31, 35, 0.68); }
    .cross-product-detail-page .product-container hr {
      margin: 0 0 42px;
      border: 2px solid #181f23; }
    .cross-product-detail-page .product-container .specification {
      font-weight: 600;
      font-size: 32px;
      line-height: 44px;
      margin: 0 0 26px; }
    .cross-product-detail-page .product-container ul {
      margin: 0;
      padding: 0;
      list-style: none; }
      .cross-product-detail-page .product-container ul li {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        margin-left: 40px;
        padding-bottom: 0; }
        .cross-product-detail-page .product-container ul li:not(:last-child) {
          margin-bottom: 30px; }
  .cross-product-detail-page .get-more-info-form-container {
    max-width: 880px;
    margin: 0 auto;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.168) -12.42%, rgba(0, 0, 0, 0.066) 46.75%, rgba(43, 215, 193, 0.252) 109.25%);
    border: 1px solid rgba(42, 42, 42, 0.2);
    border-radius: 4px;
    padding: 30px; }
    .cross-product-detail-page .get-more-info-form-container h2 {
      margin: 0 0 30px;
      font-weight: 800;
      font-size: 48px;
      line-height: 65px; }
    .cross-product-detail-page .get-more-info-form-container p {
      margin: 0 0 40px;
      font-weight: 400;
      font-size: 24px;
      line-height: 33px; }
    .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields {
      display: block; }
      .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield {
        position: relative;
        font-family: "Nunito Sans", Arial, sans-serif;
        margin-bottom: 20px;
        margin-bottom: 17px; }
        .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield .gfield_required.gfield_required_asterisk,
        .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield .gfield_required .gfield_required.gfield_required_asterisk {
          display: none; }
        .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container input,
        .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container select,
        .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container textarea {
          padding-left: 18px;
          padding-top: 17px;
          padding-bottom: 17px; }
        .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container input,
        .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container select,
        .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container .chosen-container,
        .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container textarea {
          border: 3px solid #ff5757; }
        .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield.gfield_error .validation_message {
          background-color: transparent;
          border: unset;
          margin-top: unset;
          padding-top: 12px;
          padding-left: 20px; }
        .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield .gfield_label {
          color: rgba(24, 31, 35, 0.68);
          background-color: #fefefe;
          padding: unset;
          font-size: 18px;
          font-weight: 400;
          margin-left: 21px;
          margin-bottom: 0px;
          position: absolute;
          top: 19px;
          z-index: 3;
          margin-left: 20px; }
          .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield .gfield_label a {
            font-size: inherit; }
          .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield .gfield_label .gfield_required {
            display: inline-block;
            font-size: 13.008px;
            padding-left: 0.125em; }
          .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield .gfield_label.hidden {
            color: transparent;
            background-color: transparent; }
            .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield .gfield_label.hidden .gfield_required {
              color: transparent; }
        .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield.select-field .gfield_label.hidden {
          display: none; }
        .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield .ginput_container input,
        .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield .ginput_container select,
        .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield .ginput_container textarea {
          color: #181f23;
          border: 1px solid rgba(24, 31, 35, 0.68);
          padding-left: 20px;
          padding-top: 19px;
          padding-bottom: 19px;
          line-height: 24px;
          font-size: 18px;
          width: 100%;
          border-radius: 4px;
          font-family: inherit; }
          .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield .ginput_container input:focus,
          .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield .ginput_container select:focus,
          .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield .ginput_container textarea:focus {
            outline: unset;
            border: 3px solid #80fff3;
            padding-left: 18px;
            padding-top: 17px;
            padding-bottom: 17px; }
        .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_checkbox .gfield_checkbox .gchoice {
          vertical-align: top; }
          .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_checkbox .gfield_checkbox .gchoice input.gfield-choice-input {
            vertical-align: top;
            width: unset; }
        .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_select {
          position: relative;
          z-index: 0;
          background-color: #fefefe;
          border-radius: 4px; }
          .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_select::after {
            content: "\f078";
            font-family: "Font Awesome 6 Pro";
            bottom: 20px;
            right: 15px;
            position: absolute;
            font-size: 18px;
            line-height: 25px;
            color: rgba(24, 31, 35, 0.68);
            z-index: -1; }
            @media all and (min-width: 640px) {
              .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_select::after {
                right: 28px; } }
          .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_select select {
            background-color: transparent;
            appearance: none;
            z-index: 2;
            position: relative;
            width: 100%;
            padding-right: 30px; }
        .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield .ginput_container input {
          border: 1px solid rgba(42, 42, 42, 0.2); }
          .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield .ginput_container input:focus {
            border: 1px solid rgba(24, 31, 35, 0.68);
            padding-left: 19px;
            padding-top: 19px;
            padding-bottom: 19px; }
        .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_consent input[type="checkbox"] {
          -webkit-appearance: none;
          -moz-appearance: none;
          outline: 0;
          appearance: none;
          max-width: 16px;
          width: 100%;
          height: 16px;
          border-radius: 3.3px;
          margin: 0;
          background: transparent;
          float: left;
          padding: 0; }
        .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_consent input[type="checkbox"]:checked {
          background-size: 100%;
          background-repeat: no-repeat;
          background-position: center; }
        .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_consent label {
          display: block;
          font-weight: normal;
          padding-left: 25px;
          font-size: 14px;
          margin-bottom: 0;
          position: relative;
          top: -2px; }
        .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_consent input[type="checkbox"] {
          border: 1px solid rgba(24, 31, 35, 0.68); }
        .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_consent input[type="checkbox"]:checked {
          background-image: url("../../assets/images/checkbox-grey.svg"); }
        .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_consent a {
          color: rgba(24, 31, 35, 0.68);
          font-weight: 700; }
        .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield .ginput_container textarea {
          max-height: 120px;
          border: 1px solid rgba(42, 42, 42, 0.2); }
          @media all and (min-width: 640px) {
            .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield .ginput_container textarea {
              max-height: 185px; } }
        .cross-product-detail-page .get-more-info-form-container .form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container label {
          color: #e02424; }
    .cross-product-detail-page .get-more-info-form-container .form .gform_footer {
      padding-bottom: unset;
      padding-top: 40px;
      margin: unset;
      display: block;
      text-align: center; }
      .cross-product-detail-page .get-more-info-form-container .form .gform_footer input.gform_button.button {
        margin-bottom: unset; }
      .cross-product-detail-page .get-more-info-form-container .form .gform_footer input.gform_button.button {
        text-align: center;
        text-transform: uppercase;
        line-height: 22px;
        display: inline-block;
        border-radius: 4px;
        padding: 16px 10px;
        width: fit-content;
        cursor: pointer;
        font-weight: 700;
        -webkit-appearance: unset;
        font-size: 12px;
        color: #80fff3;
        background-color: #2a2a2a;
        border: 1px solid #80fff3;
        padding: 16px 100px;
        font-size: 16px;
        width: 100%;
        box-shadow: 0px 2px 6px rgba(42, 42, 42, 0.2);
        margin: 0 auto; }
        @media all and (min-width: 640px) {
          .cross-product-detail-page .get-more-info-form-container .form .gform_footer input.gform_button.button {
            font-size: 16px; } }
        .cross-product-detail-page .get-more-info-form-container .form .gform_footer input.gform_button.button:hover {
          text-decoration: none; }
        .cross-product-detail-page .get-more-info-form-container .form .gform_footer input.gform_button.button:hover {
          background-color: #494949; }
        @media all and (min-width: 640px) {
          .cross-product-detail-page .get-more-info-form-container .form .gform_footer input.gform_button.button {
            padding: 16px 100px;
            width: fit-content; } }
    .cross-product-detail-page .get-more-info-form-container .form .gform_confirmation_message_4 p {
      color: rgba(42, 42, 42, 0.68);
      font-weight: 600;
      font-size: 36px;
      line-height: 49px;
      margin-bottom: 42px; }
    .cross-product-detail-page .get-more-info-form-container .form .gform_confirmation_message_4 .uk-flex {
      gap: 100px;
      margin-bottom: 44px; }
    .cross-product-detail-page .get-more-info-form-container .form .gform_confirmation_message_4 .twitter {
      width: 50px; }
    .cross-product-detail-page .get-more-info-form-container .form .gform_confirmation_message_4 .linkedin {
      width: 43px; }
    .cross-product-detail-page .get-more-info-form-container .input-fields {
      gap: 20px;
      margin-bottom: 20px; }

.cross-resources-page {
  max-width: 1330px;
  margin: 0 auto;
  padding: 0 16px;
  color: #181f23; }
  .cross-resources-page h1 {
    margin: 60px 0 80px;
    line-height: 87px; }
  .cross-resources-page h2 {
    margin: 0 0 5px;
    text-transform: uppercase;
    line-height: 65px;
    padding-left: 10px; }
  .cross-resources-page p {
    margin: 0; }
  .cross-resources-page a {
    color: #181f23;
    text-decoration: none; }
  .cross-resources-page hr {
    border: 4px solid #181f23;
    margin: 0; }
  .cross-resources-page .card {
    background: #fefefe;
    box-shadow: -6px 6px 30px rgba(0, 0, 0, 0.17);
    padding: 40px 50px 30px 30px; }
  .cross-resources-page .border-bottom {
    border-bottom: 1px solid rgba(42, 42, 42, 0.2); }
  .cross-resources-page .single-link {
    padding: 22px 30px 22px 0; }
  .cross-resources-page .links-title {
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    margin-bottom: 10px; }
  .cross-resources-page .single-link-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 0; }
  .cross-resources-page .link-icon {
    color: rgba(42, 42, 42, 0.68);
    font-weight: 400;
    font-size: 20.0729px;
    line-height: 20px; }
  .cross-resources-page .tech-support-container {
    margin-bottom: 100px;
    /* @media all and (min-width: $screen-mdl) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 82px;
    } */ }
    .cross-resources-page .tech-support-container .tech-support-forms {
      gap: 20px;
      margin-bottom: 25px; }
      @media all and (min-width: 640px) {
        .cross-resources-page .tech-support-container .tech-support-forms {
          margin-bottom: 50px; } }
    .cross-resources-page .tech-support-container hr {
      margin: 0 0 42.5px; }
  .cross-resources-page ul {
    margin-bottom: 50px; }
  .cross-resources-page li {
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    list-style: none;
    padding-left: 22px; }
  .cross-resources-page li:before {
    content: "\2014";
    position: absolute;
    margin-left: -40px; }
  .cross-resources-page .crossover-button {
    text-align: center;
    text-transform: uppercase;
    line-height: 22px;
    display: inline-block;
    border-radius: 4px;
    padding: 16px 10px;
    width: fit-content;
    cursor: pointer;
    font-weight: 700;
    -webkit-appearance: unset;
    font-size: 12px;
    color: #80fff3;
    background-color: #2a2a2a;
    border: 2px solid #80fff3;
    box-shadow: 0px 2px 6px rgba(42, 42, 42, 0.18);
    padding: 16px 66px;
    text-transform: uppercase; }
    @media all and (min-width: 640px) {
      .cross-resources-page .crossover-button {
        font-size: 16px; } }
    .cross-resources-page .crossover-button:hover {
      text-decoration: none; }
    .cross-resources-page .crossover-button:hover {
      background-color: #494949; }
  .cross-resources-page img {
    margin: 0 auto; }
  .cross-resources-page .forms-container {
    margin-bottom: 100px; }
    .cross-resources-page .forms-container .form-title-description {
      margin-bottom: 40px; }
      @media all and (min-width: 992px) {
        .cross-resources-page .forms-container .form-title-description {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 80px;
          grid-column: 1/2;
          grid-row: 1/2; } }
      .cross-resources-page .forms-container .form-title-description hr {
        margin-bottom: 32px; }
      .cross-resources-page .forms-container .form-title-description p {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px; }
    .cross-resources-page .forms-container .forms {
      gap: 20px 80px; }
      @media all and (min-width: 992px) {
        .cross-resources-page .forms-container .forms {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-column: 1/3; } }
  .cross-resources-page .single-form {
    background: #fefefe;
    padding: 30px;
    gap: 36px; }
  .cross-resources-page .pdf-form img {
    margin-right: 22px; }
  .cross-resources-page .pdf-form p {
    margin: 0; }
  .cross-resources-page .links-container {
    margin-bottom: 0; }
    @media all and (min-width: 768px) {
      .cross-resources-page .links-container {
        margin-bottom: 120px; } }
    .cross-resources-page .links-container .link-title {
      margin-bottom: 62px; }
      @media all and (min-width: 992px) {
        .cross-resources-page .links-container .link-title {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 80px;
          grid-column: 1/2;
          grid-row: 1/2; } }
    @media all and (min-width: 992px) {
      .cross-resources-page .links-container .all-links {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column: 1/3;
        gap: 0 80px; } }
    .cross-resources-page .links-container .all-links .bell-container {
      margin-bottom: 40px; }
      @media all and (min-width: 992px) {
        .cross-resources-page .links-container .all-links .bell-container {
          grid-column: 1/2;
          grid-row: 1/2; } }
    .cross-resources-page .links-container .all-links .cellular-networks-container {
      margin-bottom: 40px; }
      @media all and (min-width: 992px) {
        .cross-resources-page .links-container .all-links .cellular-networks-container {
          grid-column: 1/2;
          grid-row: 2/3; } }
    .cross-resources-page .links-container .all-links .wireless-broadband-container {
      margin-bottom: 40px; }
      @media all and (min-width: 992px) {
        .cross-resources-page .links-container .all-links .wireless-broadband-container {
          grid-column: 1/2;
          grid-row: 3/4; } }
    .cross-resources-page .links-container .all-links .tool-container {
      padding-bottom: 40px; }
      @media all and (min-width: 992px) {
        .cross-resources-page .links-container .all-links .tool-container {
          grid-column: 2/3;
          grid-row: 1/4; } }
      .cross-resources-page .links-container .all-links .tool-container .tool-links .tools-description {
        color: rgba(24, 31, 35, 0.68);
        padding: 0px 34px 10px 0;
        margin: 0;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px; }
      .cross-resources-page .links-container .all-links .tool-container .tool-links .tool-single-link {
        padding: 19px 30px 19px 0;
        margin-bottom: 11px; }
        .cross-resources-page .links-container .all-links .tool-container .tool-links .tool-single-link .links-title {
          margin-bottom: 10px; }

.cross-project-examples-page {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 16px;
  /* Styles for the content section */ }
  @media all and (min-width: 1200px) {
    .cross-project-examples-page {
      padding: 0; } }
  .cross-project-examples-page h1 {
    margin: 80px 0 47px; }
  .cross-project-examples-page h2,
  .cross-project-examples-page p {
    margin: 0; }
  .cross-project-examples-page a {
    color: #181f23; }
    .cross-project-examples-page a:hover {
      color: rgba(24, 31, 35, 0.68);
      text-decoration: none; }
  .cross-project-examples-page #button {
    display: inline-block;
    background-color: rgba(24, 31, 35, 0.68);
    color: #80fff3;
    width: 100px;
    height: 100px;
    text-align: center;
    border-radius: 50%;
    position: fixed;
    bottom: 30px;
    right: 34px;
    transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    border: 1px solid #80fff3;
    text-transform: uppercase; }
    .cross-project-examples-page #button img {
      height: 25px;
      width: 15px;
      margin-top: 12px;
      margin-bottom: 6px; }
  .cross-project-examples-page #button.show {
    opacity: 1;
    visibility: visible; }
  .cross-project-examples-page .content {
    width: 77%;
    margin: 50px auto;
    font-family: "Merriweather", serif;
    font-size: 17px;
    color: #6c767a;
    line-height: 1.9; }
  @media (min-width: 500px) {
    .cross-project-examples-page .content {
      width: 43%; }
    .cross-project-examples-page #button {
      margin: 30px; } }
  .cross-project-examples-page .content h1 {
    margin-bottom: -10px;
    color: #03a9f4;
    line-height: 1.5; }
  .cross-project-examples-page .content h3 {
    font-style: italic;
    color: #96a2a7; }
  .cross-project-examples-page .place {
    display: block;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 46px; }
    .cross-project-examples-page .place p {
      display: inline-block; }
  .cross-project-examples-page .category-list-container {
    display: none; }
    @media all and (min-width: 768px) {
      .cross-project-examples-page .category-list-container {
        display: unset;
        margin-bottom: 50px; } }
    .cross-project-examples-page .category-list-container .category-list {
      gap: 57px;
      list-style: none;
      margin: 0;
      padding: 0;
      margin-bottom: 50px; }
      .cross-project-examples-page .category-list-container .category-list li {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0;
        padding-bottom: 0; }
        .cross-project-examples-page .category-list-container .category-list li::before {
          content: "";
          display: none; }
      .cross-project-examples-page .category-list-container .category-list .current {
        padding-bottom: 3px;
        border-bottom: 2px solid #181f23;
        font-weight: 700; }
  .cross-project-examples-page .dropdown-category-list-container {
    margin-bottom: 57px; }
  .cross-project-examples-page .article-container {
    margin-bottom: 80px; }
    .cross-project-examples-page .article-container .category-name-container h2 {
      background-color: #2a2a2a;
      color: #fefefe;
      padding: 15px 10px 15px 10px; }
      @media all and (max-width: 360px) {
        .cross-project-examples-page .article-container .category-name-container h2 {
          font-size: 30px; } }
      @media all and (min-width: 640px) {
        .cross-project-examples-page .article-container .category-name-container h2 {
          padding: 40px 10px 30px 55px; } }
    .cross-project-examples-page .article-container .top-article {
      margin: 0 auto 80px;
      max-width: 636px; }
      @media all and (min-width: 1200px) {
        .cross-project-examples-page .article-container .top-article {
          display: grid;
          grid-column: 1/2;
          grid-row: 1/2;
          padding: 0 0 0 49px;
          max-width: unset;
          align-items: unset; } }
      .cross-project-examples-page .article-container .top-article .top-article-image {
        background-color: #fefefe;
        position: relative;
        margin-bottom: 30px;
        max-width: 602px; }
        @media all and (min-width: 1200px) {
          .cross-project-examples-page .article-container .top-article .top-article-image {
            grid-column: 2/3;
            grid-row: 1/2;
            top: -130px;
            padding: 27px 30px 0; } }
        @media all and (min-width: 1200px) {
          .cross-project-examples-page .article-container .top-article .top-article-image img {
            margin: 0 auto;
            display: block;
            min-height: 518px;
            min-width: 602px;
            object-fit: cover; } }
      .cross-project-examples-page .article-container .top-article h3 {
        font-weight: 600;
        line-height: 49px;
        margin-bottom: 14px; }
      .cross-project-examples-page .article-container .top-article .top-article-details {
        max-width: 635px; }
        .cross-project-examples-page .article-container .top-article .top-article-details .top-article-description {
          font-weight: 400;
          font-size: 18px;
          line-height: 25px;
          margin-bottom: 75px; }
        .cross-project-examples-page .article-container .top-article .top-article-details .top-article-category {
          font-weight: 700;
          font-size: 20px;
          line-height: 27px; }
      @media all and (min-width: 1480px) {
        .cross-project-examples-page .article-container .top-article {
          display: grid;
          grid-template-columns: repeat(2, 1fr); } }
  @media all and (min-width: 1200px) {
    .cross-project-examples-page .slider-container {
      margin-top: -150px; }
      .cross-project-examples-page .slider-container.no-featured-project {
        margin-top: 0; } }
  @media all and (min-width: 640px) {
    .cross-project-examples-page .slider-container .uk-slider-items.uk-grid {
      margin-left: -60px; }
      .cross-project-examples-page .slider-container .uk-slider-items.uk-grid > * {
        padding-left: 60px; } }
  @media all and (min-width: 1200px) {
    .cross-project-examples-page .slider-container {
      margin-left: 55px; } }
  .cross-project-examples-page .slider-container .uk-width-auto .slider-article {
    max-width: 636px;
    margin-bottom: 80px; }
    .cross-project-examples-page .slider-container .uk-width-auto .slider-article .slider-article-image {
      width: 100%; }
      @media all and (min-width: 640px) {
        .cross-project-examples-page .slider-container .uk-width-auto .slider-article .slider-article-image {
          display: flex;
          height: 312px;
          width: 636px; }
          .cross-project-examples-page .slider-container .uk-width-auto .slider-article .slider-article-image img {
            margin-bottom: 0;
            width: 100%;
            object-fit: cover; } }
    .cross-project-examples-page .slider-container .uk-width-auto .slider-article .slider-article-details {
      padding: 30px 20px 20px 20px;
      border-left: 1px solid #181f23;
      border-bottom: 1px solid #181f23;
      min-height: 440px;
      position: relative; }
      .cross-project-examples-page .slider-container .uk-width-auto .slider-article .slider-article-details h3 {
        margin-bottom: 14px; }
      .cross-project-examples-page .slider-container .uk-width-auto .slider-article .slider-article-details .slider-place {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 46px; }
        .cross-project-examples-page .slider-container .uk-width-auto .slider-article .slider-article-details .slider-place p {
          display: inline-block; }
      .cross-project-examples-page .slider-container .uk-width-auto .slider-article .slider-article-details .slider-article-description {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 35px; }
      .cross-project-examples-page .slider-container .uk-width-auto .slider-article .slider-article-details .slider-article-category {
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        position: absolute;
        bottom: 20px; }
  .cross-project-examples-page .navigation-container {
    color: #181f23; }
    .cross-project-examples-page .navigation-container .navigation {
      display: flex;
      width: 100%;
      justify-content: space-between; }
      @media all and (min-width: 640px) {
        .cross-project-examples-page .navigation-container .navigation {
          display: block;
          width: unset;
          justify-content: unset; } }
      @media all and (min-width: 1200px) {
        .cross-project-examples-page .navigation-container .navigation {
          margin-right: 39px; } }
      .cross-project-examples-page .navigation-container .navigation a {
        color: inherit; }
        .cross-project-examples-page .navigation-container .navigation a img.arrow-left {
          margin: 0 16px; }
          @media all and (min-width: 640px) {
            .cross-project-examples-page .navigation-container .navigation a img.arrow-left {
              margin-left: 0; } }
        .cross-project-examples-page .navigation-container .navigation a img.arrow-right {
          margin: 0 16px; }
        @media all and (min-width: 640px) {
          .cross-project-examples-page .navigation-container .navigation a.next {
            margin-left: 80px; } }
        .cross-project-examples-page .navigation-container .navigation a:hover {
          text-decoration: none;
          opacity: 0.68; }
        .cross-project-examples-page .navigation-container .navigation a.uk-invisible {
          cursor: default;
          visibility: unset !important;
          opacity: 0.5; }

.cross-videos-page {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 16px;
  /* Styles for the content section */ }
  @media all and (min-width: 1200px) {
    .cross-videos-page {
      padding: 0; } }
  .cross-videos-page h1 {
    margin: 80px 0 47px; }
  .cross-videos-page h2 {
    margin: 0; }
  .cross-videos-page a {
    color: #181f23; }
    .cross-videos-page a:hover {
      color: rgba(24, 31, 35, 0.68);
      text-decoration: none; }
  .cross-videos-page #button {
    display: inline-block;
    background-color: rgba(24, 31, 35, 0.68);
    color: #80fff3;
    width: 100px;
    height: 100px;
    text-align: center;
    border-radius: 50%;
    position: fixed;
    bottom: 30px;
    right: 34px;
    transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    border: 1px solid #80fff3;
    text-transform: uppercase; }
    .cross-videos-page #button img {
      height: 25px;
      width: 15px;
      margin-top: 12px;
      margin-bottom: 6px; }
  .cross-videos-page #button.show {
    opacity: 1;
    visibility: visible; }
  .cross-videos-page .content {
    width: 77%;
    margin: 50px auto;
    font-family: "Merriweather", serif;
    font-size: 17px;
    color: #6c767a;
    line-height: 1.9; }
  @media (min-width: 500px) {
    .cross-videos-page .content {
      width: 43%; }
    .cross-videos-page #button {
      margin: 30px; } }
  .cross-videos-page .content h1 {
    margin-bottom: -10px;
    color: #03a9f4;
    line-height: 1.5; }
  .cross-videos-page .content h3 {
    font-style: italic;
    color: #96a2a7; }
  .cross-videos-page .place {
    display: block;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 46px; }
    .cross-videos-page .place p {
      display: inline-block; }
  .cross-videos-page .category-list-container {
    display: none; }
    @media all and (min-width: 768px) {
      .cross-videos-page .category-list-container {
        display: unset;
        margin-bottom: 50px; } }
    .cross-videos-page .category-list-container .category-list {
      gap: 57px;
      list-style: none;
      margin: 0;
      margin-bottom: 50px; }
      .cross-videos-page .category-list-container .category-list li {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0;
        padding-bottom: 0; }
        .cross-videos-page .category-list-container .category-list li::before {
          content: "";
          display: none; }
      .cross-videos-page .category-list-container .category-list .current {
        padding-bottom: 3px;
        border-bottom: 2px solid #181f23;
        font-weight: 700; }
  .cross-videos-page .dropdown-category-list-container {
    margin-bottom: 57px; }
    .cross-videos-page .dropdown-category-list-container .dropdown-category-list p {
      margin: 0; }
  .cross-videos-page .article-container {
    margin-bottom: 80px; }
    .cross-videos-page .article-container .category-name-container {
      padding-bottom: 44px; }
      .cross-videos-page .article-container .category-name-container h2 {
        background-color: #2a2a2a;
        color: #fefefe;
        padding: 15px 10px 15px 10px; }
        @media all and (max-width: 360px) {
          .cross-videos-page .article-container .category-name-container h2 {
            font-size: 30px; } }
        @media all and (min-width: 640px) {
          .cross-videos-page .article-container .category-name-container h2 {
            padding: 40px 10px 30px 55px; } }
    .cross-videos-page .article-container .vendor-description-container {
      max-width: 648px;
      padding-bottom: 44px; }
      .cross-videos-page .article-container .vendor-description-container > *:last-child {
        margin-bottom: 0; }
  .cross-videos-page .slider-container {
    margin-top: 0; }
    @media all and (min-width: 1200px) {
      .cross-videos-page .slider-container {
        margin-left: 55px; } }
    .cross-videos-page .slider-container .uk-slider-items {
      width: 100%; }
      @media all and (min-width: 640px) {
        .cross-videos-page .slider-container .uk-slider-items {
          width: auto; } }
      @media all and (min-width: 640px) {
        .cross-videos-page .slider-container .uk-slider-items.uk-grid {
          margin-left: -60px; }
          .cross-videos-page .slider-container .uk-slider-items.uk-grid > * {
            padding-left: 60px; } }
      .cross-videos-page .slider-container .uk-slider-items .slider-article-container {
        width: 100%; }
        @media all and (min-width: 640px) {
          .cross-videos-page .slider-container .uk-slider-items .slider-article-container {
            width: auto; } }
        @media all and (min-width: 640px) {
          .cross-videos-page .slider-container .uk-slider-items .slider-article-container .video-embed-container {
            display: flex;
            width: 636px;
            height: 312px; } }
        .cross-videos-page .slider-container .uk-slider-items .slider-article-container .video-embed-container iframe {
          width: 100%; }
          @media all and (min-width: 640px) {
            .cross-videos-page .slider-container .uk-slider-items .slider-article-container .video-embed-container iframe {
              height: 100%; } }
    .cross-videos-page .slider-container .navigation-container {
      color: #181f23; }
      .cross-videos-page .slider-container .navigation-container .navigation {
        margin-top: 40px;
        display: flex;
        width: 100%;
        justify-content: space-between; }
        @media all and (min-width: 960px) {
          .cross-videos-page .slider-container .navigation-container .navigation {
            margin-top: 80px; } }
        @media all and (min-width: 640px) {
          .cross-videos-page .slider-container .navigation-container .navigation {
            display: block;
            width: unset;
            justify-content: unset; } }
        @media all and (min-width: 1200px) {
          .cross-videos-page .slider-container .navigation-container .navigation {
            margin-right: 39px; } }
        .cross-videos-page .slider-container .navigation-container .navigation a {
          color: inherit; }
          .cross-videos-page .slider-container .navigation-container .navigation a img.arrow-left {
            margin: 0 16px; }
            @media all and (min-width: 640px) {
              .cross-videos-page .slider-container .navigation-container .navigation a img.arrow-left {
                margin-left: 0; } }
          .cross-videos-page .slider-container .navigation-container .navigation a img.arrow-right {
            margin: 0 16px; }
          @media all and (min-width: 640px) {
            .cross-videos-page .slider-container .navigation-container .navigation a.next {
              margin-left: 80px; } }
          .cross-videos-page .slider-container .navigation-container .navigation a:hover {
            text-decoration: none;
            opacity: 0.68; }
          .cross-videos-page .slider-container .navigation-container .navigation a.uk-invisible {
            cursor: default;
            visibility: unset !important;
            opacity: 0.5; }

.cross-our-work-page {
  color: #181f23;
  margin: 0 auto 80px; }
  @media all and (min-width: 768px) {
    .cross-our-work-page {
      margin: 0 auto 120px; } }
  .cross-our-work-page h1 {
    margin: 80px 0 90px; }
  .cross-our-work-page h2 {
    margin: 0; }
  .cross-our-work-page h3 {
    margin: 0;
    font-weight: 600;
    line-height: 49px;
    margin-bottom: 14px; }
  .cross-our-work-page p {
    margin: 0; }
  .cross-our-work-page a {
    color: #181f23; }
  .cross-our-work-page .project-examples {
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 auto 100px; }
    @media all and (min-width: 1200px) {
      .cross-our-work-page .project-examples {
        padding: 0; } }
    .cross-our-work-page .project-examples h2 {
      background-color: #2a2a2a;
      color: #fefefe;
      padding: 40px 0 30px 55px; }
    .cross-our-work-page .project-examples img {
      margin: 0 auto;
      display: block;
      margin-bottom: 30px; }
    .cross-our-work-page .project-examples .project-place {
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      margin-bottom: 46px; }
      .cross-our-work-page .project-examples .project-place p {
        display: inline-block; }
    .cross-our-work-page .project-examples .project-description {
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      margin-bottom: 35px; }
    .cross-our-work-page .project-examples .project-category {
      font-weight: 700;
      font-size: 20px;
      line-height: 27px; }
    .cross-our-work-page .project-examples .top-project-container {
      padding: 0 16px;
      margin: 60px auto;
      max-width: 636px; }
      @media all and (min-width: 1200px) {
        .cross-our-work-page .project-examples .top-project-container {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          padding: 0;
          margin-bottom: 80px;
          max-width: unset; } }
      .cross-our-work-page .project-examples .top-project-container .top-project-image-container {
        max-width: 619px;
        background-color: #fefefe;
        position: relative;
        margin-bottom: 30px; }
        @media all and (min-width: 1200px) {
          .cross-our-work-page .project-examples .top-project-container .top-project-image-container {
            grid-column: 2/3;
            grid-row: 1/2;
            top: -140px;
            padding: 28px 30px 0; } }
        @media all and (min-width: 1200px) {
          .cross-our-work-page .project-examples .top-project-container .top-project-image-container img {
            margin: 0 auto;
            display: block;
            min-height: 518px;
            min-width: 602px;
            object-fit: cover; } }
      .cross-our-work-page .project-examples .top-project-container .top-project-article-container {
        max-width: 619px; }
        @media all and (min-width: 1200px) {
          .cross-our-work-page .project-examples .top-project-container .top-project-article-container {
            margin-left: 55px; } }
    .cross-our-work-page .project-examples .bottom-project-container {
      padding: 0 16px;
      margin-bottom: 80px;
      gap: 61px; }
      @media all and (min-width: 1200px) {
        .cross-our-work-page .project-examples .bottom-project-container {
          align-items: unset;
          display: grid;
          grid-template-columns: repeat(2, 1fr); }
          .cross-our-work-page .project-examples .bottom-project-container.no-featured-project {
            margin-top: 0; } }
      @media all and (min-width: 1200px) {
        .cross-our-work-page .project-examples .bottom-project-container {
          margin-top: -150px; } }
      .cross-our-work-page .project-examples .bottom-project-container .bottom-project-image-container {
        width: 100%; }
        @media all and (min-width: 640px) {
          .cross-our-work-page .project-examples .bottom-project-container .bottom-project-image-container {
            display: flex;
            height: 312px;
            width: 636px; }
            .cross-our-work-page .project-examples .bottom-project-container .bottom-project-image-container img {
              margin: 0;
              width: 100%;
              object-fit: cover; } }
      .cross-our-work-page .project-examples .bottom-project-container .bottom-project-details {
        max-width: 596px; }
        @media all and (min-width: 1200px) {
          .cross-our-work-page .project-examples .bottom-project-container .bottom-project-details {
            padding: 30px 20px 20px 20px;
            border-left: 1px solid #181f23;
            border-bottom: 1px solid #181f23; } }
    .cross-our-work-page .project-examples .all-project-examples {
      text-align: center; }
      .cross-our-work-page .project-examples .all-project-examples a {
        border-bottom: 2px solid #2a2a2a;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        text-align: center; }
        .cross-our-work-page .project-examples .all-project-examples a:hover {
          color: rgba(24, 31, 35, 0.68);
          border-bottom: 2px solid rgba(24, 31, 35, 0.68);
          text-decoration: none; }
  .cross-our-work-page .case-study {
    background-color: #2a2a2a;
    color: #ffff;
    padding: 100px 16px; }
    @media all and (min-width: 500px) {
      .cross-our-work-page .case-study {
        padding: 100px 40px; } }
    .cross-our-work-page .case-study h2 {
      color: #ffff;
      margin-bottom: 80px; }
    .cross-our-work-page .case-study .case-studies-slider {
      padding-left: 0; }
      .cross-our-work-page .case-study .case-studies-slider .case-study-container {
        gap: 60px;
        margin-bottom: 71px; }
        @media all and (min-width: 992px) {
          .cross-our-work-page .case-study .case-studies-slider .case-study-container {
            flex-direction: row; } }
        .cross-our-work-page .case-study .case-studies-slider .case-study-container .case-study-article {
          max-width: 636px;
          padding: 0 0 46px 30px;
          border-left: 2px solid #80fff3;
          border-bottom: 2px solid #80fff3; }
          .cross-our-work-page .case-study .case-studies-slider .case-study-container .case-study-article .image-container {
            position: relative;
            margin-bottom: 24px; }
            .cross-our-work-page .case-study .case-studies-slider .case-study-container .case-study-article .image-container .image-overlay {
              position: absolute;
              top: 5px;
              left: 5px; }
              @media all and (min-width: 640px) {
                .cross-our-work-page .case-study .case-studies-slider .case-study-container .case-study-article .image-container .image-overlay {
                  top: 20px;
                  left: 20px; } }
              .cross-our-work-page .case-study .case-studies-slider .case-study-container .case-study-article .image-container .image-overlay .logo {
                margin-bottom: 10px; }
                @media all and (min-width: 640px) {
                  .cross-our-work-page .case-study .case-studies-slider .case-study-container .case-study-article .image-container .image-overlay .logo {
                    margin-bottom: 64px; } }
              .cross-our-work-page .case-study .case-studies-slider .case-study-container .case-study-article .image-container .image-overlay .case-title {
                margin-bottom: 10px;
                font-size: 26px; }
                @media all and (min-width: 640px) {
                  .cross-our-work-page .case-study .case-studies-slider .case-study-container .case-study-article .image-container .image-overlay .case-title {
                    font-size: 36px;
                    font-weight: 600; } }
              .cross-our-work-page .case-study .case-studies-slider .case-study-container .case-study-article .image-container .image-overlay .location {
                font-size: 20px;
                font-weight: 500px; }
          .cross-our-work-page .case-study .case-studies-slider .case-study-container .case-study-article .case-study-details {
            margin-top: 24px; }
            .cross-our-work-page .case-study .case-studies-slider .case-study-container .case-study-article .case-study-details .case-study-description {
              font-weight: 400;
              font-size: 14px;
              line-height: 19px;
              margin-bottom: 60px; }
            .cross-our-work-page .case-study .case-studies-slider .case-study-container .case-study-article .case-study-details .view-case-study-button {
              color: #80fff3;
              padding: 16px 20px;
              margin-bottom: 30px;
              border: 2px solid #80fff3;
              border-radius: 4px; }
    .cross-our-work-page .case-study .all-case-studies {
      text-align: center; }
      .cross-our-work-page .case-study .all-case-studies a {
        color: #fefefe;
        border-bottom: 2px solid #fefefe;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        text-align: center; }
        .cross-our-work-page .case-study .all-case-studies a:hover {
          color: rgba(254, 254, 254, 0.38);
          border-bottom: 2px solid rgba(254, 254, 254, 0.38);
          text-decoration: none; }
  .cross-our-work-page .testimonials {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 16px; }
    @media all and (min-width: 500px) {
      .cross-our-work-page .testimonials {
        padding: 120px 52px 0; } }
    .cross-our-work-page .testimonials h2 {
      margin-bottom: 80px; }
    .cross-our-work-page .testimonials .tesimonials-container {
      gap: 40px;
      margin-bottom: 78px; }
      @media all and (min-width: 1200px) {
        .cross-our-work-page .testimonials .tesimonials-container {
          flex-direction: row;
          align-items: unset; } }
      .cross-our-work-page .testimonials .tesimonials-container .testimonial-article {
        max-width: 416px; }
        .cross-our-work-page .testimonials .tesimonials-container .testimonial-article .comment-icon {
          margin-bottom: 50px; }
        .cross-our-work-page .testimonials .tesimonials-container .testimonial-article .tesimonial-quote-container {
          height: 150px;
          margin-bottom: 54px; }
          .cross-our-work-page .testimonials .tesimonials-container .testimonial-article .tesimonial-quote-container p {
            font-weight: 400;
            font-size: 18px;
            line-height: 25px;
            margin-bottom: 16px; }
        .cross-our-work-page .testimonials .tesimonials-container .testimonial-article hr {
          margin: 0;
          background-color: #2a2a2a;
          height: 6px;
          margin: 0 0 18px;
          max-width: 416px; }
        .cross-our-work-page .testimonials .tesimonials-container .testimonial-article .testimonial-info {
          font-weight: 700;
          font-size: 20px;
          line-height: 27px; }
    .cross-our-work-page .testimonials .all-testimonials {
      border-bottom: 2px solid #2a2a2a;
      display: inline-block;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px; }
      .cross-our-work-page .testimonials .all-testimonials:hover {
        color: rgba(24, 31, 35, 0.68);
        text-decoration: none; }

.cross-404-page-container .page-content {
  margin: 80px 0 90px 0; }
  .cross-404-page-container .page-content a {
    text-align: center;
    text-transform: uppercase;
    line-height: 22px;
    display: inline-block;
    border-radius: 4px;
    padding: 16px 10px;
    width: fit-content;
    cursor: pointer;
    font-weight: 700;
    -webkit-appearance: unset;
    font-size: 12px;
    color: #80fff3;
    background-color: #2a2a2a;
    border: 2px solid #80fff3; }
    @media all and (min-width: 640px) {
      .cross-404-page-container .page-content a {
        font-size: 16px; } }
    .cross-404-page-container .page-content a:hover {
      text-decoration: none; }
    .cross-404-page-container .page-content a:hover {
      background-color: #494949; }

.partner-page-container .partner-page-header-content .page-header-container {
  padding-bottom: 60px; }

.partner-page-container .partner-page-top-content {
  background-position: 0 -60px;
  background-repeat: no-repeat;
  background-color: #fefefe;
  margin-bottom: 100px; }
  @media all and (max-width: 640px) {
    .partner-page-container .partner-page-top-content {
      margin-bottom: 20px; } }
  @media all and (min-width: 640px) {
    .partner-page-container .partner-page-top-content {
      margin-top: 80px; } }
  .partner-page-container .partner-page-top-content h2 {
    color: #fefefe; }
  .partner-page-container .partner-page-top-content .contact-page-top-heading-container {
    height: 166px; }
    @media all and (min-width: 640px) {
      .partner-page-container .partner-page-top-content .contact-page-top-heading-container {
        height: 266px; } }
  .partner-page-container .partner-page-top-content .contact-form-container {
    font-family: "Nunito Sans", Arial, sans-serif;
    border: 2px solid #80fff3;
    border-radius: 4px;
    width: 100%;
    margin-bottom: 60px; }
    @media all and (min-width: 640px) {
      .partner-page-container .partner-page-top-content .contact-form-container {
        margin-bottom: 0; } }
    @media all and (min-width: 960px) {
      .partner-page-container .partner-page-top-content .contact-form-container {
        width: unset;
        min-width: 853px; } }
    .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper {
      background: #2a2a2a;
      padding: 20px 10px; }
      @media all and (min-width: 640px) {
        .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper {
          padding: 40px 30px; } }
      .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper .gform_required_legend,
      .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper .gfield_required,
      .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper .gfield_validation_message {
        color: #ff5757; }
      .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper .gform_heading h2 {
        text-align: center;
        margin-bottom: 20px; }
        @media all and (min-width: 640px) {
          .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper .gform_heading h2 {
            margin-bottom: 40px; } }
      .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields {
        display: block; }
        .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield {
          position: relative;
          font-family: "Nunito Sans", Arial, sans-serif;
          margin-bottom: 20px;
          margin-bottom: 17px; }
          .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .gfield_required.gfield_required_asterisk,
          .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .gfield_required .gfield_required.gfield_required_asterisk {
            display: none; }
          .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container input,
          .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container select,
          .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container textarea {
            padding-left: 18px;
            padding-top: 17px;
            padding-bottom: 17px; }
          .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container input,
          .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container select,
          .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container .chosen-container,
          .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container textarea {
            border: 3px solid #ff5757; }
          .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.gfield_error .validation_message {
            background-color: transparent;
            border: unset;
            margin-top: unset;
            padding-top: 12px;
            padding-left: 20px; }
          .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .gfield_label {
            color: rgba(24, 31, 35, 0.68);
            background-color: #fefefe;
            padding: unset;
            font-size: 18px;
            font-weight: 400;
            margin-left: 21px;
            margin-bottom: 0px;
            position: absolute;
            top: 19px;
            z-index: 3;
            color: rgba(24, 31, 35, 0.68); }
            .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .gfield_label a {
              font-size: inherit; }
            .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .gfield_label .gfield_required {
              display: inline-block;
              font-size: 13.008px;
              padding-left: 0.125em; }
            .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .gfield_label.hidden {
              color: transparent;
              background-color: transparent; }
              .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .gfield_label.hidden .gfield_required {
                color: transparent; }
          .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.select-field .gfield_label.hidden {
            display: none; }
          .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container input,
          .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container select,
          .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container textarea {
            color: #181f23;
            border: 1px solid rgba(24, 31, 35, 0.68);
            padding-left: 20px;
            padding-top: 19px;
            padding-bottom: 19px;
            line-height: 24px;
            font-size: 18px;
            width: 100%;
            border-radius: 4px;
            font-family: inherit; }
            .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container input:focus,
            .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container select:focus,
            .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container textarea:focus {
              outline: unset;
              border: 3px solid #80fff3;
              padding-left: 18px;
              padding-top: 17px;
              padding-bottom: 17px; }
          .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_checkbox .gfield_checkbox .gchoice {
            vertical-align: top; }
            .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_checkbox .gfield_checkbox .gchoice input.gfield-choice-input {
              vertical-align: top;
              width: unset; }
          .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_select {
            position: relative;
            z-index: 0;
            background-color: #fefefe;
            border-radius: 4px; }
            .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_select::after {
              content: "\f078";
              font-family: "Font Awesome 6 Pro";
              bottom: 20px;
              right: 15px;
              position: absolute;
              font-size: 18px;
              line-height: 25px;
              color: rgba(24, 31, 35, 0.68);
              z-index: -1; }
              @media all and (min-width: 640px) {
                .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_select::after {
                  right: 28px; } }
            .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_select select {
              background-color: transparent;
              appearance: none;
              z-index: 2;
              position: relative;
              width: 100%;
              padding-right: 30px; }
          .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_consent {
            color: #fefefe; }
            .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_consent input[type="checkbox"] {
              -webkit-appearance: none;
              -moz-appearance: none;
              outline: 0;
              appearance: none;
              max-width: 16px;
              width: 100%;
              height: 16px;
              border-radius: 3.3px;
              margin: 0;
              background: transparent;
              float: left;
              padding: 0; }
            .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_consent input[type="checkbox"]:checked {
              background-size: 100%;
              background-repeat: no-repeat;
              background-position: center; }
            .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_consent label {
              display: block;
              font-weight: normal;
              padding-left: 25px;
              font-size: 14px;
              margin-bottom: 0;
              position: relative;
              top: -2px; }
            .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_consent input[type="checkbox"] {
              border: 1px solid #80fff3; }
            .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_consent input[type="checkbox"]:checked {
              background-image: url("../../dist/images/checkbox-tilt.svg"); }
            .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container.ginput_container_consent a {
              color: #fefefe;
              font-weight: 700; }
          .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container textarea {
            height: unset;
            max-height: 120px; }
            @media all and (min-width: 640px) {
              .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield .ginput_container textarea {
                max-height: 180px; } }
          .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.dropdown-box {
            z-index: 12; }
          .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.your-message-box textarea {
            display: initial; }
          .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_body.gform-body .gform_fields .gfield.gfield_error .ginput_container label {
            color: #ff5757; }
      .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_footer {
        padding-bottom: unset;
        padding-top: 20px;
        margin: unset;
        display: block; }
        .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_footer input.gform_button.button {
          margin-bottom: unset; }
        .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_footer input.gform_button.button {
          text-align: center;
          text-transform: uppercase;
          line-height: 22px;
          display: inline-block;
          border-radius: 4px;
          padding: 16px 10px;
          width: fit-content;
          cursor: pointer;
          font-weight: 700;
          -webkit-appearance: unset;
          font-size: 12px;
          color: #80fff3;
          background-color: #2a2a2a;
          border: 1px solid #80fff3;
          padding: 16px 0;
          width: 100%; }
          @media all and (min-width: 640px) {
            .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_footer input.gform_button.button {
              font-size: 16px; } }
          .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_footer input.gform_button.button:hover {
            text-decoration: none; }
          .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_footer input.gform_button.button:hover {
            background-color: #494949; }
          @media all and (min-width: 640px) {
            .partner-page-container .partner-page-top-content .contact-form-container .gform_wrapper form .gform_footer input.gform_button.button {
              padding: 16px 90px;
              width: fit-content; } }
    .partner-page-container .partner-page-top-content .contact-form-container .gform_confirmation_wrapper {
      background: rgba(255, 255, 255, 0.18);
      backdrop-filter: blur(10px);
      color: #fefefe;
      padding: 25px 30px 30px 30px; }
      @media all and (min-width: 640px) {
        .partner-page-container .partner-page-top-content .contact-form-container .gform_confirmation_wrapper {
          padding: 50px 60px 60px 60px; } }
      @media all and (min-width: 1200px) {
        .partner-page-container .partner-page-top-content .contact-form-container .gform_confirmation_wrapper {
          min-width: 973px; } }
      .partner-page-container .partner-page-top-content .contact-form-container .gform_confirmation_wrapper a {
        color: inherit; }
      .partner-page-container .partner-page-top-content .contact-form-container .gform_confirmation_wrapper .title {
        color: inherit;
        font-weight: 600;
        font-size: 22px;
        margin-bottom: 30px;
        text-align: center; }
        @media all and (min-width: 640px) {
          .partner-page-container .partner-page-top-content .contact-form-container .gform_confirmation_wrapper .title {
            font-weight: 800;
            font-size: 48px;
            margin-bottom: 60px;
            text-align: left; } }
      .partner-page-container .partner-page-top-content .contact-form-container .gform_confirmation_wrapper .body {
        color: inherit;
        margin-bottom: 32px;
        text-align: center; }
        @media all and (min-width: 640px) {
          .partner-page-container .partner-page-top-content .contact-form-container .gform_confirmation_wrapper .body {
            margin-bottom: 62px;
            text-align: left; } }
        .partner-page-container .partner-page-top-content .contact-form-container .gform_confirmation_wrapper .body strong {
          font-weight: 800; }
      .partner-page-container .partner-page-top-content .contact-form-container .gform_confirmation_wrapper .social-media {
        font-size: 43px; }
        .partner-page-container .partner-page-top-content .contact-form-container .gform_confirmation_wrapper .social-media a:hover {
          color: #80fff3; }
        .partner-page-container .partner-page-top-content .contact-form-container .gform_confirmation_wrapper .social-media a.twitter-icon {
          margin-right: 100px; }
  .partner-page-container .partner-page-top-content .uk-container {
    padding-top: 60px; }
  .partner-page-container .partner-page-top-content .chosen-single {
    color: #181f23 !important;
    margin: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    border: none !important;
    background: none !important;
    box-shadow: none !important; }
    .partner-page-container .partner-page-top-content .chosen-single b {
      display: none !important; }
  .partner-page-container .partner-page-top-content .chosen-drop {
    margin-top: 2px !important;
    border-radius: 4px !important; }
    .partner-page-container .partner-page-top-content .chosen-drop .chosen-search {
      padding: 0 !important; }
    .partner-page-container .partner-page-top-content .chosen-drop .active-result {
      color: rgba(24, 31, 35, 0.68);
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      padding: 10px 0 10px 13px !important;
      border-bottom: 1px solid rgba(42, 42, 42, 0.2); }
      .partner-page-container .partner-page-top-content .chosen-drop .active-result.gf_placeholder {
        display: none !important; }
      .partner-page-container .partner-page-top-content .chosen-drop .active-result:first-child, .partner-page-container .partner-page-top-content .chosen-drop .active-result:last-child {
        border: none; }
      .partner-page-container .partner-page-top-content .chosen-drop .active-result:first-child {
        color: #181f23; }
  .partner-page-container .partner-page-top-content .delete-dropdown,
  .partner-page-container .partner-page-top-content .chosen-search-input {
    display: none; }

.partner-page-container .partner-details-container .uk-container {
  margin-bottom: 60px; }
  .partner-page-container .partner-details-container .uk-container img {
    max-width: 604px;
    width: calc(100% - 40px); }
  .partner-page-container .partner-details-container .uk-container .partner-description-container {
    font-size: 24px;
    color: #181f23; }
    .partner-page-container .partner-details-container .uk-container .partner-description-container.uk-grid-margin {
      margin-top: 40px; }

.partner-page-container .header-slider {
  margin-left: 56px; }
  @media all and (max-width: 960px) {
    .partner-page-container .header-slider {
      margin-left: 16px; } }
  @media all and (min-width: 640px) {
    .partner-page-container .header-slider .slider-header-text {
      line-height: 49px; } }
  .partner-page-container .header-slider .slider-header-text .text {
    font-size: 26px;
    margin: 0; }
    @media all and (min-width: 640px) {
      .partner-page-container .header-slider .slider-header-text .text {
        font-size: 36px;
        line-height: 30px; } }
  .partner-page-container .header-slider .uk-slider {
    position: relative;
    padding: 30px 0 0; }
    @media all and (min-width: 640px) {
      .partner-page-container .header-slider .uk-slider {
        padding: 50px 0 0; } }
  .partner-page-container .header-slider .uk-slider-items.uk-grid {
    cursor: ew-resize;
    margin-left: -15px; }
    .partner-page-container .header-slider .uk-slider-items.uk-grid > * {
      padding-left: 15px; }
    @media all and (min-width: 640px) {
      .partner-page-container .header-slider .uk-slider-items.uk-grid {
        margin-left: -20px; }
        .partner-page-container .header-slider .uk-slider-items.uk-grid > * {
          padding-left: 20px; } }
  .partner-page-container .header-slider li {
    padding-bottom: 0; }
    .partner-page-container .header-slider li a {
      cursor: inherit; }
    .partner-page-container .header-slider li .article-container {
      position: relative;
      width: 278px; }
      @media all and (min-width: 640px) {
        .partner-page-container .header-slider li .article-container {
          width: 404px; } }
      .partner-page-container .header-slider li .article-container .thumbnail {
        max-width: 278px;
        height: 137px; }
        @media all and (min-width: 640px) {
          .partner-page-container .header-slider li .article-container .thumbnail {
            max-width: unset;
            height: 200px;
            width: 404px; } }
        .partner-page-container .header-slider li .article-container .thumbnail img,
        .partner-page-container .header-slider li .article-container .thumbnail iframe {
          height: 100%;
          width: 100%;
          object-fit: cover; }
      .partner-page-container .header-slider li .article-container .thumbnail-overlay {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0) 1.56%, rgba(0, 0, 0, 0.8) 100%);
        height: 100%;
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: end;
        width: 100%;
        box-sizing: border-box;
        position: absolute;
        left: 0px;
        bottom: 0px;
        font-size: 12px;
        padding: 0 10px 16px 10px; }
        @media all and (min-width: 640px) {
          .partner-page-container .header-slider li .article-container .thumbnail-overlay {
            font-size: 14px;
            padding: 0 20px 18px 20px; } }
        .partner-page-container .header-slider li .article-container .thumbnail-overlay .headline h5 {
          color: #fefefe;
          font-weight: 700;
          margin-bottom: 9px; }
          @media all and (min-width: 640px) {
            .partner-page-container .header-slider li .article-container .thumbnail-overlay .headline h5 {
              margin-bottom: 11px; } }
        .partner-page-container .header-slider li .article-container .thumbnail-overlay .text {
          font-size: inherit;
          color: #fefefe;
          font-weight: 400;
          margin-right: 33px; }
          @media all and (min-width: 640px) {
            .partner-page-container .header-slider li .article-container .thumbnail-overlay .text {
              margin-right: 43px; } }

.partner-page-container .case-study-section,
.partner-page-container .video-section {
  margin-top: 30px; }
  @media all and (min-width: 640px) {
    .partner-page-container .case-study-section,
    .partner-page-container .video-section {
      margin-top: 50px; } }
  .partner-page-container .case-study-section iframe,
  .partner-page-container .video-section iframe {
    width: 278px;
    height: 137px; }
    @media all and (min-width: 640px) {
      .partner-page-container .case-study-section iframe,
      .partner-page-container .video-section iframe {
        width: 404px;
        height: 200px; } }
  .partner-page-container .case-study-section .navigation-container,
  .partner-page-container .video-section .navigation-container {
    color: #181f23; }
    @media all and (min-width: 640px) {
      .partner-page-container .case-study-section .navigation-container,
      .partner-page-container .video-section .navigation-container {
        justify-content: center; } }
    .partner-page-container .case-study-section .navigation-container .navigation,
    .partner-page-container .video-section .navigation-container .navigation {
      margin-top: 20px;
      display: flex;
      width: 100%;
      justify-content: space-between; }
      @media all and (min-width: 640px) {
        .partner-page-container .case-study-section .navigation-container .navigation,
        .partner-page-container .video-section .navigation-container .navigation {
          display: block;
          width: unset;
          justify-content: unset; } }
      @media all and (min-width: 1200px) {
        .partner-page-container .case-study-section .navigation-container .navigation,
        .partner-page-container .video-section .navigation-container .navigation {
          margin-right: 39px; } }
      .partner-page-container .case-study-section .navigation-container .navigation a,
      .partner-page-container .video-section .navigation-container .navigation a {
        color: inherit; }
        .partner-page-container .case-study-section .navigation-container .navigation a img.arrow-left,
        .partner-page-container .video-section .navigation-container .navigation a img.arrow-left {
          margin: 0 16px; }
          @media all and (min-width: 640px) {
            .partner-page-container .case-study-section .navigation-container .navigation a img.arrow-left,
            .partner-page-container .video-section .navigation-container .navigation a img.arrow-left {
              margin-left: 0; } }
        .partner-page-container .case-study-section .navigation-container .navigation a img.arrow-right,
        .partner-page-container .video-section .navigation-container .navigation a img.arrow-right {
          margin: 0 16px; }
        @media all and (min-width: 640px) {
          .partner-page-container .case-study-section .navigation-container .navigation a.next,
          .partner-page-container .video-section .navigation-container .navigation a.next {
            margin-left: 80px; } }
        .partner-page-container .case-study-section .navigation-container .navigation a:hover,
        .partner-page-container .video-section .navigation-container .navigation a:hover {
          text-decoration: none;
          opacity: 0.68; }
        .partner-page-container .case-study-section .navigation-container .navigation a.uk-invisible,
        .partner-page-container .video-section .navigation-container .navigation a.uk-invisible {
          cursor: default;
          visibility: unset !important;
          opacity: 0.5; }
      @media all and (min-width: 640px) {
        .partner-page-container .case-study-section .navigation-container .navigation,
        .partner-page-container .video-section .navigation-container .navigation {
          margin-top: 40px; } }

.partner-page-container .case-study-section .thumbnail-overlay {
  flex-direction: row !important;
  align-items: end !important;
  justify-content: space-between !important;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(180deg, transparent 1.56%, rgba(0, 0, 0, 0.8)) !important; }
