.partner-page-container {
  .partner-page-header-content {
    .page-header-container {
      padding-bottom: 60px;
    }
  }
  .partner-page-top-content {
    background-position: 0 -60px;
    background-repeat: no-repeat;
    background-color: $white;
    margin-bottom: 100px;
    @media all and (max-width: $screen-sm) {
      margin-bottom: 20px;
    }
    @media all and (min-width: $screen-sm) {
      margin-top: 80px;
    }
    h2 {
      color: $white;
    }
    .contact-page-top-heading-container {
      height: 166px;
      @media all and (min-width: $screen-sm) {
        height: 266px;
      }
    }
    .contact-form-container {
      font-family: $nunitoSans;
      border: 2px solid $lightblue;
      border-radius: 4px;
      width: 100%;
      margin-bottom: 60px;
      @media all and (min-width: $screen-sm) {
        margin-bottom: 0;
      }
      @media all and (min-width: $screen-md) {
        width: unset;
        min-width: 853px;
      }
      .gform_wrapper {
        background: $bg-dark;
        padding: 20px 10px;
        @media all and (min-width: $screen-sm) {
          padding: 40px 30px;
        }
        .gform_required_legend,
        .gfield_required,
        .gfield_validation_message {
          color: $error-dark-bg;
        }
        .gform_heading h2 {
          text-align: center;
          margin-bottom: 20px;
          @media all and (min-width: $screen-sm) {
            margin-bottom: 40px;
          }
        }
        form {
          .gform_body.gform-body {
            .gform_fields {
              display: block;
              .gfield {
                @include gform-field-container();
                margin-bottom: 17px;
                .gfield_label {
                  @include gform-field-label();
                  color: $grey-2;
                }
                &.select-field {
                  .gfield_label.hidden {
                    display: none;
                  }
                }
                .ginput_container {
                  @include gform-input-fields();
                  &.ginput_container_consent {
                    color: $white;
                    @include gform-checkbox-tilt;
                    a {
                      color: $white;
                      font-weight: 700;
                    }
                  }
                  textarea {
                    height: unset;
                    max-height: 120px;
                    @media all and (min-width: $screen-sm) {
                      max-height: 180px;
                    }
                  }
                }
                &.dropdown-box {
                  z-index: 12;
                }
                &.your-message-box textarea {
                  display: initial;
                }
                &.gfield_error .ginput_container label {
                  color: $error-dark-bg;
                }
              }
            }
          }
          .gform_footer {
            @include gform-footer($padding-top: 20px);
            @media all and (min-width: $screen-sm) {
              $padding-top: 40px;
            }
            input.gform_button.button {
              @include button-tilt-dark($border-size: 1px);
              padding: 16px 0;
              width: 100%;
              @media all and (min-width: $screen-sm) {
                padding: 16px 90px;
                width: fit-content;
              }
            }
          }
        }
      }
      .gform_confirmation_wrapper {
        background: rgba(255, 255, 255, 0.18);
        backdrop-filter: blur(10px);
        color: $white;
        padding: 25px 30px 30px 30px;
        @media all and (min-width: $screen-sm) {
          padding: 50px 60px 60px 60px;
        }
        @media all and (min-width: $screen-lg) {
          min-width: 973px;
        }
        a {
          color: inherit;
        }
        .title {
          color: inherit;
          font-weight: 600;
          font-size: 22px;
          margin-bottom: 30px;
          text-align: center;
          @media all and (min-width: $screen-sm) {
            font-weight: 800;
            font-size: 48px;
            margin-bottom: 60px;
            text-align: left;
          }
        }
        .body {
          color: inherit;
          margin-bottom: 32px;
          text-align: center;
          @media all and (min-width: $screen-sm) {
            margin-bottom: 62px;
            text-align: left;
          }
          strong {
            font-weight: 800;
          }
        }
        .social-media {
          font-size: 43px;
          a {
            &:hover {
              color: $lightblue;
            }
            &.twitter-icon {
              margin-right: 100px;
            }
          }
        }
      }
    }
    .uk-container {
      padding-top: 60px;
    }
    //   Dropdown
    .chosen-single {
      color: $black !important;
      margin: 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      border: none !important;
      background: none !important;
      box-shadow: none !important;
      b {
        display: none !important;
      }
    }
    .chosen-drop {
      margin-top: 2px !important;
      border-radius: 4px !important;
      .chosen-search {
        padding: 0 !important;
      }
      .active-result {
        color: $grey-2;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        padding: 10px 0 10px 13px !important;
        border-bottom: 1px solid rgba(42, 42, 42, 0.2);
        &.gf_placeholder {
          display: none !important;
        }
        &:first-child,
        &:last-child {
          border: none;
        }
        &:first-child {
          color: $black;
        }
      }
    }
    .delete-dropdown,
    .chosen-search-input {
      display: none;
    }
  }
  .partner-details-container {
    .uk-container {
      margin-bottom: 60px;
      img {
        max-width: 604px;
        width: calc(100% - 40px);
      }
      .partner-description-container {
        font-size: 24px;
        color: #181f23;
        &.uk-grid-margin {
          margin-top: 40px;
        }
      }
    }
  }
  //    .header-slider .slider-header-text
  .header-slider {
    margin-left: 56px;
    @media all and (max-width: $screen-md) {
      margin-left: 16px;
    }
    .slider-header-text {
      @media all and (min-width: $screen-sm) {
        line-height: 49px;
      }
      .text {
        font-size: 26px;
        margin: 0;
        @media all and (min-width: $screen-sm) {
          font-size: 36px;
          line-height: 30px;
        }
      }
    }
    .uk-slider {
      position: relative;
      padding: 30px 0 0;
      @media all and (min-width: $screen-sm) {
        padding: 50px 0 0;
      }
    }
    .uk-slider-items.uk-grid {
      cursor: ew-resize;
      // cursor: url("../../dist/images/cross-drag-cursor.png") 40 40, pointer;
      margin-left: -15px;
      & > * {
        padding-left: 15px;
      }
      @media all and (min-width: $screen-sm) {
        margin-left: -20px;
        & > * {
          padding-left: 20px;
        }
      }
    }
    li {
      padding-bottom: 0;
      a {
        cursor: inherit;
      }
      .article-container {
        position: relative;
        width: 278px;
        @media all and (min-width: $screen-sm) {
          width: 404px;
        }
        .thumbnail {
          max-width: 278px;
          height: 137px;
          @media all and (min-width: $screen-sm) {
            max-width: unset;
            height: 200px;
            width: 404px;
          }
          img,
          iframe {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
        .thumbnail-overlay {
          background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.2) 0%,
              rgba(0, 0, 0, 0.2) 100%
            ),
            linear-gradient(
              180deg,
              rgba(0, 0, 0, 0) 1.56%,
              rgba(0, 0, 0, 0.8) 100%
            );
          height: 100%;
          align-items: flex-start;
          display: flex;
          flex-direction: column;
          justify-content: end;
          width: 100%;
          box-sizing: border-box;
          position: absolute;
          left: 0px;
          bottom: 0px;
          font-size: 12px;
          padding: 0 10px 16px 10px;
          @media all and (min-width: $screen-sm) {
            font-size: 14px;
            padding: 0 20px 18px 20px;
          }
          .headline {
            h5 {
              color: $white;
              font-weight: 700;
              margin-bottom: 9px;
              @media all and (min-width: $screen-sm) {
                margin-bottom: 11px;
              }
            }
          }
          .text {
            font-size: inherit;
            color: $white;
            font-weight: 400;
            margin-right: 33px;
            @media all and (min-width: $screen-sm) {
              margin-right: 43px;
            }
          }
        }
      }
    }
  }

  .case-study-section,
  .video-section {
    margin-top: 30px;
    @media all and (min-width: $screen-sm) {
      margin-top: 50px;
    }

    iframe {
      width: 278px;
      height: 137px;
      @media all and (min-width: $screen-sm) {
        width: 404px;
        height: 200px;
      }
    }

    .navigation-container {
      color: $black;
      @media all and (min-width: $screen-sm) {
        justify-content: center;
      }
      .navigation {
        margin-top: 20px;
        @include slider-navigation();
        @media all and (min-width: $screen-sm) {
          margin-top: 40px;
        }
      }
    }
    // .thumbnail-overlay {
    //   background: none !important;
    // }
  }

  .case-study-section {
    .thumbnail-overlay {
      flex-direction: row !important;
      align-items: end !important;
      justify-content: space-between !important;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        linear-gradient(180deg, transparent 1.56%, rgba(0, 0, 0, 0.8)) !important;
    }
  }
}
