.cross-product-detail-page {
  color: $black;
  max-width: 1265px;
  margin: 0 auto 120px;
  padding: 0 16px;

  a {
    color: $black;
  }

  .back-to-shop-container {
    margin: 70px 0 18px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;

    p {
      margin: 0;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;

      &:hover {
        color: $link-hover-dark;
        text-decoration: none;
      }
    }

    .arrow-left {
      margin-right: 15px;
    }
  }

  .product-container {
    margin-bottom: 90px;

    img {
      // height: 250px;
      width: 197px;

      @media all and (min-width: $screen-sm) {
        // height: 570px;
        // width: 449px;
        min-width: 450px;
        width: 450px;
      }
    }

    @media all and (min-width: $screen-mdl) {
      flex-direction: row;
      gap: 110px;
    }

    h2 {
      font-weight: 800;
      // font-size: 48px;
      line-height: 1;
      margin-bottom: 10px;

      @media all and (min-width: $screen-sm) {
        line-height: 65px;
      }
    }

    .part-number {
      margin: 0 0 8px;
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;

      span {
        color: $grey-2;
      }
    }

    hr {
      margin: 0 0 42px;
      border: 2px solid $black;
    }
    .specification {
      font-weight: 600;
      font-size: 32px;
      line-height: 44px;
      margin: 0 0 26px;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        margin-left: 40px;
        padding-bottom: 0;
        &:not(:last-child) {
          margin-bottom: 30px;
        }
      }
    }
  }

  .get-more-info-form-container {
    max-width: 880px;
    margin: 0 auto;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.168) -12.42%,
      rgba(0, 0, 0, 0.066) 46.75%,
      rgba(43, 215, 193, 0.252) 109.25%
    );
    border: 1px solid $grey-4;
    border-radius: 4px;
    padding: 30px;

    h2 {
      margin: 0 0 30px;
      font-weight: 800;
      font-size: 48px;
      line-height: 65px;
    }

    p {
      margin: 0 0 40px;
      font-weight: 400;
      font-size: 24px;
      line-height: 33px;
    }

    .form {
      .gform_body.gform-body {
        .gform_fields {
          display: block;
          .gfield {
            @include gform-field-container();
            margin-bottom: 17px;
            .gfield_label {
              @include gform-field-label();
              margin-left: 20px;
            }
            &.select-field {
              .gfield_label.hidden {
                display: none;
              }
            }
            .ginput_container {
              @include gform-input-fields();
              input {
                border: 1px solid $grey-4;
                &:focus {
                  border: 1px solid $grey-1;
                  padding-left: 19px;
                  padding-top: 19px;
                  padding-bottom: 19px;
                }
              }
              &.ginput_container_consent {
                @include gform-checkbox-grey;
                a {
                  color: $grey-2;
                  font-weight: 700;
                }
              }

              textarea {
                max-height: 120px;
                border: 1px solid rgba(42, 42, 42, 0.2);
                @media all and (min-width: $screen-sm) {
                  max-height: 185px;
                }
              }
            }
            &.gfield_error .ginput_container label {
              color: $error-light-bg;
            }
          }
        }
      }
      .gform_footer {
        @include gform-footer($padding-top: 40px);
        text-align: center;
        input.gform_button.button {
          @include button-tilt-dark($border-size: 1px);
          padding: 16px 100px;
          font-size: 16px;
          width: 100%;
          box-shadow: 0px 2px 6px $grey-4;
          margin: 0 auto;
          @media all and (min-width: $screen-sm) {
            padding: 16px 100px;
            width: fit-content;
          }
        }
      }

      .gform_confirmation_message_4 {
        p {
          color: rgba(42, 42, 42, 0.68);
          font-weight: 600;
          font-size: 36px;
          line-height: 49px;
          margin-bottom: 42px;
        }

        .uk-flex {
          gap: 100px;
          margin-bottom: 44px;
        }

        .twitter {
          width: 50px;
        }

        .linkedin {
          width: 43px;
        }
      }
    }

    .input-fields {
      gap: 20px;
      margin-bottom: 20px;
    }
  }
}
