@mixin crossover-button() {
  text-align: center;
  text-transform: uppercase;
  line-height: 22px;
  display: inline-block;
  border-radius: 4px;
  padding: 16px 10px;
  width: fit-content;
  cursor: pointer;
  font-weight: 700;
  // For older versions of Safari
  -webkit-appearance: unset;
  font-size: 12px;
  @media all and (min-width: $screen-sm) {
    font-size: 16px;
  }
  &:hover {
    text-decoration: none;
  }
}

@mixin button-tilt-dark($border-size: 2px) {
  @include crossover-button();
  color: $lightblue;
  background-color: $bg-dark;
  border: $border-size solid $lightblue;
  &:hover {
    background-color: $grey-3;
  }
}

@mixin button-tilt-white($border-size: 2px) {
  @include crossover-button();
  color: $lightblue;
  background-color: $white;
  border: $border-size solid $lightblue;
  &:hover {
    background-color: $grey-3;
  }
}

@mixin button-black-white($border-size: 2px) {
  @include crossover-button();
  color: $black;
  background-color: $white;
  border: $border-size solid $black;
  &:hover {
    background: $gradient-1;
  }
}
