.cross-videos-page {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 16px;
  @media all and (min-width: $screen-lg) {
    padding: 0;
  }

  h1 {
    margin: 80px 0 47px;
  }

  h2 {
    margin: 0;
  }

  a {
    color: $black;

    &:hover {
      color: $link-hover-dark;
      text-decoration: none;
    }
  }

  ////////////////////////////////////// Button
  #button {
    display: inline-block;
    background-color: $grey-2;
    color: $lightblue;
    width: 100px;
    height: 100px;
    text-align: center;
    border-radius: 50%;
    position: fixed;
    bottom: 30px;
    right: 34px;
    transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    border: 1px solid $lightblue;
    text-transform: uppercase;

    img {
      height: 25px;
      width: 15px;
      margin-top: 12px;
      margin-bottom: 6px;
    }
  }

  #button.show {
    opacity: 1;
    visibility: visible;
  }

  /* Styles for the content section */

  .content {
    width: 77%;
    margin: 50px auto;
    font-family: "Merriweather", serif;
    font-size: 17px;
    color: #6c767a;
    line-height: 1.9;
  }
  @media (min-width: 500px) {
    .content {
      width: 43%;
    }
    #button {
      margin: 30px;
    }
  }
  .content h1 {
    margin-bottom: -10px;
    color: #03a9f4;
    line-height: 1.5;
  }
  .content h3 {
    font-style: italic;
    color: #96a2a7;
  }
  ////////////////////////////// Button Ends

  .place {
    display: block;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 46px;

    p {
      display: inline-block;
    }
  }

  .category-list-container {
    display: none;

    @media all and (min-width: $screen-smd) {
      display: unset;
      margin-bottom: 50px;
    }

    .category-list {
      gap: 57px;
      list-style: none;
      margin: 0;
      margin-bottom: 50px;

      li {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0;
        padding-bottom: 0;
        &::before {
          content: "";
          display: none;
        }
      }

      .current {
        padding-bottom: 3px;
        border-bottom: 2px solid $black;
        font-weight: 700;
      }
    }
  }

  .dropdown-category-list-container {
    margin-bottom: 57px;
    .dropdown-category-list p {
      margin: 0;
    }
  }

  .article-container {
    margin-bottom: 80px;

    .category-name-container {
        padding-bottom: 44px;
        h2 {
            background-color: $bg-dark;
            color: $white;
            @media all and (max-width: $screen-xxs) {
              font-size: 30px;
            }
            padding: 15px 10px 15px 10px;
            @media all and (min-width: $screen-sm) {
              padding: 40px 10px 30px 55px;
            }
        }
    }

    .vendor-description-container {
        max-width: 648px;
        padding-bottom: 44px;
        & > *:last-child {
          margin-bottom: 0;
        }
    }
  }

    .slider-container {
        margin-top: 0;
        @media all and (min-width: $screen-lg) {
            margin-left: 55px;
        }
        .uk-slider-items {
            width: 100%;
            @media all and (min-width: $screen-sm) {
              width: auto;
            }
            &.uk-grid {
                @media all and (min-width: $screen-sm) {
                margin-left: -60px;
        
                & > * {
                    padding-left: 60px;
                }
                }
            }
            .slider-article-container {
                width: 100%;
                @media all and (min-width: $screen-sm) {
                    width: auto;
                }
                .video-embed-container {
                    @media all and (min-width: $screen-sm) {
                        display: flex;
                        width: 636px;
                        height: 312px;
                    }
                    iframe {
                        width: 100%;
                        @media all and (min-width: $screen-sm) {
                            height: 100%;
                        }
                    }
                }
            }
        }
        .navigation-container {
            color: $black;
            .navigation {
                margin-top: 40px;
                @media all and (min-width: $screen-md) {
                    margin-top: 80px;
                }
                @include slider-navigation();
            }
        }
    }
}
