.case-study-page-container {
  color: $white;
  background-color: $bg-dark;

  em {
    color: $white;
  }
  .back-link-container {
    padding-top: 40px;
    .back-link {
      color: $white;
      .arrow-left {
        margin-right: 15px;
        vertical-align: middle;
      }
    }
  }
  .back-link-container,
  .info-container {
    max-width: 1320px;
    margin: 0 auto;
  }
  .page-header-container {
    padding-top: 70px;
    .case-study-heading h1 {
      margin-bottom: 40px;
      color: inherit;
    }
  }
  .top-image-container {
    position: relative;
    margin-bottom: 40px;
    @media all and (min-width: $screen-lg) {
      min-width: 976px;
    }
    img.case-study-image {
      height: 520px;
      width: 976px;
      object-fit: cover;
    }
    .image-overlay {
      line-height: 1;
      margin-top: 10px;
      @media all and (min-width: $screen-sm) {
        margin-top: 0;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.6) 100%
        );
        line-height: unset;
        position: absolute;
        top: 0;
        height: 520px;
        width: 100%;
      }
      .logo {
        margin-bottom: 10px;
        @media all and (min-width: $screen-sm) {
          margin: 42px auto auto 40px;
        }
      }
      .overlay-info {
        margin: 0 0 40px 0;
        @media all and (min-width: $screen-sm) {
          margin: 0 0 40px 51px;
        }

        .case-title h2 {
          color: $white;
          line-height: 48px;
          margin-bottom: 11px;
        }
        .location {
          font-size: 32px;
          font-weight: 600px;
        }
      }
    }
  }
  .top-text-container {
    .logo {
      margin-bottom: 16px;
    }
    .case-title h2 {
      color: $white;
      margin-bottom: 11px;
    }
    .location {
      font-size: 32px;
      font-weight: 600px;
      margin-bottom: 105px;
    }
  }
  .info-container {
    ul li {
      padding-right: 10px;
    }
    .top-list {
      margin: 0 auto 25px;
      @media all and (min-width: $screen-sm) {
        margin-bottom: 50px;
      }
    }
    .heading {
      font-size: 24px;
      margin-bottom: 50px;
      @media all and (min-width: $screen-sm) {
        font-size: 48px;
        margin-bottom: 0;
        font-weight: 800;
      }
      &.col-heading {
        @media all and (min-width: $screen-sm) {
          width: 400px;
        }
      }
    }
    .col-text {
      margin-bottom: 40px;
      @media all and (min-width: $screen-sm) {
        margin-bottom: 80px;
      }
      & > * {
        color: $white;
        &:last-child {
          margin-bottom: 0;
        }
      } 
    }
    .outcomes-container {
      @media all and (min-width: $screen-sm) {
        margin-bottom: 20px;
      }
      .outcomes-heading {
        font-weight: 800;
        font-size: 24px;
        margin-bottom: 50px;
        @media all and (min-width: $screen-sm) {
          font-size: 48px;
          margin-bottom: 50px;
        }
      }
    }
    .case-study-list {
      .list-item {
        .list-heading h3 {
          color: $white;
          margin-bottom: 20px;
        }
      }
    }
  }
  .case-studies-slider {
    padding-top: 60px;
    padding-bottom: 60px;
    @media all and (min-width: $screen-md) {
      padding-top: 80px;
      padding-bottom: 67px;
    }
  }
}
