.cross-about-page-container {
  padding: 60px 0 0;
  @media all and (min-width: $screen-md) {
    padding: 100px 0 0;
  }

  .uk-container {
    max-width: 1300px;
  }

  h1.page-title {
    margin: 0 0 60px;
  }

  .about-main-content-container {
    h2 {
      font-weight: 600;
      font-size: 32px;
      line-height: 44px;
    }

    p {
      font-size: 24px;
      line-height: 33px;
    }
  }

  .about-team-container {
    padding: 60px 0;
    @media all and (min-width: $screen-md) {
      padding: 120px 0;
    }

    h2.team-header {
      font-weight: 800;
      margin: 0 0 60px;
      @media all and (min-width: $screen-md) {
        margin: 0 0 65px;
      }
    }

    .team-member-card {
      padding: 60px 32px;
      text-align: center;
      background: $white;
      display: block;
      box-shadow: -6px 6px 30px rgba(0, 0, 0, 0.17);
      border-radius: 4px;
      transition: all 0.2s linear;
      @media all and (min-width: $screen-md) {
        padding: 60px 32px 84px;
      }

      &:active,
      &:focus,
      &:hover {
        text-decoration: none;
        box-shadow: -6px 6px 30px rgba(0, 0, 0, 0.37);
      }

      img {
        max-height: 218px;
        max-width: 185px;
        width: 100%;
        object-fit: cover;
        margin: 0 0 40px;
      }

      .name {
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        margin: 0 0 14px;
      }

      .title {
        font-family: $nunitoSans;
        background: $bg-dark;
        padding: 4px;
        font-size: 18px;
        margin: 0 0 18px;
        color: $white;
        line-height: 25px;
      }

      .location {
        font-size: 16px;
        line-height: 22px;
        color: #181f23;
        margin: 0;
      }
    }
  }

  .about-career-container {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 60px 0;
    position: relative;
    @media all and (min-width: $screen-md) {
      padding: 120px 0 107px;
    }

    .uk-container {
      max-width: 1120px;
    }

    .background-gradient {
      background: linear-gradient(0deg, #181f23 0%, rgba(24, 31, 35, 0) 100%);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }

    h2 {
      font-weight: 800;
      font-size: 48px;
      line-height: 65px;
      text-transform: uppercase;
      text-align: center;
      color: #ffffff;
      margin: 0 0 60px;
      @media all and (min-width: $screen-md) {
        margin: 0 0 65px;
      }
    }

    p {
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      color: #fefefe;
      margin: 0 0 43px;
      @media all and (min-width: $screen-md) {
        font-size: 32px;
        line-height: 44px;
      }
    }

    a {
      @include button-tilt-dark();
      box-sizing: border-box;
      text-align: center;
      margin: 60px auto 0;
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      display: block;
      max-width: 576px;
      width: 100%;
      @media all and (min-width: $screen-md) {
        margin: 86px auto 0;
        font-size: 36px;
        line-height: 49px;
      }
    }
  }
}

// Modals display outside the parent container
.team-member-modal {
  width: 100%;
  max-width: 375px !important;
  padding: 40px 20px 30px;
  @media all and (min-width: $screen-md) {
    padding: 80px 60px 50px;
    max-width: 995px !important;
  }

  button {
    appearance: none;
    border: none;
    background: transparent;
    font-size: 23px;
    right: 20px;
    position: absolute;
    top: 15px;
    color: rgba(24, 31, 35, 0.68);
    @media all and (min-width: $screen-md) {
      font-size: 44px;
      right: 33px;
      top: 24px;
    }

    &:active,
    &:focus,
    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }

  .name {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #181f23;
    margin: 0 0 10px;
    text-align: center;
    @media all and (min-width: $screen-md) {
      text-align: left;
      font-weight: 800;
      font-size: 48px;
      line-height: 65px;
    }
  }

  .title {
    color: #fefefe;
    background: #2a2a2a;
    padding: 2px 10px;
    margin: 0 40px 20px;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    @media all and (min-width: $screen-md) {
      text-align: left;
      font-weight: 600;
      font-size: 32px;
      line-height: 44px;
      margin: 0 0 30px;
    }
  }

  .bio {
    p {
      font-size: 18px;
      line-height: 25px;
      margin: 0 0 30px;
    }
  }
}
