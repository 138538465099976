.case-studies-slider {
  background-color: $bg-dark;
  padding-right: 16px;
  padding-left: 16px;
  @media all and (min-width: $screen-md) {
    padding-left: 55px;
    padding-right: 0;
  }
  .case-studies-slider-container {
    color: $white;
    .case-studies-heading h2 {
      color: inherit;
      margin-bottom: 50px;
      @media all and (min-width: $screen-sm) {
        margin-bottom: 50px;
      }
    }
    .uk-grid {
      @media all and (min-width: $screen-sm) {
        margin-left: -81px;
        & > * {
          padding-left: 81px;
        }
      }
    }
    .case-study-container {
      font-size: 14px;
      border-left: 1px solid $lightblue;
      border-bottom: 1px solid $lightblue;
      margin-bottom: 44px;
      padding: 0 10px 30px 30px;
      @media all and (min-width: $screen-sm) {
        padding: 0 2px 20px 20px;
        max-width: 625px;
      }
      .image-container {
        position: relative;
        margin-bottom: 24px;
        img {
          max-height: 299px;
          width: 100%;
        }
        .image-overlay {
          padding-top: 5px;
          @media all and (min-width: $screen-sm) {
            padding: 0 0 20px 20px;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.85) 100%);
            position: absolute;
            bottom: 0;
            left: 0;
            box-sizing: border-box;
            height: 100%;
            width: 100%;
          }
          .logo {
            margin-bottom: 10px;
            @media all and (min-width: $screen-sm) {
              margin-bottom: 64px;
            }
          }
          .info-container {
            .case-title {
              margin-bottom: 10px;
              font-size: 26px;
              @media all and (min-width: $screen-sm) {
                font-size: 36px;
                line-height: 44px;
                font-weight: 600;
              }
            }
            .location {
              font-size: 20px;
              font-weight: 500;
            }
          }
        }
      }
      .text-container {
        margin-bottom: 40px;
      }
      .button-container {
        .crossover-button {
          @include button-tilt-dark();
          padding: 16px 20px;
          @media all and (min-width: $screen-sm) {
            width: fit-content;
          }
        }
      }
    }
    .navigation-container {
      color: $lightblue;
      .navigation {
        @include slider-navigation();
      } 
    }
  }
}