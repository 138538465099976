.cross-news-single-page {
  color: $black;
  max-width: 1142px;
  margin: 0 auto 120px;
  padding: 0 16px;

  .back-to-news {
    margin: 70px 0;

    p {
      margin: 0;
    }

    a {
      color: $black;
      .arrow-left {
        margin-right: 15px;
      }
      &:hover {
        color: $grey-2;
        text-decoration: none;
      }
    }
  }

  h1 {
    font-weight: 800;
    line-height: 44px;
    margin: 0 0 40px;

    @media all and (min-width: $screen-sm) {
      line-height: 87px;
    }
  }

  .article-container {
    margin-bottom: 100px;

    .date-category {
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      margin-bottom: 35px;

      .date {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        margin: 0;
      }

      .category {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin: 0;
      }
    }

    .image-container {
      // max-width: 1140px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;

      img {
        object-fit: cover;
        display: block;
        margin: 0 auto 36px;
        width: 285px;
        height: 177px;

        @media all and (min-width: $screen-xxs) {
          width: 325px;
          height: 202px;
        }
        @media all and (min-width: $screen-xs) {
          width: 468px;
          height: 291px;
        }
        @media all and (min-width: $screen-sm) {
          width: 608px;
          height: 378px;
        }
        @media all and (min-width: $screen-smd) {
          width: 736px;
          height: 274px;
        }
        @media all and (min-width: $screen-md) {
          width: 928px;
          height: 418px;
        }
        @media all and (min-width: $screen-mdl) {
          width: 960px;
          height: 433px;
        }
        @media all and (min-width: $screen-lg) {
          width: 1140px;
          height: 510px;
        }
      }
    }

    .article-text-container {
      max-width: 785px;
      margin: 0 auto;
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        max-width: 785px;
        margin: 0 auto 40px;
      }
    }
  }

  .is-layout-flex {
    display: flex;
    justify-content: center;

    .wp-block-button {
      margin-top: 10px;
    }

    .wp-block-button__link {
      color: $lightblue;
      background-color: black;
      font-weight: 700;
      line-height: 22px;
      text-transform: uppercase;
      padding: 20px 66px;
      border: 2px solid $lightblue;
      box-shadow: 0px 2px 6px rgba(42, 42, 42, 0.18);
      border-radius: 4px;

      &:hover {
        background-color: $grey-3;
        text-decoration: none;
      }
    }
  }

  .bottom-articles-container {
    .about-crossover {
      margin-bottom: 40px;

      a {
        color: $black;
      }
    }

    .about-crossover,
    .extra-article {
      margin-bottom: 40px;

      @media all and (min-width: $screen-mdl) {
        flex-direction: row;
        align-items: flex-start;
        gap: 174px;
      }

      img {
        object-fit: contain;
        width: 356px;
        height: 88px;
        margin-bottom: 20px;
        width: 356px;
        height: 88px;
      }

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        max-width: 612px;
        margin: 0 0 10px;
      }
    }
  }
}
