.cross-project-examples-page {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 16px;
  @media all and (min-width: $screen-lg) {
    padding: 0;
  }

  h1 {
    margin: 80px 0 47px;
  }

  h2,
  p {
    margin: 0;
  }

  a {
    color: $black;

    &:hover {
      color: $link-hover-dark;
      text-decoration: none;
    }
  }

  ////////////////////////////////////// Button
  #button {
    display: inline-block;
    background-color: $grey-2;
    color: $lightblue;
    width: 100px;
    height: 100px;
    text-align: center;
    border-radius: 50%;
    position: fixed;
    bottom: 30px;
    right: 34px;
    transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    border: 1px solid $lightblue;
    text-transform: uppercase;

    img {
      height: 25px;
      width: 15px;
      margin-top: 12px;
      margin-bottom: 6px;
    }
  }

  #button.show {
    opacity: 1;
    visibility: visible;
  }

  /* Styles for the content section */

  .content {
    width: 77%;
    margin: 50px auto;
    font-family: "Merriweather", serif;
    font-size: 17px;
    color: #6c767a;
    line-height: 1.9;
  }
  @media (min-width: 500px) {
    .content {
      width: 43%;
    }
    #button {
      margin: 30px;
    }
  }
  .content h1 {
    margin-bottom: -10px;
    color: #03a9f4;
    line-height: 1.5;
  }
  .content h3 {
    font-style: italic;
    color: #96a2a7;
  }
  ////////////////////////////// Button Ends

  .place {
    display: block;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 46px;

    p {
      display: inline-block;
    }
  }

  .category-list-container {
    display: none;

    @media all and (min-width: $screen-smd) {
      display: unset;
      margin-bottom: 50px;
    }

    .category-list {
      gap: 57px;
      list-style: none;
      margin: 0;
      padding: 0;
      margin-bottom: 50px;

      li {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0;
        padding-bottom: 0;
        &::before {
          content: "";
          display: none;
        }
      }

      .current {
        padding-bottom: 3px;
        border-bottom: 2px solid $black;
        font-weight: 700;
      }
    }
  }

  .dropdown-category-list-container {
    margin-bottom: 57px;
  }

  .article-container {
    margin-bottom: 80px;

    .category-name-container h2 {
      background-color: $bg-dark;
      color: $white;
      @media all and (max-width: $screen-xxs) {
        font-size: 30px;
      }
      padding: 15px 10px 15px 10px;
      @media all and (min-width: $screen-sm) {
        padding: 40px 10px 30px 55px;
      }
    }

    .top-article {
      margin: 0 auto 80px;
      max-width: 636px;

      @media all and (min-width: $screen-lg) {
        display: grid;
        grid-column: 1/2;
        grid-row: 1/2;
        padding: 0 0 0 49px;
        // margin: 0;
        max-width: unset;
        align-items: unset;
      }

      .top-article-image {
        background-color: $white;
        position: relative;
        margin-bottom: 30px;
        max-width: 602px;

        @media all and (min-width: $screen-lg) {
          grid-column: 2/3;
          grid-row: 1/2;
          top: -130px;
          padding: 27px 30px 0;
        }

        img {
          @media all and (min-width: $screen-lg) {
            margin: 0 auto;
            display: block;
            min-height: 518px;
            min-width: 602px;
            object-fit: cover;
          }
        }
      }

      h3 {
        font-weight: 600;
        line-height: 49px;
        margin-bottom: 14px;
      }

      .top-article-details {
        max-width: 635px;

        .top-article-description {
          font-weight: 400;
          font-size: 18px;
          line-height: 25px;
          margin-bottom: 75px;
        }

        .top-article-category {
          font-weight: 700;
          font-size: 20px;
          line-height: 27px;
        }
      }
      @media all and (min-width: $screen-xl) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        // gap: 20px;
      }
    }
  }

  .slider-container {
    @media all and (min-width: $screen-lg) {
      margin-top: -150px;
      &.no-featured-project {
        margin-top: 0;
      }
    }
    .uk-slider-items.uk-grid {
      @media all and (min-width: $screen-sm) {
        margin-left: -60px;

        & > * {
          padding-left: 60px;
        }
      }
    }

    @media all and (min-width: $screen-lg) {
      margin-left: 55px;
    }

    .uk-width-auto {
      .slider-article {
        max-width: 636px;
        margin-bottom: 80px;

        .slider-article-image {
          width: 100%;
          @media all and (min-width: $screen-sm) {
            display: flex;
            height: 312px;
            width: 636px;
            img {
              margin-bottom: 0;
              width: 100%;
              object-fit: cover;
            }
          }
        }
        .slider-article-details {
          padding: 30px 20px 20px 20px;
          border-left: 1px solid $black;
          border-bottom: 1px solid $black;
          min-height: 440px;
          position: relative;

          h3 {
            margin-bottom: 14px;
          }

          .slider-place {
            font-weight: 400;
            font-size: 18px;
            line-height: 25px;
            margin-bottom: 46px;

            p {
              display: inline-block;
            }
          }

          .slider-article-description {
            font-weight: 400;
            font-size: 18px;
            line-height: 25px;
            margin-bottom: 35px;
          }

          .slider-article-category {
            font-weight: 700;
            font-size: 20px;
            line-height: 27px;
            position: absolute;
            bottom: 20px;
          }
        }
      }
    }
  }

  .navigation-container {
    color: $black;
    .navigation {
      @include slider-navigation();
    }
  }
}
