.cross-testimonials-page {
  color: $black;
  max-width: 1440px;
  margin: 0 auto 120px;
  padding: 0 16px;

  h1 {
    text-transform: uppercase;
    font-weight: 800;
    line-height: 87px;
    margin: 100px 0 90px;
  }

  p {
    font-size: 18px;
  }
  .testimonial-list-container {
    gap: 100px 93px;
    margin-bottom: 80px;
  }

  .testimonial {
    margin: 0;
    width: 611px;

    .comment-icon {
      color: $bg-dark;
      margin-bottom: 30px;
      font-size: 54px;
    }

    .testimonial-explanation {
      font-weight: 400;
      line-height: 25px;
    }

    .testimonial-quote {
      font-weight: 700;
      font-style: italic;
      margin: 0 0 70px;
    }

    hr {
      background-color: $bg-dark;
      width: 621px;
      height: 6px;
      margin: 0 0 10px;
    }

    ul {
      list-style: none;
      padding: 0;
      gap: 10px;
      margin: 0;
    }

    .testimonial-info {
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
    }
  }

  .testimonials-load-more {
    margin: 0 auto;

    .load-more-testimonials-button {
      color: $black;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      border-bottom-style: solid;

      &:hover {
        color: $link-hover-dark;
        text-decoration: none;
      }
    }
  }
}
