.dropdown-category-list-container {
  @media all and (min-width: $screen-smd) {
    display: none;
  }

  button {
    width: 210px;
  }

  .dropdown-category-list {
    top: 40px !important;
    width: 210px;

    p {
      margin: 0 0 20px;

      &:last-child {
        margin: 0;
      }
    }
  }
}
