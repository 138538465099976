.cross-contact-page-container {
  .contact-page-header-content {
    background-image: url("../../dist/images/pexels-blue-arauz.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .crossover-header-container {
      background-color: unset;
      &.default-background-color {
        background-color: $bg-dark;
      }
    }
    .page-header-container {
      padding-top: 57px;
      padding-bottom: 67px;
      @media all and (min-width: $screen-sm) {
        padding-top: 117px;
        padding-bottom: 137px;
      }
      h1 {
        color: $white;
      }
    }
  }

  .contact-page-top-content {
    background-image: url("../../dist/images/waves.png");
    background-position: 0 -60px;
    background-repeat: no-repeat;
    background-color: $black;
    margin-bottom: 20px;
    h2 {
      color: $white;
    }
    .contact-page-top-heading-container {
      height: 166px;
      @media all and (min-width: $screen-sm) {
        height: 266px;
      }
    }
    .contact-form-container {
      font-family: $nunitoSans;
      border: 2px solid $lightblue;
      border-radius: 4px;
      width: 100%;
      margin-bottom: 60px;
      @media all and (min-width: $screen-sm) {
        margin-bottom: 120px;
      }
      @media all and (min-width: $screen-md) {
        width: unset;
        min-width: 853px;
      }
      .gform_wrapper {
        background: $bg-dark;
        padding: 20px 10px;
        @media all and (min-width: $screen-sm) {
          padding: 40px 30px;
        }
        .gform_required_legend,
        .gfield_required,
        .gfield_validation_message {
          color: $error-dark-bg;
        }
        .gform_heading h2 {
          text-align: center;
          margin-bottom: 40px;
        }
        form {
          .gform_body.gform-body {
            .gform_fields {
              display: block;
              .gfield {
                @include gform-field-container();
                margin-bottom: 17px;
                .gfield_label {
                  @include gform-field-label();
                  color: $grey-2;
                }
                &.select-field {
                  .gfield_label.hidden {
                    display: none;
                  }
                }
                .ginput_container {
                  @include gform-input-fields();
                  &.ginput_container_consent {
                    color: $white;
                    @include gform-checkbox-tilt;
                    a {
                      color: $white;
                      font-weight: 700;
                    }
                  }
                  textarea {
                    height: unset;
                    max-height: 120px;
                    @media all and ( min-width: $screen-sm ) {
                      max-height: 180px;
                    }
                  }
                }
                &.dropdown-box {
                  z-index: 12;
                }
                &.your-message-box textarea {
                  display: initial;
                }
                &.gfield_error .ginput_container label {
                  color: $error-dark-bg;
                }
              }
            }
          }
          .gform_footer {
            @include gform-footer($padding-top: 20px);
            @media all and (min-width: $screen-sm) {
              $padding-top: 40px
            }
            input.gform_button.button {
              @include button-tilt-dark($border-size: 1px);
              padding: 16px 0;
              width: 100%;
              @media all and (min-width: $screen-sm) {
                padding: 16px 90px;
                width: fit-content;
              }
            }
          }
        }
      }
      .gform_confirmation_wrapper {
        background: rgba(255, 255, 255, 0.18);
        backdrop-filter: blur(10px);
        color: $white;
        padding: 25px 30px 30px 30px;
        @media all and (min-width: $screen-sm) {
          padding: 50px 60px 60px 60px;
        }
        @media all and (min-width: $screen-lg) {
          min-width: 973px;
        }
        a {
          color: inherit;
        }
        .title {
          color: inherit;
          font-weight: 600;
          font-size: 22px;
          margin-bottom: 30px;
          text-align: center;
          @media all and (min-width: $screen-sm) {
            font-weight: 800;
            font-size: 48px;
            margin-bottom: 60px;
            text-align: left;
          }
        }
        .body {
          color: inherit;
          margin-bottom: 32px;
          text-align: center;
          @media all and (min-width: $screen-sm) {
            margin-bottom: 62px;
            text-align: left;
          }
          strong {
            font-weight: 800;
          }
        }
        .social-media {
          font-size: 43px;
          a {
            &:hover {
              color: $lightblue;
            }
            &.twitter-icon {
              margin-right: 100px;
            }
          }
        }
      }
    }
  }
  .contact-page-locations {
    .locations-heading {
      height: 245px;
      h2 {
        margin-bottom: 0px;
      }
    }
    .locations-container {
      max-width: 1109px;
      padding-bottom: 20px;
      .location-row {
        margin-bottom: 40px;
        @media all and (min-width: $screen-sm) {
          margin-bottom: 80px;
        }
        i {
          margin-right: 12px;
          color: $grey-1;
        }
        h3 {
          margin-bottom: 40px;
        }
        p {
          @media all and (min-width: $screen-sm) {
            font-size: 24px;
            margin-bottom: 32px;
          }
          a {
            color: $black;
          }
        }
        .location-map-container .location-map {
          width: 100%; 
          overflow: hidden;
          iframe {
            margin-top: -60px;
            @media all and (max-width: $screen-sm) {
              width: 100%;
            }
          }
        }
      }
    }
  }

  //   Dropdown
  .chosen-single {
    color: $black !important;
    margin: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    border: none !important;
    background: none !important;
    box-shadow: none !important;

    b {
      display: none !important;
    }
  }

  .chosen-drop {
    margin-top: 2px !important;
    border-radius: 4px !important;

    .chosen-search {
      padding: 0 !important;
    }
    .active-result {
      color: $grey-2;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      padding: 10px 0 10px 13px !important;
      border-bottom: 1px solid rgba(42, 42, 42, 0.2);

      &.gf_placeholder {
        display: none !important;
      }
      &:first-child,
      &:last-child {
        border: none;
      }

      &:first-child {
        color: $black;
      }
    }
  }

  .delete-dropdown,
  .chosen-search-input {
    display: none;
  }
}
