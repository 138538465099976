.cross-news-page {
  color: $black;
  max-width: 1330px;
  margin: 0 auto 120px;
  padding: 0 16px;

  a {
    color: $black;

    &:hover {
      color: $link-hover-dark;
      text-decoration: none;
    }
  }

  h1 {
    margin: 100px auto 40px;
    font-weight: 800;
    line-height: 87px;
  }

  // This is for H1 on the Case Study Page ---
  .case-study-heading {
    margin: 80px 0 90px;
    line-height: unset;

    @media all and (min-width: $screen-sm) {
      margin-bottom: 100px;
    }
  }
  //---------------

  .page-header {
    label {
      display: none;
    }

    .input-field-container {
      border-bottom: 1px solid #d7d7d7;
      max-width: 500px;
      margin: 0 auto 50px;

      input {
        color: $black;
        height: 33px;
        font-weight: 400;
        font-size: 24px;
        line-height: 33px;
        padding-left: 10px;
        border: none;
        width: 90%;

        &:focus,
        &:active {
          outline: 1px solid $link-hover-light;
          border-radius: 4px;
        }
      }

      i {
        padding-right: 10px;
      }

      .news-search {
        display: inline-block;
        width: 40px;
        font-size: 0;
        opacity: 0;
        margin-left: -30px;
        padding: 0;

        &:hover,
        &:focus,
        &:active {
          cursor: pointer;
        }
      }
    }

    .category-list-container {
      display: none;

      @media all and (min-width: $screen-smd) {
        display: unset;
      }

      .category-list {
        flex-wrap: wrap;
        list-style: none;
        gap: 50px;
        padding: 0;
        margin-bottom: 114px;

        .category-all,
        .category {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          padding: 0 30px 8px;
          margin-bottom: 0;
          &::before {
            content: "";
            display: none;
          }
        }

        .current {
          padding-bottom: 3px;
          border-bottom: 2px solid $black;
        }
      }
    }

    .dropdown-category-list-container {
      margin-bottom: 114px;
    }
  }

  .news-article-container {
    .single-article {
      padding-bottom: 30px;
      margin-bottom: 70px;
      border-bottom: 2px solid #d7d7d7;
      gap: 33px;
      @media all and (min-width: $screen-md) {
        gap: 67px;
      }

      @media all and (min-width: $screen-mdl) {
        flex-direction: row;
      }

      &:last-child {
        margin-bottom: 80px;
      }

      h3 {
        font-weight: 700;
        font-size: 24px;
        line-height: 33px;
        margin-bottom: 17px;

        &:hover {
          color: $link-hover-dark;
        }
      }

      .image-container {
        @media all and (min-width: $screen-md) {
          min-width: 499px;
        }

        img {
          margin: 0 auto;
          object-fit: cover;
          width: 498px;
          height: 310px;
          @media all and (min-width: $screen-md) {
            min-height: 310px;
          }
        }
      }

      .date-category {
        margin-bottom: 25px;

        p {
          margin: 0;
        }

        .date {
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
        }

        .category {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
        }
      }
      .case-study-location {
        margin-bottom: 23px;
      }

      .excerpt {
        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 40px;
        }

        a {
          font-weight: 700;
          font-size: 16px;
          line-height: 22px;
          .arrow-right {
            margin-left: 16px;
          }
        }
      }
    }

    .zero-results {
      color: rgba(42, 42, 42, 0.68);
      font-weight: 400;
      font-size: 24px;
      line-height: 33px;
      text-align: center;
      margin: -14px 0 0;
    }
  }

  .news-load-more {
    margin-bottom: 16px;
    .load-more-button {
      color: $lightblue;
      background-color: black;
      font-weight: 700;
      line-height: 22px;
      padding: 16px;
      border: 2px solid $lightblue;
      box-shadow: 0px 2px 6px rgba(42, 42, 42, 0.18);
      border-radius: 4px;

      &:hover {
        background-color: $link-hover-dark;
        text-decoration: none;
      }
    }
  }
}
