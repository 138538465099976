.crossover-header-container {
  background-color: $bg-dark;

  .uk-container {
    max-width: 1440px;
    padding-left: 20px;
    padding-right: 30px;
    @media (min-width: $screen-lg) {
      padding-left: 10px;
      padding-right: 10px;
    }
    @media (min-width: $screen-xl) {
      padding-left: 50px;
      padding-right: 50px;
    }
  }

  .header-items {
    padding: 24px 0;
    @media all and (min-width: $screen-lg) {
      padding: 30px 0;
    }
    .uk-icon {
      color: $white;
    }
    // Hide the mobile logo and show it once the toggle is triggered
    .mobile-items.logo-container {
      display: none;
      &.mobile-header-toggle {
        display: flex;
      }
    }
    .uk-navbar-toggle {
      min-height: 30px;
      padding: unset;
      svg {
        width: 30px;
      }
    }
    .nav-primary {
      .primary-menu {
        li {
          padding: unset;
          margin-bottom: 0;
          a {
            font-family: $nunitoSans;
            color: $white;
            font-weight: 700;
            min-height: unset;
            &.main-menu-link {
              text-transform: uppercase;
              text-align: center;
              padding: 16px 14px 11px;
            }
          }
          &.current_page_item > a,
          a:hover {
            color: $lightblue;
          }
          &.menu-item-has-children {
            a.main-menu-link::after {
              font-family: $fa;
              content: "\f078";
              border: none;
              margin-left: 5px;
            }
            .uk-navbar-dropdown.uk-open {
              position: absolute;
              background-color: $grey-3;
              padding: 0;
              min-width: 200px;
              ul li {
                padding: 11px 0 11px 20px;
                margin-bottom: 0;
              }
            }
          }
          &::before {
            content: "";
        		display: none;
          }
        }
      }
    }
  }
  .mobile-navbar-container {
    .mobile-navbar {
      display: none;
      width: 100%;
      ul {
        margin-bottom: 11px;
        li {
          font-size: 16px;
          padding-bottom: 0;
          margin-bottom: 0;
          a {
            font-family: $nunitoSans;
            color: $white;
            font-weight: 700;
            min-height: unset;
            &.main-menu-link {
              text-transform: uppercase;
              padding: 18px 0 18px 20px;
            }
          }
          &.current_page_item > a,
          a:hover {
            color: $lightblue;
          }
          &.menu-item-has-children {
            a.main-menu-link::after {
              font-family: $fa;
              content: "\f078";
              border: none;
              margin-left: 5px;
            }
            &.uk-open {
              a.main-menu-link::after {
                transform: rotate(180deg);
              }
              .sub-menu {
                margin-left: 18px;
                background-color: $grey-3;
                padding: 0;
                min-width: 200px;
                li a {
                  padding: 11px 0 11px 20px;
                }
              }
            }
          }
          &::before {
            content: "";
        		display: none;
          }
        }
      }
      .mobile-header-social-media {
        margin-top: 39px;
        margin-left: 20px;
        margin-bottom: 30px;
        font-size: 30px;
        a {
          color: $white;
          &:hover {
            color: $lightblue;
          }
        }
      }
      &.mobile-header-toggle {
        display: block;
      }
    }
  }
  .button-container {
    @media all and (min-width: $screen-sm) {
      flex: unset;
    }
    a {
      @include button-tilt-dark($border-size: 2px);
      padding: 11px 16px;
      line-height: 16px;
      @media all and (min-width: $screen-sm) {
        padding: 14px 20px;
        font-size: 16px;
        line-height: 21px;
      }
    }
    &.mobile-header-toggle {
      // Once a toggle is triggered, hide on smaller screens
      @media all and (max-width: $screen-sm) {
        display: none;
      }
    }
  }
}

// Mobile Dark Header when clicking the Hamburger icon
.mobile-dark-header {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 132;
  overflow: scroll;
  height: 100%;
}
