.page-header-container {
    padding-top: 50px;
    padding-bottom: 30px;
    @media all and (min-width: $screen-sm) {
        padding-top: 100px;
        padding-bottom: 60px;
    }
    h1 {
        font-weight: 800;
        margin-bottom: unset;
    }
}