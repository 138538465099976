body,
h1,
h2,
h3,
p,
input,
textarea,
em {
  font-family: $nunitoSans;
  color: $black;
}
h1 {
  font-size: 36px;
  font-weight: 800;
  @media all and (min-width: $screen-sm) {
    font-size: 64px;
  }
}
h2 {
  font-size: 36px;
  font-weight: 800;
  @media all and (min-width: $screen-sm) {
    font-size: 48px;
  }
}
h3 {
  font-size: 20px;
  font-weight: 600;
  @media all and (min-width: $screen-sm) {
    font-size: 36px;
  }
}
i {
  font-family: $fa;
}

ul {
  padding-left: 36px;
  li {
    list-style: none;
    padding-bottom: 8.5px;
    margin-bottom: 10px;
    &::before {
      font-family: $fa;
      content: "\e404";
      font-size: 16px;
      margin-left: -35px;
      padding-right: 20px;
    }
    &>ul {
      padding-left: 26px;
      li {
        padding: 8.5px 10px 8.5px 0px;
        &::before {
          vertical-align: middle;
          font-size: 8px;
          padding-right: 8px;
          margin-left: -15px;
          padding-right: 8px;
        }
      }
    }
  }
}
ol {
  padding-left: 36px;
  li {
    margin-bottom: 12px;
  }
}

.uk-container {
  padding-left: 16px;
  padding-right: 16px;
  @media (min-width: 960px) {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.uk-accordion, 
.uk-slider-items,
.uk-dotnav {
  li::before {
   content: "";
   display: none;
 }
} 