.newsletter-form-section {
  background-image: url("../../dist/images/newsletter-background.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 90px;
  padding-bottom: 80px;
  @media all and (min-width: $screen-md) {
    padding-top: 116px;
    padding-bottom: 62px;
    width: calc(100% + 2px);
    position: relative;
    left: -2px;
  }
  .newsletter-header {
    h2 {
      color: $white;
      margin-bottom: 70px;
      @media all and (min-width: $screen-sm) {
        margin-bottom: 60px;
      }
    }
  }

  .newsletter-form-container {
    max-width: 732px;
    .gform_wrapper {
      .gform_required_legend,
      .gfield_required,
      .gfield_validation_message {
        color: $error-dark-bg;
      }
      form {
        position: relative;
        .gform_body.gform-body {
          .gform_fields {
            display: block;
            .gfield {
              @include gform-field-container();
              .gfield_label {
                @include gform-field-label();
              }
              .ginput_container {
                @include gform-input-fields();
                &.ginput_container_email {
                  //margin-right: 110px;
                  @media all and (min-width: $screen-sm) {
                    margin-right: 242px;
                  }
                  input {
                    border-radius: 4px 0 0 4px;
                    &:focus {
                      border-right: unset;
                    }
                  }
                }
                &.ginput_container {
                  color: $white;
                  &.ginput_container_consent {
                    @include gform-checkbox-white;
                    a {
                      color: $white;
                      font-weight: 800;
                    }
                  }
                }
              }
              &.gfield_error .ginput_container label {
                color: $error-dark-bg;
              }
            }
          }
        }

        .gform_footer {
          @include gform-footer($padding-top: 0px);

          @media all and (min-width: $screen-sm) {
            position: absolute;
            right: 0;
            top: 0;
          }

          input.gform_button.button {
            @include button-tilt-dark($border-size: 1px);
            font-size: 16px;
            border-radius: 0 4px 4px 0;
            padding: 20px 10px;
            width: 100%;

            @media all and (min-width: $screen-sm) {
              padding: 20px 76px;
              width: fit-content;
            }
          }
        }
      }
    }

    .gform_confirmation_wrapper {
      .gform_confirmation_message {
        text-align: center;
        color: $white;
        @media all and (min-width: $screen-md) {
          padding-bottom: 44px;
        }
        a,
        p {
          color: inherit;
        }
        .title {
          font-weight: 800;
          margin-bottom: 30px;
          font-size: 32px;
        }
        .body {
          font-size: 18px;
          margin-bottom: 42px;
        }
        .social-media {
          font-size: 35px;
          a {
            &:hover {
              color: $lightblue;
            }
            &.twitter-icon {
              margin-right: 100px;
            }
          }
        }
      }
    }
  }
}
