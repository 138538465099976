$transition_duration: 600ms;

.ham {
  cursor: pointer;
  transition: transform $transition_duration;
  user-select: none;
  height: 40px;
  width: 40px;

  &.active {
    transform: rotate( 45deg );

    .top {
      stroke-dashoffset: -64px;
    }
    
    .middle {
      transform: rotate( 90deg );
    }

    .bottom {
      stroke-dashoffset: -64px;
    }
  }

  .top {
    stroke-dasharray: 40 160;
  }

  .middle {
    stroke-dasharray: 40 142;
    transform-origin: 50%;
    transition: transform $transition_duration;
  }

  .bottom {
    stroke-dasharray: 40 85;
    transform-origin: 50%;
    transition: transform $transition_duration, stroke-dashoffset $transition_duration;
  }
}

.hamRotate180.active {
  transform: rotate( 180deg );
}

.line {
  fill: none;
  transition: stroke-dasharray $transition_duration, stroke-dashoffset $transition_duration;
  stroke: $white;
  stroke-width: 5.5;
  stroke-linecap: round;
}