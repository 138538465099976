.cross-default-page {
  max-width: 1440px;
  margin: auto auto 130px;
  padding: 0 16px;
  color: $black;

  h1 {
    height: 87px;
    top: 174px;
    font-weight: 800;
    line-height: 87px;
    margin: 60px 0;
  }

  h3 {
    font-weight: 600;
    line-height: 49px;
    color: #181f23;
    margin: 40px 0 8px 10px;
  }

  h4 {
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    margin: 40px 0 8px 30px;
  }

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 30px;
  }

  hr {
    border: 4px solid black;
    margin: 0 0 30px;
    max-block-size: 32px;
  }
}
