.cross-resources-page {
  max-width: 1330px;
  margin: 0 auto;
  padding: 0 16px;
  color: $black;

  h1 {
    margin: 60px 0 80px;
    line-height: 87px;
  }

  h2 {
    margin: 0 0 5px;
    text-transform: uppercase;
    line-height: 65px;
    padding-left: 10px;
  }

  p {
    margin: 0;
  }

  a {
    color: $black;
    text-decoration: none;
  }

  hr {
    border: 4px solid $black;
    margin: 0;
  }

  .card {
    background: #fefefe;
    box-shadow: -6px 6px 30px rgba(0, 0, 0, 0.17);
    padding: 40px 50px 30px 30px;
  }

  .border-bottom {
    border-bottom: 1px solid $grey-4;
  }

  .single-link {
    padding: 22px 30px 22px 0;
  }

  .links-title {
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    margin-bottom: 10px;
  }

  .single-link-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 0;
  }

  .link-icon {
    color: rgba(42, 42, 42, 0.68);
    font-weight: 400;
    font-size: 20.0729px;
    line-height: 20px;
  }

  .tech-support-container {
    //gap: 82px;
    margin-bottom: 100px;

    /* @media all and (min-width: $screen-mdl) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 82px;
    } */

    .tech-support-forms {
      gap: 20px;
      margin-bottom: 25px;
      @media all and (min-width: $screen-sm) {
        margin-bottom: 50px;
      }
    }

    hr {
      margin: 0 0 42.5px;
    }
  }

  ul {
    margin-bottom: 50px;
  }

  li {
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    list-style: none;
    padding-left: 22px;
  }

  li:before {
    content: "\2014";
    position: absolute;
    margin-left: -40px;
  }

  .crossover-button {
    @include button-tilt-dark();
    box-shadow: 0px 2px 6px rgba(42, 42, 42, 0.18);
    padding: 16px 66px;
    text-transform: uppercase;
  }

  img {
    margin: 0 auto;
  }

  .forms-container {
    margin-bottom: 100px;

    .form-title-description {
      margin-bottom: 40px;

      @media all and (min-width: $screen-mdl) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 80px;
        grid-column: 1/2;
        grid-row: 1/2;
      }

      hr {
        margin-bottom: 32px;
      }

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
      }
    }

    .forms {
      gap: 20px 80px;

      @media all and (min-width: $screen-mdl) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column: 1/3;
      }
    }
  }

  .single-form {
    background: #fefefe;
    padding: 30px;
    gap: 36px;
  }

  .pdf-form {
    img {
      margin-right: 22px;
    }
    p {
      margin: 0;
    }
  }

  .links-container {
    margin-bottom: 0;
    @media all and (min-width: $screen-smd) {
      margin-bottom: 120px;
    }

    .link-title {
      margin-bottom: 62px;

      @media all and (min-width: $screen-mdl) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 80px;
        grid-column: 1/2;
        grid-row: 1/2;
      }
    }

    .all-links {
      @media all and (min-width: $screen-mdl) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column: 1/3;
        gap: 0 80px;
      }

      .bell-container {
        margin-bottom: 40px;
        @media all and (min-width: $screen-mdl) {
          grid-column: 1/2;
          grid-row: 1/2;
        }
      }

      .cellular-networks-container {
        margin-bottom: 40px;
        @media all and (min-width: $screen-mdl) {
          grid-column: 1/2;
          grid-row: 2/3;
        }
      }

      .wireless-broadband-container {
        margin-bottom: 40px;
        @media all and (min-width: $screen-mdl) {
          grid-column: 1/2;
          grid-row: 3/4;
        }
      }

      .tool-container {
        padding-bottom: 40px;
        @media all and (min-width: $screen-mdl) {
          grid-column: 2/3;
          grid-row: 1/4;
        }

        .tool-links {
          .tools-description {
            color: $grey-1;
            padding: 0px 34px 10px 0;
            margin: 0;
            font-weight: 400;
            font-size: 18px;
            line-height: 25px;
          }

          .tool-single-link {
            padding: 19px 30px 19px 0;
            margin-bottom: 11px;

            .links-title {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}
