// Palette
$bg-dark: #2a2a2a;
$white: #fefefe;
$grey-1: rgba(24, 31, 35, 0.68);
$grey-4: rgba(42, 42, 42, 0.2);
$link-hover-dark: rgba(24, 31, 35, 0.68);
$black: #181f23;
$lightblue: #80fff3;
$grey-2: rgba(24, 31, 35, 0.68);
$grey-3: #494949;
$gradient-1: linear-gradient(
  180deg,
  rgba(255, 255, 255, 0.22),
  rgba(0, 0, 0, 0.09),
  rgba(43, 215, 193, 0.34)
);
$error-light-bg: #e02424;

$list-hover: rgba(254, 254, 254, 0.1);
$link-hover-light: rgba(254, 254, 254, 0.38);
$card-bg: rgba(255, 255, 255, 0.18);
$error-dark-bg: #ff5757;

// Screen size
$screen-xxs: 360px;
$screen-xs: 500px;
$screen-sm: 640px;
$screen-smd: 768px;
$screen-md: 960px;
$screen-mdl: 992px;
$screen-lg: 1200px;
$screen-xl: 1480px;

// Font Family
$fa: "Font Awesome 6 Pro";

@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&display=block");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap");

$theme-font-path: "../../assets/fonts/";
/*@font-face {
    font-family: "Nunito Sans";
    src: url('#{$theme-font-path}NunitoSans-Regular.ttf') format('truetype');
    font-style: normal;
} */
$nunitoSans: "Nunito Sans", Arial, sans-serif;
$inter: "Inter", Arial, sans-serif;

@mixin slider-navigation($margin-right: 39px) {
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media all and (min-width: $screen-sm) {
    display: block;
    width: unset;
    justify-content: unset;
  }
  @media all and (min-width: $screen-lg) {
    margin-right: $margin-right;
  }
  a {
    color: inherit;
    img {
      &.arrow-left {
        margin: 0 16px;
        @media all and (min-width: $screen-sm) {
          margin-left: 0;
        }
      }
      &.arrow-right {
        margin: 0 16px;
      }
    }
    &.next {
      @media all and (min-width: $screen-sm) {
        margin-left: 80px;
      }
    }
    &:hover {
      text-decoration: none;
      opacity: 0.68;
    }
    &.uk-invisible {
      cursor: default;
      visibility: unset !important;
      opacity: 0.5;
    }
  }
}